import { StrategyQuery } from '@valstro/fixatdl-core';
import type { Strategy } from '@valstro/fixatdl-core';
import { STRATEGY_FIELD } from '../utils';
import { FORM_ERROR } from 'final-form';
import type { SubmissionErrors } from 'final-form';
import { mapOrderFieldsToFixRepresentation } from '@oms/ui-util';
import type { AnyFormValues } from '@oms/frontend-foundation';

/**
 * Checks to make sure strategy edits are valid.
 */
export const strategyErrorsValidator =
  (orderFormValues: AnyFormValues) =>
  (formValues: Record<string, any>): SubmissionErrors => {
    const strategyString = formValues[STRATEGY_FIELD];

    if (!strategyString || !Object.keys(orderFormValues).length) {
      return undefined;
    }

    const strategy: Strategy = JSON.parse(String(strategyString)) as Strategy;
    const strategyQuery = StrategyQuery.from(strategy);
    const { errors } = strategyQuery.state({
      ...formValues,
      ...mapOrderFieldsToFixRepresentation(orderFormValues)
    });

    const formErrors: SubmissionErrors = {};

    if (errors?.length) {
      formErrors[FORM_ERROR] = errors;
    }

    return formErrors;
  };
