import type { Maybe } from '@oms/ui-util';
import { t } from '@oms/codegen/translations';
import type { FieldApi } from '@data-driven-forms/react-form-renderer';

export interface OnlyIfRequired<Field extends string = string> {
  fields: Field[] | Field;
  message?: string;
}

export const onlyIfRequired: (
  setup: OnlyIfRequired
) => (value: any, allValues: Record<string, any>, fieldApi: FieldApi<any>) => Maybe<string> =
  ({ fields, message = t('app.common.requiredField') }) =>
  (value, allValues) => {
    const valid = (val: any) => typeof val !== 'undefined' && val !== null && val !== '';
    const isRequired = !!Object.entries(allValues || {}).find(
      ([f, val]) =>
        (Array.isArray(fields) ? fields.includes(f) : f === fields) &&
        ((!valid(val) && valid(value)) || (valid(val) && !valid(value)))
    );
    return isRequired ? message : null;
  };
