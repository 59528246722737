import { omit } from 'lodash';
import type { RxJsonSchema, RxDocument, RxCollection } from 'rxdb-oms-app';

export const WINDOW_CONTEXTS_COLLECTION_NAME = 'window_contexts';

export const windowContextsSchema: RxJsonSchema<WindowContextDocType> = {
  title: 'window contexts schema',
  description: 'describes details about window contexts to be restored when opened again',
  version: 0,
  keyCompression: false,
  primaryKey: 'type',
  type: 'object',
  properties: {
    type: {
      type: 'string',
      maxLength: 128
    },
    width: {
      type: 'number'
    },
    height: {
      type: 'number'
    },
    isMaximized: {
      type: 'boolean'
    },
    isFullscreen: {
      type: 'boolean'
    },
    x: {
      type: 'number'
    },
    y: {
      type: 'number'
    },
    isPinned: {
      type: 'boolean'
    }
  },
  required: ['width', 'height', 'x', 'y'],
  indexes: []
} as const;

export type WindowContextShape = {
  width: number;
  height: number;
  x: number;
  y: number;
  isMaximized?: boolean;
  isFullscreen?: boolean;
  isPinned?: boolean;
};

export type WindowContextDocType = {
  type: string;
} & WindowContextShape;

export type WindowContextDocument = RxDocument<WindowContextDocType>;

export type WindowContextsCollection = RxCollection<WindowContextDocType>;

export function sanitizeDocDataToContext(data: WindowContextDocType): WindowContextShape {
  return omit(data, 'type');
}
