import { useOpenTradeModify } from '@app/common/types/orders/trades/open.modify.trade';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { t } from '@oms/codegen/translations';
import { useWorkspaceContainer } from '@oms/frontend-foundation';
import { InvestorOrderStatus, ValstroEntitlements } from '@oms/generated/frontend';
import { Button, ButtonGroup } from '@oms/ui-design-system';
import type { FC } from 'react';
import { useOpenCancelTradeDialog } from '../grid-actions/cancel.trade.action';

interface TradeViewUtilityButtonGroupProps {
  tradeId: string;
  tradeStatus?: string;
}

export const TradeViewUtilityButtonGroup: FC<TradeViewUtilityButtonGroupProps> = ({
  tradeId,
  tradeStatus
}) => {
  const container = useWorkspaceContainer();

  const onCancel = useOpenCancelTradeDialog(tradeId);
  const onModify = useOpenTradeModify(tradeId);

  const authService = container.resolve(AuthService);
  const hasEntitlement = authService.hasEntitlement([ValstroEntitlements.OrderManage]);

  const isEnabled = hasEntitlement && tradeStatus === InvestorOrderStatus.Active;

  return (
    <ButtonGroup spacing={0}>
      <Button isDisabled={!isEnabled} onClick={onModify}>
        {t('app.common.modify')}
      </Button>
      <Button isDisabled={!isEnabled} onClick={onCancel}>
        {t('app.common.cancel')}
      </Button>
    </ButtonGroup>
  );
};
