import React from 'react';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import { Input } from '@oms/ui-design-system';
import type { FieldProps, ICommonTextField } from '../../types';
import { useFieldApi, useFieldFocus } from '../../helpers';
import { type FORM_COMPONENT_TYPE } from '../../contracts';
import { type Validator } from '@data-driven-forms/react-form-renderer';

export interface ITextField<TValidator = Validator>
  extends ICommonTextField<typeof FORM_COMPONENT_TYPE.TEXT_FIELD, string, TValidator> {}

export const TextField: React.FC<FieldProps<ITextField>> = (props) => {
  const {
    meta,
    label,
    input: { name, onBlur, onChange, value },
    isVisible,
    helperText,
    isRequired,
    isDisabled: _isDisabled,
    forceIsDisabled,
    isReadOnly,
    isInvalid,
    isFeatureField,
    isPrimaryField,
    requiredFieldIndicatorStyle,
    hideFormControls,
    style,
    sx,
    wrapperSx = {},
    format,
    initialValue: _initialValue,
    value: _value,
    fieldDef: _fieldDef,
    ...rest
  } = useFieldApi<ITextField, string>(props);

  const isDisabled = !!forceIsDisabled || !!_isDisabled;
  const hidden = isVisible === false;
  const [inputRef, onInputFocus] = useFieldFocus<HTMLInputElement>(props);

  return (
    <FieldWrapper
      meta={meta}
      label={label}
      isReadOnly={isReadOnly}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      requiredFieldIndicatorStyle={requiredFieldIndicatorStyle}
      helperText={helperText}
      hideFormControls={hideFormControls}
      isFeatureField={isFeatureField}
      isPrimaryField={isPrimaryField}
      isVisible={isVisible}
      sx={wrapperSx}
    >
      <Input
        ref={inputRef}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onInputFocus}
        value={value}
        hidden={hidden}
        style={style}
        sx={sx}
        format={format}
        {...rest}
        name={name}
      />
    </FieldWrapper>
  );
};
