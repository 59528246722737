export const scaleRangeNumberFormatTypes = [
  'charge-scale-range-number',
  'price-scale-range-number',
  'volume-scale-range-number'
] as const;

export type ScaleRangeNumberFormatType = (typeof scaleRangeNumberFormatTypes)[number];

export const numericFormatTypes = [
  ...scaleRangeNumberFormatTypes,
  'decimal',
  'decimal-2-4',
  'number-position',
  'number',
  'percentage',
  'decimal-to-percentage',
  'price-change',
  'price-order',
  'price-position-based-on-one',
  'price-position',
  'price-trade',
  'price',
  'quantity'
] as const;

export type NumericFormatType = (typeof numericFormatTypes)[number];

/** @deprecated use FormatType in `@oms/ui-design-system` */
export type FormatType =
  | NumericFormatType
  | 'auto-label-from-snake-case-value'
  | 'date'
  | 'datetime'
  | 'email-address'
  | 'email-address-strict'
  | 'phone-number-international'
  | 'phone-number-national'
  | 'time'
  | 'time12'
  | 'text';

export type FormatValue<Value = number | string> = Value;

export type FormatterFunctionParams<Value = FormatValue> = {
  value: FormatValue<Value>;
  thousandDelimiter?: string;
  decimalDelimiter?: string;
  hasDelimiters?: boolean;
  trimTrailingZeros?: boolean;
  decimalPlaces?: number;
  /** Interprets the value -1 as empty */
  negativeOneAsEmpty?: boolean;
};

export type FormatterFunction<Value = FormatValue> = (params: FormatterFunctionParams<Value>) => string;
