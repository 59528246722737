import type { CSSProperties } from 'react';
import { sideTypeMapping } from '@app/common/mappers/order-enums';
import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import type {
  AnyRecord,
  FieldContract,
  FieldOption,
  IAdvancedSelectField,
  ISelectField,
  ModifiedFieldForOptions
} from '@oms/frontend-foundation';
import { getOrderSideClassName } from '@oms/frontend-foundation';
import { comboBoxItemsFrom } from '@oms/ui-design-system';
import { OrderSideType } from '@oms/generated/frontend';

const label = 'Side';

const style: CSSProperties = {
  minWidth: 'max-content'
};

const getOrderSideTypeLabel = (side: OrderSideType): string => sideTypeMapping[side];

function fieldOptionFor(side: OrderSideType): FieldOption<OrderSideType> {
  const label = getOrderSideTypeLabel(side);
  return {
    label,
    value: side,
    className: getOrderSideClassName(side, { isInactive: false })
  };
}

const className = getOrderSideClassName(OrderSideType.Buy, { isInactive: false });

export const createSideField = <TOutputContract extends AnyRecord, TFieldKey extends keyof TOutputContract>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<ISelectField<TOutputContract[TFieldKey]>>
) =>
  fc.field(key, 'select').options<ISelectField<any>>({
    initialValue: OrderSideType.Buy,
    label,
    className,
    style,
    options: [
      fieldOptionFor(OrderSideType.Buy),
      fieldOptionFor(OrderSideType.Btc),
      fieldOptionFor(OrderSideType.Sell),
      fieldOptionFor(OrderSideType.Short),
      fieldOptionFor(OrderSideType.Exempt)
    ],
    ...overrides
  });

export const createSideAdvancedSelectField = <
  TOutputContract extends AnyRecord,
  TFieldKey extends keyof TOutputContract
>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<IAdvancedSelectField<OrderSideType>>
) =>
  fc.field(key, 'advanced-select').advancedSelectOptions({
    label,
    style,
    options: comboBoxItemsFrom.enum(OrderSideType, {
      label: getOrderSideTypeLabel
    }),
    ...overrides
  });
