import { BulkRouteOrderFormInput, BulkRouteOrderFormOutput } from './bulk-route-order.contracts';
import { bulkRouteOrderContract, BulkRouteOrderContractType } from './bulk-route-order.form-contract';
import { FormBuilder, FORM_EVENT_TYPE } from '@oms/frontend-foundation';
import { createStrategyInput } from '../route-order/fixatdl-strategy-field/fixatdl-strategy-field.util';
import { DestinationType, OrderRouteCreateInput, OrderType } from '@oms/generated/frontend';
import { RouteOrderService } from '@app/data-access/services/trading/routing/route-order.service';
import { Logger } from '@oms/ui-util';
import { BulkRouteOrderRow } from './bulk-route-order-grid/bulk-route-order-grid.columns';

const logger = Logger.named('BulkRouteOrderForm');

export const bulkRouteOrderFormBuilder = FormBuilder.create<
  BulkRouteOrderFormInput,
  BulkRouteOrderFormOutput
>('bulk-route-order-form')
  .contract<BulkRouteOrderContractType>(bulkRouteOrderContract)
  .type('bulk-route-order')
  .sanitizer((s) =>
    s
      .input(function sanitize(incomingValues, _ctx) {
        const orderIds = incomingValues.investorOrderIds;

        if (!orderIds?.length) {
          return {};
        }

        const ret = {
          ...incomingValues,
          ioIds: orderIds,
          percentage: 100 // Specify the percentage of 100 here.
          // potentially specify other fields like venue and strategy
        };
        return ret;
      })
      .output(function sanitize(formValues) {
        const output: BulkRouteOrderFormOutput = {
          ioIds: formValues.ioIds || [],
          percentage: Number(formValues.percentage),
          venue: formValues.venue?.id || '',
          strategy: createStrategyInput(formValues.strategy?.value),
          investorOrders: (formValues.investorOrders as BulkRouteOrderRow[]) || []
        };
        return output;
      })
  )
  .change(async (event, ctx) => {
    const routeOrderService = ctx.container.resolve(RouteOrderService);

    switch (event.type) {
      // No need to handle SANITIZED_VALUES_CHANGED. That's handled by the grid component.
      // Only worry about SUBMIT here.
      case FORM_EVENT_TYPE.SUBMIT: {
        const output = event.payload.output;

        // Keep track of any IO IDs that failed to route.
        const failedOrderRouteIoIds: string[] = [];

        await Promise.all(
          output.investorOrders.map(async (order) => {
            if (order.id && order.orderType) {
              // If we have no ID nor orderType something went wrong.
              const orci: OrderRouteCreateInput = {
                destinationId: output.venue,
                destinationType:
                  output.venue === 'primary-trader'
                    ? DestinationType.PrimaryTrader
                    : output.venue === 'trader'
                      ? DestinationType.Trader
                      : DestinationType.Venue,
                limitPrice: order.orderType === OrderType.Limit ? Number(order?.limitPrice) : undefined,
                orderId: order.id,
                orderType: order.orderType as OrderType,
                // In theory the quantity should be percentage (set at 100% for now) times the openQuantity.
                quantity: Number(order.openQuantity),
                strategy: output.strategy
              };
              const routeResponse = await routeOrderService.routeInvestorOrder(orci, { dryRun: false }); // making this not dry run

              if (routeResponse.isFailure()) {
                failedOrderRouteIoIds.push(order.id);
              }
            } else {
              logger.error('Could not route order:', order);
            }
          })
        );

        if (failedOrderRouteIoIds.length === 0) {
          // There were no failures. Close the form.
          ctx.notify({
            type: 'CLOSE'
          });
        } else {
          // There were some failures. Reset the form with those failed IO IDs as initialValues.
          ctx.notify({
            type: 'RESET',
            payload: {
              formValues: {
                ioIds: failedOrderRouteIoIds,
                investorOrders: output.investorOrders,
                // When resetting, keep the other field values the same.
                percentage: event.payload.formValues.percentage,
                venue: event.payload.formValues.venue,
                strategy: event.payload.formValues.strategy
              }
            }
          });
        }
        break;
      }
      default: {
        break;
      }
    }
  });

export type BulkRouteOrderFormBuilderType = typeof bulkRouteOrderFormBuilder;

export default bulkRouteOrderFormBuilder;
