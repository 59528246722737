import { ICellRendererParams } from '@ag-grid-community/core';
import { Box, ProgressBar } from '@oms/ui-design-system';
import { convertToNumber } from '@oms/ui-util';
import { useObservableState } from 'observable-hooks';
import React, { useCallback } from 'react';
import { BehaviorSubject } from 'rxjs';

/**
 * Const name registered with ag-grid
 */
export const PROGRESS_RENDERER = 'ProgressRenderer';

/**
 * Progress renderer bar for ag-grid
 */

const showPercentage$ = new BehaviorSubject<boolean>(true);

export const ProgressRenderer: React.FC<ICellRendererParams> = ({ data }) => {
  const denominator = convertToNumber(data?.quantity ?? data?.totalQuantity);
  const numerator = convertToNumber(data?.executedQuantity);

  const showPercentage = useObservableState(showPercentage$);

  const handleClick = useCallback(() => {
    showPercentage$.next(!showPercentage$.getValue());
  }, [showPercentage$]);

  return (
    <Box onClick={handleClick} sx={{ height: 'full' }}>
      <ProgressBar
        type="advanced"
        denominator={denominator}
        numerator={numerator}
        showPercentage={showPercentage}
      />
    </Box>
  );
};
