import type { ReactActorComponentProps } from '@valstro/workspace-react';
import type {
  AuthClientState,
  AuthClientError,
  AuthClientEvent,
  ValstroEntitlement
} from '@app/common/auth/keycloak.types';
import {
  COMMON_AUTH_WINDOW,
  DEFAULT_AUTH_STATE,
  AUTH_EVENT_ACTION,
  MIN_TOKEN_VALIDITY
} from '@app/common/auth/auth.contracts';
import type { CommonAuthWindowActorSchema } from '@app/common/auth/auth.contracts';
import { persistAuthToken } from '@app/common/auth/auth.helpers';
import { keycloakInstance } from '@app/common/auth/keycloak.client-instance';
import { parseKeycloakToken } from '@app/common/auth/keycloak.helpers';
import type Keycloak from 'keycloak-js';
import type { KeycloakInitOptions } from 'keycloak-js';
import { createLogger } from '@oms/ui-util';
import type { Logger } from '@oms/ui-util';
import { Center } from '@oms/ui-design-system';
import { filter } from 'rxjs';
import { container as rootContainer } from 'tsyringe';
import { AuthSignal } from '@app/data-access/memory/auth.signal';
import { useAuthState } from '@app/data-access/services/system/auth/auth.hooks';
import { isBrowser } from '@app/common/workspace/workspace.constants';
import UsersService from '@app/data-access/services/reference-data/users/users.service';

/**
 * Auth Window Actor Logger
 */
export const authActorLogger = createLogger({ name: 'Auth / Keycloak Actor' });

/**
 * Common Schema
 */
type CommonAuthWindowActorSchemaOverrides = (
  prev: Omit<CommonAuthWindowActorSchema, 'view'>
) => Partial<Omit<CommonAuthWindowActorSchema, 'view'>>;

export const commonAuthWindowSchemaOverrides: CommonAuthWindowActorSchemaOverrides = (prevSchema) => ({
  ignoreFromTakeSnapshot: true,
  ignoreFromApplySnapshot: true,
  initialContext: async (ctx) => ({
    ...(await prevSchema.initialContext(ctx)),
    title: 'Login',
    alwaysOnTop: true,
    isVisible: true,
    width: 422,
    height: 300,
    minWidth: 400,
    minHeight: 270,
    isClosable: true,
    skipTaskbar: true,
    isMaximizable: isBrowser(),
    isMinimizable: false,
    isMaximized: isBrowser()
  }),
  operations: (api, workspace) => {
    const prevOperations = prevSchema.operations(api, workspace);
    return {
      ...prevOperations,
      initialize: async (forceAuthState?: AuthClientState) => {
        /**
         * If the actor has a forceAuthState, return early
         * to avoid initializing keycloak (for testing)
         * Note: The auth state gets set in the events method below
         */
        if (forceAuthState) {
          await prevOperations.hide();
          await prevOperations.centerInActiveMonitor();
          return;
        }

        const defaultOptions: KeycloakInitOptions = {
          enableLogging: true,
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri: window.location.origin + `/silent-check-sso.html`,
          silentCheckSsoFallback: true
        };

        await keycloakInstance.init(defaultOptions);
      },
      startLogin: async () => {
        keycloakInstance.login().catch(authActorLogger.error);
        await Promise.all([prevOperations.setTitle('Login'), prevOperations.show()]);
      },
      finishLogin: async () => {
        await prevOperations.hide();
        await prevOperations.centerInActiveMonitor();
      },
      logout: async () => {
        Promise.all([prevOperations.setTitle('Login'), prevOperations.show()]).catch(authActorLogger.error);
        await keycloakInstance.logout();
      }
    };
  },
  events: async (ctx, workspace) => {
    const unsubPrevEvents = await prevSchema.events?.(ctx, workspace);
    const { operations } = ctx;
    const [tokenRefreshTimer, clearTokenRefreshTimer] = createTokenRefreshTimer(authActorLogger);

    const actor = workspace
      .getActorRegistry()
      .getActorByType<CommonAuthWindowActorSchema>(COMMON_AUTH_WINDOW.TYPE);

    const container = actor?.meta?.container || rootContainer;

    const authSignalService = container.resolve(AuthSignal);

    /**
     * If the actor has a forceAuthState, update the store and return
     * This is used for testing
     */
    const forceAuthState = actor?.meta?.forceAuthState;
    if (forceAuthState) {
      authSignalService.signal.set((v) => ({
        ...v,
        ...forceAuthState,
        isReady: true
      }));

      return () => {};
    }

    /**
     * Sync instance events to the store
     */
    const onEvent = (keycloak: Keycloak) => {
      const keycloakEventHandler = async (event: AuthClientEvent, error?: AuthClientError) => {
        if (error) {
          authActorLogger.error('Keycloak Error', error);
        }

        switch (event) {
          case 'onReady': {
            if (!keycloak.authenticated) {
              authSignalService.signal.set((v) => ({
                ...v,
                lastAuthClientEvent: 'onReady',
                isReady: true
              }));
              await operations.startLogin();
            }

            break;
          }
          case 'onAuthRefreshSuccess':
          case 'onAuthSuccess': {
            if (!keycloak.token) {
              throw Error('Failed to locate keycloak token - can not login');
            }
            if (!keycloak.tokenParsed?.exp) {
              throw Error('Failed to locate keycloak token expiration - can not login');
            }

            // Set the keycloak token proactively so it's available in createAppWorkspace > dataAccessPlugin
            persistAuthToken(keycloak.token);

            tokenRefreshTimer(keycloak.tokenParsed.exp);

            const usersService = container.resolve(UsersService);
            const entitlementsResponse = await usersService.getUserWithEntitlements();
            const entitlements = entitlementsResponse?.data?.userWithEntitlements
              ?.entitlements as ValstroEntitlement[];

            const tokenParsed = parseKeycloakToken(keycloak);

            authSignalService.signal.set((v) => ({
              ...v,
              isReady: true,
              isAuthenticated: true,
              lastAuthClientEvent: event,
              token: keycloak.token,
              tokenParsed: tokenParsed,
              idToken: keycloak.idToken,
              refreshToken: keycloak.refreshToken,
              sessionId: keycloak.sessionId,
              roles: entitlements ?? [],
              expiry: tokenParsed?.exp ? tokenParsed.exp : null
            }));

            if (event === 'onAuthSuccess') {
              await operations.finishLogin();
            }

            break;
          }
          case 'onTokenExpired':
          case 'onAuthRefreshError': {
            authActorLogger.warn('Token expired, attempting to refresh...');
            try {
              await keycloak.updateToken(MIN_TOKEN_VALIDITY);
            } catch (error) {
              authActorLogger.error(
                `${event === 'onAuthRefreshError' ? 'Second token refresh attempt' : 'Token refresh'} failed, logging out`,
                error
              );
              await operations.startLogin();
            }
            break;
          }
          case 'onAuthLogout': {
            authSignalService.signal.set((v) => ({
              ...v,
              ...DEFAULT_AUTH_STATE,
              lastAuthClientEvent: event
            }));
            await operations.startLogin();
            break;
          }
        }
      };

      return keycloakEventHandler;
    };

    keycloakInstance.onReady = () => {
      onEvent(keycloakInstance)('onReady').catch(authActorLogger.error);
    };

    keycloakInstance.onAuthSuccess = () => {
      onEvent(keycloakInstance)('onAuthSuccess').catch(authActorLogger.error);
    };

    keycloakInstance.onAuthError = () => {
      onEvent(keycloakInstance)('onAuthError').catch(authActorLogger.error);
    };

    keycloakInstance.onAuthRefreshSuccess = () => {
      onEvent(keycloakInstance)('onAuthRefreshSuccess').catch(authActorLogger.error);
    };

    keycloakInstance.onAuthLogout = () => {
      onEvent(keycloakInstance)('onAuthLogout').catch(authActorLogger.error);
    };

    keycloakInstance.onTokenExpired = () => {
      onEvent(keycloakInstance)('onTokenExpired').catch(authActorLogger.error);
    };

    keycloakInstance.onAuthRefreshError = () => {
      onEvent(keycloakInstance)('onAuthRefreshError').catch(authActorLogger.error);
    };

    /**
     * Listen to auth actions from other processes
     */
    const unsubAuth = authSignalService.action$
      .pipe(filter((a) => a === AUTH_EVENT_ACTION.LOGOUT))
      .subscribe(() => {
        authSignalService.signal.set((v) => ({
          ...v,
          ...DEFAULT_AUTH_STATE,
          lastAuthClientEvent: 'onAuthLogout'
        }));
        operations.logout().catch(authActorLogger.error);
      });

    return () => {
      unsubAuth.unsubscribe();

      if (unsubPrevEvents) {
        unsubPrevEvents();
      }

      clearTokenRefreshTimer();

      keycloakInstance.onReady = () => {};
      keycloakInstance.onAuthSuccess = () => {};
      keycloakInstance.onAuthError = () => {};
      keycloakInstance.onAuthRefreshSuccess = () => {};
      keycloakInstance.onAuthLogout = () => {};
      keycloakInstance.onTokenExpired = () => {};
    };
  }
});

export const CommonAuthWindowComponent: React.FC<
  ReactActorComponentProps<CommonAuthWindowActorSchema>
> = () => {
  const { isAuthenticated, isReady } = useAuthState();
  const isAuthenticating = !isAuthenticated && !isReady;

  return (
    <Center>
      {isAuthenticating ? (
        <div>Authenticating...</div>
      ) : isAuthenticated ? (
        <div>Authenticated</div>
      ) : (
        <div>Preparing...</div>
      )}
    </Center>
  );
};

/**
 * Handles token refresh timer
 *
 * @param refreshTokenTimeout - Timeout
 * @param expiryTime - Expiry time
 * @returns void
 */
export function createTokenRefreshTimer(logger: Logger) {
  let lastRefreshTime = Math.floor(Date.now() / 1000);
  let refreshTokenTimeout: number | undefined;

  return [
    (expiryTime: number | undefined) => {
      if (!expiryTime) {
        logger.error('No expiry time found in token. Token refresh timer not set.');
        return;
      }

      if (refreshTokenTimeout) {
        clearTimeout(refreshTokenTimeout);
      }

      const now = Math.floor(Date.now() / 1000);
      const secondsUntilExpiry = expiryTime - now;

      if (secondsUntilExpiry <= MIN_TOKEN_VALIDITY) {
        logger.warn('Token is close to expiry or already expired. Attempting immediate refresh.');
        keycloakInstance
          .updateToken(MIN_TOKEN_VALIDITY)
          .then((refreshed) => {
            if (!refreshed) {
              logger.warn('Token was still valid, no refresh needed.');
              return;
            }

            const now = Math.floor(Date.now() / 1000);
            const timeSince = now - lastRefreshTime;
            logger.info(`Token refreshed successfully after waiting ${timeSince} seconds.`);
            lastRefreshTime = now;
          })
          .catch(logger.error);
        return;
      }

      const refreshTokenInSeconds = Math.max(secondsUntilExpiry - MIN_TOKEN_VALIDITY, 0);

      refreshTokenTimeout = window.setTimeout(() => {
        keycloakInstance
          .updateToken(MIN_TOKEN_VALIDITY)
          .then((refreshed) => {
            if (!refreshed) {
              logger.warn('Token was still valid, no refresh needed.');
              return;
            }

            const now = Math.floor(Date.now() / 1000);
            const timeSince = now - lastRefreshTime;
            logger.info(`Token refreshed successfully after waiting ${timeSince} seconds.`);
            lastRefreshTime = now;
          })
          .catch(logger.error);
      }, refreshTokenInSeconds * 1000);

      logger.info(`Token refresh scheduled in ${refreshTokenInSeconds} seconds.`);
    },
    () => {
      if (refreshTokenTimeout) {
        clearTimeout(refreshTokenTimeout);
      }
    }
  ] as const;
}
