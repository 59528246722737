import { v4, V4Options } from 'uuid';
import UUIDString, { isUUIDString } from '../types/uuid';

export type UUIDOptions = V4Options & {
  /** If you want to create manually or use an existing UUID, pass here. If validated, it will be used as-is instead of a new one being generated. */
  uuid?: string;
};

/**
 * Creates a new UUID v4 string using the `uuid` package.
 * This is just a convenience function for using UUID v4;
 * To create another version besides 4, just use the `uuid` package directly.
 *
 * @param [options] - UUID setup options
 * @returns A UUID v4 string.
 */
export const UUID = (options?: UUIDOptions): UUIDString => {
  if (!options) return v4();
  const { uuid, ...v4Options } = options;
  if (uuid && isUUIDString(uuid)) return uuid;
  return v4(v4Options);
};

