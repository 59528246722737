import { useMemo } from 'react';
import type { PropsWithChildren, FC } from 'react';
import type { Optional } from '@oms/ui-util';
import { Box } from '@oms/ui-design-system';
import { WIDGET_COMPONENT } from '@app/app.mapper.widget-components';
import type { LayoutEnumKey } from '@app/generated/common';
import {
  FLEX_LAYOUT_FOOTER_MAPPER,
  FLEX_LAYOUT_HEADER_MAPPER
} from '@app/app.mapper.flex-layout-peripherals';

type PeripheralType = 'header' | 'footer';

const getMapper = (type: PeripheralType) => {
  switch (type) {
    case 'header':
      return FLEX_LAYOUT_HEADER_MAPPER;
    case 'footer':
      return FLEX_LAYOUT_FOOTER_MAPPER;
  }
};

const getFlexLayoutPeripheralComponent = (type: PeripheralType, layout: LayoutEnumKey): Optional<FC> => {
  const componentEnumKey = getMapper(type)[layout];
  if (!componentEnumKey) return undefined;
  const component = WIDGET_COMPONENT[componentEnumKey];
  if (typeof component === 'undefined' || component === null) return undefined;
  return component as FC;
};

const FlexLayoutVerticalPeripheralWrapper: FC<PropsWithChildren> = ({ children }) => (
  <Box sx={{ display: 'flex', position: 'relative' }}>{children}</Box>
);

export const useFlexLayoutPeripheralContent = (type: PeripheralType, layout: LayoutEnumKey) => {
  return useMemo(() => {
    const Component = getFlexLayoutPeripheralComponent(type, layout);
    if (!Component) return null;
    return (
      <FlexLayoutVerticalPeripheralWrapper>
        <Component />
      </FlexLayoutVerticalPeripheralWrapper>
    );
  }, [layout, type]);
};

export default useFlexLayoutPeripheralContent;
