import { Cross1Icon } from '@radix-ui/react-icons';
import { openConfirmation } from '@app/generated/sdk';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { t } from '@oms/codegen/translations';
import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import type { NotificationRow } from '@oms/generated/frontend';
import { NotificationVisibility } from '@app/notifications/notifications.contracts';
import { NotificationsService } from '@app/data-access/services/notifications/notifications.service';
import { NotificationsVisibilityService } from '@app/notifications/notifications.visibility.service';
import { createLogger } from '@oms/ui-util';
import { Tooltip } from '@oms/ui-design-system';

export const clearNotificationsOnChange = async (
  ctx: ActionContext<NotificationRow, ActionComponentConfig<NotificationRow>>
) => {
  const { lifecycle, workspace } = ctx;
  const logger = createLogger({ name: 'clearNotificationsOnChange' });

  if (lifecycle === 'change') {
    try {
      const [_, api] = await openConfirmation(workspace, workspace.getLeaderProcessId(), {
        title: t('app.notifications.dialog.clearNotifications.confirmationTitle'),
        componentProps: {
          autoClose: true,
          message: t('app.notifications.dialog.clearNotifications.confirmationMessage'),
          confirmButtonText: t('app.common.yes'),
          confirmButtonProps: {
            variant: 'primary',
            palette: 'standard'
          },
          cancelButtonText: t('app.common.no')
        },
        windowOptions: {
          width: 400,
          minWidth: 400,
          maxWidth: 400,
          height: 180,
          minHeight: 180,
          maxHeight: 180
        }
      });
      const event = await api.awaitFirstEvent;
      switch (event.type) {
        case DIALOG_EVENT_TYPE.OK: {
          const notificationVisibilityService = ctx.appContainer.resolve(NotificationsVisibilityService);
          const visibility: NotificationVisibility = notificationVisibilityService.getVisibility();

          const notificationsService = ctx.appContainer.resolve(NotificationsService);
          try {
            const dismissResponse = await notificationsService.dismissNotifications(visibility);
            if (dismissResponse.isFailure()) {
              logger.error('Could not dismiss notifications:', dismissResponse.errors);
            }
          } catch (err) {
            logger.error(err);
          }
          break;
        }
      }
    } catch (e) {
      openMessageDialog(`Error: ${String(e)}`, workspace).catch((err) => logger.error(err));
      logger.error(e);
    }
  }
};

export const clearNotificationsAction: ActionDefFactory<NotificationRow> = (builder) =>
  builder
    .name('clear_notifications')
    .toolbar((t) =>
      t
        .component('action-button')
        .id('clear_notifications_button')
        .location('StaticToolbar')
        .props({
          variant: 'link',
          content: (
            <Tooltip label="Clear all notifications">
              <Cross1Icon width={12} height={12} />
            </Tooltip>
          )
        })
    )
    .onChange<ActionComponentConfig<NotificationRow>>(clearNotificationsOnChange);
