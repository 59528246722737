import { useState } from 'react';
import { Button, ButtonGroup, Input, FormControl, FormLabel } from '@oms/ui-design-system';
import { useCurrentDialogApi, DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import type { RejectWithReasonDialogProps } from './reject-with-reason.dialog.config';

export const RejectWithReasonDialogComponent: React.FC = () => {
  const [_props, api] = useCurrentDialogApi<RejectWithReasonDialogProps>();
  const [reason, setReason] = useState('');

  return (
    <>
      <FormControl sx={{ margin: 4 }}>
        <FormLabel>Reject Reason</FormLabel>
        <Input value={reason} onChange={(e) => setReason(e.target.value)} />
      </FormControl>

      <ButtonGroup direction="horizontal" justify="flex-end" align="flex-end" style={{ width: '100%' }}>
        <Button
          sx={{ marginRight: 4 }}
          type="submit"
          onClick={() => {
            // emit the reject reason as the payload
            api.emit({ type: DIALOG_EVENT_TYPE.OK, payload: reason });
            api.close().catch((e) => {
              console.error(e);
            });
          }}
        >
          Reject
        </Button>
      </ButtonGroup>
    </>
  );
};

export default RejectWithReasonDialogComponent;
