import type { FC, ReactElement } from 'react';
import clsx from 'clsx';
import type { ICellRendererParams } from '@ag-grid-community/core';
import { uiDateTimeFormat } from '@oms/ui-util';
import { Box, Flex, Icon, TimerIcon, Tooltip } from '@oms/ui-design-system';
import type { PositionRow } from '@app/common/types/positions/positions.types';
import { isPositionWithValuationOverride } from '@app/common/types/positions/positions.util';

// TODO: t() labels below

export const CLASS_NAMES = {
  VALUATION_OVERRIDE_INDICATOR: 'valuation-override-indicator',
  VALUATION_OVERRIDE_INDICATOR_TOOLTIP: 'valuation-override-indicator-tooltip'
} as const;

/**
 * Render instrument with tooltip showing last marked datetime
 */
export const instrumentColumnRenderer: FC<ICellRendererParams<PositionRow, ReactElement>> = ({
  value,
  data
}) => (
  <Flex align="center">
    <Box>{value}</Box>
    {isPositionWithValuationOverride(data) && data.manualMark.endDateTime ? (
      <Tooltip
        className={clsx(CLASS_NAMES.VALUATION_OVERRIDE_INDICATOR_TOOLTIP)}
        label={`Valuation Override until${` ${uiDateTimeFormat.dateAndTime(data.manualMark.endDateTime)}`}`}
      >
        <Icon
          as={TimerIcon}
          label="Valuation Override until"
          className={clsx(CLASS_NAMES.VALUATION_OVERRIDE_INDICATOR)}
          sx={{ fontSize: 'gridTiny', marginLeft: 1.5, marginBottom: 1 }}
        />
      </Tooltip>
    ) : null}
  </Flex>
);

export default instrumentColumnRenderer;
