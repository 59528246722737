import type {
  TableServerGridType,
  EnrichedColumnDef,
  ColumnNamespace,
  ColumnType
} from './table-server.types';
import { exists, t } from 'i18next';
import type { ColDef } from '@ag-grid-community/core';
import { format, isDateTimeFormat, isNumericFormat } from '@oms/ui-design-system';
import type { FormatType, GetFormatUnionInput } from '@oms/ui-design-system';
import { CUSTOM_FORMATTER_REGISTRY, isCustomFormatter } from './table-server.formatters';
import type { CustomFormatters } from './table-server.formatters';
import {
  FILTER_COMPONENT_REGISTRY,
  FLOATING_FILTER_COMPONENT_REGISTRY,
  isCustomFilterComponent,
  isFloatingFilterComponent
} from './table-server.filters';
import { columnMapping } from './table-server.mappers';
import { omit } from 'lodash';

export const getHeader = (field: string, gridType: TableServerGridType) => {
  const getTranslation = (ns: 'short' | 'long') => {
    const paths = [`app.tableServer.${gridType}.${field}`, `app.tableServer.common.${field}`];

    for (const path of paths) {
      if (exists(path, { ns })) {
        return t(path, { ns });
      }
    }

    return undefined;
  };

  const shortName = getTranslation('short');
  const longName = getTranslation('long');

  return { shortName, longName };
};

export const applyFormatting = <T extends string>(
  type: FormatType
): Pick<ColDef, 'filter' | 'type' | 'valueFormatter'> => {
  return {
    filter: isNumericFormat(type)
      ? 'agNumberColumnFilter'
      : isDateTimeFormat(type)
        ? 'agDateColumnFilter'
        : undefined,
    type: isNumericFormat(type) ? 'rightAligned' : undefined,
    valueFormatter: ({ value }) => {
      return value || value === 0 ? format(type, value as GetFormatUnionInput<T>) : '';
    }
  };
};

export const getValueFormatter = (formatterKey: CustomFormatters) => {
  return isCustomFormatter(formatterKey) ? CUSTOM_FORMATTER_REGISTRY[formatterKey] : undefined;
};

export const getFilterComponent = (filter: EnrichedColumnDef['filter']) => {
  return isCustomFilterComponent(filter) ? FILTER_COMPONENT_REGISTRY[filter] : undefined;
};

export const getFloatingFilterComponent = (filter: EnrichedColumnDef['floatingFilterComponent']) => {
  return isFloatingFilterComponent(filter) ? FLOATING_FILTER_COMPONENT_REGISTRY[filter] : undefined;
};

export const getExtendedColDef = (colRef: EnrichedColumnDef['extends']): EnrichedColumnDef => {
  if (!colRef) {
    return {};
  }

  const processColDef = (ref: string) => {
    const [namespace, componentType] = (ref?.split('.') as [ColumnNamespace, string]) || [];
    const colDef = (columnMapping[namespace]?.[componentType as ColumnType<typeof namespace>] ||
      {}) as EnrichedColumnDef;

    return colDef.extends ? { ...getExtendedColDef(colDef.extends), ...omit(colDef, 'extends') } : colDef;
  };

  return Array.isArray(colRef)
    ? colRef.reduce((acc, ref) => ({ ...acc, ...processColDef(ref) }), {})
    : processColDef(colRef);
};

export const generateQueryFields = (keys: string[] = []) => {
  return keys.join('\n');
};
