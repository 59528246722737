import { useMemo } from 'react';
import type { AnyRecord } from '@oms/ui-util';
import type { ICellRendererParams } from '@ag-grid-community/core';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { VStack, Flex, Text } from '@oms/ui-design-system';
import { translateEnum } from '@app/common/mappers/translate-enum';
import { getCellRendererOrderSideClassName } from '@oms/frontend-foundation';

export const SIDE_TYPE_CELL_RENDERER = 'SideTypeCellRenderer';

export function SideTypeCellRenderer<TData extends AnyRecord, TValue extends string = string>(
  params: ICellRendererParams<TData, TValue>,
  showPlaceholder: boolean
) {
  const { value } = params;
  if (showPlaceholder && (value === null || value === '')) {
    return (
      <VStack justify="center" style={{ height: '100%' }}>
        <Flex justify="space-between">
          <Text type="gridBase" as="div">
            Side
          </Text>
          <ChevronDownIcon />
        </Flex>
      </VStack>
    );
  }

  const className = useMemo(() => {
    return getCellRendererOrderSideClassName(params);
  }, [params]);

  const formattedValue = useMemo(() => {
    return translateEnum('side', params.value || '');
  }, [params.value]);

  return <Text className={className}>{formattedValue}</Text>;
}
