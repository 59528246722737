import { ValstroEntitlements, RulesDefinitionRow } from '@oms/generated/frontend';
import { RoutingRulesService } from '@app/data-access/services/reference-data/routing-rules/routing-rules.service';
import { crudWindowGridActionBuilder } from '@app/common/grids/actions/crud-window.actions';

const routingRuleActionBuilder = crudWindowGridActionBuilder<RulesDefinitionRow>('routing_rule', [
  ValstroEntitlements.RoutingRulesManage
]);

export const createAction = routingRuleActionBuilder('CREATE', {
  formKey: 'ROUTING_RULE_FORM',
  titleGetter: () => 'New routing rule'
});

export const updateAction = routingRuleActionBuilder('UPDATE', {
  formKey: 'ROUTING_RULE_FORM',
  rowDataToFormInput: (rowData: RulesDefinitionRow) => ({
    id: rowData.id,
    name: rowData.name,
    description: rowData.description,
    active: rowData.active,
    priority: rowData.priority
  }),
  titleGetter: (rows: RulesDefinitionRow[]) => `Edit ${rows[0].id ?? ''} routing rule`
});

export const deleteAction = routingRuleActionBuilder('DELETE', {
  titleGetter: (rows) => `Delete ${rows[0].id ?? ''}`,
  onConfirmDelete: async (selectedRows, ctx) => {
    const routingRulesService = ctx.appContainer.resolve(RoutingRulesService);
    await routingRulesService.delete(selectedRows[0].id);
  }
});
