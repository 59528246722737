import type { AnyKey, AnyRecord, Identifiable, Keyed, OneOrMore, Optional } from '@oms/ui-util';
import { asArray } from '@oms/ui-util';
import type { NestedTreeData } from '../../types/tree-data.types';
import type { ExtractItemKeyOptions } from '../internal/types';

// 🔑 Tree data key utils -------------------------------------------------------- /
// Utils to manage/use keys in nested tree data

export const extractItemKey = <TData extends AnyRecord, Key extends AnyKey = string>(
  data: TData,
  options?: ExtractItemKeyOptions<TData, Key>
): Optional<Key> =>
  options?.getKey?.(data) ??
  (data as Partial<Keyed<Key>>).key ??
  (data as Partial<Identifiable<Key>>).id ??
  undefined;

/**
 * Find an item with the given key in nested tree data
 *
 * @param key - The key of the item you are looking for (likely the ID)
 * @param data - One or more nested tree data objects
 * @param options.getKey - Specify how to get the key value (id, etc.) from the data
 * @returns An array of items found, if any
 */
export const findAllItemsWithKeyInNestedTreeData = <TData extends AnyRecord, Key extends AnyKey = string>(
  key: AnyKey,
  data: OneOrMore<NestedTreeData<TData>>,
  options?: ExtractItemKeyOptions<TData, Key>
): NestedTreeData<TData>[] => {
  return asArray(data).reduce((found, item) => {
    const { children, ...rest } = item;
    const dataItem = rest as TData;
    const itemKey = extractItemKey(dataItem, options);
    if (itemKey?.toString().includes(key.toString())) found.push(item);
    if (children) found.push(...findAllItemsWithKeyInNestedTreeData(key, children));
    return found;
  }, [] as NestedTreeData<TData>[]);
};

/**
 * Find an item with the given key in nested tree data
 *
 * @param key - The key of the item you are looking for (likely the ID)
 * @param data - One or more nested tree data objects
 * @param options.getKey - Specify how to get the key value (id, etc.) from the data
 * @returns The first item found, if any or `undefined` if not
 */
export const findItemWithKeyInNestedTreeData = <TData extends AnyRecord, Key extends AnyKey = string>(
  key: AnyKey,
  data: OneOrMore<NestedTreeData<TData>>,
  options?: ExtractItemKeyOptions<TData, Key>
): Optional<NestedTreeData<TData>> => findAllItemsWithKeyInNestedTreeData(key, data, options)[0];
