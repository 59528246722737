import type { ComboBoxItem } from '@oms/ui-design-system';
import type { FixAtdlListItem } from './fixatdl-form.types';

export const STRATEGY_FIELD = '$strategy';

export const mapFixAtdlListOptions = (options: FixAtdlListItem[]) => {
  return options.map(({ enumID, uiRep }) => {
    return { label: uiRep, value: enumID };
  });
};

export const mapFixAtdlAdvancedListOptions = (options: FixAtdlListItem[]): ComboBoxItem<any>[] => {
  return options.map((option) => {
    return mapOptionToComboboxItem(option);
  });
};

export const mapOptionToComboboxItem = (option: FixAtdlListItem): ComboBoxItem<any> => {
  return {
    type: 'item',
    id: option.enumID,
    value: option.enumID,
    label: option.uiRep
  };
};
