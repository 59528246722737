import { FieldContract, FieldOption } from '@oms/frontend-foundation';
import { makeValueLabelPairs } from '@oms/ui-util';
import type { DefaultNotificationName } from '@oms/generated/frontend';
import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import { createMultiAccountField } from '@app/forms/common/fields/account-field/account.field.common';
import { CustomNotificationNameType } from './custom-notification-entry.form-common';
import type { CustomNotificationEntryOutput } from './custom-notification-entry.form-common';

const fieldOptions = makeValueLabelPairs(Object.keys(CustomNotificationNameType), CustomNotificationNameType);

const fc = FieldContract.create<CustomNotificationEntryOutput, AdditionalFormFieldUnion>();

export const hiddenFormInfo = fc.field('hiddenFormInfo', 'hidden-field');

export const id = fc.field('id', 'text-field').options({
  isVisible: false
});

export const notificationName = fc.field('notificationName', 'select').options({
  isRequired: true,
  label: 'Event',
  options: fieldOptions as FieldOption<DefaultNotificationName>[]
});

export const description = fc.field('description', 'text-field').options({
  label: 'Description',
  isRequired: true
});

export const matchedAccounts = createMultiAccountField(fc, 'matchedAccounts', {
  autoFocus: false,
  isRequired: true
});

export const isPopup = fc.field('isPopup', 'checkbox').options({
  label: 'Platform popup',
  isDisabled: true // disable all pop-ups for all custom notification types
});

export const isSound = fc.field('isSound', 'checkbox').options({
  label: 'Sound'
});

export const isShown = fc.field('isShown', 'checkbox').options({
  label: 'Show in notification center'
});
