import { useEffect, useState } from 'react';
import type { FORM_COMPONENT_TYPE } from '../../../../forms/form-builder/common/form.contracts';
import type { Validator } from '@data-driven-forms/react-form-renderer';
import type { FieldProps, ICommonTextField } from '@oms/frontend-foundation';
import { TsInvestorOrdersWithFilterDocument } from '@oms/generated/frontend';
import type {
  InvestorOrderInfoFragment,
  InvestorOrderRow,
  TsInvestorOrdersWithFilterSubscription
} from '@oms/generated/frontend';
import type { PrincipalFillFormValues } from '../principal-fill.form-contract';
import { useVGrid, VGrid } from '@oms/frontend-vgrid';
import { convertToNumber } from '@oms/ui-util';
import { buildPrincipalFillColumnDefs } from './principal-fill.columns';
import { useEnhancedFormApi } from '@oms/frontend-foundation';
import { format } from '@oms/ui-design-system';
import { withTableServer } from '@app/data-access/services/system/table-server/with.table-server.component';

export interface IPrincipalFillGrid<TValidator = Validator>
  extends ICommonTextField<
    typeof FORM_COMPONENT_TYPE.PRINCIPAL_FILL,
    InvestorOrderInfoFragment[],
    TValidator
  > {}

export type PrincipalFillGridRow = Partial<InvestorOrderRow> & { fillQuantity: number };

type PrincipalFillSubscriptions = 'principal_fill';

type PrincipalFillSubscriptionMap = {
  principal_fill: {
    datasource: InvestorOrderRow;
    subscription: TsInvestorOrdersWithFilterSubscription;
  };
};

export const PrincipalFillGrid = withTableServer<
  PrincipalFillSubscriptions,
  PrincipalFillSubscriptionMap,
  FieldProps<IPrincipalFillGrid>
>(
  ({ datasources }) => {
    const [investorOrderId, setInvestorOrderId] = useState<string | undefined>();
    const [fillQuantity, setFillQuantity] = useState<number | undefined>();

    const formApi = useEnhancedFormApi<PrincipalFillFormValues>();

    useEffect(() => {
      formApi.get$({ values: true }).subscribe(({ values }) => {
        setInvestorOrderId(values?.investorOrders?.[0]?.id);
        setFillQuantity(convertToNumber(values.quantity));
      });
    }, [formApi]);

    const columnDefs = buildPrincipalFillColumnDefs();

    columnDefs.columns.push({
      headerName: 'Fill Quantity',
      valueFormatter: () => format('quantity', fillQuantity || '')
    });

    const gridProps = useVGrid<PrincipalFillGridRow>(
      'principal-fill',
      (builder) =>
        builder
          .tableServerColumnLibrary(columnDefs)
          .datasource((d) =>
            d
              .source(
                datasources.principal_fill.asServerSideRowModel({
                  filter: {
                    id: {
                      filterType: 'text',
                      type: 'equals',
                      filter: investorOrderId
                    }
                  }
                })
              )
              .rowId((r) => r.data.id!)
              .cacheBlockSize(100)
          )
          .sideBar()
          .reactiveCustomComponents(),
      [investorOrderId, fillQuantity, datasources]
    );

    return (
      <>
        <VGrid {...gridProps} />
      </>
    );
  },
  {
    principal_fill: {
      query: TsInvestorOrdersWithFilterDocument,
      getData: (r) => r.tsInvestorOrdersWithFilter
    }
  }
);
