import type { IServerSideGetRowsRequest } from '@ag-grid-community/core';
import { BehaviorSubject, share, tap } from 'rxjs';
import type { Observable } from 'rxjs';
import { Logger, asSet, cloneSet } from '@oms/ui-util';
import { asDataSource, toDataSourceResult } from '@oms/frontend-foundation';
import type { DataSourceCommon, DataSourceResult } from '@oms/frontend-foundation';
import type { AnyKey, AnyRecord, Maybe, Optional } from '@oms/ui-util';
import type { FilterModels } from '../types/grid-filter.types';
import type {
  ExtractRowsFromDataOptions,
  ServerSideRowDataHelperFilterOptions,
  ServerSideRowDataHelperFlattenOptions,
  ServerSideRowDataHelperOptions,
  ToRowDataOptions
} from './server-side-row-data-helper.types';
import { applyFilterModels } from '../util/filtering/grid-tree-data-filtering.util';
import { splitColumnFilters } from '../util/filtering/grid-filter-models.util';
import { flattenNestedTreeDataNodeToSlice } from '../util/tree-data/tree-data-flatten.util';
import type { NestedTreeData, TreeDataSlice } from '../types/tree-data.types';

export abstract class ServerSideRowDataHelper<TData extends AnyRecord = any, Key extends AnyKey = string> {
  public readonly gridType: string;

  protected _dataSource$: Observable<DataSourceResult<NestedTreeData<TData>>>;

  protected _data: NestedTreeData<TData>[] = [];
  protected _filteredData: NestedTreeData<TData>[] = [];

  protected filteredDataSubject$ = new BehaviorSubject<NestedTreeData<TData>[]>([]);

  protected dataMap = new Map<Key, NestedTreeData<TData>>();
  protected rowDataMap = new Map<Key, TreeDataSlice<TData, Key>[]>();

  protected logger: Logger;

  protected isFetchingSubject$ = new BehaviorSubject<boolean>(false);
  protected errorSubject$ = new BehaviorSubject<Optional<Error>>(undefined);

  // 🏗️ Constructor ------------------------------------------ /

  protected constructor(gridType: string, data$: Observable<DataSourceCommon<NestedTreeData<TData>>>) {
    this.gridType = gridType;
    this.logger = Logger.named(`ServerSideRowDataHelper(${gridType})`);
    this._dataSource$ = this.initDataSourceObservable$(data$);
  }

  // 📢 Public ---------------------------------------------- /

  // Properties ------ /

  public get data(): NestedTreeData<TData>[] {
    return this._data;
  }

  public get dataSource$(): Observable<DataSourceResult<TData>> {
    return this._dataSource$;
  }

  public get filteredData(): NestedTreeData<TData>[] {
    return this.filteredDataSubject$.getValue();
  }

  public get filteredData$(): Observable<NestedTreeData<TData>[]> {
    return this.filteredDataSubject$;
  }

  public get isFetching(): boolean {
    return this.isFetchingSubject$.getValue();
  }

  public get error(): Optional<Error> {
    return this.errorSubject$.getValue();
  }

  public get isFetching$(): Observable<boolean> {
    return this.isFetchingSubject$;
  }

  public get error$(): Observable<Optional<Error>> {
    return this.errorSubject$;
  }

  // Methods ------ /

  public onRowsRequest(
    request: IServerSideGetRowsRequest,
    data?: NestedTreeData<TData>[]
  ): TreeDataSlice<TData, Key>[] {
    const { groupKeys, filterModel } = request;
    const filteredData = this.filterData(data ?? this.data, filterModel as Maybe<FilterModels<TData>>);
    this.filteredDataSubject$.next(filteredData);
    return this.extractRowsFromData(filteredData, { groupKeys });
  }

  public lookupDataItemByKey(key: Key): Optional<NestedTreeData<TData>> {
    return this.dataMap.get(key);
  }

  public lookupRowDataByKey(key: Key): TreeDataSlice<TData, Key>[] {
    return this.rowDataMap.get(key) ?? [];
  }

  // ☁️ Abstract / Protected ----------------------------------------- /

  // Properties ------ /

  protected abstract options?: ServerSideRowDataHelperOptions<Key>;

  // Methods ------ /

  protected abstract getGroupKey(data: NestedTreeData<TData>): Key;

  // 🔒 Protected ----------------------------------------- /

  protected set data(data: NestedTreeData<TData>[]) {
    this._data = data;
  }

  protected set filteredData(data: NestedTreeData<TData>[]) {
    this.filteredDataSubject$.next(data);
  }

  protected get flattenOptions(): ServerSideRowDataHelperFlattenOptions<NestedTreeData<TData>, Key> {
    const { separator, convertString, getHid } = this.options ?? {};
    return { getKey: (data) => this.getGroupKey(data), separator, convertString, getHid };
  }

  protected get filterOptions(): ServerSideRowDataHelperFilterOptions<NestedTreeData<TData>> {
    const { separator } = this.options ?? {};
    return { getKey: (data) => this.getGroupKey(data).toString(), separator };
  }

  protected asDataSource<Data extends AnyRecord>(data?: Data[]) {
    return asDataSource(data ?? [], {
      isFetching: this.isFetching,
      error: this.error
    });
  }

  protected filterData(
    data: NestedTreeData<TData>[],
    filterModels?: Maybe<FilterModels<NestedTreeData<TData>>>
  ): NestedTreeData<TData>[] {
    if (!filterModels) return data;
    return applyFilterModels(data, splitColumnFilters(filterModels), this.filterOptions);
  }

  protected toRowData(
    data: NestedTreeData<TData>,
    options?: ToRowDataOptions<Key>
  ): TreeDataSlice<TData, Key> {
    const { parentHierarchy } = options ?? {};
    const { getKey, separator, getHid } = this.flattenOptions;
    return flattenNestedTreeDataNodeToSlice(data, parentHierarchy, { getKey, separator, getHid });
  }

  protected extractRowsFromData(
    data: NestedTreeData<TData>[],
    options?: ExtractRowsFromDataOptions<Key>
  ): TreeDataSlice<TData, Key>[] {
    const groupKeySet = asSet(options?.groupKeys ?? []);
    // Base case
    if (groupKeySet.size === 0) {
      const { parentHierarchy } = options ?? {};
      return data.reduce(
        (rowData, item) => {
          rowData.push(this.toRowData(item, { parentHierarchy }));
          return rowData;
        },
        [] as TreeDataSlice<TData, Key>[]
      );
    }
    return data.reduce(
      (rowData, item) => {
        const groupKey = this.getGroupKey(item);
        const { children } = item;
        if (groupKeySet.has(groupKey.toString()) && children) {
          const parentHierarchy = [...(options?.parentHierarchy ?? []), groupKey];
          const rows = this.extractRowsFromData(children, {
            groupKeys: cloneSet(groupKeySet, { omit: groupKey.toString() }),
            parentHierarchy
          });
          rowData.push(...rows);
          this.rowDataMap.set(groupKey, rows);
        }
        return rowData;
      },
      [] as TreeDataSlice<TData, Key>[]
    );
  }

  protected buildDataMap(data: NestedTreeData<TData>[]): void {
    this.dataMap.clear();
    const processRecursive = (data: NestedTreeData<TData>[]) =>
      data.forEach((item) => {
        const groupKey = this.getGroupKey(item);
        this.dataMap.set(groupKey, item);
        const { children } = item;
        if (children) processRecursive(children);
      });
    processRecursive(data);
  }

  protected initDataSourceObservable$(
    data$: Observable<DataSourceCommon<NestedTreeData<TData>>>
  ): Observable<DataSourceResult<NestedTreeData<TData>>> {
    return data$.pipe(
      tap(({ isFetching, error }) => {
        this.isFetchingSubject$.next(isFetching);
        this.errorSubject$.next(error);
      }),
      tap(({ results: data = [] }) => {
        this._data = data;
        this.filteredDataSubject$.next(data);
        this.buildDataMap(data);
      }),
      toDataSourceResult(),
      share()
    );
  }
}

export default ServerSideRowDataHelper;
