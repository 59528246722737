import { useEffect, useRef, forwardRef } from 'react';
import clsx from 'clsx';
import * as styles from './css/checkbox.css';
import type { CheckboxVariants } from './css/checkbox.css';
import { inputOffsetOutline } from '../../system/styles.css';
import { useForkRef } from '../../system/hooks/use-fork-ref';
import { __DEV__ } from '../../system/utils/assertion';
import { dataAttr } from '../../system/utils/dom';
import { FormControlProps, useFormControl } from '../form-control/form-control';
import { Box } from '../../system/components/box/box';

type OmittedProps = 'isRequired' | 'as' | 'value';

export type CheckboxProps = {
  /** Makes checkbox/radio indeterminate */
  isIndeterminate?: boolean;
  /**
   * If `true`, the checkbox/radio will be initially checked.
   */
  isDefaultChecked?: boolean;
  /**
   * If `true`, the checkbox/radio will be checked.
   * You'll need to pass `onChange` to update it's value (since it's now controlled)
   */
  isChecked?: boolean;
  /** Checkbox/radio id */
  id?: string;
  /** Checkbox/radio name */
  name?: string;
  /** Checkbox/radio value */
  value?: string | number;
  /**
   * A11y: A label that describes the input
   */
  'aria-label'?: string;
  /**
   * A11y: The id of the element that describes the input
   */
  'aria-describedby'?: string;
  /**
   * A11y: Refers to the id of the element that labels the checkbox element.
   */
  'aria-labelledby'?: string;
  /**
   * The children is the label to be displayed to the right of the checkbox.
   */
  children?: React.ReactNode;
  /**
   * The callback invoked when the checked state of the `Checkbox` changes..
   */
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  /**
   * Order of tabbing index
   */
  tabIndex?: number;
} & CheckboxVariants &
  Omit<FormControlProps, OmittedProps> &
  React.InputHTMLAttributes<HTMLInputElement>;

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const {
    id,
    name,
    value,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    'aria-describedby': ariaDescribedby,
    isDefaultChecked,
    isChecked: _isChecked,
    checked,
    size = 'sm',
    onChange,
    isIndeterminate,
    children,
    className,
    tabIndex,
    isInvalid: _isInvalid,
    isReadOnly: _isReadOnly,
    sx = {},
    ...rest
  } = props;

  const isChecked = Boolean(_isChecked) || Boolean(checked);
  const { isDisabled, isInvalid, isReadOnly } = useFormControl(props);

  const ownRef = useRef();
  const _ref = useForkRef(ownRef, ref);

  useEffect(() => {
    // @ts-ignore
    if (_ref.current) {
      // @ts-ignore
      _ref.current.indeterminate = Boolean(indeterminate);
    }
  }, [isIndeterminate, _ref]);

  return (
    <Box
      as="label"
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        verticalAlign: 'top',
        width: 'max-content',
        ...sx
      }}
      className={clsx(isDisabled && styles.checkboxLabelDisabled, className)}
      {...rest}
    >
      <Box
        as="input"
        type="checkbox"
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        aria-describedby={ariaDescribedby}
        id={id}
        ref={_ref}
        name={name}
        tabIndex={tabIndex}
        value={value}
        onChange={isReadOnly ? undefined : onChange}
        defaultChecked={isReadOnly ? undefined : isDefaultChecked}
        checked={isReadOnly ? Boolean(isChecked) : isDefaultChecked ? undefined : isChecked}
        disabled={isDisabled}
        aria-disabled={dataAttr(isDisabled)}
        readOnly={isReadOnly}
        aria-readonly={dataAttr(isReadOnly)}
        aria-invalid={dataAttr(isInvalid)}
        aria-checked={isIndeterminate ? 'mixed' : isChecked}
        className={clsx('check-input', styles.checkboxRecipe({ size }), inputOffsetOutline)}
      />
      {children && <span className={clsx(styles.checkboxLabelRecipe({ size }))}>{children}</span>}
    </Box>
  );
});
if (__DEV__) {
  Checkbox.displayName = 'Checkbox';
}
