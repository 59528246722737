import type {
  DefaultNotificationName,
  InvestorAccount,
  NotificationSetting,
  NotificationSettingValue
} from '@oms/generated/frontend';
import type {
  DefaultNotificationSettingsFragment,
  CustomNotificationSettingsFragment,
  FactoryDefaultNotificationSettings
} from '@oms/generated/frontend';
import type { Prettify, Optional } from '@oms/ui-util';

export const NotificationSettingsType = {
  DEFAULT: 'default',
  CUSTOM: 'custom'
} as const;

export type NotificationSettingsTypeValue =
  (typeof NotificationSettingsType)[keyof typeof NotificationSettingsType];

export type OverrideFlagsToBoolean<T> = Prettify<
  Omit<T, 'isPopup' | 'isShown' | 'isSound' | '__typename'> & {
    isPopup: boolean;
    isShown: boolean;
    isSound: boolean;
  }
>;

export type OverrideFlagsToEnum<T> = Prettify<
  Omit<T, 'isPopup' | 'isShown' | 'isSound' | '__typename'> & {
    isPopup: NotificationSettingValue;
    isShown: NotificationSettingValue;
    isSound: NotificationSettingValue;
  }
>;

type FacetsMeta = {
  isPopupEnabled?: boolean;
  isShownEnabled?: boolean;
  isSoundEnabled?: boolean;
};

export type DefaultNotificationSettingsFragmentWithId = DefaultNotificationSettingsFragment & { id: string };
export type CustomNotificationSettingsFragmentWithData = CustomNotificationSettingsFragment & {
  matchedAccountsData: InvestorAccount[];
};

export type NotificationSettingsData =
  | DefaultNotificationSettingsFragmentWithId
  | CustomNotificationSettingsFragment;

export type MappedDefaultNotificationSettings = Prettify<
  OverrideFlagsToBoolean<DefaultNotificationSettingsFragmentWithId> & FacetsMeta
>;
export type MappedCustomNotificationSettings = Prettify<
  OverrideFlagsToBoolean<CustomNotificationSettingsFragmentWithData>
>;
export type MappedUpsertNotificationSettings = Prettify<
  Pick<
    MappedCustomNotificationSettings,
    'notificationName' | 'description' | 'matchedAccounts' | 'isPopup' | 'isShown' | 'isSound'
  > & { id?: string }
>;
export type MappedFactoryDefaultNotificationSettings = Prettify<
  OverrideFlagsToBoolean<FactoryDefaultNotificationSettings>
>;

export type NotificationSettingsBase = Prettify<{
  id?: string;
  notificationName: DefaultNotificationName;
  // TODO: Check with BE if we can simplify this
  isPopup: NotificationSettingValue | NotificationSetting;
  isShown: NotificationSettingValue | NotificationSetting;
  isSound: NotificationSettingValue | NotificationSetting;
}>;

export type MappedNotificationSettingsBase = Prettify<
  OverrideFlagsToBoolean<NotificationSettingsBase> & Optional<FacetsMeta>
>;

export type CommonNotificationSettings = {
  id: string;
  notificationName: DefaultNotificationName;
  isPopup: boolean;
  isShown: boolean;
  isSound: boolean;
  isPopupEnabled?: boolean;
  isShownEnabled?: boolean;
  isSoundEnabled?: boolean;
  description?: string;
  matchedAccounts?: (string | null | undefined)[];
  matchedAccountsData?: { id: string; name: string }[];
};

export type MappedCommonNotificationSettings = Prettify<OverrideFlagsToBoolean<CommonNotificationSettings>>;
