import { firstValueFrom } from 'rxjs';
import type { Observable } from 'rxjs';
import type {
  ExecutionVenueFragment,
  GetExecutionVenuesForUserQuery,
  GetExecutionVenuesForUserQueryVariables,
  GetExecutionVenuesQuery,
  GetExecutionVenuesQueryVariables
} from '@oms/generated/frontend';
import { GetExecutionVenuesDocument, GetExecutionVenuesForUserDocument } from '@oms/generated/frontend';
import { inject, Lifecycle, scoped } from 'tsyringe';
import { cleanMaybe, compactMap, logger } from '@oms/ui-util';
import { toGqlDatasource } from '@oms/frontend-foundation';
import type { DataSourceCommon, ICrudService } from '@oms/frontend-foundation';
import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import type { FetchPolicy } from '@apollo/client';

@scoped(Lifecycle.ContainerScoped)
export class VenuesService implements ICrudService<ExecutionVenueFragment> {
  protected _apolloClient: RxApolloClient;

  protected name: string = 'VenuesService';
  protected logger: typeof logger.debug;

  protected fetchPolicy: FetchPolicy = 'cache-first';

  // 🏗️ Constructor ------------------------------------------------------- /

  constructor(@inject(RxApolloClient) apolloClient: RxApolloClient) {
    this._apolloClient = apolloClient;
    this.logger = logger.as(this.name);
  }

  // 🔍 Venue Queries --------------------------------------------------------- /

  public watchAll$ = (): Observable<DataSourceCommon<ExecutionVenueFragment>> =>
    this.watchAllAvailableVenues$();

  public watchAllAvailableVenues$ = (): Observable<DataSourceCommon<ExecutionVenueFragment>> => {
    const result = this._apolloClient.rxWatchQuery<GetExecutionVenuesQuery, GetExecutionVenuesQueryVariables>(
      {
        query: GetExecutionVenuesDocument,
        fetchPolicy: this.fetchPolicy
      }
    );

    return result.pipe(
      toGqlDatasource(({ executionVenues }) =>
        compactMap(cleanMaybe(executionVenues?.edges, []), ({ node: venue }) => venue)
      )
    );
  };

  public getAvailableVenueById = async (id: string): Promise<ExecutionVenueFragment | null> => {
    try {
      const venues = await firstValueFrom(this.watchAllAvailableVenues$());
      return venues.results?.find((venue) => venue.id === id) || null;
    } catch (error) {
      this.logger.error('Error fetching venue by id', error);
      return null;
    }
  };

  public watchAllVenuesForUser$ = (userId?: string): Observable<DataSourceCommon<ExecutionVenueFragment>> => {
    const result = this._apolloClient.rxWatchQuery<
      GetExecutionVenuesForUserQuery,
      GetExecutionVenuesForUserQueryVariables
    >({
      query: GetExecutionVenuesForUserDocument,
      variables: {
        userId
      },
      fetchPolicy: this.fetchPolicy
    });

    return result.pipe(
      toGqlDatasource(({ getUserAccess }) =>
        compactMap(cleanMaybe(getUserAccess?.executionVenues, []), (venue) => venue)
      )
    );
  };

  public getVenueForUserById = async (
    id: string,
    userId?: string
  ): Promise<ExecutionVenueFragment | null> => {
    try {
      const venues = await firstValueFrom(this.watchAllVenuesForUser$(userId));
      return venues.results?.find((venue) => venue.id === id) || null;
    } catch (error) {
      this.logger.error('Error fetching venues for user by id', error);
      return null;
    }
  };
}

export default VenuesService;
