import { useAppStateStream } from '@app/data-access/memory/app.stream';
import {
  Box,
  Button,
  Center,
  ExclamationTriangleIcon,
  Text,
  Flex,
  Icon,
  Stack,
  ButtonProps
} from '@oms/ui-design-system';
import { APP_STATE_TYPE } from '@app/common/app/app.contracts';
import type { ValstroEntitlement } from '@app/common/auth/keycloak.types';
import { useUserRoles } from '@app/data-access/services/system/auth/auth.hooks';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useWorkspaceContainer } from '@oms/frontend-foundation';
import { GraphqlReconnectSignal } from '@app/data-access/memory/graphql.reconnect.signal';

const ChildComponent = ({ children }: { children: React.ReactNode }) => {
  return (
    <Flex
      sx={{
        position: 'absolute',
        inset: 0
      }}
      direction="column"
    >
      {children}
    </Flex>
  );
};

export const AppStateWrapper = ({
  children,
  isPublic = false,
  requiredEntitlements = []
}: {
  children: React.ReactNode;
  isPublic?: boolean;
  requiredEntitlements?: ValstroEntitlement[];
}) => {
  const canAccess = useUserRoles();
  const isUserAuthorized = isPublic ? true : canAccess(requiredEntitlements);
  const { state, updater } = useAppStateStream();

  switch (state) {
    case APP_STATE_TYPE.IDLE:
      return null;
    case APP_STATE_TYPE.CHECKING_FOR_UPDATES:
      return <Center>Checking for updates...</Center>;
    case APP_STATE_TYPE.UPDATING:
      return updater.updateErrorMessage ? (
        <Center>Updating failed: {updater.updateErrorMessage}</Center>
      ) : (
        <Center>Updating...</Center>
      );
    case APP_STATE_TYPE.AUTHENTICATING:
      return <Center>Authenticating...</Center>;
    case APP_STATE_TYPE.UNAUTHORIZED:
      return isPublic ? <ChildComponent>{children}</ChildComponent> : <Center>Unauthorized</Center>;
    case APP_STATE_TYPE.DATA_ACCESS_CONNECTING:
      return <Center>Connecting...</Center>;
    case APP_STATE_TYPE.DATA_ACCESS_DISCONNECTED:
      return <ConnectionLoss />;
    case APP_STATE_TYPE.SYNCRONISING:
      return <Center>Syncronising user configuration...</Center>;
    case APP_STATE_TYPE.READY:
      switch (true) {
        case !isUserAuthorized:
          return <Center>Unauthorized</Center>;
        default:
          return <ChildComponent>{children}</ChildComponent>;
      }
    default:
      return null;
  }
};

const ConnectionLoss: FC = () => {
  const workspaceContainer = useWorkspaceContainer();
  const reconnectSignal = useMemo(
    () => workspaceContainer.resolve(GraphqlReconnectSignal),
    [workspaceContainer]
  );
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const onRetry: ButtonProps['onClick'] = useCallback(() => {
    reconnectSignal.reconnect();
  }, [reconnectSignal]);
  const [isRetrying, setIsRetrying] = useState<boolean>(false);

  useEffect(() => {
    const sub = reconnectSignal.$.subscribe(() => {
      setIsRetrying(true);
      timeoutRef.current && clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setIsRetrying(false);
      }, 1_000);
    });

    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
      sub.unsubscribe();
    };
  }, [reconnectSignal]);

  return (
    <Center fillArea={true}>
      <Stack direction="vertical" align={'center'}>
        <Box sx={{ backgroundColor: 'layout.level2', borderRadius: 'full', padding: 4 }}>
          <Icon
            as={ExclamationTriangleIcon}
            label="interrupted connection info"
            sx={{
              fontSize: 'h5',
              color: 'border.error',
              backgroundColor: 'layout.level2',
              borderRadius: 'md'
            }}
          />
        </Box>
        <Text type={'mediumR'} sx={{ color: 'text.tertiary' }} style={{ margin: 24 }}>
          Something bad happened and your request was not completed. Try again or let your IT administrator
          know.
        </Text>
        <Flex direction={'row'} align={'center'} grow={1} sx={{ width: 'full' }} justify={'center'}>
          <Button
            isDisabled={isRetrying}
            variant="primary"
            size="md"
            palette={'standard'}
            style={{ width: '50%' }}
            onClick={onRetry}
          >
            Retry
          </Button>
        </Flex>
      </Stack>
    </Center>
  );
};
