import type { ComponentType } from 'react';
import { withUpdatedData } from './with-updated-data';
import type { UpdatedData, UpdatedDataProps } from './with-updated-data';
import { TsExecutionsWithFilterDocument } from '@oms/generated/frontend';
import type { ExecutionRow } from '@oms/generated/frontend';
import {
  ExecutionsSubscriptionMap,
  ExecutionsSubcriptionKeys
} from '@app/widgets/trading/execution-monitor/executions.widget';

export type UpdatedExecution = UpdatedData<ExecutionRow>;

export const withUpdatedExecution = (
  Component: ComponentType<UpdatedExecution>,
  props: UpdatedDataProps = {}
) => {
  return withUpdatedData<ExecutionsSubcriptionKeys, ExecutionsSubscriptionMap, ExecutionRow>(Component, {
    id: props.idProp || 'id',
    sx: props.sx,
    config: {
      executions: {
        query: TsExecutionsWithFilterDocument,
        getData: (r) => r.tsExecutionsWithFilter
      }
    },
    result(response) {
      return (response.rows || [])?.[0] || {};
    },
    queryOpts(id) {
      return {
        filter: {
          id: {
            filterType: 'text',
            type: 'contains',
            filter: id
          }
        },
        limit: 1,
        offset: 0
      };
    }
  });
};
