import { ROW_SELECTION_COUNT_ACTION_TYPE, useVGrid, VGrid } from '@oms/frontend-vgrid';
import type { GridBuilder } from '@oms/frontend-vgrid';
import { buildInvestorOrderViewTradingOrdersColumnDefs } from './execution-view.investor-orders.columns';
import { GridConfigEventHandler } from '@app/data-access/services/system/grids/grid-config.event-handler';
import { IOHasExecutedQuantity, IONoExecutedQuantity, IOPending } from '../utils/row-state-rule-utils';
import { TsMappedInvestorOrdersWithFilterDocument } from '@oms/generated/frontend';
import type {
  MappedInvestorOrderRow,
  TsMappedInvestorOrdersWithFilterSubscription
} from '@oms/generated/frontend';
import { createViewInvestorOrderAction } from '../investor-order-view/commands/view-investor-order/view-investor-order.action';
import { ExecutionViewLayoutComponentProps } from './execution-view.layout.config';
import { refreshServersideCustomMenuAction } from '@app/common/grids/actions/refresh-serverside.action';
import { withTableServer } from '@app/data-access/services/system/table-server/with.table-server.component';

type ExecutionViewSubscriptionKeys = 'execution_views';

type ExecutionViewSubscriptionMap = {
  execution_views: {
    subscription: TsMappedInvestorOrdersWithFilterSubscription;
    datasource: MappedInvestorOrderRow;
  };
};

export const ExecutionViewInvestorOrderGrid = withTableServer<
  ExecutionViewSubscriptionKeys,
  ExecutionViewSubscriptionMap,
  ExecutionViewLayoutComponentProps
>(
  ({ datasources, props: compProps }) => {
    const { allocationMapId } = compProps;

    const props = useVGrid(
      'execution-view-investor-orders',
      (b: GridBuilder<MappedInvestorOrderRow>) =>
        b
          .tableServerColumnLibrary(buildInvestorOrderViewTradingOrdersColumnDefs())
          .datasource((d) =>
            d
              .source(
                datasources.execution_views.asServerSideRowModel({
                  filter: {
                    allocationMapId: {
                      filterType: 'text',
                      type: 'equals',
                      filter: allocationMapId
                    }
                  }
                })
              )
              .rowId((r) => r.data.allocationMapId)
              .cacheBlockSize(100)
          )
          .injectEvents([GridConfigEventHandler])
          .rowStateRules({
            pending: (params) => IOPending(params.data),
            noExecutedQuantity: (params) => IONoExecutedQuantity(params.data),
            hasExecutedQuantity: (params) => IOHasExecutedQuantity(params.data)
          })
          .sideBar()
          .rowSelection((c) => c.multiple())
          .actions((a) =>
            a.schema((s) =>
              s
                .action(createViewInvestorOrderAction('context-menu'))
                .action(createViewInvestorOrderAction('configurable'))
                .action(ROW_SELECTION_COUNT_ACTION_TYPE)
                .action(refreshServersideCustomMenuAction)
            )
          )
          .reactiveCustomComponents(),
      [allocationMapId, datasources]
    );
    return <VGrid {...props} />;
  },
  {
    execution_views: {
      query: TsMappedInvestorOrdersWithFilterDocument,
      getData: (r) => {
        return r.tsMappedInvestorOrdersWithFilter;
      }
    }
  }
);

export default ExecutionViewInvestorOrderGrid;
