import {
  Box,
  Heading,
  Flex,
  VStack,
  Text,
  type BoxProps,
  type FormatType,
  format,
  Collapsible
} from '@oms/ui-design-system';
import { type AnyRecord } from '../../../common/type.helpers';
import { convertStringToNumber, FormatLargeNumber, isDefined, isNumeric } from '@oms/ui-util';
import type { AnyKey, Labeled, Prettify, Titled } from '@oms/ui-util';
import { useCallback } from 'react';

export type SummaryGridHeading<HeadingLabel extends string = string> = Partial<Labeled<HeadingLabel>>;

export type SummaryGridField<FieldName extends AnyKey = string> = Prettify<Labeled & {
  fieldName: FieldName;
  format?: FormatType;
  className?: string;
  hidden?: boolean;
}>;

export type SummaryGridSx = {
  heading?: BoxProps['sx'];
  row?: BoxProps['sx'];
} | null;

export type SummaryGridNumberFormatOptions = {
  decimalPlaces?: number;
  trim?: boolean;
  useParentheses?: boolean;
};

export type SummaryGridProps<
  FieldData extends AnyRecord = AnyRecord,
  FieldName extends AnyKey = string,
  HeadingLabel extends string = string
> = Partial<Titled> & {
  headings?: SummaryGridHeading<HeadingLabel>[];
  fields: SummaryGridField<FieldName>[];
  data: FieldData[];
  sx?: SummaryGridSx[];
  numberFormatOptions?: SummaryGridNumberFormatOptions;
  background?: boolean;
  testId?: string;
  collapsible?: boolean;
};

const borderStyle = {
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
  borderColor: 'border.minimal'
} as Record<string, string>;

export function SummaryGrid<
  FieldData extends AnyRecord = AnyRecord,
  FieldName extends AnyKey = string,
  HeadingLabel extends string = string
>(props: SummaryGridProps<FieldData, FieldName, HeadingLabel>) {
  const { title, background, testId, collapsible = true } = props;

  return (
    <Box sx={{ backgroundColor: background ? 'layout.level3' : undefined }} data-testid={testId}>
      {collapsible && (
        <Collapsible
          label={title}
          showDivider={true}
          forceMount={true}
          defaultOpen={true}
          headerSx={{ paddingLeft: 0, paddingRight: 0 }}
          contentSx={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <SummaryGridBody {...props} />
        </Collapsible>
      )}

      {!collapsible && (
        <>
          {title && (
            <Heading
              sx={{
                color: 'text.semiMinor',

                paddingBottom: 2,
                marginBottom: 5,
                ...(!background && borderStyle)
              }}
            >
              {title}
            </Heading>
          )}
          <SummaryGridBody {...props} />
        </>
      )}
    </Box>
  );
}

export function SummaryGridBody<
  FieldData extends AnyRecord = AnyRecord,
  FieldName extends AnyKey = string,
  HeadingLabel extends string = string
>(props: SummaryGridProps<FieldData, FieldName, HeadingLabel>) {
  const { fields, headings = [], data, sx = [], numberFormatOptions = {}, background } = props;
  const { decimalPlaces = 2, trim = false, useParentheses = false } = numberFormatOptions;

  const getDisplayValue = useCallback((fieldValues: FieldData, fieldName: keyof FieldData, formatType?: FormatType) => {
    const value = fieldValues[fieldName];

    if (typeof value === 'undefined' || value === null) {
      return '';
    }

    if (formatType && isDefined(value)) {
      return format(formatType, value);
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        return (value as any[]).map((v) => String(v)).join(', ');
      } else {
        // this is because the autocomplete field value can be an object
        return String(Object.values(value)[0]);
      }
    }

    if (isNumeric(value)) {
      return FormatLargeNumber(convertStringToNumber(value), decimalPlaces, trim, useParentheses);
    }

    return String(value);
  }, [decimalPlaces, trim, useParentheses]);

  return (
    <>
      <Flex
        sx={{
          ...(headings.length && {
            paddingBottom: 2,
            marginBottom: 2,
            ...(!background && borderStyle)
          })
        }}
      >
        {headings &&
          headings.map(({ label }, index) => (
            <Box
              sx={{
                flex: 1,
                textAlign: index === 0 ? 'left' : 'right',
                ...sx[index]?.row,
                ...sx[index]?.heading
              }}
              key={label}
              data-column-name={label}
            >
              {label}
            </Box>
          ))}
      </Flex>
      <VStack spacing={1}>
        {fields?.map((field) => {
          if (field.hidden) return null;
          return (
            <Flex key={field.fieldName.toString()}>
              <Text
                sx={{ flex: 1, color: 'text.semiMinor', ...sx[0]?.row, marginBottom: 1 }}
                data-field-name={field.fieldName}
              >
                {field.label}
              </Text>

              {data.map((item, index) => {
                const displayValue = getDisplayValue(item, field.fieldName.toString(), field.format);
                return (
                  <Text
                    className={field.className}
                    sx={{ flex: 1, textAlign: 'right', color: 'white', ...sx[index + 1]?.row }}
                    isTruncated
                    data-sidebar-name={field.fieldName}
                    key={headings[index + 1]?.label + field.fieldName.toString()}
                    title={displayValue}
                  >
                    {displayValue}
                  </Text>
                );
              })}
            </Flex>
          );
        })}
      </VStack>
    </>
  );
}
