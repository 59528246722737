import type {
  ComboBoxDefaultValue,
  ComboBoxItem,
  ComboBoxItemUnion,
  ComboBoxGroupItem
} from '../combo-box.types';

export interface ComboboxItemSearchOptions<T extends ComboBoxDefaultValue = any> {
  item: ComboBoxItem<T>;
  comparator?: (item: ComboBoxItem<T>, val?: ComboBoxItem<T>) => boolean;
}

export function findComboBoxItem<T extends ComboBoxDefaultValue = any>(
  items: ComboBoxItemUnion<T>[],
  { comparator = (i, v) => !!v && (i.id === v.id || i.label === v.label), item }: ComboboxItemSearchOptions<T>
): ComboBoxItem<T> | undefined {
  const queue = structuredClone(items);

  while (queue.length) {
    const curr = queue.pop() as ComboBoxItemUnion<T>;

    switch (curr.type) {
      case 'item': {
        const currItem = curr as ComboBoxItem<T>;
        if (comparator(currItem, item)) {
          return currItem;
        }
        break;
      }
      case 'tab':
      case 'group': {
        const grp = curr as ComboBoxGroupItem<T>;
        queue.push(...grp.items);
        break;
      }
    }
  }

  return undefined;
}

export default findComboBoxItem;
