/* eslint-disable react/jsx-no-bind */
import { action } from '@storybook/addon-actions';
import React, { type FunctionComponent, type ReactElement, useEffect, useState } from 'react';
import {
  FormRenderer,
  useFormApi,
  type FormTemplateRenderProps,
  type ValidatorMapper,
  type ComponentMapper,
  type Schema,
  type ActionMapper,
  type FormRendererProps
} from '@data-driven-forms/react-form-renderer';
import { Button, Box, Heading, type Sprinkles } from '@oms/ui-design-system';
import { FORM_COMPONENT_MAPPER, type FormFieldUnion } from './mappers/ddf-mapper';
import { FORM_ERROR, type SubmissionErrors } from 'final-form';
import { isEqual } from 'lodash';
import { FORM_COMPONENT_TYPE } from './contracts';

const FormTemplate: FunctionComponent<FormTemplateRenderProps & { sx?: Sprinkles }> = React.memo(
  ({ formFields, formStyle = {}, sx = {} }) => {
    const { handleSubmit, reset, subscribe } = useFormApi();
    const [errors, setErrors] = useState<string[] | undefined>();

    useEffect(() => {
      const unsub = subscribe(
        (s) => {
          const formError =
            (s.errors && s.errors[FORM_ERROR]) || (s.submitErrors && s.submitErrors[FORM_ERROR]);
          if (formError) {
            setErrors(Array.isArray(formError) ? formError : formError.split(','));
          } else {
            setErrors(undefined);
          }
        },
        { submitErrors: true, errors: true }
      );

      return () => {
        unsub();
      };
    }, [subscribe]);

    return (
      <form
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit()?.catch((e) => {
            console.error(e);
          });
        }}
        onReset={() => {
          setErrors(undefined);
          reset();
        }}
      >
        {errors && (
          <Box
            sx={{
              color: 'Red.400',
              padding: 3,
              bgColor: 'Red.900',
              fontWeight: 'baseB',
              position: 'relative',
              width: 'full',
              opacity: 0.9,
              marginBottom: 2,
              flexDirection: 'column',
              flexGrow: 1
            }}
            style={{ zIndex: 1 }}
          >
            {errors.map((e) => (
              <Box sx={{ flexDirection: 'row', flexGrow: 1 }}>{e}</Box>
            ))}
          </Box>
        )}
        <Box
          style={
            {
              position: 'relative',
              ...formStyle
            } as React.CSSProperties
          }
          sx={sx}
        >
          {formFields as unknown as ReactElement[]}
        </Box>
        <Button type="submit">Submit</Button>
        <Button type="button" variant="secondary" sx={{ ml: 3 }} onClick={() => reset()}>
          Reset
        </Button>
      </form>
    );
  },
  isEqual
);

export type IExtractedFieldByType<T extends FormFieldUnion, K extends string> = Extract<T, { component: K }>;

export type StoryField<K extends string> = IExtractedFieldByType<FormFieldUnion, K>;

export interface DDFStoryProps<TCompType extends string> {
  fields: StoryField<TCompType>[];
  validatorMapper?: ValidatorMapper;
  componentMapper?: ComponentMapper;
  actionMapper?: ActionMapper;
  componentTypes?: Record<string, any>;
  containerStyle?: React.CSSProperties;
  formStyle?: React.CSSProperties;
  sx?: Sprinkles;
  initialValues?: Record<string, any>;
  onSubmit?: FormRendererProps['onSubmit'];
  validate?: (formValues: Record<string, any>) => Promise<SubmissionErrors> | SubmissionErrors | undefined;
}

function getKeyByValue<T extends Record<string, any>>(object: T, value: string) {
  return Object.keys(object).find((key) => object[key] === value);
}

export const DDFStory: FunctionComponent<DDFStoryProps<string>> = ({
  fields = [],
  validatorMapper,
  containerStyle = {},
  componentMapper = {},
  componentTypes = FORM_COMPONENT_TYPE,
  actionMapper = {},
  validate,
  initialValues,
  onSubmit
}) => {
  const [formResult, setFormResult] = useState({});

  // Commented this out and passed the FormTemplate directly to the FormRenderer,
  // because returning in a function causes the template component to unmount and mound again
  // which resets the state of the field
  // const Template = useCallback(
  //   (props) => {
  //     return <FormTemplate {...props} formStyle={formStyle} sx={sx} />;
  //   },
  //   [formStyle, sx]
  // );

  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: 'layout.level2',
        borderRadius: 'base',
        padding: 6
      }}
      style={{ maxWidth: '600px', ...containerStyle }}
    >
      <Heading type="mediumB" sx={{ marginBottom: 6 }}>
        DDF Field:{' '}
        <pre>
          &#123; component: componentTypes.{getKeyByValue(componentTypes, fields?.[0]?.component)}, ... &#125;
        </pre>
      </Heading>
      <FormRenderer
        validate={validate}
        schema={
          {
            fields
          } as Schema
        }
        componentMapper={{ ...FORM_COMPONENT_MAPPER, ...componentMapper }}
        FormTemplate={FormTemplate}
        onSubmit={async (values, form) => {
          const result = await onSubmit?.(values, form);
          if (typeof result === 'object' && Object.keys(result).length) {
            return result;
          }
          action('submit')(values);
          setFormResult(values);
        }}
        validatorMapper={validatorMapper}
        actionMapper={actionMapper}
        initialValues={initialValues}
        subscription={{ values: true }}
      />
      <br />
      <strong>Form result: </strong>
      <pre>
        {formResult &&
          Object.entries(formResult).map(([fieldKey, fieldValue], index) => (
            <div key={index}>
              {fieldKey}:{' '}
              {typeof fieldValue === 'object' && !!fieldValue
                ? Object.entries(fieldValue).map(([valKey, valVal], i) => (
                    <>
                      <span>
                        {valKey}: {`${valVal}`}
                      </span>
                      {i !== Object.entries(fieldValue).length - 1 && ', '}
                    </>
                  ))
                : JSON.stringify(fieldValue)}
            </div>
          ))}
      </pre>
      <br />
      <strong>Form result (stringified): </strong>
      <pre data-testid="form-result-json" className="form-result-json">
        {formResult && JSON.stringify(formResult, null, 2)}
      </pre>
      <br />
      <br />
    </Box>
  );
};
