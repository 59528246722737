import { useVGrid, VGrid } from '@oms/frontend-vgrid';
import { buildInstrumentsGridColmuns } from './instument-restrictions.columns';
import {
  JoinedInstrumentRestrictionRow,
  TableServerJoinedInstrumentRestriction,
  TsJoinedInstrumentRestrictionsWithFilterDocument,
  TsJoinedInstrumentRestrictionsWithFilterSubscription
} from '@oms/generated/frontend';
import { openEntryInstrumentRestriction } from './grid-actions/open-entry.instrument-restriction';
import { openModifyInstrumentRestriction } from './grid-actions/open-modify.instrument-restriction';
import { withTableServer } from '@app/data-access/services/system/table-server/with.table-server.component';

type InstrumentRestrictionsSubscriptionKeys = 'instrument_restrictions';

type InstrumentRestrictionsSubscriptionMap = {
  instrument_restrictions: {
    datasource: TableServerJoinedInstrumentRestriction;
    subscription: TsJoinedInstrumentRestrictionsWithFilterSubscription;
  };
};

export const InstrumentsGridWidgetComponent = withTableServer<
  InstrumentRestrictionsSubscriptionKeys,
  InstrumentRestrictionsSubscriptionMap
>(
  ({ datasources }) => {
    const gridProps = useVGrid<JoinedInstrumentRestrictionRow>(
      'instrument_restrictions',
      (builder) =>
        builder
          .tableServerColumnLibrary(buildInstrumentsGridColmuns())
          .rowSelection((c) => c.multiple())
          .datasource((d) =>
            d
              .source(datasources.instrument_restrictions.asServerSideRowModel())
              .rowId((r) => r.data.id)
              .cacheBlockSize(100)
          )
          .actions((a) =>
            a.schema((s) => s.action(openEntryInstrumentRestriction).action(openModifyInstrumentRestriction))
          )
          .sideBar()
          .reactiveCustomComponents(),
      [buildInstrumentsGridColmuns, datasources]
    );

    return <VGrid {...gridProps} />;
  },
  {
    instrument_restrictions: {
      query: TsJoinedInstrumentRestrictionsWithFilterDocument,
      getData: (r) => r.tsJoinedInstrumentRestrictionsWithFilter
    }
  }
);

export default InstrumentsGridWidgetComponent;
