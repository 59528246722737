import React from 'react';
import { useFormApi } from '@data-driven-forms/react-form-renderer';
import { Collapsible as CollapsibleComponent, type CollapsibleProps } from '@oms/ui-design-system';
import { useFieldApi } from '../../helpers';
import type { FieldProps, ICommonField, Field } from '../../types';
import { type FORM_COMPONENT_TYPE } from '../../contracts';

export interface ICollapsibleField extends ICommonField<typeof FORM_COMPONENT_TYPE.COLLAPSIBLE> {
  fields: Field[][];
  direction?: CollapsibleProps['direction'];
}

export const Collapsible: React.FC<FieldProps<ICollapsibleField>> = (props) => {
  const { renderForm } = useFormApi();
  const { fields, label, direction, defaultOpen } = useFieldApi<ICollapsibleField>(props);

  return (
    <CollapsibleComponent label={label} direction={direction} defaultOpen={defaultOpen}>
      {fields.map((f: Field[], i: number) => (
        <div key={i}>{renderForm(f)}</div>
      ))}
    </CollapsibleComponent>
  );
};
