import clsx from 'clsx';
import type { Optional } from '@oms/ui-util';
import type { ResultDisplayStatus } from '@oms/ui-design-system-css';
import { resultDisplayStyle } from '@oms/ui-design-system-css';
import { RegNmsApplicability } from '@oms/generated/frontend';
import { t } from '@oms/codegen/translations';
import type { TradeThroughDetails } from '@oms/shared/regnms';
import { TRADE_THROUGH_STATUS_LABEL_CLASS_NAME } from './trade-through-obligation-field.contstants';

/**
 * Internal type to represent the status of the trade through obligation
 * @private
 */
type Status = 'yes' | 'no' | 'error' | 'exempt' | 'not-applicable';

/**
 * Determines the trade through status based on provided details and applicability
 *
 * @param data - The trade through details containing flags like tradeThrough and malformed
 * @param applicability - The RegNmsApplicability enum value specifying how regulation applies
 * @returns The resolved status or undefined if no status can be determined
 * @private
 */
function getTradeThroughStatus(
  data: TradeThroughDetails,
  applicability: RegNmsApplicability
): Optional<Status> {
  const { tradeThrough, malformed } = data;
  switch (applicability) {
    case RegNmsApplicability.Applicable:
      switch (tradeThrough) {
        case true:
          return 'yes';
        default:
          return malformed ? 'error' : 'no';
      }
    case RegNmsApplicability.Exempt:
      return 'exempt';
    case RegNmsApplicability.NotApplicable:
    case RegNmsApplicability.Error:
      return 'not-applicable';
    default:
      return undefined;
  }
}

/**
 * Gets the display label for trade through status for localization
 *
 * @param data - The trade through details containing flags like tradeThrough and malformed
 * @param applicability - The RegNmsApplicability enum value specifying how regulation applies
 * @param fallback - The string to return if no status can be determined
 * @returns The localized label for the current trade through status
 */
export function getTradeThroughLabel(
  data: TradeThroughDetails,
  applicability: RegNmsApplicability,
  fallback: string
): string {
  const status = getTradeThroughStatus(data, applicability);
  switch (status) {
    case 'yes':
      return t('app.common.yes');
    case 'no':
      return t('app.common.no');
    case 'error':
      return t('app.common.error');
    case 'exempt':
      return t('app.common.exempt');
    case 'not-applicable':
      return t('app.common.notApplicable', { ns: 'short' });
    default:
      return fallback;
  }
}

/**
 * Maps the trade through status to the appropriate ResultDisplayStatus for UI styling
 *
 * @param status - The internal status representation
 * @returns The ResultDisplayStatus value for UI display or undefined if no special styling needed
 * @private
 */
function getResultDisplayStatus(status?: Status): Optional<ResultDisplayStatus> {
  switch (status) {
    case 'yes':
      return 'success';
    case 'no':
      return undefined;
    case 'error':
      return 'error';
    case 'exempt':
    case 'not-applicable':
      return 'warning';
    case undefined:
      return 'inactive';
  }
}

/**
 * Generates the appropriate CSS class name for styling trade through status
 *
 * @param data - The trade through details containing flags like tradeThrough and malformed
 * @param applicability - The RegNmsApplicability enum value specifying how regulation applies
 * @returns A CSS class name to apply based on the current status
 */
export function getTradeThroughClassName(
  data: TradeThroughDetails,
  applicability: RegNmsApplicability
): string {
  const status = getResultDisplayStatus(getTradeThroughStatus(data, applicability));
  return clsx(TRADE_THROUGH_STATUS_LABEL_CLASS_NAME, resultDisplayStyle({ status }));
}
