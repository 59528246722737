import { Maybe } from '@graphql-tools/utils';
import { isNull, isUndefined } from 'lodash';
import { Timestamped } from '../types/objects/object-interface-types';

type SortDirection = 'ascending' | 'descending';

type CompareFn<T> = NonNullable<Parameters<Array<T>['sort']>[0]>;

type CompareFnCreator<T> = (direction?: SortDirection) => CompareFn<T>;

interface SortingFunctionLibrary {
  object: {
    byTimestamp: CompareFnCreator<Partial<Timestamped>>;
  };
  number: {
    byAbsoluteValue: CompareFnCreator<Maybe<number>>;
  };
}

export const sortingFunction: SortingFunctionLibrary = {
  object: {
    byTimestamp:
      (direction) =>
      ({ timestamp: a = 0 }, { timestamp: b = 0 }) =>
        direction === 'ascending' ? a - b : b - a
  },
  number: {
    byAbsoluteValue: (direction) => (a, b) => {
      if (isNull(a) || isNull(b)) return 0;
      if (isUndefined(a) || isUndefined(b)) return 0;
      switch (direction) {
        case 'ascending':
          return Math.abs(a) - Math.abs(b);
        default:
          return Math.abs(b) - Math.abs(a);
      }
    }
  }
};
