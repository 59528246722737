import type { ComponentType } from 'react';
import { withUpdatedData, useUpdatedData } from './with-updated-data';
import type { UpdatedData, UpdatedDataProps, UseUpdatedDataResult } from './with-updated-data';
import { TsInvestorOrdersWithChargesDocument } from '@app/widgets/trading/investor-order-monitor/investor-order-monitor.contracts';
import type { InvestorOrderWithChargesRow } from '@app/widgets/trading/investor-order-monitor/investor-order-monitor.contracts';
import {
  InvestorOrderSubscriptionKeys,
  InvestorOrderSubscriptionMap
} from '@app/widgets/trading/investor-order-monitor/investor-order-monitor.widget';

export type UpdatedInvestorOrder = UpdatedData<InvestorOrderWithChargesRow>;

export const withUpdatedInvestorOrder = (
  Component: ComponentType<UpdatedInvestorOrder>,
  { idProp, sx }: UpdatedDataProps = {}
) => {
  return withUpdatedData<
    InvestorOrderSubscriptionKeys,
    InvestorOrderSubscriptionMap,
    InvestorOrderWithChargesRow
  >(Component, {
    id: idProp || 'id',
    sx,
    config: {
      investor_order: {
        query: TsInvestorOrdersWithChargesDocument,
        getData: (r) => r.tsInvestorOrdersWithFilter
      }
    },
    result(response) {
      return (response.rows || [])?.[0] || {};
    },
    queryOpts: (ioId) => {
      return {
        filter: {
          id: {
            filterType: 'text',
            type: 'contains',
            filter: ioId
          }
        },
        limit: 1,
        offset: 0
      };
    }
  });
};

export const useUpdatedInvestorOrder = (
  investorOrderId: string | null | undefined
): UseUpdatedDataResult<InvestorOrderWithChargesRow> => {
  const result = useUpdatedData<
    InvestorOrderSubscriptionKeys,
    InvestorOrderSubscriptionMap,
    InvestorOrderWithChargesRow
  >(
    {
      config: {
        investor_order: {
          query: TsInvestorOrdersWithChargesDocument,
          getData: (r) => r.tsInvestorOrdersWithFilter
        }
      },
      result(response) {
        return (response.rows || [])?.[0] || {};
      },
      queryOpts: {
        filter: {
          id: {
            filterType: 'text',
            type: 'contains',
            filter: investorOrderId as unknown as string
          }
        },
        limit: 1,
        offset: 0
      }
    },
    [investorOrderId]
  );

  return { ...result, data: investorOrderId ? result.data : undefined };
};
