import { RulesNestedInput, Combinator } from '@oms/generated/frontend';
import type { RuleGroup } from '@oms/frontend-foundation';
import { RoutingRuleFormValues } from './routing-rule.form-contract';
import { RoutingRule } from './routing-rule.form-common';
import { DestinationType } from '@oms/generated/frontend';

export function isObject(rule: unknown): rule is Record<string, unknown> {
  return typeof rule === 'object' && rule !== null;
}

export function isRoutingRule(rule: unknown): rule is RoutingRule {
  return isObject(rule) && 'fact' in rule && 'operator' in rule && 'value' in rule;
}

export function isRuleGroup(rule: unknown): rule is RuleGroup {
  return isObject(rule) && 'combinator' in rule && 'rules' in rule;
}

export function convertValueToString(value: unknown): string {
  if (value && typeof value === 'object' && 'id' in value) {
    return String(value?.id);
  }
  return String(value ?? '');
}

const combinatorMap: { [key: string]: Combinator } = {
  and: Combinator.And,
  or: Combinator.Or
} as const;

export function convertToRulesInput(input: RuleGroup<RoutingRule>): RulesNestedInput {
  return {
    combinator: combinatorMap[input?.combinator],
    rules: input?.rules?.map((rule) => {
      if (isRoutingRule(rule)) {
        return {
          fact: rule?.fact?.id,
          operator: rule?.operator?.id,
          value: convertValueToString(rule?.value)
        };
      } else if (isRuleGroup(rule)) {
        // Handle nested RuleGroup
        return convertToRulesInput(rule);
      }
      return {};
    })
  };
}

export function getDestinationDetails(formValues: RoutingRuleFormValues) {
  const venueDestinationId = formValues.venue?.id;

  const destinationTypeMap = new Map<string, DestinationType>([
    ['primary-trader', DestinationType.PrimaryTrader],
    ['trader', DestinationType.Trader]
  ]);

  let destinationType: DestinationType | undefined;
  let destinationId: string | undefined;

  if (venueDestinationId) {
    destinationType = destinationTypeMap.get(venueDestinationId) ?? DestinationType.Venue;
  }

  const destinationIdMap = new Map<DestinationType, string | undefined>([
    [DestinationType.Venue, venueDestinationId],
    [DestinationType.Trader, formValues.trader?.id]
  ]);

  if (destinationType) {
    destinationId = destinationIdMap.get(destinationType);
  }

  return { destinationType, destinationId };
}
