import { ValueFormatterFunc } from '@ag-grid-community/core';
import { type FormatType, format } from '@oms/ui-design-system';

export const VALUE_FORMATTERS: Partial<Record<FormatType, ValueFormatterFunc>> = {
  'auto-label-from-snake-case-value': ({ value }) => format('auto-label-from-snake-case-value', value),
  date: ({ value }) => format('date', value),
  datetime: ({ value }) => format('datetime', value),
  'email-address': ({ value }) => format('email-address', value),
  'email-address-strict': ({ value }) => format('email-address-strict', value),
  number: ({ value }) => format('number', value),
  'number-position': ({ value }) => format('number-position', value),
  percentage: ({ value }) => format('percentage', value),
  'phone-number-international': ({ value }) => format('phone-number-international', value),
  'phone-number-national': ({ value }) => format('phone-number-national', value),
  price: ({ value }) => format('price', value),
  'price-position': ({ value }) => format('price-position', value),
  'price-position-based-on-one': ({ value }) => format('price-position-based-on-one', value),
  'price-trade': ({ value }) => format('price-trade', value),
  'decimal-2-4': ({ value }) => format('decimal-2-4', value),
  quantity: ({ value }) => format('quantity', value),
  time: ({ value }) => format('time', value),
  time12: ({ value }) => format('time12', value)
};
