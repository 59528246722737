import type { AppSnapshotDefinition } from '@app/app-config/workspace.config';
import type { RxJsonSchema, RxDocument, RxCollection } from 'rxdb-oms-app';
import { LATEST_VERSION_NUMBER } from './migrations';
import { COMMON_SHAREABLE_RXDB_COLLECTION } from '@oms/frontend-schemas';
import type { CollectionWithSchemaPayload } from '@oms/frontend-schemas';
import { COMMON_RXDB_COLLECTION_WITH_SUB_SCHEMA } from '@oms/frontend-schemas';

export const TEMPORARY_SNAPSHOT_ID = '0000-0000-0000-0000';

export const isTemporarySnapshot = ({ snapshotId }: { snapshotId?: string }): boolean =>
  snapshotId === TEMPORARY_SNAPSHOT_ID;

export const SNAPSHOT_TYPE = {
  AUTO: 'auto',
  CUSTOM: 'custom'
} as const;

export type SnapshotType = (typeof SNAPSHOT_TYPE)[keyof typeof SNAPSHOT_TYPE];

export const snapshotSchema: RxJsonSchema<SnapshotDocType> = {
  title: 'snapshots schema',
  description: 'describes a snapshot',
  version: LATEST_VERSION_NUMBER,
  keyCompression: false,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 128
    },
    type: {
      type: 'string',
      maxLength: 128
    },
    name: {
      type: 'string'
    },
    createdAt: {
      type: 'string'
    },
    lastUpdatedAt: {
      type: 'string'
    },
    actors: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          id: {
            type: 'string'
          },
          type: {
            type: 'string'
          },
          name: {
            type: 'string'
          },
          parentId: {
            type: 'string'
          }
        },
        required: ['id', 'type', 'name']
      }
    },
    ...COMMON_SHAREABLE_RXDB_COLLECTION,
    ...COMMON_RXDB_COLLECTION_WITH_SUB_SCHEMA
  },
  required: ['id', 'name', 'createdAt', 'lastUpdatedAt', 'actors'],
  indexes: ['type']
} as const;

export type SnapshotDocActors = {
  id: string;
  type: string;
  name: string;
  parentId?: string;
};

export type ShareableCollectionType = {
  isShared?: boolean;
  sharedBy?: string;
  sharerName?: string;
};

export type SnapshotDocType = CollectionWithSchemaPayload<AppSnapshotDefinition> & {
  id: string;
  type: SnapshotType;
  name: string;
  createdAt: string;
  lastUpdatedAt: string;
  actors: SnapshotDocActors[];
} & ShareableCollectionType;

export type SnapshotComputedDocType = SnapshotDocType & {
  isActive: boolean;
};

export type SnapshotDocument = RxDocument<SnapshotDocType>;

export type SnapshotCollection = RxCollection<SnapshotDocType>;
