import { BroadcastSubject } from '@oms/rx-broadcast';
import type {
  FIXatdlStrategyFormValues,
  RouteOrderFormValuesChangeEvent,
  StrategyValuesChangeEvent
} from './fixatdl-strategy-field.contracts';
import type { RouteOrderFormValues } from '../route-order.form-contract';
import { mapOrderFieldsToFixRepresentation } from '@oms/ui-util';
import { getStrategyFormState } from '@app/forms/fixatdl/validators/strategy.change.validator';
import {
  forceStrategyFieldChange,
  sanitizeOrderFormValues,
  transformDdfValuesToParams
} from './fixatdl-strategy-field.util';
import type { AnyFormValues } from '@oms/frontend-foundation';
import { BehaviorSubject, filter } from 'rxjs';
import { map } from 'rxjs';
import type { FormOptions } from '@oms/frontend-foundation';
import { strategyErrorsValidator } from '@app/forms/fixatdl/validators/strategy.errors.validator';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useObservableState } from 'observable-hooks';
import { allFields$ } from '@app/forms/form-builder/fields/additional-fields/all-fields-events';
import type { EnhancedFormOptions } from '@oms/frontend-foundation';

// stream definition
export const strategyValuesChange$ = new BroadcastSubject<StrategyValuesChangeEvent>('fixatdl-strategy');

export const routeOrderValuesChange$ = new BroadcastSubject<RouteOrderFormValuesChangeEvent>(
  'route-order-values'
);

export const strategyValidationErrors$ = new BehaviorSubject<string[]>([]);

// stream updates
export const routeOrderValuesChange = (formId: string, values: RouteOrderFormValues) => {
  routeOrderValuesChange$.next({
    payload: values,
    meta: {
      formId
    }
  });
};

export const strategyValuesChanged = (
  formId: string,
  values: AnyFormValues,
  getRouteOrderFormValues?: () => RouteOrderFormValues | undefined
) => {
  const formValues = {
    ...values,
    ...mapOrderFieldsToFixRepresentation(
      (getRouteOrderFormValues?.() as Record<string, string | number>) || {}
    )
  } as FIXatdlStrategyFormValues;

  const { values: fixAtdlValues } = getStrategyFormState(formValues);
  const { parameters, controls } = transformDdfValuesToParams(fixAtdlValues);

  strategyValuesChange$.next({
    payload: {
      strategyParams: parameters,
      strategyControls: controls,
      strategyFormRawValues: values
    },
    meta: {
      formId
    }
  });
};

// data access
export const getStrategyFormValues$ = (formId: string) => {
  return strategyValuesChange$.pipe(
    filter(({ meta }) => meta.formId === formId),
    map(({ payload }) => payload || {})
  );
};

export const getRouteOrderFormValues$ = (formId: string) => {
  return routeOrderValuesChange$.pipe(
    filter(({ meta }) => meta.formId === formId),
    map(({ payload }) => sanitizeOrderFormValues(payload))
  );
};

export const useRouteOrderFormValues = (formId: string) => {
  const memoizedValues$ = useMemo(() => getRouteOrderFormValues$(formId), [formId]);
  const routeOrderFormValues = useObservableState(memoizedValues$);

  const routeOrderFormValuesRef = useRef<RouteOrderFormValues | undefined>({});
  routeOrderFormValuesRef.current = routeOrderFormValues;

  const getRouteOrderFormValues = useCallback(() => {
    return routeOrderFormValuesRef.current;
  }, []);

  return getRouteOrderFormValues;
};

export const useStrategyFormValues = (formId: string) => {
  const memoizedValues$ = useMemo(() => getStrategyFormValues$(formId), [formId]);
  const strategyParams = useObservableState(memoizedValues$);

  const [strategyFormValues, setStrategyFormValues] = useState(strategyParams);

  useEffect(() => {
    setStrategyFormValues(strategyParams);
  }, [strategyParams]);

  return strategyFormValues;
};

// validation
export const validateStrategyField = (
  values: RouteOrderFormValues,
  strategyParams: AnyFormValues | undefined,
  formApi: FormOptions<RouteOrderFormValues>
) => {
  if (!strategyParams) {
    return;
  }

  const prevErrors = strategyValidationErrors$.getValue();
  const errors = (strategyErrorsValidator(sanitizeOrderFormValues(values))(strategyParams) || {}) as Record<
    string,
    string[]
  >;
  const updatedErrors = errors['FINAL_FORM/form-error'];

  if (!isEqual(prevErrors, updatedErrors)) {
    strategyValidationErrors$.next(updatedErrors);

    // force change to re-run strategy field validator
    forceStrategyFieldChange(formApi);
  }
};

export const useAllFieldsStateReset = (formId: string, formApi: EnhancedFormOptions) => {
  useEffect(() => {
    const sub = allFields$
      .pipe(filter(({ type, meta }) => type === 'RESET_VALUES' && formId === meta.formId))
      .subscribe(() => formApi.reset());

    return () => {
      sub.unsubscribe();
    };
  }, [formApi, formId]);
};
