import type { PrincipalFillFormOutput, PrincipalFillFormInput } from './principal-fill.contracts';
import { principalFillContract } from './principal-fill.form-contract';
import type { PrincipalFillContractType } from './principal-fill.form-contract';
import { convertToNumber } from '@oms/ui-util';
import { handleSubmit, handleSanitizedValuesChange } from './principal-fill.event-handler';
import { PrincipalFillService } from '@app/data-access/services/trading/principal-fill.service';
import { TradeDateTimeType } from '@oms/generated/frontend';
import type { InvestorOrderInfoFragment } from '@oms/generated/frontend';
import { FormBuilder, FORM_EVENT_TYPE } from '@oms/frontend-foundation';

export const principalFillFormBuilder = FormBuilder.create<PrincipalFillFormInput, PrincipalFillFormOutput>(
  'princial-fill-form'
)
  .contract<PrincipalFillContractType>(principalFillContract)
  .type('principal-fill-form')
  .sanitizer((s) =>
    s
      .input(async function sanitize(incomingValues, ctx) {
        const service = ctx.container.resolve(PrincipalFillService);
        const orderIds = incomingValues.ids;

        if (!orderIds?.length) {
          return {};
        }

        const getOrderPromises = orderIds.map((id) => service.getOrderById(id));
        const getOrderResponses = await Promise.all(getOrderPromises);
        const investorOrders = getOrderResponses.map((res) => ({
          ...res.data.visibleInvestorOrder
        })) as InvestorOrderInfoFragment[];

        return {
          investorOrders,
          instrument: investorOrders[0].instrument
            ? {
                id: investorOrders[0].instrument.id
              }
            : undefined,
          tradeThrough: {
            allowSweep: true
          }
        };
      })
      .output(function sanitize(formValues) {
        const output: PrincipalFillFormOutput = {
          capacity: formValues.capacity,
          firmAccount: formValues.firmAccount ? formValues.firmAccount : undefined,
          instrument: formValues.instrument,
          investorOrders: formValues.investorOrders,
          price: convertToNumber(formValues.price),
          quantity: convertToNumber(formValues.quantity),
          locate: formValues.locate,
          tradeDateTimeType: formValues.tradeDateTime ? TradeDateTimeType.Past : TradeDateTimeType.Now,
          tradeDateTime: formValues?.tradeDateTime ?? undefined,
          tradeTagIds: formValues.tradeTagIds?.map((tag) => tag.id),
          tradeThrough: { allowSweep: formValues.tradeThrough?.allowSweep || false }
        };
        return output;
      })
  )
  .change(async (event, ctx) => {
    switch (event.type) {
      case FORM_EVENT_TYPE.SANITIZED_VALUES_CHANGED: {
        handleSanitizedValuesChange(event, ctx).catch(console.error);
        break;
      }
      case FORM_EVENT_TYPE.SUBMIT: {
        return await handleSubmit(event, ctx);
      }
    }
  });

export type PrincipalFillFormBuilderType = typeof principalFillFormBuilder;

export default principalFillFormBuilder;
