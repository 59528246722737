import {
  getGridPropsAndItems,
  getMostRecentCharges,
  gridPopoverChargeScale,
  gridPopoverChargeScaleProps,
  gridPopoverChargeSchedule,
  gridPopoverChargeScheduleProps,
  groupChargesByName
} from '@app/common/types/charges/charges.utils';
import { ChargeGroup, RateType } from '@oms/generated/frontend';
import type { CompositeChargeInfoFragment, GetMatchingChargeSchedulesQuery } from '@oms/generated/frontend';
import type { GridColValues, DisplayGridProps } from '@oms/ui-design-system';
import type { ResponsiveValue } from '@oms/ui-design-system-css';
import { convertFromScientificNotation } from 'libs/frontend/ui-design-system/src/formatting/format';

export const createChargeScheduleDisplayFromCompositeCharges = (
  compositeCharges: CompositeChargeInfoFragment[]
): DisplayGridProps | undefined => {
  if (!compositeCharges || compositeCharges.length === 0) return undefined;

  const chargeMap = groupChargesByName(compositeCharges);
  const mostRecentCharges = getMostRecentCharges(chargeMap).filter(
    (compositeCharge) => compositeCharge.chargeGroup === ChargeGroup.Fee
  );

  const components: DisplayGridProps['items'] = mostRecentCharges.flatMap((compositeCharge) => {
    const isScale = compositeCharge.rateType === RateType.Scale;
    const { gridProps, items } = getGridPropsAndItems({
      compositeCharge,
      isScale,
      showChargeAndScaleName: true,
      showChargeName: true
    });

    return [
      {
        component: {
          type: 'InWindowPopover',
          popoverContent: {
            style: {
              width: 220
            },
            gridProps,
            items
          },
          value: {
            component: {
              type: 'Link',
              text: compositeCharge.scheduleName
            }
          }
        },
        sx: {
          paddingLeft: 4
        }
      }
    ];
  });

  return {
    gridProps: {
      columns: (1 + components.length) as ResponsiveValue<GridColValues>
    },
    items: [
      {
        component: {
          type: 'Text',
          value: 'Fee schedule'
        }
      },
      ...components
    ],
    style: {
      display: 'flex'
    }
  };
};

export const createChargeScheduleDisplay = (
  schedules: GetMatchingChargeSchedulesQuery['getMatchingChargeSchedules']
): DisplayGridProps['items'] => {
  if (!schedules) return [];

  return schedules.flatMap((chargeSchedule) => {
    const isScale = chargeSchedule?.rateType === RateType.Scale;
    const gridProps = isScale ? gridPopoverChargeScaleProps : gridPopoverChargeScheduleProps;

    const items = isScale
      ? gridPopoverChargeScale({
          chargeName: chargeSchedule?.charge?.name,
          scaleName: chargeSchedule?.scaleSchedule?.name,
          basis: chargeSchedule?.scaleSchedule?.scale?.basis,
          rateType: chargeSchedule?.scaleSchedule?.scale?.rateType,
          rows: chargeSchedule?.scaleSchedule?.scale?.rows,
          showChargeAndScaleName: true
        })
      : gridPopoverChargeSchedule({
          chargeName: chargeSchedule?.charge?.name,
          rateType: chargeSchedule?.rateType,
          rateValue: chargeSchedule?.rateValue
            ? `${convertFromScientificNotation(chargeSchedule.rateValue)}`
            : undefined,
          min: chargeSchedule?.minimumChargeValue,
          max: chargeSchedule?.maximumChargeValue,
          showChargeName: true
        });

    return [
      {
        component: {
          type: 'InWindowPopover',
          popoverContent: {
            style: {
              width: 220
            },
            gridProps,
            items
          },
          value: {
            component: {
              type: 'Link',
              text: chargeSchedule?.name || ''
            }
          }
        },
        sx: {
          paddingLeft: 4
        }
      }
    ];
  });
};
