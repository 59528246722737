import type { VisibleInvestorOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import { Box, Spinner } from '@oms/ui-design-system';
import type { Sprinkles } from '@oms/ui-design-system';
import { NEW_ORDERS } from './new-orders.grid.widget';
import { useGridSelectionEvent } from '@app/data-access/memory/grid.events';
import { useUpdatedInvestorOrder } from '@app/data-access/hoc/with-updated-investor-order';
import { InvestorOrderViewSidePanelWidget } from '../investor-order-view/investor-order-view.side-panel.widget';
import { TableServerConnectionLoss } from '@app/data-access/services/system/table-server/table-server.connection.loss';

const sx: Sprinkles = {
  paddingTop: 5,
  backgroundColor: 'layout.level2',
  height: 'full',
  overflowY: 'auto'
};

const textSx: Sprinkles = {
  ...sx,
  padding: 5,
  display: 'flex',
  flexDirection: 'column',
  placeContent: 'center',
  textAlign: 'center'
};

const NewOrdersSidePanelWidget = () => {
  const selectedRowsEvent = useGridSelectionEvent<VisibleInvestorOrderInfoWithAllocationsFragment>(
    NEW_ORDERS,
    'scoped'
  );
  const newOrder = selectedRowsEvent?.payload.selectedRows[0];
  const {
    data: investorOrder,
    isLoading,
    unstableConnection,
    reconnect,
    isReconnecting
  } = useUpdatedInvestorOrder(newOrder?.id);

  switch (true) {
    case !newOrder:
      return <Box sx={textSx}>Please select an order from pane on the left.</Box>;

    case isLoading || !investorOrder:
      return <Spinner fillArea bgColor={sx.backgroundColor} />;

    default:
      return (
        <TableServerConnectionLoss
          onTryAgain={reconnect}
          unstableConnection={unstableConnection}
          isReconnecting={isReconnecting}
        >
          <InvestorOrderViewSidePanelWidget data={investorOrder} />
        </TableServerConnectionLoss>
      );
  }
};

export default NewOrdersSidePanelWidget;
