import { Optional } from '../types/shared-util-types';

/**
 * A `Result` type known to hold success type and no error.
 * The `value` type is know to be of type `R`.
 */
type SuccessResult<R> = Result<R, never>;

/**
 * A `Result` type known to hold failure type and no success result.
 * The `value` type is know to be of type `E`.
 */
type FailureResult<E = Error> = Result<never, E>;

/**
 * A wrapper type holding a single value that may be either:
 * 1. A successful result type
 * 2. A failure type (usually an error)
 *
 * The underlying value may be only one of these two. Never both or niether.
 * In this way, success and failure results produce a consistent return type
 * which can be evaluated to handle either outcome.
 *
 * Use the static`success` and `failure` methods to create a `Result` instance:
 *
 * @example
 * ```ts
 * const result = Result.failure(error);
 * // or
 * const result = Result.success('foo');
 * ```
 * This can be passed to some context that expects either to be possible.
 * There it can be unwrapped and handled:
 *
 * @example
 * ```ts
 * if (result.isSuccess()) {
 *   const { value } = result;
 *   // Handle... value is known to be success type.
 * }
 *
 * if (result.isFailure()) {
 *   const { value } = result;
 *   // Handle... value is known to be failure type.
 * }
 * ```
 *
 * Alternatively, you could use `map` or `mapAsync` to handle in a similar way.
 */
export class Result<R, E = Error> {
  protected _value: R | E;
  protected _isSuccess: boolean;

  /** @protected Use static `success` and `failure` methods to create a `Result` instance. */
  protected constructor(isSuccess: boolean, value: R | E) {
    this._isSuccess = isSuccess;
    this._value = value;
  }

  /**
   * @param error - A failed result (most likely an error)
   * @returns A `Result` instance holding the failed result
   */
  public static failure<E>(error: E): Result<never, E> {
    return new Result(false, error) as unknown as Result<never, E>;
  }

  /**
   * @param result - A successful result
   * @returns A `Result` instance holding the successful result
   */
  public static success<R>(result: R): Result<R, never> {
    return new Result(true, result) as unknown as Result<R, never>;
  }

  /**
   * Access to the underlying value, success or failure.
   * Use `isSuccess` and/or `isFailure` methods to handle this
   * as success type or failure type.
   */
  public get value(): R | E {
    return this._isSuccess ? (this._value as R) : (this._value as E);
  }

  /**
   * Type predicate that resolves the `Result` instance as a success or failure
   * for the scope of the block created:
   *
   * @example
   * ```ts
   * if (result.isSuccess()) {
   *   const { value } = result;
   *   // Handle... value is known to be success type.
   * }
   * ```
   * @returns Type predicate validating if result is a success type
   */
  public isSuccess(): this is SuccessResult<R> {
    return this._isSuccess;
  }

  /**
   * Type predicate that resolves the `Result` instance as a success or failure
   * for the scope of the block created:
   *
   * @example
   * ```ts
   * if (result.isFailure()) {
   *   const { value } = result;
   *   // Handle... value is known to be failure type.
   * }
   * ```
   * @returns Type predicate validating if result is a failure type
   */
  public isFailure(): this is FailureResult<E> {
    return !this._isSuccess;
  }

  /**
   * Unwraps the success value or throws an error
   *
   * ```ts
   * try {
   *   const value = result.unwrapOrThrow();
   * } catch (e) {
   *   const allErrors = e as Error;
   *   allErrors.errors.forEach((error) => {
   *     console.warn(error.message);
   *   });
   * }
   * ```
   *
   * @returns The success value if successful
   * @throws An error if not successful
   */
  public unwrapOrThrow(): R {
    if (this.isSuccess()) {
      return this.value;
    } else {
      throw this.value as E;
    }
  }

  /**
   * Unwraps the success value or handles the error with a provided handler function
   *
   * @example
   * ```ts
   * const value = result.unwrapOrHandle((error) => {
   *   // Handle error case and return a value of type R
   *   return defaultValue;
   * });
   * ```
   *
   * @param errorHandler - A function that handles the error and returns a value of type R
   * @returns The success value if successful, or the result of the error handler if not
   */
  public unwrapOrHandle(errorHandler: (error: E) => R): R {
    if (this.isSuccess()) {
      return this.value;
    } else {
      return errorHandler(this.value as E);
    }
  }

  /**
   * Unwraps the error value if this is a failure result
   *
   * @example
   * ```ts
   * if (result.isFailure()) {
   *   const error = result.unwrapError();
   *   console.error(error);
   * }
   * ```
   *
   * @returns The error value if this is a failure result
   * @throws If this is a success result
   */
  public unwrapError(): E {
    if (this.isFailure()) {
      return this.value as E;
    }
    throw new Error('Cannot unwrap error from success result');
  }

  /**
   * Unwraps the success value or throws an error with a custom reason
   *
   * @example
   * ```ts
   * const value = result.expect('Custom error message');
   * // or
   * const value = result.expect(new Error('Custom error'));
   * // or
   * const value = result.expect((error) => `Failed because: ${error.message}`);
   * ```
   *
   * @param reason - A string, Error, or callback function that returns either
   * @returns The success value if successful
   * @throws The provided error message/instance if not successful
   */
  public expect(reason: string | Error | ((error: E) => string | Error)): R {
    if (this.isSuccess()) {
      return this.value;
    } else {
      if (typeof reason === 'string') {
        throw new Error(reason);
      } else if (reason instanceof Error) {
        throw reason;
      } else {
        const r = reason(this.value as E);
        throw r instanceof Error ? r : new Error(r);
      }
    }
  }

  /**
   * Converts the result to an optional, that is either the
   * value or undefined.
   *
   * @example
   * ```ts
   * const value = result.asOptional();
   * if (value) {
   *   // value is know to be defined
   * }
   * ```
   *
   * @param failureHandler - Optional callback that performs a side-effect (such as logging) in the case of failure.
   * @returns The success value if successful or undefined if not
   */
  public asOptional(failureHandler?: (error: E) => void): Optional<R> {
    if (this.isSuccess()) {
      return this.value;
    } else {
      if (failureHandler) {
        failureHandler(this.value as E);
      }
      return undefined;
    }
  }

  /**
   * Maps success and/or failure results to a return type.
   *
   * @example
   * ```ts
   * const description: string = result.mapTo(
   *   (successType) => successType.toString(),
   *   (error) => error.message
   * );
   * ```
   * @param successHandler - Callback that handles success result.
   * @param failureHandler - Callback that handles failure result.
   */
  public mapTo<ResultType>(
    successHandler: (successType: R) => ResultType,
    failureHandler: (error: E) => ResultType
  ): ResultType {
    if (this._isSuccess) {
      return successHandler(this._value as R);
    } else {
      return failureHandler(this._value as E);
    }
  }

  /**
   * Maps success and/or failure results to a return type (For use with async).
   *
   * @example
   * ```ts
   * const description: string = await result.mapToAsync(
   *   async (successType) => await service.processSuccess(successType),
   *   async (error) => await service.processError(error)
   * );
   * ```
   * @param successHandler - Callback that handles success result.
   * @param failureHandler - Callback that handles failure result.
   */
  public async mapToAsync<ResultType>(
    successHandler: (successType: R) => Promise<ResultType> | ResultType,
    failureHandler: (error: E) => Promise<ResultType> | ResultType
  ): Promise<ResultType> {
    if (this._isSuccess) {
      return await successHandler(this._value as R);
    } else {
      return await failureHandler(this._value as E);
    }
  }

  /**
   * Applies handing for success and/or failure results.
   *
   * @example
   * ```ts
   * result.mapSync(
   *   (successType) => { // Handle success },
   *   (error) => { // Handle failure }
   * );
   * ```
   * @param [successHandler] - Callback that handles success result.
   * @param [failureHandler] - Callback that handles failure result.
   */
  public mapSync(successHandler?: (successType: R) => void, failureHandler?: (error: E) => void) {
    if (this._isSuccess && successHandler) successHandler(this._value as R);
    if (!this._isSuccess && failureHandler) failureHandler(this._value as E);
  }

  /**
   * Applies async handing for success and/or failure results.
   *
   * @example
   * ```ts
   * result.map(
   *   async (successType) => { // Handle success },
   *   async (error) => { // Handle failure }
   * );
   * ```
   * @param [successHandler] - Async callback that handles success result.
   * @param [failureHandler] - Async callback that handles failure result.
   */
  public async mapAsync(
    successHandler?: (successType: R) => Promise<void>,
    failureHandler?: (error: E) => Promise<void>
  ) {
    if (this._isSuccess && successHandler) await successHandler(this._value as R);
    if (!this._isSuccess && failureHandler) await failureHandler(this._value as E);
  }

  /**
   * Executes provided handlers based on the result state and returns the success status.
   *
   * @example
   * ```typescript
   * const isSuccess = result.asBool({
   *   onSuccess: (value) => console.log(value),
   *   onFailure: (error) => handleError(error),
   * });
   * ```
   *
   * @param handlers - Optional object containing success and failure handlers
   * @param handlers.onSuccess - Optional callback executed when result is successful
   * @param handlers.onFailure - Optional callback executed when result is a failure
   * @returns {boolean} True if the result is successful, false otherwise
   *
   */
  public asBool(handlers?: {
    onSuccess?: (successType: R) => void;
    onFailure?: (error: E) => void;
  }): boolean {
    const { onSuccess: successHandler, onFailure: failureHandler } = handlers || {};
    if (this._isSuccess && successHandler) successHandler(this._value as R);
    if (!this._isSuccess && failureHandler) failureHandler(this._value as E);
    return this._isSuccess;
  }
}

