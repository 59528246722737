import type { ValueProps, DisplayGridItemProps } from '@oms/ui-design-system';
import type { MarketDataItemsUnion, MarketDataLayout, MarketDataPayload } from './market-data.types';
import type { Maybe } from '@oms/ui-util';
import { TickDirection } from '@oms/generated/frontend';

export type DirectionType = 'up' | 'down' | 'neutral';
type TextColor = 'Green.500' | 'Red.500' | 'white';

type TextColorMap = {
  [K in DirectionType]: TextColor;
};

const setDirection = (price: number): DirectionType => {
  if (price > 0) return 'up';
  if (price < 0) return 'down';
  return 'neutral';
};

export const destinationColorsMap: TextColorMap = {
  up: 'Green.500',
  down: 'Red.500',
  neutral: 'white'
} as const;

const getLastAdvancedColors = (direction: DirectionType) => {
  return destinationColorsMap[direction];
};

const getLastAdvancedText = (
  direction: DirectionType,
  priceChange: Maybe<number>,
  priceChangePercent: Maybe<number>
) => {
  const sign = direction === 'up' ? '+' : '';
  const priceChangeFormatted = priceChange ? ` ${sign}${truncateNumber(priceChange, 4)}` : '';
  const priceChangePercentageFormatted = priceChangePercent
    ? `(${sign}${truncateNumber(priceChangePercent, 2)}%)`
    : '';

  return `${priceChangeFormatted} ${priceChangePercentageFormatted}`;
};

const truncateNumber = (number: number, decimalPlaces: number) => {
  const factor = Math.pow(10, decimalPlaces);
  return Math.trunc(number * factor) / factor;
};

const getDirectionByTick = (tickDirection?: Maybe<TickDirection>) => {
  switch (tickDirection) {
    case TickDirection.UpTick:
      return 'up';
    case TickDirection.DownTick:
      return 'down';
    default:
      return undefined;
  }
};

export const marketDataDisplayItemsMap: Record<
  MarketDataItemsUnion,
  (data: MarketDataPayload, layout: MarketDataLayout) => DisplayGridItemProps | null
> = {
  instrumentName: (data) => ({
    component: {
      type: 'Text',
      value: [
        { text: data.instrumentDetails?.mappings?.displayCode || '-' },
        { text: '-', textProps: { sx: { color: 'text.semiMinor' } } },
        {
          text: data.instrumentDetails?.longName || '-',
          textProps: { sx: { color: 'text.semiMinor' } }
        }
      ]
    },
    colSpan: 4
  }),
  bidAsk: (data, layout) => {
    const value = [data?.bidPrice || 0, data?.askPrice || 0];
    return {
      label: layout === 'vertical' ? 'Bid/Ask' : undefined,
      component: {
        type: 'Numeric',
        value,
        format: 'decimal-2-4',
        display: 'range'
      }
    };
  },
  mid: (data) => ({
    label: 'Mid',
    component: {
      type: 'Numeric',
      value: data?.midPrice || '-',
      format: 'price'
    }
  }),
  open: (data) => ({
    label: 'Open',
    component: {
      type: 'Numeric',
      value: data?.openPrice || '-',
      format: 'price'
    }
  }),
  prevClose: (data) => ({
    label: 'Previous close',
    component: {
      type: 'Numeric',
      value: data?.previousClosePrice || '-',
      format: 'price'
    }
  }),
  last: (data) => ({
    label: 'Last',
    component: {
      type: 'Numeric',
      value: data.lastTradePrice || '-',
      direction: data?.lastTradePrice ? setDirection(data.lastTradePrice) : 'up',
      format: 'price'
    }
  }),
  lastAdvanced: (data) => {
    const direction = setDirection(data.priceChange || 0);
    return {
      label: 'Last',
      component: {
        type: 'Text',
        value: [
          { text: data?.lastTradePrice ? String(data.lastTradePrice) : '-' },
          {
            text: getLastAdvancedText(direction, data?.priceChange, data?.priceChangePercent),
            textProps: {
              sx: {
                color: getLastAdvancedColors(direction)
              }
            }
          }
        ]
      }
    };
  },
  exchange: (data) => ({
    label: 'Exchange',
    component: {
      type: 'Text',
      value: data?.lastTradeExchange || ''
    }
  }),
  high: (data) => ({
    label: 'High',
    component: {
      type: 'Numeric',
      value: data?.highDayPrice || '-',
      format: 'price'
    }
  }),
  low: (data) => ({
    label: 'Low',
    component: {
      type: 'Numeric',
      value: data?.lowDayPrice || '-',
      format: 'price'
    }
  }),
  highLow: (data, layout) => {
    const value = [data?.highDayPrice || 0, data?.lowDayPrice || 0];
    return {
      label: layout === 'vertical' ? 'High/Low' : undefined,
      component: {
        type: 'Numeric',
        value,
        format: 'decimal-2-4',
        display: 'range'
      }
    };
  },
  close: (data) => ({
    label: 'Close',
    component: {
      type: 'Numeric',
      value: data?.closePrice || '-',
      format: 'price'
    }
  }),
  volume: (data) => ({
    label: 'Vol',
    component: {
      type: 'Numeric',
      value: data?.cumulativeVolume || '-',
      format: 'quantity'
    }
  }),
  vwap: (data) => ({
    label: 'VWAP',
    component: {
      type: 'Numeric',
      value: data?.vwap || '-',
      format: 'price'
    }
  }),
  change: (data) => ({
    label: 'Change ($)',
    component: {
      type: 'Numeric',
      value: data?.priceChange || '-',
      direction: data?.priceChange ? setDirection(data.priceChange) : 'neutral',
      format: 'price'
    }
  }),
  changePercent: (data) => ({
    label: 'Change (%)',
    component: {
      type: 'Numeric',
      value: data?.priceChangePercent || '-',
      format: 'percentage'
    }
  }),
  limitUp: (data) => ({
    label: 'Limit up',
    component: {
      type: 'Numeric',
      value: data?.limitUpPrice || '-',
      format: 'price'
    }
  }),
  limitDown: (data) => ({
    label: 'Limit down',
    component: {
      type: 'Numeric',
      value: data?.limitDownPrice || '-',
      format: 'price'
    }
  }),
  bid: (data) => ({
    label: 'Bid',
    component: {
      type: 'Numeric',
      value: data?.bidPrice || '-',
      displayTickDirection: true,
      direction: getDirectionByTick(data?.askTickDirection),
      format: 'price'
    }
  }),
  bidSize: (data) => ({
    label: 'Bid size',
    component: {
      type: 'Numeric',
      value: data?.bidSize || 0,
      format: 'price'
    }
  }),
  ask: (data) => ({
    label: 'Ask',
    component: {
      type: 'Numeric',
      value: data?.askPrice || '-',
      displayTickDirection: true,
      direction: getDirectionByTick(data?.askTickDirection),
      format: 'price'
    }
  }),
  askSize: (data) => ({
    label: 'Ask size',
    component: {
      type: 'Numeric',
      value: data?.askSize || 0,
      format: 'quantity'
    }
  }),
  adv: (data) => ({
    label: 'ADV %',
    component: {
      type: 'Numeric',
      value:
        data?.orderQuantity && data?.adv30day
          ? (Number(data.orderQuantity) / Number(data?.adv30day)) * 100
          : '-',
      format: ['decimal', { decimalPlaces: 3 }]
    }
  }),
  adv5day: (data) => ({
    label: 'ADV 5 day',
    component: {
      type: 'Numeric',
      value: data?.adv5day || 0,
      format: 'quantity'
    }
  }),
  fiftyTwoWeekRange: (data) => ({
    label: '52 week range',
    component: {
      type: 'Numeric',
      value: [data?.low52weekPrice || 0, data?.high52weekPrice || 0],
      format: 'price',
      display: 'range'
    }
  }),
  statusTags: (data) => {
    if (!data) return null;
    const items: ValueProps[] = [];

    if (data.tradingStatus) {
      items.push({
        text: data.tradingStatus.trim(),
        badgeProps: {
          palette: 'warning'
        }
      });
    }

    if (data.shortSellRestricted) {
      items.push({
        text: 'SSR',
        badgeProps: {
          palette: 'warning'
        }
      });
    }

    return items.length > 0
      ? {
          component: {
            type: 'Badge',
            value: items,
            sx: { flexDirection: 'column', alignItems: 'flex-end' }
          }
        }
      : null;
  }
};
