import {
  useVGrid,
  VGrid,
  ROW_SELECTION_COUNT_ACTION_TYPE,
  TOGGLE_FILTERS_AND_SIDEBAR_ACTION_TYPE,
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE
} from '@oms/frontend-vgrid';
import {
  RulesDefinitionRow,
  TsRulesDefinitionsWithFilterDocument,
  TsRulesDefinitionsWithFilterSubscription
} from '@oms/generated/frontend';
import { withTableServer } from '@app/data-access/services/system/table-server/with.table-server.component';
import { buildRoutingRulesColumnDefs } from './routing-rules.grid.columns';
import { createAction, deleteAction } from './routing-rules.grid.actions';

type RoutingRuleSubscriptionKeys = 'routing_rules';

type RoutingRuleSubscriptionMap = {
  routing_rules: {
    datasource: RulesDefinitionRow;
    subscription: TsRulesDefinitionsWithFilterSubscription;
  };
};

const RoutingRulesGridWidget = withTableServer<RoutingRuleSubscriptionKeys, RoutingRuleSubscriptionMap>(
  ({ datasources }) => {
    const gridProps = useVGrid<RulesDefinitionRow>(
      'routing-rules',
      (builder) =>
        builder
          .tableServerColumnLibrary(buildRoutingRulesColumnDefs())
          .rowSelection((c) => c.single())
          .datasource((d) =>
            d
              .source(datasources.routing_rules.asServerSideRowModel())
              .rowId((r) => r.data.id)
              .cacheBlockSize(100)
          )
          .rowStateRules({ inactive: (params) => !params.data?.active || false })
          .sideBar()
          .onVGridReady(({ gridApi }) => {
            gridApi.sizeColumnsToFit();
          })
          .actions((a) =>
            a.schema((s) =>
              s
                .action(ROW_SELECTION_COUNT_ACTION_TYPE)
                .action(createAction)
                .action(TOGGLE_FILTERS_AND_SIDEBAR_ACTION_TYPE)
                .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
                // TODO: uncomment once update mutation is ready
                // .action(updateAction)
                .action(deleteAction)
            )
          )
          .reactiveCustomComponents(),
      [buildRoutingRulesColumnDefs, datasources]
    );

    return <VGrid {...gridProps} />;
  },
  {
    routing_rules: {
      query: TsRulesDefinitionsWithFilterDocument,
      getData: (r) => r.tsRulesDefinitionsWithFilter
    }
  }
);

export default RoutingRulesGridWidget;
