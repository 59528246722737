import { OneOrMore } from '../types/collections';
import { Maybe } from '../types/shared-util-types';

export type CompactMapTransformFn<T, ElementOfResult> = (
  element: T,
  index: number,
  array: T[]
) => Maybe<ElementOfResult>;

/**
 * Returns an array containing the non-null and defined results of calling the given
 * transformation with each element of this sequence.
 *
 * This is a TS implementation of the [Swift method](https://developer.apple.com/documentation/swift/anybidirectionalcollection/compactmap(_:)).
 * @param array - An array to transform. Passing null or undefined will be interpreted as an empty array.
 * @param transform - A callback that accepts an element of this sequence as its argument and returns an optional value.
 * @returns An array of the non-null and defined results of calling transform with each element of the sequence.
 */
export const compactMap = <T, ElementOfResult = NonNullable<T>>(
  array?: Maybe<T[]>,
  transform?: CompactMapTransformFn<T, ElementOfResult>
): ElementOfResult[] => {
  if (!Array.isArray(array)) return [];
  if (!transform)
    return array.filter(
      (element) => typeof element !== 'undefined' && element !== null
    ) as unknown as ElementOfResult[];
  return array.reduce((results, element, index) => {
    if (typeof element === 'undefined' || element === null) return results;
    const result = transform(element, index, array);
    if (typeof result === 'undefined' || result === null) return results;
    results.push(result as ElementOfResult);
    return results;
  }, [] as ElementOfResult[]);
};

/**
 * If you have some input that may be given as an array, a single value (or some mixture of both),
 * this util function will flatten all supplied values into a single array.
 * For example:
 * ```ts
 * const foo = asArray(3); // [3]
 * const bar = asArray([3]); // [3] (same result as above)
 * // or
 * const baz = asArray(1, 2, 3, [4, 5], [6, 7], 8); // [1, 2, 3, 4, 5, 6, 7, 8]
 * ```
 *
 * @param content - A spread of some type and/or arrays of that type.
 * @returns An array of all values supplied to the content param.
 */
export function asArray<T>(...content: (T | T[])[]): T[] {
  return content.flatMap((item) => (Array.isArray(item) ? item : [item]));
}

/**
 * A type predicate that checks if an unknown value is an array and optionally checks each item.
 * Safely type-casts as specified array type if successful.
 *
 * @param input - Any value to check, even if unknown or any
 * @param [tPredicate] - An optional type predicate to evaluate each item in the list.
 * @returns A boolean that also casts the type as the specified array type
 */
export const isArrayOf = <T>(input: unknown, tPredicate?: (t: unknown) => t is T): input is T[] => {
  if (typeof input !== 'object' || input === null || !Array.isArray(input)) return false;
  if (!tPredicate) return true;
  for (const t of input as T[]) {
    if (!tPredicate(t)) return false;
  }
  return true;
};

/**
 * @param collection - Any collection type, array or set
 * @returns That collection as a set
 */
export const asSet = <T>(collection: T[] | Set<T>): Set<T> =>
  Array.isArray(collection) ? new Set<T>(collection) : collection;

interface CloneSetOptions<T> {
  omit?: OneOrMore<T>;
}

/**
 *
 * @param set - Any set to clone... this will not be mutated
 * @param options.omit - Can provide one or more values NOT to copy to the new set
 * @returns A new instance of set that is an identical copy of the original
 */
export const cloneSet = <T>(set: Set<T>, options?: CloneSetOptions<T>): Set<T> => {
  const cloned = new Set<T>([...set]);
  if (options?.omit) {
    asArray(options.omit).forEach((value) => {
      cloned.delete(value);
    });
  }
  return cloned;
};
