import { logger } from '@oms/ui-util';
import { boundDate } from '@oms/ui-util';
import { Identifiable } from '@oms/ui-util';
import { FC, useMemo, ChangeEventHandler, useCallback } from 'react';
import { Input } from '../../input/input';
import { PRECISE_TIME_INPUT_CLASS_NAME } from './internal';

export type PreciseTimeInputProps = Partial<Identifiable> & {
  date?: Date;
  min?: Date;
  max?: Date;
  /** Deliberately not `onChange` which ReactDatePicker automatically passes, causing issues setting seconds. */
  onValueChange?: (value: string, date: Date) => void;
  className?: string;
};

// To be correctly formatted for the HTML input, it needs to be in 24-hour time, so need to hardcoded to any locale
// that formats as 24-hour w/o AM/PM. In this case, choosing `en-UK` rather arbitrarily.
const toValue = (date?: Date) => date?.toLocaleTimeString('en-UK');

export const PreciseTimeInput: FC<PreciseTimeInputProps> = ({
  date: _date,
  min,
  max,
  onValueChange,
  className,
  id
}) => {
  const date = useMemo(() => {
    return _date ? boundDate(_date, { begin: min, end: max }) : undefined;
  }, [_date, min, max]);
  const value = useMemo(() => toValue(date), [date]);
  const minValue = useMemo(() => toValue(min), [min]);
  const maxValue = useMemo(() => toValue(max), [max]);
  const onChangeCb: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (!onValueChange) return;
      const existingDate = date ?? new Date();
      try {
        const { value } = e.target;
        const newDate = boundDate(new Date(`${existingDate.toLocaleDateString()} ${value}`), {
          begin: min,
          end: max
        });
        onValueChange(value, newDate);
      } catch (error) {
        if (error instanceof Error) {
          logger.as('PreciseTimeInput').error(error.message);
        } else {
          logger.as('PreciseTimeInput').error(String(error));
        }
      }
    },
    [date, onValueChange, min, max]
  );
  return (
    <Input
      className={`${PRECISE_TIME_INPUT_CLASS_NAME} ${className}`}
      id={id}
      type="time"
      step="1"
      min={minValue}
      max={maxValue}
      value={value}
      onChange={onChangeCb}
    />
  );
};
