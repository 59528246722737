import {
  useVGrid,
  VGrid,
  ROW_SELECTION_COUNT_ACTION_TYPE,
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE,
  TOGGLE_FILTERS_AND_SIDEBAR_ACTION_TYPE
} from '@oms/frontend-vgrid';
import type { GridBuilder } from '@oms/frontend-vgrid';
import {
  ExecutionStatus,
  ExecutionRow,
  TsExecutionsWithFilterSubscription,
  TsExecutionsWithFilterDocument
} from '@oms/generated/frontend';
import type {
  VisibleInvestorOrderInfoWithAllocationsFragment as IOFragment,
  ExecutionInfoFragment
} from '@oms/generated/frontend';
import { GridConfigEventHandler } from '@app/data-access/services/system/grids/grid-config.event-handler';
import { buildExecutionsGridColumnDefs } from './investor-order-view.executions.columns';
import { cancelExecutionsAction } from './grid-actions/cancel.executions.action';
import { applySettlementFXRateAction } from './grid-actions/apply-settlement-fx-rate.action';
import { viewExecutionAction } from './grid-actions/view.execution.action';
import { withTableServer } from '@app/data-access/services/system/table-server/with.table-server.component';

type InvestorOrderExecutionsGridSubscriptions = 'io_executions';

type InvestorOrderExecutionsGridSubscriptionMap = {
  io_executions: {
    datasource: ExecutionRow;
    subscription: TsExecutionsWithFilterSubscription;
  };
};

export const InvestorOrderExecutionsGrid = withTableServer<
  InvestorOrderExecutionsGridSubscriptions,
  InvestorOrderExecutionsGridSubscriptionMap,
  { id: IOFragment['id'] }
>(
  ({ datasources, props: compProps }) => {
    const { id } = compProps;
    const props = useVGrid<ExecutionInfoFragment>(
      'investor-order-executions',
      (b: GridBuilder<ExecutionInfoFragment>) =>
        b
          .tableServerColumnLibrary(buildExecutionsGridColumnDefs())
          .rowSelection((c) => c.multiple())
          .sideBar()
          .datasource((d) =>
            d
              .source(
                datasources.io_executions.asServerSideRowModel({
                  filter: {
                    investorOrderId: {
                      filterType: 'text',
                      type: 'equals',
                      filter: id
                    }
                  }
                })
              )
              .rowId((r) => {
                return r.data?.id;
              })
          )
          .injectEvents([GridConfigEventHandler])
          .rowStateRules({
            noExecutedQuantity: (params) => params.data?.status === ExecutionStatus.Inactive
          })
          .actions((a) =>
            a.schema((s) =>
              s
                .action(TOGGLE_FILTERS_AND_SIDEBAR_ACTION_TYPE)
                .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(ROW_SELECTION_COUNT_ACTION_TYPE)
                .action(cancelExecutionsAction)
                .action(applySettlementFXRateAction)
                .action(viewExecutionAction)
            )
          ),
      [datasources, id]
    );

    return <VGrid {...props} />;
  },
  {
    io_executions: {
      query: TsExecutionsWithFilterDocument,
      getData: (r) => r.tsExecutionsWithFilter
    }
  }
);

export default InvestorOrderExecutionsGrid;
