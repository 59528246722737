import { Flex } from '@oms/ui-design-system';
import type { Sprinkles } from '@oms/ui-design-system';
import type { FC } from 'react';
import { OrderPositions } from './montage-order-positions';
import { MappableOrders } from './montage-mappable-orders';
import { MontageDepthGrid } from './montage-depth-grid';
import { MontageLevel1Banner } from './montage-level1-banner';
import { useMontageLayout } from './use-montage-layout.hooks';

const wrapperSx: Sprinkles = {
  marginTop: '2px',
  height: 'full',
  width: 'full',
  paddingLeft: 'small',
  paddingRight: 'small',
  pb: 'small',
  gap: 'small'
};

const wrapperExpandedSx: Sprinkles = {
  ...wrapperSx,
  paddingRight: '2px'
};

export const MontageDataPanelWidget: FC = () => {
  const { layout } = useMontageLayout();

  return (
    <Flex direction="column" sx={layout === 'expanded' ? wrapperExpandedSx : wrapperSx}>
      <MontageLevel1Banner />
      <OrderPositions />
      <MappableOrders />
      <MontageDepthGrid />
    </Flex>
  );
};

export default MontageDataPanelWidget;
