import clsx from 'clsx';
import * as styles from './css/input.css';
import { inputOffsetOutline } from '../../system/styles.css';
import { useControllableState, UseControllableStateProps } from '../../hooks/use-controllable';
import React, { ChangeEventHandler, useCallback, useMemo, useState, forwardRef } from 'react';
import { format } from '../../formatting/format';
import type { FormatType } from '../../formatting/format';
import { legacyFormat } from '@oms/ui-util';
import type { LegacyFormatType } from '@oms/ui-util';
import type { Prettify } from '@oms/ui-util';
import { FormControlProps, useFormControl } from '../form-control/form-control';
import { dataAttr } from '../../system/utils/dom';
import { Box } from '../../system/components/box/box';
import { __DEV__ } from '../../system/utils/assertion';
import { useAutoFocus } from '../../hooks/use-auto-focus';

type OmittedProps = 'isRequired' | 'onChange';

type InternalInputProps = Prettify<
  {
    type?: string;
    'aria-label'?: string;
    'aria-describedby'?: string;
    format?: FormatType;
    /** @deprecated use `format` */
    formatter?: LegacyFormatType;
  } & Omit<FormControlProps, OmittedProps>
>;

export type InputProps = InternalInputProps &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof InternalInputProps>;

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { isReadOnly, isDisabled, isInvalid, isRequired, ...formControl } = useFormControl(props);
  const [hasExternalValue, setHasExternalValue] = useState(!!props.value);
  const {
    size = formControl.size || 'sm',
    variant = formControl.variant || 'default',
    'aria-label': ariaLabel,
    'aria-describedby': ariaDescribedby,
    className,
    type = 'text',
    id,
    isInvalid: _isInvalid,
    isReadOnly: _isReadOnly,
    isDisabled: _isDisabled,
    placeholder,
    onChange: _onChange,
    value: _value,
    formatter,
    format: _format,
    role = 'textbox',
    autoFocus,
    ...rest
  } = props;

  const formatValue: UseControllableStateProps<string>['formatValue'] = useMemo(() => {
    if (_format) {
      return (input) => format(_format, input);
    } else if (formatter) {
      return (input) => legacyFormat({ type: formatter, value: input });
    } else {
      return undefined;
    }
  }, [formatter, _format]);

  const [value, setValue] = useControllableState({
    value: _value as string,
    defaultValue: '',
    formatValue
  });

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (_onChange) {
        _onChange(e);
      }
      setValue(e.target.value);
      setHasExternalValue(!!e.target.value);
    },
    [_onChange, setValue]
  );

  const mergedRef = useAutoFocus(ref, autoFocus);

  return (
    <Box
      as="input"
      ref={mergedRef}
      readOnly={isReadOnly}
      aria-readonly={dataAttr(isReadOnly)}
      disabled={isDisabled}
      aria-disabled={dataAttr(isDisabled)}
      aria-label={ariaLabel}
      aria-invalid={dataAttr(isInvalid)}
      required={isRequired}
      aria-required={dataAttr(isRequired)}
      aria-describedby={ariaDescribedby}
      className={clsx(
        styles.inputRecipe({ size, variant }),
        className,
        inputOffsetOutline,
        hasExternalValue && 'has-value'
      )}
      onChange={onChange}
      type={type}
      id={id || formControl.id}
      value={value}
      autoComplete="off"
      placeholder={placeholder}
      role={role}
      {...rest}
    />
  );
});

if (__DEV__) {
  Input.displayName = 'Input';
}
