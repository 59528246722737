import { useRef } from 'react';
import { useVGrid, VGrid, ROW_SELECTION_COUNT_ACTION_TYPE } from '@oms/frontend-vgrid';
import type { GridActionsSchemaBuilder } from '@oms/frontend-vgrid';
import { useService } from '@oms/frontend-foundation';
import { SNAPSHOT_TYPE } from '@app/data-access/offline/collections/snapshots/snapshots.collection';
import type {
  SnapshotComputedDocType,
  SnapshotType
} from '@app/data-access/offline/collections/snapshots/snapshots.collection';
import { commonSnapshotGridColumnLibrary } from './common-snapshot-grid.columns';
import { SnapshotsService } from '@app/data-access/services/system/snapshots/snapshots.service';
import type { BuilderCallback } from '@oms/ui-util';
import {
  deleteSnapshotAction,
  inlineSnapshotActions,
  loadSnapshotAction,
  saveNewSnapshotAction,
  saveOverSnapshotAction,
  shareSnapshotAction
} from './common-snapshot-grid.actions';

export interface CommonSnapshotGridComponentProps {
  type: SnapshotType;
}

export const COMMON_SNAPSHOTS_GRID_ACTIONS: Record<
  SnapshotType,
  BuilderCallback<GridActionsSchemaBuilder<SnapshotComputedDocType>>
> = {
  [SNAPSHOT_TYPE.AUTO]: (s) =>
    s
      .action(ROW_SELECTION_COUNT_ACTION_TYPE)
      .action(loadSnapshotAction)
      .action(shareSnapshotAction)
      .action(deleteSnapshotAction)
      .action(inlineSnapshotActions),
  [SNAPSHOT_TYPE.CUSTOM]: (s) =>
    s
      .action(ROW_SELECTION_COUNT_ACTION_TYPE)
      .action(saveNewSnapshotAction)
      .action(saveOverSnapshotAction)
      .action(loadSnapshotAction)
      .action(shareSnapshotAction)
      .action(deleteSnapshotAction)
      .action(inlineSnapshotActions)
};

export const CommonSnapshotGridComponent = ({ type }: CommonSnapshotGridComponentProps) => {
  const service = useService(SnapshotsService);
  const query$ = useRef(service.all$(type));
  const actionsSchemaRef = useRef(COMMON_SNAPSHOTS_GRID_ACTIONS[type]);

  const gridProps = useVGrid<SnapshotComputedDocType>(
    'snapshots',
    (builder) =>
      builder
        .sideBar()
        .columnLibrary(commonSnapshotGridColumnLibrary)
        .rowSelection((c) => c.multiple())
        .datasource((d) => d.source(query$.current).rowId((r) => r.data.id))
        .actions((a) => a.schema(actionsSchemaRef.current)),
    []
  );

  return <VGrid {...gridProps} />;
};

export default CommonSnapshotGridComponent;
