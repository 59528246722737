import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import { Collapsible, Flex, Section } from '@oms/ui-design-system';
import { useFormApi } from '@data-driven-forms/react-form-renderer';
import type { Field } from '@data-driven-forms/react-form-renderer';
import { Orientation } from '@valstro/fixatdl-core';
import './strategy-panel.scss';
import type { FieldProps } from '@oms/frontend-foundation';
import type { StrategyPanelProps } from '../../fixatdl-form.types';

export const StrategyPanel: FC<FieldProps<StrategyPanelProps>> = React.memo(
  ({
    title,
    fields,
    orientation = Orientation.HORIZONTAL,
    collapsible,
    collapsed: isCollapsed,
    sx,
    style,
    name
  }) => {
    const [collapsed, setCollapsed] = useState(isCollapsed);
    const { renderForm } = useFormApi();

    const direction = orientation === Orientation.HORIZONTAL ? 'row' : 'column';

    useEffect(() => {
      setCollapsed(isCollapsed);
    }, [isCollapsed]);

    const renderFields = () => {
      return (
        <Flex direction={direction} wrap="wrap" className="fields-wrapper">
          {renderForm(fields as Field[])}
        </Flex>
      );
    };

    const onOpenChange = useCallback((isOpen: boolean) => {
      setCollapsed(!isOpen);
    }, []);

    return (
      <Section
        title={!collapsible ? title : undefined}
        sx={{
          bg: 'layout.level2',
          ...sx
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          ...style
        }}
        testId={name}
      >
        {collapsible ? (
          <Collapsible
            label={title}
            direction="vertical"
            defaultOpen={!collapsed}
            onOpenChange={onOpenChange}
            forceMount={true}
            headerSx={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}
            contentSx={{ padding: 0 }}
            headerStyle={{ border: 'none', backgroundColor: 'transparent' }}
            contentStyle={{ border: 'none' }}
          >
            {renderFields()}
          </Collapsible>
        ) : (
          renderFields()
        )}
      </Section>
    );
  },
  isEqual
);
