import { FieldDefinition, FormContract } from '@oms/frontend-foundation';
import type { InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import {
  id,
  priority,
  name,
  description,
  active,
  venue,
  trader,
  hiddenStrategyOptions,
  strategy,
  criteria
} from './routing-rule.form-fields';
import type { RoutingRuleFieldContract } from './routing-rule.form-common';

export const routingRuleFormContract = FormContract.create<RoutingRuleFieldContract>()
  .fields({
    id,
    priority,
    name,
    description,
    active,
    venue,
    trader,
    strategy,
    hiddenStrategyOptions,
    criteria
  })
  .schema((f) => ({
    fields: [
      FieldDefinition.box(
        'rule-settings-box',
        [
          FieldDefinition.simpleGrid('rule-settings-wrapper', 2, [
            FieldDefinition.heading('Rule settings', { name: 'rule-title', sx: { marginBottom: 3 } }),
            FieldDefinition.box('toggle-button-wrapper', [f.active])
          ]),
          FieldDefinition.simpleGrid('rule-settings-grid', 2, [f.name, f.description])
        ],
        { sx: { marginX: 4, marginTop: 4 } }
      ),
      FieldDefinition.box('criteria-section-wrapper', [
        {
          name: 'criteria-section',
          component: 'tabs',
          tabs: {
            defaultValue: 'criteria',
            orientation: 'horizontal',
            sx: {
              overflow: 'hidden',
              paddingLeft: 2,
              bgColor: 'layout.level2'
            },
            config: {
              criteria: {
                title: 'Criteria',
                fields: [f.criteria]
              }
            }
          }
        }
      ]),
      FieldDefinition.box(
        'primary-route-destination-box',
        [
          FieldDefinition.simpleGrid('primary-route-destination-wrapper', 1, [
            FieldDefinition.heading('Primary route destination', {
              name: 'primary-route-destination-title',
              sx: { marginBottom: 3 }
            })
          ]),
          FieldDefinition.simpleGrid('primary-route-destination-grid', 3, [f.venue, f.trader, f.strategy], {
            sx: { padding: 3, bgColor: 'layout.level3' }
          })
        ],
        {
          sx: { marginX: 4, marginTop: 4 }
        }
      )
    ]
  }))
  .template('simple', { submitLabel: 'Add Routing Rule' });

export type RoutingRuleFormContractType = typeof routingRuleFormContract;

export type RoutingRuleFormValues = InferFormValuesFromFormContract<RoutingRuleFormContractType>;
