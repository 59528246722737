import { useImage } from '../image/use-image';
import clsx from 'clsx';
import * as styles from './css/avatar.css';
import type { AvatarNameProps, AvatarProps, DefaultAvatarProps } from './avatar.types';
import { defaultGetInitials } from './avatar-helpers';
import { useMemo } from 'react';
import type { FC } from 'react';
import { Box } from '../../system/components/box/box';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';

/**
 * Name with initials for avatar
 */
const AvatarName: FC<AvatarNameProps> = ({
  name,
  isFullName,
  count,
  className,
  getInitials = defaultGetInitials,
  getInitialOptions,
  ...props
}) => {
  const initials = useMemo(
    () => getInitials({ name, isFullName, count }, getInitialOptions),
    [name, isFullName, count, getInitials, getInitialOptions]
  );
  return (
    <Box className={className} sx={{ fontWeight: 'baseB' }} aria-label={name} {...props}>
      {initials}
    </Box>
  );
};

if (__DEV__) {
  AvatarName.displayName = 'AvatarName';
}

/**
 * Default avatar icon
 */
const DefaultAvatar: FC<DefaultAvatarProps> = ({ className, ...props }) => (
  <Box
    className={className}
    sx={{
      width: 'full',
      height: 'full'
    }}
    {...props}
  >
    <svg className={className} viewBox="0 0 128 128" role="img">
      <g>
        <path d="M103,102.1388 C93.094,111.92 79.3504,118 64.1638,118 C48.8056,118 34.9294,111.768 25,101.7892 L25,95.2 C25,86.8096 31.981,80 40.6,80 L87.4,80 C96.019,80 103,86.8096 103,95.2 L103,102.1388 Z" />
        <path d="M63.9961647,24 C51.2938136,24 41,34.2938136 41,46.9961647 C41,59.7061864 51.2938136,70 63.9961647,70 C76.6985159,70 87,59.7061864 87,46.9961647 C87,34.2938136 76.6985159,24 63.9961647,24" />
      </g>
    </svg>
  </Box>
);

if (__DEV__) {
  DefaultAvatar.displayName = 'DefaultAvatar';
}

/**
 * User avatar component with initials in a round or squared badge
 */
export const Avatar = polymorphicComponent<'div', AvatarProps>((props, ref) => {
  const {
    size = 'md',
    shape = 'rounded',
    palette = 'neutral',
    name,
    isFullName,
    count,
    src,
    children,
    className: _className,
    style,
    icon: _icon,
    onError,
    getInitials,
    getInitialOptions,
    ...rest
  } = props;

  const className = clsx(styles.avatarRecipe({ size, shape, palette }), _className);

  const icon = _icon ?? <DefaultAvatar aria-label={name} className={className} style={style} />;

  const status = useImage({ src, onError });
  const hasLoaded = status === 'loaded';

  const renderChildren = () => {
    if (src && hasLoaded) {
      return (
        <Box
          as="img"
          sx={{
            objectFit: 'cover',
            width: 'full',
            height: 'full',
            borderRadius: 'full'
          }}
          src={src}
          alt={name}
        />
      );
    }

    if (src && !hasLoaded) {
      if (name || getInitials) {
        return (
          <AvatarName
            name={name}
            isFullName={isFullName}
            count={count}
            getInitials={getInitials}
            getInitialOptions={getInitialOptions}
          />
        );
      } else {
        return icon;
      }
    }

    if (!src && (name || getInitials)) {
      return (
        <AvatarName
          name={name}
          isFullName={isFullName}
          count={count}
          getInitials={getInitials}
          getInitialOptions={getInitialOptions}
        />
      );
    }

    return icon;
  };

  return (
    <Box ref={ref} className={className} {...rest}>
      {renderChildren()}
      {children}
    </Box>
  );
});

if (__DEV__) {
  Avatar.displayName = 'Avatar';
}
