import { FormBuilder, FORM_EVENT_TYPE } from '@oms/frontend-foundation';
import type { RoutingRuleFormInput, RoutingRuleFormOutput } from './routing-rule.form-common';
import { routingRuleFormContract } from './routing-rule.form-contract';
import type { RoutingRuleFormContractType } from './routing-rule.form-contract';
import { createStrategyInput } from '../../trading/route-order/fixatdl-strategy-field/fixatdl-strategy-field.util';
import { RoutingRulesService } from '@app/data-access/services/reference-data/routing-rules/routing-rules.service';
import { getDestinationDetails, convertToRulesInput } from './routing-rule.form-utils';

export const routingRuleFormBuilder = FormBuilder.create<RoutingRuleFormInput, RoutingRuleFormOutput>(
  'routing-rule-form'
)
  .contract<RoutingRuleFormContractType>(routingRuleFormContract)
  .type('routing-rule')
  .sanitizer((s) =>
    s
      .input((formValues) => formValues)
      .output(function sanitize(formValues) {
        if (!formValues.name) return;
        if (!formValues.description) return;
        if (!formValues.criteria) return;

        const { destinationType, destinationId } = getDestinationDetails(formValues);
        if (!destinationType) return;

        const output: RoutingRuleFormOutput = {
          id: formValues.id ?? undefined,
          name: formValues.name,
          description: formValues.description,
          active: formValues.active ?? false,
          priority: formValues.priority ?? undefined,
          // TODO: Discuss with BE about potentially using an object instead of an array,
          // since the first level always contains only one object.
          routeRules: [convertToRulesInput(formValues.criteria)],
          // For MVP handle it like an array of 1 obj
          routes: [
            {
              destinationType: destinationType,
              destinationId: destinationId,
              strategy: createStrategyInput(formValues?.strategy?.value)
            }
          ]
        };

        return output;
      })
  )
  .change(async (event, ctx) => {
    switch (event.type) {
      case FORM_EVENT_TYPE.VALUES_CHANGED: {
        break;
      }
      case FORM_EVENT_TYPE.SANITIZED_VALUES_CHANGED: {
        break;
      }
      case FORM_EVENT_TYPE.SUBMIT: {
        const service = ctx.container.resolve(RoutingRulesService);
        const formOutput = event.payload.output;
        // TODO: TKT-13136 - Uncomment once the update mutation is implemented and ready for use.
        // Currently, only the 'create' method is supported.
        // const method = formSaveType === FORM_SAVE_TYPE.CREATE ? 'create' : 'update';
        return await service['create'](formOutput);
      }
    }
  });

export type RoutingRuleFormBuilderType = typeof routingRuleFormBuilder;

export default routingRuleFormBuilder;
