import { useMemo } from 'react';
import { Box } from '../../system/components/box/box';
import type { BoxProps } from '../../system/components/box/box';
import clsx from 'clsx';
import * as styles from './css/avatar-badge.css';
import { __DEV__ } from '../../system/utils/assertion';
import { Sprinkles } from '../../system/sprinkles.css';
import { polymorphicComponent } from '../../system/utils/polymorphic';

type OmittedProps = 'position';

export type AvatarBadgeProps = {
  bgColor?: Sprinkles['bgColor'];
  backgroundColor?: Sprinkles['backgroundColor'];
  boxSize?: string | number;
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
} & Omit<BoxProps, OmittedProps>;

export const AvatarBadge = polymorphicComponent<'div', AvatarBadgeProps>((props, ref) => {
  const {
    boxSize,
    style = {},
    sx = {},
    bgColor,
    backgroundColor,
    position = 'top-left',
    className,
    ...rest
  } = props;

  const dynamicStyles = useMemo(() => {
    const [y, x] = position.split('-');
    return {
      top: y === 'top' ? 0 : 'auto',
      bottom: y === 'bottom' ? 0 : 'auto',
      left: x === 'left' ? 0 : 'auto',
      right: x === 'right' ? 0 : 'auto',
      transform: `translate(${x === 'left' ? `-` : ``}15%, ${y === 'top' ? `-` : ``}15%)`,
      width: boxSize ? boxSize : undefined,
      height: boxSize ? boxSize : undefined
    };
  }, [position, boxSize]);

  return (
    <Box
      ref={ref}
      className={clsx(styles.avatarBadge, className)}
      style={{
        ...style,
        ...dynamicStyles
      }}
      sx={{
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 'full',
        bgColor: bgColor ? bgColor : backgroundColor ? backgroundColor : 'Red.400',
        ...sx
      }}
      {...rest}
    />
  );
});

if (__DEV__) {
  AvatarBadge.displayName = 'AvatarBadge';
}
