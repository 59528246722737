import type { CellRendererSelectorResult, ColDef, ICellRendererParams } from '@ag-grid-community/core';
import type { AgGridReactProps } from '@ag-grid-community/react';
import { emptyGroupRenderer } from '../renderers/cell-renderers/empty-group-renderer';
import { createDetailCellRenderer } from '../renderers/cell-renderers/detail-cell-renderer';
import type {
  CustomDetailRendererGetPropsCallback,
  CustomDetailRendererGetRowHeightParamsCallback,
  DetailCellRendererParams
} from '../renderers/cell-renderers/detail-cell-renderer';
import type { AnyRecord } from '@oms/frontend-foundation';
import type { ColumnBuilderField } from './column.builder.types';
import type { ComponentType, FC } from 'react';

export class DetailGridBuilder<
  TData extends AnyRecord,
  THeaderProps extends AnyRecord,
  TDetailProps extends AnyRecord
> {
  private _masterDetailGridSettings: AgGridReactProps<TData> = {
    masterDetail: true,
    reactiveCustomComponents: true
  };

  private _groupByColumnId: ColumnBuilderField<TData> = '';
  private _groupByColDefOverrides: ColDef<TData> = {};
  private _customRendererSettings: DetailCellRendererParams<TData, THeaderProps, TDetailProps> = {};

  groupByColumn(
    colId: ColumnBuilderField<TData> & string,
    shouldShowGroup: (data: TData) => boolean
  ): DetailGridBuilder<TData, THeaderProps, TDetailProps> {
    this._groupByColumnId = colId;
    this._groupByColDefOverrides = {
      cellRenderer: undefined,
      valueGetter: ({ data }) => (data ? (shouldShowGroup(data) ? 'opened' : 'closed') : 'closed'),
      cellRendererSelector: (params: ICellRendererParams<any>): CellRendererSelectorResult => ({
        component: shouldShowGroup(params.data) ? 'agGroupCellRenderer' : emptyGroupRenderer
      })
    };

    return this;
  }

  headerComponent(HeaderComponent: FC<THeaderProps>): DetailGridBuilder<TData, THeaderProps, TDetailProps> {
    this._customRendererSettings.HeaderComponent = HeaderComponent;
    return this;
  }

  detailComponent(
    DetailComponent: ComponentType<TDetailProps>
  ): DetailGridBuilder<TData, THeaderProps, TDetailProps> {
    this._customRendererSettings.DetailComponent = DetailComponent;
    return this;
  }

  getHeaderProps(
    getHeaderProps: CustomDetailRendererGetPropsCallback<TData, THeaderProps>
  ): DetailGridBuilder<TData, THeaderProps, TDetailProps> {
    this._customRendererSettings.getHeaderProps = getHeaderProps;
    return this;
  }

  getDetailProps(
    getDetailProps: CustomDetailRendererGetPropsCallback<TData, TDetailProps>
  ): DetailGridBuilder<TData, THeaderProps, TDetailProps> {
    this._customRendererSettings.getDetailProps = getDetailProps;
    return this;
  }

  getDetailRowHeightParams(
    getRowHeightParams: CustomDetailRendererGetRowHeightParamsCallback<TData>
  ): DetailGridBuilder<TData, THeaderProps, TDetailProps> {
    this._masterDetailGridSettings.detailRowAutoHeight = true;
    this._customRendererSettings.getDetailRowHeightParams = getRowHeightParams;
    return this;
  }

  public build() {
    if (this._groupByColumnId === '') {
      throw new Error('colId is required');
    }

    return {
      masterDetailGridSettings: {
        ...this._masterDetailGridSettings,
        detailCellRenderer: createDetailCellRenderer<TData, THeaderProps, TDetailProps>(
          this._customRendererSettings
        )
      },
      groupByColumnId: this._groupByColumnId,
      groupByColDefOverrides: this._groupByColDefOverrides
    };
  }
}
