import { addHexTransparency } from '../../utils/color';
import tokens from '../figma-tokens.json';

export enum ThemeVariation {
  Light = 'light',
  Dark = 'dark'
}

const COLOR_FAMILIES = [
  'BrandBlue',
  'Green',
  'Yellow',
  'Red',
  'BlueGrey',
  'PureGrey',
  'TransparentWhite'
] as const;
const COLOR_SHADES = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900] as const;
const EXPANDED_COLOR_SHADES = [...COLOR_SHADES, 1000] as const;
const COLOR_TRANSPARENCIES = [5, 10, 20, 40, 50, 60, 70, 80, 90, 100] as const; // No 30

export type ColorFamily = (typeof COLOR_FAMILIES)[number];
export type ColorShade = (typeof COLOR_SHADES)[number];
export type ExandedColorShade = (typeof EXPANDED_COLOR_SHADES)[number];
export type ColorTransparency = (typeof COLOR_TRANSPARENCIES)[number];

const shortOf: Record<ColorFamily, string> = {
  BrandBlue: 'BB',
  Green: 'G',
  Yellow: 'Y',
  Red: 'R',
  BlueGrey: 'BG',
  PureGrey: 'PG',
  TransparentWhite: 'TW'
};

export function getBase(color: Exclude<ColorFamily, 'TransparentWhite'>, shade: ColorShade): string;
export function getBase(color: 'BlueGrey', shade: ExandedColorShade): string;
export function getBase(color: 'TransparentWhite', transparency: ColorTransparency): string;
// Implementation
export function getBase(color: ColorFamily, variation: ExandedColorShade | ColorTransparency): string {
  //@ts-ignore
  return tokens.Core.BaseColors[color][`${shortOf[color]}_${variation}`].value;
}

interface Basic extends Record<string, string> {
  transparent: string;
  current: string;
  inherit: string;
  white: string;
  whiteRGBCode: string;
  black: string;
  blackRGBCode: string;
}

type BaseColors = Record<Exclude<ColorFamily, 'BlueGrey' | 'TransparentWhite'>, Record<ColorShade, string>> &
  Record<Extract<ColorFamily, 'BlueGrey'>, Record<ExandedColorShade, string>> &
  Record<Extract<ColorFamily, 'TransparentWhite'>, Record<ColorTransparency, string>> &
  Basic;

const base: BaseColors = (() => {
  const baseColors = {
    transparent: 'transparent',
    current: 'currentColor',
    inherit: 'inherit',
    white: '#fff',
    whiteRGBCode: '255, 255, 255',
    black: '#0e0e10',
    blackRGBCode: '0, 0, 0'
  } as Partial<BaseColors>;
  COLOR_FAMILIES.forEach((color) => {
    const content = {} as any;
    switch (color) {
      case 'BlueGrey':
        EXPANDED_COLOR_SHADES.forEach((shade) => {
          content[shade] = getBase(color, shade);
        });
        break;
      case 'TransparentWhite':
        COLOR_TRANSPARENCIES.forEach((transparency) => {
          content[transparency] = getBase(color, transparency);
        });
        break;
      default:
        COLOR_SHADES.forEach((shade) => {
          content[shade] = getBase(color, shade);
        });
    }
    baseColors[color] = content;
  });
  return baseColors as BaseColors;
})();

const baseLayout = {
  backdrop: base.BlueGrey[1000],
  level1: base.BlueGrey[900],
  level2: base.BlueGrey[800],
  level3: base.BlueGrey[700],
  level4: base.BlueGrey[600],
  level5: base.BlueGrey[500],
  green: base.Green[900],
  red: base.Red[900],
  blue: base.BrandBlue[900],
  yellow: base.Yellow[900],
  blueSubtle: base.BrandBlue[200],
  blueActive: base.BrandBlue[500],
  warning: base.Yellow[900],
  error: base.Red[900],
  success: base.Green[900],
  info: base.BrandBlue[900]
};

const price = {
  positive: base.Green[400],
  negative: base.Red[400]
};

const layout: Record<ThemeVariation, typeof baseLayout> = {
  [ThemeVariation.Dark]: baseLayout,
  [ThemeVariation.Light]: {
    ...baseLayout,
    backdrop: base.TransparentWhite[100],
    level1: base.TransparentWhite[100],
    level2: base.BlueGrey[50],
    level3: base.BlueGrey[100],
    level4: base.BlueGrey[200],
    level5: base.BlueGrey[500],
    warning: base.Yellow[200],
    error: base.Red[200],
    success: base.Green[200],
    info: base.BrandBlue[200]
  }
};

const baseBorder = {
  superMinimal: base.BlueGrey[900],
  minimal: base.BlueGrey[700],
  subtle: base.BlueGrey[600],
  strong: base.BlueGrey[500],
  active: base.BrandBlue[300],
  activeLight: base.BrandBlue[200],
  pressed: base.BrandBlue[600],
  error: base.Red[500],
  orange: base.Yellow[800]
};

const border: Record<ThemeVariation, typeof baseBorder> = {
  [ThemeVariation.Dark]: baseBorder,
  [ThemeVariation.Light]: {
    ...baseBorder,
    superMinimal: base.TransparentWhite[100],
    minimal: base.BlueGrey[200],
    subtle: base.BlueGrey[200],
    strong: base.BlueGrey[500],
    active: base.BlueGrey[300],
    activeLight: base.BlueGrey[200],
    pressed: base.BlueGrey[600],
    error: base.Red[500]
  }
};

const baseText = {
  primary: base.BlueGrey[100],
  secondary: base.BlueGrey[200],
  tertiary: base.BlueGrey[300],
  semiMinor: base.BlueGrey[400],
  minor: base.BlueGrey[500],
  active: base.BrandBlue[300],
  error: base.Red[500],
  green: base.Green[400],
  warning: base.Yellow[300]
};

const text: Record<ThemeVariation, typeof baseText> = {
  [ThemeVariation.Dark]: baseText,
  [ThemeVariation.Light]: {
    ...baseText,
    primary: base.BlueGrey[800],
    secondary: base.BlueGrey[700],
    tertiary: base.BlueGrey[500],
    semiMinor: base.BlueGrey[400],
    minor: base.BlueGrey[500],
    active: base.BrandBlue[300],
    error: base.Red[500],
    warning: base.Yellow[300]
  }
};

const baseIcons = {
  primary: base.BlueGrey[100],
  secondary: base.BlueGrey[200],
  tertiary: base.BlueGrey[300],
  semiMinor: base.BlueGrey[500],
  minor: base.BlueGrey[600],
  active: base.BrandBlue[300],
  error: base.Red[200],
  warning: base.Yellow[300],
  success: base.Green[400]
};

const icons: Record<ThemeVariation, typeof baseIcons> = {
  [ThemeVariation.Dark]: baseIcons,
  [ThemeVariation.Light]: {
    ...baseIcons,
    primary: base.BlueGrey[800],
    secondary: base.BlueGrey[700],
    tertiary: base.BlueGrey[300],
    minor: base.BlueGrey[500],
    active: base.BrandBlue[300]
  }
};

const baseButton = {
  isPrimary: {
    default: base.BrandBlue[500],
    active: base.BrandBlue[600],
    pressed: base.BrandBlue[700],
    disabled: base.TransparentWhite[5],
    focusRing: base.BrandBlue[600]
  },
  isSecondary: {
    defaultActive: base.BlueGrey[800],
    pressed: base.BrandBlue[700],
    disabled: base.TransparentWhite[5],
    borderDefault: border.dark.subtle,
    borderActive: border[ThemeVariation.Dark].active,
    focusRing: base.BrandBlue[800]
  },
  onPrimary: {
    defaultActive: base.BrandBlue[100],
    pressed: base.BrandBlue[200],
    disabled: base.TransparentWhite[20]
  },
  onSecondary: {
    default: base.BrandBlue[300]
  }
};

const button: Record<ThemeVariation, typeof baseButton> = {
  [ThemeVariation.Dark]: baseButton,
  [ThemeVariation.Light]: {
    isPrimary: {
      default: base.BrandBlue[500],
      active: base.BrandBlue[600],
      pressed: base.BrandBlue[700],
      disabled: base.TransparentWhite[5],
      focusRing: base.BrandBlue[300]
    },
    isSecondary: {
      defaultActive: base.BlueGrey[800],
      pressed: base.BrandBlue[700],
      disabled: base.TransparentWhite[5],
      borderDefault: base.BlueGrey[600],
      borderActive: border[ThemeVariation.Light].active,
      focusRing: base.BrandBlue[300]
    },
    onPrimary: {
      defaultActive: base.BrandBlue[100],
      pressed: base.BrandBlue[200],
      disabled: base.TransparentWhite[20]
    },
    onSecondary: {
      default: base.BrandBlue[300]
    }
  }
};

const baseCheckbox = {
  background: base.TransparentWhite[20],
  border: base.transparent,
  checked: base.BrandBlue[500],
  error: base.Red[300],
  hover: base.TransparentWhite[40]
};

const checkbox: Record<ThemeVariation, typeof baseCheckbox> = {
  [ThemeVariation.Dark]: baseCheckbox,
  [ThemeVariation.Light]: {
    background: base.PureGrey[200],
    border: base.PureGrey[500],
    checked: base.BrandBlue[500],
    error: base.Red[500],
    hover: base.PureGrey[300]
  }
};

const baseToggle = {
  backgroundOn: base.BrandBlue[500],
  backgroundOff: base.BlueGrey[700],
  borderOn: '#1133EE', // TODO: This color from Figma is not on the palette.
  borderOff: base.BlueGrey[600],
  borderError: base.Red[400], // Figma does not specify invalid for Toggle. Using error color from other components.
  hoverOn: base.BrandBlue[600], // Figma does not specify hover colors. Using one shade darker than backgroundOn.
  hoverOff: base.BlueGrey[700], // Figma does not specify hover colors. Using one shade lighter than backgroundOff.
  toggle: base.TransparentWhite[100]
};

const toggle: Record<ThemeVariation, typeof baseToggle> = {
  [ThemeVariation.Dark]: baseToggle,
  [ThemeVariation.Light]: {
    // Figma did not have light mode colors.
    backgroundOn: base.BrandBlue[500],
    backgroundOff: base.BlueGrey[100],
    borderOn: '#1133EE', // TODO: This color from Figma is not on the palette.
    borderOff: base.BlueGrey[300],
    borderError: base.Red[600],
    hoverOn: base.BrandBlue[600],
    hoverOff: base.BlueGrey[200],
    toggle: base.TransparentWhite[100]
  }
};

const baseProgressBar = {
  numeratorBar: base.TransparentWhite[20],
  denominatorBar: base.TransparentWhite[50],
  // "Complete" state for denominator base
  numeratorBarComplete: base.TransparentWhite[5]
};

const progressBar: Record<ThemeVariation, typeof baseProgressBar> = {
  [ThemeVariation.Dark]: baseProgressBar,
  [ThemeVariation.Light]: {
    numeratorBar: base.PureGrey[200],
    denominatorBar: base.PureGrey[400],
    numeratorBarComplete: base.Green[400]
  }
};

const basePriceSlider = {
  bar: base.BrandBlue[500],
  dot: base.TransparentWhite[100]
};

const priceSlider: Record<ThemeVariation, typeof basePriceSlider> = {
  [ThemeVariation.Dark]: basePriceSlider,
  [ThemeVariation.Light]: {
    bar: base.BrandBlue[500],
    dot: base.TransparentWhite[100]
  }
};

const baseOther = {
  dropShadow: {
    default: `rgba(0, 0, 0, 0.25)`,
    hover: {
      primary: addHexTransparency(getBase('BrandBlue', 500), 0.3),
      secondary: addHexTransparency(getBase('BrandBlue', 500), 0.3),
      dangerSecondary: addHexTransparency(getBase('Red', 400), 0.1)
    }
  },
  focusRing: '#003672'
};

const other: Record<ThemeVariation, typeof baseOther> = {
  [ThemeVariation.Dark]: baseOther,
  [ThemeVariation.Light]: baseOther
};

export type ColorsInTheme = {
  base: typeof base;
  layout: typeof baseLayout;
  text: typeof baseText;
  border: typeof baseBorder;
  icons: typeof baseIcons;
  button: typeof baseButton;
  checkbox: typeof baseCheckbox;
  other: typeof baseOther;
  toggle: typeof baseToggle;
  progressBar: typeof baseProgressBar;
  priceSlider: typeof basePriceSlider;
  price: typeof price;
};

export type ColorTheme<Variant extends string> = Record<Variant, ColorsInTheme>;

export const colorTheme: ColorTheme<ThemeVariation> = {
  [ThemeVariation.Dark]: {
    base,
    layout: layout[ThemeVariation.Dark],
    text: text[ThemeVariation.Dark],
    border: border[ThemeVariation.Dark],
    icons: icons[ThemeVariation.Dark],
    button: button[ThemeVariation.Dark],
    checkbox: checkbox[ThemeVariation.Dark],
    other: other[ThemeVariation.Dark],
    toggle: toggle[ThemeVariation.Dark],
    progressBar: progressBar[ThemeVariation.Dark],
    priceSlider: priceSlider[ThemeVariation.Dark],
    price: price
  },
  [ThemeVariation.Light]: {
    base,
    layout: layout[ThemeVariation.Light],
    text: text[ThemeVariation.Light],
    border: border[ThemeVariation.Light],
    icons: icons[ThemeVariation.Light],
    button: button[ThemeVariation.Light],
    checkbox: checkbox[ThemeVariation.Light],
    other: other[ThemeVariation.Light],
    toggle: toggle[ThemeVariation.Light],
    progressBar: progressBar[ThemeVariation.Light],
    priceSlider: priceSlider[ThemeVariation.Light],
    price: price
  }
};

export default colorTheme;
