import numbro from 'numbro';
import type { ValueFormatterParams } from '@ag-grid-community/core';
import { Maybe, Optional } from '../types/shared-util-types';

const DEFAULT_STRING = '';

type NegativeArgs = 'sign' | 'parenthesis' | undefined;

/**
 * Sanitizes a floating point input by rounding up to the nearest sig fig
 */
export const roundUpNum = (num: number, decimalPlaces: number = 2): number => {
  const decPlc = Math.pow(10, decimalPlaces);
  return Math.round((num + Number.EPSILON) * decPlc) / decPlc;
};

interface ConvertToNumberOptions {
  or?: number;
  allowNaN?: boolean;
}

interface OptionalReturnMarker {
  optionalReturn: true;
}
const isOptionalMarker = (input: unknown): input is OptionalReturnMarker =>
  typeof input === 'object' &&
  input !== null &&
  (input as Partial<OptionalReturnMarker>).optionalReturn === true;

export function convertToNumber(value: Maybe<string | number>, options?: ConvertToNumberOptions): number;
export function convertToNumber(value: Maybe<string | number>, or: number): number;
export function convertToNumber(
  value: Maybe<string | number>,
  options: OptionalReturnMarker
): Optional<number>;
export function convertToNumber(
  value: Maybe<string | number>,
  options?: ConvertToNumberOptions | number | OptionalReturnMarker
): Optional<number> {
  if (typeof value === 'number') return value;
  const convertString = (value: string, allowNaN?: boolean, fallback?: number): Optional<number> => {
    const converted = Number.parseFloat(value.replace(/,/g, ''));
    if (Number.isNaN(converted) && !allowNaN) return fallback;
    return converted;
  };
  if (isOptionalMarker(options)) {
    if (typeof value === 'undefined' || value === null) return undefined;
    return convertString(value);
  } else {
    const fallback = typeof options === 'number' ? options : (options?.or ?? 0);
    const allowNaN = typeof options === 'object' ? options.allowNaN : undefined;
    if (typeof value === 'undefined' || value === null) return fallback;
    return convertString(value, allowNaN, fallback) as number;
  }
}

export const formatNumber = (value: number | undefined | null): string => {
  if (typeof value !== 'number' || isNaN(value)) {
    return '';
  }
  const parts = value.toString().split('.');
  parts[0] = parts[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  return parts.join('.');
};

/**
 * Get formatter to display a value as %, trimmed to n (default:4) decimal places
 * @param len Number of figures after the decimal (default:4)
 * @param trim whether to truncate trailing non-significant figures (default: true)
 * @param useParentheses use parentheses to wrap negative values. If false, will use sign instead. Default: true
 */
const getPctFormat = (
  len: number = 4,
  trim: boolean = true,
  useParentheses: boolean = true
): {
  output: string;
  mantissa: number;
  trimMantissa: boolean;
  negative: NegativeArgs;
} => {
  return {
    output: 'percent',
    mantissa: len,
    trimMantissa: trim,
    negative: useParentheses ? 'parenthesis' : 'sign'
  };
};

/**
 * Get formatter to display a value as a large number, trimmed to n (default:2) decimal places
 * @param len Number of figures after the decimal (default:2)
 * @param trim whether to truncate trailing non-significant figures (default: false)
 * @param useParentheses use parentheses to wrap negative values. If false, will use sign instead. Default: false
 */
const getLargeNumberFormat = (
  len: number = 2,
  trim: boolean = false,
  useParentheses: boolean = false,
  thousandSeparated = true
) => {
  return {
    output: 'number',
    mantissa: len,
    thousandSeparated,
    trimMantissa: trim,
    negative: useParentheses ? 'parenthesis' : 'sign'
  };
};

/**
 * Format as '%'.
 * Trims to 4 decimal places by default
 * @param raw Raw input number
 * @param len Number of figures after the decimal (default:4)
 * @param trim whether to truncate trailing non-significant figures (default: true)
 * @param useParentheses use parentheses to wrap negative values. If false, will use sign instead. Default: true
 * @param scaleDownBy100 optionally scale value down by 100 (default: false)
 */
export const FormatPct = (
  raw: number,
  len: number = 4,
  trim: boolean = true,
  useParentheses: boolean = true,
  scaleDownBy100: boolean = false
): string => {
  if (raw !== undefined) {
    const val = scaleDownBy100 ? raw / 100 : raw;
    return numbro(val).format(getPctFormat(len, trim, useParentheses) as any);
  } else {
    return DEFAULT_STRING;
  }
};

/**
 * Format large number. Trims to 2 decimal places by default
 * @param raw Raw input number
 * @param len Number of figures after the decimal (default:2)
 * @param trim whether to truncate trailing non-significant figures (default: false)
 * @param useParentheses use parentheses to wrap negative values. If false, will use sign instead. Default: false
 * @param thousandSeparated adds a delimiter
 */
export const FormatLargeNumber = (
  raw: number,
  len: number = 2,
  trim: boolean = false,
  useParentheses: boolean = false,
  thousandSeparated = true
): string => {
  if (raw !== undefined && raw !== null && !isNaN(raw)) {
    return numbro(raw).format(getLargeNumberFormat(len, trim, useParentheses, thousandSeparated) as any);
  } else {
    return DEFAULT_STRING;
  }
};

/**
 * Format a numeric value to some number (default: 2) of decimal places, with thousands separator,
 * without trimming non-significant values, without negative parentheses
 * @param params basic value formatter params
 * @param decimals number of decimal places to format (default: 2)
 * @param zeroAsEmpty whether to just return an empty string if a value of zero is received (default:false)
 */
export const FormatLargeVal = (
  params: Partial<ValueFormatterParams>,
  decimals: number = 2,
  zeroAsEmpty: boolean = false
): string => {
  if (params && params.value !== undefined) {
    if (params.value === 0 && zeroAsEmpty) {
      return '';
    } else if (params.value === '' && zeroAsEmpty) {
      return '';
    } else {
      return FormatLargeNumber(params.value, decimals, false, false);
    }
  } else {
    return '';
  }
};
