import type { Prettify } from '@oms/ui-util';
import { InvestorOrderStatus, ValstroEntitlements } from '@oms/generated/frontend';
import type { ActionDefFactory, ActionContext } from '@oms/frontend-vgrid';
import { t } from '@oms/codegen/translations';
import type { InvestorOrderWithChargesRow } from '@app/widgets/trading/investor-order-monitor/investor-order-monitor.contracts';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { runConfigButton } from '@app/actions/util/run-config-button.util';
import type {
  ActionCommandConfig,
  ActionCommandContracts,
  ActionCommandType
} from '@app/actions/commands/command.registry.types';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import investorOrderEntryBuilder from '../../investor-order-entry.form-builder';
import type { InvestorOrderEntryInput } from '../../investor-order-entry.form-common';
import type { InvestorOrderEntryValues } from '../../investor-order-entry.form-contract';
import { openInvestorOrderEntryFromActionButton } from '../../investor-order-entry.form-open';
import { MODIFY_INVESTOR_ORDER_ACTION_NAME } from './modify-investor-order.action.types';

// Types --------------------------------------------------------------------- /

export type ActionType = ActionCommandType;
export type ModifyInvestorOrderActionArgs = Prettify<Pick<InvestorOrderWithChargesRow, 'id'>> & {
  status?: string | InvestorOrderStatus;
};

// Action --------------------------------------------------------------------- /

export const createModifyInvestorOrderAction =
  <TData extends ModifyInvestorOrderActionArgs>(type: ActionType): ActionDefFactory<TData> =>
  (builder) =>
    builder
      .name(type === 'configurable' ? MODIFY_INVESTOR_ORDER_ACTION_NAME : 'modify_investor_order_static')
      .toolbar(
        type === 'configurable'
          ? (builder) =>
              builder
                .location('UserToolbar')
                .component('action-button')
                .id('modify_investor_order_config_button')
                .props({
                  variant: 'primary',
                  content: t('app.commands.modifyInvestorOrder.button'),
                  isDisabled: true
                })
          : null
      )
      .access(({ appContainer }) => {
        const authService = appContainer.resolve(AuthService);
        return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
      })
      .customMenu(
        type === 'context-menu'
          ? (m) =>
              m
                .name(t('app.commands.modifyInvestorOrder.contextMenu'))
                .tabName(t('app.common.grids.contextMenuTabs.action'))
                .priority(80)
                .visible(({ rowData }) => isActive(rowData))
                .primary()
          : null
      )
      .lifecycles('change', 'init', 'onSelectionChanged', 'onRowDataUpdated')
      .onChange<ActionCommandConfig<ActionCommandContracts['modify_investor_order']>>(
        modifyInvestorOrderActionOnChange
      );

// Util --------------------------------------------------------------------- /

/**
 * Re-useable function to handle the onChange lifecycle of the new investor order action
 * - This function will open the modify investor order form
 * - This function will also handle the initial values of the form (if config is provided)
 *
 * @param ctx - The action event
 * @returns Change function
 */
export async function modifyInvestorOrderActionOnChange<TData extends ModifyInvestorOrderActionArgs>(
  ctx: ActionContext<TData>
): Promise<void> {
  const { lifecycle, data, notify, workspace } = ctx;
  const [selectedRow] = data;
  const { id } = selectedRow || {};

  // -------- Handle button state --------

  const isDisabled = !isActive(data);

  notify({ isDisabled });

  if (lifecycle !== 'change' || isDisabled) {
    return;
  }

  // -------- Handle clicking the button --------

  notify({ isLoading: true });

  await runConfigButton<TData, InvestorOrderEntryInput, InvestorOrderEntryValues>({
    ctx,
    name: t('app.commands.modifyInvestorOrder.button'),
    formBuilder: investorOrderEntryBuilder,
    input: { entryType: 'update', id },
    fixedFormValueKeys: [],
    handleError: true,
    onOpenForm: async ({ initialValues, initialFeedback }) => {
      const result = await openInvestorOrderEntryFromActionButton(
        workspace,
        { id },
        {
          form: { initialValues, initialFeedback, input: { entryType: 'update', id } },
          title: t('app.commands.modifyInvestorOrder.formTitle')
        }
      );

      await result.mapSync(
        (_) => {},
        ([message, workspace]) => {
          const messageCtx = t('app.common.errorWithMessage', { message });
          void openMessageDialog(messageCtx, workspace).catch(console.error);
        }
      );
    }
  });

  notify({ isLoading: false });
}

/**
 * Re-useable function to determine if the button should be active
 *
 * @param rowData - The data from the grid
 * @returns Whether the button should be active
 */
function isActive(rowData?: ModifyInvestorOrderActionArgs[]): boolean {
  if (!rowData) return false;
  if (rowData.length === 0) return false;
  if (rowData.length > 1) return false;
  const [firstSelection] = rowData;
  if (!firstSelection.id) return false;
  return firstSelection.status === InvestorOrderStatus.Active;
}
