import type { IServerSideGetRowsParams, RowDataUpdatedEvent } from '@ag-grid-community/core';
import { BehaviorSubject, finalize, tap } from 'rxjs';
import type { Subscription } from 'rxjs';
import { TableServerService } from './table-server.service';
import {
  agFilterModelToTableServerFilterStr,
  agSortModelToTableServerSortStr
} from '@app/data-access/services/system/table-server/filters/ag-grid.table-server.transformer';
import type {
  GroupTableServerSubscription,
  TableServerConnectionKeys,
  TableServerConnectionState,
  TableServerDatasource,
  TableServerDatasources,
  TableServerDatasourceType,
  TableServerFactory,
  TableServerOptions,
  TableServerQueries,
  TableServerQueryFilter,
  TableServerQueryOptions,
  TableServerRow,
  TableServerRowSubscriptionVariables,
  TableServerSubscription,
  TableServerSubscriptionShape,
  WithTableServer
} from './table-server.datasource.contracts';
import { Logger } from '@oms/ui-util';
import type { EnrichedColumnDef } from '@app/common/grids/table-server/table-server.types';

const logger = Logger.named('Tableserver Datasource');

/**
 * Create grid datasources for tableserver queries
 * @param queries list of tables server queries
 * @param tableserverService service to subscribe to query from tableserver
 * @returns an object with the grid datasources / component props & an observable used to communicate if any of the datasources have connection issues.
 */
export const createDatasources = <
  TDatasourceKeys extends string,
  TDatasourceRecord extends Record<TDatasourceKeys, TableServerSubscription | GroupTableServerSubscription>,
  TProps = unknown
>(
  queries: TableServerQueries<TDatasourceKeys, TDatasourceRecord>,
  tableserverService: TableServerService
): {
  datasources: WithTableServer<TDatasourceKeys, TDatasourceRecord, TProps>['datasources'];
  connectionStates$: BehaviorSubject<
    Record<TableServerConnectionKeys<TDatasourceKeys>, TableServerConnectionState>
  >;
} => {
  const subscriptions = new Map<string, Subscription>();
  const connectionStates$ = new BehaviorSubject<
    Record<TableServerConnectionKeys<TDatasourceKeys>, TableServerConnectionState>
  >({} as Record<TableServerConnectionKeys<TDatasourceKeys>, TableServerConnectionState>);
  const datasources: TableServerDatasources<TDatasourceKeys, TDatasourceRecord> = {} as unknown as any;

  Object.entries<TableServerQueries<TDatasourceKeys, TDatasourceRecord>[TDatasourceKeys]>(queries).forEach(
    ([queryName, queryParams]) => {
      const notifyConnectionState = (
        connectionState: TableServerConnectionState,
        type: TableServerDatasourceType
      ) => {
        connectionStates$.next({
          ...connectionStates$.getValue(),
          [`${queryName}_${type}`]: connectionState
        });
      };

      const removeConnectionState = (query: string, type: TableServerDatasourceType) => {
        const connectionState = connectionStates$.getValue();
        delete connectionState[`${query as TDatasourceKeys}_${type}`];
        connectionStates$.next(connectionState);
      };

      const isAggregateQuery = 'aggregateQuery' in queryParams && 'detailQuery' in queryParams;

      const datasource = {
        asServerSideRowModel: (
          queryOverride: Parameters<
            TableServerDatasource<TDatasourceKeys, TDatasourceRecord>['asServerSideRowModel']
          >[0]
        ) => {
          let subscriptionKey: string | undefined;

          logger.debug(`Creating datasource for ${queryName}.`, { queryParams });

          return {
            getRows: (
              params: Parameters<
                ReturnType<
                  TableServerDatasource<TDatasourceKeys, TDatasourceRecord>['asServerSideRowModel']
                >['getRows']
              >[0]
            ) => {
              notifyConnectionState('initializing', 'server_side');
              const { groupKeys } = params.request;
              const isRowExpanded = Array.isArray(groupKeys) && groupKeys.length > 0;
              const queryFromConfig = isAggregateQuery
                ? isRowExpanded
                  ? queryParams.detailQuery
                  : queryParams.aggregateQuery
                : queryParams;
              const queryFromFn = queryOverride
                ? 'aggregateQuery' in queryOverride && 'detailQuery' in queryOverride
                  ? isRowExpanded
                    ? queryOverride.detailQuery
                    : queryOverride.aggregateQuery
                  : queryOverride
                : {};
              const query = {
                ...queryFromConfig,
                ...(queryFromFn || {})
              } as TableServerQueryOptions<TableServerRow, TableServerSubscriptionShape<TableServerRow>>;

              const variables = createVariables(
                query,
                params as unknown as IServerSideGetRowsParams<TableServerRow>,
                groupKeys
              );
              subscriptionKey = `${queryName}-${getSubscriptionKey(variables)}`;

              logger.debug('Grid datasource query is', {
                query,
                variables,
                subscriptionKey,
                isRowExpanded,
                queryParams
              });

              if (subscriptions.has(subscriptionKey)) {
                subscriptions.get(subscriptionKey)?.unsubscribe();
              }

              subscriptions.set(
                subscriptionKey,
                tableserverService
                  .query$({
                    ...query,
                    variables
                  })
                  .subscribe(({ totalCount, connectionState, rows }) => {
                    notifyConnectionState(connectionState, 'server_side');

                    const processedRows =
                      !isRowExpanded && isAggregateQuery
                        ? rows?.map((row) => ({ ...row, id: `group-${row.id}` }))
                        : rows;

                    const payload = {
                      rowData: processedRows || [],
                      rowCount: totalCount
                    };

                    logger.trace('Server side row model success', { payload });
                    params.success(payload);

                    // Dispatch a serverSideRowDataUpdated event to notify the grid action of the changes
                    const updatedEvent: RowDataUpdatedEvent = {
                      type: 'serverSideRowDataUpdated',
                      api: params.api,
                      context: {},
                      columnApi: params.columnApi
                    };

                    params.api.dispatchEvent(updatedEvent);
                  })
              );

              logger.trace(`Grabbing grid rows for ${queryName}.`, {
                isRowExpanded,
                groupKeys,
                query,
                variables,
                subscriptionKey,
                subscriptions
              });
            },
            destroy() {
              if (subscriptionKey && subscriptions.has(subscriptionKey)) {
                logger.info(`⚠️ Grid datasource destroyed for ${queryName}.`);
                subscriptions.get(subscriptionKey)?.unsubscribe();
                subscriptions.delete(subscriptionKey);
                removeConnectionState(queryName, 'server_side');
              }
            }
          };
        }
      } as TableServerDatasource<TDatasourceKeys, TDatasourceRecord>;

      if (!isAggregateQuery) {
        (datasource as TableServerFactory<any, any, any>).asObservable = (props) => {
          notifyConnectionState('initializing', 'subscription');

          const filterBy = agFilterModelToTableServerFilterStr(props?.filter || {});
          const sortBy = agSortModelToTableServerSortStr(props?.sort || []);

          return tableserverService
            .query$({
              variables: {
                limit: props?.limit || 50,
                offset: props?.offset || 0,
                filterBy,
                sortBy
              },
              query: queryParams.query,
              getData: queryParams.getData
            })
            .pipe(
              tap((e) => {
                notifyConnectionState(e.connectionState, 'subscription');
              }),
              finalize(() => {
                removeConnectionState(queryName, 'subscription');
              })
            );
        };
      }

      datasources[queryName as TDatasourceKeys] = datasource;
    }
  );

  logger.debug('Created datasources successfully.', { datasources });
  return { datasources, connectionStates$ };
};

export const createVariables = <
  TData extends TableServerRow,
  TSubscription extends TableServerSubscriptionShape<TData>
>(
  options: TableServerOptions<TData, TSubscription>,
  params: IServerSideGetRowsParams<TData>,
  groupKeys?: string[]
): TableServerRowSubscriptionVariables => {
  const { startRow = 0, endRow = 100, sortModel, filterModel } = params.request;
  const limit = endRow - startRow;
  const { filter, sort } = options;

  const groupColId = params.request.rowGroupCols?.[0]?.id;
  const filterValue = groupKeys?.[0];

  const queryFilter: TableServerQueryFilter<TData> = {
    ...filter,
    ...((groupColId && filterValue
      ? {
          [groupColId]: {
            ...(filter?.[groupColId] || {}),
            filter: filterValue
          }
        }
      : {}) as Partial<TableServerQueryFilter<TData>>),
    ...filterModel
  } as TableServerQueryFilter<TData>;

  // This allows a column to filter on a different column if it specifies useColumn in filterParams.
  const modifiedQueryFilter: TableServerQueryFilter<TData> = {};
  for (const key in queryFilter) {
    const colDef = params.api.getColumn(key)?.getColDef() as EnrichedColumnDef | undefined;
    const filterOnCol = colDef?.filterParams?.useColumn;
    modifiedQueryFilter[filterOnCol ? (filterOnCol as any) : (key as any)] = { ...queryFilter[key] };
  }

  // Using map to remove duplicates
  const mergedSort = [...new Map([...(sort || []), ...sortModel].map((item) => [item.colId, item])).values()];

  logger.debug('Filter models', { modifiedQueryFilter, mergedSort });

  return {
    filterBy: agFilterModelToTableServerFilterStr(modifiedQueryFilter),
    sortBy: agSortModelToTableServerSortStr(mergedSort),
    limit,
    offset: startRow
  };
};

export const getSubscriptionKey = (vars: TableServerRowSubscriptionVariables): string => {
  return `${vars.filterBy}-${vars.sortBy}-${vars.limit}-${vars.offset}`;
};
