import React, { useMemo, type CSSProperties } from 'react';
import clsx from 'clsx';
import * as styles from './field-wrapper.css';
import type { Prettify } from '@oms/ui-util';
import type { Meta } from '@data-driven-forms/react-form-renderer';
import {
  FormErrorMessage,
  FormControl,
  type FormControlProps,
  FormLabel,
  FormHelperText,
  Box
} from '@oms/ui-design-system';
import { isString } from 'lodash';

export type FieldWrapperProps = {
  meta?: Meta<any>;
  label?: string;
  helperText?: string;
  inline?: boolean;
  reverse?: boolean;
  hideFormControls?: boolean;
  /** @deprecated */
  isFeatureField?: boolean;
  isPrimaryField?: boolean;
  isVisible?: boolean;
  labelStyle?: CSSProperties;
};

export type AllFieldWrapperProps = Prettify<FieldWrapperProps & FormControlProps>;

// if using an inline field wrapper, correctly render the label before a control
const inlineLabelStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginRight: '10px'
} as React.CSSProperties;

export const FieldWrapper: React.FC<React.PropsWithChildren<AllFieldWrapperProps>> = ({
  meta,
  label = '',
  inline: _inline,
  reverse: _reverse,
  helperText,
  children,
  hideFormControls = false,
  isFeatureField = false,
  isPrimaryField = false,
  clipFormLabels = true,
  isVisible = true,
  className,
  sx = {},
  style: _style = {},
  autoFocus,
  hidden,
  id,
  isDisabled,
  isReadOnly,
  isRequired,
  requiredFieldIndicatorStyle,
  spacing
}) => {
  const formLabel = useMemo(() => (label ? label : <>&nbsp;</>), [label]);

  if (React.Children.count(children) !== 1) {
    throw new Error('FieldWrapper requires exactly 1 child.');
  }

  if (!isVisible) {
    return null;
  }

  const hasError =
    meta &&
    meta.touched &&
    (meta.error || (!meta.dirtySinceLastSubmit && meta.submitError)) &&
    (isString(meta.error) || isString(meta.submitError)) &&
    !meta.submitting;

  const isValid = meta && meta.touched ? meta.valid : true;

  const variant = isFeatureField ? 'feature' : isPrimaryField ? 'primary' : 'default';
  const size = isFeatureField || isPrimaryField ? 'lg' : undefined;
  const style = {
    marginBottom: hideFormControls ? 0 : '1rem',
    display: !isVisible ? 'none' : undefined,
    ..._style
  };
  const direction = _inline ? 'horizontal' : 'vertical';

  return (
    <FormControl
      autoFocus={autoFocus}
      direction={direction}
      hidden={hidden}
      id={id}
      isDisabled={isDisabled}
      isInvalid={hasError}
      isReadOnly={isReadOnly}
      isRequired={isRequired}
      requiredFieldIndicatorStyle={requiredFieldIndicatorStyle}
      spacing={spacing}
      sx={sx}
      style={style}
      clipFormLabels={clipFormLabels}
      variant={variant}
      size={size}
      className={clsx(className, styles.fieldWrapper, { [styles.invalid]: !isValid, [styles.valid]: isValid })}
    >
      <Box sx={{ position: 'relative' }}>
        {!hideFormControls && isFeatureField === false && !_inline && (
          <FormLabel title={label}>{formLabel}</FormLabel>
        )}
        {!hideFormControls && _inline && (
          <FormLabel className={styles.label} overrideStyles={{ ...inlineLabelStyle }} title={label}>
            {formLabel}
          </FormLabel>
        )}
      </Box>
      {children}
      {!hideFormControls && isFeatureField === true && (
        <Box sx={{ position: 'relative' }}>
          <FormLabel className={styles.label} style={{ top: 0, width: '100%' }} title={label}>
            {formLabel}
          </FormLabel>
        </Box>
      )}
      {!hideFormControls && (
        <Box sx={{ position: 'relative' }}>
          <Box sx={{ position: 'absolute' }} style={{ top: 0, width: '100%' }}>
            {hasError && (
              <FormErrorMessage className={styles.message}>{meta.error || meta.submitError}</FormErrorMessage>
            )}
            {helperText && !hasError && (
              <FormHelperText className={styles.message}>{helperText}</FormHelperText>
            )}
          </Box>
        </Box>
      )}
    </FormControl>
  );
};
