import type { LogLevel, Optional } from '@oms/ui-util';
import type { AlertBannerStackItem, AlertLevel } from '@oms/ui-design-system';
import {
  type FormValidationFeedbackLevelType,
  FormValidationFeedbackLevel,
  type FeedbackWrapper
} from '../../../../../graphql/graphql-envelope';

type InfoBannerProps = {
  type: 'ERROR' | 'WARNING';
  messages: string[];
  title?: string;
};

export const convertAlert = {
  infoBanner: {
    type: (type?: InfoBannerProps['type']) => ({
      toAlertLevel: (): AlertLevel => {
        switch (type) {
          case 'ERROR':
            return 'error';
          case 'WARNING':
            return 'warning';
          default:
            return 'info';
        }
      }
    }),
    messages: (level: AlertLevel, messages?: string[]) => ({
      toAlertBannerStackItems: (): AlertBannerStackItem[] => [
        {
          level,
          messages: messages || []
        }
      ]
    }),
    partialProps: (props: Omit<InfoBannerProps, 'type'>, level: AlertLevel) => ({
      toAlertBannerStackItems: (): AlertBannerStackItem[] => {
        const { messages, title } = props;
        return [
          {
            level,
            messages,
            title
          }
        ];
      }
    }),
    props: (props: InfoBannerProps) => ({
      toAlertBannerStackItems: (): AlertBannerStackItem[] => {
        const { type, ...rest } = props;
        const level = convertAlert.infoBanner.type(type).toAlertLevel();
        return convertAlert.infoBanner.partialProps(rest, level).toAlertBannerStackItems();
      }
    })
  },
  alertBanner: {
    level: (level?: AlertLevel) => ({
      toInfoBannerType: (): Optional<InfoBannerProps['type']> => {
        switch (level) {
          case 'error':
            return 'ERROR';
          case 'warning':
            return 'WARNING';
          default:
            return undefined;
        }
      },
      toFormValidationFeedbackLevel: (): FormValidationFeedbackLevelType => {
        switch (level) {
          case 'error':
            return FormValidationFeedbackLevel.Error;
          case 'warning':
            return FormValidationFeedbackLevel.Warning;
          default:
            return FormValidationFeedbackLevel.Info;
        }
      },
      toLogLevel: (): LogLevel => {
        switch (level) {
          case 'error':
            return 'error';
          case 'warning':
            return 'warn';
          case 'info':
            return 'info';
          default:
            return 'info';
        }
      }
    })
  },
  formValidationAlertItem: {
    level: (level?: FormValidationFeedbackLevelType) => ({
      toAlertLevel: (): AlertLevel => {
        switch (level) {
          case FormValidationFeedbackLevel.Error:
            return 'error';
          case FormValidationFeedbackLevel.Warning:
            return 'warning';
          default:
            return 'info';
        }
      }
    }),
    item: ({ level, message }: FeedbackWrapper) => ({
      toAlertBannerStackItem: (): AlertBannerStackItem => ({
        level: convertAlert.formValidationAlertItem.level(level).toAlertLevel(),
        messages: [message]
      })
    })
  },
  logLevel: (level?: LogLevel) => ({
    toAlertBannerLevel: (): Optional<AlertLevel> => {
      /** More strict... maps only to direct equivalent or undefined */
      switch (level) {
        case 'error':
          return 'error';
        case 'warn':
          return 'warning';
        case 'info':
          return 'info';
        default:
          return undefined;
      }
    },
    /** Coerces all but error and warning to info level  */
    toGuaranteedAlertBannerLevel: (): AlertLevel => {
      switch (level) {
        case 'error':
          return 'error';
        case 'warn':
          return 'warning';
        default:
          return 'info';
      }
    }
  })
};

export default convertAlert;
