import { COMMAND_PALETTE_GROUP } from '@app/commands/command-palette.constants';
import type { CommandPaletteItem } from '@app/common/command-palette/command-palette.contracts';
import { AppDatabase } from '@app/data-access/offline/app-database';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { CommandPaletteService } from '@app/data-access/services/system/command-palette/command-palette.service';
import { SnapshotsService } from '@app/data-access/services/system/snapshots/snapshots.service';
import { openAboutValstro, openConfirmation, openBugReporter } from '@app/generated/sdk';
import { exitTauriApp } from '@app/tauri/util';
import { reflow$ } from '@app/workspace/plugin-adaptive-layouts/adaptive-layouts.plugin';
import type { SignalUnsubscribeFn } from '@oms/rx-broadcast';
import { Actor, isTauri } from '@valstro/workspace';
import { inject, singleton } from 'tsyringe';
import type { RegisterCommand } from '../register-command.model';
import { testScoped } from '@app/workspace.registry';
import { AppWorkspace } from '@app/app-config/workspace.config';
import { getLeaderOrTabId } from '@app/common/workspace/workspace.util';
import type { AppWindowActorSchema } from '@app/app-config/workspace.config';
import { BUG_REPORTER_WINDOW_ID } from '@app/widgets/system/bug-reporter/bug-reporter.form-builder.config';
import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';

@testScoped
@singleton()
export class SystemCommands implements RegisterCommand {
  private authUnsubscribe: SignalUnsubscribeFn | undefined = undefined;
  constructor(
    @inject(AppWorkspace) private appWorkspace: AppWorkspace,
    @inject(CommandPaletteService) private cmpd: CommandPaletteService,
    @inject(AuthService) private authService: AuthService,
    @inject(AppDatabase) private appDatabase: AppDatabase,
    @inject(SnapshotsService) private snapshotsService: SnapshotsService,
    @inject(RxApolloClient) private apolloClient: RxApolloClient
  ) {}

  async init() {
    const systemItems: CommandPaletteItem[] = [
      {
        id: 'report-bug',
        label: 'Report a bug',
        category: COMMAND_PALETTE_GROUP.SYSTEM,
        onSelect: () => {
          openBugReporter()
            .then(() => {
              Actor.get<AppWindowActorSchema>(BUG_REPORTER_WINDOW_ID)
                // Make sure to show, because the window might be hidden & not closed.
                .then((actor) => actor.operations.show().catch(console.error))
                .catch(console.error);
            })
            .catch(console.error);
        }
      },
      {
        id: 'clear-remembered-windows',
        label: 'Clear window cache',
        category: COMMAND_PALETTE_GROUP.SYSTEM,
        onSelect: async () => {
          await this.snapshotsService.closeWindowsAll();
          await this.appDatabase.clearOffline({
            window_contexts: true
          });
        }
      },
      {
        id: 'reset-cache',
        label: 'Hard reset local settings & cache',
        category: COMMAND_PALETTE_GROUP.SYSTEM,
        onSelect: async () => {
          await this.snapshotsService.closeWindowsAll(); // This needs to be before the confirmation dialog to actually work, because it conflicts with the dialog itself
          const [_dialog, api] = await openConfirmation(
            this.appWorkspace,
            getLeaderOrTabId(this.appWorkspace),
            {
              componentProps: {
                message:
                  'This will reset local server caches & hard reset local user config such as additional form fields & window positions. Layouts & user preferences will not be affected. Are you sure you want to proceed?'
              }
            }
          );

          const event = await api.awaitFirstEvent;

          if (event.type !== 'OK') {
            this.snapshotsService.reloadCurrent().catch(console.error);
            return;
          }

          this.snapshotsService.clearActiveLayoutId();

          await Promise.all([this.appDatabase.clearOffline(), this.apolloClient.resetStore()]);

          this.authService.logout();
        }
      },
      {
        id: 'about',
        label: 'About Valstro',
        category: COMMAND_PALETTE_GROUP.SYSTEM,
        onSelect: () => {
          void openAboutValstro().catch(console.error);
        }
      },
      {
        id: 'reflow',
        label: 'Reflow windows',
        category: COMMAND_PALETTE_GROUP.SYSTEM,
        onSelect: () => {
          reflow$.next();
        }
      },
      {
        id: 'logout',
        label: 'Logout',
        category: COMMAND_PALETTE_GROUP.SYSTEM,
        onSelect: async () => {
          await this.snapshotsService.closeWindowsAll();
          this.authService.logout();
        }
      }
    ];

    if (isTauri()) {
      systemItems.push({
        id: 'exit',
        label: 'Exit',
        category: COMMAND_PALETTE_GROUP.SYSTEM,
        onSelect: () => {
          this.authService.logout();
          exitTauriApp().catch(console.error);
        }
      });
    }

    await this.cmpd.register(systemItems);
  }

  dispose() {
    if (this.authUnsubscribe) {
      this.authUnsubscribe();
    }
  }
}
