import type { AnyKey, AnyRecord, Maybe, OneOrMore, Optional } from '@oms/ui-util';
import { asArray, setOperations } from '@oms/ui-util';
import type { NestedTreeData } from '../../types/tree-data.types';
import { convert } from '../tree-data/tree-data-hierarchy.util';
import { findItemWithKeyInNestedTreeData } from '../tree-data/tree-data-key.util';
import { countAllChildrenOfNestedDataNode } from '../tree-data/tree-data-children.util';
import type { FilterTreeDataOptions } from '../internal/types';

// 🥅 Grid tree data filtering utils -------------------------------------------------------------- /
// 👁️ Internal to be used only by other grid utils
// Utils to filter nested tree data for the ag-Grid server side render model

/**
 * @private
 *
 * When filtering tree data from an ag-Grid set filter, ag-Grid provides an array of path keys
 * for leaf nodes only. An parents with all children selected are omitted.
 * This util helps identify which items to include in the filter.
 *
 * ### Rules:
 *  - Parents are considered selected with ALL children are in the filter
 *  - Otherwise, parents of selected nodes should NOT be included in the filter
 *
 * **NOTE:** There is Valstro-specific tree filtering logic in this, and may not be appropriate for all tree data filtering.
 *
 * @param pathKeys One more more path keys (each item's full hierarchy path), supplied by ag-Grid for the auto column filter
 * @param data One or more (object by itself or an array of) nested objects (with possible children)
 * @param options.getKey - Specify how to get the key value (id, etc.) from the data
 * @param options.separator - Specify how levels of the `pathKey` key are separated (defaults to ".")
 * @param options.convertString - A callback to set convert hierarchy value in case hierarchy key isn't a string
 * @returns A set of `id`s that represent the item's id rather than path key.
 */
export const getFilteredIdSetFromNestedData = <TData extends AnyRecord, Key extends AnyKey = string>(
  pathKeys: OneOrMore<Maybe<Key>>,
  data: OneOrMore<NestedTreeData<TData>>,
  options?: FilterTreeDataOptions<TData, Key>
): Set<Key> => {
  const { getKey, separator, convertString } = options ?? {};
  // Analyze the path keys ag-Grid gives us to determine all parents
  const parentToChildMap = asArray(pathKeys).reduce((parentToChildMap, pathKey) => {
    if (typeof pathKey === 'undefined' || pathKey === null) return parentToChildMap;
    const processHierarchyRecursive = (hierarchy: Key[], inheritedChildren?: Set<Key>) => {
      const key = hierarchy.length ? hierarchy[hierarchy.length - 1] : undefined;
      const parentHierarchy = hierarchy.slice(0, -1);
      if (key) {
        const parentId = parentHierarchy.length ? parentHierarchy[parentHierarchy.length - 1] : undefined;
        const childSet = parentToChildMap.get(parentId) ?? new Set<Key>();
        childSet.add(key);
        inheritedChildren?.forEach((childId) => {
          childSet.add(childId);
        });
        parentToChildMap.set(parentId, childSet);
        if (parentHierarchy.length) processHierarchyRecursive(parentHierarchy, childSet);
      }
    };
    const hierarchy = convert.pathKey(pathKey.toString()).toHierarchy({ separator, convertString });
    processHierarchyRecursive(hierarchy);
    return parentToChildMap;
  }, new Map<Optional<Key>, Set<Key>>());
  const includedIdSet = new Set<Key>();
  const partialParentSet = new Set<Key>();
  parentToChildMap.forEach((childrenSet, parentId) => {
    if (parentId) {
      const parentData = findItemWithKeyInNestedTreeData(parentId, data, { getKey });
      // Only include parent if all children are selected
      const childCount = parentData ? countAllChildrenOfNestedDataNode(parentData) : 0;
      if (childrenSet.size === childCount) {
        includedIdSet.add(parentId);
      } else {
        partialParentSet.add(parentId);
      }
    }
    childrenSet.forEach((childId) => {
      includedIdSet.add(childId);
    });
  });
  return setOperations.difference(includedIdSet, partialParentSet);
};
