import React from 'react';
import { Checkbox as CheckboxComponent, FormControl, FormErrorMessage } from '@oms/ui-design-system';
import type { FieldProps, ICommonTextField } from '../../types';
import { useFieldApi } from '../../helpers';
import { type FORM_COMPONENT_TYPE } from '../../contracts';
import { type Validator } from '@data-driven-forms/react-form-renderer';

export interface ICheckboxField<TValidator = Validator>
  extends ICommonTextField<typeof FORM_COMPONENT_TYPE.CHECKBOX, boolean, TValidator> {}

export const Checkbox: React.FC<FieldProps<ICheckboxField>> = (props) => {
  const {
    meta,
    label,
    input,
    isReadOnly,
    isRequired,
    isDisabled: _isDisabled,
    forceIsDisabled,
    isInvalid,
    requiredFieldIndicatorStyle
  } = useFieldApi<ICheckboxField>(props);

  // Sometimes validators override the disabled props. If forceIsDisabled is true, then we should always disable the field.
  const isDisabled = !!forceIsDisabled || !!_isDisabled;

  const error = meta.touched && meta.error ? meta.error : '';

  const extraProps = { isReadOnly, isRequired, isDisabled, isInvalid, requiredFieldIndicatorStyle };

  return (
    <FormControl {...extraProps}>
      <CheckboxComponent {...input} sx={{ paddingRight: 2 }}>
        {label}
      </CheckboxComponent>
      <FormErrorMessage>{error}&nbsp;</FormErrorMessage>
    </FormControl>
  );
};
