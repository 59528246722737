import {
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE,
  ROW_SELECTION_COUNT_ACTION_TYPE,
  useVGrid,
  VGrid
} from '@oms/frontend-vgrid';
import type { GridBuilder } from '@oms/frontend-vgrid';
import { GridConfigEventHandler } from '@app/data-access/services/system/grids/grid-config.event-handler';
import { TsTradesWithFilterDocument } from '@oms/generated/frontend';
import type { TradeRow, TsTradesWithFilterSubscription } from '@oms/generated/frontend';
import { buildExecutionViewTradesColumnDefs } from './execution-view.trade.columns';
import { viewTradeAction } from '../trade-monitor/grid-actions/view.trade.action';
import { openModifyTradeAction } from '../trade-monitor/grid-actions/open-modify.trade.action';
import { cancelTradeAction } from '../trade-monitor/grid-actions/cancel.trade.action';
import { refreshServersideCustomMenuAction } from '@app/common/grids/actions/refresh-serverside.action';
import { withTableServer } from '@app/data-access/services/system/table-server/with.table-server.component';

type ExecutionTradesSubsciptionKeys = 'execution_trades';

type ExecutionTradesSubscriptionMap = {
  execution_trades: {
    datasource: TradeRow;
    subscription: TsTradesWithFilterSubscription;
  };
};

type ExecutionTradesProps = {
  tradeId: string;
};

export const ExecutionTradesGrid = withTableServer<
  ExecutionTradesSubsciptionKeys,
  ExecutionTradesSubscriptionMap,
  ExecutionTradesProps
>(
  ({ datasources, props = {} }) => {
    const { tradeId } = props;

    const gridProps = useVGrid(
      'execution-view-trades',
      (b: GridBuilder<TradeRow>) =>
        b
          .tableServerColumnLibrary(buildExecutionViewTradesColumnDefs())
          .datasource((d) =>
            d
              .source(
                datasources.execution_trades.asServerSideRowModel({
                  filter: {
                    id: {
                      filterType: 'text',
                      type: 'equals',
                      filter: tradeId
                    }
                  }
                })
              )
              .rowId((r) => r.data.id)
              .cacheBlockSize(100)
          )
          .injectEvents([GridConfigEventHandler])
          .rowStateRules({})
          .sideBar()
          .rowSelection((c) => c.single())
          .actions((a) =>
            a.schema((s) =>
              s
                .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(viewTradeAction())
                .action(openModifyTradeAction())
                .action(cancelTradeAction())
                .action(ROW_SELECTION_COUNT_ACTION_TYPE)
                .action(refreshServersideCustomMenuAction)
            )
          )
          .reactiveCustomComponents(),
      [tradeId, datasources]
    );

    return <VGrid {...gridProps} />;
  },
  {
    execution_trades: {
      query: TsTradesWithFilterDocument,
      getData: (r) => r.tsTradesWithFilter
    }
  }
);
