import React, { useCallback, useMemo } from 'react';
import { useFormApi } from '@data-driven-forms/react-form-renderer';
import {
  SimpleGrid as SimpleGridComponent,
  Heading,
  Box,
  useElementSize,
  type SimpleGridProps
} from '@oms/ui-design-system';
import omit from 'lodash/omit';
import type { FieldProps, Field } from '../../types';
import { useFieldApi } from '../../helpers';
import { type ISimpleGridField } from './simple-grid.type';

const baseWidth = 12; // TODO: Make dynamic
const threeBreakInRems = baseWidth * 520;
const twoBreakInRems = baseWidth * 380;
const oneBreakInRems = baseWidth * 260;

export const SimpleGrid: React.FC<FieldProps<ISimpleGridField>> = (props) => {
  const [containerRef, containerSize] = useElementSize();
  const { renderForm } = useFormApi();
  const {
    fields,
    columns,
    label,
    columnGap,
    rowGap,
    input,
    isInArray,
    isResponsive = true,
    sx = {}
  } = useFieldApi<ISimpleGridField>(props);

  const modifiedFieldName = useCallback(
    (field: string) => {
      if (isInArray) {
        const fieldParts = input.name.split('.');
        const arrayIdx = fieldParts.findIndex((p) => p.includes(isInArray));

        const modified = fieldParts.slice(0, arrayIdx + 1).join('.');
        // console.log('modified', modified, `${modified}.${field}`);
        return `${modified}.${field}`;
      }

      return field;
    },
    [isInArray, input]
  );

  // Remove width/minWidth prop from fields in simple grid
  const cleanedFields = useMemo(
    () =>
      fields.map((fields: Field[]) => {
        if (Array.isArray(fields)) {
          return fields.map((field: Field) => {
            field = { ...field, name: modifiedFieldName(field.name) };
            if (field.style || field.sx) {
              const style = field.style ? omit(field.style, 'width', 'minWidth') : undefined;
              const sx = field.sx ? omit(field.sx, 'width', 'minWidth') : undefined;
              return {
                ...field,
                style,
                sx
              };
            } else {
              return field;
            }
          });
        } else {
          return fields;
        }
      }),
    [fields, modifiedFieldName]
  );

  // Adjust column size by container size
  const colsAdjustedForContainerSize: SimpleGridProps['columns'] = useMemo(() => {
    if (!containerSize.width || !isResponsive) {
      return columns as SimpleGridProps['columns'];
    }

    const widthInRems = containerSize.width * baseWidth;

    // make sure we're comparing numbers with numbers to make typescript happy
    const previousColumns = Number(columns);
    let cols = columns;
    if (widthInRems < threeBreakInRems && previousColumns > 3) {
      cols = 3;
    }

    if (widthInRems < twoBreakInRems && previousColumns > 2) {
      cols = 2;
    }

    if (widthInRems < oneBreakInRems && previousColumns > 1) {
      cols = 1;
    }
    return cols as SimpleGridProps['columns'];
  }, [containerSize.width, columns, isResponsive]);

  return (
    <Box sx={sx}>
      {label && (
        <Heading type="baseB" sx={{ mb: 4 }}>
          {label}
        </Heading>
      )}
      <SimpleGridComponent
        ref={containerRef}
        columns={colsAdjustedForContainerSize}
        rowGap={rowGap || 0}
        columnGap={columnGap}
        sx={{ alignItems: 'flex-end' }}
      >
        {cleanedFields.map((fields: Field[], i: number) => (
          // We use a fragment for the key and not a div so that we'll
          // be able to use gridColumn sprinkles like span in wrapperSx
          <React.Fragment key={i}>{renderForm(fields)}</React.Fragment>
        ))}
      </SimpleGridComponent>
    </Box>
  );
};
