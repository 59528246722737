import { ICellRendererParams } from '@ag-grid-community/core';
import React, { FC, useEffect, useMemo, useState } from 'react';
import get from 'lodash/get';
import { useWorkspaceContainer } from '@oms/frontend-foundation';
import { MarketDataService } from '../marketdata.service';
import { Subscription } from 'rxjs';
import { MarketData } from '../marketdata.common';
import { Level1IntegrationEvent, TickDirection } from '@oms/generated/frontend';
import { ArrowDownIcon, ArrowUpIcon } from '@oms/ui-design-system';
import * as Sentry from '@sentry/react';

const tickerRefDataKey = 'tickerId';

export const MarketDataCellRenderer: FC<ICellRendererParams> = Sentry.withProfiler(
  ({ api, node, column, formatValue, valueFormatted }) => {
    const workspaceContainer = useWorkspaceContainer();
    const [value, setValue] = useState(valueFormatted);
    const ticker = useMemo(() => {
      const tickerColumn = api.getAllGridColumns().find((c) => {
        const { refData = {} } = c.getColDef();
        return !!refData[tickerRefDataKey];
      });

      if (!tickerColumn) return;

      const { refData = {} } = tickerColumn.getColDef();

      return get(node.data, refData[tickerRefDataKey] || '');
    }, [api, node]);

    const columnId: keyof MarketData['level1'] | undefined = useMemo(() => {
      return column?.getColId() as keyof MarketData['level1'] | undefined;
    }, [column]);

    useEffect(() => {
      let tickerSub: Subscription | undefined;
      if (ticker) {
        const mktDataSvc = workspaceContainer.resolve(MarketDataService);

        tickerSub = mktDataSvc.observe(ticker).subscribe(({ data }) => {
          if (!data) return;

          const { level1 } = data;

          if (level1 && columnId) {
            const newValue = level1[columnId];
            setValue(newValue);
          }
        });
      }

      return () => {
        tickerSub?.unsubscribe();
      };
    }, [ticker]);

    const formattedValue = useMemo(() => {
      return formatValue ? formatValue(value) : value;
    }, [value, formatValue]);

    const L1Comp: React.FC<{ formattedValue: any }> = useMemo(() => {
      return columnId ? l1CompMap[columnId] || DefaultL1Comp : DefaultL1Comp;
    }, [columnId]);

    return <L1Comp formattedValue={formattedValue} />;
  },
  { name: 'Market Data Cell Renderer' }
);

const DefaultL1Comp: React.FC<{ formattedValue: any }> = ({ formattedValue }) => {
  return <>{formattedValue}</>;
};

const TickDirectionComp: React.FC<{ formattedValue: TickDirection }> = ({
  formattedValue: tickDirection
}) => {
  switch (tickDirection) {
    case TickDirection.UpTick:
      return (
        <span>
          <ArrowUpIcon sx={{ color: 'Green.500', fontSize: 'largeB', marginLeft: 1.5 }} />
        </span>
      );

    case TickDirection.DownTick:
      return (
        <span>
          <ArrowDownIcon sx={{ color: 'Red.500', fontSize: 'largeB', marginLeft: 1.5 }} />
        </span>
      );

    case TickDirection.UpUnchanged:
    case TickDirection.DownUnchanged:
    case TickDirection.EndOfDay:
    case TickDirection.NoTick:
    default:
      return null;
  }
};
const l1CompMap: Partial<Record<keyof Level1IntegrationEvent, React.FC<{ formattedValue: any }>>> = {
  lastTradeTickDirection: TickDirectionComp,
  bidTickDirection: TickDirectionComp,
  askTickDirection: TickDirectionComp
};
