import React, { useCallback } from 'react';
import { useInWindowContextMenuApi } from '@oms/ui-design-system';
import type { SimpleContextMenuItem } from '@oms/ui-design-system';
import { reactFlexLayoutEvents } from '@valstro/workspace-react';
import { COMPONENT_ENUM, LAYOUT_ENUM } from '@app/generated/common';
import { StackIcon } from '@radix-ui/react-icons';
import { Logger } from '@oms/ui-util';
import { withWindowMetaShouldRender } from '../components/with-window-meta-should-render';
import type { WindowMeta } from '../hooks/use-window-meta';

const l = Logger.named('WidgetSelectorWindowToolbarAction');

/**
 * Layout windows that are allowed to have widgets added to them
 */
export const ALLOWED_LAYOUT_WINDOWS: (keyof typeof LAYOUT_ENUM)[] = [
  'INVESTOR_ORDER_MONITOR',
  'TRADING_ORDER_MONITOR'
];

/**
 * Widgets available to add to the flex layout [key, title, category]
 */
const WIDGETS: Array<[keyof typeof COMPONENT_ENUM, string, 'Trading' | 'Mkt data' | 'Ref data']> = [
  // Trading
  ['INVESTOR_ORDER_MONITOR_GRID', 'Investor orders', 'Trading'],
  ['TRADING_ORDER_MONITOR_GRID', 'Trading orders', 'Trading'],
  ['TRADE_MONITOR_GRID', 'Trade monitor', 'Trading'],
  ['EXECUTION_MONITOR', 'Execution monitor', 'Trading'],
  ['NOTIFICATIONS', 'Notifications', 'Trading'],
  ['NEW_ORDERS_GRID', 'New orders', 'Trading'],
  ['PENDING_MODIFICATIONS_GRID', 'Pending modifications', 'Trading'],
  ['REPAIR_QUEUE_INVESTOR_ORDERS_GRID', 'Repair queue: IOs', 'Trading'],
  ['REPAIR_QUEUE_TRADES_GRID', 'Repair queue: Trades', 'Trading'],
  ['ROUTING_RULES_GRID', 'Routing rules', 'Trading'],
  // Market Data
  ['TIME_AND_SALES', 'Time and Sales', 'Mkt data'],
  // Ref Data
  ['EXCHANGES_GRID', 'Exchanges', 'Ref data'],
  ['CURRENCIES_GRID', 'Currencies', 'Ref data'],
  ['INSTRUMENT_RESTRICTIONS_GRID', 'Instrument Restrictions', 'Ref data']
];

/**
 * Widget selector window toolbar action
 *
 * This component is used to select a widget to add to the current flex layout.
 * It will open a context menu with the available widgets to add.
 * Then an event will be emitted to add the widget to the flex layout.
 */
export const WidgetSelectorWindowToolbarAction = withWindowMetaShouldRender(
  ({ childFlexLayoutActorId = '' }: WindowMeta) => {
    const api = useInWindowContextMenuApi();

    const onComponentOpen = useCallback(
      (key: string, title: string) => {
        reactFlexLayoutEvents
          .emit('addTabToActiveTabset', {
            tabJSON: {
              type: 'tab',
              name: title,
              component: key
            },
            flexLayoutActorId: childFlexLayoutActorId
          })
          .catch((error) => {
            l.error('Error adding widget to flex layout', error);
          });
      },
      [childFlexLayoutActorId]
    );

    const onIconClick = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        const options: SimpleContextMenuItem[] = WIDGETS.map(([key, title, category]) => {
          const item: SimpleContextMenuItem = {
            id: key.toString(),
            label: title,
            tabName: category,
            onClick: () => {
              onComponentOpen(key.toString(), title);
            }
          };
          return item;
        });

        api.open(
          {
            clientX: e.clientX,
            clientY: e.clientY
          },
          options
        );
      },
      [api, onComponentOpen]
    );

    return (
      <button aria-label="Add new widget" className="window__toolbar__action" onClick={onIconClick}>
        <StackIcon />
      </button>
    );
  },
  (meta) => {
    if (!meta.widgetType || !meta.childFlexLayoutActorId) {
      return false;
    }

    return ALLOWED_LAYOUT_WINDOWS.toString().includes(meta.widgetType);
  }
);
