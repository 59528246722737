/**
 * Type alias for any type that can be the key of a plain JS object.
 */
export type AnyKey = string | number | symbol;

const isAnyKey = (input: unknown): input is AnyKey => {
  return typeof input === 'string' || typeof input === 'number' || typeof input === 'symbol';
};

/**
 * Type alias for any plain JS object.
 */
export type AnyRecord = Record<AnyKey, unknown>;

/**
 * Describes any object with a `key` property.
 */
export type Keyed<K extends AnyKey = string> = {
  key: K;
};

export const isKeyed = <K extends AnyKey = string>(input: unknown): input is Keyed<K> => {
  if (typeof input !== 'object') return false;
  if (input === null) return false;
  return isAnyKey((input as Partial<Keyed>).key);
};

/**
 * Describes any object with a `value` property.
 */
export type Valued<V = string> = {
  value: V;
};

export const isValued = <V = string>(input: unknown): input is Valued<V> => {
  if (typeof input !== 'object') return false;
  if (input === null) return false;
  return typeof (input as Partial<Valued>).value !== 'undefined';
};

/**
 * Describes any object with a `rawValue` property.
 */
export type RawValued<V = unknown> = {
  rawValue: V;
};

export const isRawValued = <V = unknown>(input: unknown): input is RawValued<V> => {
  if (typeof input !== 'object') return false;
  if (input === null) return false;
  return typeof (input as Partial<RawValued>).rawValue !== 'undefined';
};

/**
 * Describes any object with a `label` property.
 */
export type Labeled<L = string> = {
  label: L;
};

export const isLabeled = <L = string>(input: unknown): input is Labeled<L> => {
  if (typeof input !== 'object') return false;
  if (input === null) return false;
  return typeof (input as Partial<Labeled>).label !== 'undefined';
};

/**
 * Describes any object with a `title` property.
 */
export type Titled<T = string> = {
  title: T;
};

export const isTitled = <T = string>(input: unknown): input is Titled<T> => {
  if (typeof input !== 'object') return false;
  if (input === null) return false;
  return typeof (input as Partial<Titled>).title !== 'undefined';
};

/**
 * Describes any object with a `description` property.
 */
export type Described<D = string> = {
  description: D;
};

export const isDescribed = <D = string>(input: unknown): input is Described<D> => {
  if (typeof input !== 'object') return false;
  if (input === null) return false;
  return typeof (input as Partial<Described>).description !== 'undefined';
};

/**
 * Describes any object with a `id` property.
 */
export type Identifiable<I extends AnyKey = string> = {
  id: I;
};

export const isIdentifiable = <I extends string = string>(input: unknown): input is Identifiable<I> => {
  if (typeof input !== 'object') return false;
  if (input === null) return false;
  return isAnyKey((input as Partial<Identifiable>).id);
};

/**
 * Describes any object with a `identifier` property.
 */
export type Identified<I extends string = string> = {
  identifier: I;
};

export const isIdentified = <I extends string = string>(input: unknown): input is Identified<I> => {
  if (typeof input !== 'object') return false;
  if (input === null) return false;
  return typeof (input as Partial<Identified>).identifier === 'string';
};

/**
 * Describes any object with a `name` property.
 */
export type Named<N = string> = {
  name: N;
};

export const isNamed = <N = string>(input: unknown): input is Named<N> => {
  if (typeof input !== 'object') return false;
  if (input === null) return false;
  return typeof (input as Partial<Named<N>>).name !== 'undefined';
};

/**
 * Describes any object with a `code` property.
 */
export type Coded<N = string> = {
  code: N;
};

export const isCoded = <N = string>(input: unknown): input is Coded<N> => {
  if (typeof input !== 'object') return false;
  if (input === null) return false;
  return typeof (input as Partial<Coded<N>>).code !== 'undefined';
};

/**
 * Describes any object with a `count` property.
 */
export type Counted = {
  count: number;
};

export const isCounted = (input: unknown): input is Counted => {
  if (typeof input !== 'object') return false;
  if (input === null) return false;
  return typeof (input as Partial<Counted>).count === 'number';
};

/**
 * Describes any object with a `quantity` property.
 */
export type Quantifiable<Q = number> = {
  quantity: Q;
};

export const isQuantifiable = (input: unknown): input is Quantifiable => {
  if (typeof input !== 'object') return false;
  if (input === null) return false;
  return typeof (input as Partial<Quantifiable>).quantity !== 'undefined';
};

/**
 * Describes any object with a `src` property.
 */
export type Sourced<S = string> = {
  src: S;
};

export const isSourced = <S = string>(input: unknown): input is Sourced<S> => {
  if (typeof input !== 'object') return false;
  if (input === null) return false;
  return typeof (input as Partial<Sourced<S>>).src !== 'undefined';
};

/**
 * Describes any object with a `children` property.
 */
export type Parent<Child> = {
  children: Child[];
};

export const isParent = <Child>(input: object): input is Parent<Child> => {
  if (input === null) return false;
  return (input as Partial<Parent<Child>>).children instanceof Array;
};

/**
 * Describes any object with a `src` property.
 */
export type Hidable = {
  hidden: boolean;
};

export const isHidable = (input: unknown): input is Hidable => {
  if (typeof input !== 'object' || input === null) return false;
  return typeof (input as Partial<Hidable>).hidden === 'boolean';
};

/**
 * Describes any object with a `isDisabled` property.
 */
export type Disableable = {
  isDisabled: boolean;
};

export const isDisableable = (input: unknown): input is Disableable => {
  if (typeof input !== 'object' || input === null) return false;
  return typeof (input as Partial<Disableable>).isDisabled === 'boolean';
};

/**
 * Describes any object with a `timestamp` property containing a Unix timestamp.
 */
export type Timestamped<T = number> = {
  timestamp: T;
};

export const isTimestamped = <T>(input: unknown): input is Timestamped<T> => {
  if (typeof input !== 'object' || input === null) return false;
  return typeof (input as Partial<Timestamped<T>>).timestamp !== 'undefined';
};

/**
 * Describes any object or type with a `init` method to perform initialization effects.
 */
export type Initializable<R = void> = {
  init: () => R;
};

export const isInitializable = <R = void>(input: any): input is Initializable<R> => {
  if (typeof input !== 'object' || input === null) return false;
  const { init } = input as Partial<Initializable<R>>;
  return typeof init === 'function';
};

/**
 * @deprecated Prefer using `Destroyable`
 * Describes any object or type with a `deinit` method to perform deinitialization effects.
 */
export type Deinitializable<R = void> = {
  deinit: () => R;
};

export const isDeinitializable = <R = void>(input: any): input is Deinitializable<R> => {
  if (typeof input !== 'object' || input === null) return false;
  const { deinit } = input as Partial<Deinitializable<R>>;
  return typeof deinit === 'function';
};

/**
 * Describes any object or type with a `deinit` method to perform deinitialization effects.
 */
export type Destroyable<R = void> = {
  destroy: () => R;
};

export const isDestroyable = <R = void>(input: any): input is Destroyable<R> => {
  if (typeof input !== 'object' || input === null) return false;
  const { destroy } = input as Partial<Destroyable<R>>;
  return typeof destroy === 'function';
};

/**
 * Describes any object with a `from` and `to` range.
 */
export type Segmented<T = number> = {
  from: T;
  to?: T;
};

export const isSegmented = <T = number>(input: unknown): input is Segmented<T> => {
  if (typeof input !== 'object' || input === null) return false;
  const { from } = input as Partial<Segmented<T>>;
  return typeof from !== 'undefined';
};

// Common compound types --------------- /

export type ValuedAndLabeled<V = string, L = string> = Valued<V> & Labeled<L>;

export const isValuedAndLabeled = <V = string, L = string>(
  input: unknown
): input is ValuedAndLabeled<V, L> => {
  if (!isValued<V>(input)) return false;
  if (!isLabeled<L>(input)) return false;
  return true;
};

export type ValuedWithPossibleLabel<V = string, L = string> = Valued<V> & Partial<Labeled<L>>;

export const isValuedWithPossibleLabel = <V = string, L = string>(
  input: unknown
): input is ValuedWithPossibleLabel<V, L> => isValued<V>(input);

/** A type for flexible input that can be converted to `ValuedAndLabeled` */
export type ValuedAndLabeledInput<V = string, L = string> = ValuedWithPossibleLabel<V, L> | V;

export const inputToValuedAndLabeled = <V = string, L extends string = string>(
  input: ValuedAndLabeledInput<V, L>
): ValuedAndLabeled<V, L> => {
  const toLabel = (val: any): L => {
    if (typeof val === 'object' && val !== null) {
      if (val instanceof Array) return val.flatMap((v) => toLabel(v)).join(', ') as L;
      if (typeof val.label === 'string') return val.label as L;
      if (typeof val.description === 'string') return val.description as L;
      return val.toString() as L;
    }
    return val.toString() as L;
  };
  if (isValuedWithPossibleLabel<V, L>(input)) {
    const { value, label } = input;
    return { value, label: label ?? toLabel(value) };
  } else {
    return { value: input, label: toLabel(input) };
  }
};

/**
 * Keeps properties but swaps value to any
 */
export type AnyProp<T, V = any> = {
  [P in keyof T]: V;
};

/**
 * Makes a record shallow
 */
export type TShallowRecord<T> =
  T extends Record<string, unknown> ? Record<string, any> : T extends Array<unknown> ? Array<any> : T;

/**
 * Prettifies an object type
 */
export type Prettify<T> = {
  [K in keyof T]: T[K];
} & {};
