import type { FC } from 'react';
import { Fragment, useMemo } from 'react';
import { asArray } from '@oms/ui-util';
import { Text, TextProps } from '../../../../layout/text/text';
import { DISPLAY_FIELD_COMPONENT_TYPE } from '../../display-field.contracts';
import { TextEllipser } from '../../../text-ellipser/text-ellipser';
import type { FormatType } from '../../../../formatting/format';
import { format, isDateTimeFormat, isNumericFormat } from '../../../../formatting/format';

type ValueProps = string | { text: string; textProps?: TextProps };

export type TextFieldProps = {
  type: typeof DISPLAY_FIELD_COMPONENT_TYPE.Text;
  value: ValueProps | ValueProps[];
  textProps?: TextProps;
  formatType?: FormatType;
};

export const TextField: FC<TextFieldProps> = ({ value, textProps, formatType }) => {
  const items = useMemo(() => {
    const values = asArray(value);
    return values.map((value, index) => {
      const input = typeof value === 'object' ? value?.text : value;
      const formatted = formatType ? applyFormatting(formatType, input) : input;

      return (
        <Fragment key={`${input}-${index}`}>
          <Text {...(typeof value === 'object' ? value?.textProps : textProps)} data-raw-value={input}>
            {formatted}
            {index < values.length - 1 ? <>&nbsp;</> : null}
          </Text>
        </Fragment>
      );
    });
  }, [value, textProps]);

  return <TextEllipser>{items}</TextEllipser>;
};

function applyFormatting(
  type: FormatType,
  input: string,
): string {
  if (isNumericFormat(type)) {
    return format(type, input);
  }
  if (isDateTimeFormat(type)) {
    return format(type, input);
  }
  return format(type, input);
};
