import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Box, Spinner } from '@oms/ui-design-system';
import type { AtdlFieldPartial } from '@app/forms/fixatdl/components/ddf-transformer/controls/control.transformer.types';
import { useService } from '@oms/frontend-foundation';
import { FIXatdlStrategyFormRenderer } from './fixatdl-strategy-field-form-renderer';
import { StrategyService } from './fixatdl-strategy-field.service';
import type { FIXatdlMeta, FIXatdlStrategyValue } from './fixatdl-strategy-field.contracts';
import { useCurrentAppWidgetActorProps } from '@app/common/workspace/workspace.hooks';

type FIXatdlStrategyDialogProps = FIXatdlStrategyValue & FIXatdlMeta;

const FIXatdlStrategyDialog: FC<FIXatdlStrategyDialogProps> = () => {
  const [{ strategyName, strategyParams, strategyControls, venueId, formId }] =
    useCurrentAppWidgetActorProps<FIXatdlStrategyDialogProps>();

  const [isLoading, setIsLoading] = useState(false);
  const [ddfFields, setDdfFields] = useState<AtdlFieldPartial[]>([]);

  const service = useService(StrategyService);

  useEffect(() => {
    setIsLoading(true);
    service
      .fetchFIXATDLJSON(venueId, strategyName, strategyParams, strategyControls)
      .then((fields) => {
        setDdfFields(fields);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [strategyName, venueId, service, strategyParams, strategyControls]);

  return (
    <Box sx={{ bgColor: 'layout.level1', padding: 2, overflowY: 'scroll' }}>
      {isLoading ? (
        <Spinner fillArea />
      ) : (
        <FIXatdlStrategyFormRenderer
          fields={ddfFields}
          strategyName={strategyName}
          venueId={venueId}
          formId={formId}
        />
      )}
    </Box>
  );
};

export default FIXatdlStrategyDialog;
