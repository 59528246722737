import type { OrderEntryType } from '@oms/generated/frontend';
import type { Maybe } from '@oms/ui-util';
import { orderEntryTypeMapping } from './order-enums';

export const mapOrderEntryType = (
  value?: Maybe<OrderEntryType> | string,
  defaultVal = value || ''
): string => {
  return value && orderEntryTypeMapping[value as OrderEntryType]
    ? orderEntryTypeMapping[value as OrderEntryType]
    : defaultVal;
};
