// 📦 Type Imports
import type { CellClassParams, CellClassRules, ICellRendererParams } from '@ag-grid-community/core';
import type { Optional, Prettify } from '@oms/ui-util';

// 🔄 Value Imports
import { OrderSideType } from '@oms/generated/frontend';
import clsx from 'clsx';
import { priceDisplayStyle } from '@oms/ui-design-system-css';
import type { PriceDisplayType } from '@oms/ui-design-system-css';
import { ORDER_SIDE_DISPLAY_VALUE_CLASS_NAME } from './order-side-display.constants';
import { isOrderInactive } from './order-status.util';

// -------------------------------------------------------------------
// 📊 Constants
// -------------------------------------------------------------------

/**
 * Maps price display types to their corresponding OrderSideType values
 */
const PriceDisplayTypes: Record<Exclude<PriceDisplayType, 'inactive'>, Set<OrderSideType>> = {
  positive: new Set([OrderSideType.Btc, OrderSideType.Buy]),
  negative: new Set([OrderSideType.Exempt, OrderSideType.Sell, OrderSideType.Short])
};

// -------------------------------------------------------------------
// 🏷️ Classification Functions
// -------------------------------------------------------------------

/**
 * Order side styling options
 */
type OrderSideStyleTypeOptions = {
  /** Whether the order status is inactive */
  isInactive?: boolean;
};

/**
 * Determines the appropriate price display type for an order side
 * @param side The order side to classify
 * @param options Configuration options
 * @param options.isInactive Whether the order status is inactive
 * @returns The price display type or undefined if not applicable
 */
export function getPriceDisplayType(
  side: OrderSideType | string,
  options?: OrderSideStyleTypeOptions
): Optional<PriceDisplayType> {
  if (options?.isInactive) return 'inactive';
  const sideType = typeof side === 'string' ? (side.toUpperCase() as OrderSideType) : side;
  if (PriceDisplayTypes.positive.has(sideType)) return 'positive';
  if (PriceDisplayTypes.negative.has(sideType)) return 'negative';
  return undefined;
}

/**
 * Determines the price display type for a grid cell containing order side values
 * @template TData - The type of the row data in the grid
 * @template TValue - The type of the cell value
 * @param cell - The AG Grid cell parameters
 * @returns The price display type or undefined if not applicable
 */
export function getGridPriceDisplayType<TData = any, TValue = any>(
  cell: CellClassParams<TData, TValue>
): Optional<PriceDisplayType> {
  const { data, value } = cell;
  if (typeof value !== 'string') {
    return undefined;
  }
  const isInactive = typeof data !== 'undefined' ? isOrderInactive(data) : false;
  return getPriceDisplayType(value, { isInactive });
}

// -------------------------------------------------------------------
// 🎨 Styling Functions
// -------------------------------------------------------------------

/**
 * Order side styling options
 */
type OrderSideStyleOptions = Prettify<
  OrderSideStyleTypeOptions & {
    /** Additional class names to include */
    additionalClassName?: string;
  }
>;

/**
 * Returns the appropriate class name for an order side based on its price display type
 * @param side The order side to get styling for
 * @param options Configuration options
 * @param options.isInactive Whether the order status is inactive
 * @param options.additionalClassName Additional class names to include
 * @returns A CSS class name (string)
 */
export function getOrderSideClassName(side: OrderSideType | string, options?: OrderSideStyleOptions): string {
  const { isInactive, additionalClassName } = options || {};
  return clsx(
    priceDisplayStyle({ type: getPriceDisplayType(side, { isInactive }) }),
    ORDER_SIDE_DISPLAY_VALUE_CLASS_NAME,
    additionalClassName
  );
}

/**
 * Returns appropriate styling classes for an order side in a cell renderer context
 * @template TData - The type of the row data in the grid
 * @template TValue - The type of the cell value
 * @param cell - The AG Grid cell renderer parameters
 * @returns The CSS class name for styling or undefined if not applicable
 */
export function getCellRendererOrderSideClassName<TData = any, TValue = any>(
  cell: ICellRendererParams<TData, TValue>
): Optional<string> {
  const { data, value } = cell;
  if (typeof value !== 'string' || typeof data === 'undefined' || data === null) {
    return undefined;
  }
  const isInactive = isOrderInactive(data);
  return getOrderSideClassName(value, { isInactive });
}

/**
 * Creates AG Grid cell class rules for styling order side values
 * @template TData - The type of the row data in the grid
 * @template TValue - The type of the cell value
 * @returns AG Grid cell class rules object that applies appropriate styling based on order side and status
 */
export function getGridOrderSideCellClassRules<TData = any, TValue = any>(): CellClassRules<TData, TValue> {
  return {
    [ORDER_SIDE_DISPLAY_VALUE_CLASS_NAME]: (_) => true,
    [priceDisplayStyle({ type: 'positive' })]: (cell) => getGridPriceDisplayType(cell) === 'positive',
    [priceDisplayStyle({ type: 'negative' })]: (cell) => getGridPriceDisplayType(cell) === 'negative',
    [priceDisplayStyle({ type: 'inactive' })]: (cell) => getGridPriceDisplayType(cell) === 'inactive'
  };
}
