import type { AnySimpleAccount } from '@app/common/types/accounts/types';
import { isAnySimpleAccount } from '@app/common/types/accounts/utils';
import AccountsService from '@app/data-access/services/reference-data/accounts/accounts.service';
import { ParentAccountsService } from '@app/data-access/services/reference-data/accounts/parent-accounts.service';
import type { AdvancedSelectQueryFn } from '@oms/frontend-foundation';
import { getAdvancedSelectQueryReturn } from '@oms/frontend-foundation';
import type { AdvancedSelectPredicate, IAdvancedSelectField } from '@oms/frontend-foundation';
import type { ComboBoxItem } from '@oms/ui-design-system';
import { comboBoxItemFrom } from '@oms/ui-design-system';
import type { GroupingAccountFragment, InvestorAccountFragment } from '@oms/generated/frontend';
import { isLabeled } from '@oms/ui-util';
import type { Optional } from '@oms/ui-util';

export type AccountId = string;
export type AdvancedSelectAccountFieldValue = ComboBoxItem<AccountId>;
export type AdvancedSelectAccountField = IAdvancedSelectField<AccountId>;

type AccountPredicate = AdvancedSelectPredicate<InvestorAccountFragment>;

export type AccountComboBoxData = InvestorAccountFragment | AnySimpleAccount;
export type AccountComboBoxValue = AccountId;

export type ParentAccountComboBoxData = GroupingAccountFragment;
export type ParentAccountComboBoxValue = AccountId;

export const accountResultMapper = (
  investorAccount: AccountComboBoxData
): Optional<ComboBoxItem<AccountComboBoxValue>> =>
  comboBoxItemFrom.recordWithStringValue(investorAccount, {
    id: ({ id }) => id || '',
    label: (data) => (isLabeled(data) ? data.label : (data.name ?? data.id ?? '-')),
    value: ({ id }) => id || '',
    sublabel: ({ description }) => description || '',
    isDisabled: (account) => (isAnySimpleAccount(account) ? account.isDisabled : undefined)
  });

export const simpleAccountMapper = (simpleAccount: AnySimpleAccount): ComboBoxItem<AccountComboBoxValue> => {
  return {
    id: simpleAccount.id,
    label: simpleAccount.label,
    type: 'item',
    value: simpleAccount.id,
    isDisabled: simpleAccount.isDisabled
  };
};

export type AccountQueryType =
  | 'all'
  | 'visibleAccounts'
  | 'visibleFirmAccounts'
  | 'firmAccounts'
  | 'intermediaryAccounts'
  | 'positionsAccess';

interface MakeWatchAllAccountsQueryOptions {
  type?: AccountQueryType;
  filter?: AccountPredicate;
}

type AccountQueryFn = Parameters<
  typeof getAdvancedSelectQueryReturn<AccountComboBoxData, AccountComboBoxValue>
>[0]['queryFn'];

const makeWatchAllAccountsQuery = (
  options?: MakeWatchAllAccountsQueryOptions
): AdvancedSelectQueryFn<AccountComboBoxValue> => {
  const { filter, type = 'all' } = options ?? {};
  return (container) => {
    const accountsService = container.resolve(AccountsService);
    const queryFn: AccountQueryFn = (() => {
      switch (type) {
        case 'visibleAccounts':
          return accountsService.watchAllVisibleAccounts$.bind(accountsService);
        case 'visibleFirmAccounts':
          return accountsService.watchAllVisibleFirmAccounts$.bind(accountsService);
        case 'firmAccounts':
          return accountsService.watchAllFirmAccounts$.bind(accountsService);
        case 'intermediaryAccounts':
          return accountsService.watchAllIntermediaryAccounts$.bind(accountsService);
        case 'positionsAccess':
          return accountsService.watchAllPositionsAccessAccounts$.bind(accountsService);
        default:
          return accountsService.watchAll$.bind(accountsService);
      }
    })();

    return getAdvancedSelectQueryReturn<AccountComboBoxData, AccountComboBoxValue>({
      queryFn,
      resultMapper: accountResultMapper,
      filter
    });
  };
};

export const watchAllAccountsWithFilter: AdvancedSelectQueryFn<AccountComboBoxValue> = (
  container,
  filter?: AccountPredicate
) => makeWatchAllAccountsQuery({ filter })(container);

export const watchAllVisibleAccountsWithFilter: AdvancedSelectQueryFn<AccountComboBoxValue> = (
  container,
  filter?: AccountPredicate
) => makeWatchAllAccountsQuery({ filter, type: 'visibleAccounts' })(container);

export const watchAllAccountsQuery = makeWatchAllAccountsQuery();

export const watchAllVisibleAccountsQuery = makeWatchAllAccountsQuery({ type: 'visibleAccounts' });

export const watchAllVisibleFirmAccountsQuery = makeWatchAllAccountsQuery({
  type: 'visibleFirmAccounts'
});

export const watchAllFirmAccountsQuery = makeWatchAllAccountsQuery({
  type: 'firmAccounts'
});

export const watchAllIntermediaryAccountsQuery = makeWatchAllAccountsQuery({
  type: 'intermediaryAccounts'
});

export const watchAllPositionsAccessAccountsQuery = makeWatchAllAccountsQuery({
  type: 'positionsAccess'
});

export const parentAccountResultMapper = (
  parentAccount: ParentAccountComboBoxData
): Optional<ComboBoxItem<ParentAccountComboBoxValue>> =>
  comboBoxItemFrom.recordWithStringValue(parentAccount, {
    id: ({ id }) => id || '',
    label: ({ name, id }) => name || id || '-',
    value: ({ id }) => id || '',
    sublabel: ({ description }) => description || ''
  });

export const watchAllParentAccounts: AdvancedSelectQueryFn<AccountId> = (container) => {
  const parentAccountService = container.resolve(ParentAccountsService);

  return getAdvancedSelectQueryReturn<ParentAccountComboBoxData, ParentAccountComboBoxValue>({
    queryFn: parentAccountService.watchAll$.bind(parentAccountService),
    resultMapper: parentAccountResultMapper
  });
};

export default watchAllAccountsQuery;
