import { ApolloLink, HttpLink, split, from, Operation } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import type { ErrorLink } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { MessageType, createClient, stringifyMessage } from 'graphql-ws';
import type { Client } from 'graphql-ws';
import type { WildcardMockLink } from 'wildcard-mock-link';
import { getEnvVar } from '@oms/ui-util';
import { createGraphQlTracingLink } from './apollo-client.tracing';
import { createLogger } from '@oms/ui-util';
import { container } from 'tsyringe';
import { AuthService } from '../services/system/auth/auth.service';
import { RxApolloClient } from './rx-apollo-client';
import { BroadcastInMemoryCache } from './broadcast-apollo-cache';
import * as Sentry from '@sentry/react';
import { RetryLink } from '@apollo/client/link/retry';
import { apolloClientErrorHandler } from './apollo-client.error-handler';
import { GraphqlSocketSignal, GraphqlSocketState } from '../memory/graphql.socket.signal';
import { retrySocketConnection } from './apollo-ws.retry';
import { SentryLink } from 'apollo-link-sentry';

/**
 * Logger
 */
export const apolloLogger = createLogger({ name: 'Apollo' });

/**
 * Common type for getting the auth token
 */
export type ApolloClientGetAuthToken = () => string;

/**
 * Get the GraphQL server URL
 *
 * @returns string
 */
const getGraphQlServerUrl = (): string => {
  if (getEnvVar('NX_NEST_API_HOST')) {
    return `${getEnvVar('NX_NEST_API_HOST')}${
      getEnvVar('NX_NEST_API_PORT') ? `:${getEnvVar('NX_NEST_API_PORT')}` : ''
    }`;
  } else {
    return window.location.host;
  }
};

// Don't include a port if none specified
const graphqlServerUrl = () => getGraphQlServerUrl();

/**
 * Create an Apollo Auth Link that adds the auth token to the request headers
 *
 * @param getAuthToken - Function to get the auth token
 * @returns ApolloLink
 */
const createApolloAuthLink = (getAuthToken?: ApolloClientGetAuthToken) =>
  new ApolloLink((operation, forward) => {
    const token = typeof getAuthToken === 'function' ? getAuthToken() : '';

    if (!token) {
      apolloLogger.warn('No token found, most likely because it has expired. Logging user out.');
      const authService = container.resolve(AuthService);
      authService.logout();
    }

    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    }));

    return forward(operation);
  });

/**
 * Create an Apollo HTTP link that adds the auth token to the request headers
 *
 * @returns HttpLink
 */
const createApolloHttpLink = () =>
  new HttpLink({
    uri: `${getEnvVar('NX_NEST_API_SCHEME')}://${graphqlServerUrl()}/graphql`,
    fetch: (input, init) => {
      /**
       * Abort controller and signal to abort fetch requests
       */
      const abortController = new AbortController();
      const abortSignal = abortController.signal;

      // Merge custom headers with any existing headers
      const headers = { ...init?.headers } as HeadersInit;
      return fetch(input, { ...init, headers, signal: abortSignal });
    }
  });

/**
 * Set-up a WebSocket-based link for Apollo
 * Specifying the browsers native WebSocket implementation to allow it to also be used in workers
 * assume we need to use wss if the API is using https
 */
const webSocketProto = () => (getEnvVar('NX_NEST_API_SCHEME') === 'https' ? 'wss' : 'ws');

const delayedSendSubscriptionPing = ({
  activeSocket,
  keepAlive,
  getAuthToken,
  graphqlSocketDelay,
  delayedSendSubscriptionPingTimeout
}: SocketPingPongContext) => {
  if (delayedSendSubscriptionPingTimeout) clearTimeout(delayedSendSubscriptionPingTimeout);

  delayedSendSubscriptionPingTimeout = setTimeout(() => {
    const authToken = typeof getAuthToken === 'function' ? getAuthToken() : '';

    if (!authToken) {
      apolloLogger.warn('No token found, most likely because it has expired. Logging user out.');
      return;
    }

    if (graphqlSocketDelay && graphqlSocketDelay.isRecording()) {
      graphqlSocketDelay.setStatus({ code: 2, message: 'deadline_exceeded' });
      graphqlSocketDelay.end();
    }

    graphqlSocketDelay = Sentry.startInactiveSpan({
      name: 'graphql.socket.delay',
      op: 'graphql'
    });

    if (activeSocket.readyState === WebSocket.OPEN) {
      activeSocket.send(
        stringifyMessage({
          type: MessageType.Ping,
          payload: { authToken }
        })
      );
    }
  }, keepAlive);
};

/**
 * This function was blatantly copied from the graphql-ws npm package
 * https://github.com/enisdenjo/graphql-ws/blob/master/src/client.ts
 *
 * @param closeEvent event automatically provided by apollo when a connection is closed
 * @returns whether the error that caused the event is fatal.
 */
function isFatalInternalCloseCode({ code, reason }: CloseEvent): boolean {
  if (
    [
      1000, // Normal Closure is not an erroneous close code
      1001, // Going Away
      1006, // Abnormal Closure
      1005, // No Status Received
      1012, // Service Restart
      1013 // Bad Gateway/Try Again Later
    ].includes(code)
  ) {
    return false;
  } else {
    const fatalMsg = `Connection closed with code ${code} and reason ${reason}`;
    apolloLogger.fatal(fatalMsg);
    Sentry.captureMessage(fatalMsg, (scope) => {
      scope.setTransactionName(fatalMsg);
      scope.setLevel('fatal');
      scope.setContext(fatalMsg, { websocketUrl, code, reason });

      return scope;
    });
    // all other internal errors are fatal
    return code >= 1000 && code <= 1999;
  }
}

// biggest integer possible so we can keep re-trying.
const KEEP_RETRYING_FOREVER = Number.MAX_SAFE_INTEGER;

const websocketUrl = () => `${webSocketProto()}://${graphqlServerUrl()}/graphql`;

/**
 * Updates the data access signal with the latest state of the socket.
 * @param state The current state of the websocket
 */
const setSocketDataAccessState = ({ signal }: GraphqlSocketSignal, ctx: GraphqlSocketState) => {
  apolloLogger.debug(`Setting socket state to ${ctx.state}.`, ctx);
  signal.set(ctx);
};

type SocketPingPongContext = {
  /**
   * Span used to send metrics to sentry on how long it takes for a socket roundtrip
   */
  graphqlSocketDelay?: Sentry.Span;
  /**
   * Last known timeout for sending ping to server via websocket
   */
  delayedSendSubscriptionPingTimeout?: NodeJS.Timeout;
  activeSocket: WebSocket;
  keepAlive: number;
  getAuthToken?: ApolloClientGetAuthToken;
};

export const createGraphQLAuthWsClient = (
  _isLeader: boolean,
  getAuthToken: ApolloClientGetAuthToken,
  signal: GraphqlSocketSignal,
  keepAlive = 10_000
): Client => {
  const socketContext: Partial<SocketPingPongContext> = {
    getAuthToken,
    keepAlive
  };
  const url = websocketUrl();
  const socketDataAccessCtx: GraphqlSocketState = {
    state: 'init',
    url
  };

  setSocketDataAccessState(signal, socketDataAccessCtx);

  return createClient({
    url,
    connectionParams: () => ({ authToken: getAuthToken() }),
    on: {
      error: (wsError) => {
        setSocketDataAccessState(signal, { ...socketDataAccessCtx, state: 'error' });
        apolloLogger.error({ wsError, url });
      },
      opened: (socket) => {
        setSocketDataAccessState(signal, { ...socketDataAccessCtx, state: 'opened' });
        socketContext.activeSocket = socket as WebSocket;
      },
      pong: (received: boolean, _payload?: Record<string, unknown>) => {
        // Disable pong if `keepAlive` is 0
        if (received && keepAlive > 0) {
          socketContext.graphqlSocketDelay?.setStatus({ code: 1, message: 'ok' });
          socketContext.graphqlSocketDelay?.end();
          delayedSendSubscriptionPing(socketContext as SocketPingPongContext);
        }
      },
      closed: () => {
        setSocketDataAccessState(signal, { ...socketDataAccessCtx, state: 'closed' });
        if (socketContext.delayedSendSubscriptionPingTimeout) {
          clearTimeout(socketContext.delayedSendSubscriptionPingTimeout);
        }
      },
      connecting: () => {
        setSocketDataAccessState(signal, { ...socketDataAccessCtx, state: 'connecting' });
      },
      connected: () => {
        setSocketDataAccessState(signal, { ...socketDataAccessCtx, state: 'connected' });
        // Disable ping if `keepAlive` is 0
        if (keepAlive > 0) {
          delayedSendSubscriptionPing(socketContext as SocketPingPongContext);
        }
      }
    },
    /**
     * Connection must not be lazy, we need to execute connectionParams everytime we try to re-connect,
     * to get the most recent auth token from local storage.
     */
    lazy: false,
    isFatalConnectionProblem: (event: unknown) => {
      if (typeof event === 'object' && event && (event as CloseEvent).type === 'error') {
        // if ws error, keep retrying
        return false;
      } else {
        return isFatalInternalCloseCode(event as CloseEvent);
      }
    },
    retryAttempts: KEEP_RETRYING_FOREVER,
    retryWait: (attempts) => retrySocketConnection(attempts)
  });
};

/**
 * Create an Apollo WS link that adds the auth token to the request headers
 *
 * @param getAuthToken - Function to get the auth token
 * @param client - GraphQL WS client
 * @returns GraphQLWsLink - Apollo WS link
 */
const createApolloAuthWsLink = (
  isLeader: boolean,
  getAuthToken: ApolloClientGetAuthToken,
  signal: GraphqlSocketSignal,
  client?: Client
) => {
  return new GraphQLWsLink(client ? client : createGraphQLAuthWsClient(isLeader, getAuthToken, signal));
};

/**
 * Create an Apollo HTTP link that adds the auth token to the request headers
 *
 * @param getAuthToken - Function to get the auth token
 * @returns ApolloLink
 */
const createApolloAuthHttpLink = (getAuthToken?: ApolloClientGetAuthToken) => {
  const authLink = createApolloAuthLink(getAuthToken);
  const httpLink = createApolloHttpLink();
  return authLink.concat(httpLink);
};

/**
 * Create an Apollo split link that adds the auth token to the request headers
 * and splits the link between HTTP and WS
 *
 * @param getAuthToken - Function to get the auth token
 * @param client - GraphQL WS client
 * @returns ApolloLink
 */
const createApolloAuthSplitLink = (
  isLeader: boolean,
  getAuthToken: ApolloClientGetAuthToken,
  signal: GraphqlSocketSignal,
  client?: Client
) =>
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    createApolloAuthWsLink(isLeader, getAuthToken, signal, client),
    createApolloAuthHttpLink(getAuthToken)
  );

/**
 * Create an Apollo error link that handles errors
 *
 * @param errorHandler - Function to handle errors
 * @returns ApolloLink
 */
const createApolloErrorLink = (errorHandler?: ErrorLink.ErrorHandler) => {
  return onError(errorHandler as ErrorLink.ErrorHandler);
};

/**
 * Apollo client link type
 */
export type ApolloClientLinkType = 'http' | 'auth-http' | 'auth-http-ws' | 'mock';

/**
 * Options for creating an Apollo client
 */
export type CreateApolloClientOptions = {
  errorHandler?: ErrorLink.ErrorHandler | null;
  retry?: RetryLink.Options | null;
  isLeader: boolean;
  graphqlWsClient?: Client;
  cache?: BroadcastInMemoryCache;
  getAuthToken?: ApolloClientGetAuthToken;
  mockLink?: WildcardMockLink;
  links?: ApolloLink[];
  signal: GraphqlSocketSignal;
};

/**
 * Create or update an Apollo Client based on the link type
 */
export const createOrUpdateApolloClient = <TLink extends ApolloClientLinkType>(
  linkType: TLink,
  options: CreateApolloClientOptions,
  apolloClient: RxApolloClient | null = null
) => {
  const {
    retry = options.retry === null
      ? null
      : {
          delay: {
            initial: 300,
            max: 30_000,
            jitter: true
          },
          attempts: {
            max: 5,
            retryIf: (error: any, _operation: Operation) => !!error
          }
        },
    errorHandler = options.errorHandler === null ? null : apolloClientErrorHandler,
    graphqlWsClient,
    isLeader = true,
    getAuthToken,
    mockLink,
    cache,
    links: apolloLinks = [],
    signal: socketSignal
  } = options || {};
  let links: ApolloLink[] = [...apolloLinks];

  switch (linkType) {
    case 'http':
      links = [createApolloHttpLink()];
      break;
    case 'auth-http':
      links = [createApolloAuthHttpLink(getAuthToken)];
      break;
    case 'auth-http-ws': {
      if (!getAuthToken) throw new Error('getAuthToken is required for auth-http-ws');

      links = [createApolloAuthSplitLink(isLeader, getAuthToken, socketSignal, graphqlWsClient)];
      break;
    }
    case 'mock':
      socketSignal.signal.set({ state: 'connected', url: 'wss://memes.com' });
      if (mockLink) {
        links = [mockLink];
      }
      break;
  }

  const baseLinks = [
    // https://github.com/DiederikvandenB/apollo-link-sentry
    // https://www.fieldguide.io/blog/graphql-observability-with-sentry
    new SentryLink({
      setTransaction: false,
      setFingerprint: false,
      attachBreadcrumbs: {
        includeError: true,
        includeQuery: true,
        includeVariables: true
      }
    }),
    createGraphQlTracingLink(),
    ...links
  ];
  retry && baseLinks.unshift(new RetryLink(retry));
  const combinedLink = errorHandler
    ? from([createApolloErrorLink(errorHandler), ...baseLinks])
    : from(baseLinks);

  // ------ Update existing apollo client instance ------

  if (apolloClient) {
    apolloClient.setLink(combinedLink);
    return apolloClient;
  }

  // ------ New apollo client instance ------

  const rxCache = cache
    ? cache
    : new BroadcastInMemoryCache({
        typePolicies: {
          Subscription: {
            fields: {}
          }
        }
      });

  return new RxApolloClient({
    cache: rxCache,
    link: combinedLink,
    connectToDevTools: true
  });
};
