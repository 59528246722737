import {
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE,
  ROW_SELECTION_COUNT_ACTION_TYPE,
  TOGGLE_FILTERS_AND_SIDEBAR_ACTION_TYPE,
  useVGrid,
  VGrid
} from '@oms/frontend-vgrid';
import { APP_CELL_RENDERERS } from '@app/common/grids/cell-renderers/app-renderers';
import { buildExecutionsMonitorColumnDefs } from './execution-monitor.columns';
import {
  ExecutionRow,
  TsExecutionsWithFilterDocument,
  TsExecutionsWithFilterSubscription
} from '@oms/generated/frontend';
import { applySettlementFXRateAction } from '../trade-monitor/trade-view/actions/apply-settlement-fx-rate.action';
import { cancelExecutionsAction } from '../trade-monitor/trade-view/actions/cancel.executions.action';
import { viewExecutionAction } from './grid-actions/view.execution.action';
import { refreshServersideCustomMenuAction } from '@app/common/grids/actions/refresh-serverside.action';
import { VisibilityEventHander } from '../investor-order-monitor/grid-services/visibility.event-handler';
import { withTableServer } from '@app/data-access/services/system/table-server/with.table-server.component';

export type ExecutionsSubcriptionKeys = 'executions';

export type ExecutionsSubscriptionMap = {
  executions: {
    datasource: ExecutionRow;
    subscription: TsExecutionsWithFilterSubscription;
  };
};

export const ExecutionsGridWidget = withTableServer<ExecutionsSubcriptionKeys, ExecutionsSubscriptionMap>(
  ({ datasources }) => {
    const gridProps = useVGrid<ExecutionRow>(
      'executions-monitor',
      (builder) =>
        builder
          .tableServerColumnLibrary(buildExecutionsMonitorColumnDefs())
          .rowSelection((c) => c.multiple())
          .datasource((d) =>
            d
              .source(datasources.executions.asServerSideRowModel())
              .rowId((r) => r.data.id)
              .cacheBlockSize(100)
          )
          .injectEvents([VisibilityEventHander])
          .sideBar()
          .actions((a) =>
            a.schema((s) =>
              s
                .action(TOGGLE_FILTERS_AND_SIDEBAR_ACTION_TYPE)
                .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(ROW_SELECTION_COUNT_ACTION_TYPE)
                .action(viewExecutionAction)
                .action(cancelExecutionsAction)
                .action(applySettlementFXRateAction)
                .action(refreshServersideCustomMenuAction)
            )
          )
          .reactiveCustomComponents()
          .registerAppCellRenderers(APP_CELL_RENDERERS),
      [datasources, buildExecutionsMonitorColumnDefs]
    );

    return <VGrid {...gridProps} />;
  },
  {
    executions: {
      query: TsExecutionsWithFilterDocument,
      getData: (r) => r.tsExecutionsWithFilter
    }
  }
);

export default ExecutionsGridWidget;
