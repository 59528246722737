import { ICellRendererParams } from '@ag-grid-community/core';
import { PriceSlider } from '@oms/ui-design-system';
import React from 'react';

/**
 * Const name registered with ag-grid
 */
export const PRICE_SLIDER_RENDERER = 'PriceSliderRenderer';
export const PRICE_SLIDER_52_WEEK_RENDERER = 'PriceSlider52WeekRenderer';

/**
 * Price Slider renderer for ag-grid
 */
export const PriceSliderRenderer: React.FC<ICellRendererParams> = ({ data, value }) => {
  // value is the last price
  const dayHigh = data?.high ?? 0;
  const dayLow = data?.low ?? 0;
  return <PriceSlider highPrice={dayHigh} lowPrice={dayLow} currentPrice={value} />;
};

export const PriceSlider52WeekRenderer: React.FC<ICellRendererParams> = ({ data, value }) => {
  // value is the last price
  const yearHigh = data?.high52week ?? 0;
  const yearLow = data?.low52week ?? 0;
  return <PriceSlider highPrice={yearHigh} lowPrice={yearLow} currentPrice={value} />;
};
