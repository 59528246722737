import { VisibleInvestorOrderInfoFragment, OrderSide } from '@oms/generated/frontend';
import { DisplayGridItemProps, DISPLAY_FIELD_COMPONENT_TYPE } from '@oms/ui-design-system';
import { VolumeWeightedAveragePrice } from './hooks/use-order-vwap.hook';
import type { DirectionType } from '@app/data-access/services/marketdata/components/market-data.display.util';

type DisplayGridItemArgs = { vwap?: VolumeWeightedAveragePrice } & Pick<
  VisibleInvestorOrderInfoFragment,
  'side' | 'averagePrice'
>;
export function getPerformance({ vwap, side, averagePrice }: DisplayGridItemArgs): {
  performance: number | '-';
  direction: DirectionType;
} {
  let performance: number | '-' = '-';
  let direction: DirectionType = 'neutral';

  if (!vwap || !averagePrice || !side) {
    return { performance, direction };
  }

  if (vwap && averagePrice && side) {
    const diff = averagePrice - vwap;

    // Determine if performance is positive
    const isPositivePerformance =
      side === OrderSide.Buy
        ? diff < 0 // For buy orders: lower price is better
        : diff > 0; // For sell orders: higher price is better

    direction = isPositivePerformance ? 'up' : 'down';
    performance = Math.abs(diff);
  }

  return { performance, direction };
}

export function getDisplayGridItems({
  vwap,
  side,
  averagePrice
}: DisplayGridItemArgs): DisplayGridItemProps[] {
  const { performance, direction: performanceDirection } = getPerformance({ vwap, side, averagePrice });
  return [
    {
      label: 'Order VWAP',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: vwap || '-',
        format: 'price',
        display: 'fraction'
      }
    },
    {
      label: 'Volume',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Text, // TODO: change this to Numeric when Factset implements `volume` in their API and we add it to our UI
        value: '-'
      }
    },
    {
      label: 'Avg Price',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: averagePrice || '-',
        format: 'price',
        display: 'fraction'
      }
    },
    {
      label: 'Performance',
      component: {
        type: DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: performance,
        format: 'price-change',
        display: 'fraction',
        direction: performanceDirection
      }
    }
  ];
}

export function getStartTime(time: unknown): string | undefined {
  if (typeof time !== 'string' && typeof time !== 'number' && !(time instanceof Date)) {
    return undefined;
  }

  const date = new Date(time);

  // check it is a valid date
  if (isNaN(date.getTime())) {
    return undefined;
  }

  const seconds = date.getSeconds();

  const roundedSeconds = Math.floor(seconds / 10) * 10;

  date.setSeconds(roundedSeconds, 0);

  return date.toISOString();
}

export function getEndTime(): string {
  // return the current time rounded to the nearest 10 seconds as an iso string
  const date = new Date();
  const seconds = date.getSeconds();
  const roundedSeconds = Math.floor(seconds / 10) * 10;
  date.setSeconds(roundedSeconds, 0);
  return date.toISOString();
}
