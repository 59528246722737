import type { Observable } from 'rxjs';
import { BehaviorSubject, switchMap, map } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import { cleanMaybe, Logger } from '@oms/ui-util';
import type { AnyRecord } from '@oms/frontend-foundation';
import { toGqlDatasource } from '@oms/frontend-foundation';
import { testScoped } from '@app/workspace.registry';
import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { AccountsService } from '@app/data-access/services/reference-data/accounts/accounts.service';
import type { DataSourceCommon } from '@oms/frontend-foundation';
import {
  NotificationSettingValue,
  UpdateDefaultNotificationSettingsDocument,
  GetAllDefaultNotificationSettingsDocument,
  ResetAllNotificationSettingsDocument,
  GetAllCustomNotificationSettingsDocument,
  GetCustomNotificationSettingsDocument,
  UpsertCustomNotificationSettingsDocument,
  DeleteCustomNotificationSettingsDocument,
  GetFactoryDefaultSettingsForNotificationDocument
} from '@oms/generated/frontend';
import type {
  GetAllDefaultNotificationSettingsQuery,
  GetAllDefaultNotificationSettingsQueryVariables,
  UpdateDefaultNotificationSettingsMutation,
  UpdateDefaultNotificationSettingsMutationVariables,
  ResetAllNotificationSettingsMutation,
  ResetAllNotificationSettingsMutationVariables,
  CustomNotificationSettingsFragment,
  GetAllCustomNotificationSettingsQuery,
  GetAllCustomNotificationSettingsQueryVariables,
  GetCustomNotificationSettingsQuery,
  GetCustomNotificationSettingsQueryVariables,
  UpsertCustomNotificationSettingsMutation,
  UpsertCustomNotificationSettingsMutationVariables,
  DeleteCustomNotificationSettingsMutation,
  DeleteCustomNotificationSettingsMutationVariables,
  DefaultNotificationName,
  GetFactoryDefaultSettingsForNotificationQuery,
  GetFactoryDefaultSettingsForNotificationQueryVariables,
  NotificationSetting,
  DefaultNotificationSettingsInput,
  CustomNotificationSettings
} from '@oms/generated/frontend';
import type {
  OverrideFlagsToBoolean,
  OverrideFlagsToEnum,
  MappedCustomNotificationSettings,
  MappedFactoryDefaultNotificationSettings,
  NotificationSettingsTypeValue,
  NotificationSettingsData,
  DefaultNotificationSettingsFragmentWithId,
  MappedUpsertNotificationSettings,
  MappedCommonNotificationSettings,
  CustomNotificationSettingsFragmentWithData
} from '@app/widgets/user/user-preferences/preferences/notification-settings/notification-settings.contracts';
import { NotificationSettingsType } from '@app/widgets/user/user-preferences/preferences/notification-settings/notification-settings.contracts';

@testScoped
@singleton()
export class NotificationSettingsService {
  private fetch$ = new BehaviorSubject<void>(undefined);

  protected name: string = 'NotificationSettingsService';
  protected logger: Logger;

  constructor(
    @inject(RxApolloClient) private apolloClient: RxApolloClient,
    @inject(GQLResponse) private gqlResponse: GQLResponse,
    @inject(AuthService) protected authService: AuthService,
    @inject(AccountsService) private accountsService: AccountsService
  ) {
    this.logger = Logger.named(this.name);
  }

  public triggerFetch() {
    this.fetch$.next();
  }

  public watch$(
    type: NotificationSettingsTypeValue
  ): Observable<DataSourceCommon<MappedCommonNotificationSettings>> {
    const settingsFetcher =
      type === NotificationSettingsType.CUSTOM
        ? this.getAllCustomNotificationSettingsWithAccounts.bind(this)
        : this.getAllDefaultNotificationSettings.bind(this);

    return this.fetch$.pipe(switchMap(settingsFetcher));
  }

  /**
   * Default Notification Settings
   */

  public getAllDefaultNotificationSettings(): Observable<
    DataSourceCommon<OverrideFlagsToBoolean<DefaultNotificationSettingsFragmentWithId>>
  > {
    const userId = this.authService.getUserId() || '';
    return this.apolloClient
      .rxWatchQuery<GetAllDefaultNotificationSettingsQuery, GetAllDefaultNotificationSettingsQueryVariables>({
        query: GetAllDefaultNotificationSettingsDocument,
        variables: {
          userId
        },
        fetchPolicy: 'no-cache'
      })
      .pipe(
        toGqlDatasource((response) => {
          const settings = cleanMaybe(response?.allDefaultNotificationsSettings?.settings, []).map((item) => {
            if (item) {
              return {
                ...item,
                id: item.notificationName
              };
            }
            return item;
          }) as DefaultNotificationSettingsFragmentWithId[];
          return this.mapSettingsValuesToBoolean<DefaultNotificationSettingsFragmentWithId>(
            settings,
            true,
            true
          );
        })
      );
  }

  public updateDefaultNotificationSettings(settings: DefaultNotificationSettingsInput) {
    const userId = this.authService.getUserId() || '';
    const updatedSettings = this.mapSettingValueToEnum(settings);
    const mutation = this.gqlResponse.wrapMutate<
      UpdateDefaultNotificationSettingsMutation,
      UpdateDefaultNotificationSettingsMutationVariables
    >({
      mutation: UpdateDefaultNotificationSettingsDocument,
      variables: {
        settingsToUpdate: {
          ...updatedSettings,
          userId
        }
      }
    });

    return mutation.awaitAsyncResponse().exec();
  }

  public resetDefaultNotificationSettings() {
    const userId = this.authService.getUserId() || '';
    const mutation = this.gqlResponse.wrapMutate<
      ResetAllNotificationSettingsMutation,
      ResetAllNotificationSettingsMutationVariables
    >({
      mutation: ResetAllNotificationSettingsDocument,
      variables: {
        userId
      },
      refetchQueries: [GetAllDefaultNotificationSettingsDocument]
    });

    return mutation.exec();
  }

  /**
   * Custom Notification Settings
   */

  // TODO: Switch to this method once the matchedAccounts will include the account data
  public _getAllCustomNotificationSettings(): Observable<
    DataSourceCommon<OverrideFlagsToBoolean<CustomNotificationSettingsFragment>>
  > {
    const userId = this.authService.getUserId() || '';
    return this.apolloClient
      .rxWatchQuery<GetAllCustomNotificationSettingsQuery, GetAllCustomNotificationSettingsQueryVariables>({
        query: GetAllCustomNotificationSettingsDocument,
        variables: {
          userId
        },
        fetchPolicy: 'no-cache'
      })
      .pipe(
        toGqlDatasource((response) => {
          const settings = cleanMaybe(
            response?.allCustomNotificationsSettings?.settings,
            []
          ) as CustomNotificationSettingsFragment[];
          return this.mapSettingsValuesToBoolean<CustomNotificationSettingsFragment>(settings, false, false);
        })
      );
  }

  public getAllCustomNotificationSettingsWithAccounts(): Observable<
    DataSourceCommon<MappedCustomNotificationSettings>
  > {
    const userId = this.authService.getUserId() || '';
    return this.apolloClient
      .rxWatchQuery<GetAllCustomNotificationSettingsQuery, GetAllCustomNotificationSettingsQueryVariables>({
        query: GetAllCustomNotificationSettingsDocument,
        variables: { userId },
        fetchPolicy: 'no-cache'
      })
      .pipe(
        switchMap((customNotifications) => {
          const settings = cleanMaybe(
            customNotifications?.data?.allCustomNotificationsSettings?.settings,
            []
          ) as CustomNotificationSettingsFragment[];
          // Create a map of account ids to fetch account data
          const accountsData = new Map<string, AnyRecord>();
          settings.forEach((setting) => {
            setting?.matchedAccounts?.forEach((id) => {
              if (id) {
                accountsData.set(id, {});
              }
            });
          });

          return this.accountsService.getInvestorAccountByIds(Array.from(accountsData.keys())).pipe(
            map((accounts) => {
              accounts.forEach((account) => {
                accountsData.set(account.id, account);
              });

              const updatedSettings = settings.map((setting) => {
                const matchedAccountsData = (setting.matchedAccounts ?? [])
                  .map((id) => accountsData.get(id as string))
                  .filter((account) => !!account);
                return {
                  ...setting,
                  matchedAccountsData
                };
              });

              // Mutate and update customNotifications with the updated settings array
              return {
                ...customNotifications,
                data: {
                  ...customNotifications.data,
                  allCustomNotificationsSettings: {
                    ...customNotifications.data.allCustomNotificationsSettings,
                    settings: updatedSettings
                  }
                }
              };
            })
          );
        }),
        toGqlDatasource((response) => {
          const settings = cleanMaybe(
            response?.allCustomNotificationsSettings?.settings,
            []
          ) as CustomNotificationSettingsFragmentWithData[];
          return this.mapSettingsValuesToBoolean<CustomNotificationSettingsFragmentWithData>(
            settings,
            false,
            false
          );
        })
      );
  }

  public async getCustomSettingById(
    customNotificationId: string
  ): Promise<OverrideFlagsToBoolean<CustomNotificationSettings> | null> {
    const userId = this.authService.getUserId() || '';
    const result = await this.apolloClient.query<
      GetCustomNotificationSettingsQuery,
      GetCustomNotificationSettingsQueryVariables
    >({
      query: GetCustomNotificationSettingsDocument,
      variables: {
        userId,
        customNotificationId
      }
    });

    const customSettings = result.data.customNotificationSettings;
    if (!customSettings) {
      return null;
    }
    return (
      this.mapSettingsValuesToBoolean<CustomNotificationSettingsFragment>(
        [customSettings],
        false,
        false
      )[0] ?? null
    );
  }

  public upsertCustomSettings(settings: MappedUpsertNotificationSettings) {
    const userId = this.authService.getUserId() || '';
    const updatedSettings = this.mapSettingValueToEnum(settings);
    const mutation = this.gqlResponse.wrapMutate<
      UpsertCustomNotificationSettingsMutation,
      UpsertCustomNotificationSettingsMutationVariables
    >({
      mutation: UpsertCustomNotificationSettingsDocument,
      variables: {
        settings: {
          ...updatedSettings,
          userId
        }
      }
    });

    return mutation.exec();
  }

  public deleteCustomSettings(notificationIds: string[]) {
    const mutation = this.gqlResponse.wrapMutate<
      DeleteCustomNotificationSettingsMutation,
      DeleteCustomNotificationSettingsMutationVariables
    >({
      mutation: DeleteCustomNotificationSettingsDocument,
      variables: {
        settingsIds: notificationIds
      },
      refetchQueries: [GetAllCustomNotificationSettingsDocument]
    });

    return mutation.exec();
  }

  /**
   * Factory Defaults Notification Settings
   */

  public async getFactoryDefaults(
    notificationName: DefaultNotificationName
  ): Promise<MappedFactoryDefaultNotificationSettings | null> {
    const result = await this.apolloClient.query<
      GetFactoryDefaultSettingsForNotificationQuery,
      GetFactoryDefaultSettingsForNotificationQueryVariables
    >({
      query: GetFactoryDefaultSettingsForNotificationDocument,
      variables: {
        notificationName
      }
    });

    const { factoryDefaultSettingsForNotification } = result.data;

    if (!factoryDefaultSettingsForNotification) {
      return null;
    }

    return {
      notificationName: factoryDefaultSettingsForNotification.notificationName,
      isPopup:
        factoryDefaultSettingsForNotification.isPopup.defaultValue === NotificationSettingValue.Enabled,
      isShown:
        factoryDefaultSettingsForNotification.isShown.defaultValue === NotificationSettingValue.Enabled,
      isSound: factoryDefaultSettingsForNotification.isSound.defaultValue === NotificationSettingValue.Enabled
    };
  }

  private mapSettingsValuesToBoolean<T extends NotificationSettingsData>(
    settings: T[],
    useFacetsSettings: boolean,
    includeFacetsMeta: boolean
  ): OverrideFlagsToBoolean<T>[] {
    return settings.map((setting) => ({
      ...setting,
      // Override flags
      isPopup: useFacetsSettings
        ? (setting?.isPopup as NotificationSetting).value === NotificationSettingValue.Enabled
        : setting?.isPopup === NotificationSettingValue.Enabled,
      isShown: useFacetsSettings
        ? (setting?.isShown as NotificationSetting).value === NotificationSettingValue.Enabled
        : setting?.isShown === NotificationSettingValue.Enabled,
      isSound: useFacetsSettings
        ? (setting?.isSound as NotificationSetting).value === NotificationSettingValue.Enabled
        : setting?.isSound === NotificationSettingValue.Enabled,
      // Add facets meta
      ...(useFacetsSettings && includeFacetsMeta
        ? {
            isPopupEnabled: (setting?.isPopup as NotificationSetting).facets?.isOverrideAllowed || false,
            isShownEnabled: (setting?.isShown as NotificationSetting).facets?.isOverrideAllowed || false,
            isSoundEnabled: (setting?.isSound as NotificationSetting).facets?.isOverrideAllowed || false
          }
        : {})
    }));
  }

  private mapSettingValueToEnum<T extends AnyRecord>(settings: T): OverrideFlagsToEnum<T> {
    return Object.keys(settings).reduce((acc, key) => {
      if (key === 'isPopup' || key === 'isShown' || key === 'isSound') {
        (acc as any)[key] =
          settings[key] === true ? NotificationSettingValue.Enabled : NotificationSettingValue.Disabled;
      } else {
        (acc as any)[key] = settings[key as keyof T];
      }
      return acc;
    }, {} as OverrideFlagsToEnum<T>);
  }
}
