import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import type {
  CreateOrderRoutingRuleMutation,
  CreateOrderRoutingRuleMutationVariables,
  DeleteOrderRuleByIdMutation,
  DeleteOrderRuleByIdMutationVariables,
  InvestorOrderRuleRouting
} from '@oms/generated/frontend';
import { CreateOrderRoutingRuleDocument, DeleteOrderRuleByIdDocument } from '@oms/generated/frontend';
import { inject, singleton } from 'tsyringe';
import { testScoped } from '@app/workspace.registry';

@testScoped
@singleton()
export class RoutingRulesService {
  constructor(
    @inject(RxApolloClient) private apolloClient: RxApolloClient,
    @inject(GQLResponse) private gqlResponse: GQLResponse
  ) {}

  create(rulesInput: InvestorOrderRuleRouting) {
    const mutation = this.gqlResponse.wrapMutate<
      CreateOrderRoutingRuleMutation,
      CreateOrderRoutingRuleMutationVariables
    >({
      mutation: CreateOrderRoutingRuleDocument,
      fetchPolicy: 'no-cache',
      variables: { rulesInput }
    });
    return mutation.exec();
  }

  delete(orderRuleId: string) {
    const mutation = this.gqlResponse.wrapMutate<
      DeleteOrderRuleByIdMutation,
      DeleteOrderRuleByIdMutationVariables
    >({
      mutation: DeleteOrderRuleByIdDocument,
      variables: { orderRuleId }
    });
    return mutation.exec();
  }
}
