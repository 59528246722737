import { BroadcastSubject } from '@oms/rx-broadcast';
import type { ShortcutUnion } from '@app/app-config/shortcut.config';
import { Subject, filter } from 'rxjs';
import { isTauri } from '@valstro/workspace';

export type ShortcutEvent = {
  shortcut: ShortcutUnion;
  platformName: string;
};

export const filterShortcutOp = (shortcut: ShortcutUnion) =>
  filter((event: ShortcutEvent) => event.shortcut === shortcut);

export const globalShortcut$ = new BroadcastSubject<ShortcutEvent>('global-shortcut');
export const windowShortcut$ = new Subject<ShortcutEvent>();

export const getGlobalShortcut$ = (shortcut: ShortcutUnion) =>
  globalShortcut$.pipe(filterShortcutOp(shortcut));

export const getWindowShortcut$ = (shortcut: ShortcutUnion) =>
  globalShortcut$.pipe(filterShortcutOp(shortcut));

/**
 * Returns a stream of shortcut events from either the global or window shortcut subject
 * - If the app is running in Tauri, the global shortcut subject will be used
 * - If the app is running in a browser, the window shortcut subject will be used
 *
 * @param shortcut - The shortcut to listen for
 * @returns Observable<ShortcutEvent> - A stream of shortcut events from either the global or window shortcut subject
 */
export const getGlobalOrWindowShortcut$ = (shortcut: ShortcutUnion) =>
  isTauri()
    ? globalShortcut$.pipe(filterShortcutOp(shortcut))
    : windowShortcut$.pipe(filterShortcutOp(shortcut));
