import type { DetailGridBuilder } from '@oms/frontend-vgrid';
import { TradingOrdersDetailGrid } from './investor-order-monitor.details.grid';
import type { TradingOrderDetailGridProps } from './investor-order-monitor.details.grid';
import { InvestorOrderTradingOrdersStatsGroupHeader } from './investor-order-monitor.stats-header';
import type { InvestorOrderWithChargesRow } from './investor-order-monitor.contracts';

const STATS_HEADER_HEIGHT = 48;

const CONNECTION_ERROR_HEIGHT = 28;

export const investorOrderMasterDetail = (
  md: DetailGridBuilder<InvestorOrderWithChargesRow, InvestorOrderWithChargesRow, TradingOrderDetailGridProps>
) =>
  md
    .groupByColumn('masterDetailGroup', (d) => !!d.tradingOrderIds?.length)
    .headerComponent(InvestorOrderTradingOrdersStatsGroupHeader)
    .getHeaderProps(({ data }) => data || null)
    .detailComponent(TradingOrdersDetailGrid)
    .getDetailProps(({ data }) => ({ investorOrderId: data?.id || '' }))
    .getDetailRowHeightParams(({ data }) => {
      return {
        numberOfRows: data?.tradingOrderIds?.length,
        headerComponentHeight: STATS_HEADER_HEIGHT + CONNECTION_ERROR_HEIGHT,
        hideToolbar: true
      };
    });
