import React, { useCallback, useMemo } from 'react';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import { useFieldApi } from '../../helpers';
import type { FieldProps } from '../../types';
import { type INativeDatePickerField } from './native-date-picker.type';
import { Input } from '@oms/ui-design-system';
import { DateTime } from 'luxon';

export const NativeDatePicker: React.FC<FieldProps<INativeDatePickerField>> = (props) => {
  const {
    meta,
    label,
    input: { name, onBlur, onChange, onFocus, value },
    isVisible,
    helperText,
    isRequired,
    isDisabled: _isDisabled,
    forceIsDisabled,
    isReadOnly,
    isInvalid,
    isPrimaryField,
    requiredFieldIndicatorStyle,
    hideFormControls,
    style,
    timePicker = false,
    sx,
    wrapperSx = {},
    format,
    initialValue,
    value: _value
  } = useFieldApi<INativeDatePickerField>(props);

  // Sometimes validators override the disabled props. If forceIsDisabled is true, then we should always disable the field.
  const isDisabled = !!forceIsDisabled || !!_isDisabled;

  const valueDateToString = useMemo(() => {
    const val = value ? String(value) : initialValue ? String(initialValue) : '';
    if (!val) return '';

    const dateTime = DateTime.fromISO(val);
    // For date only don't covert to local. We don't have a time component
    // to take time zone into account and it might change the date if we
    // assume midnight
    const result = timePicker ? dateTime.toLocal().toFormat("yyyy-MM-dd'T'HH:mm") : dateTime.toISODate();

    return result;
  }, [initialValue, timePicker, value]);

  const onChangeStringToDate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      if (value) {
        // For date only don't convert to UTC. The assumption of time being
        // midnight might casue coverting to the previous day
        const dateTime = timePicker
          ? DateTime.fromFormat(value, "yyyy-MM-dd'T'HH:mm").toUTC().toISO()
          : value;

        onChange(dateTime);
      } else {
        onChange(null);
      }
    },
    [onChange, timePicker]
  );

  return (
    <FieldWrapper
      meta={meta}
      label={label}
      isReadOnly={isReadOnly}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      requiredFieldIndicatorStyle={requiredFieldIndicatorStyle}
      helperText={helperText}
      hideFormControls={hideFormControls}
      isPrimaryField={isPrimaryField}
      isVisible={isVisible}
      sx={wrapperSx}
    >
      <Input
        name={name}
        type={timePicker ? 'datetime-local' : 'date'}
        onBlur={onBlur}
        value={valueDateToString || ''}
        onChange={onChangeStringToDate}
        onFocus={onFocus}
        hidden={isVisible === false}
        style={style}
        className="native-date-picker"
        sx={sx}
        format={format}
      />
    </FieldWrapper>
  );
};
