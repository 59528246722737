import { Logger } from '../logger/logger.class';
import { z } from 'zod';
import { FormatterFunction } from './types';

// Enable to see debug logs
const l = Logger.named('formatEmailAddress').disable();

// Define email schema using Zod
const emailSchema = z.string().email();

export const makeFormatEmailAddress =
  (fallbackWithInput?: boolean): FormatterFunction =>
  ({ value }) => {
    const input = typeof value === 'string' ? value : '';
    const fallback = fallbackWithInput ? input : '';

    const result = emailSchema.safeParse(input);
    if (!result.success) {
      l.debug(result.error);
      return fallback;
    }
    return result.data ?? fallback;
  };
