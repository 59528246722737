import type { AnyRecord } from '@oms/ui-util';
import type { NestedTreeData } from '../../types/tree-data.types';
import type { ExtractChildrenReturn } from '../internal/types';

// 👶 Tree data children utils -------------------------------------------------------- /
// Utils to manage children in nested tree grid data

/**
 * @param data - One or more nested tree data objects
 * @returns A count of all children nested below the node including **ALL** layers below
 */
export const countAllChildrenOfNestedDataNode = <TData extends AnyRecord>(
  data: NestedTreeData<TData>
): number => {
  if (!data.children) return 0;
  return data.children.reduce((count, item) => {
    count += countAllChildrenOfNestedDataNode(item);
    return count;
  }, data.children.length ?? 0);
};

/** Separates the nested children from nested data */
export const extractChildren = <TData extends AnyRecord>(
  data: NestedTreeData<TData>
): ExtractChildrenReturn<TData> => {
  const { children, ...rest } = data;
  return { children, item: rest as TData };
};
