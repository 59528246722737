import { ALL_VERSIONS as actions } from '@app/actions/collections/migrations';
import { ALL_VERSIONS as snapshots } from '@app/data-access/offline/collections/snapshots/migrations';
import type { RxCollection } from 'rxdb-oms-app';

export const REPLICATION_MIGRATIONS = {
  actions,
  snapshots
} as const;

export type MigrationCollectionName = keyof typeof REPLICATION_MIGRATIONS;
export const REPLICATION_MIGRATIONS_KEYS = Object.keys(REPLICATION_MIGRATIONS) as MigrationCollectionName[];
export interface MigrationCollection extends RxCollection {
  name: MigrationCollectionName;
}
