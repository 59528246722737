import { inject, injectable } from 'tsyringe';
import { defer } from 'rxjs';
import type { Observable } from 'rxjs';
import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import type {
  TableServerQueryOptions,
  TableServerQueryResult,
  TableServerRow,
  TableServerRowSubscriptionVariables,
  TableServerSubscriptionShape
} from './table-server.datasource.contracts';
import { tableServer } from './table-server.operator';
import { TableServerReconnectSignal } from './table-server.reconnect.signal';

@injectable()
export class TableServerService {
  constructor(
    @inject(RxApolloClient) private apolloClient: RxApolloClient,
    @inject(TableServerReconnectSignal) private reconnect: TableServerReconnectSignal
  ) {}

  /**
   * Observable that subscribes to a table server query
   *
   * @param options {TOptions extends TableServerQueryOptions<TSubscription, TData>}
   * @returns {Observable<TableServerQueryResult<TData>>}
   */
  public query$<TData extends TableServerRow, TSubscription extends TableServerSubscriptionShape<TData>>(
    opts: Omit<TableServerQueryOptions<TData, TSubscription>, 'reconnect'>
  ): Observable<TableServerQueryResult<TData>> {
    const { query, variables } = opts;

    return defer(() =>
      this.apolloClient.rxSubscribe<TSubscription, TableServerRowSubscriptionVariables>({
        query,
        fetchPolicy: 'no-cache',
        variables
      })
    ).pipe(tableServer({ ...opts, reconnect: this.reconnect }));
  }
}
