import { Children } from 'react';
import * as React from 'react';
import clsx from 'clsx';
import * as styles from './stack.css';
import { Box } from '../../system/components/box/box';
import type { BoxProps } from '../../system/components/box/box';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';
import { Space, Sprinkles } from '../../system/sprinkles.css';

export type StackSpecificProps = {
  /**
   * If `true` the items will be stacked horizontally.
   */
  isInline?: boolean;
  /**
   * If `true`, the children will be wrapped in a `Box` with
   */
  shouldWrapChildren?: boolean;
  /**
   * Spacing between items
   */
  spacing?: Space;
  /**
   * Direction of items
   */
  direction?: 'horizontal' | 'vertical';
  /**
   * Reverse order of items
   */
  reverseOrder?: boolean;
  /**
   * Short for alignItems
   */
  align?: Sprinkles['alignItems'];
  /**
   * Short for justifyContent
   */
  justify?: Sprinkles['justifyContent'];
  /**
   * Short for flex
   */
  wrap?: Sprinkles['flexWrap'];

  /**
   * Make children of equal width
   */
  equalWidthChildren?: boolean;

  children?: React.ReactNode | React.ReactNode[];
};

export type StackProps = StackSpecificProps & BoxProps;

export const Stack = polymorphicComponent<'div', StackProps>((props, ref) => {
  const {
    as,
    className,
    children,
    isInline,
    direction,
    spacing = 3,
    reverseOrder = false,
    shouldWrapChildren = false,
    equalWidthChildren = false,
    align,
    justify,
    wrap,
    sx = {},
    ...rest
  } = props;

  const stackItems = Children.toArray(children) as React.ReactElement[];
  const actualDirection = direction === 'vertical' && !isInline ? 'vertical' : 'horizontal';

  const spacingSizeClass = React.useMemo(() => `${spacing}`.replace('.', '-'), [spacing]);

  return (
    <Box
      as={as}
      sx={{
        display: 'flex',
        flexDirection: actualDirection === 'vertical' ? 'column' : 'row',
        alignItems: align,
        justifyContent: justify,
        flexWrap: wrap,
        ...sx
      }}
      {...rest}
      className={clsx(
        styles.stack,
        reverseOrder && styles.stackReverse[actualDirection],
        !shouldWrapChildren && actualDirection === 'horizontal' && `space-x-${spacingSizeClass}`,
        !shouldWrapChildren && actualDirection === 'vertical' && `space-y-${spacingSizeClass}`,
        !shouldWrapChildren && equalWidthChildren && 'stack-equally',
        className
      )}
      ref={ref}
    >
      {shouldWrapChildren &&
        stackItems.map((item, index) => (
          <Box
            key={index} // This isn't ideal + in future, would want to just use CSS for stack padding.
            sx={{
              flex: equalWidthChildren ? 1 : undefined,
              paddingBottom:
                index !== stackItems.length - 1 && actualDirection === 'vertical' ? spacing : undefined,
              paddingRight:
                index !== stackItems.length - 1 && actualDirection === 'horizontal' ? spacing : undefined
            }}
          >
            {item}
          </Box>
        ))}

      {!shouldWrapChildren && children}
    </Box>
  );
});

/**
 * @returns A layout stack of components with vertical direction.
 */
export const VStack: React.FC<Omit<StackProps, 'direction'>> = ({ children, ...props }) => (
  <Stack direction={'vertical'} {...props}>
    {children}
  </Stack>
);

/**
 * @returns A layout stack of components with horizontal direction.
 */
export const HStack: React.FC<Omit<StackProps, 'direction'>> = ({ children, ...props }) => (
  <Stack direction={'horizontal'} {...props}>
    {children}
  </Stack>
);

if (__DEV__) {
  Stack.displayName = 'Stack';
}
