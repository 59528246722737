import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { getLeaderOrTabId } from '@app/common/workspace/workspace.util';
import { t } from '@oms/codegen/translations';

import { ExecutionInfoFragment } from '@oms/generated/frontend';
import { openViewExecution } from '@app/common/types/orders/execution/open.view.execution';
import { createLogger } from '@oms/ui-util';

function isVisible(data: ExecutionInfoFragment[]) {
  return data.length === 1;
}

export const viewExecutionOnChange = async (
  ctx: ActionContext<ExecutionInfoFragment, ActionComponentConfig<ExecutionInfoFragment>>
) => {
  const logger = createLogger({ name: 'viewExecutionOnChange' });

  const { lifecycle, data } = ctx;

  const selectedRow = data[0];

  ctx.notify({ isDisabled: !isVisible(data) });

  if (lifecycle === 'change') {
    if (!selectedRow) {
      logger.debug('No selected row');
      return;
    }

    if (!selectedRow.id) {
      logger.debug('No id in selected row');
      return;
    }

    if (!selectedRow.trade) {
      logger.debug('No trade in selected row');
      return;
    }

    await openViewExecution({
      windowId: getLeaderOrTabId(ctx.appContainer),
      dialogInfo: {
        id: selectedRow.id,
        side: selectedRow.trade?.side,
        price: selectedRow.executedPrice,
        quantity: selectedRow.executedQuantity,
        symbol: selectedRow.trade?.instrument.mappings.displayCode
      },
      componentProps: {
        id: selectedRow.id,
        executionId: selectedRow.id,
        allocationMapId: selectedRow.allocationMapId,
        tradeId: selectedRow.trade.id
      }
    });
  }
};

export const viewExecutionAction: ActionDefFactory<ExecutionInfoFragment> = (builder) =>
  builder
    .name('view_execution')
    .toolbar((builder) =>
      builder
        .component('action-button')
        .id('view_execution_button')
        .location('UserToolbar')
        .props({
          isDisabled: true,
          content: t('app.commands.viewExecution.button')
        })
    )
    .customMenu((m) =>
      m
        .name(t('app.commands.viewExecution.contextMenu'))
        .visible(({ rowData }) => isVisible(rowData))
        .tabName(t('app.common.grids.contextMenuTabs.action'))
        .priority(30)
    )
    .onChange<ActionComponentConfig<ExecutionInfoFragment>>(viewExecutionOnChange);
