import { mapTif } from '@app/common/mappers/map-tif';
import type { DdfValidator, MultiInputOption } from '@oms/frontend-foundation';
import { TimeInForce } from '@oms/generated/frontend';
/**
 * Returns the ISO string representation of tomorrow's midnight.
 * @returns {string} The ISO string representation of tomorrow's midnight.
 */
export function getTomorrowMidnight() {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);
  return tomorrow.toISOString();
}

export type TimeInForceMultiInputOption = MultiInputOption<TimeInForce> & { validate?: DdfValidator[] };

export const makeTifDurationOption = (
  subcomponentName: `${'bid' | 'ask'}TifDuration` | 'tifDuration'
): TimeInForceMultiInputOption => ({
  type: 'item',
  label: mapTif(TimeInForce.Duration),
  subcomponent: {
    component: 'text-field',
    name: subcomponentName,
    placeholder: 'mm:ss',
    label: 'TIF (MM:SS)'
  },
  id: TimeInForce.Duration
});

export const commonTimeInForceOptions: TimeInForceMultiInputOption[] = [
  {
    type: 'item',
    label: mapTif(TimeInForce.Ioc),
    id: TimeInForce.Ioc
  },
  {
    type: 'item',
    label: mapTif(TimeInForce.Day),
    id: TimeInForce.Day
  },
  {
    type: 'item',
    label: mapTif(TimeInForce.Open),
    id: TimeInForce.Open
  },
  {
    type: 'item',
    label: mapTif(TimeInForce.Close),
    id: TimeInForce.Close
  },
  {
    type: 'item',
    label: mapTif(TimeInForce.Gtc),
    id: TimeInForce.Gtc
  }
];

export const makeTifGtdTimestampOption = (
  subcomponentName: `${'bid' | 'ask'}GtdTimestamp` | 'gtdTimestamp',
  initialValue?: string,
  isPrimaryField?: boolean
): TimeInForceMultiInputOption => ({
  type: 'item',
  label: mapTif(TimeInForce.Gtd),
  subcomponent: {
    component: 'native-date-picker',
    name: subcomponentName,
    label: 'TIF (GTD)',
    timePicker: true,
    ...(initialValue ? { initialValue } : {}),
    isPrimaryField
  },

  validate: [{ type: 'afterDate', message: 'Must be future date.' }],
  id: TimeInForce.Gtd
});

export const getTimeInForceOptions = ({
  isPrimaryField
}: {
  isPrimaryField?: boolean;
} = {}): TimeInForceMultiInputOption[] =>
  [makeTifDurationOption('tifDuration')]
    .concat(commonTimeInForceOptions)
    .concat(makeTifGtdTimestampOption('gtdTimestamp', getTomorrowMidnight(), isPrimaryField));
