import type { BehaviorSubject, Observable } from 'rxjs';
import { useVGrid, VGrid } from '@oms/frontend-vgrid';
import { buildNotificationsColumnDefs } from './notifications.columns';
import type { SortType } from './notifications.widget';
import { clearNotificationsAction } from './grid-actions/clear.notifications.action';
import { inlineNotificationsActions } from './grid-actions/inline.notifications.action';
import { createNotificationsEventHandler } from './event-handlers/notifications.event-handler';
import {
  NotificationHasExecutedQuantity,
  NotificationHazard,
  NotificationNoExecutedQuantity,
  NotificationPending
} from './notifications.utils';
import { NotificationVisibility } from '@app/notifications/notifications.contracts';
import { TsNotificationsWithFilterDocument } from '@oms/generated/frontend';
import type { NotificationRow, TsNotificationsWithFilterSubscription } from '@oms/generated/frontend';
import { withTableServer } from '@app/data-access/services/system/table-server/with.table-server.component';

export const NOTIFICATIONS_GRID = 'notifications-grid';

type NotificationSubscriptionKeys = 'notifications';

type NotificationSubcriptionMap = {
  notifications: {
    datasource: NotificationRow;
    subscription: TsNotificationsWithFilterSubscription;
  };
};

type NotificationProps = {
  variant$: Observable<NotificationVisibility>;
  sort$: BehaviorSubject<SortType>;
};

// This is the common grid used within the Notifications widget. Each of our 3 grids (my alerts, team alerts, all alerts)
// are variants of this grid.
export const NotificationsGridWidget = withTableServer<
  NotificationSubscriptionKeys,
  NotificationSubcriptionMap,
  NotificationProps
>(
  ({ datasources, props }) => {
    const { variant$, sort$ } = props || {};

    const gridProps = useVGrid<NotificationRow>(
      NOTIFICATIONS_GRID,
      (b) =>
        b
          .tableServerColumnLibrary(buildNotificationsColumnDefs())
          .datasource(
            (d) =>
              d
                .source(
                  datasources.notifications.asServerSideRowModel({
                    filter: {
                      // Setting TableServer filters
                      isDisplayed: {
                        filterType: 'boolean',
                        filter: true
                      }
                    }
                  })
                )
                .rowId(({ data }) => data?.id)
                .cacheBlockSize(100) // This is the AG Grid default. Tweak depending on performance.
          )
          .headerHeight(0) // Make the header height 0 to hide it.
          .rowHeight(60)
          .injectEvents([createNotificationsEventHandler(variant$, sort$)])
          .rowSelection((c) => c.multiple()) // TODO: not yet fully implemented. Need to handle double clicks?
          .rowStateRules({
            pending: (params) => NotificationPending(params.data),
            noExecutedQuantity: (params) => NotificationNoExecutedQuantity(params.data),
            hasExecutedQuantity: (params) => NotificationHasExecutedQuantity(params.data),
            hazard: (params) => NotificationHazard(params.data)
          })
          .actions((a) =>
            a.schema((s) => s.action(clearNotificationsAction).action(inlineNotificationsActions))
          ),
      // .sideBar(), // show/hide sidebar during Dev
      // .context not yet implemented
      [variant$, sort$, datasources]
    );

    return <VGrid {...gridProps} className="no-header" />;
  },
  {
    notifications: {
      query: TsNotificationsWithFilterDocument,
      getData: (r) => r.tsNotificationsWithFilter
    }
  }
);

export default NotificationsGridWidget;
