import { OrderTagsService } from '@app/data-access/services/reference-data/order-tags/order-tags.service';
import type { AdvancedSelectQueryFn, DataSourceCommon } from '@oms/frontend-foundation';
import type { ComboBoxItem } from '@oms/ui-design-system';
import type { TagFragment } from '@oms/generated/frontend';
import { map } from 'rxjs';

export const watchAllOrderTagsQuery: AdvancedSelectQueryFn = (container) => {
  const orderTagsService = container.resolve(OrderTagsService);

  return {
    type: 'watchAll',
    query(params) {
      return orderTagsService.watchAll$(...(params?.queryProps?.filters || [])).pipe(
        map<DataSourceCommon<TagFragment>, DataSourceCommon<ComboBoxItem>>((r) => ({
          results: (r.results || []).map((tag) => ({
            id: tag.id,
            // Workaround for enrichment until migrating to ID only driven comboxboxes:
            // Since enrichment returns only id, we put it in the label and sublabel as well
            label: tag.tagName ?? tag.id,
            sublabel: tag?.tagCode ?? tag.id,
            value: tag.id,
            type: 'item'
          })),
          isFetching: r.isFetching,
          error: r.error
        }))
      );
    }
  };
};
