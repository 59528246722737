import { ReconnectSignal } from '@app/data-access/memory/reconnect.signal';
import { testScoped } from '@app/workspace.registry';
import type { CreateSignalOpts } from '@oms/rx-broadcast';
import { singleton } from 'tsyringe';

@testScoped
@singleton()
export class TableServerReconnectSignal extends ReconnectSignal {
  constructor(opts: CreateSignalOpts | undefined = undefined) {
    const options = opts || {};
    super('table_server_reconnect', { broadcast: true, ...options });
  }
}
