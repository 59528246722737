import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Box } from '../../system/components/box/box';
import type { BoxProps } from '../../system/components/box/box';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';

export type SizeSliderProps = {} & BoxProps;
export const robotoFlex = '"Roboto Flex", system-ui, sans-serif';
export const robotoMono = '"Roboto Mono", Courier, monospace';
export const comicSans = '"Comic Sans MS", "Comic Sans", cursive;';
export const SizeSlider = polymorphicComponent<'div', SizeSliderProps>((props, ref) => {
  const { className, ...rest } = props;
  const [size, setSize] = useState('12');

  useEffect(() => {
    document.body.style.fontSize = `${size}px`;
    document.documentElement.style.fontSize = `${size}px`;
  }, [size]);

  return (
    <Box ref={ref} className={clsx(className)} {...rest}>
      Size: {size}px
      <br />
      <input
        type="range"
        id="display-size"
        name="displaySize"
        min="10"
        max="18"
        step="1"
        value={size}
        onChange={(e) => setSize(e.target.value)}
      />
    </Box>
  );
});

if (__DEV__) {
  SizeSlider.displayName = 'SizeSlider';
}
