import { Lazy, asArray, cleanMaybe, compactMap } from '@oms/ui-util';
import { OneOrMore, whereNotUndefined } from '@oms/ui-util';
import type { Optional } from '@oms/ui-util';
import type { Maybe } from '@oms/ui-util';
import type {
  PositionFragment,
  PositionsTreeForAccountFragment,
  PositionGroupingAccountFragment
} from '@oms/generated/frontend';
import type { PositionRow } from '@app/common/types/positions/positions.types';
import type { SimpleInvestorAccount } from '@app/common/types/accounts/types';
import type { NestedTreeData } from '../common/tree-grid/types/tree-data.types';
import { testTrees } from './test-trees.data';
import convert, { hasAccountType, hasAccounts, hasChildren } from '../util/positions.convert.util';
import type { AnyGroupingAccountFragment } from '../util/positions.convert.util';

type AccountId = string;
type AccountMap<T> = Map<Maybe<AccountId>, T[]>;

type AllAccountMaps = {
  accountMap: AccountMap<AnyGroupingAccountFragment>;
  accountPositionMap: AccountMap<PositionFragment>;
};

/**
 * Tool to help transform Positions Tree data as rows for Positions grids.
 */
export class PositionRowTool {
  public readonly trees: PositionsTreeForAccountFragment[];

  private _lazy_accountMaps: Lazy<AllAccountMaps>;

  /**
   * Tool to help transform Positions Tree data as rows for Positions grids.
   * @param trees One or more positions tree (either array or single object)
   */
  public constructor(trees: OneOrMore<PositionsTreeForAccountFragment>) {
    const treeArray = asArray(trees);
    this.trees = treeArray;
    this._lazy_accountMaps = new Lazy(() => PositionRowTool.initAccountMaps(treeArray));
  }

  /**
   * Tool to help transform Positions Tree data as rows for Positions grids.
   * @param trees One or more positions tree (either array or single object)
   */
  public static for(trees: OneOrMore<PositionsTreeForAccountFragment>): PositionRowTool {
    return new PositionRowTool(trees);
  }

  /** A simplified list of investor accounts to use on Position forms dropdowns */
  public get simpleInvestorAccounts(): SimpleInvestorAccount[] {
    return this.trees.flatMap(({ accountIdsAndNames }) => {
      return compactMap(cleanMaybe(accountIdsAndNames, []), (account): Optional<SimpleInvestorAccount> => {
        if (!account) return;
        const { accountId: id, accountName: label } = account;
        if (!id || !label) return;
        return { id, label, type: 'investor' };
      });
    });
  }

  /**
   * @returns Nested row data for the upper Positions grid, formatted for the server-side render model
   * @link [Server Side Model Tree Data](https://www.ag-grid.com/javascript-data-grid/server-side-model-tree-data/)
   */
  public getTreeDataPositionRows(): NestedTreeData<PositionRow>[] {
    return compactMap(this.trees, ({ accountsForEntity: groupingAccount }) => {
      if (!groupingAccount) return;
      return convert.groupingAccount(groupingAccount).toPositionRowForTreeData();
    });
  }

  /**
   * @param accountId - Optionally, supply an account ID to filter by or omit to get all rows
   * @returns Flat row data for the lower Positions grid
   */
  public getAccountPositionRows(accountId?: string): PositionRow[] {
    if (!this.trees.length) return [];
    if (!accountId) return this.accountRowsFromTree();
    return this.accountRowsFromTreeForAccount(accountId);
  }

  // 👁️ Private ---------------------------------------------------- /

  private get accountMap() {
    return this._lazy_accountMaps.value.accountMap;
  }

  private get accountPositionMap() {
    return this._lazy_accountMaps.value.accountPositionMap;
  }

  private accountRowsFromTree(): PositionRow[] {
    return this.trees.reduce((resultRows, { accountPositions }) => {
      const rows = accountPositions?.reduce((acc, current) => {
        if (!current) return acc;
        const addedPositions = compactMap(cleanMaybe(current.positions, []), (position) => {
          if (!position) return;
          return convert.positionFragment(position).toPositionRow();
        });
        return [...acc, ...addedPositions];
      }, [] as PositionRow[]);
      return rows ? [...resultRows, ...rows] : resultRows;
    }, [] as PositionRow[]);
  }

  private accountRowsFromTreeForAccount(accountId: string): PositionRow[] {
    const groupingAccounts = this.accountMap.get(accountId);
    if (!groupingAccounts) return [];
    return this.getPositionRowsFrom(this.selectAccounts(groupingAccounts));
  }

  private selectAccounts(
    groupingAccounts: OneOrMore<AnyGroupingAccountFragment>
  ): AnyGroupingAccountFragment[] {
    return asArray(groupingAccounts).reduce((acc, groupingAccount) => {
      const addedAccounts: AnyGroupingAccountFragment[] = [...acc];
      if (hasAccounts(groupingAccount)) {
        addedAccounts.push(...groupingAccount.accounts.filter(whereNotUndefined));
      }

      if (hasAccountType(groupingAccount) && groupingAccount.accountType?.toUpperCase().includes('FIRM'))
        addedAccounts.push(groupingAccount);

      if (hasChildren(groupingAccount)) {
        addedAccounts.push(...this.selectAccounts(groupingAccount.children));
      }

      return addedAccounts;
    }, [] as AnyGroupingAccountFragment[]);
  }

  private getPositionRowsFrom(accounts: OneOrMore<AnyGroupingAccountFragment>): PositionRow[] {
    return asArray(accounts).flatMap((account) => {
      const positions = this.accountPositionMap.get(account.id) ?? [];
      return positions.map((position) => convert.positionFragment(position).toPositionRow());
    });
  }

  private static initAccountMaps(treeArray?: PositionsTreeForAccountFragment[]): AllAccountMaps {
    const accountMap: AccountMap<PositionGroupingAccountFragment> = new Map();
    const accountPositionMap: AccountMap<PositionFragment> = new Map();

    const addToMap = <T>(
      map: AccountMap<T>,
      accountId: Maybe<AccountId>,
      value: Maybe<OneOrMore<Maybe<T>>>
    ) => {
      if (!value) return;
      const current = map.get(accountId) ?? [];
      map.set(accountId, [...current, ...compactMap(asArray(value))]);
    };

    const processGroupingAccount = (groupingAccount: Maybe<AnyGroupingAccountFragment>) => {
      if (!groupingAccount) {
        return;
      }

      addToMap(accountMap, groupingAccount.id, groupingAccount);

      if (hasAccounts(groupingAccount)) {
        groupingAccount.accounts.forEach(processGroupingAccount);
      }
      if (hasChildren(groupingAccount)) {
        groupingAccount.children.forEach(processGroupingAccount);
      }
    };

    treeArray?.forEach(({ accountPositions, accountsForEntity }) => {
      processGroupingAccount(accountsForEntity);
      accountPositions?.forEach((accountPosition) => {
        if (!accountPosition) return;
        const { accountId, positions } = accountPosition;
        addToMap(accountPositionMap, accountId, positions);
      });
    });
    return { accountMap, accountPositionMap };
  }

  // 🧪 Testing ---------------------------------------------------- /

  public static testInstance(overrideTrees?: OneOrMore<PositionsTreeForAccountFragment>): PositionRowTool {
    const trees = overrideTrees ?? testTrees;
    return new PositionRowTool(trees);
  }
}

export default PositionRowTool;
