import type { Prettify } from '@oms/ui-util';
import type {
  MONTAGE_BUMP_PRICE_ACTION_NAME,
  MontageBumpPrice
} from '@app/widgets/trading/montage/commands/bump-price/bump-price.action.types';
import type {
  MONTAGE_TARGET_TRADING_ORDER_ACTION_NAME,
  MontageTargetTradingOrder
} from '@app/widgets/trading/montage/commands/target-trading-order/target-trading-order.action.types';
import {
  CreateInvestorOrderAction,
  CREATE_INVESTOR_ORDER_ACTION_NAME
} from '@app/widgets/trading/investor-order-entry/commands/create-investor-order/create-investor-order.action.types';
import type {
  CREATE_UNBOUND_TO_ACTION_NAME,
  CreateUnboundTradeOrder
} from '@app/widgets/trading/route-order/commands/create-unbound-trading-order/create-unbound-trading-order.action.types';
import type {
  RouteOrderAction,
  ROUTE_ORDER_ACTION_NAME
} from '@app/widgets/trading/route-order/commands/route-order/route-order.action.types';
import {
  VIEW_INVESTOR_ORDER_ACTION_NAME,
  ViewInvestorOrderAction
} from '@app/widgets/trading/investor-order-view/commands/view-investor-order/view-investor-order.action.types';
import {
  ACCEPT_INVESTOR_ORDER_ACTION_NAME,
  AcceptInvestorOrderAction
} from '@app/widgets/trading/investor-order-monitor/commands/accept-investor-order/accept-investor-order.action.types';
import {
  DONE_FOR_DAY_ACTION_NAME,
  DoneForDayAction
} from '@app/widgets/trading/investor-order-monitor/commands/done-for-day/done-for-day.action.types';
import {
  REJECT_INVESTOR_ORDER_ACTION_NAME,
  RejectInvestorOrderAction
} from '@app/widgets/trading/investor-order-monitor/commands/reject-investor-order/reject-investor-order.action.types';
import {
  CANCEL_INVESTOR_ORDER_ACTION_NAME,
  CancelInvestorOrderAction
} from '@app/widgets/trading/investor-order-monitor/commands/cancel-investor-order/cancel-investor-order.action.types';
import {
  MODIFY_INVESTOR_ORDER_ACTION_NAME,
  ModifyInvestorOrderAction
} from '@app/widgets/trading/investor-order-entry/commands/modify-investor-order/modify-investor-order.action.types';
import type { ButtonConfigSubject } from '../events/button.config.subject';
import type { Action } from '../types/action.types';

export type ActionCommandType = 'configurable' | 'context-menu';

// TODO: Codegen adding commands to the types in this file.
export type ActionCommands =
  | typeof ACCEPT_INVESTOR_ORDER_ACTION_NAME
  | typeof CANCEL_INVESTOR_ORDER_ACTION_NAME
  | typeof CREATE_INVESTOR_ORDER_ACTION_NAME
  | typeof CREATE_UNBOUND_TO_ACTION_NAME
  | typeof DONE_FOR_DAY_ACTION_NAME
  | typeof MONTAGE_BUMP_PRICE_ACTION_NAME
  | typeof MONTAGE_TARGET_TRADING_ORDER_ACTION_NAME
  | typeof MODIFY_INVESTOR_ORDER_ACTION_NAME
  | typeof REJECT_INVESTOR_ORDER_ACTION_NAME
  | typeof ROUTE_ORDER_ACTION_NAME
  | typeof VIEW_INVESTOR_ORDER_ACTION_NAME;

export type ActionCommandConfig<T extends ActionCommandContractValues> = Prettify<
  CommandMeta & Pick<Action, 'confirmation'> & { payload?: T; actionContext$?: ButtonConfigSubject }
>;

export type ActionCommandContracts = {
  [ACCEPT_INVESTOR_ORDER_ACTION_NAME]: AcceptInvestorOrderAction;
  [CANCEL_INVESTOR_ORDER_ACTION_NAME]: CancelInvestorOrderAction;
  [CREATE_INVESTOR_ORDER_ACTION_NAME]: CreateInvestorOrderAction;
  [CREATE_UNBOUND_TO_ACTION_NAME]: CreateUnboundTradeOrder;
  [DONE_FOR_DAY_ACTION_NAME]: DoneForDayAction;
  [MONTAGE_BUMP_PRICE_ACTION_NAME]: MontageBumpPrice;
  [MONTAGE_TARGET_TRADING_ORDER_ACTION_NAME]: MontageTargetTradingOrder;
  [MODIFY_INVESTOR_ORDER_ACTION_NAME]: ModifyInvestorOrderAction;
  [REJECT_INVESTOR_ORDER_ACTION_NAME]: RejectInvestorOrderAction;
  [ROUTE_ORDER_ACTION_NAME]: RouteOrderAction;
  [VIEW_INVESTOR_ORDER_ACTION_NAME]: ViewInvestorOrderAction;
};

export const AllActionCommandsRecord: Record<keyof ActionCommandContracts, true> = {
  accept_investor_order: true,
  cancel_investor_order: true,
  create_investor_order: true,
  create_unbound_to: true,
  done_for_day: true,
  montage_bump_price: true,
  montage_target_trading_order: true,
  modify_investor_order: true,
  reject_investor_order: true,
  route_order: true,
  view_investor_order: true
};

export const ActionCommandsList = Object.keys(AllActionCommandsRecord) as readonly string[];

export type ActionCommandContractValues = ActionCommandContracts[keyof ActionCommandContracts];

export type CommandMeta = {
  allowsConfirmation: boolean;
  allowsLabel: boolean;
  allowsSize: boolean;
  allowsColor: boolean;
  commandType: CommandTypes;
};

export type CommandTypes =
  | 'DialogWithDefaults'
  | 'LaunchDialog'
  | 'LaunchWidget'
  | 'SingleCommand'
  | 'Custom';

export const COMMANDS_TO_META: Record<ActionCommands, CommandMeta> = {
  accept_investor_order: {
    allowsLabel: true,
    allowsConfirmation: false,
    allowsSize: true,
    allowsColor: true,
    commandType: 'SingleCommand'
  },
  cancel_investor_order: {
    allowsLabel: true,
    allowsConfirmation: true,
    allowsSize: true,
    allowsColor: true,
    commandType: 'SingleCommand'
  },
  create_investor_order: {
    allowsLabel: true,
    allowsConfirmation: true,
    allowsSize: true,
    allowsColor: true,
    commandType: 'DialogWithDefaults'
  },
  create_unbound_to: {
    allowsLabel: true,
    allowsConfirmation: true,
    allowsSize: true,
    allowsColor: true,
    commandType: 'DialogWithDefaults'
  },
  done_for_day: {
    allowsLabel: true,
    allowsConfirmation: true,
    allowsSize: true,
    allowsColor: true,
    commandType: 'SingleCommand'
  },
  montage_bump_price: {
    allowsLabel: false,
    allowsConfirmation: false,
    allowsSize: false,
    allowsColor: false,
    commandType: 'Custom'
  },
  montage_target_trading_order: {
    allowsLabel: false,
    allowsConfirmation: false,
    allowsSize: false,
    allowsColor: false,
    commandType: 'Custom'
  },
  modify_investor_order: {
    allowsLabel: true,
    allowsConfirmation: false,
    allowsSize: true,
    allowsColor: true,
    commandType: 'LaunchDialog'
  },
  reject_investor_order: {
    allowsLabel: true,
    allowsConfirmation: true,
    allowsSize: true,
    allowsColor: true,
    commandType: 'LaunchDialog'
  },
  route_order: {
    allowsLabel: true,
    allowsConfirmation: true,
    allowsSize: true,
    allowsColor: true,
    commandType: 'DialogWithDefaults'
  },
  view_investor_order: {
    allowsLabel: true,
    allowsConfirmation: false,
    allowsSize: true,
    allowsColor: true,
    commandType: 'LaunchWidget'
  }
};
