import type { Prettify } from '@oms/ui-util';
import type { GQLMutationEnvelopeShape } from '@oms/frontend-foundation';

export type HotSuccessResp = {
  type: 'hot-success';
};

export type HotErrorResp<TFormValues> = {
  type: 'hot-error';
  initialValues: TFormValues | undefined;
  initialFeedback: NonNullable<GQLMutationEnvelopeShape<any>['feedback']>;
};

export type ColdResp<TFormValues> = {
  type: 'cold';
  initialValues: TFormValues | undefined;
  initialFeedback?: NonNullable<GQLMutationEnvelopeShape<any>['feedback']>;
};

export type ErrorResp = {
  type: 'error';
  error: Error;
};

export type ConfigButtonResponse<TFormValues> = Prettify<
  HotSuccessResp | HotErrorResp<TFormValues> | ColdResp<TFormValues> | ErrorResp
>;

/**
 * Represents the type of confirmation requested by the action
 * - 'always': Always request confirmation
 * - 'never': Never request confirmation
 * - 'auto': Request confirmation only if required by context
 */
export type ConfirmationType = 'always' | 'never' | 'auto';

export const DEFAULT_CONFIRMATION_TYPE: ConfirmationType = 'auto';
