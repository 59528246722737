import type { Prettify } from '@oms/ui-util';
import { Link, LinkProps } from '../link/link';
import { Numeric, NumericProps } from './components/numeric/numeric';
import { TextField, TextFieldProps } from './components/text-field/text-field';
import { Badge as BadgeComp, BadgeProps } from './components/badge/badge';
import { InWindowPopover, InWindowPopoverProps } from './components/in-window-popover/in-window-popover';
import { DISPLAY_FIELD_COMPONENT_TYPE } from './display-field.contracts';

export const displayFieldComponentMapper = {
  [DISPLAY_FIELD_COMPONENT_TYPE.Numeric]: Numeric,
  [DISPLAY_FIELD_COMPONENT_TYPE.Link]: Link,
  [DISPLAY_FIELD_COMPONENT_TYPE.Text]: TextField,
  [DISPLAY_FIELD_COMPONENT_TYPE.Badge]: BadgeComp,
  [DISPLAY_FIELD_COMPONENT_TYPE.InWindowPopover]: InWindowPopover
} as const;

export type DisplayFieldComponentProps = Prettify<
  NumericProps | LinkProps | TextFieldProps | BadgeProps | InWindowPopoverProps
>;
