import { Box, Center, HStack, Spinner, VStack } from '@oms/ui-design-system';
import { LogoComponent } from '@app/components/logo/logo';
import { useAppVersion } from '@app/common/env/use-app-version.hook';
import { getAppEnv } from '@oms/ui-util';

export function SplashScreenContainer() {
  const version = useAppVersion({ prependV: true });
  return (
    <Center sx={{ backgroundColor: 'layout.backdrop' }}>
      <VStack>
        <HStack spacing={3}>
          <Spinner />
          <LogoComponent />
        </HStack>
        {version && (
          <HStack spacing={3} sx={{ fontSize: 'capsS', color: 'BlueGrey.500', marginLeft: 'small' }}>
            <Box style={{ fontWeight: 'bold' }}>{getAppEnv().toFullEnv()}</Box>
            <Box>{version}</Box>
          </HStack>
        )}
      </VStack>
    </Center>
  );
}
