import { testScoped } from '@app/workspace.registry';
import { getMousePosition } from '@valstro/workspace';
import { createLogger } from '@oms/ui-util';
import { inject, singleton } from 'tsyringe';
import { LocalStorageService } from '@app/data-access/services/system/storage/local-storage.service';
import { openConfirmation } from '@app/generated/sdk';
import { AppWorkspace } from '@app/app-config/workspace.config';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { Dialog } from '@app/common/registry/dialog.open';
import type { ConfirmationDefaultDialog } from '@app/generated/common';

/**
 * Unsubscribe function for inactivity service
 */
type InactivityServiceUnsubscribe = () => void;

/**
 * Init options for inactivity services
 */
export interface InactivityServiceInitOptions {
  isLeader: boolean;
  rootActorId: string;
}

/**
 * Base class for all inactivity services
 *
 * @description
 * This class is responsible for tracking user inactivity
 * and logging out the user if they are inactive for too long
 *
 * @note
 * This class is abstract and must be implemented by each platform
 */
export abstract class InactivityService {
  static readonly STORAGE_KEY = 'last_activity_timestamp';
  static readonly INACTIVITY_EVENT = 'user_inactive';
  static readonly DEFAULT_INACTIVITY_TIMEOUT = 5 * 60 * 60 * 1000; // 5 hours
  static readonly DEFAULT_ACTIVITY_CHECK_INTERVAL = 60 * 1000;
  static readonly DEFAULT_CONFIRMATION_TIMEOUT = 30_000;

  protected inactivityCheckInterval: NodeJS.Timeout | null = null;
  protected setupUnsubscribe: InactivityServiceUnsubscribe | null = null;
  protected rootActorId: string | undefined = undefined;
  protected logger = createLogger({
    name: 'InactivityService'
  });

  private isConfirmingInactivity = false;

  protected inMemoryLastActivity: number = Date.now();
  protected localStorageUpdateInterval: NodeJS.Timeout | null = null;
  protected readonly STORAGE_UPDATE_INTERVAL = InactivityService.DEFAULT_ACTIVITY_CHECK_INTERVAL / 3; // Ensure always less than the inactivity check interval

  constructor(
    @inject(AppWorkspace) private workspace: AppWorkspace,
    @inject(LocalStorageService) private localStorage: LocalStorageService,
    @inject(AuthService) private authService: AuthService
  ) {}

  abstract setup(options: InactivityServiceInitOptions): InactivityServiceUnsubscribe;

  public init({ isLeader, rootActorId }: InactivityServiceInitOptions) {
    this.rootActorId = rootActorId;
    this.inMemoryLastActivity = Date.now();
    this.updateLastActivityInStorage(); // Initialize with current time
    this.setupUnsubscribe = this.setup({ isLeader, rootActorId });

    // Only setup inactivity check if we are the leader
    if (isLeader) {
      this.setupInactivityCheck();
      this.setupStorageUpdateInterval();
    }
  }

  public destroy() {
    this.isConfirmingInactivity = false;

    if (this.inactivityCheckInterval) {
      clearTimeout(this.inactivityCheckInterval);
    }

    if (this.localStorageUpdateInterval) {
      clearInterval(this.localStorageUpdateInterval);
    }

    if (this.setupUnsubscribe) {
      this.setupUnsubscribe();
    }
  }

  protected updateLastActivity() {
    this.inMemoryLastActivity = Date.now();
  }

  protected updateLastActivityInStorage() {
    this.logger.debug('Updating last activity in storage', this.inMemoryLastActivity);
    this.localStorage.setString(InactivityService.STORAGE_KEY, this.inMemoryLastActivity.toString());
  }

  private setupStorageUpdateInterval() {
    // Throttle updates to localStorage for performance reasons
    this.localStorageUpdateInterval = setInterval(() => {
      this.updateLastActivityInStorage();
    }, this.STORAGE_UPDATE_INTERVAL);
  }

  protected trackInnerWindowChanges(): InactivityServiceUnsubscribe {
    const boundUpdateLastActivity = this.updateLastActivity.bind(this);

    // Track mouse & keyboard activity
    window.addEventListener('mousemove', boundUpdateLastActivity);
    window.addEventListener('mousedown', boundUpdateLastActivity);
    window.addEventListener('keypress', boundUpdateLastActivity);
    window.addEventListener('scroll', boundUpdateLastActivity);
    window.addEventListener('touchstart', boundUpdateLastActivity);

    // Update activity when the tab becomes visible
    const visibilityHandler = () => {
      if (document.visibilityState === 'visible') {
        this.updateLastActivity();
      }
    };
    window.addEventListener('visibilitychange', visibilityHandler);

    // Unsubscribe from events
    return () => {
      window.removeEventListener('mousemove', boundUpdateLastActivity);
      window.removeEventListener('mousedown', boundUpdateLastActivity);
      window.removeEventListener('keypress', boundUpdateLastActivity);
      window.removeEventListener('scroll', boundUpdateLastActivity);
      window.removeEventListener('touchstart', boundUpdateLastActivity);
      window.removeEventListener('visibilitychange', visibilityHandler);
    };
  }

  private setupInactivityCheck() {
    this.inactivityCheckInterval = setInterval(() => {
      if (this.isUserInactive()) {
        this.logger.debug('User is inactive, checking for inactivity');
        this.onInactivity().catch((e) => {
          this.logger.error('Error on inactivity check', e);
        });
      } else {
        this.logger.debug('User is active, resetting inactivity check');
        // Update storage when we do an inactivity check to ensure it's current
        this.updateLastActivityInStorage();
      }
    }, InactivityService.DEFAULT_ACTIVITY_CHECK_INTERVAL);
  }

  private async onInactivity() {
    if (this.isConfirmingInactivity) {
      this.logger.debug('Already confirming inactivity, skipping');
      return;
    }

    this.isConfirmingInactivity = true;
    this.logger.debug('User is inactive, logging out in 30 seconds (if not confirmed)');

    let dialog: Dialog<ConfirmationDefaultDialog> | undefined;

    const timeout = setTimeout(() => {
      this.logger.debug('Timeout reached, logging out');
      if (dialog) {
        this.logger.debug('Closing confirmation dialog');
        dialog.close().catch((e) => {
          this.logger.error('Error closing confirmation dialog', e);
        });
      }
      this.isConfirmingInactivity = false;
      this.authService.logout();
    }, InactivityService.DEFAULT_CONFIRMATION_TIMEOUT);

    const [_dialog, api] = await openConfirmation(this.workspace, this.rootActorId, {
      title: 'Session Timeout',
      componentProps: {
        message: 'You will be logged out in 30 seconds due to inactivity.',
        hideCancelButton: true,
        autoClose: true,
        confirmButtonText: 'Continue session',
        confirmButtonProps: {
          palette: 'standard'
        }
      }
    });

    dialog = _dialog;

    this.logger.debug('Waiting for confirmation dialog response');

    const result = await api.awaitFirstEvent;

    this.logger.debug('Confirmation dialog response received', result);

    if (result.type === 'OK') {
      this.logger.debug('User confirmed session, updating last activity timestamp');
      this.updateLastActivity();
      clearTimeout(timeout);
      this.isConfirmingInactivity = false;
      return;
    }
  }

  private getLastActivityTimestamp() {
    // First check in-memory timestamp
    if (this.inMemoryLastActivity) {
      return this.inMemoryLastActivity;
    }

    // Fall back to localStorage if in-memory is not available
    const lastActivityTimestamp = this.localStorage.getString(InactivityService.STORAGE_KEY);
    if (!lastActivityTimestamp) {
      return Date.now();
    }
    return parseInt(lastActivityTimestamp);
  }

  private isUserInactive() {
    const lastActivityTimestamp = this.getLastActivityTimestamp();
    const currentTime = Date.now();
    return currentTime - lastActivityTimestamp > InactivityService.DEFAULT_INACTIVITY_TIMEOUT;
  }
}

/**
 * Browser Inactivity Service
 *
 * @description
 * This class is responsible for tracking user inactivity in the browser
 * and logging out the user if they are inactive for too long
 *
 * @note
 * This will update the last activity timestamp for changes across all tabs
 * but will only run regular inactivity checks on the leader tab
 */
@testScoped
@singleton()
export class BrowserInactivityService extends InactivityService {
  constructor(
    @inject(AppWorkspace) workspace: AppWorkspace,
    @inject(LocalStorageService) localStorage: LocalStorageService,
    @inject(AuthService) authService: AuthService
  ) {
    super(workspace, localStorage, authService);
  }

  setup() {
    // Track inner window changes for every tab
    const unsubscribe = this.trackInnerWindowChanges();

    return () => {
      unsubscribe();
    };
  }
}

/**
 * Tauri Inactivity Service
 *
 * @description
 * This class is responsible for tracking user inactivity in the tauri app
 * and logging out the user if they are inactive for too long
 *
 * @note
 * This will update the last activity timestamp for changes across all tabs AND the OS level mouse movement
 * but will only run regular inactivity checks on the leader tab
 */
@testScoped
@singleton()
export class TauriInactivityService extends InactivityService {
  private OS_MOUSE_MOVEMENT_CHECK_INTERVAL = InactivityService.DEFAULT_ACTIVITY_CHECK_INTERVAL / 2; // Less frequent than checks for inactivity
  private lastMousePosition: { x: number; y: number } | null = null;

  constructor(
    @inject(AppWorkspace) workspace: AppWorkspace,
    @inject(LocalStorageService) localStorage: LocalStorageService,
    @inject(AuthService) authService: AuthService
  ) {
    super(workspace, localStorage, authService);
  }

  setup(options: InactivityServiceInitOptions) {
    let lastOSMousePositionUnsubscribe: InactivityServiceUnsubscribe | null = null;

    // Still track inner window changes for every tab
    const windowUnsubscribe = this.trackInnerWindowChanges();

    // Only setup OS level mouse movement check if we are the leader
    // Because this particular check is global to the users machine
    // And we only want one instance to be running
    if (options.isLeader) {
      lastOSMousePositionUnsubscribe = this.setupOSMouseMovementCheck();
    }

    return () => {
      windowUnsubscribe();
      if (lastOSMousePositionUnsubscribe) {
        lastOSMousePositionUnsubscribe();
      }
    };
  }

  private checkMousePosition() {
    getMousePosition()
      .then((position) => {
        const { x, y } = position;
        const { x: lastX, y: lastY } = this.lastMousePosition || {};

        // If the mouse position has initialized, and has moved since the last check,
        // update the last activity timestamp
        if (lastX !== undefined && lastY !== undefined && (lastX !== x || lastY !== y)) {
          this.updateLastActivity();
        }

        this.lastMousePosition = position;
      })
      .catch((e) => {
        this.logger.error('Error checking mouse position', e);
      });
  }

  private setupOSMouseMovementCheck(): InactivityServiceUnsubscribe {
    const boundCheckMousePosition = this.checkMousePosition.bind(this);
    const interval = setInterval(boundCheckMousePosition, this.OS_MOUSE_MOVEMENT_CHECK_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }
}
