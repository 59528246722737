import { ComponentType, PropsWithRef, useMemo } from 'react';
import { type CustomCellRendererProps } from '@ag-grid-community/react';
import { type AnyRecord } from '@oms/frontend-foundation';

export type CustomDetailRendererGetPropsCallback<TData, TDetailProps> = (
  props: CustomCellRendererProps<TData>
) => TDetailProps | null;

export type CustomDetailRendererRowHeightParams = {
  numberOfRows?: number;
  hideToolbar?: boolean;
  headerComponentHeight?: number;
};

export type CustomDetailRendererGetRowHeightParamsCallback<TData> = (
  props: CustomCellRendererProps<TData>
) => CustomDetailRendererRowHeightParams;

export type DetailCellRendererParams<
  TData extends AnyRecord,
  THeaderProps extends AnyRecord,
  TDetailProps extends AnyRecord
> = {
  HeaderComponent?: ComponentType<THeaderProps>;
  getHeaderProps?: CustomDetailRendererGetPropsCallback<TData, THeaderProps>;
  DetailComponent?: ComponentType<TDetailProps>;
  getDetailProps?: CustomDetailRendererGetPropsCallback<TData, TDetailProps>;
  getDetailRowHeightParams?: CustomDetailRendererGetRowHeightParamsCallback<TData>;
};

const TOOLBAR_HEIGHT = 42;
const HORIZONTAL_SCROLLBAR_HEIGHT = 18;

export const createDetailCellRenderer = <
  TData extends AnyRecord,
  THeaderProps extends AnyRecord,
  TDetailProps extends AnyRecord
>({
  HeaderComponent,
  DetailComponent,
  getHeaderProps,
  getDetailProps,
  getDetailRowHeightParams
}: DetailCellRendererParams<TData, THeaderProps, TDetailProps>) => {
  return (cellRendererProps: CustomCellRendererProps<TData>) => {
    const detailProps = useMemo(() => getDetailProps?.(cellRendererProps), [cellRendererProps]);

    const headerProps = useMemo(() => getHeaderProps?.(cellRendererProps), [cellRendererProps]);

    const { api, node } = cellRendererProps;
    const { rowHeight, headerHeight } = api.getSizesForCurrentTheme();
    const {
      numberOfRows = 0,
      hideToolbar = false,
      headerComponentHeight = 0
    } = getDetailRowHeightParams?.(cellRendererProps) ?? {};

    const height = useMemo(() => {
      if (!numberOfRows) {
        return undefined;
      }
      return (
        numberOfRows * rowHeight +
        headerHeight +
        (hideToolbar ? 0 : TOOLBAR_HEIGHT) +
        HORIZONTAL_SCROLLBAR_HEIGHT +
        headerComponentHeight
      );
    }, [rowHeight, headerHeight, numberOfRows, hideToolbar, headerComponentHeight]);

    const style = useMemo(() => ({ height, display: 'flex', flexDirection: 'column' as const }), [height]);

    if (!DetailComponent || !node.detail) {
      return null;
    }

    return (
      <div className="detail-cell-renderer-wrapper" style={style}>
        {HeaderComponent ? (
          <HeaderComponent {...(headerProps as PropsWithRef<THeaderProps> & THeaderProps)} />
        ) : null}
        <DetailComponent {...(detailProps as PropsWithRef<TDetailProps> & TDetailProps)} />
      </div>
    );
  };
};
