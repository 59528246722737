import type { Maybe, Prettify } from '@oms/ui-util';
import {
  ExecutionStatus,
  InvestorOrderStatus,
  TradeStatus,
  TradingOrderStatus
} from '@oms/generated/frontend';
import type { ExecutionRow, InvestorOrderRow, TradeRow, TradingOrderRow } from '@oms/generated/frontend';

const INACTIVE_ORDER_STATUS_SET = new Set<string>([
  // Execution status
  ExecutionStatus.Inactive,
  // Investor order status
  InvestorOrderStatus.Cancelled,
  InvestorOrderStatus.ExecutionCancelled,
  InvestorOrderStatus.Expired,
  InvestorOrderStatus.FullyFilled,
  InvestorOrderStatus.Inactive,
  InvestorOrderStatus.Rejected,
  // Trade status
  TradeStatus.Inactive,
  // Trading order status
  TradingOrderStatus.Cancelled,
  TradingOrderStatus.Expired,
  TradingOrderStatus.Inactive,
  TradingOrderStatus.Rejected,
  TradingOrderStatus.TradeCancelled
]);

type AnyStatus = ExecutionStatus | InvestorOrderStatus | TradeStatus | TradingOrderStatus | string;

/**
 * Checks if an order status string is inactive
 * @param status The order status string to check (optional, and could be undefined or null)
 * @returns True if the status is considered inactive, false otherwise
 */
export function isOrderStatusInactive(status?: Maybe<AnyStatus>): boolean {
  if (typeof status !== 'string') return false;
  return INACTIVE_ORDER_STATUS_SET.has(status.toUpperCase());
}

type AnyOrderRow = Prettify<InvestorOrderRow | TradingOrderRow | ExecutionRow | TradeRow>;

/**
 * Checks if an order row has an inactive status
 * @param order The order row object to check (optional, and could be undefined or null)
 * @returns True if the order's status is considered inactive, false otherwise
 */
export function isOrderInactive(order?: Maybe<Partial<AnyOrderRow>>): boolean {
  if (typeof order !== 'object' || order === null) return false;
  const { executedQuantity = 0 } = order as InvestorOrderRow;
  if (executedQuantity > 0) return false;
  return isOrderStatusInactive(order.status);
}
