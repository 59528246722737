import React, { useCallback } from 'react';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import { useFieldApi } from '../../helpers';
import type { FieldProps } from '../../types';
import { type INativeTimePickerField } from './native-time-picker.type';
import { Input } from '@oms/ui-design-system';

export const NativeTimePicker: React.FC<FieldProps<INativeTimePickerField>> = (props) => {
  const {
    meta,
    label,
    input: { name, onBlur, onChange, onFocus, value },
    isVisible,
    helperText,
    isRequired,
    isDisabled: _isDisabled,
    forceIsDisabled,
    isReadOnly,
    isInvalid,
    isPrimaryField,
    requiredFieldIndicatorStyle,
    hideFormControls,
    style,
    sx,
    wrapperSx = {},
    format,
    initialValue,
    value: _value
  } = useFieldApi<INativeTimePickerField>(props);

  // Sometimes validators override the disabled props. If forceIsDisabled is true, then we should always disable the field.
  const isDisabled = !!forceIsDisabled || !!_isDisabled;

  const valueTimeToString = value || initialValue || '';

  const onChangeStringToTime = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      onChange(value || null);
    },
    [onChange]
  );

  return (
    <FieldWrapper
      meta={meta}
      label={label}
      isReadOnly={isReadOnly}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      requiredFieldIndicatorStyle={requiredFieldIndicatorStyle}
      helperText={helperText}
      hideFormControls={hideFormControls}
      isPrimaryField={isPrimaryField}
      isVisible={isVisible}
      sx={wrapperSx}
    >
      <Input
        name={name}
        type="time"
        onBlur={onBlur}
        value={valueTimeToString || ''}
        onChange={onChangeStringToTime}
        onFocus={onFocus}
        hidden={isVisible === false}
        style={style}
        className="native-time-picker"
        sx={sx}
        format={format}
      />
    </FieldWrapper>
  );
};
