import { COMMON_ACTOR_TYPE, COMMON_PLATFORM_NAME, Plugin } from '@valstro/workspace';
import { filter } from 'rxjs';
import { getCurrentWebviewWindow } from '@tauri-apps/api/webviewWindow';
import { LogicalSize } from '@tauri-apps/api/dpi';
import { getVersion } from '@tauri-apps/api/app';
import type { AppWidgetActorSchema, AppWorkspace } from '@app/app-config/workspace.config';
import { createLogger } from '@oms/ui-util';
import { createWorkspaceWindowReady$ } from '@app/common/workspace/workspace.rxjs';
import { COMPONENT_ENUM } from '@app/generated/common';
import { formatVersion } from '@oms/ui-util';
import * as Sentry from '@sentry/react';

export const launcherAndSplashPluginLogger = createLogger({ name: 'Launcher & Splash Plugin' });

/**
 * Creates the app database when the leader window is ready
 */
export const launcherAndSplashPlugin = () =>
  Plugin.create<AppWorkspace>({
    name: 'valstro-launcher-and-splash-plugin',
    pluginFn: ({ workspace }) => {
      const windowReady$ = createWorkspaceWindowReady$(workspace);
      const leaderWindowReady$ = windowReady$.pipe(filter((x) => x.isLeader));
      const tauriLeaderWindowReady$ = leaderWindowReady$.pipe(
        filter((x) => x.platformName === COMMON_PLATFORM_NAME.TAURI)
      );

      const subscription = tauriLeaderWindowReady$.subscribe(({ rootWindowActor }) => {
        async function setupLauncherWidget() {
          const webview = getCurrentWebviewWindow();
          await webview.setSize(new LogicalSize(280, 40));
          const version = await getVersion();
          // eslint-disable-next-line no-restricted-syntax
          console.debug('🔢 App version:', formatVersion(version, { prependV: true }));

          await rootWindowActor.spawnChild<AppWidgetActorSchema>({
            id: COMPONENT_ENUM.SYSTEM_LAUNCHER, // fix ID to prevent multiple instances
            type: COMMON_ACTOR_TYPE.WIDGET,
            context: {
              componentId: String(COMPONENT_ENUM.SYSTEM_LAUNCHER),
              componentProps: {}
            }
          });
        }

        setupLauncherWidget().catch((err) =>
          Sentry.captureException(err, (scope) => {
            scope.setTransactionName('Setup Launcher Widget Error');
            return scope;
          })
        );
      });

      return () => {
        subscription.unsubscribe();
      };
    }
  });
