import type { Maybe } from '@oms/ui-util';
import { SharedNotification } from '@oms/shared/oms-common';
import { primaryAlertTypeMapping } from './notification-enums';

export const mapPrimaryAlertType = (
  value?: Maybe<SharedNotification.PrimaryAlertType> | string,
  defaultVal = value || ''
): string => {
  if (!value) return defaultVal;
  return primaryAlertTypeMapping[value as SharedNotification.PrimaryAlertType];
};
