import type { ResolvePropsFunction } from '@data-driven-forms/react-form-renderer';
import type { OrderSettingsFormValues } from '../order-settings.form-contract';
import { OrderSizeRowUtil } from './order-size-row-util.class';
import { UUID } from '@oms/ui-util';

export const INIT_PRICE_ROWS = 'initPriceRows';

export const initPriceRows = (): ResolvePropsFunction => (props, fieldApi, formOptions) => {
  const allValues = formOptions.getState().values as OrderSettingsFormValues;
  if (!OrderSizeRowUtil.getProfileDescription(allValues, fieldApi)) {
    return props;
  }
  const util = new OrderSizeRowUtil(allValues, fieldApi);
  if (util.isEmpty) {
    util.addNewRow({ id: UUID(), orderSize: 0 });
    const { profiles } = util.allFormValues;
    formOptions.change<keyof OrderSettingsFormValues>('profiles', profiles);
  }
  return props;
};
