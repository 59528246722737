import { WindowDecorationComponent as Window } from '@valstro/workspace-react';
import {
  Actor,
  AnyActorSchema,
  Plugin,
  isTauri,
  onQuickWindowAndLeaderWindowReady
} from '@valstro/workspace';
import type { AppWorkspace } from '@app/app-config/workspace.config';
import { browserAuthWindowActor } from './auth-window.browser.modal.actor';
import { tauriAuthWindowActor } from './auth-window.tauri.actor';
import { AppWindowWidgetDecoration } from '@app/widgets/widget.window-decoration.component';
import type { PropsWithChildren } from 'react';
import { WebviewWindow } from '@tauri-apps/api/webviewWindow';
import { COMMON_AUTH_WINDOW } from '@app/common/auth/auth.contracts';
import type { CommonAuthWindowActorSchema } from '@app/common/auth/auth.contracts';
import type { AuthClientState } from '@app/common/auth/keycloak.types';
import { DependencyContainer } from 'tsyringe';
import { AuthSignal } from '@app/data-access/memory/auth.signal';
import { createLogger } from '@oms/ui-util';

const logger = createLogger({ name: 'Auth Plugin' });

function AuthWindowDecoration({
  children,
  ...decorationProps
}: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) {
  return (
    <Window.Container {...decorationProps}>
      <Window.ContentContainer>{children}</Window.ContentContainer>
    </Window.Container>
  );
}

export interface AuthWindowPluginOptions {
  forceAuthState?: AuthClientState;
  shouldUseQuickWindows?: boolean;
  container: DependencyContainer;
}

/**
 * Manages auth state in a singe process / window
 */
export const authWindowPlugin = ({
  forceAuthState,
  container,
  shouldUseQuickWindows
}: AuthWindowPluginOptions) =>
  Plugin.create<AppWorkspace>({
    name: 'valstro-auth-window-plugin',
    pluginFn: ({ workspace }) => {
      if (forceAuthState) {
        container.resolve(AuthSignal).signal.set(forceAuthState);
        return;
      }

      const options = isTauri()
        ? {
            defaultContext: {
              isDecorated: true,
              transparent: false
            },
            windowDecorationComponent: AuthWindowDecoration,
            forceAuthState,
            container
          }
        : {
            defaultContext: {
              isDecorated: false,
              transparent: true
            },
            windowDecorationComponent: AppWindowWidgetDecoration,
            forceAuthState,
            container
          };

      // Register the auth window actors
      workspace.getActorRegistry().register(browserAuthWindowActor(options));
      workspace.getActorRegistry().register(tauriAuthWindowActor(options));

      async function openAuthWindow(rootWindowActor: Actor<AnyActorSchema>) {
        // Sometimes, when the auth window is redirected to keycloak, is doens't pick up the close event.
        // Therefore, we need to close the window manually (if already open) before opening a new one.
        if (isTauri()) {
          const webview = await WebviewWindow.getByLabel(COMMON_AUTH_WINDOW.ID);
          if (webview) {
            await webview.close();
          }
        }

        await rootWindowActor.spawnChild({
          type: COMMON_AUTH_WINDOW.TYPE,
          id: COMMON_AUTH_WINDOW.ID,
          context: {
            title: 'Login'
          },
          // Ignore the beforeunload destroy event, as keycloak will redirect to the auth window
          // triggering the beforeunload event which will destroy the window
          advanced: {
            ignoreBeforeUnloadDestroy: true,
            suppressClearUrlParamState: true // Necessary to prevent the auth window from forgetting who it is
          }
        });
      }

      const unlisten =
        isTauri() && shouldUseQuickWindows
          ? onQuickWindowAndLeaderWindowReady(workspace, ({ rootWindowActor }) => {
              openAuthWindow(rootWindowActor).catch(logger.error);
            })
          : workspace.addHook('leaderWindowReady', ({ rootWindowActor }) => {
              openAuthWindow(rootWindowActor).catch(logger.error);
            });

      // Initialize the auth window actor
      workspace
        .addActorHook<CommonAuthWindowActorSchema>(COMMON_AUTH_WINDOW.TYPE)
        .after('start', async () => {
          const authActor = await Actor.get<CommonAuthWindowActorSchema>(COMMON_AUTH_WINDOW.ID);
          authActor.operations.initialize(forceAuthState).catch(logger.error);
          workspace.updateMeta({
            authActor
          });
        });

      return function unsubscribe() {
        unlisten();
      };
    }
  });
