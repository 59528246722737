import { mathOp } from '../number/MathOperations';

/**
 * Converts the first character of a string representation of a value to uppercase.
 *
 * @template Value - The type of the input value, which can be a string, number, or symbol.
 * @param {Value} value - The value to be converted to title case.
 * @returns {string} The title-cased string.
 */
export const toTitleCase = <Value extends string | number | symbol>(value: Value) => {
  const v = value.toString();
  return v.slice(0, 1).toUpperCase() + v.slice(1);
};

type ConvertStringToNumberOptions = {
  thousandDelimiter?: string;
  decimalDelimiter?: string;
};

export const convertStringToNumber = (
  value: string | any,
  options?: ConvertStringToNumberOptions
): number => {
  const { thousandDelimiter, decimalDelimiter } = options || {};

  if (thousandDelimiter && decimalDelimiter) {
    const parts = value.toString().split(decimalDelimiter);
    parts[0] = parts[0].replace(new RegExp('\\' + thousandDelimiter, 'g'), '');

    return parseFloat(parts.join('.')) || value;
  }

  return typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : value;
};

export const convertStringToNumberAndCheckDecimalPlaces = (
  value: string | any,
  options?: ConvertStringToNumberOptions
): { numberValue: number; decimalLength: number } => {
  const { thousandDelimiter, decimalDelimiter } = options || {};

  if (thousandDelimiter && decimalDelimiter) {
    const parts = value.toString().split(decimalDelimiter);
    parts[0] = parts[0].replace(new RegExp('\\' + thousandDelimiter, 'g'), '');

    const numberValue = parseFloat(parts.join('.')) || value;
    const decimalLengthValue = parts[1].length;
    return { numberValue, decimalLength: decimalLengthValue };
  }
  const numberValue = typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : value;

  return { numberValue, decimalLength: mathOp.countDecimalPlaces(value.toString()) };
};

export const isNumeric = (value: string | number): boolean => {
  if (typeof value === 'string') {
    return /^-?\d+(?:\.\d+)?$/.test(value.replace(/,/g, ''));
  }

  return typeof value === 'number';
};
