// External imports
import { useCallback, useMemo, useRef } from 'react';
import { useGridFilter } from '@ag-grid-community/react';
import type { IAfterGuiAttachedParams, IDoesFilterPassParams } from '@ag-grid-community/core';

// Shared imports
import type { Prettify } from '@oms/ui-util';

// App imports
import { agGridArrayFilterSchema } from '@app/data-access/services/system/table-server/filters/ag-grid.filters.schema';
import type { AgGridArrayFilter } from '@app/data-access/services/system/table-server/filters/ag-grid.filters.schema';
import type { CustomFilterFn } from '../table-server/table-server.filters';
import { Box } from '@oms/ui-design-system';

// Types
type InputModel = AgGridArrayFilter;
type ResolvedModel = Prettify<Omit<Required<AgGridArrayFilter>, 'values'> & { values: string[] }>;

// Component
// TODO: This filter currently has no UI. For now, use only for programatic filtering.
export const AgArrayColumnFilter: CustomFilterFn<any, any, InputModel> = ({ getValue, model }) => {
  const refInput = useRef<HTMLInputElement>(null);

  const resolvedModel = useMemo((): ResolvedModel => {
    const {
      filterType = 'array',
      type = 'contains',
      values: modelValues = []
    } = model ? agGridArrayFilterSchema.parse(model) : {};
    return {
      filterType,
      type,
      values: Array.isArray(modelValues) ? modelValues : [modelValues]
    };
  }, [model]);

  const doesFilterPass = useCallback(
    ({ node }: IDoesFilterPassParams<any>) => {
      const { type, values: matchValues } = resolvedModel;
      const cellValues = getValue<string[]>(node) || [];

      if (!Array.isArray(cellValues)) {
        throw new Error('cellValues must be an array');
      }

      switch (type) {
        case 'contains':
          return matchValues.some((matchValue) => cellValues.includes(matchValue));
        case 'notContains':
          return matchValues.every((matchValue) => !cellValues.includes(matchValue));
        case 'equals':
          return (
            matchValues.every((matchValue) => cellValues.includes(matchValue)) &&
            matchValues.length === cellValues.length
          );
        case 'notEqual':
          return !(
            matchValues.every((matchValue) => cellValues.includes(matchValue)) &&
            matchValues.length === cellValues.length
          );
        case 'startsWith':
          return matchValues.every((matchValue) =>
            cellValues.some((cellValue) => cellValue.startsWith(matchValue))
          );
        case 'endsWith':
          return matchValues.every((matchValue) =>
            cellValues.some((cellValue) => cellValue.endsWith(matchValue))
          );
        case 'blank':
          return cellValues.length === 0;
        case 'notBlank':
          return cellValues.length > 0;
        default:
          return true;
      }
    },
    [resolvedModel]
  );

  const afterGuiAttached = useCallback((params?: IAfterGuiAttachedParams) => {
    if (!params?.suppressFocus) {
      refInput.current?.focus();
    }
  }, []);

  useGridFilter({
    doesFilterPass,
    afterGuiAttached
  });

  // TODO: Empty return until UI is ready. This currently renders nothing, but allows for programatic filtering.
  return <Box style={{ height: 0, width: 0 }} />;
};
