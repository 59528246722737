import { AppWorkspace } from '@app/app-config/workspace.config';
import { testScoped } from '@app/workspace.registry';
import { BroadcastSubject, createBroadcastSignal, Signal } from '@oms/rx-broadcast';
import { UUID, isDevEnv } from '@oms/ui-util';
import { useWorkspace } from '@valstro/workspace-react';
import type { RxDatabase, RxStorage } from 'rxdb-oms-app';
import { createRxDatabase, removeRxDatabase } from 'rxdb-oms-app';
import { getRxStorageMemory } from 'rxdb-oms-app/plugins/storage-memory';
import type { MessageFromRemote, MessageToRemote } from 'rxdb-oms-app/plugins/storage-remote';
import { exposeRxStorageRemote, getRxStorageRemote } from 'rxdb-oms-app/plugins/storage-remote';
import type { Subscription } from 'rxjs';
import { BehaviorSubject, filter, Subject, take } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import type { MemoryDatabaseCollections } from './collections';
import { MEMORY_DB_COLLECTIONS_MAP } from './collections';

export type MemoryDatabase = RxDatabase<MemoryDatabaseCollections>;

export interface MemoryDatabaseOptions {
  storage?: RxStorage<any, any>;
  name?: string;
}
@testScoped
@singleton()
export class MemoryDatabaseInitializerService {
  toRemote$: BroadcastSubject<any>;
  toClient$: BroadcastSubject<any>;
  remoteMessages$ = new Subject<MessageToRemote>();
  clientMessages$ = new Subject<MessageFromRemote>();
  clientSubscription: Subscription | undefined;
  remoteSubscription: Subscription | undefined;
  useRemoteStorage = false;
  db: MemoryDatabase | undefined;
  storage: RxStorage<any, any> | undefined;
  rxDbProcessIdentifier: string;
  hasExposedSignal: Signal<boolean>;
  shouldInitialize$ = new BehaviorSubject<boolean | undefined>(undefined);

  constructor(@inject(AppWorkspace) public workspace: AppWorkspace) {
    const leaderId = workspace.getLeaderProcessId();
    this.toRemote$ = new BroadcastSubject<any>(`rxdb/to/remote/${leaderId}`);
    this.toClient$ = new BroadcastSubject<any>(`rxdb/to/client/${leaderId}`);
    this.hasExposedSignal = createBroadcastSignal<boolean>(`rxdb/hasExposed/${leaderId}`, false, {
      initialize$: this.shouldInitialize$,
      initializeOnce: false
    });
    this.rxDbProcessIdentifier = UUID();
  }

  static async createDatabase({ storage, name }: Required<MemoryDatabaseOptions>) {
    return await createRxDatabase<MemoryDatabaseCollections>({
      name: name || 'app-memory',
      storage
    });
  }

  public async create(isLeader: boolean, { storage: _storage, name }: MemoryDatabaseOptions) {
    this.shouldInitialize$.next(isLeader);
    this.useRemoteStorage = _storage === undefined;

    if (this.useRemoteStorage) {
      this.clientSubscription?.unsubscribe();
      this.clientSubscription = this.toClient$.subscribe((msg) => {
        this.clientMessages$.next(msg);
      });

      if (!isLeader) {
        await this.waitForExposed();
      }
    }

    const identifier = this.workspace.getIsLeader()
      ? this.workspace.getLeaderProcessId()
      : this.rxDbProcessIdentifier;

    const that = this;
    const storage =
      _storage ||
      getRxStorageRemote({
        identifier,
        mode: 'storage',
        messageChannelCreator: () =>
          Promise.resolve({
            send(msg) {
              that.toRemote$.next(msg);
            },
            messages$: that.clientMessages$,
            async close() {
              that.clientSubscription?.unsubscribe();
            }
          })
      });

    const db = await MemoryDatabaseInitializerService.createDatabase({
      storage,
      name: name || 'app-memory'
    });

    this.db = db;
    this.storage = storage;

    return { db, storage };
  }

  public expose() {
    this.remoteSubscription?.unsubscribe();
    this.remoteSubscription = this.toRemote$.subscribe((msg) => {
      this.remoteMessages$.next(msg);
    });

    const that = this;
    const result = exposeRxStorageRemote({
      storage: getRxStorageMemory(),
      messages$: that.remoteMessages$,
      send(msg) {
        that.toClient$.next(msg);
      }
    });

    this.hasExposedSignal.set(true);
    return result;
  }

  public waitForExposed() {
    return new Promise<void>((resolve, reject) => {
      let timeout: NodeJS.Timeout | undefined;
      this.hasExposedSignal.$.pipe(
        filter((v) => v === true),
        take(1)
      ).subscribe((value) => {
        if (value) {
          if (timeout) {
            clearTimeout(timeout);
          }

          resolve();
        }
      });

      if (this.hasExposedSignal.get() === true) {
        resolve();
      }

      timeout = setTimeout(
        () => {
          console.error('Timeout waiting for RxDB exposed signal');
          reject(new Error('Timeout waiting for RxDB exposed signal'));
        },
        isDevEnv() ? 120_000 : 60_000
      );
    });
  }

  public async addCollections(db?: MemoryDatabase) {
    const database = db || this.db;
    if (!database) {
      throw new Error('Database not found');
    }
    await database.addCollections(MEMORY_DB_COLLECTIONS_MAP);
  }

  public async destroy(db?: MemoryDatabase, _storage?: RxStorage<any, any>) {
    this.remoteSubscription?.unsubscribe();
    this.clientSubscription?.unsubscribe();
    const database = db || this.db;
    const storage = _storage || this.storage;
    if (!database || !storage) {
      throw new Error('Database not found');
    }
    await database.remove();
    await removeRxDatabase(database.name, storage);
  }
}

export function useMemoryDatabase() {
  const workspace = useWorkspace<AppWorkspace>();
  if (!workspace.getMeta()?.memoryDatabase) {
    throw new Error('App database not ready');
  }

  return workspace.getMeta().memoryDatabase;
}

export function getMemoryDatabase(workspace: AppWorkspace) {
  const db = workspace.getMeta().memoryDatabase;
  if (!db) {
    throw new Error('App database not ready');
  }
  return db;
}

export const destroyAndClearDatabase = async (db: MemoryDatabase, storage: RxStorage<any, any>) => {
  await db.remove();
  await removeRxDatabase(db.name, storage);
};
