import type { FC, PropsWithChildren } from 'react';
import { DragItem } from '../drag-item/drag-item';
import { Box, IconButton, Text } from '@oms/ui-design-system';
import { TrashIcon } from '@radix-ui/react-icons';
import { DragItemTypes } from '../types';
import type { DragNDrop, OnDelete } from '../types';
import { onPreventDefault } from '../utils/on.prevent.default';

export type ActionButtonGroupProps = OnDelete & Omit<DragNDrop, 'type'>;

export const ActionButtonGroup: FC<PropsWithChildren<ActionButtonGroupProps>> = ({
  onDelete,
  onDrag,
  onDrop,
  children
}) => {
  return (
    <DragItem
      container={{ sx: { backgroundColor: 'text.minor' } }}
      contentContainer={{ sx: { backgroundColor: 'layout.level1' } }}
      iconContainer={{ sx: { backgroundColor: 'layout.level2' } }}
      onDrag={onDrag}
      onDrop={onDrop}
      type={DragItemTypes.ActionGroup}
    >
      <Box
        sx={{
          display: 'inline-flex',
          flexDirection: 'row',
          flexGrow: 1,
          justifyContent: 'space-between',
          width: 'full',
          padding: 1,
          backgroundColor: 'layout.level1'
        }}
      >
        <Text
          as={'div'}
          type="mediumB"
          sx={{ display: 'flex', alignSelf: 'center', textAlign: 'center', padding: 4 }}
        >
          Action Group
        </Text>
        <Box
          sx={{
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            alignSelf: 'center',
            display: 'flex',
            paddingX: 4,
            paddingY: 3
          }}
        >
          <IconButton
            aria-label="drag-action-button-group"
            isRound={true}
            sx={{ color: 'icons.primary', backgroundColor: 'text.minor' }}
            variant="ghost"
            size={'sm'}
            onClick={(e) => onPreventDefault(e, onDelete)}
            icon={<TrashIcon />}
          />
        </Box>
      </Box>
      {children}
    </DragItem>
  );
};
