import { Box } from '@oms/ui-design-system';
import type { FC } from 'react';
import { DragItemTypes } from '../types';
import type { DragItemDropResult, DropItemMeta, DragNDrop } from '../types';
import { useDrop } from 'react-dnd';

export const DropZone: FC<Omit<DragNDrop, 'type' | 'onDrag'>> = ({ onDrop, canDrop }) => {
  const [dropStyles, drop] = useDrop<DropItemMeta, DragItemDropResult, DropItemMeta>(
    () => ({
      accept: [DragItemTypes.Action, DragItemTypes.ActionGroup, DragItemTypes.Separator],
      drop(_item, monitor) {
        if (monitor.isOver()) {
          onDrop();
        }
        return {};
      },
      canDrop: () => {
        return canDrop ? canDrop() : true;
      },
      collect(monitor) {
        const overDrop = monitor.canDrop() && monitor.isOver();
        return {
          borderStyle: 'solid',
          border: overDrop ? undefined : 'none',
          borderColor: overDrop ? 'text.active' : 'inherit',
          borderWidth: overDrop ? '1px' : 'none'
        };
      }
    }),
    [onDrop]
  );
  return <Box ref={drop} sx={{ ...dropStyles }} style={{ height: '3px' }}></Box>;
};
