import { useVGrid, VGrid } from '@oms/frontend-vgrid';
import { buildInstrumentsGridColmuns } from './instuments-grid.columns';
import {
  TableServerJoinedInstrument,
  TsInstrumentsWithFilterDocument,
  TsInstrumentsWithFilterSubscription
} from '@oms/generated/frontend';
import { withTableServer } from '@app/data-access/services/system/table-server/with.table-server.component';

type InstrumentSubscriptionKeys = 'instrument';

type InstrumentSubscriptionMap = {
  instrument: {
    datasource: TableServerJoinedInstrument;
    subscription: TsInstrumentsWithFilterSubscription;
  };
};

export const InstrumentsGridWidgetComponent = withTableServer<
  InstrumentSubscriptionKeys,
  InstrumentSubscriptionMap
>(
  ({ datasources }) => {
    const gridProps = useVGrid(
      'instruments',
      (builder) =>
        builder
          .tableServerColumnLibrary(buildInstrumentsGridColmuns())
          .rowSelection((c) => c.multiple())
          .datasource((d) =>
            d
              .source(datasources.instrument.asServerSideRowModel())
              .rowId((r) => r.data.id)
              .cacheBlockSize(100)
          )

          .sideBar()
          .reactiveCustomComponents(),
      [buildInstrumentsGridColmuns, datasources]
    );

    return <VGrid {...gridProps} />;
  },
  {
    instrument: {
      query: TsInstrumentsWithFilterDocument,
      getData: (r) => r.tsInstrumentsWithFilter
    }
  }
);

export default InstrumentsGridWidgetComponent;
