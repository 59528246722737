import { asArray } from '@oms/ui-util';
import type { Counted, Labeled, Sourced } from '@oms/ui-util';
import clsx from 'clsx';
import type { CSSProperties, FC } from 'react';
import { HStack } from '../../layout/stack/stack';
import { Box } from '../../system/components/box/box';
import { Avatar } from './avatar.component';
import { AvatarBadge } from './avatar-badge';
import * as styles from './css/label-with-avatar.css';

export const CLASS_NAMES = {
  labelWithAvatarWrapper: 'label-with-avatar-wrapper',
  avatarForLabel: 'avatar-for-label',
  avatarBadgeForLabel: 'avatar-badge-for-label',
  labelText: 'label-text'
};

export { styles as labelWithAvatarStyles, CLASS_NAMES as labelWithAvatarClassNames };

export type LabelWithAvatarProps = Labeled &
  Partial<
    Sourced &
      Counted & {
        isActive: boolean;
        /** Wrapper styles */
        style: CSSProperties;
        /** Override default style classes */
        classNames: string[];
        avatarClassNames: string[];
        avatarBadgeClassNames: string[];
        labelTextClassNames: string[];
        isLabelVisible?: boolean;
      }
  >;

export const LabelWithAvatar: FC<LabelWithAvatarProps> = ({
  label,
  src,
  count,
  isActive,
  style,
  classNames = asArray(styles.labelWithAvatarWrapperRecipe({ purpose: 'single' })),
  avatarClassNames = asArray(styles.avatarForLabelRecipe({ size: 'normal' })),
  avatarBadgeClassNames,
  labelTextClassNames,
  isLabelVisible = true
}) => {
  const className = clsx(CLASS_NAMES.labelWithAvatarWrapper, ...classNames);
  const rowClassName = clsx(styles.avatarLabelRowRecipe());
  const avatarClassName = clsx(CLASS_NAMES.avatarForLabel, ...avatarClassNames);
  const avatarBadgeClassName = clsx(
    CLASS_NAMES.avatarBadgeForLabel,
    ...(avatarBadgeClassNames ??
      asArray(styles.avatarBadgeForLabelRecipe({ size: 'large', status: isActive ? 'active' : 'inactive' })))
  );
  const labelTextClassName = clsx(
    CLASS_NAMES.labelText,
    ...(labelTextClassNames ?? styles.labelTextRecipe())
  );
  return (
    <Box className={className} style={style}>
      <HStack className={rowClassName}>
        <Avatar
          palette="primary"
          size="md"
          name={label}
          count={count}
          src={src}
          isFullName={false}
          className={avatarClassName}
        >
          {isActive ? <AvatarBadge position="bottom-right" className={avatarBadgeClassName} /> : null}
        </Avatar>
        {isLabelVisible && <Box className={labelTextClassName}>{label}</Box>}
      </HStack>
    </Box>
  );
};

export default LabelWithAvatar;
