import pino, { LevelWithSilent, LoggerOptions, WriteFn, levels } from 'pino';

const levelStyles: Record<string, string> = {
  debug: 'color: gray;',
  trace: 'color: dimgray;',
  info: 'color: dodgerblue;',
  warn: 'color: orange;',
  error: 'color: red;',
  fatal: 'color: red; font-weight: bold;',
  default: 'color: lightblue;'
};

const writeFn: WriteFn = (log: Record<string, any>) => {
  const rawLevel = log['level'] as unknown;
  const level: LevelWithSilent =
    rawLevel && typeof rawLevel === 'string'
      ? (rawLevel.toLowerCase() as LevelWithSilent)
      : rawLevel && typeof rawLevel === 'number'
        ? (levels.labels[rawLevel]?.toLowerCase() as LevelWithSilent) || 'info'
        : 'info';

  let name = 'Unknown';
  let msg = 'No message';
  if (typeof log['name'] === 'string') {
    name = log['name'];
  }

  if (typeof log['msg'] === 'string') {
    msg = log['msg'];
  }

  const style = levelStyles[level] || levelStyles['default'];

  if (level !== 'silent') {
    // eslint-disable-next-line no-restricted-syntax
    console[level === 'fatal' ? 'error' : level](
      `%c${level.toUpperCase()} %c${name} %c${msg}`,
      style, // log level
      'color: darkcyan;', // name
      'color: inherit;', // message
      log // log data
    );
  }
};

export const DEFAULT_LOGGER_OPTS: LoggerOptions = {
  level: 'error',
  browser: {
    asObject: true,
    serialize: true,
    write: (log) => writeFn(log)
  }
};

export const createPinoLogger = (opts: LoggerOptions = DEFAULT_LOGGER_OPTS) => {
  return pino(opts, {
    // this is for tests... pino does some magic in figuring out whether its in a node process or not, and it doesnt use the proper console. methods in node
    write: (msgStr: string) => {
      writeFn(JSON.parse(msgStr) as Record<string, any>);
    }
  });
};
