import { BroadcastSubject } from '@oms/rx-broadcast';
import type { AnyRecord } from '../../common/type.helpers';
import type { FormBuilderChangeFnResult } from './form-builder.class';
import type { FeedbackWrapper } from '../../graphql/graphql-envelope';

/**
 * Constants for form renderer events
 */
export const FORM_RENDERER_EVENT_TYPE = {
  SUBMISSION_RESULT: 'SUBMISSION_RESULT',
  // TODO: Implement these two things
  SET_FEEDBACK: 'SET_FEEDBACK',
  SET_FIELD_VALUES: 'SET_FIELD_VALUES',
  RESPONSE_INITIAL_VALUES: 'RESPONSE_INITIAL_VALUES',
  RESET: 'RESET',
  CLOSE: 'CLOSE'
} as const;

/**
 * Form events from renderer to form renderer change
 */
export type FormRendererEventMeta = {
  formId: string;
};

export type FormRendererEventSetFeedback = {
  type: typeof FORM_RENDERER_EVENT_TYPE.SET_FEEDBACK;
  payload: {
    feedback: Array<FeedbackWrapper>;
  };
};

export type FormRendererEventSetFieldValues<TFormFieldValues extends AnyRecord = AnyRecord> = {
  type: typeof FORM_RENDERER_EVENT_TYPE.SET_FIELD_VALUES;
  payload: {
    fieldValues: {
      [key in keyof TFormFieldValues]?: TFormFieldValues[key];
    };
  };
};

export type FormRendererEventSubmissionResult<
  TOutputContract extends AnyRecord,
  TFormFieldValues extends AnyRecord = AnyRecord
> = {
  type: typeof FORM_RENDERER_EVENT_TYPE.SUBMISSION_RESULT;
  payload: {
    isSuccessful: boolean;
    result: FormBuilderChangeFnResult<TFormFieldValues>;
    formValues: Partial<TFormFieldValues>;
    output: TOutputContract;
  };
};

export type FormRendererEventResponseInitialValues<TFormFieldValues extends AnyRecord = AnyRecord> = {
  type: typeof FORM_RENDERER_EVENT_TYPE.RESPONSE_INITIAL_VALUES;
  payload: {
    isSuccessful: boolean;
    errorMessage?: string;
    formValues?: Partial<TFormFieldValues>;
  };
};

export type FormRendererEventReset<TFormFieldValues extends AnyRecord = AnyRecord> = {
  type: typeof FORM_RENDERER_EVENT_TYPE.RESET;
  payload?: {
    formValues?: Partial<TFormFieldValues>;
  };
};

export type FormRendererEventClose = {
  type: typeof FORM_RENDERER_EVENT_TYPE.CLOSE;
};

export type FormRendererEventBase<
  TOutputContract extends AnyRecord,
  TFormFieldValues extends AnyRecord = AnyRecord
> =
  | FormRendererEventSubmissionResult<TOutputContract, TFormFieldValues>
  | FormRendererEventSetFeedback
  | FormRendererEventSetFieldValues<TFormFieldValues>
  | FormRendererEventResponseInitialValues<TFormFieldValues>
  | FormRendererEventReset
  | FormRendererEventClose;

export type FormRendererEvent<
  TOutputContract extends AnyRecord,
  TFormFieldValues extends AnyRecord = AnyRecord
> = FormRendererEventBase<TOutputContract, TFormFieldValues> & {
  meta: FormRendererEventMeta;
};

/**
 * Broadcast subject for form renderer events
 */
export const formRendererEvent$ = new BroadcastSubject<FormRendererEvent<AnyRecord, AnyRecord>>(
  'form-renderer-events'
);

export const getFormRendererEvent$ = <
  TOutputContract extends AnyRecord,
  TFormFieldValues extends AnyRecord = AnyRecord
>() => formRendererEvent$ as BroadcastSubject<FormRendererEvent<TOutputContract, TFormFieldValues>>;
