import * as React from 'react';
import clsx from 'clsx';
import * as styles from './css/form-control.css';
import { InputVariants } from '../input/css/input.css';
import RequiredFieldIndicatorStyle from './required-field-indicator-style';
import { Stack, StackProps } from '../../layout/stack/stack';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';
import { DefaultProps } from '../../system/utils/types';
import { useId } from '../../system/hooks/use-id';

export type UseFormControlProps = {
  /**
   * If `true`, the form control will be disabled. This has 2 side effects:
   * - The `FormLabel` will have `data-disabled` attribute
   * - The form element (e.g, Input) will be disabled
   */
  isDisabled?: boolean;
  /**
   * If `true`, the form control will be required. This has 2 side effects:
   * - The `FormLabel` will show a required indicator
   * - The form element (e.g, Input) will have `aria-required` set to `true`
   */
  isRequired?: boolean;
  /**
   * If `true`, the form control will be invalid. This has 2 side effects:
   * - The `FormLabel` and `FormErrorIcon` will have `data-invalid` set to `true`
   * - The form element (e.g, Input) will have `aria-invalid` set to `true`
   */
  isInvalid?: boolean;
  /**
   * If `true`, the form control will be readonly
   */
  isReadOnly?: boolean;
  /**
   * The ID for this form control
   */
  id?: string;
  /**
   * If `true`, title & error messages will only ever be on one line
   */
  clipFormLabels?: boolean;
  /**
   * An enum specifying what style of indicator should be used for required fields.
   * - `default`: Always use whatever is set as the system default.
   * - `asterisk`: Always place an asterisk to the right of the field label to specify it's required.
   * - `none`: Opt-out of any required indicator.
   */
  requiredFieldIndicatorStyle?: RequiredFieldIndicatorStyle;
} & InputVariants;

interface UseFormControlData extends UseFormControlProps {
  labelId?: string;
  errorId?: string;
  helpTextId?: string;
}

interface IFormControlProps extends DefaultProps, UseFormControlProps {
  children?: React.ReactNode;
}

export type FormControlProps = {} & IFormControlProps & {
    direction?: StackProps['direction'];
    spacing?: StackProps['spacing'];
    sx?: StackProps['sx'];
    style?: StackProps['style'];
    hidden?: StackProps['hidden'];
    autoFocus?: boolean;
  };

interface FormControlContext extends UseFormControlProps {}

export const useFormControl = (props: UseFormControlProps): UseFormControlData => {
  const context = useFormControlContext();
  if (!context) {
    return props;
  }

  const keys = Object.keys(context) as Array<keyof UseFormControlProps>;

  return keys.reduce((acc, prop) => {
    /** Giving precedence to `props` over `context` */
    acc[prop] = props[prop] as never;

    if (props[prop] == null) {
      acc[prop] = context[prop] as never;
    }

    return acc;
  }, {} as Partial<UseFormControlData>) as UseFormControlData;
};

const FormControlContext = React.createContext<FormControlContext | undefined>(undefined);

const useFormControlContext = () => React.useContext(FormControlContext);

export const FormControl = polymorphicComponent<'div', FormControlProps>((props, ref) => {
  const {
    children,
    className,
    isRequired,
    isDisabled,
    isInvalid,
    isReadOnly,
    size = 'sm',
    direction = 'vertical',
    spacing = 1.5,
    variant = 'default',
    id: idProp,
    clipFormLabels,
    requiredFieldIndicatorStyle,
    ...rest
  } = props;

  const id = useId(idProp, `field`);
  const classes = clsx(styles.formControl, className, variant === 'feature' && 'feature-field');

  const labelId = `${id}-label`;
  const errorId = `${id}-error`;
  const helpTextId = `${id}-helptext`;

  const context = {
    isRequired,
    isDisabled,
    isInvalid,
    isReadOnly,
    id,
    labelId,
    errorId,
    helpTextId,
    size,
    variant,
    clipFormLabels,
    requiredFieldIndicatorStyle,
    spacing
  };

  return (
    <FormControlContext.Provider value={context}>
      <Stack role="group" direction={direction} spacing={spacing} ref={ref} className={classes} {...rest}>
        {children}
      </Stack>
    </FormControlContext.Provider>
  );
});

if (__DEV__) {
  FormControl.displayName = 'FormControl';
}
