import type { AnyRecord, Optional } from '@oms/ui-util';
import type { FormClassNameResolver } from '../contracts';
import { getOrderSideClassName } from '../../common/util/order-side-display/order-side-display.util';

type FormClassNameResolverFn<TValue = unknown, TPayload extends AnyRecord = AnyRecord> = (
  value: TValue,
  payload: TPayload
) => Optional<string>;

const FORM_CLASS_NAME_RESOLVER_MAPPER: Record<FormClassNameResolver, FormClassNameResolverFn> = {
  sideTypeStyling: (value, _payload) => {
    if (typeof value !== 'string') {
      return undefined;
    }
    return getOrderSideClassName(value, { isInactive: false });
  }
};

// Type mappings for resolver generics
type ResolverTypeMap = {
  sideTypeStyling: {
    value: string;
    payload: AnyRecord;
  };
  // Add additional resolvers here with their specific types
};

/**
 * Returns a strongly typed resolver function for the given resolver key
 * @param resolverKey The key of the resolver to get
 * @returns The resolver function with proper generic types
 */
export function getFormClassNameResolver<K extends FormClassNameResolver>(
  resolverKey: K
): FormClassNameResolverFn<
  K extends keyof ResolverTypeMap ? ResolverTypeMap[K]['value'] : unknown,
  K extends keyof ResolverTypeMap ? ResolverTypeMap[K]['payload'] : AnyRecord
> {
  return FORM_CLASS_NAME_RESOLVER_MAPPER[resolverKey] as FormClassNameResolverFn<
    K extends keyof ResolverTypeMap ? ResolverTypeMap[K]['value'] : unknown,
    K extends keyof ResolverTypeMap ? ResolverTypeMap[K]['payload'] : AnyRecord
  >;
}
