import { format, parseJSON, isValid } from 'date-fns';
import { Optional } from '../types/shared-util-types';

export type AnyDateInput = string | number | Date;

/**
 * Conventional ISO YYYY-MM-DD format mapped to date-fn's
 *  nonstandard format string syntax
 */
const ISO_DATE_FORMAT = 'yyyy-MM-dd';
const DATE_FORMAT = 'MMM d yyyy';
const DATE_TIME_FORMAT = 'MMM do, yyyy, h:mm a';

/**
 * @deprecated Use `UIStyleGuide.dateAndTime` for UI usage aligned to Figma.
 * Per [Figma](https://www.figma.com/file/nKgorxE8wAdpd7pBreGMVE/Formats-and-Selections?t=5CIuiuXhSUx2tfD0-0)
 */
const DATE_TIME_FORMAT_UI = 'MMM dd yyyy, h:mma';

const SHORT_TIME_FORMAT = 'h:mma a';
/**
 * 24hr time display- e.g., user's 6:01pm local time should display "18:01"
 */
const TIME_FORMAT_24_HOURS = 'HH:mm';
/**
 * 12h display with am/pm: user's 6:01pm local time should display "6:01PM"
 */
const TIME_FORMAT_12_HOURS = 'h:mma';

type UIDateTimeType = 'date' | 'time' | 'dateAndTime';

/**
 * Per [Figma Style Guide](https://www.figma.com/file/IOe34hekMhb1WnU3irAl8B/Style-guide?t=wIyNAoy9xf6qT7g5-1)
 * Keep these formats aligned to Figma.
 */
const UIStyleGuide: Record<UIDateTimeType, string> = {
  date: 'MMM dd yyyy',
  time: 'HH:mm:ss',
  dateAndTime: 'MMM dd yyyy, HH:mm:ss'
};

/**
 * Extract a YYYY-MM-DD string from a Date object
 *
 * @param dt Date object
 */
export const extractDate = (dt: Date): string => {
  if (isValid(dt)) {
    return format(dt, ISO_DATE_FORMAT);
  } else {
    return '';
  }
};

export const isValidDate = (dateInput: AnyDateInput) => {
  if (!dateInput) return false;
  const parsed = parseJSON(dateInput);
  return isValid(parsed);
};

const dateOnlyRegex = /^\d{4}-\d{2}-\d{2}$/;

export const convertAnyDateInput = (
  dateInput: Optional<AnyDateInput>,
  timeFormat = TIME_FORMAT_24_HOURS
): string => {
  if (!dateInput) return '';

  try {
    // When passed YYYY-MM-DD and a date only format, we add 'T00:00:00Z' since parseJSON below
    // doesn't accept date only string as valid since it expects to have a time part as well.
    const isDateOnly =
      typeof dateInput === 'string' && !timeFormat.includes(':') && dateOnlyRegex.test(dateInput.toString());
    const parsed = parseJSON(!isDateOnly ? dateInput : dateInput + 'T00:00:00Z');

    if (isValid(parsed)) {
      return format(parsed, timeFormat);
    } else {
      console.warn(`Unable to construct valid date object from ${dateInput}`);
      return '';
    }
  } catch {
    console.warn(`Unable to parse valid date from ${dateInput}`);
    return '';
  }
};

/**
 * ISO Date String to Date Format
 */
export const date = (dateInput: Optional<AnyDateInput>): string => {
  return convertAnyDateInput(dateInput, DATE_FORMAT);
};

/**
 * ISO Date String to 24 Hour Time Format
 */
export const time = (dateInput: Optional<AnyDateInput>): string => {
  return convertAnyDateInput(dateInput, TIME_FORMAT_24_HOURS);
};

/**
 * ISO Date String to 12 Hour Time Format with AM/PM
 */
export const time12 = (dateInput: Optional<AnyDateInput>): string => {
  return convertAnyDateInput(dateInput, TIME_FORMAT_12_HOURS);
};

/**
 * ISO Date String to Short Time Format ({short hours}:{minutes}{am/pm})
 */
export const isoDateToShortTime = (dateInput: Optional<AnyDateInput>): string => {
  return convertAnyDateInput(dateInput, SHORT_TIME_FORMAT);
};

export const dateTime = (dateInput: Optional<AnyDateInput>, dateTimeFormat: string = DATE_TIME_FORMAT) => {
  return convertAnyDateInput(dateInput, dateTimeFormat);
};

/** Formatted for UI/UX standard formats per [Figma](https://www.figma.com/file/nKgorxE8wAdpd7pBreGMVE/Formats-and-Selections?t=5CIuiuXhSUx2tfD0-0) */

/**
 * Formatted for UI/UX standard formats per [Figma Style Guide](https://www.figma.com/file/IOe34hekMhb1WnU3irAl8B/Style-guide?t=wIyNAoy9xf6qT7g5-1)
 *
 * > Note: Specify type as `'deprecated'` to use the deprecate style from [Figma](https://www.figma.com/file/nKgorxE8wAdpd7pBreGMVE/Formats-and-Selections?t=5CIuiuXhSUx2tfD0-0)
 *
 * @param dateInput - Any string, number or `Date` date input
 * @param type - Defaults to date and time but can also specify date or time only
 * @returns A formatted date string following UX/Figma UI guidelines
 */
export const dateTimeUi = (dateInput: Optional<AnyDateInput>, type?: UIDateTimeType | 'deprecated') => {
  switch (type ?? 'dateAndTime') {
    case 'deprecated':
      return convertAnyDateInput(dateInput, DATE_TIME_FORMAT_UI);
    default:
      return convertAnyDateInput(dateInput, UIStyleGuide[type as UIDateTimeType]);
  }
};

/**
 * Formatted for UI/UX standard formats per [Figma Style Guide](https://www.figma.com/file/IOe34hekMhb1WnU3irAl8B/Style-guide?t=wIyNAoy9xf6qT7g5-1)
 */
export const uiDateTimeFormat: Record<UIDateTimeType, (dateInput?: AnyDateInput) => string> = {
  date: (dateInput): string => dateTimeUi(dateInput, 'date'),
  time: (dateInput): string => dateTimeUi(dateInput, 'time'),
  dateAndTime: (dateInput): string => dateTimeUi(dateInput, 'dateAndTime')
};
