import { Box } from '../../system/components/box/box';
import type { BoxProps } from '../../system/components/box/box';
import clsx from 'clsx';
import * as styles from './css/tag.css';
import type { TagVariants } from './css/tag.css';
import { Icon } from '../icon/icon';
import { Cross1Icon } from '@radix-ui/react-icons';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';

export type TagProps = {
  onDelete?: () => void;
  iconStyle?: React.CSSProperties;
} & TagVariants &
  BoxProps;

export const Tag = polymorphicComponent<'span', TagProps>((props, ref) => {
  const {
    className,
    as = 'span',
    size = 'sm',
    sx = {},
    onDelete,
    children,
    iconStyle = {},
    variant = 'default',
    ...rest
  } = props;
  return (
    <Box
      as={as}
      ref={ref}
      className={clsx(styles.tagRecipe({ size, variant }), className)}
      sx={{
        ...sx
      }}
      {...rest}
    >
      {children}
      {onDelete && (
        <Icon
          label="Close"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDelete();
          }}
          className={clsx(styles.tagClose, styles.tagCloseVariants[size])}
          style={iconStyle}
          as={Cross1Icon}
        />
      )}
    </Box>
  );
});

if (__DEV__) {
  Tag.displayName = 'Tag';
}
