import type { ISetFilterParams } from '@ag-grid-community/core';
import type { AnyRecord, Named, OneOrMore } from '@oms/ui-util';
import { asArray } from '@oms/ui-util';
import type { ColumnBuilder } from '@oms/frontend-vgrid';
import type ServerSideRowDataHelper from '../../helpers/server-side-row-data-helper.abstract.class';
import type { NestedTreeData } from '../../types/tree-data.types';
import type { CreateFilterOptions } from '../internal/types';

// 🔡 Grid filter value utils -------------------------------------------------------------- /
// Utils to create the values for grid filters (the actual filter UI)

/**
 * Creates values for an ag-Grid set filter formatted as tree data
 *
 * @param data - One or more nested tree data objects
 * @param options.getValue - A callback function to get the value to display
 * @returns An array of string arrays to pass to ag-Grid as filter values
 */
export const createTreeSetFilterValues = <TData extends AnyRecord>(
  data: OneOrMore<NestedTreeData<TData>> = [],
  options?: CreateFilterOptions<TData>
): string[][] => {
  const paths: string[][] = [];
  const processRecursive = (data: OneOrMore<NestedTreeData<TData>> = [], parents: string[] = []) => {
    asArray(data).forEach((item) => {
      const { children } = item;
      const value = options?.getValue?.(item) ?? (item as Partial<Named>).name ?? '';
      const path = [...parents, value];
      if (children?.length) {
        processRecursive(children, path);
      } else {
        paths.push(path);
      }
    });
  };
  processRecursive(data);
  return paths;
};

/**
 * Creates values for an ag-Grid set filter
 *
 * @param data - One or more nested tree data objects
 * @param options.getValue - A callback function to get the value to display
 * @returns An array of string arrays to pass to ag-Grid as filter values
 */
export const createSetFilterValues = <TData extends AnyRecord>(
  data: OneOrMore<NestedTreeData<TData>> = [],
  options?: CreateFilterOptions<TData>
): string[] => {
  const filterValues = new Set<string>();
  const processRecursive = (data: OneOrMore<NestedTreeData<TData>> = []) => {
    asArray(data).forEach((item) => {
      const { children } = item;
      const value = options?.getValue?.(item) ?? (item as Partial<Named>).name ?? '';
      filterValues.add(value);
      if (children?.length) {
        processRecursive(children);
      }
    });
  };
  processRecursive(data);
  return [...filterValues];
};

/**
 * @param columnBuilder - Pass column builder
 * @param dataHelper - Pass data helper class instance
 * @param options.getValue - A callback function to get the value to display
 * @returns The column builder with filter added
 */
export const buildSetFilter = <TData extends AnyRecord, RowData extends AnyRecord>(
  columnBuilder: ColumnBuilder<RowData>,
  dataHelper?: ServerSideRowDataHelper<TData>,
  options?: CreateFilterOptions<TData>
): ColumnBuilder<RowData> => {
  if (!dataHelper) return columnBuilder.filter('agSetColumnFilter');
  const filterParams: ISetFilterParams<RowData, string> = {
    values: ({ success }) => {
      const data = (() => {
        if (!dataHelper) return [];
        if (dataHelper.filteredData.length) return dataHelper.filteredData;
        return dataHelper.data;
      })();
      success(createSetFilterValues(data, options));
    }
  };
  return columnBuilder.filter('agSetColumnFilter').filterParams(filterParams);
};
