import type { AdvancedSelectQueryFn, AdvancedSelectQueryPayload } from '@oms/frontend-foundation';
import type { ComboBoxItem } from '@oms/ui-design-system';
import { map } from 'rxjs';
import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import { ExchangesDocument } from '@oms/generated/frontend';
import type { ExchangesQuery } from '@oms/generated/frontend';

export const watchAllExchangesQuery: AdvancedSelectQueryFn = (container) => {
  const apolloClient = container.resolve(RxApolloClient);
  return {
    type: 'watchAll',
    query: () => {
      const observable = apolloClient
        .rxWatchQuery<ExchangesQuery>({
          query: ExchangesDocument,
          fetchPolicy: 'cache-and-network'
        })
        .pipe(
          map((data) => {
            const comboBoxItems = data?.data?.exchanges?.nodes.map((exchange) => {
              const comboItem: ComboBoxItem = {
                type: 'item',
                id: exchange.id || '',
                label: exchange.id || '',
                sublabel: exchange.longName || '',
                value: exchange.id || ''
              };
              return comboItem;
            });
            const shape: AdvancedSelectQueryPayload = {
              isFetching: false,
              results: comboBoxItems
            };
            return shape;
          })
        );
      return observable;
    }
  };
};

export default watchAllExchangesQuery;
