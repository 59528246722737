import { useMemo } from 'react';
import { UUID } from '@oms/ui-util';

/**
 * Optionally generate a unique id
 *
 * @param id - Optional id
 * @returns A unqiue memoized id
 */
export function useUniqueId(id?: string) {
  return useMemo(() => id || UUID(), [id]);
}
