import type { DepartmentTypes, DeskTypes } from '@oms/generated/frontend';
import { cleanMaybe, compactMap } from '@oms/ui-util';
import type { Optional, Valued } from '@oms/ui-util';
import { BaseUserDefaultsObject } from './types';
import type {
  UserAggregationUnit,
  UserOrderTag,
  UserTradingEntity,
  UserCountry,
  ExtendedUserDefaultsObject,
  UserAccount
} from './types';

export class UserDefaults
  implements BaseUserDefaultsObject, ExtendedUserDefaultsObject, Valued<BaseUserDefaultsObject>
{
  readonly #userDefaults: BaseUserDefaultsObject;

  // 🏗️ Constructor ------------------------------------------------------- /

  public constructor(userDefaults: BaseUserDefaultsObject) {
    this.#userDefaults = userDefaults;
  }

  // Conversion ------------------------------------------------------- /

  public get value(): BaseUserDefaultsObject {
    return this.#userDefaults;
  }

  public toObject(): BaseUserDefaultsObject {
    return this.value;
  }

  public toExtendedObject(): ExtendedUserDefaultsObject {
    return {
      __typename: this.__typename,
      departmentType: this.departmentType,
      receivingDeskType: this.receivingDeskType,
      aggregationUnit: this.aggregationUnit,
      orderTags: this.orderTags,
      tradingEntity: this.tradingEntity,
      country: this.country,
      firmAccount: this.firmAccount,
      intermediaryAccount: this.intermediaryAccount
    };
  }

  // 😐 Base ------------------------------------------------------- /

  public get __typename() {
    return cleanMaybe(this.#userDefaults.__typename, 'UserDefaults');
  }

  public get departmentType(): DepartmentTypes {
    return this.#userDefaults.departmentType;
  }

  public get receivingDeskType(): DeskTypes {
    return this.#userDefaults.receivingDeskType;
  }

  public get aggregationUnit(): Optional<UserAggregationUnit> {
    return cleanMaybe(this.#userDefaults.aggregationUnit);
  }

  public get orderTags(): UserOrderTag[] {
    return compactMap(cleanMaybe(this.#userDefaults.orderTags, []), (tag) => tag);
  }

  public get tradingEntity(): UserTradingEntity {
    return this.#userDefaults.tradingEntity;
  }

  public get country(): UserCountry {
    return this.#userDefaults.country;
  }

  public get firmAccount(): Optional<UserAccount> {
    return cleanMaybe(this.#userDefaults.firmAccount);
  }

  public get intermediaryAccount(): Optional<UserAccount> {
    return cleanMaybe(this.#userDefaults.intermediaryAccount);
  }
}

export default UserDefaults;
