import { BehaviorSubject, combineLatest, map, tap, debounceTime, startWith } from 'rxjs';
import type { Observable, Subscription } from 'rxjs';
import type { Optional, AnyRecord, Deinitializable } from '@oms/ui-util';
import { Logger } from '@oms/ui-util';
import type { GridSelectionEvent } from '@app/data-access/memory/grid.events';
import type { GridTabSelectionEvent, TabId, TabIdentifier } from './grid-tab.types';
import { INITIAL_TAB } from './grid-tab.constants';
import { compareSelectedRows, isMatch, selectedRowsFrom, tabIdFrom } from './grid-tab.util';

export class TabSelectionManager<TData extends AnyRecord, TCustomPayload extends AnyRecord = AnyRecord>
  implements Deinitializable
{
  protected observable$: Observable<GridTabSelectionEvent<TData, TCustomPayload>>;

  protected subscriptions: Subscription[] = [];

  protected tabLookup = new Map<TabId, BehaviorSubject<TData[]>>();

  protected selectedTabIdSubject$ = new BehaviorSubject<Optional<string>>(undefined);
  protected selectedRowsSubject$ = new BehaviorSubject<TData[]>([]);

  protected logger: Logger;

  // 🏗️ Constructor -------------------------------------------------------- /

  constructor(gridTabSelection$: Observable<GridTabSelectionEvent<TData, TCustomPayload>>) {
    this.observable$ = this.initObservable$(gridTabSelection$);
    this.logger = Logger.named('TabSelectionManager');
  }

  public static fromCombinedGridTabSelectionEvent<
    TData extends AnyRecord,
    TCustomPayload extends AnyRecord = AnyRecord
  >(gridTabSelection$: Observable<GridTabSelectionEvent<TData, TCustomPayload>>) {
    return new TabSelectionManager<TData, TCustomPayload>(gridTabSelection$);
  }

  public static fromSeparateTabAndGridSelectionEvents<
    TData extends AnyRecord,
    TCustomPayload extends AnyRecord = AnyRecord
  >(
    gridSelection$: Observable<GridSelectionEvent<TData, TCustomPayload>>,
    currentTabId$: Observable<Optional<TabId>>
  ) {
    const gridTabSelection$ = combineLatest([
      gridSelection$,
      currentTabId$.pipe(startWith(INITIAL_TAB))
    ]).pipe(map(([gridSelectionEvent, currentTabId]) => ({ ...gridSelectionEvent, currentTabId })));
    return new TabSelectionManager<TData, TCustomPayload>(gridTabSelection$);
  }

  // 📢 Public ----------------------------------------------------------- /

  public init(): () => void {
    this.subscriptions.push(this.observable$.subscribe((_) => {}));
    return this.deinit.bind(this);
  }

  public get selectedTabId$(): Observable<TabIdentifier> {
    return this.selectedTabIdSubject$.pipe(map((tabId) => tabId ?? INITIAL_TAB));
  }

  public get selectedTabId(): TabIdentifier {
    return this.selectedTabIdSubject$.getValue() ?? INITIAL_TAB;
  }

  public get selectedRows$(): Observable<TData[]> {
    return this.selectedRowsSubject$;
  }

  public get selectedRows(): TData[] {
    return this.selectedRowsSubject$.getValue();
  }

  public get selectedRow$(): Observable<Optional<TData>> {
    return this.selectedRowsSubject$.pipe(map((rows) => rows[0]));
  }

  public get selectedRow(): Optional<TData> {
    return this.selectedRowsSubject$.getValue()[0];
  }

  public deinit(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  // 🔒 Protected / Private -------------------------------------------------------- /

  protected initObservable$(
    observable$: Observable<GridTabSelectionEvent<TData, TCustomPayload>>
  ): Observable<GridTabSelectionEvent<TData, TCustomPayload>> {
    return observable$.pipe(
      debounceTime(10),
      tap((event) => {
        this.onTabEvent(event);
      })
    );
  }

  protected onTabEvent(event: GridTabSelectionEvent<TData, TCustomPayload>): TData[] {
    try {
      const tabIdentifier: TabIdentifier = event.currentTabId ?? INITIAL_TAB;
      const tabId: Optional<TabId> = tabIdentifier !== INITIAL_TAB ? tabIdentifier : tabIdFrom(event);
      if (isMatch(event, tabId)) {
        // Row selection event is from the current tab
        this.setTabLookup(event);
        const tabChanged = this.nextSelectedTabId(tabId);
        const selectedRows = selectedRowsFrom(event);
        this.nextSelectedRows(selectedRows, tabChanged);
        return selectedRows;
      } else {
        // Row selection doesn't match... first we can set the lookup values...
        if (tabId) this.setTabLookup(tabId);
        this.setTabLookup(event);
        const selectedRows = tabId ? (this.tabLookup.get(tabId)?.getValue() ?? []) : [];
        const tabChanged = this.nextSelectedTabId(tabId);
        if (tabChanged) {
          // We just changed tabs, and grid selection may not have fired yet,
          // so we need update the observable
          this.nextSelectedRows(selectedRows, true);
        }
        // Else, if the tab hasn't changed, so this is an event from a non-active tab
        // We already updated the value for that tab in the lookup,
        return selectedRows;
      }
    } catch (e) {
      this.logger.scope('onTabEvent').error(e);
      return [];
    }
  }
  protected nextSelectedTabId(tabId?: TabId): boolean {
    if (!this.hasTabIdChanged(tabId)) return false;
    this.selectedTabIdSubject$.next(tabId);
    return true;
  }

  protected nextSelectedRows(selectedRows: TData[], tabChanged?: boolean): boolean {
    if (!tabChanged && !this.haveRowsChanged(selectedRows)) return false;
    this.selectedRowsSubject$.next(selectedRows);
    return true;
  }

  protected hasTabIdChanged(tabId?: TabId): boolean {
    return tabId !== this.selectedTabIdSubject$.getValue();
  }

  protected haveRowsChanged(selectedRows: TData[]): boolean {
    return !compareSelectedRows(this.selectedRowsSubject$.getValue(), selectedRows);
  }

  protected setTabLookup(currentTabId: TabId, selectedRows?: TData[]): Optional<TData[]>;

  protected setTabLookup(gridSelectionEvent: GridSelectionEvent<TData>): Optional<TData[]>;

  // Implementation only --- /
  protected setTabLookup(
    tabIdOrEvent: TabId | GridSelectionEvent<TData>,
    selectedRows?: TData[]
  ): Optional<TData[]> {
    if (typeof tabIdOrEvent === 'object') {
      const gridSelectionEvent = tabIdOrEvent;
      const currentTabId = tabIdFrom(gridSelectionEvent);
      if (!currentTabId) return;
      return this.setTabLookup(currentTabId, selectedRowsFrom(gridSelectionEvent));
    }
    const tabId = tabIdOrEvent;
    const current = this.tabLookup.get(tabId);
    const currentValue = current?.getValue();
    const rowSubject = (() => {
      if (!current) return new BehaviorSubject(selectedRows ?? []);
      if (selectedRows && !compareSelectedRows(current.getValue(), selectedRows)) current.next(selectedRows);
      return current;
    })();
    this.tabLookup.set(tabId, rowSubject);
    return currentValue;
  }
}

export default TabSelectionManager;
