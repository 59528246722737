import type { VisibilityReason } from '@oms/generated/frontend';
import type { Maybe } from '@oms/ui-util';
import { visibilityReasonMapping } from './order-enums';

export const mapOrderVisibilityReason = (
  value?: Maybe<VisibilityReason> | string,
  defaultVal = value || ''
): string => {
  return value && visibilityReasonMapping[value as VisibilityReason]
    ? visibilityReasonMapping[value as VisibilityReason]
    : defaultVal;
};
