import { useMemo } from 'react';
import { useCurrentActorContext } from '@valstro/workspace-react';
import type { AppWindowActorSchema, AppWindowContext } from '@app/app-config/workspace.config';

export type WindowMeta = Exclude<AppWindowContext['meta'], undefined>;

/**
 * Get the window meta from the current actor context
 *
 * @returns The window meta
 */
export function useWindowMeta(): WindowMeta {
  const context = useCurrentActorContext<AppWindowActorSchema>();
  return useMemo(() => (context?.meta || {}) as WindowMeta, [context?.meta]);
}
