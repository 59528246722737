import React, { useEffect, useRef } from 'react';
import { RadioGroup, Radio as RadioComponent } from '@oms/ui-design-system';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import type { FieldProps, ICommonSelectField } from '../../types';
import { useFieldApi } from '../../helpers';
import { type FieldInputProps } from 'react-final-form';
import { type FORM_COMPONENT_TYPE } from '../../contracts';
import { type Validator } from '@data-driven-forms/react-form-renderer';

export interface IRadioField<TValidator = Validator>
  extends ICommonSelectField<typeof FORM_COMPONENT_TYPE.RADIO, string | number, TValidator> {
  isInline?: boolean;
}

export const Radio: React.FC<FieldProps<IRadioField>> = (props) => {
  const {
    meta,
    label,
    input,
    options,
    isReadOnly,
    isRequired,
    isDisabled: _isDisabled,
    requiredFieldIndicatorStyle,
    forceIsDisabled,
    isInvalid,
    defaultValue,
    style,
    ...rest
  } = useFieldApi<IRadioField>(props);

  const onChangeRef = useRef<FieldInputProps<any, HTMLElement>['onChange']>();
  onChangeRef.current = input.onChange;

  useEffect(() => {
    if (defaultValue) {
      onChangeRef.current?.(defaultValue);
    }
  }, [defaultValue]);

  // Sometimes validators override the disabled props. If forceIsDisabled is true, then we should always disable the field.
  const isDisabled = !!forceIsDisabled || !!_isDisabled;

  const extraProps = { isReadOnly, isRequired, isDisabled, isInvalid, requiredFieldIndicatorStyle };

  return (
    <FieldWrapper meta={meta} label={label} {...extraProps}>
      <RadioGroup {...rest} onChange={input.onChange} defaultValue={defaultValue} value={defaultValue}>
        {options?.map(({ value, label }) => (
          <RadioComponent key={value} value={value} style={style}>
            {label}
          </RadioComponent>
        ))}
      </RadioGroup>
    </FieldWrapper>
  );
};
