import type { DependencyContainer } from 'tsyringe';
import type { RxDocument } from 'rxdb-oms-app';
import { deepClone } from 'fast-json-patch';
import { cleanMaybe } from '@oms/ui-util';
import { AppWorkspace } from '@app/app-config/workspace.config';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import type { Validator } from '@data-driven-forms/react-form-renderer';
import type { AnyFieldDefinition, AnyRecord, Field, FormContract } from '@oms/frontend-foundation';
import type { UserPreferencesSchema } from '@app/data-access/offline/collections/user-preferences.collection';
import type {
  UserPreferencesCollection,
  UserPreferencesDocType
} from '@app/data-access/offline/collections/user-preferences.collection';
import type { OperationType, UserPreferenceType } from './user-preferences.types';
import { ALL_USER_PREFRENCES_FORM_TYPES } from './user-preferences.const';

export const getFieldsFromContract = <TFieldContract extends AnyRecord>(
  contract: FormContract<TFieldContract, Record<string, AnyFieldDefinition>>
) => contract.build().schema.fields as Field<TFieldContract[], Validator>[];

const getUserPreferencesFromWorkspace = (workspace: AppWorkspace): UserPreferencesCollection =>
  workspace.getMeta().memoryDatabase.collections.user_preferences;

export const getUserPreferencesFromContainer = (container: DependencyContainer): UserPreferencesCollection =>
  getUserPreferencesFromWorkspace(container.resolve(AppWorkspace));

type UserPreferences<Type extends OperationType> = {
  type: Type;
  collection: UserPreferencesCollection;
  userId: string;
} & (Type extends 'create'
  ? {
      document?: never;
    }
  : {
      document: RxDocument<UserPreferencesSchema>;
    });

export const getUserPreferences = async (
  container: DependencyContainer
): Promise<UserPreferences<'create'> | UserPreferences<'update'>> => {
  const authService = container.resolve(AuthService);

  const collection = getUserPreferencesFromContainer(container);
  const userId = cleanMaybe(authService.getUserId(), '');

  const document = await collection.findOne(userId).exec();
  if (document) {
    return { type: 'update', collection, document, userId };
  } else {
    return { type: 'create', collection, userId };
  }
};

type UserPreferencesSubSchema<Type extends UserPreferenceType> = NonNullable<UserPreferencesSchema[Type]>;

export const toUserPreferencesForm = async <FormValuesType, Type extends UserPreferenceType>(
  type: Type,
  container: DependencyContainer,
  transformToFormValues: (schema: Partial<UserPreferencesSubSchema<Type>>) => FormValuesType
) => {
  const userPreferences = await getUserPreferences(container);
  if (userPreferences.type === 'update') {
    const schema = cleanMaybe(userPreferences.document[type], {}) as Parameters<
      typeof transformToFormValues
    >[0];
    return transformToFormValues(schema);
  } else {
    return transformToFormValues({});
  }
};

export const fromUserPrefererencesForm = async <Type extends UserPreferenceType>(
  type: Type,
  output: NonNullable<UserPreferencesSchema[Type]>,
  container: DependencyContainer
): Promise<void> => {
  const userPreferences = await getUserPreferences(container);

  if (userPreferences.type === 'create') {
    const { collection, userId } = userPreferences;
    const document: UserPreferencesDocType = {
      id: userId,
      [type]: output
    };
    await collection.insert(document);
  } else {
    const { collection, document: existing, userId } = userPreferences;
    const otherForms: Partial<Record<UserPreferenceType, any>> = {};
    for (const formType of ALL_USER_PREFRENCES_FORM_TYPES) {
      if (formType !== type) {
        otherForms[formType] = deepClone(existing[formType]);
      }
    }
    const document: UserPreferencesDocType = {
      ...otherForms,
      id: existing.id || userId,
      [type]: output
    };
    await collection.incrementalUpsert(document);
  }
};
