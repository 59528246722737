import { ICellRendererParams } from '@ag-grid-community/core';
import { get } from 'lodash';
import { AnyRecord } from '@oms/frontend-foundation';
import { Box, Icon } from '@oms/ui-design-system';
import { ArrowRightIcon } from '@radix-ui/react-icons';

const displayValue = (value: unknown, zeroString: string, emptyString?: string) => {
  if (Number(value) === 0) {
    return zeroString;
  }
  if (value === undefined || value === null) {
    return emptyString ?? value;
  }
  return String(value);
};

const oldToNewValue = (oldValue: unknown, newValue: unknown, zeroString: string, emptyString?: string) => {
  if (((oldValue === undefined || oldValue === null) && !emptyString) || oldValue === newValue) {
    return <>{displayValue(newValue, zeroString, emptyString)}</>;
  }
  return (
    <>
      <Box as="span" sx={{ color: 'TransparentWhite.50' }}>
        {displayValue(oldValue, zeroString, emptyString)}
      </Box>
      <Icon
        as={ArrowRightIcon}
        label="changed to"
        sx={{ color: 'TransparentWhite.50', fontSize: 'gridBase', marginX: '2px' }}
      />
      {displayValue(newValue, zeroString, emptyString)}
    </>
  );
};

export const VALUE_CHANGED_RENDERER = 'valueChangedRenderer';

/**
 * ag-grid renderer displaying old value in grey -> new value.
 * If they are the same, show only the new value.
 * When there is a change to or from zero, zeroString can render a string instead.
 */
export const valueChangedRenderer =
  <TData extends AnyRecord = any>(
    oldField: keyof TData,
    newField: keyof TData,
    zeroString: string,
    emptyString?: string
  ) =>
  ({ data }: ICellRendererParams<TData>) => {
    // If field represents a nested property, it'll traverse the nested structure
    const oldFieldValue = get(data, oldField, undefined);
    const newFieldValue = get(data, newField, undefined);

    return oldToNewValue(oldFieldValue, newFieldValue, zeroString, emptyString);
  };
