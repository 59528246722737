import { Text } from '@oms/ui-design-system';
import { isEqual } from 'lodash';
import React from 'react';
import type { FC } from 'react';
import type { LabelProps } from '../../fixatdl-form.types';
import { useFieldApi } from '@oms/frontend-foundation';
import type { FieldProps, ICommonField } from '@oms/frontend-foundation';
import type { fixatdlFormComponentTypes } from '../fixatdl-component.mappers';

export const Label: FC<FieldProps<LabelProps>> = React.memo(({ ...props }) => {
  const {
    input: { value }
  } = useFieldApi<ICommonField<fixatdlFormComponentTypes.LABEL, string>>(props);

  return <Text sx={{ paddingBottom: 4 }}>{value}</Text>;
}, isEqual);
