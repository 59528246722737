import { createSignal } from '@oms/rx-broadcast';
import { ProcessState } from './process-id.subject';
import type { Signal } from '@oms/rx-broadcast';

/**
 * Any cross-process communication via signals should extend this to make sure the channel names are scoped by leader process.
 */
export abstract class AbstractSignal<TState> {
  public signal: Signal<TState>;
  constructor(
    private processState: ProcessState,
    private channel: string,
    private signalOptions?: Parameters<typeof createSignal<TState>>[2],
    public DEFAULT_STATE?: TState
  ) {
    this.signalOptions = this.signalOptions || {};
    if (this.signalOptions.broadcast === undefined) this.signalOptions.broadcast = true;
    this.signal = createSignal<TState>(
      this.channelName,
      this.DEFAULT_STATE || ({} as TState),
      this.signalOptions
    );
  }

  public get channelName() {
    return `${this.processState.LEADER_PROCESS_ID}-${this.channel}`;
  }

  public reset() {
    this.signal.set(this.DEFAULT_STATE || ({} as TState));
  }
}
