import type { SubSchemaRegistry } from '@oms/frontend-schemas';

export const SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI: SubSchemaRegistry = {
  TIME_AND_SALES: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/marketdata/time-and-sales/schema.time-and-sales.widget',
    versionedSchemas: []
  },
  CURRENCIES_GRID: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/reference-data/currencies/schema.currencies-grid.widget',
    versionedSchemas: []
  },
  EXCHANGES_GRID: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath: 'apps/oms-app/src/app/widgets/reference-data/exchanges/schema.exchanges-grid.widget',
    versionedSchemas: []
  },
  INSTRUMENT_RESTRICTIONS_GRID: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/reference-data/instrument-restrictions/schema.instrument-restrictions.widget',
    versionedSchemas: []
  },
  INSTRUMENTS: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/reference-data/instruments/schema.instruments-grid.widget',
    versionedSchemas: []
  },
  ROUTING_RULES_GRID: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/reference-data/routing-rules/schema.routing-rules.grid.widget',
    versionedSchemas: []
  },
  ABOUT_VALSTRO: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath: 'apps/oms-app/src/app/widgets/system/about/schema.about-valstro.widget',
    versionedSchemas: []
  },
  SYSTEM_LAUNCHER: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath: 'apps/oms-app/src/app/widgets/system/launcher/schema.launcher.widget',
    versionedSchemas: []
  },
  SYSTEM_PLACEHOLDER: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath: 'apps/oms-app/src/app/widgets/system/placeholder/schema.placeholder.widget',
    versionedSchemas: []
  },
  SYSTEM_REMOTE_FORM: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath: 'apps/oms-app/src/app/widgets/system/remote-form/schema.remote-form.widget',
    versionedSchemas: []
  },
  SYSTEM_IMPORT_SNAPSHOT: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath: 'apps/oms-app/src/app/widgets/system/snapshots/schema.import-snaphot.widget',
    versionedSchemas: []
  },
  SYSTEM_LAYOUTS: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath: 'apps/oms-app/src/app/widgets/system/snapshots/schema.layouts.widget',
    versionedSchemas: []
  },
  SYSTEM_SNAPSHOTS: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath: 'apps/oms-app/src/app/widgets/system/snapshots/schema.snapshots.widget',
    versionedSchemas: []
  },
  EVENT_DETAILS: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath: 'apps/oms-app/src/app/widgets/trading/event-details/schema.event-details.widget',
    versionedSchemas: []
  },
  EXECUTION_MONITOR: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath: 'apps/oms-app/src/app/widgets/trading/execution-monitor/schema.executions.widget',
    versionedSchemas: []
  },
  EXECUTION_VIEW_INFORMATION: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/execution-view/schema.execution-view.information.widget',
    versionedSchemas: []
  },
  EXECUTION_VIEW_SIDE_PANEL: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/execution-view/schema.execution-view.side-panel.widget',
    versionedSchemas: []
  },
  EXECUTION_VIEW_TABBED_DETAILS: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/execution-view/schema.execution-view.tabbed-details.widget',
    versionedSchemas: []
  },
  EXECUTION_VIEW_TABBED_GRIDS: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/execution-view/schema.execution-view.tabbed-grids.widget',
    versionedSchemas: []
  },
  INVESTOR_ORDER_MONITOR_GRID: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/investor-order-monitor/schema.investor-order-monitor.widget',
    versionedSchemas: []
  },
  IO_VIEW_ORDER_INFORMATION: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/investor-order-view/schema.investor-order-view.order-information.widget',
    versionedSchemas: []
  },
  IO_VIEW_SIDE_PANEL: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/investor-order-view/schema.investor-order-view.side-panel.widget',
    versionedSchemas: []
  },
  IO_VIEW_TABBED_GRIDS: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/investor-order-view/schema.investor-order-view.tabbed-grids.widget',
    versionedSchemas: []
  },
  IO_VIEW_TABBED_SUMMARIES: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/investor-order-view/schema.investor-order-view.tabbed-summaries.widget',
    versionedSchemas: []
  },
  MAPPABLE_ORDERS: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath: 'apps/oms-app/src/app/widgets/trading/mappable-orders/schema.mappable-orders.widget',
    versionedSchemas: []
  },
  MONTAGE_DATA_PANEL: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath: 'apps/oms-app/src/app/widgets/trading/montage/schema.montage.data.panel.widget',
    versionedSchemas: []
  },
  MONTAGE_SIDE_PANEL: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath: 'apps/oms-app/src/app/widgets/trading/montage/schema.montage.side-panel.widget',
    versionedSchemas: []
  },
  NEW_ORDERS_GRID: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath: 'apps/oms-app/src/app/widgets/trading/new-orders/schema.new-orders.grid.widget',
    versionedSchemas: []
  },
  NEW_ORDERS_SIDE_PANEL: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/new-orders/schema.new-orders.side-panel.widget',
    versionedSchemas: []
  },
  NOTIFICATIONS: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath: 'apps/oms-app/src/app/widgets/trading/notifications/schema.notifications.widget',
    versionedSchemas: []
  },
  ORDER_VWAP: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath: 'apps/oms-app/src/app/widgets/trading/order-vwap/schema.order-vwap.widget',
    versionedSchemas: []
  },
  PENDING_MODIFICATIONS_GRID: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/pending-modifications/schema.pending-modifications.grid.widget',
    versionedSchemas: []
  },
  PENDING_MODIFICATIONS_SIDE_PANEL: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/pending-modifications/schema.pending-modifications.side-panel.widget',
    versionedSchemas: []
  },
  REPAIR_QUEUE_INVESTOR_ORDERS_GRID: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/repair-queue/schema.repair-queue.investor-orders.widget',
    versionedSchemas: []
  },
  REPAIR_QUEUE_TRADES_GRID: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/repair-queue/schema.repair-queue.trades.widget',
    versionedSchemas: []
  },
  TRADE_MONITOR_GRID: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath: 'apps/oms-app/src/app/widgets/trading/trade-monitor/schema.trade-monitor.widget',
    versionedSchemas: []
  },
  TRADING_ORDER_MONITOR_GRID: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/trading-order-monitor/schema.trading-order-monitor.widget',
    versionedSchemas: []
  },
  TO_VIEW_ORDER_INFORMATION: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/trading-order-view/schema.trading-order-view.order-information.widget',
    versionedSchemas: []
  },
  TO_VIEW_SIDE_PANEL: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/trading-order-view/schema.trading-order-view.side-panel.widget',
    versionedSchemas: []
  },
  TO_VIEW_TABBED_GRIDS: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/trading-order-view/schema.trading-order-view.tabbed-grids.widget',
    versionedSchemas: []
  },
  TO_VIEW_TABBED_SUMMARIES: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/trading-order-view/schema.trading-order-view.tabbed-summaries.widget',
    versionedSchemas: []
  },
  USER_PREFERENCES: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath: 'apps/oms-app/src/app/widgets/user/user-preferences/schema.user-preferences.widget',
    versionedSchemas: []
  },
  MONTAGE_TOOLBAR: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/montage/montage-toolbar/schema.montage-toolbar.widget',
    versionedSchemas: []
  },
  STRATEGY_PARAMETERS: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/route-order/fixatdl-strategy-field/schema.fixatdl-strategy-parameters.widget',
    versionedSchemas: []
  },
  TRADE_VIEW_EXECUTIONS_GRID: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/trade-monitor/trade-view/schema.trade-view.executions-grid.widget',
    versionedSchemas: []
  },
  TRADE_VIEW_SIDE_PANEL: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/trade-monitor/trade-view/schema.trade-view.side-panel.widget',
    versionedSchemas: []
  },
  TRADE_VIEW_TABBED_GRIDS: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/trade-monitor/trade-view/schema.trade-view.tabbed-grids.widget',
    versionedSchemas: []
  },
  TRADE_VIEW_INFORMATION: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/trade-monitor/trade-view/schema.trade-view.trade-information.widget',
    versionedSchemas: []
  },
  TRADE_VIEW_TABBED_DETAILS: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/trade-monitor/trade-view/schema.trading-view.tabbed-details.widget',
    versionedSchemas: []
  },
  POSITIONS_ACCOUNT_GRID: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/positions/grids/account/schema.positions-account-grid.widget',
    versionedSchemas: []
  },
  POSITIONS_INSTRUMENT_GRID: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/positions/grids/instrument/schema.positions-instrument-grid.widget',
    versionedSchemas: []
  },
  NOTIFICATIONS_SETTINGS: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/user/user-preferences/preferences/notification-settings/schema.notification-settings.widget',
    versionedSchemas: []
  },
  EXECUTION_VIEW: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath: 'apps/oms-app/src/app/widgets/trading/execution-view/schema.execution-view.layout',
    versionedSchemas: []
  },
  INVESTOR_ORDER_MONITOR: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/investor-order-monitor/schema.investor-order-monitor.layout',
    versionedSchemas: []
  },
  INVESTOR_ORDER_VIEW: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/investor-order-view/schema.investor-order-view.layout',
    versionedSchemas: []
  },
  MONTAGE: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath: 'apps/oms-app/src/app/widgets/trading/montage/schema.montage.layout',
    versionedSchemas: []
  },
  NEW_ORDERS: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath: 'apps/oms-app/src/app/widgets/trading/new-orders/schema.new-orders.layout',
    versionedSchemas: []
  },
  PENDING_MODIFICATIONS: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/pending-modifications/schema.pending-modifications.layout',
    versionedSchemas: []
  },
  REPAIR_QUEUE: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath: 'apps/oms-app/src/app/widgets/trading/repair-queue/schema.repair-queue.layout',
    versionedSchemas: []
  },
  TRADING_ORDER_MONITOR: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/trading-order-monitor/schema.trading-order-monitor.layout',
    versionedSchemas: []
  },
  TRADING_ORDER_VIEW: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/trading-order-view/schema.trading-order-view.layout',
    versionedSchemas: []
  },
  POSITIONS: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath: 'apps/oms-app/src/app/widgets/trading/positions/layouts/schema.positions.layout',
    versionedSchemas: []
  },
  TRADE_VIEW: {
    collection: 'snapshots',
    type: 'snapshot-component',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/trade-monitor/trade-view/schema.trade-view.layout',
    versionedSchemas: []
  },
  ACTION_BUTTON_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/actions/forms/action-button/schema.action-button.form-builder.input',
    versionedSchemas: []
  },
  ACTION_BUTTON_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/actions/forms/action-button/schema.action-button.form-builder.values',
    versionedSchemas: []
  },
  ACTION_BUTTON_LAYOUT_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/actions/forms/action-button-layout/schema.action-button-layout.form-builder.input',
    versionedSchemas: []
  },
  ACTION_BUTTON_LAYOUT_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/actions/forms/action-button-layout/schema.action-button-layout.form-builder.values',
    versionedSchemas: []
  },
  CURRENCY_FORM_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/reference-data/currencies/schema.currency.form-builder.input',
    versionedSchemas: []
  },
  CURRENCY_FORM_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/reference-data/currencies/schema.currency.form-builder.values',
    versionedSchemas: []
  },
  EXCHANGE_FORM_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/reference-data/exchanges/schema.exchange.form-builder.input',
    versionedSchemas: []
  },
  EXCHANGE_FORM_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/reference-data/exchanges/schema.exchange.form-builder.values',
    versionedSchemas: []
  },
  INSTRUMENT_RESTRICTION_ENTRY_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/reference-data/instrument-restriction-entry/schema.instrument-restriction-entry.form-builder.input',
    versionedSchemas: []
  },
  INSTRUMENT_RESTRICTION_ENTRY_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/reference-data/instrument-restriction-entry/schema.instrument-restriction-entry.form-builder.values',
    versionedSchemas: []
  },
  ROUTING_RULE_FORM_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/reference-data/routing-rules/schema.routing-rule.form-builder.input',
    versionedSchemas: []
  },
  ROUTING_RULE_FORM_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/reference-data/routing-rules/schema.routing-rule.form-builder.values',
    versionedSchemas: []
  },
  BUG_REPORTER_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/system/bug-reporter/schema.bug-reporter.form-builder.input',
    versionedSchemas: []
  },
  BUG_REPORTER_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/system/bug-reporter/schema.bug-reporter.form-builder.values',
    versionedSchemas: []
  },
  SAVE_SNAPSHOT_FORM_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/system/snapshots/schema.save-snapshot.form-builder.input',
    versionedSchemas: []
  },
  SAVE_SNAPSHOT_FORM_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/system/snapshots/schema.save-snapshot.form-builder.values',
    versionedSchemas: []
  },
  BULK_ROUTE_ORDER_FORM_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/bulk-route-order/schema.bulk-route-order.form-builder.input',
    versionedSchemas: []
  },
  BULK_ROUTE_ORDER_FORM_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/bulk-route-order/schema.bulk-route-order.form-builder.values',
    versionedSchemas: []
  },
  CROSS_PRINCIPAL_FILL_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/cross-principal-fill/schema.cross-principal-fill.form-builder.input',
    versionedSchemas: []
  },
  CROSS_PRINCIPAL_FILL_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/cross-principal-fill/schema.cross-principal-fill.form-builder.values',
    versionedSchemas: []
  },
  INVESTOR_ORDER_BULK_ENTRY_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/investor-order-bulk-entry/schema.investor-order-bulk-entry.form-builder.input',
    versionedSchemas: []
  },
  INVESTOR_ORDER_BULK_ENTRY_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/investor-order-bulk-entry/schema.investor-order-bulk-entry.form-builder.values',
    versionedSchemas: []
  },
  INVESTOR_ORDER_ENTRY_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/investor-order-entry/schema.investor-order-entry.form-builder.input',
    versionedSchemas: []
  },
  INVESTOR_ORDER_ENTRY_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/investor-order-entry/schema.investor-order-entry.form-builder.values',
    versionedSchemas: []
  },
  PRINCIPLE_FILL_FORM_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/principal-fill/schema.principal-fill.form-builder.input',
    versionedSchemas: []
  },
  PRINCIPLE_FILL_FORM_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/principal-fill/schema.principal-fill.form-builder.values',
    versionedSchemas: []
  },
  ROUTE_ORDER_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/route-order/schema.route-order.form-builder.input',
    versionedSchemas: []
  },
  ROUTE_ORDER_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/route-order/schema.route-order.form-builder.values',
    versionedSchemas: []
  },
  TRADE_MODIFY_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/trade-modify/schema.trade-modify.form-builder.input',
    versionedSchemas: []
  },
  TRADE_MODIFY_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/trade-modify/schema.trade-modify.form-builder.values',
    versionedSchemas: []
  },
  CUSTOM_NOTIFICATION_ENTRY_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/user/custom-notification-entry/schema.custom-notification-entry.form-builder.input',
    versionedSchemas: []
  },
  CUSTOM_NOTIFICATION_ENTRY_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/user/custom-notification-entry/schema.custom-notification-entry.form-builder.values',
    versionedSchemas: []
  },
  REJECT_INVESTOR_ORDER_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/investor-order-monitor/grid-forms/schema.reject-investor-order.form-builder.input',
    versionedSchemas: []
  },
  REJECT_INVESTOR_ORDER_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/investor-order-monitor/grid-forms/schema.reject-investor-order.form-builder.values',
    versionedSchemas: []
  },
  APPLY_SETTLEMENT_FX_RATE_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/investor-order-view/grid-forms/schema.apply-settlement-fx-rate.form-builder.input',
    versionedSchemas: []
  },
  APPLY_SETTLEMENT_FX_RATE_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/investor-order-view/grid-forms/schema.apply-settlement-fx-rate.form-builder.values',
    versionedSchemas: []
  },
  CANCEL_EXECUTIONS_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/investor-order-view/grid-forms/schema.cancel-executions.form-builder.input',
    versionedSchemas: []
  },
  CANCEL_EXECUTIONS_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/investor-order-view/grid-forms/schema.cancel-executions.form-builder.values',
    versionedSchemas: []
  },
  ORDER_VWAP_FORM_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/order-vwap/form/schema.order-vwap.form-builder.input',
    versionedSchemas: []
  },
  ORDER_VWAP_FORM_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/order-vwap/form/schema.order-vwap.form-builder.values',
    versionedSchemas: []
  },
  REJECT_PENDING_MODIFICATION_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/pending-modifications/grid-forms/schema.reject-pending-modification.form-builder.input',
    versionedSchemas: []
  },
  REJECT_PENDING_MODIFICATION_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/pending-modifications/grid-forms/schema.reject-pending-modification.form-builder.values',
    versionedSchemas: []
  },
  BULK_REPAIR_TRADE_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/repair-queue/bulk-repair-trade/schema.bulk-repair-trade.form-builder.input',
    versionedSchemas: []
  },
  BULK_REPAIR_TRADE_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/repair-queue/bulk-repair-trade/schema.bulk-repair-trade.form-builder.values',
    versionedSchemas: []
  },
  ENABLE_QUOTE_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/montage/depth-grid/forms/schema.enable-quote.form-builder.input',
    versionedSchemas: []
  },
  ENABLE_QUOTE_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/montage/depth-grid/forms/schema.enable-quote.form-builder.values',
    versionedSchemas: []
  },
  MONTAGE_FILTERS_FORM_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/montage/montage-toolbar/montage-filters.form/schema.montage-filters.form-builder.input',
    versionedSchemas: []
  },
  MONTAGE_FILTERS_FORM_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/montage/montage-toolbar/montage-filters.form/schema.montage-filters.form-builder.values',
    versionedSchemas: []
  },
  MOCK_USER_SETTINGS_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/user/user-preferences/preferences/mock-user-settings/schema.mock-user-settings.form-builder.input',
    versionedSchemas: []
  },
  MOCK_USER_SETTINGS_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/user/user-preferences/preferences/mock-user-settings/schema.mock-user-settings.form-builder.values',
    versionedSchemas: []
  },
  MONTAGE_SETTINGS_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/user/user-preferences/preferences/montage-settings/schema.montage-settings.form-builder.input',
    versionedSchemas: []
  },
  MONTAGE_SETTINGS_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/user/user-preferences/preferences/montage-settings/schema.montage-settings.form-builder.values',
    versionedSchemas: []
  },
  ORDER_SETTINGS_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/user/user-preferences/preferences/order-settings/schema.order-settings.form-builder.input',
    versionedSchemas: []
  },
  ORDER_SETTINGS_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/user/user-preferences/preferences/order-settings/schema.order-settings.form-builder.values',
    versionedSchemas: []
  },
  MONTAGE_BUMP_PRICE_FORM_INPUT: {
    collection: 'snapshots',
    type: 'snapshot-form-input',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/montage/commands/bump-price/form/schema.bump-price.form-builder.input',
    versionedSchemas: []
  },
  MONTAGE_BUMP_PRICE_FORM_VALUES: {
    collection: 'snapshots',
    type: 'snapshot-form-values',
    subSchemaFolderPath:
      'apps/oms-app/src/app/widgets/trading/montage/commands/bump-price/form/schema.bump-price.form-builder.values',
    versionedSchemas: []
  }
} as const;
