import { useAuthState } from '@app/data-access/services/system/auth/auth.hooks';
import { Flex, Avatar, AvatarBadge, Box, Text } from '@oms/ui-design-system';
import type { FlexProps, AvatarProps } from '@oms/ui-design-system';

export interface UserAvatarProps {
  onClick?: React.MouseEventHandler<HTMLElement>;
  wrapperSx?: FlexProps['sx'];
  wrapperStyle?: React.CSSProperties;
  sx?: AvatarProps['sx'];
  style?: React.CSSProperties;
  showInfo?: boolean;
  className?: string;
  size?: AvatarProps['size'];
}

export function UserAvatar({
  onClick,
  wrapperStyle,
  wrapperSx,
  sx,
  style,
  size = 'md',
  showInfo = false,
  className
}: UserAvatarProps) {
  const auth = useAuthState();

  return (
    <Flex sx={{ alignItems: 'center', ...wrapperSx }} style={wrapperStyle}>
      <Avatar
        size={size}
        aria-label={auth.isAuthenticated ? 'Logged in user avatar' : 'User is not logged in'}
        className={className}
        sx={{ cursor: auth.isAuthenticated && onClick ? 'pointer' : undefined, ...sx }}
        onClick={onClick}
        style={style}
      >
        <AvatarBadge
          backgroundColor={auth.isAuthenticated ? 'Green.500' : 'Red.500'}
          position="bottom-right"
        />
      </Avatar>
      {showInfo ? (
        <Box sx={{ ml: 3 }}>
          <Text as="div" type="mediumR" isTruncated>
            {auth.tokenParsed?.firstName || 'User'} {auth.tokenParsed?.lastName || ''}
          </Text>
          {auth.tokenParsed?.email && (
            <Text as="div" type="smallR" sx={{ color: 'text.active' }} isTruncated>
              {auth.tokenParsed.email}
            </Text>
          )}
        </Box>
      ) : null}
    </Flex>
  );
}
