import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import type { AdvancedSelectQueryFn, AdvancedSelectQueryTypeaheadParams } from '@oms/frontend-foundation';
import type { ComboBoxItemUnion } from '@oms/ui-design-system';
import type { Subscription } from 'rxjs';
import type { OperationVariables, WatchQueryOptions, QueryOptions } from '@apollo/client';

export type TypeaheadQueryOptions<
  ComboValue = any,
  T = any,
  TVariables extends OperationVariables = OperationVariables,
  TFindOne = any,
  TFineOneVariables extends OperationVariables = OperationVariables
> = {
  lookupQuery: (inputValue: string) => WatchQueryOptions<TVariables, T>;
  lookupMapper: (data: T) => ComboBoxItemUnion<ComboValue>[];
  getOneQuery: (id: string) => QueryOptions<TFineOneVariables, TFindOne>;
  getOneMapper: (data: TFindOne) => ComboBoxItemUnion<ComboValue> | null;
};

export function typeaheadQuery<
  ComboValue = any,
  T = any,
  TVariables extends OperationVariables = OperationVariables,
  TFindOne = any,
  TFineOneVariables extends OperationVariables = OperationVariables
>({
  lookupQuery,
  lookupMapper,
  getOneQuery,
  getOneMapper
}: TypeaheadQueryOptions<
  ComboValue,
  T,
  TVariables,
  TFindOne,
  TFineOneVariables
>): AdvancedSelectQueryFn<ComboValue> {
  return (container) => {
    const apolloClient = container.resolve(RxApolloClient);
    let hasResults = false;
    let lastSub: Subscription | undefined;

    const query = ({ inputValue, callback }: AdvancedSelectQueryTypeaheadParams<ComboValue>) => {
      if (lastSub) {
        lastSub.unsubscribe();
      }

      if (!inputValue) {
        hasResults = false;
        return;
      }

      if (hasResults === false) {
        callback({
          isFetching: true,
          results: []
        });
      }

      const watchQueryOptions = lookupQuery(inputValue);
      lastSub = apolloClient.rxWatchQuery<T, TVariables>(watchQueryOptions).subscribe((result) => {
        const { data, loading: isFetching, error } = result;
        const results = lookupMapper(data);
        hasResults = results.length > 0;

        callback({
          results,
          isFetching,
          error
        });
      });
    };

    return {
      type: 'typeahead',
      query,
      findOne: async ({ id }) => {
        try {
          const { data } = await apolloClient.query<TFindOne, TFineOneVariables>(getOneQuery(id));
          return getOneMapper(data);
        } catch (e) {
          console.error(e);
          return null;
        }
      },
      unsubscribe: () => {
        if (lastSub) {
          lastSub.unsubscribe();
        }
      }
    };
  };
}
