import {
  type FC,
  type MouseEventHandler,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { type RuleBuilderField, type RuleGroup } from './rule.types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  componentTypes,
  FieldArray,
  type FormOptions,
  useFieldApi,
  type UseFieldApiConfig,
  useFormApi
} from '@data-driven-forms/react-form-renderer';
import { RuleGroup as RuleGroupComp } from './rule.group';
import { isRuleGroup } from './rule.utils';
import { Rule as RuleComp } from './rule';
import { AddIcon, Box, Button, Grid, GridItem } from '@oms/ui-design-system';
import { type FieldArrayRenderProps } from 'react-final-form-arrays';
import { isEqual, omit, pick } from 'lodash';
import { FieldWrapper } from '../field-wrapper/field-wrapper';

export type RuleBuilderConfig = RuleBuilderField & UseFieldApiConfig;

export interface CoreRuleBuilderProps {
  index?: number;
  rootName: string;
  fieldConfig: RuleBuilderConfig;
  inputName: string;
  disableGroups?: boolean;
}

export interface CombinatorProps extends CoreRuleBuilderProps {
  combinator: string;
  renderForm: FormOptions['renderForm'];
}

export interface RenderRuleProps extends CoreRuleBuilderProps {
  onRemove: (idx: number) => void;
  currValue: any | RuleGroup;
  renderForm: FormOptions['renderForm'];
  level: number;
  path: number[];
}

export interface RuleTraverseProps extends CoreRuleBuilderProps {
  level: number;
  path: number[];
}

const RenderRule: FC<RenderRuleProps> = ({
  rootName,
  inputName,
  renderForm,
  onRemove,
  currValue,
  level,
  index,
  path,
  fieldConfig
}) => {
  const { field, operator, filterValue } = useFieldApi<RuleBuilderConfig>(fieldConfig);
  const onDelete = useCallback(() => {
    if (index !== undefined) {
      onRemove(index);
    }
  }, [index, onRemove]);
  const fieldsToRender = useRef(
    renderForm([
      {
        ...field,
        name: `${inputName}.${field.name}`,
        initialValue: omit(currValue, 'operator', 'filterValues')['field']
      },
      {
        ...operator,
        name: `${inputName}.${operator.name}`,
        initialValue: pick(currValue, 'operator')['operator']
      },
      {
        ...filterValue,
        name: `${inputName}.${filterValue.name}`,
        initialValue: pick(currValue, 'filterValues')['filterValues']
      }
    ])
  );

  if (isRuleGroup(currValue)) {
    return (
      <MemoRuleTraverse
        rootName={rootName}
        inputName={inputName}
        path={path}
        level={level}
        index={index}
        fieldConfig={fieldConfig}
      />
    );
  }

  if (!currValue) {
    return null;
  }

  return (
    <RuleComp onDelete={onDelete} path={path}>
      {fieldsToRender.current}
    </RuleComp>
  );
};

const RenderRuleMemo = memo(RenderRule, (prev, curr) => {
  return isEqual(omit(prev, 'onRemove', 'renderForm'), omit(curr, 'onRemove', 'renderForm'));
});

const Combinator: FC<CombinatorProps> = ({ index, combinator, inputName, renderForm }) => {
  const combinatorField = useRef(
    renderForm([
      {
        name: inputName,
        component: componentTypes.SELECT,
        initialValue: combinator,
        options: [
          { label: 'OR', value: 'or' },
          { label: 'AND', value: 'and' }
        ]
      }
    ])
  );

  if (index === 0) {
    return <Box>Where</Box>;
  }

  if (index === 1) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{combinatorField.current}</>;
  }

  return <Box>{combinator}</Box>;
};

const MemoCombinator = memo(Combinator, (prev, curr) => {
  return isEqual(omit(prev, 'onRemove', 'renderForm'), omit(curr, 'onRemove', 'renderForm'));
});

const RuleTraverse: FC<RuleTraverseProps> = ({ level, index, path, rootName, fieldConfig, inputName }) => {
  const { renderForm, change, getFieldState, subscribe } = useFormApi();
  const {
    addRuleGroupText = 'Add Rule Group',
    addRuleText = 'Add Rule',
    disableGroups = false
  } = useFieldApi<RuleBuilderConfig>(fieldConfig);
  const cosiRef = useRef<FieldArrayRenderProps<any, HTMLElement>>();
  const arrName = useMemo(() => `${inputName}.rules`, [inputName]);
  const [combinator, setCombinator] = useState<string>('and');

  // const [data, drop] = useDrop<RuleGroupProps | RuleProps, Rule | RuleGroup, RuleDragCollectionProps>(() => ({
  //   accept: ['rule', 'ruleGroup'] as RuleDragItemTypes[]
  //   canDrop: (item) => {
  //     const parentHoverPath = getParentPath(path);
  //     const parentItemPath = getParentPath(item.path);
  //     const hoverIndex = path[path.length - 1];
  //     const itemIndex = item.path[item.path.length - 1];

  //     // Don't allow drop if 1) item is ancestor of drop target,
  //     // or 2) item is hovered over itself or the previous item
  //     return !(
  //       isAncestor(item.path, path) ||
  //       (pathsAreEqual(parentHoverPath, parentItemPath) &&
  //         (hoverIndex === itemIndex ||
  //           hoverIndex === itemIndex - 1 ||
  //           (independentCombinators && hoverIndex === itemIndex - 2)))
  //     );
  //   },
  //   collect: (monitor) => ({
  //     isOver: monitor.canDrop() && monitor.isOver(),
  //     dropMonitorId: monitor.getHandlerId() ?? '',
  //     dropEffect: (monitor.getDropResult() ?? {}).dropEffect
  //   }),
  //   // `dropEffect` gets added automatically to the object returned from `drop`:
  //   drop: () => ({ type: 'rule', path })
  // }));

  const combinatorField = useMemo(() => {
    return `${inputName}.combinator`;
  }, [inputName]);

  const onDelete: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();

      if (level > 0) {
        const parsedInput = inputName.split('.');
        const root = parsedInput.slice(0, parsedInput.length - 1).join('.');
        const rules = `${root}.rules`;
        const state = getFieldState(rules);
        if (!state || index === undefined) {
          return;
        }
        const ruleArr = structuredClone(state.value) as any[];
        ruleArr.splice(index, 1);

        change(rules, ruleArr);
      }
    },
    [change, getFieldState, index, inputName, level]
  );

  const addRuleGroup: MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
    e.preventDefault();
    cosiRef.current!.fields.push({ rules: [{}] });
  }, []);

  const addRule: MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
    e.preventDefault();
    cosiRef.current!.fields.push({});
  }, []);

  const renderArray = useCallback(
    (cosi: FieldArrayRenderProps<any, HTMLElement>) => {
      cosiRef.current = cosi;
      return cosi.fields.map((r, i) => {
        const newPath = structuredClone(path);
        newPath.push(i);
        // console.log('creating path', newPath);
        return (
          <Grid key={`${r}-${i}`} columns={12} gap={1} sx={{ flexDirection: 'row', flexGrow: 1 }}>
            <GridItem colSpan={1} sx={{ alignItems: 'center', justifyContent: 'center' }}>
              <MemoCombinator
                rootName={rootName}
                index={i}
                inputName={combinatorField}
                combinator={combinator}
                renderForm={renderForm}
                fieldConfig={fieldConfig}
              />
            </GridItem>
            <GridItem colSpan={11}>
              <RenderRuleMemo
                rootName={rootName}
                path={newPath}
                index={i}
                level={level + 1}
                inputName={r}
                onRemove={cosi.fields.remove}
                currValue={cosi.fields.value[i]}
                renderForm={renderForm}
                fieldConfig={fieldConfig}
              />
            </GridItem>
          </Grid>
        );
      });
    },
    [combinator, combinatorField, fieldConfig, level, path, renderForm, rootName]
  );

  useEffect(() => {
    const unsub = subscribe(
      (_e) => {
        const combinatorState = getFieldState(combinatorField);
        if (combinatorState && combinatorState.value !== combinator) {
          setCombinator(combinatorState.value);
        }
      },
      { values: true }
    );

    return () => {
      unsub();
    };
  }, [combinator, combinatorField, getFieldState, rootName, subscribe]);

  const state = getFieldState(arrName);
  const isEmptyLevel0 =
    level === 0 && (!state || !state.value || (Array.isArray(state.value) && !state.value.length));

  return (
    <Box
      sx={{
        bg: isEmptyLevel0 ? 'inherit' : !level || level % 2 === 0 ? 'layout.level2' : 'layout.level1'
      }}
    >
      <RuleGroupComp
        key={`${inputName}-group`}
        inputName={inputName}
        hideActionMenu={level === 0}
        level={level}
        onDelete={onDelete}
        addRule={{ onClick: addRule, text: addRuleText }}
        addGroup={disableGroups ? undefined : { onClick: addRuleGroup, text: addRuleGroupText }}
        path={path}
        styles={{
          borderWidth: level === 0 ? '0px' : '1px'
        }}
      >
        <FieldArray key={arrName} name={arrName}>
          {renderArray}
        </FieldArray>
      </RuleGroupComp>
    </Box>
  );
};

const MemoRuleTraverse = memo(RuleTraverse, (prev, curr) => {
  return isEqual(omit(prev, 'onRemove', 'renderForm'), omit(curr, 'onRemove', 'renderForm'));
});

export const RuleBuilder: FC<RuleBuilderConfig> = (props) => {
  const {
    input,
    field,
    operator,
    filterValue,
    addRuleGroupText = 'Add Rule Group',
    addRuleText = 'Add Rule',
    disableGroups = false,
    ...rest
  } = useFieldApi<RuleBuilderConfig>(props);
  const { name, value, onChange } = input;

  useEffect(() => {
    if (!field || !operator || !filterValue) {
      throw new Error('Missing DDF components for field, operator, or filterValue');
    }
  }, [field, filterValue, operator]);

  useEffect(() => {
    if (!isRuleGroup(value) && value) {
      onChange(undefined);
    }
  }, [onChange, value]);

  const addRuleGroup: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      const val = structuredClone({ rules: value?.rules || [], combinator: value?.combinator || 'and' });
      val.rules.push({ rules: [{}], combinator: 'and' });
      onChange(val);
    },
    [onChange, value?.combinator, value?.rules]
  );

  const addRule: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      const val = structuredClone({ rules: value?.rules || [], combinator: value?.combinator || 'and' });
      val.rules.push({});
      onChange(val);
    },
    [onChange, value?.combinator, value?.rules]
  );

  return (
    <>
      <FieldWrapper
        {...omit(rest, 'buttonSx', 'groupSx', 'rootSx')}
        sx={{
          height: 'auto',
          flexGrow: 1,
          flexDirection: 'column',
          display: 'flex',
          ...(rest.rootSx || {})
        }}
      >
        <Box
          sx={{
            height: 'auto',
            flexGrow: 1,
            flexDirection: 'column',
            display: 'flex',
            ...(rest.groupSx || {})
          }}
        >
          <DndProvider backend={HTML5Backend}>
            <MemoRuleTraverse path={[]} level={0} inputName={name} rootName={name} fieldConfig={props} />
          </DndProvider>
        </Box>
      </FieldWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 2,
          ...(rest.buttonSx || {})
        }}
      >
        <Button
          sx={{ marginRight: 1 }}
          variant={'secondary'}
          leftIcon={<AddIcon />}
          onClick={addRule}
          data-testid={`${name}-add-rule`}
          isDisabled={rest.isDisabled}
        >
          {addRuleText}
        </Button>
        {!disableGroups && (
          <Button
            variant={'secondary'}
            leftIcon={<AddIcon />}
            data-testid={`${name}-add-rule-group`}
            onClick={addRuleGroup}
            isDisabled={rest.isDisabled}
          >
            {addRuleGroupText}
          </Button>
        )}
      </Box>
    </>
  );
};
