import { useEffect, useState } from 'react';
import { Logger } from '@oms/ui-util';
import type { Optional } from '@oms/ui-util';
import { RegNmsApplicability } from '@oms/generated/frontend';
import type { SipData } from '@oms/shared/regnms';
import { Level2WebSocketSubject } from '../../marketdata/level2-websocket.subjects';

export const sipQuoteLogger = Logger.named('useLevel2SipQuotes');

export const useLevel2SipQuotes = (
  instrumentId: Optional<string>,
  regNmsApplicability: RegNmsApplicability,
  shouldConnectToSidecar: boolean
): Optional<SipData> => {
  const [level2Data, setLevel2Data] = useState<Optional<SipData>>(undefined);

  useEffect(() => {
    if (!instrumentId || regNmsApplicability !== RegNmsApplicability.Applicable || !shouldConnectToSidecar) {
      return;
    }

    const webSocketSubject$ = new Level2WebSocketSubject('sip');
    const obs$ = webSocketSubject$.multiplex(
      () => instrumentId,
      () => '',
      () => true
    );

    const subscription = obs$.subscribe({
      next: (value) => {
        setLevel2Data(value);
      },
      error: (e) => sipQuoteLogger.error(e)
    });

    return () => {
      subscription.unsubscribe();
      webSocketSubject$.complete();
    };
  }, [instrumentId, regNmsApplicability]);

  return level2Data;
};
