import type { ResolvePropsFunction } from '@data-driven-forms/react-form-renderer';
import type { OrderSettingsFormValues } from '../order-settings.form-contract';
import { OrderSizeRowUtil } from './order-size-row-util.class';
import { UUID } from '@oms/ui-util';

export const ADD_PRICE_ROW = 'addPriceRow';

export const addPriceRow = (): ResolvePropsFunction => (props, fieldApi, formOptions) => {
  const allValues = formOptions.getState().values as OrderSettingsFormValues;
  const util = new OrderSizeRowUtil(allValues, fieldApi);
  if (util.shouldAddNextRow)
    formOptions.change<keyof OrderSettingsFormValues>(
      'profiles',
      util.addNewRow({ id: UUID(), orderSize: 0 }).profiles
    );
  return props;
};
