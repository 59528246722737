import type { CSSProperties } from 'react';
import { ToggleButton } from '@oms/ui-design-system';
import type { ToggleButtonProps } from '@oms/ui-design-system';
import { Crosshair2Icon } from '@radix-ui/react-icons';

const style: CSSProperties = {
  height: 'calc(var(--primarySize) - 1px)',
  padding: '0.25rem'
} as const;

const sx = { mr: 2 } as const;

export type InstrumentFollowToggleButtonProps = ToggleButtonProps & {
  isPrimaryField?: boolean;
};

export const InstrumentFollowToggleButton: React.FC<InstrumentFollowToggleButtonProps> = ({
  isPrimaryField,
  ...props
}) => {
  return (
    <ToggleButton
      shape="square"
      size={isPrimaryField ? 'md' : 'sm'}
      variant="secondary"
      sx={sx}
      style={style}
      aria-label="Follow instrument"
      {...props}
    >
      <Crosshair2Icon />
    </ToggleButton>
  );
};
