import { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import {
  type FormRendererProps as DddfFormRendererProps,
  FormRenderer as DddfFormRenderer,
  type FormTemplateRenderProps,
  type Field,
  type Schema
} from '@data-driven-forms/react-form-renderer';
import type { SubmissionErrors } from 'final-form';
import type { AnyRecord } from '../../../../common/type.helpers';
import type { EnhancedFormOptions } from '../../../types';
import { FormBuilderTemplateWrapper } from '../templates/common/form-builder-template.wrapper';
import { Center, Spinner, useDeepMemo } from '@oms/ui-design-system';
import type { FormBuilderChangeFnResult, FormBuilderDefinition } from '../../form-builder.class';
import type { FormContract, InferFormValuesFromFormContract } from '../../form-contract.class';
import type { AnyFieldDefinition } from '../../form-field-definition.class';
import {
  type FormRendererEventSubmissionResult,
  formRendererEvent$
} from '../../form-builder.events.renderer';
import { filter, firstValueFrom, timeout } from 'rxjs';
import {
  type FormBuilderTemplateMapperKey,
  FORM_BUILDER_TEMPLATE_MAPPER,
  type FormBuilderTemplateProps
} from '../templates/form-builder.template-mapper';
import { FORM_COMPONENT_MAPPER } from '../../../mappers/ddf-mapper';
import { FORM_VALIDATOR_MAPPER } from '../../../mappers/ddf-validator-mapper';
import { FORM_ACTION_MAPPER } from '../../../mappers/ddf-action-mapper';
import { isPromiseLike } from '@oms/ui-util';
import {
  FORM_SAVE_TYPE,
  type OnValuesChanging,
  type FormSaveType,
  type OnSanitizedValuesChanged,
  type OnValuesChanged
} from '../../form-builder.common.types';
import type { Unsubscribe } from '@valstro/workspace';
import type { DependencyContainer } from 'tsyringe';
import type { FeedbackWrapper } from '../../../../graphql/graphql-envelope';
import { useWorkspaceFormMappers } from '../../../common/form.workspace.hook';
import { traverseAndApplyTransformationToObject } from '../../../../utils/traverse-value.util';
import type { FormBuilderEventSubmitFinishedSuccess } from '../../form-builder.events';

export type FormRendererProps<
  TInputContract extends AnyRecord = AnyRecord,
  TOutputContract extends AnyRecord = AnyRecord,
  TFormContract extends FormContract<TOutputContract, Record<string, AnyFieldDefinition>> = FormContract<
    TOutputContract,
    Record<string, AnyFieldDefinition>
  >,
  TFormFieldValues extends AnyRecord = InferFormValuesFromFormContract<TFormContract>
> = Pick<
  FormBuilderDefinition<TInputContract, TOutputContract, TFormContract, TFormFieldValues>,
  'schema'
> & {
  sanitizer?: FormBuilderDefinition<
    TInputContract,
    TOutputContract,
    TFormContract,
    TFormFieldValues
  >['sanitizer'];
  template?: FormBuilderTemplateMapperKey;
  templateProps?: Partial<FormBuilderTemplateProps[keyof FormBuilderTemplateProps]>;
  componentMapper?: DddfFormRendererProps['componentMapper'];
  validatorMapper?: DddfFormRendererProps['validatorMapper'];
  actionMapper?: DddfFormRendererProps['actionMapper'];
  initialValues?: Partial<TFormFieldValues>;
  formId: string;
  container: DependencyContainer;
  formType?: string;
  formSaveType?: FormSaveType;
  onFormApi?: (formApi: EnhancedFormOptions<TFormFieldValues>) => void;
  onSubmit?: (values: TFormFieldValues) => Promise<void>;
  onRunInputSanitizer?: (values: TInputContract) => Promise<Partial<TFormFieldValues>>;
  onSubmitFinished?: (
    result: FormBuilderChangeFnResult<TFormFieldValues>,
    payload: FormBuilderEventSubmitFinishedSuccess<TOutputContract, TFormFieldValues>['payload']
  ) => Promise<void>;
  onError?: (values: TFormFieldValues) => void;
  onMount?: () => void;
  onUnmount?: () => void;
  onInit?: (formApi: EnhancedFormOptions<TFormFieldValues>) => Unsubscribe | Promise<Unsubscribe>;
  onValuesChanging?: OnValuesChanging<TFormFieldValues>;
  onValuesChanged?: OnValuesChanged<TFormFieldValues>;
  onSanitizedValuesChanged?: OnSanitizedValuesChanged<TFormFieldValues, TOutputContract>;
  onValidate?: (values: TFormFieldValues) => void;
  onReset?: () => void;
  onCancel?: () => void;
  isLoading?: boolean;
  errorMessage?: string;
  initialFeedback?: FeedbackWrapper[];
  triggerValidationOnOpen?: boolean;
};

export type FormRenderState =
  | {
      type: 'initializing';
    }
  | {
      type: 'initialized';
    }
  | {
      type: 'invalidated';
      error: string;
    };

function FormRendererBase<
  TInputContract extends AnyRecord = AnyRecord,
  TOutputContract extends AnyRecord = AnyRecord,
  TFormContract extends FormContract<TOutputContract, Record<string, AnyFieldDefinition>> = FormContract<
    TOutputContract,
    Record<string, AnyFieldDefinition>
  >,
  TFormFieldValues extends AnyRecord = InferFormValuesFromFormContract<TFormContract>
>({
  formId,
  formType,
  formSaveType = FORM_SAVE_TYPE.UNKNOWN,
  schema: _schema,
  template = 'simple',
  templateProps: _templateProps,
  sanitizer,
  onSubmit: _onSubmit,
  onSubmitFinished: _onSubmitFinished,
  onReset: _onReset,
  onMount: _onMount,
  onUnmount: _onUnmount,
  onFormApi: _onFormApi,
  initialValues,
  componentMapper: _componentMapper = {},
  validatorMapper: _validatorMapper = {},
  actionMapper: _actionMapper = {},
  onValuesChanging,
  onValuesChanged,
  onSanitizedValuesChanged,
  onInit,
  isLoading,
  errorMessage,
  container,
  initialFeedback,
  triggerValidationOnOpen = false
}: FormRendererProps<TInputContract, TOutputContract, TFormContract, TFormFieldValues>) {
  const hasTriggeredInitalValidationRef = useRef<boolean>(false);
  const workspaceMappers = useWorkspaceFormMappers();
  const isReady = !isLoading;
  const [_formApi, _setFormApi] = useState<EnhancedFormOptions<TFormFieldValues> | null>(null);
  const setFormApi = useCallback(
    (formApi: EnhancedFormOptions<TFormFieldValues>) => {
      _setFormApi(formApi);
      _onFormApi?.(formApi);
    },
    [_onFormApi]
  );

  /**
   * Deep memoize the template props as they often get passed in as an unstable object <FormRenderer templateProps={{}} />
   */
  const templateProps = useDeepMemo(() => {
    return _templateProps ?? {};
  }, [_templateProps]);

  /**
   * Apply condition mapper to the schema
   */
  const schema = useMemo(() => {
    const conditionMapper = workspaceMappers.conditionMapper;
    if (!conditionMapper) return _schema;
    const nextSchema = traverseAndApplyTransformationToObject(
      structuredClone(_schema),
      (maybeField: Field) => {
        return 'condition' in maybeField && typeof maybeField.condition === 'string';
      },
      (field: Field) => {
        const condition = field?.condition as string | undefined;
        if (!condition) return field;
        const conditionFromMapper = conditionMapper[condition];
        if (!conditionFromMapper) {
          console.error(`Condition "${conditionFromMapper}" not found in the condition mapper.`);
          return field;
        }
        return {
          ...field,
          condition: conditionFromMapper
        };
      }
    );
    return nextSchema;
  }, [_schema, workspaceMappers.conditionMapper]);

  /**
   * Wrap the form template to inject the formApi
   */
  const WrappedFormTemplate = useCallback(
    (props: FormTemplateRenderProps) => {
      if (template === undefined) {
        throw new Error('Please provide a form template renderer function.');
      }

      const FormTemplate = FORM_BUILDER_TEMPLATE_MAPPER[template];

      if (FormTemplate === undefined) {
        throw new Error(`FormTemplate "${template}" is not defined.`);
      }

      return (
        <FormBuilderTemplateWrapper<TInputContract, TOutputContract, TFormFieldValues>
          sanitizer={sanitizer}
          setFormApi={setFormApi}
          container={container}
          schema={schema}
          onValuesChanging={onValuesChanging}
          onValuesChanged={onValuesChanged}
          onSanitizedValuesChanged={onSanitizedValuesChanged}
          formId={formId}
          formType={formType}
          formSaveType={formSaveType}
          initialFeedback={initialFeedback}
        >
          <FormTemplate {...props} {...templateProps} />
        </FormBuilderTemplateWrapper>
      );
    },
    [
      template,
      sanitizer,
      setFormApi,
      container,
      schema,
      onValuesChanging,
      onValuesChanged,
      onSanitizedValuesChanged,
      formId,
      formType,
      formSaveType,
      templateProps,
      initialFeedback
    ]
  );

  /**
   * Combine default mappers with mappers from workspace & props
   */
  const mappers = useMemo(() => {
    const componentMapper = {
      ...FORM_COMPONENT_MAPPER,
      ...workspaceMappers.componentMapper,
      ..._componentMapper
    } as DddfFormRendererProps['componentMapper'];

    const validatorMapper = {
      ...FORM_VALIDATOR_MAPPER,
      ...workspaceMappers.validatorMapper,
      ..._validatorMapper
    } as DddfFormRendererProps['validatorMapper'];

    const actionMapper = {
      ...FORM_ACTION_MAPPER,
      ...workspaceMappers.actionMapper,
      ..._actionMapper
    } as DddfFormRendererProps['actionMapper'];

    return { componentMapper, validatorMapper, actionMapper };
  }, [_actionMapper, _componentMapper, _validatorMapper, workspaceMappers]);

  /**
   * Submit handler
   */
  // eslint-disable-next-line require-await
  const onSubmit = useCallback(
    async (values: AnyRecord): Promise<SubmissionErrors | void> => {
      // Skip submitting if the form is running initial validation
      if (hasTriggeredInitalValidationRef.current === false && triggerValidationOnOpen) {
        return;
      }

      if (!_onSubmit) return {};
      _onSubmit?.(values as TFormFieldValues)?.catch((e) => {
        console.error(e);
      });

      const submissionResult = (await firstValueFrom(
        formRendererEvent$.pipe(
          filter((e) => e.type === 'SUBMISSION_RESULT' && e.meta.formId === formId),
          timeout(60_000)
        )
      )) as FormRendererEventSubmissionResult<TOutputContract, TFormFieldValues>;

      if (submissionResult.payload.isSuccessful === false) {
        return submissionResult.payload.result || {};
      }

      const result = _onSubmitFinished?.(submissionResult.payload.result, {
        output: submissionResult.payload.output,
        formValues: submissionResult.payload.formValues
      });
      if (isPromiseLike(result)) {
        await result;
      }
      return submissionResult;
    },
    [_onSubmit, _onSubmitFinished, formId, triggerValidationOnOpen]
  );

  /**
   * Mount and unmount lifecycle
   */
  useEffect(() => {
    _onMount?.();
    return () => {
      _onUnmount?.();
    };
  }, [_onMount, _onUnmount]);

  /**
   * Init lifecycle
   */
  useEffect(() => {
    let unsubscribe: undefined | (() => void);
    async function init() {
      if (!_formApi) return;

      // Trigger validation on open (only once)
      if (triggerValidationOnOpen && hasTriggeredInitalValidationRef.current === false) {
        // Trigger validation and preserve invoking actual submit handler
        await _formApi.submit();

        // Set flag to use the actual submit handler on next (users) submit
        hasTriggeredInitalValidationRef.current = true;
      }

      if (isReady && onInit) {
        unsubscribe = await onInit(_formApi);
      }
    }

    init().catch((e) => {
      console.error(e);
    });

    return () => {
      unsubscribe?.();
    };
  }, [isReady, onInit, _formApi, container, triggerValidationOnOpen]);

  /**
   * Loading state
   */
  if (isLoading) {
    return <Spinner fillArea>Initializing form...</Spinner>;
  }

  /**
   * Error state
   */
  if (errorMessage) {
    return <Center fillArea>Error: {errorMessage}</Center>;
  }

  return (
    <DddfFormRenderer
      initialValues={initialValues}
      onSubmit={onSubmit}
      schema={schema as Schema}
      FormTemplate={WrappedFormTemplate}
      subscription={{ values: true }} // actions wont re-render by default unless on blur which can cause issues when you want to disable or reset form values.
      {...mappers}
    />
  );
}

export function FormRenderer<
  TInputContract extends AnyRecord = AnyRecord,
  TOutputContract extends AnyRecord = AnyRecord,
  TFormContract extends FormContract<TOutputContract, Record<string, AnyFieldDefinition>> = FormContract<
    TOutputContract,
    Record<string, AnyFieldDefinition>
  >,
  TFormFieldValues extends AnyRecord = InferFormValuesFromFormContract<TFormContract>
>(props: FormRendererProps<TInputContract, TOutputContract, TFormContract, TFormFieldValues>) {
  return useDeepMemo(
    () => <FormRendererBase<TInputContract, TOutputContract, TFormContract, TFormFieldValues> {...props} />,
    [props]
  );
}
