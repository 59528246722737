import type { RxJsonSchema, RxCollection } from 'rxdb-oms-app';
import type { MemoryDatabaseCollections } from '../collections';

export const completedMigrationsSchema: RxJsonSchema<CompletedMigrationsDocType> = {
  title: 'Completed Migrations schema',
  description: 'Stores completed migrations',
  version: 0,
  keyCompression: false,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 128
    },
    documentCollectionName: {
      type: 'string',
      maxLength: 128
    },
    documentPrimaryKey: {
      type: 'string',
      maxLength: 128
    }
  },
  required: []
} as const;

export const COMPLETED_MIGRATIONS_COLLECTION = {
  schema: completedMigrationsSchema
};

export type CompletedMigrationsDocType = {
  id: string;
  documentCollectionName: keyof MemoryDatabaseCollections;
  documentPrimaryKey: string;
};

export type CompletedMigrationsCollection = RxCollection<CompletedMigrationsDocType, {}, {}>;
