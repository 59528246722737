import React, { useEffect, useMemo } from 'react';
import type { ICellRendererParams } from '@ag-grid-community/core';
import { exists, t } from 'i18next';
import { format } from '@oms/ui-design-system';
import { FlexibleContentRenderer, useFlexibleContent } from './flexible-content.utils';
import { EnumKey } from '@oms/frontend-foundation';

export const FLEXIBLE_CONTENT_RENDERER = 'FlexibleContentCellRenderer';

type CellFormatterResult = {
  longText: string;
  shortText: string;
  tooltip?: string;
};

type CellFormatterFn = (value: string) => CellFormatterResult;

const limitPriceFormatter = (value: string): CellFormatterResult => {
  if (!value || value === 'MARKET') {
    return { longText: 'Market', shortText: 'MKT' };
  }

  const formattedValue = format('price', value);

  return { longText: formattedValue, shortText: formattedValue, tooltip: formattedValue };
};

const ownerFormatter = (value: string): CellFormatterResult => {
  if (!value) {
    return { longText: '', shortText: '' };
  }

  const shortText = typeof value === 'string' ? getInitials(value) : '';

  return { longText: value, shortText, tooltip: value };
};

const customFormatterMap: Partial<Record<FormatterKey, CellFormatterFn>> = {
  limitPrice: limitPriceFormatter,
  owner: ownerFormatter
};

function getInitials(name: string) {
  return name
    .split(' ') // Split the name by spaces
    .filter((part) => part !== '') // Remove any empty parts (extra spaces)
    .map((part) => part[0].toUpperCase()) // Take the first letter and capitalize it
    .join(''); // Join the initials into a string
}

const getCellText = (
  formatterKey: FormatterKey,
  value: string
): { longText: string; shortText: string; tooltip?: string } => {
  const getEnumTranslation = (ns: 'short' | 'long') => {
    const path = `app.enums.${formatterKey}.${value}`;

    return exists(path, { ns }) ? t(path, { ns }) : value;
  };

  const customFormatterFn = customFormatterMap[formatterKey];

  if (customFormatterFn) {
    return customFormatterFn(value);
  }

  const shortText = getEnumTranslation('short');
  const longText = getEnumTranslation('long');

  return { shortText, longText };
};

export type FormatterKey = EnumKey | 'yesNo' | 'limitPrice' | 'owner';

type FlexibleEnumParams = {
  value: string;
  formatterKey: FormatterKey;
};

export const FlexibleContentCellRenderer: React.FC<
  Omit<ICellRendererParams, 'value'> & FlexibleEnumParams
> = ({ value, formatterKey }) => {
  const { containerRef, hiddenRef, containerWidth, displayState, checkTextWidthAndSetContent } =
    useFlexibleContent();

  const { longText, shortText, tooltip } = useMemo(() => {
    return getCellText(formatterKey, value);
  }, [formatterKey, value]);

  useEffect(() => {
    checkTextWidthAndSetContent(longText, shortText, tooltip);
  }, [value, containerWidth]);

  return (
    <FlexibleContentRenderer
      containerRef={containerRef}
      hiddenElementRef={hiddenRef}
      content={displayState.content}
      tooltip={displayState.tooltip}
    />
  );
};
