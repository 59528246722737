import type { ComponentType } from 'react';
import { withUpdatedData } from './with-updated-data';
import type { UpdatedData, UpdatedDataProps } from './with-updated-data';
import { TsTradesWithFilterDocument } from '@oms/generated/frontend';
import type { TradeRow } from '@oms/generated/frontend';
import {
  TradesSubscriptionKeys,
  TradesSubscriptionMap
} from '@app/widgets/trading/trade-monitor/trade-monitor.widget';

export type UpdatedTrade = UpdatedData<TradeRow>;

export const withUpdatedTrade = (Component: ComponentType<UpdatedTrade>, props: UpdatedDataProps = {}) => {
  return withUpdatedData<
    Exclude<TradesSubscriptionKeys, 'trading_order_group'>,
    Omit<TradesSubscriptionMap, 'trading_order_group'>,
    TradeRow
  >(Component, {
    id: props.idProp || 'id',
    sx: props.sx,
    config: {
      trading_order_rows: {
        query: TsTradesWithFilterDocument,
        getData: (r) => r.tsTradesWithFilter
      }
    },
    result(response) {
      return (response.rows || [])?.[0] || {};
    },
    queryOpts(tradeId) {
      return {
        limit: 1,
        offset: 0,
        filter: {
          id: {
            filterType: 'text',
            type: 'contains',
            filter: tradeId
          }
        }
      };
    }
  });
};
