import type { DependencyContainer } from 'tsyringe';
import { useCallback } from 'react';
import { useAppWorkspace, useCurrentAppWindow } from '@app/common/workspace/workspace.hooks';
import type { FetchResult } from '@apollo/client';
import { InvestorOrdersService } from '@app/data-access/services/trading/investor-orders/investor-orders.service';
import { openConfirmation } from '@app/generated/sdk';
import { t } from '@oms/codegen/translations';
import type {
  CancelInvestorOrdersInput,
  CancelInvestorOrdersMutation,
  FeedbackWrapper
} from '@oms/generated/frontend';
import { convertAlert, useWorkspaceContainer } from '@oms/frontend-foundation';
import type { AllGQLResponseErrors, FoundationWorkspace } from '@oms/frontend-foundation';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { cleanMaybe, Result } from '@oms/ui-util';
import { Optional } from '@oms/ui-util';

type CancelOrdersErrorReason = 'User canceled' | 'No orders to cancel';

export class CancelOrdersError extends Error {
  constructor(reason: CancelOrdersErrorReason) {
    super(reason);
    Object.setPrototypeOf(this, CancelOrdersError.prototype);
  }
}

export interface IOpenCancelInvestorOrderDialogOptions {
  workspace: FoundationWorkspace;
  container: DependencyContainer;
  windowId: string;
  investorOrderIds: string[];
  investorOrderService?: InvestorOrdersService;
  errors?: FeedbackWrapper[];
  isRetry?: boolean;
  onStart?: () => void;
  onSuccess?: (result: FetchResult<CancelInvestorOrdersMutation>) => void;
  onFailure?: (errors: AllGQLResponseErrors) => void;
}

export const openCancelInvestorOrderDialog = async ({
  workspace,
  container,
  windowId,
  investorOrderIds,
  investorOrderService,
  errors,
  isRetry
}: IOpenCancelInvestorOrderDialogOptions): Promise<
  Result<Optional<CancelInvestorOrdersMutation>, CancelOrdersError>
> => {
  const orderService = investorOrderService || container.resolve(InvestorOrdersService);

  const alerts = errors?.map((item) =>
    convertAlert.formValidationAlertItem.item(item).toAlertBannerStackItem()
  );

  const count = investorOrderIds.length;

  const [_, api] = await openConfirmation(workspace, windowId, {
    title: t('app.orders.cancelIODialog.title', { count }),
    componentProps: {
      autoClose: true,
      message: t('app.orders.cancelIODialog.message', { count }),
      confirmButtonText: isRetry ? t('app.common.retry') : t('app.common.yes'),
      cancelButtonText: t('app.common.no'),
      alerts
    }
  });

  const event = await api.awaitFirstEvent;
  switch (event.type) {
    case DIALOG_EVENT_TYPE.OK: {
      // By the time the user clicks past the confirmation dialog, the count of selected rows
      // that are active may have changed.

      const ordersToCancel: CancelInvestorOrdersInput = {
        investorOrderIds
      };

      if (investorOrderIds.length === 0) {
        return Result.failure(new CancelOrdersError('No orders to cancel'));
      }

      const result = await orderService.cancelOrders(ordersToCancel);

      return await result.mapToAsync(
        async ({ data }) => Result.success(cleanMaybe(data)),
        async (errors) => {
          const feedbackWrappers: FeedbackWrapper[] = errors as unknown as FeedbackWrapper[];

          return await openCancelInvestorOrderDialog({
            workspace,
            container,
            windowId,
            investorOrderIds,
            errors: feedbackWrappers,
            isRetry: true
          });
        }
      );
    }
    default: {
      return Result.failure(new CancelOrdersError('User canceled'));
    }
  }
};

export const useOpenCancelInvestorOrderDialog = (id: string) => {
  const workspace = useAppWorkspace();
  const container = useWorkspaceContainer();
  const windowActor = useCurrentAppWindow();

  return useCallback(() => {
    openCancelInvestorOrderDialog({
      workspace: workspace,
      container: container,
      windowId: windowActor.id,
      investorOrderIds: [id]
    }).catch((e) => {
      console.error(e);
    });
  }, [workspace, id, container, windowActor.id]);
};
