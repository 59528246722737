import { Observable, map } from 'rxjs';
import { compactMap } from '@oms/ui-util';
import type { AdvancedSelectQueryFn } from '@oms/frontend-foundation';
import type { ComboBoxItem } from '@oms/ui-design-system';
import UsersService from '@app/data-access/services/reference-data/users/users.service';
import type { RouteOrderFormValues } from '../route-order.form-contract';

export const createRoutableUserComboboxItem = <
  T extends { id: string; email?: string; avatar?: string; label: string }
>(
  user: T
): ComboBoxItem<string> => {
  const { id, email, avatar, label } = user;
  const labelVal: string = 'label' in user && user.label ? user.label || '' : label || '';
  return {
    type: 'item',
    id: id || '',
    showAvatar: !!avatar,
    avatarSrc: avatar,
    label: labelVal,
    sublabel: email,
    value: id || ''
  };
};

const watchRoutableUsersQuery: AdvancedSelectQueryFn = (container) => {
  const usersService = container.resolve(UsersService);

  return {
    type: 'watchAll',
    query: ({ form }) => {
      const values: Partial<RouteOrderFormValues> = form.getState().values || {};
      const { hiddenMode } = values;
      const investorOrderId = hiddenMode?.type === 'route' ? hiddenMode.investorOrderId : undefined;

      if (!investorOrderId) {
        return new Observable((observer) => {
          observer.next({
            isFetching: false,
            error: undefined,
            results: []
          });
          observer.complete();
        });
      }

      return usersService.watchForRoutableUsers$(investorOrderId).pipe(
        map(({ results: _results, isFetching, error }) => {
          const results = _results
            ? compactMap(_results, (user) => createRoutableUserComboboxItem(user))
            : [];

          return {
            isFetching,
            error,
            results
          };
        })
      );
    }
  };
};

export default watchRoutableUsersQuery;
