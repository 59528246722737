import type { Optional } from '@oms/ui-util';
import type { SummaryGridField } from '@oms/frontend-foundation';
import { getOrderSideClassName, isOrderInactive } from '@oms/frontend-foundation';
import type { TradeRow } from '@oms/generated/frontend';
import { mapSettleType } from '../mappers/map-settle-type';
import { get } from 'lodash';
import { translateEnum } from '../mappers/translate-enum';
import type { FieldMetadata } from './common.types';

type TradeFieldMetadata = FieldMetadata<TradeRow>;

export const TRADE_DETAILS_FIELDS_MAP: Partial<Record<keyof TradeRow, TradeFieldMetadata>> = {
  capacity: { label: 'Capacity' },
  createdTime: { label: 'Created time', format: 'datetime' },
  enteredBy: { label: 'Entered by' },
  id: { label: 'Trade ID', format: 'text' },
  instrumentDisplayCode: { label: 'Instrument' },
  lastMarket: { label: 'Last market' },
  lastTradeTime: { label: 'Trade datetime', format: 'datetime' },
  locate: { label: 'Locate' },
  normalizedLiquidityIndicator: { label: 'Normalized liquidity indicator' },
  owner: { label: 'Owner' },
  price: { label: 'Trade price', format: 'price' },
  quantity: { label: 'Quantity', format: 'quantity' },
  rawLiquidityIndicator: { label: 'Raw liquidity indicator' },
  settleDate: { label: 'Settle date' },
  settleType: {
    label: 'Settle type',
    mapper: ({ value }) => (typeof value === 'string' ? mapSettleType(value) : '')
  },
  side: {
    label: 'Side',
    mapper: ({ value }) => (typeof value === 'string' ? translateEnum('side', value) : ''),
    classNameMapper: ({ value, data }) =>
      typeof value === 'string' ? getOrderSideClassName(value, { isInactive: isOrderInactive(data) }) : ''
  },
  status: { label: 'Status' },
  tradeContraAccount: { label: 'Trade contra account' },
  tradeContraAccountName: { label: 'Trade contra account name' },
  tradeCounterParty: { label: 'Trade counterparty' },
  tradingAccount: { label: 'Trading account' },
  tradingAccountName: { label: 'Account' },
  traderByName: { label: 'Trader' },
  tradingOrderId: { label: 'Trading order ID', format: 'text' },
  updatedTime: { label: 'Updated datetime', format: 'datetime' }
};

export type TradeSummaryFieldName = keyof typeof TRADE_DETAILS_FIELDS_MAP;

const tradeSummaryFields = (
  fieldNames: TradeSummaryFieldName[],
  trade: Partial<TradeRow>
): SummaryGridField[] => {
  return fieldNames.map((fieldName) => {
    const { label, format, classNameMapper } = (TRADE_DETAILS_FIELDS_MAP[fieldName] ||
      {}) as Partial<TradeFieldMetadata>;
    const value = get(trade, fieldName, '');
    return {
      label: label || fieldName,
      fieldName,
      format,
      className: classNameMapper ? classNameMapper({ value, data: trade }) : undefined
    };
  });
};

const tradeSummaryValues = (fieldNames: TradeSummaryFieldName[], trade: Partial<TradeRow>) => {
  return [
    fieldNames.reduce(
      (acc, fieldName) => {
        const fieldDef = TRADE_DETAILS_FIELDS_MAP[fieldName] as Partial<TradeFieldMetadata>;
        const { mapper } = fieldDef;
        // Using lodash's get instead of reimplementing nested fields with . again
        const value = get(trade, fieldName, undefined);
        if (value === null || value === undefined) {
          return { ...acc, [fieldName]: undefined };
        }
        const mappedValue = (mapper ? mapper({ value, data: trade }) : undefined) || String(value);
        return { ...acc, [fieldName]: mappedValue };
      },
      {} as Partial<Record<TradeSummaryFieldName, Optional<string>>>
    )
  ];
};

export const tradeSummaryData = (
  label: string,
  fields: (keyof typeof TRADE_DETAILS_FIELDS_MAP)[],
  trade: Partial<TradeRow>
) => {
  return {
    label,
    value: tradeSummaryValues(fields, trade),
    fields: tradeSummaryFields(fields, trade)
  };
};
