import { inject, singleton } from 'tsyringe';
import { Logger } from '@oms/ui-util';
import { testScoped } from '@app/workspace.registry';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import {
  DismissNotificationDocument,
  DismissNotificationMutation,
  DismissNotificationMutationVariables,
  DismissNotificationsDocument,
  DismissNotificationsMutation,
  DismissNotificationsMutationVariables,
  DismissNotificationsPrimaryAlertType
} from '@oms/generated/frontend';
import { NOTIFICATION_VISIBILITY, NotificationVisibility } from '@app/notifications/notifications.contracts';

@testScoped
@singleton()
export class NotificationsService {
  protected name: string = 'NotificationsService';
  protected logger: Logger;

  constructor(
    @inject(GQLResponse) private gqlResponse: GQLResponse,
    @inject(AuthService) protected authService: AuthService
  ) {
    this.logger = Logger.named(this.name);
  }

  public dismissNotification(notificationId: string) {
    const mutation = this.gqlResponse.wrapMutate<
      DismissNotificationMutation,
      DismissNotificationMutationVariables
    >({
      mutation: DismissNotificationDocument,
      variables: {
        notificationInput: {
          notificationId: notificationId
        }
      }
    });

    return mutation.awaitAsyncResponse().exec();
  }

  public dismissNotifications(visibility: NotificationVisibility) {
    const mutation = this.gqlResponse.wrapMutate<
      DismissNotificationsMutation,
      DismissNotificationsMutationVariables
    >({
      mutation: DismissNotificationsDocument,
      variables: {
        notificationsInput: {
          primaryAlertType: this.getAlertType(visibility)
        }
      }
    });

    return mutation.awaitAsyncResponse().exec();
  }

  // convert NotificationVisibility to DismissNotificationsPrimaryAlertType for mutation input.
  getAlertType(visibility: NotificationVisibility): DismissNotificationsPrimaryAlertType {
    switch (visibility) {
      case NOTIFICATION_VISIBILITY.MINE:
        return DismissNotificationsPrimaryAlertType.MyAlert;
      case NOTIFICATION_VISIBILITY.TEAM:
        return DismissNotificationsPrimaryAlertType.TeamAlert;
      case NOTIFICATION_VISIBILITY.ALL:
        return DismissNotificationsPrimaryAlertType.AllAlert;
      default:
        this.logger.error('Unknown visibility');
        return DismissNotificationsPrimaryAlertType.MyAlert;
    }
  }
}
