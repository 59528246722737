import { useRef, useEffect } from 'react';
import { useCurrentActorContext } from '@valstro/workspace-react';
import type { AppWindowActorSchema } from '@app/app-config/workspace.config';
import { isTauri } from '@valstro/workspace';
import { COMMON_POPOVER } from '@valstro/workspace-plugin-popover';
import { COMMON_COMMAND_PALETTE } from '@app/common/command-palette/command-palette.contracts';
import { COMMON_AUTH_WINDOW } from '@app/common/auth/auth.contracts';
import { useWorkspace } from '@valstro/workspace-react';

const ACTOR_ID_TO_IGNORE = [
  String(COMMON_POPOVER.ID_BASE),
  String(COMMON_COMMAND_PALETTE.BASE_ID),
  String(COMMON_AUTH_WINDOW.ID)
];

/**
 * Use tauri click away on window blur
 *
 * This hook will dispatch a click away event when the window is blurred
 * This is useful for closing popovers when the window is clicked away from
 */
export function useTauriClickAwayOnWindowBlur(actorId: string): void {
  const workspace = useWorkspace();
  const previousIsFocused = useRef(false);
  const context = useCurrentActorContext<AppWindowActorSchema>();
  const isFocused = context?.isFocused ?? false;

  useEffect(() => {
    if (
      isTauri() &&
      previousIsFocused.current &&
      !isFocused &&
      actorId !== workspace.getLeaderProcessId() &&
      !ACTOR_ID_TO_IGNORE.some((id) => actorId.startsWith(id))
    ) {
      clearRadixPopover();
      clearAGGridContextMenu();
    }

    previousIsFocused.current = isFocused;
  }, [isFocused, actorId, workspace]);
}

/**
 * Clear Radix popover
 *
 * This function will dispatch a pointerdown and pointerup event to clear the Radix popover
 */
function clearRadixPopover() {
  const pointerDownEvent = new PointerEvent('pointerdown', {
    bubbles: true,
    cancelable: true,
    composed: true,
    pointerId: 1,
    pressure: 1,
    clientX: 0,
    clientY: 0
  });

  document.documentElement.dispatchEvent(pointerDownEvent);

  const pointerUpEvent = new PointerEvent('pointerup', {
    bubbles: true,
    cancelable: true,
    composed: true,
    pointerId: 1,
    pressure: 0,
    clientX: 0,
    clientY: 0
  });

  document.documentElement.dispatchEvent(pointerUpEvent);
}

/**
 * Clear AG Grid context menu
 *
 * This function will dispatch a mousedown and mouseup event to clear the AG Grid context menu
 */
function clearAGGridContextMenu() {
  // Create a temporary element outside the AG Grid area (this is required for AG Grid to register the event)
  const tempElement = document.createElement('div');
  tempElement.style.position = 'fixed';
  tempElement.style.top = '-100px';
  tempElement.style.left = '-100px';
  document.body.appendChild(tempElement);

  try {
    const mouseDownEvent = new MouseEvent('mousedown', {
      bubbles: true,
      cancelable: true,
      view: window
    });

    tempElement.dispatchEvent(mouseDownEvent);

    const mouseUpEvent = new MouseEvent('mouseup', {
      bubbles: true,
      cancelable: true,
      view: window
    });

    tempElement.dispatchEvent(mouseUpEvent);

    const clickEvent = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    });

    tempElement.dispatchEvent(clickEvent);
  } finally {
    document.body.removeChild(tempElement);
  }
}
