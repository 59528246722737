import React, { type FC, memo, useRef, useMemo, type CSSProperties } from 'react';
import { Flex, HStack, Button, type Sprinkles, type FlexProps } from '@oms/ui-design-system';
import { useFormApi, type FormTemplateRenderProps, FormSpy } from '@data-driven-forms/react-form-renderer';
import { FormBuilderFeedback } from '../../helpers/form-builder-feedback/form-builder-feedback';
import { useFormBuilderTemplate } from '../common/form-builder-template.context';
import { useCurrentWindow } from '@valstro/workspace-react';

export type UniqueFormBuilderSimpleTemplateProps = {
  testid?: string;
  submitLabel?: string;
  showCancelButton?: boolean;
  showResetButton?: boolean;
  showSubmitButton?: boolean;
  resetLabel?: string;
  /**
   * Defaults to `true`
   * If set to `false`, the form feedback will not be shown and you'll need to use FORM_BUILDER_FEEDBACK field component to show feedback
   */
  showFormFeedback?: boolean;
  shouldFormFeedbackGrowWindow?: boolean;
  hideScrollbar?: boolean;
  sx?: Sprinkles;
  formFieldsSx?: Sprinkles;
  toolbarSx?: Sprinkles;
  as?: React.ElementType;
} & FormTemplateRenderProps;

export type FormBuilderSimpleTemplateProps = UniqueFormBuilderSimpleTemplateProps & FormTemplateRenderProps;

export const FormBuilderSimpleTemplate: FC<FormBuilderSimpleTemplateProps> = memo(
  ({
    testid = '',
    submitLabel = 'Submit',
    showCancelButton = false,
    showResetButton = false,
    showSubmitButton = true,
    resetLabel = 'Reset',
    cancelLabel = 'Cancel',
    showFormFeedback = true,
    shouldFormFeedbackGrowWindow = false,
    hideScrollbar,
    formFields,
    sx,
    formFieldsSx,
    toolbarSx,
    as = 'form'
  }) => {
    const formOptions = useFormApi();
    const innerRef = useRef<HTMLDivElement>(null);
    const { formId, formType, initialFeedback } = useFormBuilderTemplate();
    const currentWindow = useCurrentWindow();

    const showToolbar = useMemo(
      () => !!(showResetButton || showSubmitButton || showCancelButton),
      [showResetButton, showSubmitButton, showCancelButton]
    );

    const primarySx = useMemo<Sprinkles>(() => {
      const { position = 'absolute', inset = 0, backgroundColor = 'layout.level1', ...otherSx } = sx ?? {};
      return { position, inset, backgroundColor, ...otherSx };
    }, [sx]);

    const toolbarSxWithDefaults = useMemo<Sprinkles>(() => {
      const {
        marginTop = 'auto',
        alignItems = 'center',
        justifyContent = 'flex-end',
        backgroundColor = 'layout.backdrop',
        flexGrow = 0,
        px = 4,
        py = 4,
        ...otherSx
      } = toolbarSx ?? {};
      return { marginTop, alignItems, justifyContent, backgroundColor, flexGrow, px, py, ...otherSx };
    }, [toolbarSx]);

    const scrollbarStyle = useMemo((): CSSProperties => {
      if (hideScrollbar) return { scrollbarWidth: 'none' };
      return {};
    }, [hideScrollbar]);

    return (
      <Flex
        as={as as unknown as FlexProps['as']}
        role="form"
        noValidate={true}
        onSubmit={(event) => {
          event.preventDefault();
          formOptions.handleSubmit()?.catch((e) => {
            console.error(e);
          });
        }}
        data-id={testid || formId}
        data-testid={testid || formId}
        aria-label={`${formType ? `${formType} ` : ''}form`}
        direction="column"
        sx={primarySx}
      >
        <Flex
          direction="column"
          sx={{ flexGrow: 1, overflowY: 'scroll' }}
          style={scrollbarStyle}
          ref={innerRef}
        >
          {showFormFeedback === true && (
            <FormBuilderFeedback
              initialFeedback={initialFeedback}
              shouldGrowWindow={shouldFormFeedbackGrowWindow}
              sx={{
                p: 1,
                pb: 0
              }}
            />
          )}
          <Flex direction="column" sx={{ px: 1, py: 1, ...formFieldsSx }}>
            {formFields as unknown as React.ReactElement[]}
          </Flex>
        </Flex>

        {showToolbar && (
          <Flex sx={toolbarSxWithDefaults}>
            <FormSpy>
              {({ submitting, validating, pristine, form: { reset }, values: _values }) => (
                <HStack spacing={4}>
                  {showResetButton && (
                    <Button
                      type="button"
                      variant="secondary"
                      size="md"
                      disabled={pristine}
                      onClick={() => {
                        formOptions.onReset && formOptions.onReset();
                        reset();
                      }}
                    >
                      {resetLabel}
                    </Button>
                  )}

                  {showCancelButton && (
                    <Button
                      type="button"
                      variant="secondary"
                      size="md"
                      onClick={() => {
                        currentWindow.operations.close().catch((e) => console.error(e));
                      }}
                    >
                      {cancelLabel}
                    </Button>
                  )}

                  {showSubmitButton && (
                    <Button
                      type="submit"
                      variant="primary"
                      size="md"
                      isLoading={submitting}
                      isDisabled={
                        submitting ||
                        validating /* || getState().invalid - note: not using this as we decided Submit should always be clickable (with errors) */
                      }
                    >
                      {submitLabel}
                    </Button>
                  )}
                </HStack>
              )}
            </FormSpy>
          </Flex>
        )}
      </Flex>
    );
  }
);
