import type { ICellRendererParams } from '@ag-grid-community/core';
import { format, Text } from '@oms/ui-design-system';
import { priceDisplayStyle } from '@oms/ui-design-system-css';
import { useMemo } from 'react';

export const PNL_CELL_RENDERER = 'PnlCellRenderer';

export const PnlCellRenderer: React.FC<ICellRendererParams> = ({ value }) => {
  const className = useMemo(() => {
    if (typeof value !== 'number') {
      return undefined;
    }
    if (value > 0) {
      return priceDisplayStyle({ type: 'positive' });
    }
    if (value < 0) {
      return priceDisplayStyle({ type: 'negative' });
    }
    return undefined;
  }, [value]);

  const formattedValue = useMemo(() => {
    if (typeof value !== 'number') {
      return '';
    }
    return format('whole-number', value);
  }, [value]);

  return <Text className={className}>{formattedValue}</Text>;
};
