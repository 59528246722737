import { Box, ExclamationTriangleIcon, Text, Button, ButtonProps } from '@oms/ui-design-system';
import { FC, PropsWithChildren } from 'react';

export type TableServerConnectionLossProps = {
  onTryAgain: ButtonProps['onClick'];
  isReconnecting?: boolean;
  unstableConnection?: boolean;
};

export const TableServerConnectionLoss: FC<PropsWithChildren<TableServerConnectionLossProps>> = ({
  isReconnecting,
  onTryAgain,
  unstableConnection,
  children
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 'full' }}>
      {unstableConnection && (
        <Box
          data-testid="table-server-conn-loss-banner"
          role="alert"
          aria-live="assertive"
          aria-label="grid connection lost"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
            backgroundColor: 'layout.error',
            padding: 2
          }}
        >
          <Box sx={{ marginRight: 2, textAlign: 'center' }}>
            <ExclamationTriangleIcon sx={{ color: 'icons.error' }} />
          </Box>
          <Text
            as={'p'}
            type={'smallB'}
            sx={{ color: 'text.primary', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            Connection lost, attempting to reconnect...
          </Text>
          <Button variant="link" size="sm" onClick={onTryAgain} isDisabled={isReconnecting}>
            Try again
          </Button>
        </Box>
      )}
      {children}
    </Box>
  );
};
