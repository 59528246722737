import { InvestorOrdersService } from '@app/data-access/services/trading/investor-orders/investor-orders.service';
import { TradingOrdersService } from '@app/data-access/services/trading/trading-orders/trading-orders.service';
import type {
  RouteOrderFormInput,
  RouteOrderFormInfo
} from '@app/widgets/trading/route-order/route-order.form-common';
import type { RouteOrderFormValues } from '@app/widgets/trading/route-order/route-order.form-contract';
import { DestinationType, OrderType, PriceOptions, TimeInForce } from '@oms/generated/frontend';
import type {
  VisibleInvestorOrderInfoWithAllocationsFragment as IOFragment,
  VisibleTradingOrderInfoWithAllocationsFragment as TOFragment,
  InvestorAccount
} from '@oms/generated/frontend';
import { convertToNumber, formatNumberUtil } from '@oms/ui-util';
import type { DependencyContainer } from 'tsyringe';
import { createStrategyFieldValue } from './fixatdl-strategy-field/fixatdl-strategy-field.util';
import type { PriceInputValue } from '@oms/frontend-foundation';
import type { Optional } from '@oms/ui-util';
import { getDurationInMMSS } from '@app/forms/common/validators/validate-duration-input/validate-duration-input.validator';

export const handleNonNumericLimitPrice = (price: PriceInputValue): Optional<PriceOptions> => {
  if (PriceOptions[price as keyof typeof PriceOptions]) {
    return price as PriceOptions;
  }
  return undefined;
};

/**
 * Fetch Order Info from IDs provided in input
 * - Fetch Investor Order (investorOrderId)
 * - Fetch Trading Order (tradingOrderId)
 * - Create Trading Order (no IDs provided)
 *
 * @param input - RouteOrderFormInput
 * @param container - DependencyContainer
 * @returns RouteOrderFormInfo
 */
export async function getOrderInfoFromInput(
  input: RouteOrderFormInput,
  container: DependencyContainer
): Promise<RouteOrderFormInfo> {
  const investorOrderService = container.resolve(InvestorOrdersService);
  const tradingOrderService = container.resolve(TradingOrdersService);

  const mode = input.mode || { type: 'create' };

  let investorOrder: IOFragment | undefined;
  let tradingOrder: TOFragment | undefined;

  switch (mode.type) {
    case 'route': {
      if (!mode.investorOrderId) throw new Error('Investor Order ID is required');
      const result = await investorOrderService.getById(mode.investorOrderId);
      if (result.isSuccess() && result.value.data.visibleInvestorOrder) {
        investorOrder = result.value.data.visibleInvestorOrder as IOFragment;
        return {
          type: 'route',
          investorOrder
        };
      } else if (result.isFailure()) {
        console.error(result.errors);
        throw new Error('Failed to get investor order');
      }
      break;
    }
    case 'modify': {
      if (!mode.tradingOrderId) throw new Error('Trading Order ID is required');

      const result = await tradingOrderService.getById(mode.tradingOrderId);
      if (result.isSuccess() && result.value.data.visibleTradingOrder) {
        tradingOrder = result.value.data.visibleTradingOrder as TOFragment;
        return {
          type: 'modify',
          tradingOrder,
          location: mode.location
        };
      } else if (result.isFailure()) {
        console.error(result.errors);
        throw new Error('Failed to get investor order');
      }
      break;
    }
    case 'create':
      return {
        type: 'create',
        location: mode.location
      };
  }

  return {
    type: 'create'
  };
}

/**
 * Get Initial Common Route & Modify Field Values based on Order/Trading Info
 *
 * @param inputs - InitialOrderBasedFieldInputs
 * @returns Partial<RouteOrderFormValues>
 */
export function getInitialCommonRouteModifyFieldValues<
  T extends (IOFragment | TOFragment) & { tradingAccount?: InvestorAccount | null }
>(orderInfo: T): Partial<RouteOrderFormValues> {
  const {
    sideType,
    instrument,
    limitPrice,
    quantity,
    timeInForce,
    gtdTimestamp,
    tradeCurrency,
    settleDate,
    settleType,
    customerNotes,
    orderTags,
    locate,
    ...rest
  } = orderInfo;
  const formValues: Partial<RouteOrderFormValues> = {};
  formValues.sideType = sideType ? sideType : undefined;
  formValues.limitPrice =
    typeof limitPrice === 'number' && limitPrice !== 0 ? formatNumberUtil(limitPrice) : PriceOptions.Market;
  const orderQuantity = 'openQuantity' in rest ? rest.openQuantity : quantity;
  formValues.quantity = typeof orderQuantity === 'number' ? formatNumberUtil(orderQuantity) : undefined;
  formValues.timeInForce = {
    id: timeInForce || TimeInForce.Day,
    subValue:
      timeInForce === TimeInForce.Gtd
        ? gtdTimestamp
        : timeInForce === TimeInForce.Duration && !!('tifDuration' in rest)
          ? getDurationInMMSS(rest.tifDuration as number)
          : undefined
  };
  formValues.instrument = instrument ? { id: instrument.id } : undefined;
  formValues.tradeCurrency = tradeCurrency ? { id: tradeCurrency } : undefined;
  formValues.customerNotes = customerNotes ? customerNotes : undefined;
  formValues.settlementType = settleType ? settleType : undefined;
  formValues.settlementDate = settleDate ? String(settleDate) : undefined;
  formValues.orderTags = orderTags ? orderTags.map((tag) => ({ id: tag?.id || '' })) : undefined;
  formValues.locate = locate || undefined;
  formValues.firmAccount = rest.tradingAccount ? { id: rest.tradingAccount.id } : undefined;

  return formValues;
}

/**
 * Get Initial Modify-Only Field Values based on Trading Order Info
 *
 * @param tradingOrder - VisibleTradingOrderInfoWithAllocationsFragment
 * @returns Partial<RouteOrderFormValues>
 */
export function getInitialModifyOnlyFieldValues(
  orderInfo: RouteOrderFormInfo
): Partial<RouteOrderFormValues> {
  if (orderInfo.type !== 'modify') {
    throw new Error('Invalid order type');
  }
  const routeOrderFormValues: Partial<RouteOrderFormValues> = {};
  const { destinationType, destinationName, venueDestination, traderDestination } = orderInfo.tradingOrder;
  const venueDestinationId = venueDestination?.id;
  const traderDestinationId = traderDestination?.id;

  if (destinationType === DestinationType.PrimaryTrader || destinationType === DestinationType.Trader) {
    // set vene to trader enum
    routeOrderFormValues.venue = {
      id: destinationType
    };
  }

  if (destinationType === DestinationType.Venue && destinationName && venueDestinationId) {
    // set venue to actual venue by looking it up
    routeOrderFormValues.venue = {
      id: venueDestinationId
    };
  }

  if (destinationType === DestinationType.Trader && destinationName && traderDestinationId) {
    // set trader if destination is trader
    routeOrderFormValues.trader = {
      id: traderDestinationId
    };
  }

  const strategy = orderInfo.tradingOrder.strategy;
  const venueId = routeOrderFormValues.venue?.id;

  if (strategy && strategy?.name && strategy?.parameters) {
    routeOrderFormValues.strategy = createStrategyFieldValue(strategy, venueId);
  }

  routeOrderFormValues.venueNotes = orderInfo?.tradingOrder?.venueNotes || '';

  return routeOrderFormValues;
}

/**
 * Sanitize Form Values to Common Output for
 * - Create Trading Order
 * - Modify Trading Order
 * - Route Investor Order
 *
 * @param formValues - RouteOrderFormValues
 * @returns - A common sanitized output for create, modify, and route
 */
export function sanitizeFormValuesToCommonOutput(formValues: RouteOrderFormValues) {
  const {
    instrument: _instrumentComboItemId,
    sideType,
    quantity: _quantity,
    limitPrice: _limitPriceOrMarket,
    timeInForce,
    venue: _venueComboItemId,
    locate,
    orderTags: _orderTags,
    customerNotes: _customerNotes,
    venueNotes
  } = formValues;

  const instrument = _instrumentComboItemId
    ? {
        id: _instrumentComboItemId.id
      }
    : undefined;

  const priceOption = _limitPriceOrMarket ? handleNonNumericLimitPrice(_limitPriceOrMarket) : undefined;

  const limitPriceNumber = _limitPriceOrMarket ? convertToNumber(_limitPriceOrMarket) : 0;
  const limitPrice = limitPriceNumber && limitPriceNumber > 0 ? limitPriceNumber : null;

  const orderType =
    limitPrice || (priceOption && priceOption !== PriceOptions.Market) ? OrderType.Limit : OrderType.Market;

  const quantity = convertToNumber(_quantity);
  // These have to be null otherwise they're not being passed to the mutation
  // and the BE thinks they're unchanged keeping the original values.
  // It’s better to use nulls instead of empty arrays as well as it doesn’t take more space in the DB.
  const orderTags = _orderTags?.map((tag) => ({ id: tag.id })) || null;
  const customerNotes = _customerNotes ?? null;

  const venueDestination = _venueComboItemId
    ? {
        id: _venueComboItemId.id
      }
    : undefined;

  let destinationType: DestinationType | undefined;

  switch (venueDestination?.id) {
    case 'primary-trader':
      destinationType = DestinationType.PrimaryTrader;
      break;
    case 'trader':
      destinationType = DestinationType.Trader;
      break;
    default:
      destinationType = venueDestination?.id ? DestinationType.Venue : undefined;
      break;
  }

  return {
    instrument,
    sideType,
    quantity,
    limitPrice,
    timeInForce,
    orderTags,
    orderType,
    destinationType,
    venueDestination,
    locate,
    customerNotes,
    venueNotes,
    priceOption
  };
}
