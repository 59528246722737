import { toTitleCase } from '../strings/string-utility';
import { AnyKey, AnyRecord } from '../types/objects/object-interface-types';
import { Maybe, Optional, Prefixed } from '../types/shared-util-types';

/**
 * Maps an original array into an object of keys and values.
 *
 * @param array - Any array of values
 * @param key - A callback the derives an object key from the value.
 * @param [transform] - An optional callback that transforms the value.
 * @returns An object mapped from the original array of values
 */
export const mapToObject = <Key extends AnyKey, Value, ResultValue = Value>(
  array: Value[],
  key: (value: Value) => Maybe<Key>,
  transform?: (value: Value) => ResultValue
): Record<Key, ResultValue> => {
  const result: Partial<Record<Key, ResultValue>> = {};
  array.forEach((value) => {
    const k = key(value);
    if (typeof k !== 'undefined' && k !== null)
      result[k] = transform?.(value) ?? (value as unknown as ResultValue);
  });
  return result as Record<Key, ResultValue>;
};

// ---------------------------------------------------------------- /

export const parseJSON = <T>(jsonString: string): T | null => {
  try {
    return JSON.parse(jsonString) as T;
  } catch (_e) {
    return null;
  }
};

const getFromDynamicObject = <R>(
  object: Maybe<Record<string, unknown>>,
  key: string,
  predicate: (value: unknown) => value is R
): Optional<R> => {
  if (typeof object !== 'object' || object === null) return undefined;
  const value = object[key];
  return predicate(value) ? value : undefined;
};

export const getStringFromDynamicObject = (
  object: Maybe<Record<string, unknown>>,
  key: string
): Optional<string> =>
  getFromDynamicObject(object, key, (value): value is string => typeof value === 'string');

/**
 * Creates a function that retrieves the value from a prefixed object using a key and a specified prefix.
 *
 * @template Prefix - The prefix to be added to the key. Defaults to `string`.
 * @param {Prefix} prefix - The prefix to be added to the key.
 * @returns {function} A function that takes an object and a key, and returns the value associated with the prefixed key.
 */
export const createPrefixedGetterFn =
  <Prefix extends string = string>(prefix: Prefix) =>
  /**
   * Retrieves the value from a prefixed object using a key and the specified prefix.
   *
   * @template T - The type of the input object.
   * @template Key - The type of the key to retrieve the value.
   * @param {Prefixed<T, Prefix>} data - The prefixed object.
   * @param {Key} key - The key to retrieve the value.
   * @returns {T[Key]} The value associated with the prefixed key.
   */
  <T extends AnyRecord, Key extends keyof T>(data: Prefixed<T, Prefix>, key: Key): T[Key] => {
    const prefixedKey = `${prefix}${toTitleCase(key)}` as keyof typeof data;
    return data[prefixedKey] as unknown as T[Key];
  };

/** @private */
const prefixKey = <Key, Prefix extends string = string>(key: Key, prefix: Prefix): Prefixed<Key, Prefix> => {
  return `${prefix}${toTitleCase(String(key))}` as Prefixed<Key, Prefix>;
};

/**
 * Creates a function that prefixes all keys of an object with a specified prefix and capitalizes them.
 * If a string is passed, it will be prefixed by the same rules as the key.
 *
 * @template Prefix - The prefix to be added to each key. Defaults to `string`.
 * @param {Prefix} prefix - The prefix to be added to each key.
 * @returns {function} A function that takes an object and returns a new object with prefixed and capitalized keys.
 */
export const createPrefixingFn =
  <Prefix extends string = string>(prefix: Prefix) =>
  /**
   * Prefixes all keys of an object with the specified prefix and capitalizes them.
   *
   * @template T - The type of the input object or string.
   * @param {T} data - The string  or The object whose keys are to be prefixed and capitalized.
   * @returns {Prefixed<T, Prefix>} A new object with prefixed and capitalized keys.
   */
  <T>(data: T): Prefixed<T, Prefix> => {
    if (typeof data === 'object' && data !== null) {
      const prefixed: Record<string, unknown> = {};
      Object.keys(data).forEach((key) => {
        const value = data[key as keyof T];
        const preFixedKey = prefixKey(key as keyof T, prefix);
        prefixed[preFixedKey] = value;
      });
      return prefixed as Prefixed<T, Prefix>;
    } else {
      return prefixKey(data, prefix);
    }
  };
