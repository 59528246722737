import { container } from 'tsyringe';
import type {
  ColDef,
  ProcessDataFromClipboardParams,
  ProcessCellForExportParams
} from '@ag-grid-community/core';
import type { FeedbackWrapper } from '@oms/generated/frontend';
import { FormValidationFeedbackLevel, OrderSideType } from '@oms/generated/frontend';
import { convertStringToNumber } from '@oms/ui-util';
import { InvestorOrderBulkEntryService } from '@app/data-access/services/trading/investor-order-bulk-entry/investor-order-bulk-entry.service';
import type { InvestorOrderItem } from '../investor-order-bulk-entry.form-common';

export const isWarnRowState = (data: InvestorOrderItem) => {
  return (
    data.validation?.some(
      (validationItem: FeedbackWrapper) => validationItem.level === FormValidationFeedbackLevel.Warning
    ) || false
  );
};

export const isErrorRowState = (data: InvestorOrderItem) => {
  return (
    data.validation?.some(
      (validationItem: FeedbackWrapper) => validationItem.level === FormValidationFeedbackLevel.Error
    ) || false
  );
};

/**
 * Process data from clipboard and update the grid
 * @param params - clipboard data
 *
 * TODO's:
 * - parce header row and get indexes for columns
 */
export const processDataFromClipboard = (params: ProcessDataFromClipboardParams): string[][] | null => {
  // TODO: remove container.resolve
  const bulkIOEntryService = container.resolve(InvestorOrderBulkEntryService);
  // TODO: ckeck if we need these
  // let dataTransactionType: 'add' | 'update';
  // let dataTransactionIndex: number;

  const { api, context, data } = params;
  const sanitizedData = removeLastLineIfBlank(data);

  console.warn('[Bukl IO entry] Clipboard', data, sanitizedData);

  const referenceCell = api.getFocusedCell();
  const colDefs: ColDef[] = api.getColumnDefs()!;
  const collsToFill = colDefs.map((col: ColDef) => (col.editable ? col.field : null)).filter(Boolean);
  const cellRanges = api.getCellRanges()![0];
  const { columns, endRow: _endRow, startColumn: _startColumn, startRow } = cellRanges;

  let updatedGridData: InvestorOrderItem[];
  let itemsToInsert: InvestorOrderItem[] = [];

  if (referenceCell && referenceCell.rowPinned && referenceCell.rowPinned === 'top') {
    // add a new rows at the top
    itemsToInsert = Array(sanitizedData.length).map(() => bulkIOEntryService.getDefaultRowData());
  }
  updatedGridData = [...itemsToInsert, ...bulkIOEntryService.getOrdersValue(context.formId)];

  const startRowIndex = startRow?.rowIndex ?? referenceCell?.rowIndex ?? 0;

  // Determine column order to update. If a cell range is selected, use its columns.
  // Otherwise, use the allowed columns (collsToFill) starting from the reference cell's column.
  const columnsToUpdate =
    columns?.length && columns.length >= sanitizedData[0].length
      ? columns.map((col) => col.getColId())
      : (() => {
          // If no range, find the index of the reference column in collsToFill
          const refColIndex = collsToFill.indexOf(referenceCell?.column.getColId() ?? '');
          return refColIndex >= 0 ? collsToFill.slice(refColIndex) : collsToFill;
        })();
  const normalizedColumnsToUpdate = columnsToUpdate.splice(0, sanitizedData[0].length);

  console.warn('[Bukl IO entry] Columns to update', columnsToUpdate, normalizedColumnsToUpdate);

  // Process rows
  for (let r = 0; r < sanitizedData.length; r++) {
    const targetRowIndex = startRowIndex + r;

    if (!updatedGridData[targetRowIndex]) {
      updatedGridData[targetRowIndex] = bulkIOEntryService.getDefaultRowData();
    }

    console.warn('[Bukl IO entry] Row data', updatedGridData[targetRowIndex]);

    // Process columns
    for (let c = 0; c < sanitizedData[r].length; c++) {
      const targetColId = c < normalizedColumnsToUpdate.length ? normalizedColumnsToUpdate[c] : undefined;

      // Validate data and update row object
      switch (targetColId) {
        case 'sideType': {
          const cellData = mapStringToOrderSideType(sanitizedData[r][c]);
          if (cellData) {
            updatedGridData[targetRowIndex][targetColId] = cellData;
          }
          break;
        }
        case 'quantity':
        case 'limitPrice': {
          const cellData = convertStringToNumber(sanitizedData[r][c]);
          if (cellData) {
            updatedGridData[targetRowIndex][targetColId] = cellData;
          }
          break;
        }
        case 'instrument': {
          const cellData = sanitizedData[r][c];
          if (cellData) {
            updatedGridData[targetRowIndex][targetColId] = cellData;
          }

          // TODO: fetch instrument id and start validation process
          // TODO: Check if validation should be triggered here OR in event handler
          void bulkIOEntryService.validateInstrument(context.formId, updatedGridData[targetRowIndex]);
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  // Remove empty array items
  const dataToProcess = updatedGridData.filter((row) => row != null);
  bulkIOEntryService.setOrdersState(context.formId, dataToProcess);

  // No need to do following, as the grid will handle the update
  // Just update the grid datastore and let the grid handle the rest
  /*
  dataTransactionIndex = referenceCell.rowIndex;

  const addDataTransaction = [];
  const updateDataTransaction = [];
  for (let i = 0; i < dataToProcess.length; i++) {
    if (bulkIOEntryService.getOrderById(context.formId, dataToProcess[i].id)) {
      updateDataTransaction.push(dataToProcess[i]);
    } else {
      addDataTransaction.push(dataToProcess[i]);
    }
  }
  
  // Create separate calls for update and add if rows (below refCell) to paste are bigger than the ramaining grid rows
  // NOTE: Call update first to avoid save correct addIndex. If we call add first, the addIndex will be wrong and we will have to recalculate it.

  api.applyTransaction({
    update: updateDataTransaction,
    updateIndex: dataTransactionIndex
  });

  api.applyTransaction({
    add: addDataTransaction,
    addIndex: dataTransactionIndex
  });

  */

  return null;
};

const mapStringToOrderSideType = (input: string): OrderSideType | null => {
  if (!input) return null;

  const trimmed = input.trim();
  const orderSideValues = Object.values(OrderSideType) as string[];
  const pattern = `^(?:${orderSideValues.join('|')})$`;
  const regex = new RegExp(pattern, 'i');
  const match = trimmed.match(regex);

  if (match) {
    const normalized = match[0].toLowerCase();
    return orderSideValues.find((value) => value.toLowerCase() === normalized) as OrderSideType;
  }

  return null;
};

const removeLastLineIfBlank = (data: string[][]): string[][] => {
  const lastLine = data[data.length - 1];
  const lastLineIsBlank = lastLine && lastLine.length === 1 && lastLine[0] === '';

  if (lastLineIsBlank) {
    // do not remove the last empty line when that is the only line pasted
    if (data.length === 1) {
      return data;
    }
    data.pop();
  }

  return data;
};

export const processCellFromClipboard = (_params: ProcessCellForExportParams): void => {
  /* params should look like:
    {
    column: column,
    node: rowNode,
    value: value,
    api: this.gridOptionsWrapper.getApi(),
    columnApi: this.gridOptionsWrapper.getColumnApi(),
    context: this.gridOptionsWrapper.getContext()
    }
  */
};
