import type { Actor } from '@valstro/workspace';
import type { Maybe, Prettify } from '@oms/ui-util';
import { Result } from '@oms/ui-util';
import { openRejectInvestorOrder } from '@app/generated/sdk';
import { FORM_MAP } from '@app/generated/mappers';
import { InvestorOrderStatus, ValstroEntitlements } from '@oms/generated/frontend';
import type { ActionDefFactory, ActionContext } from '@oms/frontend-vgrid';
import { t } from '@oms/codegen/translations';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import type {
  ActionCommandConfig,
  ActionCommandContracts,
  ActionCommandType
} from '@app/actions/commands/command.registry.types';
import type { InvestorOrderWithChargesRow } from '@app/widgets/trading/investor-order-monitor/investor-order-monitor.contracts';
import { getLeaderOrTabId } from '@app/common/workspace/workspace.util';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import type { Form } from '@app/common/registry/form.open';
import { REJECT_INVESTOR_ORDER_ACTION_NAME } from './reject-investor-order.action.types';
import { runConfigButton } from '@app/actions/util/run-config-button.util';
import {
  RejectInvestorOrderFormInput,
  RejectInvestorOrderFormValues
} from '../../grid-forms/reject-investor-order.form-contract';
import rejectInvestorOrderFormBuilder from '../../grid-forms/reject-investor-order.form-builder';

// Types --------------------------------------------------------------------- /

export type ActionType = ActionCommandType;
export type RejectInvestorOrderActionArgs = Prettify<
  Pick<InvestorOrderWithChargesRow, 'id'> & {
    status?: string | InvestorOrderStatus;
  }
>;

// Action --------------------------------------------------------------------- /

export const createRejectInvestorOrderAction =
  <TData extends RejectInvestorOrderActionArgs>(type: ActionType): ActionDefFactory<TData> =>
  (builder) =>
    builder
      .name(type === 'configurable' ? REJECT_INVESTOR_ORDER_ACTION_NAME : 'reject_investor_order_static')
      .toolbar(
        type === 'configurable'
          ? (builder) =>
              builder
                .location('UserToolbar')
                .component('action-button')
                .id('reject_investor_order_config_button')
                .props({
                  variant: 'primary',
                  content: t('app.commands.rejectInvestorOrder.button'),
                  isDisabled: true
                })
          : null
      )
      .access(({ appContainer }) => {
        const authService = appContainer.resolve(AuthService);
        return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
      })
      .customMenu(
        type === 'context-menu'
          ? (m) =>
              m
                .name(t('app.commands.rejectInvestorOrder.contextMenu'))
                .tabName(t('app.common.grids.contextMenuTabs.action'))
                .priority(160)
                .visible(({ rowData }) => isActive(rowData))
          : null
      )
      .lifecycles('change', 'init', 'onSelectionChanged', 'onRowDataUpdated')
      .onChange<ActionCommandConfig<ActionCommandContracts['reject_investor_order']>>(
        rejectInvestorOrderActionOnChange
      );

// Util --------------------------------------------------------------------- /

/**
 * Re-useable function to handle the onChange lifecycle of the reject investor order action
 * - This function will open the Investor Order Reject widget
 *
 * @param ctx - The action event
 * @returns Change function
 */
export async function rejectInvestorOrderActionOnChange<TData extends RejectInvestorOrderActionArgs>(
  ctx: ActionContext<TData>
): Promise<void> {
  const { lifecycle, data, notify, container, workspace } = ctx;

  const windowId = getLeaderOrTabId(container);

  // For now we only support canceling one order, but we will suport multiple rows eventually.
  const selectedRows = data.filter(isRejectableRow).slice(0, 1);

  const id = selectedRows[0]?.id;

  // -------- Handle button state --------

  const isDisabled = !isActive(data);

  notify({ isDisabled });

  if (lifecycle !== 'change' || !id || isDisabled) {
    return;
  }

  // -------- Handle clicking the button --------

  notify({ isLoading: true });

  await runConfigButton<TData, RejectInvestorOrderFormInput, RejectInvestorOrderFormValues>({
    ctx,
    name: t('app.commands.rejectInvestorOrder.button'),
    formBuilder: rejectInvestorOrderFormBuilder,
    input: { id },
    fixedFormValueKeys: [],
    handleError: true,
    onOpenForm: async ({}) => {
      const result = await openRejectInvestorOrderForm({ id, parentActorOrId: windowId });

      await result.mapAsync(
        async (_) => {},
        async ({ message }) => {
          const messageCtx = t('app.common.errorWithMessage', { message });
          await openMessageDialog(messageCtx, workspace).catch(console.error);
        }
      );
    }
  });

  notify({ isLoading: false });
}

interface OpenRejectInvestorOrderFormSetup {
  id: string;
  parentActorOrId?: Actor | string;
}

export async function openRejectInvestorOrderForm({
  id,
  parentActorOrId
}: OpenRejectInvestorOrderFormSetup): Promise<
  Result<Form<(typeof FORM_MAP)['REJECT_INVESTOR_ORDER']>, Error>
> {
  try {
    const form = await openRejectInvestorOrder(parentActorOrId, {
      form: {
        formId: `reject-investor-order-${id}`,
        input: {
          id
        }
      }
    });
    return Result.success(form);
  } catch (e) {
    const error = e instanceof Error ? e : typeof e === 'string' ? new Error(e) : new Error('Unknown error');
    return Result.failure(error);
  }
}

/**
 * Re-useable function to determine if the row can be rejected
 *
 * @param rowData - The data from the grid
 * @returns Whether the button should be active
 */
export function isRejectableRow(rowData: Maybe<Partial<RejectInvestorOrderActionArgs>>): boolean {
  if (!rowData) return false;
  const { status } = rowData;
  return status === InvestorOrderStatus.Unaccepted;
}

/**
 * Re-useable function to determine if the button should be active
 *
 * @param rowData - The data from the grid
 * @returns Whether the button should be active
 */
export function isActive(rowData?: RejectInvestorOrderActionArgs[]): boolean {
  if (!rowData) return false;
  if (rowData.length < 1) return false;
  return rowData.some(isRejectableRow);
}
