import { type FC, type LegacyRef, useMemo } from 'react';
import clsx from 'clsx';
import type { Optional } from '@oms/ui-util';
import { asArray } from '@oms/ui-util';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import { Select as SelectComponent } from '@oms/ui-design-system';
import type { FieldProps, ICommonSelectField } from '../../types';
import { useFieldApi, useFieldFocus } from '../../helpers';
import { type FORM_COMPONENT_TYPE } from '../../contracts';
import { type Validator } from '@data-driven-forms/react-form-renderer';
import * as styles from './select.component.css';
import { getFormClassNameResolver } from '../../mappers/ddf-class-name-resolver.mapper';


export interface ISelectField<TValue = string | number, TValidator = Validator>
  extends ICommonSelectField<typeof FORM_COMPONENT_TYPE.SELECT, TValue, TValidator> { }

export const Select: FC<FieldProps<ISelectField>> = (props) => {
  const {
    meta,
    label,
    className,
    classNameResolvers,
    wrapperSx = {},
    sx = {},
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    input: { name, onBlur, onChange, onFocus, value: rawValue },
    helperText,
    isRequired,
    isDisabled: _isDisabled,
    forceIsDisabled,
    isReadOnly,
    isInvalid,
    requiredFieldIndicatorStyle,
    isFeatureField,
    isPrimaryField,
    isVisible,
    hideFormControls,
    options = [],
    style = {}
  } = useFieldApi<ISelectField>(props);

  const value = useMemo(() => {
    if (options.length === 2 && options[0].value === 'Y' && options[1].value === 'N') {
      // If this is a 2-option select with Y/N values, assign those from true/false.
      if (rawValue === true) {
        return 'Y';
      }
      if (rawValue === false) {
        return 'N';
      }
    }
    return rawValue as Optional<string | number | readonly string[]>;
  }, [rawValue, options?.[0]?.value, options?.[1]?.value]);

  // Sometimes validators override the disabled props. If forceIsDisabled is true, then we should always disable the field.
  const isDisabled = !!forceIsDisabled || !!_isDisabled;

  // Combine field/input props
  const fieldProps = { name, onBlur, onChange, onFocus, value, hidden: isVisible === false, style, sx };

  const resolvedClassName = useMemo(() => {
    const classNames: string[] = [];
    if (className) {
      classNames.push(className);
    }
    if (classNameResolvers && typeof value === 'string') {
      asArray(classNameResolvers).forEach((resolver) => {
        const resolverFn = getFormClassNameResolver(resolver);
        const resolved = resolverFn(value, {});
        if (resolved) classNames.push(resolved);
      });
    }
    return classNames.length ? clsx(...classNames) : undefined;
  }, [className, classNameResolvers]);

  // Combine field wrapper props
  const fieldWrapperProps = {
    meta,
    label,
    isReadOnly,
    isRequired,
    sx: wrapperSx,
    isDisabled,
    isInvalid,
    requiredFieldIndicatorStyle,
    helperText,
    hideFormControls,
    isFeatureField,
    isPrimaryField,
    isVisible
  };

  // Handle selectAllOnFocus & autoFocus
  const [inputRef] = useFieldFocus<HTMLSelectElement>(props);

  return (
    <FieldWrapper {...fieldWrapperProps}>
      <SelectComponent {...fieldProps} className={resolvedClassName} ref={inputRef as any as LegacyRef<HTMLSelectElement>}>
        {!value && (
          <option value="" disabled hidden>
            {isFeatureField || isPrimaryField ? '' : 'Select'}
          </option>
        )}
        {options.map(({ value, label, isDisabled, className }) => (
          <option key={value} className={clsx(styles.option, className)} value={value} disabled={isDisabled}>
            {label}
          </option>
        ))}
      </SelectComponent>
    </FieldWrapper>
  );
};
