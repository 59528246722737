import { FieldDefinition, FormContract } from '@oms/frontend-foundation';
import type { InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import type { MontageFilterFormInput } from './montage-filters.form-common';
import type { Sprinkles } from '@oms/ui-design-system-css';
import { instrument, investorAccount, layout, coverageUser } from './montage-filters.form-fields';

export const montageFiltersFormContract = FormContract.create<MontageFilterFormInput>()
  .fields({
    instrument,
    investorAccount,
    layout
  })
  .schema((f) => ({
    fields: [
      FieldDefinition.box(
        'wrapper',
        [
          FieldDefinition.box('instrument-dropdown', [FieldDefinition.box('instrument', [f.instrument])], {
            sx: {
              marginLeft: 'small'
            }
          }),
          FieldDefinition.box(
            'investor-account-dropdown',
            [FieldDefinition.box('investorAccount', [f.investorAccount])],
            {
              sx: {
                marginLeft: 'small'
              }
            }
          ),
          coverageUser
        ],
        {
          sx: {
            display: 'flex',
            flexDirection: 'row',
            margin: 'small'
          }
        }
      )
    ]
  }))
  .template('simple', {
    showSubmitButton: false,
    showFormFeedback: false,
    hideScrollbar: true,
    sx: {
      backgroundColor: 'layout.level2',
      overflow: 'hidden'
    } as Sprinkles
  });

export type MontageFiltersFormContractType = typeof montageFiltersFormContract;

export type MontageFiltersFormValues = InferFormValuesFromFormContract<MontageFiltersFormContractType>;

export default montageFiltersFormContract;
