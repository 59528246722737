import {
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE,
  ROW_SELECTION_COUNT_ACTION_TYPE,
  TOGGLE_FILTERS_AND_SIDEBAR_ACTION_TYPE,
  useVGrid,
  VGrid
} from '@oms/frontend-vgrid';
import { tradingOrderMarketData, buildTradingOrderMonitorColumnDefs } from './trading-order-monitor.columns';
import { GridConfigEventHandler } from '@app/data-access/services/system/grids/grid-config.event-handler';
import { inlineTradingOrderActions } from './grid-actions/inline.trading-order.actions';
import { cancelTradingOrderAction } from './grid-actions/cancel.trading-order.action';
import { forceCancelTradingOrderAction } from './grid-actions/force-cancel.trading-order.action';
import { modifyTradingOrderAction } from './grid-actions/modify.trading-order.action';
import { viewTradingOrderAction } from './grid-actions/view.trading-order.action';
import { openEntryTradingOrderAction } from './grid-actions/open-entry.trading-order.action';
import { refreshServersideCustomMenuAction } from '@app/common/grids/actions/refresh-serverside.action';
import { TsTradingOrdersWithFilterDocument } from '@oms/generated/frontend';
import type { TradingOrderRow, TsTradingOrdersWithFilterSubscription } from '@oms/generated/frontend';
import { VisibilityEventHander } from '../investor-order-monitor/grid-services/visibility.event-handler';
import { updateGridFilterModel } from '@app/common/grids/grid-api/grid-filter-model.util';
import { createGridTrackingConsumerEventHandler } from '@app/common/grids/grid-tracking/grid-tracking.consumer.handler';
import { createGridTrackingPublisherEventHandler } from '@app/common/grids/grid-tracking/grid-tracking.publisher.handler';
import { GRID_TYPE } from '@app/common/grids/grid.constants';
import {
  TOHasExecutedQuantity,
  TOHazard,
  TONoExecutedQuantity,
  TOPending
} from '../utils/row-state-rule-utils';
import { orderTrackingAction } from './grid-actions/order-tracking/order-tracking.action';
import { withTableServer } from '@app/data-access/services/system/table-server/with.table-server.component';

export type TradingOrderMonitorSubscriptionKeys = 'trading_orders';

export type TradingOrderMonitorSubscriptionMap = {
  trading_orders: {
    datasource: TradingOrderRow;
    subscription: TsTradingOrdersWithFilterSubscription;
  };
};

export const TradingOrderMonitorWidgetComponent = withTableServer<
  TradingOrderMonitorSubscriptionKeys,
  TradingOrderMonitorSubscriptionMap
>(
  ({ datasources }) => {
    const gridProps = useVGrid<TradingOrderRow>(
      GRID_TYPE.TRADING_ORDER_MONITOR,
      (builder) =>
        builder
          .tableServerColumnLibrary(buildTradingOrderMonitorColumnDefs())
          .rowSelection((c) => c.multiple())
          .datasource((d) =>
            d
              .source(datasources.trading_orders.asServerSideRowModel())
              .rowId((r) => r.data.id)
              .cacheBlockSize(100)
          )
          .rowStateRules({
            pending: (params) => TOPending(params.data),
            noExecutedQuantity: (params) => TONoExecutedQuantity(params.data),
            hasExecutedQuantity: (params) => TOHasExecutedQuantity(params.data),
            hazard: (params) => TOHazard(params.data)
          })
          .marketData(tradingOrderMarketData)
          .injectEvents([
            VisibilityEventHander,
            GridConfigEventHandler,
            createGridTrackingPublisherEventHandler<TradingOrderRow>({
              getProps: {
                orderId: ({ id }) => id,
                orderType: (_) => 'trading',
                instrumentId: ({ instrument }) => instrument,
                instrumentDisplayCode: ({ instrumentDisplayCode }) => instrumentDisplayCode
              },
              columnFilters: {
                instrumentId: ['instrument', 'instrumentDisplayCode'],
                instrumentDisplayCode: ['instrument', 'instrumentDisplayCode']
              }
            }),
            createGridTrackingConsumerEventHandler<TradingOrderRow>({
              sourceTypes: ['investor-order-monitor'],
              orderType: 'investor',
              onOrderSelection: ({ api, data }) => {
                updateGridFilterModel<TradingOrderRow, any>(api, (current) => ({
                  ...current,
                  investorOrderIds: data.length
                    ? {
                        filterType: 'array',
                        type: 'contains',
                        values: data.map(({ orderId }) => orderId)
                      }
                    : undefined
                }));
              }
            })
          ])
          .actions((a) =>
            a.schema((s) =>
              s
                .action(openEntryTradingOrderAction())
                .action(TOGGLE_FILTERS_AND_SIDEBAR_ACTION_TYPE)
                .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(orderTrackingAction)
                .action(viewTradingOrderAction())
                .action(modifyTradingOrderAction())
                .action(cancelTradingOrderAction())
                .action(forceCancelTradingOrderAction)
                .action(ROW_SELECTION_COUNT_ACTION_TYPE)
                .action(inlineTradingOrderActions())
                .action(refreshServersideCustomMenuAction)
            )
          )
          .sideBar()
          .reactiveCustomComponents(),
      [datasources, buildTradingOrderMonitorColumnDefs]
    );

    return <VGrid {...gridProps} />;
  },
  {
    trading_orders: {
      query: TsTradingOrdersWithFilterDocument,
      getData: ({ tsTradingOrdersWithFilter }) => tsTradingOrdersWithFilter
    }
  }
);

export default TradingOrderMonitorWidgetComponent;
