import type { AppWorkspace } from '@app/app-config/workspace.config';
import { isTauri, Plugin } from '@valstro/workspace';
import { createLogger } from '@oms/ui-util';
import type { DependencyContainer } from 'tsyringe';
import { BrowserInactivityService, TauriInactivityService } from './inactivity.service';

export interface InactivityPluginOptions {
  enabled?: boolean;
  container: DependencyContainer;
}

export const inactivityPlugin = ({ enabled = true, container }: InactivityPluginOptions) =>
  Plugin.create<AppWorkspace>({
    name: 'inactivity-plugin',
    pluginFn: ({ workspace }) => {
      const logger = createLogger({ name: 'Inactivity Plugin' });

      if (!enabled) {
        return function unsubscribe() {};
      }

      let inactivityService: BrowserInactivityService | TauriInactivityService | undefined;

      workspace.addHook('windowReady', ({ isLeader, rootWindowActor }) => {
        if (isTauri()) {
          inactivityService = container.resolve(TauriInactivityService);
          logger.debug('Using Tauri inactivity service');
        } else {
          inactivityService = container.resolve(BrowserInactivityService);
          logger.debug('Using Browser inactivity service');
        }

        inactivityService.init({
          isLeader,
          rootActorId: rootWindowActor.id
        });
      });

      return function unsubscribe() {
        if (inactivityService) {
          logger.debug('Destroying inactivity service');
          inactivityService.destroy();
        }
      };
    }
  });
