import { useDeepMemo } from '@oms/ui-design-system';
import type { GridProps, Sprinkles } from '@oms/ui-design-system';
import React, { useEffect, useMemo, useState } from 'react';
import { MarketDataDisplay } from './market-data.display';
import type { MarketDataWidgetProps } from './market-data.types';
import { useRxMarketDataSubscription } from './market-data.hook';
import { marketDataWidgetTypeMap } from './market-data.widget.util';
import { isEqual } from 'lodash';
import { MarketDataService } from '../marketdata.service';
import { useService } from '@oms/frontend-foundation';
import type { InstrumentDetailsSimpleFragment } from '@oms/generated/frontend';
import { SymbolInputPromptMessage } from '@app/forms/form-builder/fields/market-data-field/market-data-field.component';

/**
 * MarketDataWidget
 *
 * This component is used to display specific market widgets,
 * that are required in the app.
 *
 * It's a wrapper around the MarketDataDisplay component, that adds
 * business logic and real-time data.
 */
export const MarketDataWidget: React.FC<MarketDataWidgetProps> = React.memo(
  ({ type, values = [], gridProps, instrumentId, sx, trackableValues, testId = 'market-data', ...rest }) => {
    const marketDataService = useService(MarketDataService);
    const [isFetching, setIsFetching] = useState(false);
    const [instrumentDetails, setInstrumentDetails] = useState<InstrumentDetailsSimpleFragment | null>(null);

    /**
     * Get ticker from instrument details
     */
    const ticker = useDeepMemo(
      () => instrumentDetails?.mappings?.displayCode || undefined,
      [instrumentDetails]
    );

    /**
     * Get level 1 market data
     */
    const marketData = useRxMarketDataSubscription(ticker);
    const level1Event = marketData?.level1;

    useEffect(() => {
      setInstrumentDetails(null);

      if (instrumentId) {
        setIsFetching(true);
        marketDataService
          .getInstrumentById(instrumentId)
          .then((instrument) => {
            setInstrumentDetails(instrument);
          })
          .catch((error) => {
            console.error('Error fetching instrument details', error);
          })
          .finally(() => {
            setIsFetching(false);
          });
      }
    }, [instrumentId, marketDataService]);

    /**
     * Generate props for the MarketDataDisplay component
     * Note: We're using a map (marketDataWidgetTypeMap) to add props to the component based on the type
     */
    const props = useMemo(
      () => ({
        level1Event,
        instrumentDetails,
        gridProps: gridProps ? gridProps : ({ columns: 2, rows: 8, rowGap: 4 } as GridProps),
        values,
        ...(type !== 'custom' ? marketDataWidgetTypeMap[type] : {}) // using a map (marketDataWidgetTypeMap) to add props to the component based on the type
      }),
      [instrumentDetails, level1Event, type, values, gridProps]
    );

    const omBannerStyles: Sprinkles = {
      backgroundColor: 'layout.level1',
      borderRadius: 'sm',
      px: 3,
      py: 1
    };

    if (instrumentDetails) {
      const widgetProps = {
        ...props,
        instrumentDetails: props.instrumentDetails as InstrumentDetailsSimpleFragment
      };

      return (
        <MarketDataDisplay
          {...rest}
          {...widgetProps}
          trackableValues={trackableValues || props.trackableValues}
          sx={{
            ...(type === 'om-banner' || type === 'om-banner-compact' ? { ...omBannerStyles } : null),
            ...sx
          }}
          testId={testId}
        />
      );
    }

    return <SymbolInputPromptMessage isLoading={isFetching} />;
  },
  isEqual
);
