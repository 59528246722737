import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { cleanMaybe, isPromiseLike } from '@oms/ui-util';
import type {
  FormBuilderDefinition,
  AnyFormBuilder,
  InferInfoFromFormBuilder as InferB,
  FormBuilderChangeFnResult
} from './form-builder.class';
import type { AnyFieldDefinitions } from './form-field-definition.class';
import {
  getFormBuilderRemoteEvent$,
  type FormBuilderEventSubmitFinishedSuccess,
  type FormBuilderEvent
} from './form-builder.events';
import type { FormRendererProps } from './components/renderers/form-renderer';
import { type FormContract, type InferFormValuesFromFormContract } from './form-contract.class';
import { getFormRendererEvent$, type FormRendererEventBase } from './form-builder.events.renderer';
import {
  FORM_SAVE_TYPE,
  type RemoteFormBuilderDefinition,
  type FormSaveType,
  type OnSanitizedValuesChangedCtx,
  type OnValuesChangedCtx,
  type OnValuesChangingCtx,
  type OnValuesChanging,
  type OnValuesChanged
} from './form-builder.common.types';
import type { AnyRecord } from '../../common/type.helpers';
import { useUniqueId } from '../../hooks/use-unique-id';
import { useWorkspaceContainer } from '../../workspace/workspace.services';
import { useWorkspace } from '../../workspace/workspace.hook';
import { useDeepMemo } from '@oms/ui-design-system';
import { isEmpty } from 'lodash';
import { extractGQLEnvelope } from '../../graphql/graphql-envelope';
import { GQLResult } from '../../graphql/graphql-result';
import { extractErrorCode } from '../../graphql/graphql-util';
import { useClosestActorByType, useClosestFlexLayoutActor, useCurrentWindow } from '@valstro/workspace-react';
import {
  Actor,
  type WidgetActorSchema,
  type CommonWindowActorSchema,
  COMMON_ACTOR_TYPE,
  type Unsubscribe
} from '@valstro/workspace';
import { useWorkspaceFormMappers } from '../common/form.workspace.hook';
import type { EnhancedFormOptions } from '../types';
import { omitBy, isUndefined } from 'lodash';

export interface FormIdentifierOptions {
  formId?: string;
  formSaveType?: FormSaveType;
  formType?: string;
}

/**
 * Interpret a form builder instance into a form renderer props
 * that can be used to render a form
 *
 * Note: This hook will create handlers for the form and run the change function
 * and sanitize the form values
 *
 * @param formBuilder - The form builder instance
 * @param input - The input values for the form
 * @param initialValues - The initial values for the form
 * @param props - Additional props for the form
 * @param formId - The form id
 * @returns - The form renderer props
 */
export function useInterpretFormBuilder<T extends AnyFormBuilder>(
  formBuilder: T,
  input?: InferB<T>['inputContract'],
  initialValues?: Partial<InferB<T>['fieldValues']>,
  _props?: Partial<
    FormRendererProps<
      InferB<T>['inputContract'],
      InferB<T>['outputContract'],
      InferB<T>['formContract'],
      InferB<T>['fieldValues']
    >
  >
): FormRendererProps<
  InferB<T>['inputContract'],
  InferB<T>['outputContract'],
  InferB<T>['formContract'],
  InferB<T>['fieldValues']
> {
  const def = useMemo(() => formBuilder.build(), [formBuilder]);
  const formId = _props?.formId;
  const formSaveType = _props?.formSaveType || FORM_SAVE_TYPE.UNKNOWN;
  const formType = _props?.formType || def.formType;

  const identiferOptions = useMemo(
    () => ({
      formId,
      formSaveType,
      formType
    }),
    [formId, formSaveType, formType]
  );
  const props = useInterpretFormBuilderDefinition(def, input, initialValues, identiferOptions);

  const onValuesChanging: OnValuesChanging<InferB<T>['fieldValues']> = useCallback(
    (...args) => {
      _props?.onValuesChanging?.(...args)?.catch(console.error);
      props.onValuesChanging?.(...args)?.catch(console.error);
    },
    [props.onValuesChanging, _props?.onValuesChanging]
  );

  const onValuesChanged: OnValuesChanged<InferB<T>['fieldValues']> = useCallback(
    (...args) => {
      _props?.onValuesChanged?.(...args)?.catch(console.error);
      props.onValuesChanged?.(...args)?.catch(console.error);
    },
    [props.onValuesChanged, _props?.onValuesChanged]
  );

  return useMemo(() => {
    const overrides = omitBy(_props, isUndefined); // Ignore undefined values, so only the defined values are merged
    return { ...props, ...overrides, onValuesChanging, onValuesChanged };
  }, [props, _props, onValuesChanging, onValuesChanged]);
}

/**
 * Interpret a form builder by its id into a form renderer props
 * that can be used to render a form
 *
 * Note: This looks up the form builder instance from the workspace form builder mappers
 *
 * @param formBuilderId - The form builder instance
 * @param input - The input values for the form
 * @param initialValues - The initial values for the form
 * @param props - Additional props for the form
 * @param formId - The form id
 * @returns - The form renderer props
 */
export function useInterpretFormBuilderId<T extends AnyFormBuilder>(
  formBuilderId: string,
  input?: InferB<T>['inputContract'],
  initialValues?: Partial<InferB<T>['fieldValues']>,
  _props?: Partial<
    FormRendererProps<
      InferB<T>['inputContract'],
      InferB<T>['outputContract'],
      InferB<T>['formContract'],
      InferB<T>['fieldValues']
    >
  >
): FormRendererProps<
  InferB<T>['inputContract'],
  InferB<T>['outputContract'],
  InferB<T>['formContract'],
  InferB<T>['fieldValues']
> {
  const formBuilder = useWorkspaceFormBuilder<T>(formBuilderId);
  return useInterpretFormBuilder(formBuilder, input, initialValues, _props);
}

/**
 * Interpret a remote form definition into a form definition
 * that can be used to render a form in a remote window
 *
 * @param def - The remote form builder definition
 * @param initialValues - The initial values for the form
 * @param formId - The form id
 * @returns FormRendererProps - The form renderer props
 */
export function useInterpretRemoteFormDefinition<T extends AnyFormBuilder>({
  formBuilderId,
  formId,
  formSaveType,
  schema,
  formType,
  initialValues,
  input,
  template,
  templateProps,
  initialFeedback,
  triggerValidationOnOpen
}: RemoteFormBuilderDefinition<InferB<T>['fieldValues'], InferB<T>['inputContract']>): FormRendererProps<
  InferB<T>['inputContract'],
  InferB<T>['outputContract'],
  InferB<T>['formContract'],
  InferB<T>['fieldValues']
> {
  const memoizedProps = useMemo(() => {
    const props: Partial<
      FormRendererProps<
        InferB<T>['inputContract'],
        InferB<T>['outputContract'],
        InferB<T>['formContract'],
        InferB<T>['fieldValues']
      >
    > = {
      formId,
      formSaveType,
      formType,
      schema,
      template,
      templateProps,
      initialFeedback,
      triggerValidationOnOpen
    };
    return props;
  }, [
    formId,
    formSaveType,
    formType,
    schema,
    template,
    templateProps,
    initialFeedback,
    triggerValidationOnOpen
  ]);
  return useInterpretFormBuilderId<T>(formBuilderId, input, initialValues, memoizedProps);
}

/**
 * Converts a form builder into a remote definition
 * that can be used to open a window with a form
 *
 * @param builder - The form builder definition
 * @param input - The input for the form
 * @param initialValues - The initial values for the form
 * @param formId - The form id
 * @returns FormRendererProps - The form renderer props
 */
export function useRemoteFormDefinitionFromBuilder<T extends AnyFormBuilder>(
  formBuilder: T,
  input?: InferB<T>['inputContract'],
  initialValues?: InferB<T>['fieldValues'],
  _props?: Partial<RemoteFormBuilderDefinition<InferB<T>['fieldValues'], InferB<T>['inputContract']>>
): RemoteFormBuilderDefinition<InferB<T>['fieldValues'], InferB<T>['inputContract']> {
  const { formBuilderId, schema, template, templateProps } = useMemo(
    () => formBuilder.build(),
    [formBuilder]
  );

  const formSaveType = _props?.formSaveType || FORM_SAVE_TYPE.UNKNOWN;
  const formType = _props?.formType;
  const _formId = _props?.formId;
  const formId = useUniqueId(_formId);
  const initialFeedback = _props?.initialFeedback;
  const triggerValidationOnOpen = _props?.triggerValidationOnOpen;

  return useDeepMemo(
    function generateRemoteFormProps() {
      const remoteFormProps: RemoteFormBuilderDefinition<
        InferB<T>['fieldValues'],
        InferB<T>['inputContract']
      > = {
        formId,
        schema,
        input,
        initialFeedback,
        triggerValidationOnOpen,
        initialValues,
        template,
        templateProps,
        formType,
        formSaveType,
        formBuilderId
      };

      return remoteFormProps;
    },
    [
      _props,
      input,
      initialValues,
      formBuilderId,
      schema,
      template,
      templateProps,
      formSaveType,
      formType,
      formId,
      initialFeedback,
      triggerValidationOnOpen
    ]
  );
}

/**
 * Interpret a form builder definition into a form renderer props
 * that can be used to render a form
 *
 * Note: This hook will create handlers for the form and run the change function
 * and sanitize the form values
 *
 * @param formBuilder - The form builder definition
 * @param initialValues - The initial values for the form
 * @param props - Additional props for the form
 * @param formId - The form id
 * @returns - The form renderer props
 */
function useInterpretFormBuilderDefinition<
  TInputContract extends AnyRecord = AnyRecord,
  TOutputContract extends AnyRecord = AnyRecord,
  TFormContract extends FormContract<TOutputContract, AnyFieldDefinitions> = FormContract<
    TOutputContract,
    AnyFieldDefinitions
  >,
  TFormFieldValues extends AnyRecord = Partial<InferFormValuesFromFormContract<TFormContract>>
>(
  {
    change,
    effect,
    sanitizer,
    schema,
    template,
    templateProps,
    formBuilderId,
    formType: _formType
  }: FormBuilderDefinition<TInputContract, TOutputContract, TFormContract, TFormFieldValues>,
  input?: TInputContract,
  _initialValues?: Partial<TFormFieldValues>,
  identiferOptions: FormIdentifierOptions = {}
): FormRendererProps<TInputContract, TOutputContract, TFormContract, TFormFieldValues> {
  const formSaveType = identiferOptions.formSaveType || FORM_SAVE_TYPE.UNKNOWN;
  const _formId = identiferOptions.formId;
  const formType = identiferOptions.formType || _formType;
  const effectRef = useRef(effect);
  const formApiRef = useRef<EnhancedFormOptions<TFormFieldValues> | undefined>(undefined);
  const formId = useUniqueId(_formId);
  const workspace = useWorkspace();
  const container = useWorkspaceContainer();
  const windowActor = useCurrentWindow();
  const widgetActor = cleanMaybe(useClosestActorByType<WidgetActorSchema>(COMMON_ACTOR_TYPE.WIDGET));
  const flexLayoutActor = cleanMaybe(useClosestFlexLayoutActor());
  const windowId = windowActor.id;
  const widgetId = widgetActor?.id || flexLayoutActor?.id || windowId;
  if (!widgetId) {
    throw new Error('Could not resolve widget ID');
  }
  const [isLoading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [initialValues, setInitialValues] = useState<Partial<TFormFieldValues>>({});

  const changeCtx = useMemo(
    () => ({
      workspace,
      container,
      notify: (event: FormRendererEventBase<TOutputContract, TFormFieldValues>) =>
        getFormRendererEvent$().next({ ...event, meta: { formId } })
    }),
    [workspace, container, formId]
  );

  const runChange = useCallback(
    async <T extends FormBuilderEvent<TOutputContract, TFormFieldValues>>(e: T) => {
      try {
        getFormBuilderRemoteEvent$().next(e);
        const result = await change({ ...e, meta: { ...e.meta, formId } } as T, changeCtx);
        const isSubmit = e.type === 'SUBMIT';
        const isSanitizedValuesChanged = e.type === 'SANITIZED_VALUES_CHANGED';
        const isOtherEvent = !isSubmit && !isSanitizedValuesChanged;
        const isInsideTemplate = 'formSaveType' in e.meta && e.meta.formSaveType === FORM_SAVE_TYPE.TEMPLATE;

        if (isOtherEvent) {
          return;
        }

        let resultObj: AnyRecord | undefined | void = {};
        let isSuccessful = false;

        if (result instanceof GQLResult) {
          result.mapSync(
            (data) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              const envelope = extractGQLEnvelope(data);

              // Get all feedback from the envelope
              const feedback =
                envelope && envelope.feedback && envelope.feedback.length > 0 ? envelope.feedback : [];

              const hasFeedbackErrors = feedback.filter((f) => f.level === 'Error').length > 0;

              // Only apply feedback if there's errors full stop OR if the sanitized values have changed
              // This means we don't apply feedback if we've submitted & there's only warnings
              // Because, the form will close automatically anyway.
              // TODO: This may change in the future, because what if the window is pinned? Does the user want to see warrnings for a previous successful submission?
              if (hasFeedbackErrors || isSanitizedValuesChanged) {
                getFormRendererEvent$().next({
                  type: 'SET_FEEDBACK',
                  payload: {
                    feedback
                  },
                  meta: {
                    formId: e.meta.formId
                  }
                });
              }

              // Return early, to avoid auto closing the window
              if (hasFeedbackErrors || isSanitizedValuesChanged || isInsideTemplate) {
                return;
              }

              isSuccessful = true;

              // Otherwise, close the window
              Actor.get<CommonWindowActorSchema>(e.meta.windowId)
                .then((actor) => {
                  actor.operations.close().catch(console.warn);
                })
                .catch(console.warn);
            },
            {
              ENVELOPE_FEEDBACK_ERROR: (errors) => {
                getFormRendererEvent$().next({
                  type: 'SET_FEEDBACK',
                  payload: {
                    feedback: errors.map((e) => {
                      return {
                        code: e.code,
                        level: e.level,
                        message: e.message
                      };
                    })
                  },
                  meta: {
                    formId: e.meta.formId
                  }
                });
              }
            },
            (remainingErrors) => {
              if (remainingErrors.length > 0) {
                getFormRendererEvent$().next({
                  type: 'SET_FEEDBACK',
                  payload: {
                    feedback: remainingErrors.map((e) => {
                      return {
                        code: extractErrorCode(e),
                        level: 'Error',
                        message: e.message
                      };
                    })
                  },
                  meta: {
                    formId: e.meta.formId
                  }
                });
              }
            }
          );
        } else {
          resultObj = result;
          isSuccessful = isEmpty(resultObj);
        }

        if (isSubmit) {
          getFormRendererEvent$().next({
            type: 'SUBMISSION_RESULT',
            payload: {
              result,
              isSuccessful,
              formValues: e.payload.formValues,
              output: e.payload.output
            },
            meta: {
              formId
            }
          });
        }
      } catch (error) {
        const err = error as Error | string;
        const errorMessage = typeof err === 'string' ? err : err.message;
        if (e.type === 'SUBMIT') {
          getFormRendererEvent$().next({
            type: 'SUBMISSION_RESULT',
            payload: {
              result: {},
              isSuccessful: false,
              output: {},
              formValues: {}
            },
            meta: {
              formId
            }
          });
        }

        getFormRendererEvent$().next({
          type: 'SET_FEEDBACK',
          payload: {
            feedback: [{ code: 'error', level: 'Error', message: errorMessage }]
          },
          meta: {
            formId
          }
        });
      }
    },
    [change, changeCtx, formId]
  );

  /**
   * Initialize the form values
   */
  useLayoutEffect(() => {
    // If we already have initial values, use them verbatim
    if (!isEmpty(_initialValues)) {
      setInitialValues(_initialValues);
      setLoading(false);
      setErrorMessage(undefined);
      return;
    }

    // If no input is provided just initialize the form with empty values
    if (!input) {
      setInitialValues({});
      setLoading(false);
      setErrorMessage(undefined);
      return;
    }

    // Otherwise, run the input sanitizer
    const result = sanitizer.input(input, {
      container,
      schema,
      formId,
      formSaveType,
      formType
    });
    if (isPromiseLike(result)) {
      result
        .then((r) => {
          setInitialValues(r || {});
          setLoading(false);
        })
        .catch((err: Error) => {
          console.error(err);
          setErrorMessage(err?.message || 'An error occurred while initializing the form');
          setLoading(false);
        });
    } else {
      setInitialValues(result || {});
      setLoading(false);
    }
  }, [input, sanitizer, container, schema, formId, formSaveType, formType, _initialValues]);

  const onMount = useCallback(() => {
    runChange({
      type: 'MOUNT',
      meta: { formId, formType, formSaveType, formBuilderId, windowId, widgetId }
    }).catch(console.error);
  }, [runChange, formId, formType, formSaveType, formBuilderId, windowId, widgetId]);

  const onUnmount = useCallback(() => {
    runChange({
      type: 'UNMOUNT',
      meta: { formId, formType, formSaveType, formBuilderId, windowId, widgetId }
    }).catch(console.error);
  }, [runChange, formId, formType, formSaveType, formBuilderId, windowId, widgetId]);

  const onReset = useCallback(() => {
    runChange({
      type: 'RESET',
      meta: { formId, formType, formSaveType, formBuilderId, windowId, widgetId }
    }).catch(console.error);
  }, [runChange, formId, formType, formSaveType, formBuilderId, windowId, widgetId]);

  const onInit = useCallback(
    async (formApi: EnhancedFormOptions<TFormFieldValues>) => {
      let unsubscribe: Unsubscribe | undefined;
      if (effectRef.current) {
        unsubscribe = await effectRef.current({
          container,
          formApi,
          workspace
        });
      }
      formApiRef.current = formApi;

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    },
    [container, workspace]
  );

  const onSubmit = useCallback(
    async (values: TFormFieldValues) => {
      // Step 1: Sanitize the form values to the output contract
      let sanitizedFormValues = sanitizer.output(values as TFormFieldValues, {
        container,
        schema,
        formId,
        formSaveType,
        formType
      });

      // Step 2: If the sanitizer returns a promise, wait for it to resolve
      if (isPromiseLike(sanitizedFormValues)) {
        sanitizedFormValues = await sanitizedFormValues;
      }

      if (!sanitizedFormValues) {
        throw new Error(
          'Sanitizer failed to produce and output. Your fields are empty and/or you are failing to meet the output contract'
        );
      }

      if (!formApiRef.current) {
        throw new Error('Can not run onSubmit without having form API');
      }

      await runChange({
        type: 'SUBMIT',
        payload: {
          formValues: values,
          output: sanitizedFormValues,
          modifiedFields: formApiRef.current.getModifiedFields()
        },
        meta: { formId, formType, formSaveType, formBuilderId, windowId, widgetId }
      });
    },
    [
      sanitizer,
      container,
      schema,
      formId,
      formSaveType,
      formType,
      runChange,
      formBuilderId,
      windowId,
      widgetId
    ]
  );

  const onSubmitFinished = useCallback(
    async (
      result: FormBuilderChangeFnResult<TFormFieldValues>,
      payload: FormBuilderEventSubmitFinishedSuccess<TOutputContract, TFormFieldValues>['payload']
    ) => {
      if (result?.isSuccess) {
        await runChange({
          type: 'SUBMIT_FINISHED_SUCCESS',
          payload,
          meta: {
            formId,
            formType,
            formSaveType,
            formBuilderId,
            windowId,
            widgetId
          }
        });
      } else {
        const errors = result instanceof GQLResult || typeof result !== 'object' ? {} : result;
        await runChange({
          type: 'SUBMIT_FINISHED_ERRORS',
          payload: { errors },
          meta: {
            formId,
            formType,
            formSaveType,
            formBuilderId,
            windowId,
            widgetId
          }
        });
      }
    },
    [runChange, formId, formType, formSaveType, formBuilderId, windowId, widgetId]
  );

  const onValuesChanging = useCallback(
    async (payload: OnValuesChangingCtx<TFormFieldValues>) => {
      await runChange({
        type: 'VALUES_CHANGING',
        payload,
        meta: { formId, formType, formSaveType, formBuilderId, windowId, widgetId }
      });
    },
    [formSaveType, formType, formBuilderId, formId, runChange, windowId, widgetId]
  );

  const onValuesChanged = useCallback(
    async (payload: OnValuesChangedCtx<TFormFieldValues>) => {
      await runChange({
        type: 'VALUES_CHANGED',
        payload,
        meta: { formId, formType, formSaveType, formBuilderId, windowId, widgetId }
      });
    },
    [formSaveType, formType, formBuilderId, formId, runChange, windowId, widgetId]
  );

  const onSanitizedValuesChanged = useCallback(
    async (payload: OnSanitizedValuesChangedCtx<TFormFieldValues, TOutputContract>) => {
      await runChange({
        type: 'SANITIZED_VALUES_CHANGED',
        payload,
        meta: { formId, formType, formSaveType, formBuilderId, windowId, widgetId }
      });
    },
    [formSaveType, formType, formBuilderId, formId, runChange, windowId, widgetId]
  );

  return useDeepMemo(
    function generateRendererProps() {
      const rendererProps: FormRendererProps<
        TInputContract,
        TOutputContract,
        TFormContract,
        TFormFieldValues
      > = {
        formId,
        sanitizer,
        schema,
        onSubmit,
        onSubmitFinished,
        onReset,
        onMount,
        onUnmount,
        onInit,
        onValuesChanging,
        onValuesChanged,
        onSanitizedValuesChanged,
        initialValues,
        template,
        templateProps,
        isLoading,
        errorMessage,
        container,
        formType
      };

      return rendererProps;
    },
    [
      sanitizer,
      schema,
      onSubmit,
      onSubmitFinished,
      onReset,
      onMount,
      onUnmount,
      onInit,
      onValuesChanging,
      onValuesChanged,
      onSanitizedValuesChanged,
      formId,
      template,
      templateProps,
      initialValues,
      isLoading,
      errorMessage,
      formType
    ]
  );
}

/**
 * Get a form builder instance from the workspace form builder mappers by id
 *
 * @param formBuilderId - The form builder id
 * @returns The form builder instance
 */
function useWorkspaceFormBuilder<T extends AnyFormBuilder>(formBuilderId: string): T {
  const { formBuilderMapper } = useWorkspaceFormMappers() || {};
  return useMemo(() => {
    if (isEmpty(formBuilderMapper)) {
      throw new Error(
        'Form builder mapper is not available, please add it to the foundation workspace plugin'
      );
    }

    const matchingFormBuilder = formBuilderMapper[formBuilderId];

    if (!matchingFormBuilder) {
      throw new Error(
        `No matching form builder from foundation workspace plugin with key: ${formBuilderId} was found.`
      );
    }

    return matchingFormBuilder as T;
  }, [formBuilderMapper, formBuilderId]);
}
