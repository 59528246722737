import { useEffect, useMemo, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { omit } from 'lodash';

export type ObserverRect = Omit<DOMRectReadOnly, 'toJSON'>;

const defaultState: ObserverRect = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0
};

const browser = typeof window !== 'undefined';

export function useResizeObserver<T extends HTMLElement = any>(providedRef?: React.MutableRefObject<T>) {
  const frameID = useRef(0);
  const _ref = useRef<T>(null);
  const ref = providedRef ? providedRef : _ref;

  const [rect, setRect] = useState<ObserverRect>(defaultState);
  const [viewportRect, setViewportRect] = useState<ObserverRect>(defaultState);

  const observer = useMemo(
    () =>
      browser
        ? new ResizeObserver((entries: any) => {
            const entry = entries[0];

            if (entry) {
              cancelAnimationFrame(frameID.current);

              frameID.current = requestAnimationFrame(() => {
                if (ref.current) {
                  const viewportOffset = entry.target.getBoundingClientRect() as DOMRect;
                  setViewportRect(omit(viewportOffset, 'toJSON'));
                  setRect(entry.contentRect);
                }
              });
            }
          })
        : null,
    []
  );

  useEffect(() => {
    if (ref.current) {
      observer?.observe(ref.current);
    }

    return () => {
      observer?.disconnect();

      if (frameID.current) {
        cancelAnimationFrame(frameID.current);
      }
    };
  }, [ref.current]);

  return [ref, rect, viewportRect] as const;
}

export function useElementSize<T extends HTMLElement = any>(
  providedRef?: React.MutableRefObject<T>
): [React.MutableRefObject<T>, ObserverRect, ObserverRect] {
  const [ref, rect, viewportRect] = useResizeObserver<T>(providedRef);
  return [ref, rect, viewportRect] as [React.MutableRefObject<T>, ObserverRect, ObserverRect];
}
