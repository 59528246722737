import {
  useVGrid,
  VGrid,
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE,
  ROW_SELECTION_COUNT_ACTION_TYPE,
  TOGGLE_FILTERS_AND_SIDEBAR_ACTION_TYPE
} from '@oms/frontend-vgrid';
import type { GridBuilder } from '@oms/frontend-vgrid';
import { buildInvestorOrderViewTradingOrdersColumnDefs } from './investor-order-view.trading-orders.columns';
import { GridConfigEventHandler } from '@app/data-access/services/system/grids/grid-config.event-handler';
import { viewTradingOrderAction } from '../trading-order-monitor/grid-actions/view.trading-order.action';
import { modifyTradingOrderAction } from '../trading-order-monitor/grid-actions/modify.trading-order.action';
import { cancelTradingOrderAction } from '../trading-order-monitor/grid-actions/cancel.trading-order.action';
import {
  TOHasExecutedQuantity,
  TOHazard,
  TONoExecutedQuantity,
  TOPending
} from '../utils/row-state-rule-utils';
import { TsMappedTradingOrdersWithFilterDocument } from '@oms/generated/frontend';
import type {
  MappedTradingOrderRow,
  TsMappedTradingOrdersWithFilterSubscription
} from '@oms/generated/frontend';
import { withTableServer } from '@app/data-access/services/system/table-server/with.table-server.component';

type MappedTradingOrdersSubscriptionKeys = 'mapped_trading_orders';

type MappedTradingOrdersSubscriptionMap = {
  mapped_trading_orders: {
    datasource: MappedTradingOrderRow;
    subscription: TsMappedTradingOrdersWithFilterSubscription;
  };
};

type MappedTradingOrdersProps = { id: string };

export const InvestorOrderTradingOrdersGrid = withTableServer<
  MappedTradingOrdersSubscriptionKeys,
  MappedTradingOrdersSubscriptionMap,
  MappedTradingOrdersProps
>(
  ({ datasources, props: compProps = {} }) => {
    const { id } = compProps || {};
    const props = useVGrid<MappedTradingOrderRow>(
      'investor-order-trading-orders',
      (b: GridBuilder<MappedTradingOrderRow>) =>
        b
          .tableServerColumnLibrary(buildInvestorOrderViewTradingOrdersColumnDefs())
          .sideBar()
          .datasource((d) =>
            d
              .source(
                datasources.mapped_trading_orders.asServerSideRowModel({
                  filter: {
                    investorOrderId: {
                      filterType: 'text',
                      type: 'equals',
                      filter: id
                    }
                  }
                })
              )
              .rowId((r) => r.data.id)
              .cacheBlockSize(100)
          )
          .injectEvents([GridConfigEventHandler])
          .rowStateRules({
            pending: (params) => TOPending(params.data),
            noExecutedQuantity: (params) => TONoExecutedQuantity(params.data),
            hasExecutedQuantity: (params) => TOHasExecutedQuantity(params.data),
            hazard: (params) => TOHazard(params.data)
          })
          .rowSelection((c) => c.multiple())
          .actions((a) =>
            a.schema((s) =>
              s
                .action(TOGGLE_FILTERS_AND_SIDEBAR_ACTION_TYPE)
                .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(viewTradingOrderAction())
                .action(modifyTradingOrderAction())
                .action(cancelTradingOrderAction())
                .action(ROW_SELECTION_COUNT_ACTION_TYPE)
            )
          )
          .reactiveCustomComponents(),
      [datasources, id]
    );
    return <VGrid {...props} />;
  },
  {
    mapped_trading_orders: {
      query: TsMappedTradingOrdersWithFilterDocument,
      getData: (r) => r.tsMappedTradingOrdersWithFilter
    }
  }
);

export default InvestorOrderTradingOrdersGrid;
