import type { SetOperationOptions, SetWithOperations } from './set-operations.internal.types';

/**
 * Checks if `Set` instance has available operations methods: union, intersection and difference.
 */
const isSetWithOperations = <T>(set: Set<T>): set is SetWithOperations<T> => {
  const { union, intersection, difference } = Set.prototype as Partial<SetWithOperations<T>>;
  return (
    typeof union === 'function' && typeof intersection === 'function' && typeof difference === 'function'
  );
};

/**
 * Create a intersection of two sets. This is only the values both sets have in common.
 *
 * The `Set` type may have a `intersection` method, but it's newer and not always available, so this acts as a polyfill.
 * Use the `bypassNativeMethod` option to opt-out of using the native method when available.
 */
const intersection = <T>(of: Set<T>, and: Set<T>, options?: SetOperationOptions): Set<T> => {
  // If available, use the built-in JS method
  if (!options?.bypassNativeMethod && isSetWithOperations(of)) return of.intersection(and) as Set<T>;
  // If not, use the custom implementation
  const intersectionSet = new Set<T>();
  of.forEach((value) => {
    if (and.has(value)) intersectionSet.add(value);
  });
  return intersectionSet;
};

/**
 * Create a difference of two sets. This only items that are unique to the first set (intersecting items removed from the first set).
 *
 * The `Set` type may have a `union` method, but it's newer and not always available, so this acts as a polyfill.
 * Use the `bypassNativeMethod` option to opt-out of using the native method when available.
 */
const difference = <T>(of: Set<T>, and: Set<T>, options?: SetOperationOptions): Set<T> => {
  // If available, use the built-in JS method
  if (!options?.bypassNativeMethod && isSetWithOperations(of)) return of.difference(and) as Set<T>;
  // If not, use the custom implementation
  const differenceSet = new Set<T>();
  of.forEach((value) => {
    if (!and.has(value)) differenceSet.add(value);
  });
  return differenceSet;
};

export const setOperations = { intersection, difference };
