import { ComponentType, useCallback } from 'react';
import {
  GroupTableServerSubscription,
  TableServerQueries,
  TableServerSubscription,
  WithTableServer
} from './table-server.datasource.contracts';
import { Logger } from '@oms/ui-util';
import { ButtonProps } from '@oms/ui-design-system';
import { useTableServer } from './table-server.hook';
import { TableServerConnectionLoss } from './table-server.connection.loss';

const logger = Logger.named('withTableServer');

export const withTableServer = <
  TDatasourceKeys extends string,
  TDatasourceRecord extends Record<TDatasourceKeys, TableServerSubscription | GroupTableServerSubscription>,
  TProps = unknown
>(
  Component: ComponentType<WithTableServer<TDatasourceKeys, TDatasourceRecord, TProps>>,
  queries: TableServerQueries<TDatasourceKeys, TDatasourceRecord>
): ComponentType<TProps> => {
  const WithTableServer: ComponentType<TProps> = (props) => {
    const { datasources, reconnectService, isReconnecting, unstableConnection } = useTableServer<
      TDatasourceKeys,
      TDatasourceRecord
    >(queries);

    const onTryAgain: ButtonProps['onClick'] = useCallback(() => {
      logger.debug('♻️ Manual reconnect.');
      reconnectService.reconnect();
    }, [reconnectService]);

    return (
      <TableServerConnectionLoss
        onTryAgain={onTryAgain}
        isReconnecting={isReconnecting}
        unstableConnection={unstableConnection}
      >
        <Component datasources={datasources} props={props} />
      </TableServerConnectionLoss>
    );
  };

  return WithTableServer;
};
