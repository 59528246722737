import type { FC } from 'react';
import { useMemo } from 'react';
import { useRxMarketDataSubscription } from '@app/data-access/services/marketdata/components/market-data.hook';
import type { Level1Data } from '@app/data-access/services/marketdata/marketdata.common';
import { TableServerJoinedInvestorOrder } from '@oms/generated/frontend';
import { Flex, Text } from '@oms/ui-design-system';
import { format } from '@oms/ui-design-system';
import { getOrderSideClassName } from '@oms/frontend-foundation';
import { getADV, getFormattedVwap, getSuffix } from './investor-order-monitor.stats-header.utils';

const SummaryItem = ({ label, children }: { label: string; children: React.ReactNode }) => {
  return (
    <Flex direction="column">
      <Text type="gridTiny" sx={{ color: 'text.minor' }}>
        {label}
      </Text>
      <Text type="gridBase" sx={{ color: 'text.primary' }}>
        {children}
      </Text>
    </Flex>
  );
};

type VWAPProps = {
  side: TableServerJoinedInvestorOrder['side'];
  averagePrice: TableServerJoinedInvestorOrder['averagePrice'];
  vwap: Level1Data['vwap'];
  midPrice: Level1Data['midPrice'];
};

const VWAP: FC<VWAPProps> = ({ averagePrice, side, vwap, midPrice }) => {
  const formattedVwap = useMemo(() => getFormattedVwap({ midPrice, vwap }), [midPrice, vwap]);
  const suffix = useMemo(() => getSuffix({ averagePrice, side, vwap }), [averagePrice, side, vwap]);
  const className = useMemo(() => {
    if (!suffix) {
      return undefined;
    }
    return getOrderSideClassName(side, { isInactive: false });
  }, [side, suffix]);

  return (
    <>
      {formattedVwap} <Text className={className}>{suffix}</Text>
    </>
  );
};

export const InvestorOrderTradingOrdersStatsGroupHeader: FC<TableServerJoinedInvestorOrder> = ({
  instrumentDisplayCode,
  totalQuantity,
  averageFillSize,
  side,
  averagePrice,
  lastExecutionTime
}) => {
  const marketData = useRxMarketDataSubscription(instrumentDisplayCode || '');

  const level1 = marketData?.level1 || {};

  const adv = getADV({ quantity: totalQuantity, adv30day: level1.adv30day });

  const formattedExecutionTime = lastExecutionTime ? format('datetime', String(lastExecutionTime)) : '-';

  return (
    <Flex
      style={{
        minHeight: 48,
        paddingRight: 'calc(var(--ag-cell-horizontal-padding) * 2 - 1px)',
        paddingLeft:
          'calc((var(--ag-cell-horizontal-padding) - 1px * 2) + (var(--ag-cell-widget-spacing) + 16px))'
      }}
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        bgColor: 'layout.level2',
        width: 'full',
        gap: 'large'
      }}
      direction="row"
    >
      {/* Hardcoding '-' for now as per request from Product */}
      <SummaryItem label="Order VWAP">-</SummaryItem>
      <SummaryItem label="VWAP">
        <VWAP side={side} averagePrice={averagePrice} vwap={level1.vwap} midPrice={level1.midPrice} />
      </SummaryItem>
      <SummaryItem label="ADV %">{adv}</SummaryItem>
      <SummaryItem label="Last Execution Time">{formattedExecutionTime}</SummaryItem>
      <SummaryItem label="Avg Fill Size">{averageFillSize}</SummaryItem>
    </Flex>
  );
};
