import { type Sprinkles } from '@oms/ui-design-system';

type TabContentSx = Sprinkles;
type TabSpacing = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 12 | 13 | 14 | 16;
type Spacing = Sprinkles['padding'];

const combineSpacing = (...amounts: TabSpacing[]): Spacing => {
  const combined = Math.floor((amounts as number[]).reduce((acc, current) => acc + current, 0));
  const clamped = Math.max(Math.min(combined, 16), 0);
  const corrected = clamped === 11 || clamped === 15 ? clamped - 1 : clamped;
  return corrected as Spacing;
};

export const createTabsContentSpacingSx = (
  padding: TabSpacing,
  horizontalBleed?: TabSpacing
): TabContentSx => {
  const hBleed = horizontalBleed ?? 0;
  const combined = combineSpacing(padding, hBleed);
  const negative = `-${Math.abs(hBleed as number)}` as Spacing;
  return {
    paddingTop: padding,
    paddingBottom: padding,
    marginLeft: negative,
    paddingLeft: combined,
    marginRight: negative,
    paddingRight: combined
  };
};
