import React, { useMemo } from 'react';
import { useFieldApi, type UseFieldApiConfig } from '@data-driven-forms/react-form-renderer';
import {
  CheckboxButtonGroup as CheckboxButtonGroupComponent,
  CheckboxButton as CheckboxButtonComponent,
  type CheckboxButtonProps,
  FormControl,
  FormErrorMessage
} from '@oms/ui-design-system';
import { type ICheckboxButtonGroupField } from './checkbox-button-group.type';

export type CheckboxButtonGroupEnhancedProps = ICheckboxButtonGroupField &
  UseFieldApiConfig & {
    input: any;
    isDisabled?: boolean;
    label: string;
    isRequired?: boolean;
    providerRequired?: React.ReactNode;
    disabled: boolean;
    options?: CheckboxButtonProps[];
  };

/**
 * Model-driven checkbox-button field
 */
export const CheckboxButtonGroup: React.FC<CheckboxButtonGroupEnhancedProps> = React.memo((props) => {
  const { options = [] } = props;
  const {
    meta,
    label,
    input,
    isReadOnly,
    isRequired,
    isDisabled: _isDisabled,
    forceIsDisabled,
    isInvalid,
    requiredFieldIndicatorStyle
  } = useFieldApi(props);

  // Sometimes validators override the disabled props. If forceIsDisabled is true, then we should always disable the field.
  const isDisabled = !!forceIsDisabled || !!_isDisabled;

  const error = meta.touched && meta.error ? meta.error : '';

  const extraProps = { isReadOnly, isRequired, isDisabled, isInvalid, requiredFieldIndicatorStyle };

  const { value } = input;

  const componentProps = {
    label,
    ...(props.FieldProps || {}),
    ...input,
    checkedValues: Array.isArray(value) ? value : undefined
  };

  const items = useMemo(() => {
    return options.map((childProps) => {
      const key = `${childProps.name}:${childProps.value}:${childProps.label}`;
      return <CheckboxButtonComponent key={key} {...childProps} />;
    });
  }, [options]);

  return (
    <FormControl {...extraProps}>
      <CheckboxButtonGroupComponent {...componentProps}>{items}</CheckboxButtonGroupComponent>
      <FormErrorMessage>{error}&nbsp;</FormErrorMessage>
    </FormControl>
  );
});
