import type { MemoryDatabaseCollections } from './collections';
import { createLogger } from '@oms/ui-util';
import { forkJoin, fromEventPattern, tap, take } from 'rxjs';
import type { Observable } from 'rxjs';

const logger = createLogger({
  name: 'app-database-util'
});

/**
 * Creates an observable that emits when all the memory database collections are removed.
 *
 * @param {MemoryDatabaseCollections} collections - The memory database collections.
 * @returns {Observable<unknown[]>} An observable that emits when all the memory database collections are removed.
 */
export function createMemoryOnRemove$(collections: MemoryDatabaseCollections): Observable<unknown[]> {
  const collectionsArr = Object.values(collections);
  const onRemoveObservables = collectionsArr.map((collection, index) =>
    fromEventPattern(
      (handler) => collection.onRemove.push(handler),
      (handler) => {
        const index = collection.onRemove.indexOf(handler);
        if (index > -1) {
          collection.onRemove.splice(index, 1);
        }
      }
    ).pipe(
      tap(() => logger.debug(`Collection ${index} removed`)),
      take(1)
    )
  );

  return forkJoin(onRemoveObservables).pipe(
    tap(() => logger.info('All snapshot collections removed')),
    take(1)
  );
}
