import { format } from '../../formatting/format';
import type { FormatType, GetFormatUnionInput, GetFormatUnionOptions } from '../../formatting/format';

export type FormatterProps<T extends FormatType> = {
  type: T;
  value: GetFormatUnionInput<T>;
  options?: GetFormatUnionOptions<T>;
};

export function Formatter<T extends FormatType>({ type, value, options }: FormatterProps<T>) {
  return <>{format(type, value, options || ({} as GetFormatUnionOptions<T>))}</>;
}
