import { Center, Heading, Flex, Text } from '@oms/ui-design-system';
import { FC, PropsWithChildren } from 'react';

export interface BaseNoRowsOverlayProps {
  title?: string;
  description?: string;
}

export const BaseNoRowsOverlay: FC<PropsWithChildren<BaseNoRowsOverlayProps>> = ({
  title,
  description,
  children
}) => (
  <Center fillArea sx={{ backgroundColor: 'layout.level1' }}>
    <Flex direction="column" sx={{ gap: 4 }}>
      {title && <Heading type="mediumR">{title}</Heading>}
      {description && (
        <Text type="mediumR" sx={{ color: 'text.tertiary' }}>
          {description}
        </Text>
      )}
      {children}
    </Flex>
  </Center>
);

export const DEFAULT_EMPTY_GRID_COPY = 'This grid is empty';

export const DefaultNoRowsOverlay: FC<PropsWithChildren<BaseNoRowsOverlayProps>> = ({
  title = DEFAULT_EMPTY_GRID_COPY,
  description,
  children
}) => (
  <BaseNoRowsOverlay title={title} description={description}>
    {children}
  </BaseNoRowsOverlay>
);

export default BaseNoRowsOverlay;
