import { useService } from '@oms/frontend-foundation';
import { useState, useRef, useMemo, useEffect } from 'react';
import { createDatasources } from './table-server.datasource';
import {
  GroupTableServerSubscription,
  TableServerQueries,
  TableServerSubscription
} from './table-server.datasource.contracts';
import { TableServerReconnectSignal } from './table-server.reconnect.signal';
import { TableServerService } from './table-server.service';
import { Logger } from '@oms/ui-util';

const logger = Logger.named('useTableServer');

export const useTableServer = <
  TDatasourceKeys extends string,
  TDatasourceRecord extends Record<TDatasourceKeys, TableServerSubscription | GroupTableServerSubscription>
>(
  queries: TableServerQueries<TDatasourceKeys, TDatasourceRecord>
) => {
  const [unstableConnection, setUnstableConnection] = useState(false);
  const [isReconnecting, setReconnecting] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const tableserverService = useService(TableServerService);
  const reconnectService = useService(TableServerReconnectSignal);
  const { datasources, connectionStates$ } = useMemo(
    () => createDatasources(queries, tableserverService),
    [tableserverService]
  );

  useEffect(() => {
    const sub = connectionStates$.subscribe((connectionStates) => {
      logger.debug('🔍 Table server connection states', { connectionStates });
      setUnstableConnection(Object.values(connectionStates).includes('unstable'));
    });

    return () => {
      logger.debug('⚠️ Unsubscribing from connection states.');
      sub.unsubscribe();
    };
  }, [connectionStates$]);

  useEffect(() => {
    const sub = reconnectService.$.subscribe(() => {
      setReconnecting(true);
      timeoutRef.current && clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setReconnecting(false);
      }, 1_000);
    });

    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
      sub.unsubscribe();
    };
  }, [reconnectService]);

  return {
    reconnectService,
    isReconnecting,
    unstableConnection,
    datasources,
    connectionStates: connectionStates$.getValue()
  };
};
