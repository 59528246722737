import type { ColDef } from '@ag-grid-community/core';
import { cleanMaybe } from '@oms/ui-util';
import type { Maybe, Optional } from '@oms/ui-util';
import type {
  MontageItem,
  MontageItemType,
  MontagePricePartitionMap
} from '@app/data-access/services/trading/montage/montage.types';
import { rankToColorMap } from './depth.constants';
import type {
  DepthCellBackgroundColor,
  DepthCellRowFlagColor,
  DepthCellRowFlagType,
  DepthCellTextColor,
  DepthCellType
} from './depth.cell.types';
import { priceDisplayStyle } from '@oms/ui-design-system-css';

export const getDepthCellType = (type?: ColDef<MontageItem>['field']): DepthCellType => {
  switch (type) {
    case 'counterpartyId':
      return 'primary';
    case 'limitPrice':
      return 'price';
    default:
      return 'standard';
  }
};

export const getHeaderFor = (type: MontageItemType): string => {
  switch (type) {
    case 'bid':
      return 'Bid';
    case 'ask':
      return 'Ask';
  }
};

export const getBackgroundColor = (
  montageItem: Maybe<MontageItem>,
  pricePartitionMap: MontagePricePartitionMap
): DepthCellBackgroundColor => {
  const rank = (() => {
    const { id = '' } = cleanMaybe(montageItem, {});
    const { rank } = cleanMaybe(pricePartitionMap.get(id), {});
    return typeof rank === 'number' ? rank : -1;
  })();
  return rankToColorMap.get(rank) ?? 'inherit';
};

/** @deprecated Use `getClassName` and pass as `className` rather than `sx` color */
export const getCellTextColorByGridType = (type?: MontageItemType): DepthCellTextColor => {
  switch (type) {
    case 'ask':
      return 'Red.400';
    case 'bid':
      return 'text.green';
    default:
      return 'inherit';
  }
};

export const getClassNameByGridType = (type?: MontageItemType): Optional<string> => {
  switch (type) {
    case 'ask':
      return priceDisplayStyle({ type: 'negative' });
    case 'bid':
      return priceDisplayStyle({ type: 'positive' });
    default:
      return undefined;
  }
};

/** @deprecated Use `getClassName` and pass as `className` rather than `sx` color */
export const getCellTextColor = (
  cellType: DepthCellType,
  montageItem: Maybe<MontageItem>
): DepthCellTextColor => {
  switch (cellType) {
    case 'price': {
      return getCellTextColorByGridType(montageItem?.type);
    }
    default:
      return 'inherit';
  }
};

export const getClassName = (cellType: DepthCellType, montageItem: Maybe<MontageItem>): Optional<string> => {
  switch (cellType) {
    case 'price': {
      return getClassNameByGridType(montageItem?.type);
    }
    default:
      return undefined;
  }
};

export const getDepthCellRowFlagType = (montageItem: Maybe<MontageItem>): Optional<DepthCellRowFlagType> => {
  if (!montageItem) return undefined;
  const { isMyTeam, source } = montageItem;
  if (isMyTeam) {
    return 'myTeamOrder';
  } else if (source === 'unboundTradingOrder') {
    return 'myTradingOrder';
  } else {
    return undefined;
  }
};

export const getRowFlagColor = (flagType: DepthCellRowFlagType): DepthCellRowFlagColor => {
  switch (flagType) {
    case 'myTeamOrder':
      return 'border.orange';
    case 'myTradingOrder':
      return 'border.activeLight';
  }
};
