import type { LoggerOptions, NamelessLoggerOptions } from './logger.types';
import pino, { type Logger as PinoLogger } from 'pino';
import { createPinoLogger, DEFAULT_LOGGER_OPTS } from './pino.logger.factory';
import omit from 'lodash/omit';
import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import isError from 'lodash/isError';
import { asArray } from '../collections/collections.util';
import { getAppEnv, getEnvVar } from '../env/env.util';
import { OneOrMore } from '../types/collections';
import { LogLevel } from '../types/logging';

/**
 * Environment variable name to get the UI's log level.
 */
export const UI_LOG_LEVEL_ENV_NAME = 'UI_LOG_LEVEL';

declare global {
  interface Window {
    setLogLevel: (level: LogLevel) => void;
    clearLogLevel: () => void;
  }
}

let CACHED_LOG_LEVEL: LogLevel | undefined;

if (typeof window !== 'undefined') {
  if ('localStorage' in window && !window['setLogLevel']) {
    CACHED_LOG_LEVEL = window.localStorage.getItem(UI_LOG_LEVEL_ENV_NAME) as LogLevel;

    window['setLogLevel'] = (level: LogLevel) => {
      window.localStorage.setItem(UI_LOG_LEVEL_ENV_NAME, level);
      CACHED_LOG_LEVEL = level;
    };

    window['clearLogLevel'] = () => {
      window.localStorage.removeItem(UI_LOG_LEVEL_ENV_NAME);
      CACHED_LOG_LEVEL = undefined;
    };
  }
}

/**
 * A simple debug logger that defaults to logging to the console.
 */
export class Logger {
  private _logger: PinoLogger;
  private _options: LoggerOptions;
  private _scope?: string[];

  //  Constructor ------------------------------------------------ /

  protected constructor(options?: LoggerOptions) {
    this._options = merge({}, DEFAULT_LOGGER_OPTS, {
      ...(options || {}),
      // allows this logger to make their level independent of the env var for log level
      useLogLevelEnv: options?.level && options.useLogLevelEnv === undefined ? null : options?.useLogLevelEnv
    });
    this._options.useLogLevelEnv =
      this._options.useLogLevelEnv === undefined ? UI_LOG_LEVEL_ENV_NAME : this._options.useLogLevelEnv;

    this._logger = createPinoLogger(this._options);
  }

  /**
   * Creates a logger that can be enabled or disabled.
   *
   * ```ts
   * const logger = Logger.create({ name: 'MyLogger' });
   * logger.info('Hello, World!');
   * ```
   *
   * @param options.name - Prepends all logs with this label
   * @returns A logger instance to use for debugging
   */
  public static create(options?: LoggerOptions): Logger {
    return new Logger(options);
  }

  /**
   * Creates named logger that can be enabled or disabled.
   *
   * ```ts
   * const logger = Logger.named('MyLogger');
   * logger.info('Hello, World!');
   * ```
   *
   * @param name - All logs with this name
   * @returns A logger instance function to use for debugging
   */
  public static named(name: string, options?: NamelessLoggerOptions): Logger {
    return Logger.create({ name, ...(options ?? {}) });
  }

  // Presets ----------- /

  public static debug(options?: NamelessLoggerOptions): Logger {
    return Logger.named('DEBUG', options);
  }

  // 📢 Public ------------------------------------------------ /

  public get disabled(): boolean {
    return this._logger.level === 'silent';
  }

  public scope(scope: OneOrMore<string>): Logger {
    this._scope = asArray(scope);
    return this;
  }

  protected logAs<Args extends Array<any>>(level: LogLevel, ...args: Args): Logger {
    if (this._options.useLogLevelEnv) {
      this._logger.level = CACHED_LOG_LEVEL
        ? CACHED_LOG_LEVEL
        : getEnvVar(this._options.useLogLevelEnv, this._logger.level || 'error');
    }

    const msgKey = 'msg';
    const environmentKey = 'env';
    const versionKey = 'version';
    const loggerNameKey = 'name';
    const levelKey = 'level';
    const timeKey = 'time';

    const formattedLogMessage = args
      .filter((a) => a !== undefined || a !== null)
      .reduce<Record<string, any>>(
        (log, elem) => {
          const elemType = typeof elem;
          const errorKey = 'error';
          switch (elemType) {
            case 'object':
              log = mergeWith(
                log,
                isError(elem) ? { [errorKey]: pino.stdSerializers.errWithCause(elem) } : elem,
                (obj, src) => {
                  const objErr = isError(obj) ? pino.stdSerializers.errWithCause(obj) : undefined;
                  const srcErr = isError(src) ? pino.stdSerializers.errWithCause(src) : undefined;

                  return srcErr || objErr || undefined;
                }
              );
              break;
            case 'string':
            case 'bigint':
            case 'boolean':
            case 'number':
              log[msgKey] = `${log[msgKey] ? `${log[msgKey]}; ` : ''}${elem}`;
              break;
          }

          return log;
        },
        {} as Record<string, any>
      );
    const logger = this.buildScopedLogger(this._options.name, this._scope || []);

    const extraContext = {
      [environmentKey]: getAppEnv().toFullEnv(),
      [versionKey]: getEnvVar('NX_RELEASE_VERSION') || 'UNKNOWN',
      [loggerNameKey]: logger || 'Default',
      [levelKey]: level.toUpperCase(),
      [timeKey]: new Date().toISOString()
    };

    this._logger[level](
      omit({ ...formattedLogMessage, ...extraContext }, msgKey),
      formattedLogMessage[msgKey]
    );

    return this;
  }

  public info<Args extends Array<any>>(...args: Args): Logger {
    return this.logAs('info', ...args);
  }

  public trace<Args extends Array<any>>(...args: Args): Logger {
    return this.logAs('trace', ...args);
  }

  public debug<Args extends Array<any>>(...args: Args): Logger {
    return this.logAs('debug', ...args);
  }

  public log<Args extends Array<any>>(...args: Args): Logger {
    // https://developer.chrome.com/docs/devtools/console/log
    return this.logAs('info', ...args);
  }

  public warn<Args extends Array<any>>(...args: Args): Logger {
    return this.logAs('warn', ...args);
  }

  public error<Args extends Array<any>>(...args: Args): Logger {
    return this.logAs('error', ...args);
  }

  public fatal<Args extends Array<any>>(...args: Args): Logger {
    return this.logAs('fatal', ...args);
  }

  public disable(): Logger {
    this._logger.level = 'silent';
    return this;
  }

  public enable(level: LogLevel = 'error'): Logger {
    this._logger.level = level;
    return this;
  }

  public changeName(name: string): Logger {
    this._options.name = name;
    return this;
  }

  public resetName(): Logger {
    this._options.name = undefined;
    return this;
  }

  public reset(): Logger {
    this.resetName();
    this.resetScope();

    return this;
  }

  public setLevel(level: LogLevel) {
    this._logger.level = level;
    return this;
  }

  // 🔒 Protected / private ------------------------------------------------ /

  protected resetScope(): Logger {
    this._scope = [];
    return this;
  }

  protected buildScopedLogger(name?: string, scope?: string[]): string {
    return [name, ...(scope ?? [])]
      .reduce((scopedLabel, component) => {
        if (component) scopedLabel.push(component);
        return scopedLabel;
      }, [] as string[])
      .join('.');
  }
}
