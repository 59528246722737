import { forwardRef } from 'react';
import clsx from 'clsx';
import * as styles from './css/switch.css';
import { inputOffsetOutline } from '../../system/styles.css';
import { Box } from '../../system/components/box/box';
import { Sprinkles } from '../../system/sprinkles.css';
import { __DEV__ } from '../../system/utils/assertion';
import { dataAttr } from '../../system/utils/dom';
import { CheckboxProps } from '../checkbox/checkbox';
import { useFormControl } from '../form-control/form-control';
import { VisuallyHidden } from '../visually-hidden/visually-hidden';

type OmittedProps = 'variant' | 'isIndeterminate' | 'isReadOnly' | 'size';

export type SwitchProps = {
  color?: Sprinkles['color'];
  // CheckboxProps has a 'size' prop that supports only 'sm' and 'md'. Switch should support 'lg' too.
  size?: 'sm' | 'md' | 'lg';
} & Omit<CheckboxProps, OmittedProps>;

export const Switch = forwardRef<HTMLInputElement, SwitchProps>((props, ref) => {
  const {
    id,
    name,
    value,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    'aria-describedby': ariaDescribedby,
    color = 'primary',
    isDefaultChecked,
    isChecked: _isChecked,
    checked,
    isDisabled: _isDisabled,
    size = 'md',
    onChange,
    children,
    className,
    tabIndex,
    isInvalid: _isInvalid,
    sx = {},
    ...rest
  } = props;

  const isChecked = _isChecked !== undefined ? _isChecked : checked;

  const { isDisabled, isInvalid } = useFormControl(props);

  return (
    <Box
      as="label"
      data-testid="switch"
      sx={{
        ...sx,
        display: 'flex',
        alignItems: 'center'
      }}
      className={clsx(styles.switchLabel, isDisabled && styles.switchLabelDisabled, className)}
      {...rest}
    >
      <VisuallyHidden
        as="input"
        type="checkbox"
        data-testid="checkbox"
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        id={id}
        ref={ref}
        name={name}
        value={value}
        aria-invalid={dataAttr(isInvalid)}
        defaultChecked={isDefaultChecked}
        onChange={onChange}
        checked={isChecked}
        data-disabled={isDisabled}
        disabled={isDisabled}
        tabIndex={tabIndex}
      />
      <div
        className={clsx('input-switch', inputOffsetOutline, styles.switchRecipe({ size }))}
        data-disabled={isDisabled}
        data-color={color ? color : undefined}
      >
        <div className={clsx(styles.switchToggleRecipe({ size }))} />
      </div>

      {children && (
        <Box as="span" sx={{ marginLeft: 2 }}>
          {children}
        </Box>
      )}
    </Box>
  );
});
if (__DEV__) {
  Switch.displayName = 'Switch';
}
