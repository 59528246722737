import { InvestorAccountType, TagCode } from '@oms/generated/frontend';
import type { OrderSide } from '@oms/generated/frontend';
import { useService } from '@oms/frontend-foundation';
import { useMemo, useState, useEffect } from 'react';
import type { FC } from 'react';
import { MatchableInvestorOrdersSubscriptionService } from '@app/data-access/services/trading/investor-orders/matchable-investor-orders.subscriptions.service';
import { Spinner, DisplayGrid } from '@oms/ui-design-system';
import type { GridColValues, ResponsiveValue, DisplayGridProps } from '@oms/ui-design-system';
import { useMontageState } from '@app/data-access/services/trading/montage/hooks/use-montage-state.hook';
import { PositionsService } from '@app/data-access/services/trading/positions/positions.service';
import type { InvestorOrderRow } from '@oms/generated/frontend';

export type MatchedInvestorOrderSummaryProps = {
  orders: InvestorOrderRow[];
  side?: OrderSide;
  instrumentId?: string;
  showTradeAlong?: boolean;
  accountId?: string;
  isModifyMode?: boolean;
};

const getOrdersSummaryData = (orders: InvestorOrderRow[], allOrders: InvestorOrderRow[]) => ({
  ...getOrdersTotals(orders),
  tradeAlong: getIsTradeAlongAllowed(allOrders)
});

const getOrdersTotals = (orders: InvestorOrderRow[]) => {
  return orders.reduce(
    (acc, order) => {
      return {
        openQuantity: acc.openQuantity + (order?.openQuantity ?? 0),
        workingQuantity: acc.workingQuantity + (order?.workingQuantity ?? 0)
      };
    },
    {
      openQuantity: 0,
      workingQuantity: 0
    }
  );
};

const getIsTradeAlongAllowed = (orders: InvestorOrderRow[]) => {
  const isAtLeastOneFirmOrder = orders.find(
    (order) => order.investorAccountType === InvestorAccountType.Firm
  );
  const allClientOrdersHaveTAOKTag = orders.every((order) =>
    order.investorAccountType === InvestorAccountType.Client
      ? order.orderTagIds && order.orderTagIds.some((tag) => tag === TagCode.Taok)
      : true
  );

  return isAtLeastOneFirmOrder && allClientOrdersHaveTAOKTag;
};

const getDisplayGridItems = (
  values: {
    positionQuantity: number;
    openQuantity: number;
    workingQuantity: number;
    tradeAlong?: boolean;
  },
  displayTradeAlong?: boolean
): DisplayGridProps['items'] => {
  const gridItems: DisplayGridProps['items'] = [
    {
      label: 'W',
      component: {
        type: 'Numeric',
        value: values.workingQuantity
      }
    },
    {
      label: 'O',
      component: {
        type: 'Numeric',
        value: values.openQuantity
      }
    },
    {
      label: 'P',
      component: {
        type: 'Numeric',
        value: values.positionQuantity
      }
    }
  ];

  if (displayTradeAlong) {
    gridItems.push({
      colSpan: 2,
      label: 'Trade Along',
      component: {
        type: 'Text',
        value: values.tradeAlong ? 'Yes' : 'No'
      }
    });
  }

  return gridItems;
};

const getDisplayGridPosition = (positionQuantity: number): DisplayGridProps['items'] => {
  return [
    {
      label: 'P',
      component: {
        type: 'Numeric',
        value: positionQuantity
      }
    }
  ];
};

const getGridProps = (numberOfColumns: ResponsiveValue<GridColValues>): DisplayGridProps['gridProps'] => ({
  columns: numberOfColumns,
  rows: 1,
  columnGap: 0
});

export const MatchedInvestorOrderSummary: FC<MatchedInvestorOrderSummaryProps> = ({
  orders,
  side,
  showTradeAlong,
  instrumentId,
  accountId,
  isModifyMode = false
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allInvestorOrders, setAllInvestorOrders] = useState<InvestorOrderRow[]>([]);
  const { instrumentId: instrumentIdForMontage } = useMontageState();
  const [positionQuantity, setPositionQuantity] = useState<number>(0);
  const datasourceService = useService(MatchableInvestorOrdersSubscriptionService);
  const positionsService = useService(PositionsService);

  useEffect(() => {
    const useInstrumentId = instrumentId || instrumentIdForMontage;
    if (!useInstrumentId || !side) {
      return setAllInvestorOrders([]);
    }
    setIsLoading(true);
    const sub = datasourceService
      .queryMatchableInvestorOrders$({
        side,
        instrumentId: useInstrumentId
      })
      .subscribe((data) => {
        setIsLoading(false);

        if (data.errors) {
          return;
        }
        if (data.rows) {
          setAllInvestorOrders(data.rows);
        }
      });
    return () => {
      sub.unsubscribe();
    };
  }, [datasourceService, instrumentId, instrumentIdForMontage, side]);

  useEffect(() => {
    const useInstrumentId = instrumentId || instrumentIdForMontage;
    if (!useInstrumentId || !accountId) {
      return setPositionQuantity(0);
    }

    const sub = positionsService
      .watchQuery_getMontagePositionQuantityWithAccountType$(useInstrumentId, accountId)
      .subscribe(({ quantity, accountType }) => {
        // show positions only for firm accounts
        setPositionQuantity(accountType === 'FIRM' ? quantity : 0);
      });
    return () => {
      sub.unsubscribe();
    };
  }, [positionsService, instrumentId, instrumentIdForMontage, accountId]);

  const isTradeAlongDisplayed = showTradeAlong && !!allInvestorOrders;

  const displayGridItems = useMemo(() => {
    if (isModifyMode) {
      return getDisplayGridPosition(positionQuantity);
    }
    return getDisplayGridItems(
      {
        positionQuantity: positionQuantity,
        ...getOrdersSummaryData(orders, allInvestorOrders)
      },
      isTradeAlongDisplayed
    );
  }, [orders, allInvestorOrders, isTradeAlongDisplayed, positionQuantity, isModifyMode]);

  if (isLoading) {
    return <Spinner style={{ opacity: 0.33 }} />;
  }

  return (
    <DisplayGrid
      items={displayGridItems}
      width="100%"
      gridProps={getGridProps(
        (isTradeAlongDisplayed ? displayGridItems.length + 1 : displayGridItems.length) as GridColValues
      )}
      labelMargin={6}
    />
  );
};
