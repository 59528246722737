import { DatetimePickerField } from './datetime-picker/datetime-picker';
import { TimePickerField } from './time-picker/time-picker';
import { DropdownList } from './dropdown-list/dropdown-list';
import { Label } from './label/label';
import { Text } from './text/text';
import { SingleSelectList } from './single-select-list/single-select-list';
import { MultiSelectList } from './multi-select-list/multi-select-list';
import { EditableDropdownList } from './editable-dropdown-list/editable-dropdown-list';
import { SingleSpinner } from './single-spinner/single-spinner';
import { DoubleSpinner } from './double-spinner/double-spinner';
import { HiddenField } from './hidden-field/hidden-field';
import { Checkbox } from './checkbox/checkbox';
import { CheckboxList } from './checkbox-list/checkbox-list';
import { StrategyPanel } from './strategy-panel/strategy-panel';
import { RadioButton } from './radio-button/radio-button';
import { RadioButtonList } from './radio-button-list/radio-button-list';
import { Slider } from './slider/slider';
import { DatePickerField } from './date-picker/date-picker';
import type { EnumAsUnion } from '@oms/ui-util';
import type { ComponentMapper } from '@data-driven-forms/react-form-renderer';

export enum fixatdlFormComponentTypes {
  DROPDOWN_LIST = 'dropdown-list',
  SINGLE_SPINNER = 'single-spinner',
  TIME_PICKER = 'time-picker',
  DATE_PICKER = 'date-picker',
  DATETIME_PICKER = 'datetime-picker',
  DOUBLE_SPINNER = 'double-spinner',
  TEXT = 'text',
  LABEL = 'label',
  SINGLE_SELECT_LIST = 'single-select-list',
  HIDDEN_FIELD = 'hidden-field',
  CHECKBOX = 'checkbox',
  SLIDER = 'slider',
  MULTI_SELECT_LIST = 'multi-select-list',
  EDITABLE_DROPDOWN_LIST = 'editable-dropdown-list',
  CHECKBOX_LIST = 'checkbox-list',
  STRATEGY_PANEL = 'strategy-panel',
  RADIO_BUTTON = 'radio-button',
  RADIO_BUTTON_LIST = 'radio-button-list'
}

export type FixatdlFormComponentType = EnumAsUnion<typeof fixatdlFormComponentTypes>;

export const fixatdlFormComponentMapper: ComponentMapper = {
  [fixatdlFormComponentTypes.DATETIME_PICKER]: DatetimePickerField,
  [fixatdlFormComponentTypes.TIME_PICKER]: TimePickerField,
  [fixatdlFormComponentTypes.DATE_PICKER]: DatePickerField,
  [fixatdlFormComponentTypes.DROPDOWN_LIST]: DropdownList,
  [fixatdlFormComponentTypes.SINGLE_SPINNER]: SingleSpinner,
  [fixatdlFormComponentTypes.DOUBLE_SPINNER]: DoubleSpinner,
  [fixatdlFormComponentTypes.TEXT]: Text,
  [fixatdlFormComponentTypes.LABEL]: Label,
  [fixatdlFormComponentTypes.SINGLE_SELECT_LIST]: SingleSelectList,
  [fixatdlFormComponentTypes.HIDDEN_FIELD]: HiddenField,
  [fixatdlFormComponentTypes.CHECKBOX]: Checkbox,
  [fixatdlFormComponentTypes.RADIO_BUTTON_LIST]: RadioButtonList,
  [fixatdlFormComponentTypes.SLIDER]: Slider,
  [fixatdlFormComponentTypes.MULTI_SELECT_LIST]: MultiSelectList,
  [fixatdlFormComponentTypes.EDITABLE_DROPDOWN_LIST]: EditableDropdownList,
  [fixatdlFormComponentTypes.CHECKBOX_LIST]: CheckboxList,
  [fixatdlFormComponentTypes.STRATEGY_PANEL]: StrategyPanel,
  [fixatdlFormComponentTypes.RADIO_BUTTON]: RadioButton
};
