import { Plugin } from '@valstro/workspace';
import type { AppWorkspace } from '@app/app-config/workspace.config';
import type { ApolloClientLinkType } from '@app/data-access/api/apollo-client';
import { createOrUpdateApolloClient } from '@app/data-access/api/apollo-client';
import { getPersistedAuthToken } from '@app/common/auth/auth.helpers';
import { Logger } from '@oms/ui-util';
import type { WildcardMockLink } from 'wildcard-mock-link';
import type { DependencyContainer } from 'tsyringe';
import { DataAccessSignal } from '@app/data-access/memory/data-access.signal';
import { AuthSignal } from '@app/data-access/memory/auth.signal';
import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import { Subscription } from 'rxjs';
import { GraphqlSocketSignal } from '@app/data-access/memory/graphql.socket.signal';
import { ApolloHTTPSetup } from './data-access.types';
import { DataAccessContext, dataAccessState$ } from './data-access.operator';
import { GraphqlReconnectSignal } from '@app/data-access/memory/graphql.reconnect.signal';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';

const logger = Logger.named('Data Access Plugin');

export interface DataAccessPluginOptions {
  apolloMockLink?: WildcardMockLink;
  container: DependencyContainer;
  auditTime?: number;
}

/**
 * Manages data access
 */
export const dataAccessPlugin = ({ container, apolloMockLink, auditTime = 1_000 }: DataAccessPluginOptions) =>
  Plugin.create<AppWorkspace>({
    name: 'valstro-data-access-plugin',
    pluginFn: ({ workspace }) => {
      let sub: Subscription | undefined;
      let dataAccessSignalService: DataAccessSignal;
      let graphqlSocketSignalService: GraphqlSocketSignal;
      let alreadyBootstrapped = false;

      workspace.addHook('leaderElection', ({ isLeader }) => {
        if (alreadyBootstrapped) return;

        alreadyBootstrapped = true;

        if (sub) {
          sub.unsubscribe();
        }

        dataAccessSignalService = container.resolve(DataAccessSignal);
        dataAccessSignalService.auditTime = auditTime;
        graphqlSocketSignalService = container.resolve(GraphqlSocketSignal);

        const authSignalService = container.resolve(AuthSignal);
        const authSignal = authSignalService.signal;
        const dataAccessSignal = dataAccessSignalService.signal;
        const graphqlSocketSignal = graphqlSocketSignalService.signal;

        const httpSetup = setupApolloClientHTTP(container, isLeader, apolloMockLink);

        const dataAccessContext: DataAccessContext = {
          hasConnectedBefore: false,
          authSignal,
          graphqlSocketSignal,
          httpSetup,
          dataAccessSignal,
          reconnectSignal: container.resolve(GraphqlReconnectSignal)
        };

        sub = dataAccessState$(dataAccessContext).subscribe();
      });

      return async function unsubscribe() {
        alreadyBootstrapped = false;
        sub?.unsubscribe();
        graphqlSocketSignalService.reset();
        dataAccessSignalService.reset();
      };
    }
  });

function setupApolloClientHTTP(
  container: DependencyContainer,
  isLeader: boolean,
  apolloMockLink?: WildcardMockLink
): ApolloHTTPSetup {
  const getAuthToken = () => {
    const authToken = getPersistedAuthToken();
    return authToken ?? '';
  };

  const linkType: ApolloClientLinkType = apolloMockLink ? 'mock' : 'auth-http';

  const client = createOrUpdateApolloClient(linkType, {
    getAuthToken,
    isLeader,
    mockLink: apolloMockLink,
    signal: container.resolve(GraphqlSocketSignal)
  });

  container.register(RxApolloClient, { useValue: client });
  container.register(GQLResponse, { useValue: new GQLResponse(client) });

  logger.log('Registered Apollo Client instance & GraphQL HTTP Client');

  return {
    container,
    client,
    isLeader,
    getAuthToken,
    apolloMockLink,
    teardown: () => {
      client.stop();
      logger.log('Stopped Apollo Client instance & GraphQL WS Client');
    }
  };
}
