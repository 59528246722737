import type { SetFilterModel } from '@ag-grid-community/core';
import type { AnyRecord } from '@oms/ui-util';
import type {
  FilterModels,
  KeyedSetFilterModel,
  FilterKey,
  SplitColumnFilters
} from '../../types/grid-filter.types';

// 📦 Grid filter model utils -------------------------------------------------------------- /
// Utils to manage grid filter models

/**
 * Type predicate to check if an ag-Grid filter mode is a set filter
 */
export const isSetFilterModel = (filterModel?: unknown): filterModel is SetFilterModel =>
  typeof filterModel === 'object' && filterModel !== null
    ? (filterModel as Partial<SetFilterModel>).filterType === 'set'
    : false;

/**
 * @param filterModels - Filter model object from ag-Grid
 * @returns An array of ag-Grid filters with key added (rather then the key of object)
 */
export const toKeyedSetFilters = <TData extends AnyRecord>(
  filterModels?: FilterModels<TData>
): KeyedSetFilterModel<TData>[] => {
  if (!filterModels) return [];
  return Object.entries(filterModels).reduce((keyedFilters, [key, filterModel]) => {
    if (isSetFilterModel(filterModel)) {
      keyedFilters.push({
        key: key as FilterKey<TData>,
        ...filterModel
      });
    }
    return keyedFilters;
  }, [] as KeyedSetFilterModel<TData>[]);
};

/**
 * Separates the ag-Grid auto column from other columns
 *
 * @param filterModels - Filter model object from ag-Grid
 * @returns An object with model for the ag-Grid auto column and an array of any other filters with key added (rather then the key of object)
 */
export const splitColumnFilters = <TData extends AnyRecord>(
  filterModels: FilterModels<TData>
): SplitColumnFilters<TData> => {
  return Object.entries(filterModels).reduce(
    (splitColumnFilters, [_key, filterModel]) => {
      if (isSetFilterModel(filterModel)) {
        const key = _key as FilterKey<TData>;
        switch (key) {
          case 'ag-Grid-AutoColumn':
            splitColumnFilters.autoColumn = {
              key,
              ...filterModel
            };
            break;
          default:
            splitColumnFilters.columns.push({
              key,
              ...filterModel
            });
        }
      }
      return splitColumnFilters;
    },
    { columns: [] } as SplitColumnFilters<TData>
  );
};
