import { testScoped } from '@app/workspace.registry';
import { singleton } from 'tsyringe';
import { ReconnectSignal } from './reconnect.signal';

@testScoped
@singleton()
export class GraphqlReconnectSignal extends ReconnectSignal {
  constructor() {
    super('graphql_reconnect');
  }
}
