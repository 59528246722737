import type { Optional } from '@oms/ui-util';
import type { SummaryGridField } from '@oms/frontend-foundation';
import { getOrderSideClassName, isOrderInactive } from '@oms/frontend-foundation';
import type { InvestorOrderRow, Strategy, StrategyParameter, TradingOrderRow } from '@oms/generated/frontend';
import { getDurationInMMSS } from '@app/forms/common/validators/validate-duration-input/validate-duration-input.validator';
import { mapSettleType } from '../mappers/map-settle-type';
import { get } from 'lodash';
import { AtdlFormats } from '@valstro/fixatdl-core';
import { translateEnum } from '../mappers/translate-enum';
import type { FieldMetadata } from './common.types';

type OrderFieldMetadata = FieldMetadata<InvestorOrderRow | TradingOrderRow>;

const ORDER_SUMMARY_FIELDS_MAP: Partial<
  Record<keyof TradingOrderRow | keyof InvestorOrderRow, OrderFieldMetadata>
> = {
  id: { label: 'Order ID' },
  averagePrice: { label: 'Average price' },
  captureTimestamp: { label: 'Capture timestamp', format: 'datetime' },
  clientOrderId: { label: 'Client order ID', format: 'text' },
  createdTimestamp: { label: 'Created timestamp', format: 'datetime' },
  updatedTime: { label: 'Updated time', format: 'datetime' },
  lastExecutionTime: { label: 'Last execution time', format: 'datetime' },
  customerNotes: { label: 'Customer notes' },
  venueNotes: { label: 'Venue notes' },
  displayQuantity: { label: 'Display quantity', format: 'quantity' },
  enteredByEmail: { label: 'Entered By' },
  executedQuantity: { label: 'Executed quantity', format: 'quantity' },
  expiryDateTime: { label: 'Expiry dateTime', format: 'datetime' },
  fixMessage: { label: 'Fix message' },
  instrument: { label: 'Instrument ID' },
  instrumentDisplayCode: { label: 'Symbol' },
  investorAccountId: { label: 'Account ID' },
  investorAccountName: { label: 'Account name' },
  investorAccountType: { label: 'Account type' },
  tradingAccountName: { label: 'Account name' },
  tradingAccountType: { label: 'Account type' },
  leavesQuantity: { label: 'Leaves quantity', format: 'quantity' },
  limitPrice: { label: 'Limit price' },
  locate: { label: 'Locate' },
  minQuantity: { label: 'Min quantity', format: 'quantity' },
  notionalAmount: { label: 'Notional amount' },
  openQuantity: { label: 'Open quantity', format: 'quantity' },
  commissionRateType: { label: 'Commission rate type' },
  commissionRateValue: { label: 'Commission rate value' },
  feesTotal: { label: 'Fees total', format: 'price' },
  marketChargeTotal: { label: 'Market charges total', format: 'price' },
  orderComments: { label: 'Order comments' },
  orderEntryType: { label: 'Order entry type' },
  orderTagIds: { label: 'Order tags' },
  orderType: { label: 'Order type' },
  ownerEmail: { label: 'Owner' },
  totalQuantity: { label: 'Quantity', format: 'quantity' },
  representativeCode: { label: 'Representative code' },
  sendingDesk: { label: 'Sending desk' },
  settleCurrency: { label: 'Settle currency' },
  settleDate: { label: 'Settle date', format: 'date' },
  settleType: {
    label: 'Settle type',
    mapper: ({ value }) => (typeof value === 'string' ? mapSettleType(value) : '')
  },
  side: {
    label: 'Side',
    mapper: ({ value }) => (typeof value === 'string' ? translateEnum('side', value) : ''),
    classNameMapper: ({ value, data }) =>
      typeof value === 'string' ? getOrderSideClassName(value, { isInactive: isOrderInactive(data) }) : ''
  },
  status: { label: 'Status' },
  stopPrice: { label: 'Stop price' },
  timeInForce: { label: 'Time in force' },
  tifDuration: {
    label: 'TIF Duration',
    mapper: ({ value }) => (typeof value === 'number' ? getDurationInMMSS(value) : undefined)
  },
  tradeCurrency: { label: 'Trade currency' },
  transmittedTimestamp: { label: 'Transmitted timestamp', format: 'datetime' },
  updatedTimestamp: { label: 'Updated timestamp', format: 'datetime' },
  receivedTimestamp: { label: 'Received timestamp', format: 'datetime' },
  underlyingAccount: { label: 'Underlying account' },
  validatedTimestamp: { label: 'Validated timestamp', format: 'datetime' },
  workingQuantity: { label: 'Working quantity', format: 'quantity' },
  capacity: { label: 'Capacity' },
  venue: { label: 'Route to' },
  targetExchangeId: { label: 'Target exchange' },
  workflow: { label: 'Workflow' },
  fixWorkflowId: { label: 'FIX workflow ID' },
  todayExecutedQuantity: { label: "Today's executed quantity" },
  todayAveragePrice: { label: "Today's average price" },
  category: { label: 'Category' }
} as const;

export type OrderSummaryFieldName = keyof typeof ORDER_SUMMARY_FIELDS_MAP;

const orderSummaryFields = (
  fieldNames: OrderSummaryFieldName[],
  order: Partial<TradingOrderRow>
): SummaryGridField[] => {
  return fieldNames.map((fieldName) => {
    const { label, format, classNameMapper } = (ORDER_SUMMARY_FIELDS_MAP[fieldName] ||
      {}) as Partial<OrderFieldMetadata>;
    const value = get(order, fieldName, '');
    return {
      label: label || fieldName,
      fieldName,
      format,
      className: classNameMapper ? classNameMapper({ value, data: order }) : undefined
    };
  });
};

const orderSummaryValues = (fieldNames: OrderSummaryFieldName[], order: Partial<TradingOrderRow>) => {
  return [
    fieldNames.reduce(
      (acc, fieldName) => {
        const fieldDef = ORDER_SUMMARY_FIELDS_MAP[fieldName] as Partial<OrderFieldMetadata>;
        const { mapper } = fieldDef;
        // Using lodash's get instead of reimplementing nested fields with . again
        const value = get(order, fieldName, undefined);
        if (value === null || value === undefined) {
          return { ...acc, [fieldName]: undefined };
        }
        const mappedValue = (mapper ? mapper({ value, data: order }) : undefined) || String(value);
        return { ...acc, [fieldName]: mappedValue };
      },
      {} as Partial<Record<OrderSummaryFieldName, Optional<string>>>
    )
  ];
};

export const orderSummaryData = (
  label: string,
  fields: (keyof typeof ORDER_SUMMARY_FIELDS_MAP)[],
  order: Partial<TradingOrderRow>
) => {
  return {
    label,
    value: orderSummaryValues(fields, order),
    fields: orderSummaryFields(fields, order)
  };
};

export const orderStrategyParametersSummaryData = (label: string, order: TradingOrderRow) => {
  const strategy = order.strategyDetails
    ? (JSON.parse(order.strategyDetails as string) as Strategy)
    : undefined;

  const strategyName = strategy?.name || 'strategy-name';
  const fields: SummaryGridField[] = [
    {
      fieldName: strategyName,
      label: 'Strategy'
    }
  ];
  const data: Record<string, any> = { [strategyName]: strategy?.uirep || strategyName };

  (strategy?.parameters || []).forEach((param, index) => {
    const { name, label, value, uiRep } = param as StrategyParameter;
    const fieldValue = (uiRep || AtdlFormats.coerceEmpty(value) || '-') as string;

    const fieldName = name || `${strategyName}-${index}`;
    fields.push({
      fieldName,
      label: label || fieldName
    });
    data[fieldName] = fieldValue;
  }, [] as SummaryGridField[]);

  return { label, fields, value: [data] };
};
