import { useVGrid, VGrid, CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE } from '@oms/frontend-vgrid';
import { inlineTradingOrderActions } from '../trading-order-monitor/grid-actions/inline.trading-order.actions';
import { openEntryTradingOrderAction } from '../trading-order-monitor/grid-actions/open-entry.trading-order.action';
import { cancelTradingOrderAction } from '../trading-order-monitor/grid-actions/cancel.trading-order.action';
import { modifyTradingOrderAction } from '../trading-order-monitor/grid-actions/modify.trading-order.action';
import { viewTradingOrderAction } from '../trading-order-monitor/grid-actions/view.trading-order.action';
import { refreshServersideCustomMenuAction } from '@app/common/grids/actions/refresh-serverside.action';
import { memo } from 'react';
import { buildInvestorOrderTradingOrderMonitorColumnDefs } from '../trading-order-monitor/trading-order-monitor.columns';
import { TsMappedTradingOrdersWithFilterDocument } from '@oms/generated/frontend';
import type {
  MappedTradingOrderRow,
  TradingOrderRow,
  TsMappedTradingOrdersWithFilterSubscription
} from '@oms/generated/frontend';
import {
  TOHasExecutedQuantity,
  TOHazard,
  TONoExecutedQuantity,
  TOPending
} from '../utils/row-state-rule-utils';
import { withTableServer } from '@app/data-access/services/system/table-server/with.table-server.component';
import { WithTableServer } from '@app/data-access/services/system/table-server/table-server.datasource.contracts';

type TradingOrderSubscriptionKeys = 'trading_order_details';

type TradingOrderSubscriptionMap = {
  trading_order_details: {
    datasource: MappedTradingOrderRow;
    subscription: TsMappedTradingOrdersWithFilterSubscription;
  };
};

export type TradingOrderDetailGridProps = {
  investorOrderId: string;
};

export const TradingOrdersDetailGrid = withTableServer<
  TradingOrderSubscriptionKeys,
  TradingOrderSubscriptionMap,
  TradingOrderDetailGridProps
>(
  memo(
    ({
      datasources,
      props
    }: WithTableServer<
      TradingOrderSubscriptionKeys,
      TradingOrderSubscriptionMap,
      TradingOrderDetailGridProps
    >) => {
      const { investorOrderId } = props || {};
      const gridProps = useVGrid<TradingOrderRow>(
        `investor-order-${investorOrderId || ''}-trading-orders`,
        (builder) =>
          builder
            .tableServerColumnLibrary(buildInvestorOrderTradingOrderMonitorColumnDefs())
            .rowSelection((c) => c.multiple())
            .datasource((d) =>
              d
                .source(
                  datasources.trading_order_details.asServerSideRowModel({
                    filter: {
                      investorOrderId: {
                        filterType: 'text',
                        type: 'equals',
                        filter: investorOrderId
                      }
                    }
                  })
                )
                .rowId((r) => r.data.id)
                .cacheBlockSize(100)
            )
            .rowStateRules({
              pending: (params) => TOPending(params.data),
              noExecutedQuantity: (params) => TONoExecutedQuantity(params.data),
              hasExecutedQuantity: (params) => TOHasExecutedQuantity(params.data),
              hazard: (params) => TOHazard(params.data)
            })
            .toolbarStrategies('hidden')
            .actions((a) =>
              a.schema((s) =>
                s
                  .action(openEntryTradingOrderAction())
                  .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
                  .action(viewTradingOrderAction())
                  .action(modifyTradingOrderAction())
                  .action(cancelTradingOrderAction())
                  .action(inlineTradingOrderActions())
                  .action(refreshServersideCustomMenuAction)
              )
            ),
        [investorOrderId, datasources]
      );

      return <VGrid {...gridProps} />;
    },
    ({ props: currProps = {} }, { props: nextProps = {} }) =>
      currProps.investorOrderId === nextProps.investorOrderId
  ),
  {
    trading_order_details: {
      query: TsMappedTradingOrdersWithFilterDocument,
      getData(result) {
        return result.tsMappedTradingOrdersWithFilter;
      }
    }
  }
);

export default TradingOrdersDetailGrid;
