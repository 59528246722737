import React from 'react';
import { MultiToggleGroup, ToggleGroupItem } from '@oms/ui-design-system';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import { type IToggleButtonGroupMultiField } from './toggle-group.type';
import { useFieldApi } from '../../helpers';
import { type FieldProps } from '../../types';

export const ToggleGroupMulti: React.FC<FieldProps<IToggleButtonGroupMultiField<any>>> = (props) => {
  const {
    options = [],
    meta,
    input,
    autoFocus = false,
    isDisabled: _isDisabled,
    forceIsDisabled,
    hidden,
    label,
    isInvalid,
    isVisible = true,
    requiredFieldIndicatorStyle,
    helperText,
    isRequired,
    isReadOnly,
    hideFormControls,
    buttonGroupProps,
    emptyLabel
  } = useFieldApi<IToggleButtonGroupMultiField<any>>(props);

  // Sometimes validators override the disabled props. If forceIsDisabled is true, then we should always disable the field.
  const isDisabled = !!forceIsDisabled || !!_isDisabled;

  // Let's spread the props here so we can pass only what's necessary for an input
  const { onChange, value } = input;

  return (
    <FieldWrapper
      meta={meta}
      hideFormControls={hideFormControls}
      label={label}
      isReadOnly={isReadOnly}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      requiredFieldIndicatorStyle={requiredFieldIndicatorStyle}
      hidden={hidden}
      autoFocus={autoFocus}
      helperText={helperText}
      isVisible={isVisible}
    >
      <MultiToggleGroup onValueChange={onChange} value={value} buttonGroupProps={buttonGroupProps}>
        {options.map(({ value: oValue, label, isDisabled }, i: number) => (
          <ToggleGroupItem key={i} value={oValue} isDisabled={isDisabled}>
            {label}
          </ToggleGroupItem>
        ))}
        {options.length === 0 && emptyLabel && <div>{emptyLabel}</div>}
      </MultiToggleGroup>
    </FieldWrapper>
  );
};
