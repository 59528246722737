import type { OperatorFunction } from 'rxjs';
import { catchError, of, map, startWith } from 'rxjs';
import type { DataSourceCommon, DataSourceResult } from './data-access.common';
import type { ApolloError, ApolloQueryResult } from '@apollo/client';
import type { GraphQLFormattedError } from 'graphql';
import { Logger, Result } from '@oms/ui-util';

const logger = Logger.named('data-access.operators');

export function toDatasource<T extends object, R extends object>(
  mapFn: (result: T) => DataSourceCommon<R>
): OperatorFunction<T, DataSourceCommon<R>> {
  return (obs$) =>
    obs$.pipe(
      map(mapFn),
      catchError((e) => {
        logger.scope('toDatasource').error(e);
        return of({
          isFetching: false,
          results: [],
          error: e as Error
        });
      }),
      startWith({
        isFetching: true,
        error: undefined,
        results: []
      } as DataSourceCommon<R>)
    );
}

export function toGqlDatasource<T extends ApolloQueryResult<any>, R extends object>(
  dataFn: (data: T['data']) => R[]
): OperatorFunction<T, DataSourceCommon<R>> {
  return toDatasource<T, R>(({ loading: isFetching, data, error, errors = [] }) => {
    const allErrors: (ApolloError | GraphQLFormattedError)[] = [];
    allErrors.push(...errors);
    if (error) allErrors.push(error);
    return {
      isFetching,
      error: allErrors.length ? new Error(allErrors.flatMap(({ message }) => message).join(',')) : undefined,
      results: dataFn(data as DataSourceCommon<R>)
    };
  });
}

export const asDataSource = <TData extends object = object>(
  results: TData[],
  options?: { isFetching?: boolean; error?: Error }
): DataSourceCommon<TData> => {
  const { isFetching = false, error } = options ?? {};
  return error
    ? {
        results,
        isFetching,
        error
      }
    : {
        results,
        isFetching
      };
};

interface AsDataSourceOptions {
  onError?: (e: Error) => void;
}

export const asObservableDataSource =
  <TData extends object = object>(
    options?: AsDataSourceOptions
  ): OperatorFunction<TData[], DataSourceCommon<TData>> =>
  (observable$) =>
    observable$.pipe(
      map((data) => asDataSource(data)),
      startWith(asDataSource([] as TData[], { isFetching: true })),
      catchError((e) => {
        if (e instanceof Error) options?.onError?.(e);
        return of(asDataSource([] as TData[]));
      })
    );

interface DataSourceToResultOptions {
  failOnFetching?: { errorMessage: string };
}

const dataSourceToResult = <TData extends object = object>(
  dataSource: DataSourceCommon<TData>,
  options?: DataSourceToResultOptions
): DataSourceResult<TData> => {
  const { failOnFetching } = options ?? {};
  const { error, ...rest } = dataSource;
  if (error) return Result.failure(error);
  if (failOnFetching) return Result.failure(new Error(failOnFetching.errorMessage));
  const { results = [], isFetching } = rest;
  return Result.success({ results, isFetching });
};

export const toDataSourceResult =
  <TData extends object = object>(
    options?: DataSourceToResultOptions
  ): OperatorFunction<DataSourceCommon<TData>, DataSourceResult<TData>> =>
  (observable$) =>
    observable$.pipe(map((dataSource) => dataSourceToResult(dataSource, options)));
