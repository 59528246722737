import { openRejectWithReason } from '@app/generated/sdk';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { TradeRepairRequestService } from '@app/data-access/services/trading/repair-requests/trade-repair-requests.service';
import type { ActionContext, ActionComponentConfig, ActionDefFactory } from '@oms/frontend-vgrid';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { ValstroEntitlements } from '@oms/generated/frontend';
import { Maybe } from '@oms/ui-util';
import { t } from '@oms/codegen/translations';

type RejectTradeActionArgs = {
  id?: Maybe<string>;
};

function isVisible(rowData: RejectTradeActionArgs[]) {
  return rowData.length > 0;
}

export const rejectRepairQueueTradesOnChange = async <T extends RejectTradeActionArgs>(
  ctx: ActionContext<T, ActionComponentConfig<T>>
) => {
  const { appContainer, lifecycle, data, workspace } = ctx;

  const isEnabled = isVisible(data);

  ctx.notify({ isDisabled: !isEnabled });

  if (lifecycle === 'change' && isEnabled) {
    const [_, api] = await openRejectWithReason(workspace, workspace.getLeaderProcessId());
    const event = await api.awaitFirstEvent;
    switch (event.type) {
      case DIALOG_EVENT_TYPE.OK: {
        const tradeRepairRequestIds = data.map((row) => row.id || '');

        const tradeRepairRequestService = appContainer.resolve(TradeRepairRequestService);

        const response = await tradeRepairRequestService.rejectWithReason(
          tradeRepairRequestIds,
          event.payload as string
        );

        if (!response.isSuccess()) {
          console.error(response.errors);
        }
        break;
      }
    }
  }
};

export const rejectRepairQueueTradesAction =
  <T extends RejectTradeActionArgs>(): ActionDefFactory<T> =>
  (builder) =>
    builder
      .name('reject_repair_queue_trades')
      .toolbar((t) =>
        t.component('action-button').id('reject_repair_queue_trades_button').location('UserToolbar').props({
          isDisabled: true,
          content: 'Reject'
        })
      )
      .access(({ appContainer }) => {
        const authService = appContainer.resolve(AuthService);

        return authService.hasEntitlement([ValstroEntitlements.RepairQueueManage]);
      })
      .customMenu((m) =>
        m
          .name('Reject')
          .visible(({ rowData }) => isVisible(rowData))
          .tabName(t('app.common.grids.contextMenuTabs.action'))
      )
      .onChange<ActionComponentConfig<T>>(rejectRepairQueueTradesOnChange);
