import React from 'react';
import { useFormApi } from '@data-driven-forms/react-form-renderer';
import { Box, Heading, type Sprinkles } from '@oms/ui-design-system';
import type { FieldProps, ICommonField, Field } from '../../types';
import { useFieldApi } from '../../helpers';
import { type FORM_COMPONENT_TYPE } from '../../contracts';

interface ISectionFieldUnique {
  title?: string;
  sx?: Sprinkles;
}

export interface ISectionField extends ISectionFieldUnique, ICommonField<typeof FORM_COMPONENT_TYPE.SECTION> {
  fields: Field[][];
}

export const Section: React.FC<FieldProps<ISectionField>> = ({ title, ...rest }) => {
  const { renderForm } = useFormApi();
  const { fields, style = {}, sx = {} } = useFieldApi<ISectionField>(rest);

  return (
    <Box style={style} sx={{ bg: 'layout.level3', borderRadius: 'md', px: 5, pt: 5, pb: 5, ...sx }}>
      {title && (
        <Heading type="mediumR" sx={{ marginBottom: 5 }}>
          {title}
        </Heading>
      )}
      {fields.map((fields: Field[]) => renderForm(fields))}
    </Box>
  );
};
