import type { FC } from 'react';
import { DragItem } from '../drag-item/drag-item';
import { Grid, GridItem, IconButton, Text } from '@oms/ui-design-system';
import { TrashIcon } from '@radix-ui/react-icons';
import { onPreventDefault } from '../utils/on.prevent.default';
import { DragItemTypes } from '../types';
import type { DragNDrop, OnDelete } from '../types';

export type SeparatorProps = OnDelete & Omit<DragNDrop, 'type'>;

export const Separator: FC<SeparatorProps> = ({ onDelete, onDrag, onDrop }) => {
  return (
    <DragItem
      contentContainer={{ sx: { backgroundColor: 'layout.level1', padding: 1 } }}
      onDrag={onDrag}
      onDrop={onDrop}
      type={DragItemTypes.Separator}
    >
      <Grid columns={12} gap={0} sx={{ backgroundColor: 'layout.level2' }}>
        <GridItem colSpan={1} sx={{ alignItems: 'center', padding: 4 }}>
          <Text type="baseB">Separator</Text>
        </GridItem>
        <GridItem colSpan={10}></GridItem>
        <GridItem
          colSpan={1}
          sx={{ alignItems: 'center', justifyContent: 'flex-end', paddingY: 3, paddingX: 4 }}
        >
          <IconButton
            aria-label="delete-separator"
            isRound={true}
            sx={{ color: 'icons.semiMinor', backgroundColor: 'text.minor' }}
            variant="ghost"
            size={'sm'}
            onClick={(e) => {
              onPreventDefault(e, onDelete);
            }}
            icon={<TrashIcon />}
          />
        </GridItem>
      </Grid>
    </DragItem>
  );
};
