import type { Optional, AnyRecord, Identifiable } from '@oms/ui-util';
import { cleanMaybe } from '@oms/ui-util';
import type { GridSelectionEvent } from '../grid.events';
import type { TabId } from './grid-tab.types';

export const tabIdFrom = (gridSelectionEvent: GridSelectionEvent<any>): Optional<string> =>
  cleanMaybe(gridSelectionEvent.meta.tabNodeId);

export const selectedRowsFrom = <TData extends AnyRecord>(
  gridSelectionEvent: GridSelectionEvent<TData>
): TData[] => cleanMaybe(gridSelectionEvent.payload.selectedRows, []);

export const isMatch = (gridSelectionEvent: GridSelectionEvent<any>, currentTabId?: TabId): boolean =>
  currentTabId === tabIdFrom(gridSelectionEvent);

export const compareSelectedRows = <TData extends Partial<Identifiable>>(
  before: TData[],
  after: TData[]
): boolean => {
  if (before.length !== after.length) return false;
  for (const index in after) {
    if (after[index]?.id !== before[index]?.id) return false;
  }
  return true;
};
