import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import type { DataSourceCommon, ICrudService } from '@oms/frontend-foundation';
import { asDataSource } from '@oms/frontend-foundation';
import type {
  CreateGroupingAccountMutationVariables,
  DeleteParentAccountMutationVariables,
  GetParentAccountsQuery,
  GetParentAccountsQueryVariables,
  GroupingAccount,
  GroupingAccountFragment,
  GroupingAccountInput,
  UpdateGroupingAccountMutationVariables
} from '@oms/generated/frontend';
import {
  CreateGroupingAccountDocument,
  DeleteParentAccountDocument,
  GetParentAccountsDocument,
  UpdateGroupingAccountDocument
} from '@oms/generated/frontend';
import { cleanMaybe, compactMap } from '@oms/ui-util';
import omit from 'lodash/omit';
import type { Observable } from 'rxjs';
import { BehaviorSubject, catchError, map, of, startWith, tap } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import { testScoped } from '@app/workspace.registry';

@testScoped
@singleton()
export class ParentAccountsService implements ICrudService<GroupingAccountFragment> {
  private _apolloClient: RxApolloClient;
  private _gqlResponse: GQLResponse;
  private _parentAccounts = new BehaviorSubject<GroupingAccountFragment[]>([]);

  constructor(
    @inject(RxApolloClient) apolloClient: RxApolloClient,
    @inject(GQLResponse) gqlResponse: GQLResponse
  ) {
    this._apolloClient = apolloClient;
    this._gqlResponse = gqlResponse;
  }

  entityExists(): boolean {
    return !!this._parentAccounts.getValue().find((account) => account.entity);
  }

  public watchAll$(): Observable<DataSourceCommon<GroupingAccountFragment>> {
    const result = this._apolloClient.rxWatchQuery<GetParentAccountsQuery, GetParentAccountsQueryVariables>({
      query: GetParentAccountsDocument
    });

    return result.pipe(
      map(({ data }) =>
        asDataSource(compactMap(cleanMaybe(data.getParentAccounts, []), (parentAccount) => parentAccount))
      ),
      tap(({ results }) => {
        if (!results) return;
        this._parentAccounts.next(results);
      }),
      catchError((e) => {
        console.error(e);
        return of(asDataSource([]));
      }),
      startWith(asDataSource([], { isFetching: true }))
    );
  }

  /** @deprecated Use `watchAll$()` */
  get immutableData$(): Observable<DataSourceCommon<GroupingAccount>> {
    const result = this._apolloClient.rxWatchQuery({
      query: GetParentAccountsDocument
    });

    return result.pipe(
      map(({ data }) => {
        return {
          results: data,
          isFetching: false
        };
      }),
      tap(({ results }) => this._parentAccounts.next(results)),
      catchError((e) => {
        console.error(e);
        return of({
          isFetching: false,
          results: []
        });
      })
    );
  }

  async create(account: GroupingAccountFragment) {
    try {
      this._gqlResponse.wrapMutate<GroupingAccount, CreateGroupingAccountMutationVariables>({
        mutation: CreateGroupingAccountDocument,
        variables: {
          input: omit({ ...account, accumulationCurrencyId: account.accumulationCurrency?.id }, [
            'accumulationCurrency'
          ]) as GroupingAccountInput
        },
        refetchQueries: [GetParentAccountsDocument]
      });
    } catch (err) {
      console.error(err);
    }
  }

  async update(account: GroupingAccountFragment) {
    if (account.id) {
      try {
        const res = await this._gqlResponse.wrapMutate<
          GroupingAccountFragment,
          UpdateGroupingAccountMutationVariables
        >({
          mutation: UpdateGroupingAccountDocument,
          variables: {
            id: account.id,
            input: omit({ ...account, accumulationCurrencyId: account?.accumulationCurrency?.id }, [
              'id',
              'entity',
              'accumulationCurrency'
            ]) as GroupingAccountInput
          }
        });

        return res;
      } catch (err) {
        console.error(err);
      }
    }

    return;
  }

  async delete(accountId: string) {
    try {
      await this._gqlResponse.wrapMutate<GroupingAccountFragment, DeleteParentAccountMutationVariables>({
        mutation: DeleteParentAccountDocument,
        variables: {
          id: accountId
        },
        refetchQueries: [GetParentAccountsDocument]
      });
    } catch (err) {
      console.error(err);
    }
  }
}
