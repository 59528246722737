import { INLINE_SPLIT_BUTTON_ACTION } from '@oms/frontend-vgrid';
import type {
  ActionContext,
  ActionDefFactory,
  ActionComponentConfig,
  SplitButtonActionComponentConfig,
  SplitButtonActionConfig
} from '@oms/frontend-vgrid';
import type { SnapshotComputedDocType } from '@app/data-access/offline/collections/snapshots/snapshots.collection';
import { openSaveSnapshotForm, openConfirmation } from '@app/generated/sdk';
import { SnapshotsService } from '@app/data-access/services/system/snapshots/snapshots.service';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { getLeaderOrTabId } from '@app/common/workspace/workspace.util';
import { t } from '@oms/codegen/translations';
import { CreateActionIcon } from '@oms/frontend-foundation';

export const saveNewSnapshotAction: ActionDefFactory<SnapshotComputedDocType> = (builder) =>
  builder
    .name('save_snapshot')
    .toolbar((t) =>
      t
        .component('action-icon-button')
        .id('save_snapshot_button')
        .location('StaticToolbar')
        .props({
          icon: <CreateActionIcon />
        })
    )
    .lifecycles('change')
    .onChange<ActionComponentConfig<SnapshotComputedDocType>>(
      (ctx: ActionContext<SnapshotComputedDocType, ActionComponentConfig<SnapshotComputedDocType>>) => {
        openSaveSnapshotForm(getLeaderOrTabId(ctx.appContainer), {
          form: {
            formId: `save-snapshot`,
            input: {}
          }
        }).catch(console.error);
      }
    );

const saveOverSnapshotChange = async (e: ActionContext<SnapshotComputedDocType>) => {
  const { lifecycle, data } = e;
  const isSharedFromAnotherUser = !!data[0]?.sharerName;
  e.notify({ isDisabled: data.length !== 1 || isSharedFromAnotherUser });

  const selectedRow = data?.[0];
  const id = selectedRow?.id;
  const service = e.appContainer.resolve(SnapshotsService);

  if (selectedRow) {
    e.notify({ isVisible: true });
  }
  if (lifecycle === 'change' && id) {
    await service.saveOver(id);
  }
};

export const saveOverSnapshotAction: ActionDefFactory<SnapshotComputedDocType> = (builder) =>
  builder
    .name('save_over')
    .toolbar((t) =>
      t.id('save_over_button').component('action-button').location('UserToolbar').props({
        content: 'Save',
        isDisabled: true
      })
    )
    .customMenu((m) =>
      m
        .name('Save')
        .visible(({ rowData }) => {
          return !rowData?.[0]?.sharerName;
        })
        .tabName(t('app.common.grids.contextMenuTabs.action'))
    )
    .onChange<ActionComponentConfig<SnapshotComputedDocType>>(saveOverSnapshotChange);

const loadSnapshotChange = async (e: ActionContext<SnapshotComputedDocType>) => {
  const { lifecycle, data } = e;
  e.notify({ isDisabled: data.length !== 1 });

  const selectedRow = data?.[0];
  const service = e.appContainer.resolve(SnapshotsService);

  if (lifecycle === 'change' && selectedRow?.id) {
    e.notify({ isDisabled: true });
    await service.load(selectedRow.id);
  }
};

export const loadSnapshotAction: ActionDefFactory<SnapshotComputedDocType> = (builder) =>
  builder
    .name('load_snapshot')
    .toolbar((t) =>
      t.id('load_snapshot_button').component('action-button').location('UserToolbar').props({
        content: 'Load',
        isDisabled: true
      })
    )
    .menu((m) => m.name('Load'))
    .onChange<ActionComponentConfig<SnapshotComputedDocType>>(loadSnapshotChange);

const deleteSnapshotChange = async (e: ActionContext<SnapshotComputedDocType>) => {
  const { lifecycle, data } = e;
  e.notify({ isDisabled: !data || data.length === 0 || !!data[0]?.sharerName });
  const service = e.appContainer.resolve(SnapshotsService);

  if (lifecycle === 'change' && data && data.length > 0) {
    const [, api] = await openConfirmation(e.workspace, getLeaderOrTabId(e.appContainer), {
      title: 'Delete Snapshot',
      componentProps: {
        message: `Are you sure you want to delete ${data.length} snapshot(s)?`
      }
    });
    api.awaitFirstEvent
      .then((result) => {
        switch (result.type) {
          case DIALOG_EVENT_TYPE.OK:
            service.delete(data.map((row) => row.id)).catch(console.error);
            break;
        }
      })
      .catch(console.error);
  }
};

export const deleteSnapshotAction: ActionDefFactory<SnapshotComputedDocType> = (builder) =>
  builder
    .name('delete_snapshot')
    .toolbar((t) =>
      t.id('delete_snapshot_button').component('action-button').location('UserToolbar').props({
        content: 'Delete',
        isDisabled: true
      })
    )
    .customMenu((m) => m.name('Delete').tabName(t('app.common.grids.contextMenuTabs.action')))
    .onChange<ActionComponentConfig<SnapshotComputedDocType>>(deleteSnapshotChange);

const shareSnapshotChange = async (e: ActionContext<SnapshotComputedDocType>) => {
  const { lifecycle, data } = e;
  const selectedRow = data?.[0];

  e.notify({
    isDisabled: !data || data.length === 0 || !!data[0]?.sharerName,
    content: selectedRow?.isShared ? 'Unshare' : 'Share'
  });

  const id = selectedRow?.id;
  const service = e.appContainer.resolve(SnapshotsService);
  if (lifecycle === 'change' && id) {
    selectedRow?.isShared ? service.unshare(id).catch(console.error) : service.share(id).catch(console.error);
  }
};

const unshareSnapshotChange = async (e: ActionContext<SnapshotComputedDocType>) => {
  const { lifecycle, data } = e;
  e.notify({ isDisabled: data.length !== 1 });

  const selectedRow = data?.[0];
  const id = selectedRow?.id;
  const service = e.appContainer.resolve(SnapshotsService);

  if (lifecycle === 'change' && id) {
    await service.unshare(id);
  }
};

export const shareSnapshotAction: ActionDefFactory<SnapshotComputedDocType> = (builder) =>
  builder
    .name('share_snapshot')
    .toolbar((t) =>
      t.id('share_snapshot_button').component('action-button').location('UserToolbar').props({
        content: 'Toggle Sharing',
        isDisabled: true
      })
    )
    .customMenu((m) => m.name('Share').tabName(t('app.common.grids.contextMenuTabs.action')))
    .onChange<ActionComponentConfig<SnapshotComputedDocType>>(shareSnapshotChange);

export const inlineSnapshotActions: ActionDefFactory<SnapshotComputedDocType> = (builder) =>
  builder
    .name('inline_snapshot_actions')
    .inline((col) =>
      col
        .header('Actions')
        .initialWidth(130)
        .filter(false)
        .cell((c) => c.dependsOn(['id']).renderer(INLINE_SPLIT_BUTTON_ACTION, { variant: 'secondary' }))
    )
    .onChange<SplitButtonActionComponentConfig<SnapshotComputedDocType>>(async (ctx) => {
      switch (ctx.lifecycle) {
        case 'init':
        case 'refresh': {
          const [rowData] = ctx.data;
          ctx.notify({ actionsConfig: rowActions(rowData) });
          break;
        }
        case 'change': {
          const { selectedId } = ctx.state;
          switch (selectedId) {
            case 'inline_load_snapshot':
              await loadSnapshotChange(ctx);
              break;
            case 'inline_save_over_snapshot':
              await saveOverSnapshotChange(ctx);
              break;
            case 'inline_delete_snapshot':
              await deleteSnapshotChange(ctx);
              break;
            case 'inline_share_snapshot':
              await shareSnapshotChange(ctx);
              break;
            case 'inline_unshare_snapshot':
              await unshareSnapshotChange(ctx);
              break;
            default:
              throw new Error(`onChange not defined for ${selectedId || 'selectedId'}`);
          }
        }
      }
    });

function rowActions(_rowData: SnapshotComputedDocType): SplitButtonActionConfig[] {
  const layoutOwnerIsCurrentUser = !_rowData?.sharerName;
  const actions = [
    {
      id: 'inline_load_snapshot',
      title: 'Load'
    }
  ];
  if (layoutOwnerIsCurrentUser) {
    actions.push(
      {
        id: 'inline_save_over_snapshot',
        title: 'Save Over'
      },
      {
        id: 'inline_delete_snapshot',
        title: 'Delete'
      }
    );

    if (_rowData?.isShared) {
      actions.push({
        id: 'inline_unshare_snapshot',
        title: 'Unshare'
      });
    } else {
      actions.push({
        id: 'inline_share_snapshot',
        title: 'Share'
      });
    }
  }
  return actions;
}
