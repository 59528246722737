import { Plugin } from '@valstro/workspace';
import type { AppWorkspace } from '@app/app-config/workspace.config';
import {
  addOfflineDatabaseCollections,
  createOfflineDatabase,
  destroyAndClearDatabase
} from '@app/data-access/offline/offline-database';
import type { OfflineDatabase, OfflineDatabaseOptions } from '@app/data-access/offline/offline-database';
import { createLogger } from '@oms/ui-util';
import type { RxStorage } from 'rxdb-oms-app';
import { confirm as tauriConfirm } from '@tauri-apps/plugin-dialog';
import { relaunch, exit } from '@tauri-apps/plugin-process';
import { AppDatabaseSignal } from '@app/data-access/memory/app-database.signal';
import type { DependencyContainer } from 'tsyringe';
import { isTauri } from '@app/common/workspace/workspace.constants';
import { AppDatabase } from '@app/data-access/offline/app-database';
import { MemoryDatabaseInitializerService } from '@app/data-access/offline/memory-database';
import type { MemoryDatabaseOptions } from '@app/data-access/offline/memory-database';
import { roughSizeOfObject } from '@app/common/debug/rough-size-of-object.util';

export const appDatabasePluginLogger = createLogger({ name: 'App Database Plugin' });

export interface AppDatabaseOptions {
  offline?: OfflineDatabaseOptions;
  memory?: MemoryDatabaseOptions;
  container: DependencyContainer;
  debug?: boolean;
}

/**
 * Creates the app database when the leader window is ready
 */
export const appDatabasePlugin = ({ container, memory = {}, offline = {}, debug }: AppDatabaseOptions) =>
  Plugin.create<AppWorkspace>({
    name: 'valstro-app-database-plugin',
    pluginFn: ({ workspace }) => {
      const signalService = container.resolve(AppDatabaseSignal);
      const memoryDatabaseInitializer = container.resolve(MemoryDatabaseInitializerService);
      const appDatabase = container.resolve(AppDatabase);
      let clearDebug: (() => void) | undefined;
      const unsubWindowReady = workspace.addHook('leaderElection', async ({ isLeader }) => {
        if (appDatabase.isInitialized) {
          appDatabasePluginLogger.info('App database already initialized');
          signalService.signal.set({
            isConnecting: false,
            isReady: true,
            db: appDatabase,
            error: null
          });
          return;
        }

        try {
          signalService.signal.set({
            isConnecting: true,
            isReady: false,
            error: null
          });

          const existingOfflineDatabase = workspace.getMeta().offlineDatabase;
          const existingMemoryDatabase = workspace.getMeta().memoryDatabase;

          if (existingOfflineDatabase || existingMemoryDatabase) {
            return;
          }

          if (isLeader && memory && memory.storage === undefined) {
            memoryDatabaseInitializer.expose();
          }

          const { db: memoryDatabase } = await memoryDatabaseInitializer.create(isLeader, memory);
          const { db: offlineDatabase, storage: offlineStorage } = await createOfflineDatabase(offline);

          try {
            await memoryDatabaseInitializer.addCollections();
            await addOfflineDatabaseCollections(offlineDatabase);
          } catch (e) {
            console.error(e);
            signalService.signal.set({
              isConnecting: true,
              isReady: false,
              error: (e as Error)?.message
            });
            if (isTauri()) {
              await handleTauriRxDBError(offlineDatabase, offlineStorage);
            } else {
              await handleBrowserRxDBError(e, offlineDatabase, offlineStorage);
            }
          }

          appDatabase.init(memoryDatabase, offlineDatabase);
          workspace.updateMeta({
            offlineDatabase,
            memoryDatabase
          });
          const container = workspace.meta?.container;
          if (!container) {
            throw new Error('Container not found');
          }
          container.register(AppDatabase, { useValue: appDatabase });
          appDatabasePluginLogger.log('Created & registered offline & memory database', appDatabase);

          if (isLeader) {
            // Prune database on startup (remove orphaned grids and grid templates)
            pruneDatabase().catch(console.error);
          }

          signalService.signal.set({
            isConnecting: false,
            isReady: true,
            db: appDatabase,
            error: null
          });

          if (debug && isLeader) {
            clearDebug = setupMemoryDatabaseDebug(appDatabase);
          }
        } catch (e) {
          appDatabasePluginLogger.error(e);
          signalService.signal.set({
            isConnecting: false,
            isReady: false,
            error: (e as Error)?.message
          });
        }
      });

      const unsubscribe = async () => {
        unsubWindowReady();
        await memoryDatabaseInitializer.destroy();
        await appDatabase.destroy();

        appDatabasePluginLogger.log('Destroyed offline & memory database');
        signalService.reset();

        clearDebug?.();
      };

      return unsubscribe;
    }
  });

async function handleTauriRxDBError(offlineDatabase: OfflineDatabase, storage: RxStorage<any, any>) {
  const confirmed = await tauriConfirm(
    'Your local data is no longer compatible with the latest version of our application. Would you like to reset it?'
  );
  if (confirmed) {
    await destroyAndClearDatabase(offlineDatabase, storage);
    await relaunch();
  } else {
    await exit(1);
  }
}

async function handleBrowserRxDBError(
  e: unknown,
  offlineDatabase: OfflineDatabase,
  storage: RxStorage<any, any>
) {
  const confirmed = confirm(
    'Your local data is no longer compatible with the latest version of our application. Would you like to reset it?'
  );
  if (confirmed) {
    await destroyAndClearDatabase(offlineDatabase, storage);
    window.location.reload();
  } else {
    throw e;
  }
}

/**
 * Prunes the database by removing orphaned grids and grid templates
 */
async function pruneDatabase() {
  // Prune orphaned stuff here if need be
}

/**
 * Setup a debug interval to log the memory database every 10 seconds
 * This is useful for debugging memory database issues because it can be difficult to inspect the memory database with RxDB
 *
 * To enable this, set the debug option to true in the app database plugin in create-workspace.tsx
 *
 * @param appDatabase - The app database
 * @returns A function to clear the debug interval
 */
function setupMemoryDatabaseDebug(appDatabase: AppDatabase) {
  const getData = async () => {
    // Get all collection data
    const snapshots_promise = appDatabase.memoryDb.collections.snapshots.find().exec();
    const actions_promise = appDatabase.memoryDb.collections.actions.find().exec();
    const completed_migrations_promise = appDatabase.memoryDb.collections.completed_migrations.find().exec();
    const grids_promise = appDatabase.memoryDb.collections.grids.find().exec();
    const [snapshots, actions, completed_migrations, grids] = await Promise.all([
      snapshots_promise,
      actions_promise,
      completed_migrations_promise,
      grids_promise
    ]);
    const fullSnaphot = {
      snapshots: snapshots.map((s) => s.toJSON()),
      actions: actions.map((a) => a.toJSON()),
      completed_migrations: completed_migrations.map((cm) => cm.toJSON()),
      grids: grids.map((g) => g.toJSON())
    };
    appDatabasePluginLogger.info('RxDB memory db dump:', fullSnaphot);
    appDatabasePluginLogger.info('RxDB memory db no. of grids:', fullSnaphot.grids.length);
    appDatabasePluginLogger.info('RxDB memory db no. of actions:', fullSnaphot.actions.length);
    appDatabasePluginLogger.info('RxDB memory db no. of snapshots:', fullSnaphot.snapshots.length);
    appDatabasePluginLogger.info(
      'RxDB memory database dump (megabytes): ',
      roughSizeOfObject(fullSnaphot) / 1_000_000
    );
  };

  const runDbDump = setInterval(() => {
    getData().catch(console.error);
  }, 10_000);

  return () => {
    clearInterval(runDbDump);
  };
}
