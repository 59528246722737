import {
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE,
  groupByAction,
  ROW_SELECTION_COUNT_ACTION_TYPE,
  TOGGLE_FILTERS_AND_SIDEBAR_ACTION_TYPE,
  useVGrid,
  VGrid
} from '@oms/frontend-vgrid';
import { GridConfigEventHandler } from '@app/data-access/services/system/grids/grid-config.event-handler';
import { TradeStatus, TsTradesWithFilterDocument } from '@oms/generated/frontend';
import type {
  TradeRow,
  TradesGroupedByPriceRow,
  TsTradesWithFilterSubscription
} from '@oms/generated/frontend';
import { TsTradesGroupedByPriceWithFilterDocument } from './trade-monitor.contracts';
import type { TsTradesGroupedByPriceWithFilterSubscription } from './trade-monitor.contracts';
import { openModifyTradeAction } from './grid-actions/open-modify.trade.action';
import { cancelTradeAction } from './grid-actions/cancel.trade.action';
import { buildTradesMonitorColumnDefs } from './trade-monitor.columns';
import { viewTradeAction } from './grid-actions/view.trade.action';
import { useCurrentAppWidgetActorProps } from '@app/common/workspace/workspace.hooks';
import { t } from '@oms/codegen/translations';
import { SortModelItem } from '@app/data-access/services/system/table-server/filters/ag-grid.filters.schema';
import {
  TableServerQueryFilter,
  WithTableServer
} from '@app/data-access/services/system/table-server/table-server.datasource.contracts';
import { withTableServer } from '@app/data-access/services/system/table-server/with.table-server.component';
import { VisibilityEventHander } from '../investor-order-monitor/grid-services/visibility.event-handler';
import { useMemo } from 'react';

export type TradesSubscriptionKeys = 'trading_order_rows' | 'trading_order_group';

export type TradesSubscriptionMap = {
  trading_order_rows: {
    datasource: TradeRow;
    subscription: TsTradesWithFilterSubscription;
  };
  trading_order_group: {
    aggregate: {
      datasource: TradesGroupedByPriceRow;
      subscription: TsTradesGroupedByPriceWithFilterSubscription;
    };
    detail: {
      datasource: TradeRow;
      subscription: TsTradesWithFilterSubscription;
    };
  };
};

type TradesMonitorWidgetProps = {
  id?: string;
};

const timestampSort: SortModelItem<TradeRow>[] = [
  {
    colId: 'updatedTime',
    sort: 'desc'
  }
];

const commonFilter = ({ id }: TradesMonitorWidgetProps): TableServerQueryFilter<TradeRow> | undefined => {
  return id
    ? {
        tradingOrderId: {
          filterType: 'text',
          type: 'equals',
          filter: id
        }
      }
    : undefined;
};

export const TradeMonitorWidget = withTableServer<
  TradesSubscriptionKeys,
  TradesSubscriptionMap,
  TradesMonitorWidgetProps
>(
  ({
    props: tradingOrderMonitorProps,
    datasources
  }: WithTableServer<TradesSubscriptionKeys, TradesSubscriptionMap, TradesMonitorWidgetProps>) => {
    const { id: tradingOrderId } = tradingOrderMonitorProps || {};
    const [props] = useCurrentAppWidgetActorProps<TradesMonitorWidgetProps>();
    const id = tradingOrderId || props.id;
    const rowDatasource = useMemo(
      () =>
        datasources.trading_order_rows.asServerSideRowModel({
          filter: commonFilter({ id }),
          sort: timestampSort
        }),
      [id, datasources]
    );

    const groupDatasource = useMemo(
      () =>
        datasources.trading_order_group.asServerSideRowModel({
          aggregateQuery: {
            filter: commonFilter({ id }),
            sort: [
              {
                colId: 'price',
                sort: 'asc'
              }
            ]
          },
          detailQuery: {
            filter: {
              ...commonFilter({ id }),
              price: {
                filterType: 'number',
                type: 'equals'
              }
            },
            sort: timestampSort
          }
        }),
      [id, datasources]
    );

    const gridProps = useVGrid<TradeRow>(
      'trade-monitor',
      (builder) =>
        builder
          .tableServerColumnLibrary(buildTradesMonitorColumnDefs())
          .datasource((d) =>
            d
              .source(rowDatasource)
              .rowId((r) => r.data.id)
              .cacheBlockSize(100)
          )
          .injectEvents([GridConfigEventHandler, VisibilityEventHander])
          .rowStateRules({
            noExecutedQuantity: (params) => params.data?.status === TradeStatus.Inactive
          })
          .sideBar()
          .rowSelection((c) => c.multiple())
          .actions((a) => {
            const actions = a.schema((s) => {
              if (id) {
                s.action(
                  groupByAction<TradeRow>({
                    aggregateDatasource: groupDatasource,
                    detailDatasource: rowDatasource,
                    groupFieldName: 'price',
                    label: t('app.actions.groupByPrice')
                  })
                );
              }
              return s
                .action(TOGGLE_FILTERS_AND_SIDEBAR_ACTION_TYPE)
                .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(viewTradeAction())
                .action(openModifyTradeAction())
                .action(cancelTradeAction())
                .action(ROW_SELECTION_COUNT_ACTION_TYPE);
            });
            return actions;
          })
          .reactiveCustomComponents(),
      [datasources, id]
    );

    return <VGrid {...gridProps} />;
  },
  {
    trading_order_rows: {
      query: TsTradesWithFilterDocument,
      getData: (r) => r.tsTradesWithFilter
    },
    trading_order_group: {
      aggregateQuery: {
        query: TsTradesGroupedByPriceWithFilterDocument,
        getData: (r) => r.tsTradesGroupedByPriceWithFilter
      },
      detailQuery: {
        query: TsTradesWithFilterDocument,
        getData: (r) => r.tsTradesWithFilter
      }
    }
  }
);

export default TradeMonitorWidget;
