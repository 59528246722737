import type { Optional } from '@oms/ui-util';
import type { SummaryGridField } from '@oms/frontend-foundation';
import { getOrderSideClassName, isOrderInactive } from '@oms/frontend-foundation';
import type { ExecutionRow } from '@oms/generated/frontend';
import { mapSettleType } from '../mappers/map-settle-type';
import { get } from 'lodash';
import { translateEnum } from '../mappers/translate-enum';
import type { FieldMetadata } from './common.types';

type ExecutionFieldMetadata = FieldMetadata<ExecutionRow>;

export const EXECUTION_DETAILS_FIELDS_MAP: Partial<Record<keyof ExecutionRow, ExecutionFieldMetadata>> = {
  id: { label: 'Execution ID' },
  tradeId: { label: 'Trade ID' },
  investorOrderId: { label: 'Investor Order ID' },
  executedPrice: { label: 'Executed Price', format: 'price' },
  executedQuantity: { label: 'Executed Quantity', format: 'quantity' },
  executionDateTime: { label: 'Execution Datetime', format: 'datetime' },
  snapshotDateTime: { label: 'Snapshot Datetime', format: 'datetime' },
  status: { label: 'Status' },
  capacity: { label: 'Capacity' },
  settleDate: { label: 'Settle Date', format: 'datetime' },
  settleType: {
    label: 'Settle type',
    mapper: ({ value }) => (typeof value === 'string' ? mapSettleType(value) : '')
  },
  settleCurrency: { label: 'Settle Currency' },
  settleFxRate: { label: 'Settle Fx Rate', format: 'price' },
  side: {
    label: 'Side',
    mapper: ({ value }) => (typeof value === 'string' ? translateEnum('side', value) : ''),
    classNameMapper: ({ value, data }) =>
      typeof value === 'string' ? getOrderSideClassName(value, { isInactive: isOrderInactive(data) }) : ''
  },
  tradeCounterParty: { label: 'Trade Counterparty' },
  tradeContraAccountName: { label: 'Trade Contra Account Name' },
  lastMarket: { label: 'Last Market' },
  lastTradeTime: { label: 'Last Trade Time', format: 'datetime' },
  trader: { label: 'Trader' },
  rawLiquidityIndicator: { label: 'Raw Liquidity Indicator' },
  normalizedLiquidityIndicator: { label: 'Normalized Liquidity Indicator' },
  instrumentDisplayCode: { label: 'Instrument Display Code' }
};

export type ExecutionSummaryFieldName = keyof typeof EXECUTION_DETAILS_FIELDS_MAP;

const executionSummaryFields = (
  fieldNames: ExecutionSummaryFieldName[],
  execution: Partial<ExecutionRow>
): SummaryGridField[] => {
  return fieldNames.map((fieldName) => {
    const { label, format, classNameMapper } = (EXECUTION_DETAILS_FIELDS_MAP[fieldName] ||
      {}) as Partial<ExecutionFieldMetadata>;
    const value = get(execution, fieldName, '');
    return {
      label: label || fieldName,
      fieldName,
      format,
      className: classNameMapper ? classNameMapper({ value, data: execution }) : undefined
    };
  });
};

const executionSummaryValues = (
  fieldNames: ExecutionSummaryFieldName[],
  execution: Partial<ExecutionRow>
) => {
  return [
    fieldNames.reduce(
      (acc, fieldName) => {
        // Using const on the field name definition ensures we get type protection when specifying
        // the fields as a user, but using explicit types here under the hood is way to complex to appease
        // typescript :/ so I'm treating it as an explicit any
        const fieldDef = EXECUTION_DETAILS_FIELDS_MAP[fieldName] as Partial<ExecutionFieldMetadata>;
        const { mapper } = fieldDef;
        // Using lodash's get instead of reimplementing nested fields with . again
        const value = get(execution, fieldName, undefined);
        if (value === null || value === undefined) {
          return { ...acc, [fieldName]: undefined };
        }
        const mappedValue = (mapper ? mapper({ value, data: execution }) : undefined) || String(value);
        return { ...acc, [fieldName]: mappedValue };
      },
      {} as Partial<Record<ExecutionSummaryFieldName, Optional<string>>>
    )
  ];
};

export const executionSummaryData = (
  label: string,
  fields: (keyof typeof EXECUTION_DETAILS_FIELDS_MAP)[],
  order: Partial<ExecutionRow>
) => {
  return {
    label,
    value: executionSummaryValues(fields, order),
    fields: executionSummaryFields(fields, order)
  };
};
