import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { ModuleRegistry } from '@ag-grid-community/core';
import { useDeepMemo } from '@oms/ui-design-system';
import { useCallback, useEffect, useMemo, useRef, type DependencyList } from 'react';
import type { DependencyContainer } from 'tsyringe';
import { GridBuilder, GridBuilderCallback } from '../builders/grid.builder';
import type { VGridProps } from '../components/vgrid.component';
import { generateGridId } from '../services/grid-id.service';
import { type AnyRecord, useWorkspace, useWorkspaceContainer } from '@oms/frontend-foundation';
import {
  useClosestFlexLayoutActor,
  useCurrentWidget,
  useCurrentWindow,
  useFlexLayoutNodeContext
} from '@valstro/workspace-react';
import { type Actor, type CommonWidgetActorSchema } from '@valstro/workspace';
import { type VGridContextInstance } from '../models/v.context.model';
import { VGridInstance } from '../models/v.instance.model';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ServerSideRowModelModule,
  SideBarModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  SetFilterModule,
  MasterDetailModule,
  MenuModule,
  RowGroupingModule,
  RangeSelectionModule
]);

export type UseVGridOptions = {
  gridId?: string;
};

export function useVGrid<TData extends AnyRecord>(
  gridType: string,
  callback: GridBuilderCallback<TData>,
  dependencies: DependencyList,
  options: UseVGridOptions = {}
): VGridProps<TData> {
  const gridBuilderCallback: GridBuilderCallback<TData> = useCallback(callback, dependencies);
  const workspace = useWorkspace();
  const windowActor = useCurrentWindow();
  const widgetActor = useCurrentWidget();
  const flexLayoutActor = useClosestFlexLayoutActor();
  const nodeCtx = useFlexLayoutNodeContext() || {};
  const tabNode = nodeCtx.tabNode || null;
  const tabSetNode = nodeCtx.tabSetNode || null;
  const parentContainer = useWorkspaceContainer();
  const container = useRef<DependencyContainer>(parentContainer.createChildContainer());
  const builderInstance = useRef<GridBuilder<TData>>();
  const context = useRef<VGridContextInstance>({
    gridType,
    workspace,
    windowActor,
    flexLayoutActor,
    flexLayoutTabNode: tabNode,
    flexLayoutTabSetNode: tabSetNode,
    scopedActorId: flexLayoutActor?.id || widgetActor.id,
    widgetActor: widgetActor as Actor<CommonWidgetActorSchema>, // TODO: Fix type in @valstro/workspace
    container: container.current,
    parentContainer,
    gridId: options.gridId
  });

  useEffect(() => {
    if (gridType !== context.current.gridType) {
      context.current.gridType = gridType;
    }
  }, [gridType]);

  const builderProps: VGridProps<TData> = useDeepMemo(() => {
    // Managing garbage collection of the builder instance when props change
    if (builderInstance.current) {
      builderInstance.current.destroy();
    }

    // TODO: sync redundant di container types
    container.current.register(VGridInstance.Context, { useValue: context.current });

    builderInstance.current = gridBuilderCallback(
      GridBuilder.of<TData>({
        container: container.current,
        gridType,
        builderCb: gridBuilderCallback
        // viewId: multiOptions.viewId,
        // gridName: multiOptions.gridName,
        // gridId: multiOptions.gridId
      })
    );

    return builderInstance.current.build();
  }, [gridBuilderCallback, gridType]);

  useEffect(() => {
    // Managing garbage collection of the builder instance when the component unmounts
    return () => {
      builderInstance.current?.destroy();
      container.current?.clearInstances();
    };
  }, []);

  return useMemo(() => {
    const gridId = generateGridId(widgetActor.id, gridType, builderProps.viewId);
    const newProps: VGridProps<TData> = {
      ...builderProps,
      container: container.current,
      gridType,
      gridId
    };

    return newProps;
  }, [builderProps, gridType, widgetActor.id]);
}
