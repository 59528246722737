import { UUID } from '@oms/ui-util';
import type { AnyKey } from '@oms/ui-util';
import type { GetHierarchyIdFn } from '../../types/tree-data.types';

export const DEFAULT_SEPARATOR = '.';

/** A default function to generate a hierarchy ID by prefixing it's identifier with it's level */
export const DEFAULT_GET_HIERARCHY_FN: GetHierarchyIdFn<AnyKey> = (hierarchy) => {
  const { length } = hierarchy;
  if (length === 0) return UUID();
  const level = length - 1;
  return `${level}-${hierarchy[level].toString()}`;
};
