import { PropsWithChildren } from 'react';
import type { FC } from 'react';
import { Text, TextProps } from '../../layout/text/text';
import { BoxProps, Box } from '../../system/components/box/box';

const SimpleTextWrapper: FC<PropsWithChildren<BoxProps & { type?: TextProps['type'] }>> = ({
  children,
  type,
  ...boxProps
}) => (
  <Box {...boxProps}>
    <Text type={type}>{children}</Text>
  </Box>
);

export default SimpleTextWrapper;
