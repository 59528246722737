import clsx from 'clsx';
import { Box } from '../../system/components/box/box';
import type { BoxProps } from '../../system/components/box/box';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';
import { useFormControl } from './form-control';
import * as styles from './css/form-error-message.css';
import { formLabelRecipe } from './css/form-label.css';
import { textEllipsis } from '../../system/styles.css';

export type FormErrorMessageProps = {} & BoxProps;

export const FormErrorMessage = polymorphicComponent<'div', FormErrorMessageProps>((props, ref) => {
  const { as = 'div', children, className, id, ...rest } = props;
  const formControl = useFormControl({});

  const classes = clsx(
    styles.formErrorMessage,
    formLabelRecipe({ size: 'sm', variant: 'error' }), // Always small for now, so it doesn't get in the way
    formControl.clipFormLabels && textEllipsis,
    className
  );

  return (
    <Box {...rest} as={as} ref={ref} className={classes} id={id || formControl.errorId}>
      {children}
    </Box>
  );
});

if (__DEV__) {
  FormErrorMessage.displayName = 'FormErrorMessage';
}
