import type { AnyRecord } from '../../common/type.helpers';
import type { IAnyField, Schema } from '../types';
import type {
  FormBuilderPartialTemplateProps,
  FormBuilderPartialTemplatePropsByKey,
  FormBuilderTemplateMapperKey
} from './components/templates/form-builder.template-mapper';
import type { AnyFieldDefinition, InferFormValueFromFieldDefinition } from './form-field-definition.class';
import type { Prettify } from '@oms/ui-util';

/**
 * Form contract schema callback
 */
export type FormContractSchemaCallback<
  T extends Schema,
  TBuiltFieldDefinitions extends Record<string, IAnyField>
> = (fields: TBuiltFieldDefinitions) => T;

/**
 * Form contract definition (return type of FormContract.build())
 */
export type FormContractDefinition<TFieldDefinitions extends Record<string, AnyFieldDefinition>> = {
  fieldDefinitions: TFieldDefinitions;
  schema: Schema;
  template?: FormBuilderTemplateMapperKey;
  templateProps?: FormBuilderPartialTemplateProps[keyof FormBuilderPartialTemplateProps];
};

/**
 * Get the field definitions from a FormContract instance
 */
export type GetFormContractFieldDefs<T extends FormContract<any>> = ReturnType<
  T['build']
>['fieldDefinitions'];

/**
 * Infer the form values from a FormContract instance
 * Note: "form values" are the data type of the values inside the form state
 */
export type InferFormValuesFromFormContract<T extends FormContract<any>> = Prettify<
  Partial<{
    [P in keyof GetFormContractFieldDefs<T>]?: InferFormValueFromFieldDefinition<
      GetFormContractFieldDefs<T>[P] extends AnyFieldDefinition ? GetFormContractFieldDefs<T>[P] : never
    >;
  }>
>;

/**
 * Form contract field definitions type
 * Note: this is a helper type to manually type a form contract field defs JUST using TS
 */
export type FormContractFieldDefinitionsType<
  TFieldContract extends AnyRecord,
  TFieldDefinitions extends {
    [P in keyof TFieldContract]: AnyFieldDefinition;
  }
> = TFieldDefinitions;

/**
 * Form contract type
 * Note: this is a helper type to manually type a form contract JUST using TS
 */
export type FormContractType<
  TFieldContract extends AnyRecord,
  TFieldDefinitions extends {
    [P in keyof TFieldContract]: AnyFieldDefinition;
  }
> = FormContract<TFieldContract, TFieldDefinitions>;

/**
 * Form contract builder
 * Used to create a multiple field definitions that are used in a form that conform to an external contract.
 *
 * @example
 * ```ts
 * const f = new FieldContract<RouteOrderMutationInput>();
 * const form = new FormContract<RouteOrderMutationInput>();
 *
 * const myForm = form.fields({
 *  quantity: f.field('quantity', 'number').build({
 *    numericScale: 2
 *  }),
 *  //...
 * });
 * ```
 */
export class FormContract<
  TFieldContract extends AnyRecord,
  TFieldDefinitions extends Record<string, AnyFieldDefinition> = {}
> {
  private _schema: Schema | undefined;
  private _fieldDefinitions: TFieldDefinitions | undefined;
  private _template: FormBuilderTemplateMapperKey | undefined;
  private _templateProps: FormBuilderPartialTemplateProps[keyof FormBuilderPartialTemplateProps] | undefined;

  private constructor(fieldDefinitions: TFieldDefinitions) {
    this._fieldDefinitions = fieldDefinitions;
  }

  static create<TFieldContract extends AnyRecord>() {
    return new FormContract<TFieldContract>({});
  }

  public fields<T extends { [k in keyof TFieldContract]: AnyFieldDefinition }>(fieldDefinitions: T) {
    return new FormContract<TFieldContract, T>(fieldDefinitions);
  }

  public template<K extends FormBuilderTemplateMapperKey>(
    key: K,
    props?: FormBuilderPartialTemplatePropsByKey<K>
  ) {
    this._template = key;
    if (props) {
      this._templateProps = props as FormBuilderPartialTemplateProps[keyof FormBuilderPartialTemplateProps];
    }
    return this;
  }

  public schema<T extends Schema<IAnyField>>(schema: FormContractSchemaCallback<T, TFieldDefinitions>) {
    if (!this._fieldDefinitions) {
      throw new Error('Missing field definitions');
    }

    // Build the real ddf field definitions from the FieldDefinition instances
    const builtFieldDefs = Object.entries(this._fieldDefinitions).reduce((acc, [key, fieldDef]) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      acc[key as keyof TFieldDefinitions] = fieldDef.build();
      return acc;
    }, {} as TFieldDefinitions);

    // Pass to schema callback, so we don't need to do "schema((f) => f.someField.build())"
    // We can just do "schema(f => f.someField)"
    this._schema = schema(builtFieldDefs);
    return this;
  }

  public build(): FormContractDefinition<TFieldDefinitions> {
    if (!this._fieldDefinitions) {
      throw new Error('Missing field definitions');
    }

    if (!this._schema) {
      throw new Error('Missing schema');
    }

    return {
      fieldDefinitions: this._fieldDefinitions,
      schema: this._schema,
      template: this._template,
      templateProps: this._templateProps
    };
  }
}

export type AnyFormContract = FormContract<AnyRecord, Record<string, AnyFieldDefinition>>;
