import { Input } from '../input/input';
import type { InputProps } from '../input/input';
import clsx from 'clsx';
import { ClockIcon } from '@radix-ui/react-icons';
import { Icon } from '../icon/icon';
import { IMask, useIMask } from 'react-imask';
import { useEffect, forwardRef, useCallback } from 'react';
import type { ChangeEvent } from 'react';
import { format, parse } from 'date-fns';
import { Box } from '../../system/components/box/box';
import { useMergedRef } from '../../system/hooks/use-merge-ref';
import { __DEV__ } from '../../system/utils/assertion';

const dateFormat = 'HH:mm:ss';
type TimeInputMaskConfig = Omit<IMask.MaskedDateOptions, 'mask' | 'parse'> & {
  mask: IMask.AnyMaskedOptions['mask'];
  parse: (value: string) => Date | undefined;
};
export const TIME_INPUT_MASK_CONFIG: TimeInputMaskConfig = {
  mask: dateFormat,
  blocks: {
    HH: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 23,
      maxLength: 2,
      placeholderChar: 'h'
    },
    mm: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 59,
      maxLength: 2,
      placeholderChar: 'm'
    },
    ss: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 59,
      maxLength: 2,
      placeholderChar: 's'
    }
  },
  prepare: (value: unknown) => String(value).toUpperCase(),
  format: (date: Date) => {
    return format(new Date(date), dateFormat);
  },
  parse: (str: string) => {
    const date = parse(str, dateFormat, new Date());

    return isNaN(date.getTime()) ? undefined : date;
  },
  autofix: true, // defaults to `false`, see details

  // also Pattern options can be set
  lazy: false
};

export const TimePicker = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { className, placeholder, children, pattern, value: _value, ...restProps } = props;

  const onAccept = useCallback(
    (_val: string, maskRef: IMask.InputMask<IMask.MaskedDateOptions>, e?: InputEvent) => {
      if (e && props.onChange) {
        props.onChange({
          ...e,
          target: {
            ...e.target,
            value: isNaN(maskRef.typedValue?.getTime()) ? undefined : maskRef.typedValue.toString()
          }
        } as unknown as ChangeEvent<HTMLInputElement>);
      }
    },
    [props]
  );

  const {
    ref: iMaskInputRef,
    setValue,
    value,
    maskRef
  } = useIMask(TIME_INPUT_MASK_CONFIG as IMask.MaskedDateOptions, {
    onAccept
  });

  useEffect(() => {
    if (TIME_INPUT_MASK_CONFIG.format && maskRef.current && props.value) {
      setValue(TIME_INPUT_MASK_CONFIG.format(props.value as any));
      maskRef.current.updateValue();
    }
  }, [TIME_INPUT_MASK_CONFIG, maskRef, props.value, setValue]);

  return (
    <Box sx={{ display: 'inline-flex', flexDirection: 'row' }}>
      <Input
        {...restProps}
        className={clsx(className)}
        ref={useMergedRef(ref, iMaskInputRef)}
        type="text"
        value={value}
      />
      <Icon
        label="Clock"
        as={ClockIcon}
        style={{ left: 'auto', right: '10px' }}
        sx={{
          color: 'icons.semiMinor',
          position: 'absolute',
          alignSelf: 'center',
          justifySelf: 'center'
        }}
      />
    </Box>
  );
});

if (__DEV__) {
  TimePicker.displayName = 'TimePicker';
}
