/**
 * Extract the label regardless of case sensitive key matching
 * @param k key to look up
 * @param enumMap Enum or record to query
 * @returns string or undefined
 */
const extractLabelCaseInsensitiveMatch = <TEnum extends Record<string, string>>(
  k: string,
  enumMap: TEnum
): string | undefined => {
  // case: exact match
  const matchedVerbatim = enumMap[k] !== undefined;
  if (matchedVerbatim) {
    return enumMap[k];
  }
  // case: coerce lookup key to uppercase due to wonky codegen
  const kUpper = k.toUpperCase();
  const matchedUpper = enumMap[kUpper] !== undefined;
  if (matchedUpper) {
    return enumMap[kUpper];
  } else {
    return undefined;
  }
};

/**
 * Create an array of value:label pairs (e.g., for a Select input's options)
 * @param keys array of string keys to look up
 * @param enumMap enum/map object
 * @param excludeUnmapped whether to exclude keys that don't exist in enumMap (default: true)
 * If false, will include a record with value + label set to the unmapped value.
 */
export const makeValueLabelPairs = <TEnum extends Record<string, string>>(
  keys: Array<string>,
  enumMap: TEnum,
  excludeUnmapped: boolean = true
) => {
  const initArr: Array<{ label: string; value: string }> = [];
  if (keys && keys.length && enumMap && Object.keys(enumMap)) {
    return keys.reduce((acc, iter) => {
      const found = extractLabelCaseInsensitiveMatch(iter, enumMap);
      if (found) {
        // handle found case
        return [...acc, ...[{ value: iter, label: found as any }]];
      } else if (!found && !excludeUnmapped) {
        // handle unmapped case
        return [...acc, ...[{ value: iter, label: iter }]];
      } else {
        return acc;
      }
    }, initArr);
  } else {
    return initArr;
  }
};
