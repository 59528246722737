import { useFormApi } from '@data-driven-forms/react-form-renderer';
import { IconButton, Box, type Sprinkles, Flex, Button, AddIcon } from '@oms/ui-design-system';
import { TrashIcon } from '@radix-ui/react-icons';
import { type FieldValidator } from 'final-form';
import { type FC, type MouseEventHandler, useCallback, useEffect, useMemo, useRef } from 'react';
import { type FieldArrayRenderProps, FieldArray } from 'react-final-form-arrays';
import { useFieldApi } from '../../helpers';
import { type FieldProps, type ICommonField } from '../../types';
import { type AllFieldWrapperProps, FieldWrapper } from '../field-wrapper/field-wrapper';
import { type FormFieldArrayProps } from './field-array.type';

export interface FieldItemProps {
  fields: ICommonField<any>[];
  fieldIndex: number;
  name: string;
  remove: (idx: number) => void;
  sx?: Sprinkles;
  min?: number;
  canAdd?: boolean;
}

export const FieldItem: FC<FieldItemProps> = ({ fields, name, remove, fieldIndex, sx, min, canAdd }) => {
  const { renderForm } = useFormApi();
  const editedFields = fields.map((field) => ({ ...field, name: `${name}.${field.name}` }));

  const onRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      remove(fieldIndex);
    },
    [fieldIndex, remove]
  );

  const canRemove = useMemo(() => (min && min !== fieldIndex + 1) || !min, [fieldIndex, min]);

  return (
    <Box
      sx={{
        marginBottom: 4,
        display: 'flex',
        flexDirection: 'row',
        ...sx
      }}
      data-testid={`${name}-container`}
    >
      <Box
        sx={{
          bg: 'layout.level3',
          width: 'full',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
          padding: 4,
          borderRadius: 'sm'
        }}
      >
        {renderForm(editedFields)}
      </Box>
      {canRemove && (
        <IconButton
          aria-label={'close'}
          icon={<TrashIcon />}
          shape={'square'}
          variant="ghost"
          sx={{ flexGrow: 0, alignSelf: 'center', justifySelf: 'center' }}
          onClick={onRemove}
        />
      )}
      {!canRemove && canAdd && (
        <Box
          as={'div'}
          style={{ width: '24px' }}
          sx={{ flexGrow: 0, alignSelf: 'center', justifySelf: 'center' }}
        />
      )}
    </Box>
  );
};

export const FormFieldArray: FC<FieldProps<FormFieldArrayProps>> = (props) => {
  const {
    form = { fields: [], sx: {} },
    container = { sx: {} },
    style = {},
    min,
    meta,
    label,
    isReadOnly,
    isRequired,
    isDisabled,
    isInvalid,
    requiredFieldIndicatorStyle,
    helperText,
    hideFormControls,
    validate,
    button,
    sx = {},
    direction = 'column',
    ...rest
  } = useFieldApi<FormFieldArrayProps>(props);
  const arrayProps = useRef<FieldArrayRenderProps<any, HTMLElement>>();

  const extraProps: AllFieldWrapperProps = {
    meta,
    label,
    isReadOnly,
    isRequired,
    isDisabled,
    isInvalid,
    requiredFieldIndicatorStyle,
    helperText,
    hideFormControls
  };

  const onAdd = useCallback(
    (cosi: FieldArrayRenderProps<any, HTMLElement>, e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      cosi.fields.push({});
    },
    []
  );

  const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => onAdd(arrayProps.current!, e),
    [onAdd]
  );

  useEffect(() => {
    const len = arrayProps.current?.fields.length || 0;
    if (min && arrayProps.current && len < min) {
      const diff = min - len;

      for (let i = 0; i < diff; i++) {
        arrayProps.current.fields.push({});
      }
    }
  }, [min]);

  return (
    <FieldWrapper
      {...extraProps}
      style={style}
      sx={{
        marginY: 2,
        marginX: 0,
        flexDirection: 'column',
        display: 'flex',
        overflow: 'auto',
        height: 'full',
        flexGrow: 1,
        ...sx
      }}
    >
      <>
        <Box
          sx={{
            overflow: 'auto',
            height: 'auto',
            display: 'flex',
            flexDirection: direction,
            flexGrow: 1,
            ...(container.sx || {})
          }}
        >
          <FieldArray
            key={rest.input.name}
            name={rest.input.name}
            validate={validate as any as FieldValidator<any[]>}
          >
            {(cosi) => {
              arrayProps.current = cosi;
              return cosi.fields.map((name, index) => (
                <FieldItem
                  min={min}
                  key={`${name || rest.input.name}-${index}`}
                  fields={form.fields || []}
                  fieldIndex={index}
                  name={name}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                  remove={cosi.fields.remove}
                  canAdd={!!button}
                />
              ));
            }}
          </FieldArray>
        </Box>
        {button && (
          <Flex
            sx={{ margin: 1, padding: 1 }}
            grow={0}
            direction={'row'}
            justify={'flex-start'}
            align={'flex-end'}
          >
            <Button
              {...button}
              data-testid={`field-array-${rest.input.name}-add-button`}
              leftIcon={<AddIcon />}
              onClick={onClick}
            >
              {button.buttonText}
            </Button>
          </Flex>
        )}
      </>
    </FieldWrapper>
  );
};
