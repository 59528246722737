import { PriceType, QuantityType, TimeInForce } from '@oms/generated/frontend';
import { FieldContract } from '@oms/frontend-foundation';
import { comboBoxItemsFrom } from '@oms/ui-design-system';
import type {
  MontageSettingsSchema,
  LeftClickSettingsType
} from '@app/data-access/offline/collections/user-preferences.collection';
import { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import { createVenueField } from '@app/forms/common/fields/venue-field/venue.field.common';
import { createSideAdvancedSelectField } from '@app/forms/common/fields/side-field/side-field.common';
import { createOrderSizeField as createOrderSizeFieldCommon } from '@app/forms/common/fields/order-size-field/order-size.field';
import {
  getAdvancedSelectInitialValue,
  getKey,
  getPriceTypeLabel,
  getQuantityLabel,
  makeTimeInForceOptions
} from './montage-settings.util';

const fc = FieldContract.create<MontageSettingsSchema, AdditionalFormFieldUnion>();

export const createDestinationIdField = (type: LeftClickSettingsType) =>
  createVenueField(fc, getKey(type, 'destinationId'));

export const createGtdTimestampField = (type: LeftClickSettingsType) =>
  fc.field(getKey(type, 'gtdTimestamp'), 'text-field').options({
    label: 'GTD Timestamp',
    isVisible: false
  });

export const createDisplaySizeField = (type: LeftClickSettingsType) =>
  fc.field(getKey(type, 'displaySize'), 'number-format').options({
    label: 'Display size',
    format: 'number'
  });

export const createInitiateOrderField = (type: LeftClickSettingsType) =>
  fc.field(getKey(type, 'initiateOrder'), 'checkbox').options({
    label: 'Initiate order'
  });

export const createOrderSizeField = (type: LeftClickSettingsType) =>
  createOrderSizeFieldCommon(fc, getKey(type, 'orderSize'), {
    validate: [{ type: 'required' }]
  });

export const createPriceTypeField = (type: LeftClickSettingsType) =>
  fc.field(getKey(type, 'priceType'), 'advanced-select').advancedSelectOptions({
    label: 'Price',
    options: comboBoxItemsFrom
      .enum(PriceType, {
        label: getPriceTypeLabel
      })
      .filter(({ value }) => value !== PriceType.Blank),
    initialValue: getAdvancedSelectInitialValue(type, 'priceType')
  });

export const createQuantityTypeField = (type: LeftClickSettingsType) =>
  fc.field(getKey(type, 'quantityType'), 'advanced-select').advancedSelectOptions({
    label: 'Quantity',
    options: comboBoxItemsFrom.enum(QuantityType, {
      label: getQuantityLabel
    }),
    initialValue: getAdvancedSelectInitialValue(type, 'quantityType')
  });

export const createQuantityValueField = (type: LeftClickSettingsType) =>
  fc.field(getKey(type, 'quantityValue'), 'number-format').options({
    label: 'Quantity value',
    format: 'number',
    validate: [{ type: 'required' }]
  });

export const createSideTypeField = (type: LeftClickSettingsType) =>
  createSideAdvancedSelectField(fc, getKey(type, 'sideType'), {
    initialValue: getAdvancedSelectInitialValue(type, 'sideType')
  });

export const createStrategyField = (type: LeftClickSettingsType) =>
  fc.field(getKey(type, 'strategy'), 'fixatdl-strategy').options({
    label: 'Strategy',
    venueIdKey: getKey(type, 'destinationId'),
    strategyFieldKey: getKey(type, 'strategy'),
    isDialogEnabled: false
  });

// TODO: used text-field until fixatdl-strategy is migrated
export const createStrategyPresetsField = (type: LeftClickSettingsType) =>
  fc.field(getKey(type, 'strategyPresets'), 'text-field').options({
    label: 'Strategy presets'
  });

export const createTifDurationField = (type: LeftClickSettingsType) =>
  fc.field(getKey(type, 'gtdTimestamp'), 'text-field').options({
    label: 'GTD Timestamp',
    isVisible: false
  });

export const createTimeInForceTypeField = (type: LeftClickSettingsType) =>
  fc.field(getKey(type, 'timeInForceType'), 'multi-input').multiInputOptions<TimeInForce, string>({
    name: 'tif',
    label: 'TIF',
    validate: [{ type: 'validateTifInput' }],
    options: makeTimeInForceOptions(type)
  });

export const createTimeInForceValueField = (type: LeftClickSettingsType) =>
  fc.field(getKey(type, 'timeInForceValue'), 'text-field').options({
    label: 'TIF',
    isVisible: false
  });

export const hideOddLots = fc.field('hideOddLots', 'checkbox').options({
  label: 'Hide odd lots'
});

export const displayQuotesInShares = fc.field('displayQuotesInShares', 'checkbox').options({
  label: 'Display quotes in shares'
});

export const sendAttributable = fc.field('sendAttributable', 'checkbox').options({
  label: 'Send Attributable'
});
