import { ErrorLink, ErrorResponse } from '@apollo/client/link/error';
import { Logger } from '@oms/ui-util';
import * as Sentry from '@sentry/react';
import { createGraphQLErrorFingerprint, graphqlContextForSentry } from './apollo-client.sentry.context';

const l = Logger.create({
  name: 'apollo.client.err.handler'
});

export const apolloClientErrorHandler: ErrorLink.ErrorHandler = ({
  graphQLErrors,
  networkError,
  response,
  operation
}: ErrorResponse) => {
  l.error(`Apollo Error: ${operation.operationName}`, {
    response,
    graphQLErrors,
    networkError
  });

  // https://github.com/DiederikvandenB/apollo-link-sentry
  // https://www.fieldguide.io/blog/graphql-observability-with-sentry
  Sentry.withScope((scope) => {
    scope.setTransactionName(operation.operationName);

    graphqlContextForSentry({
      document: operation.query,
      scope,
      variables: operation.variables,
      extensions: operation.extensions
    });

    graphQLErrors?.forEach((error) => {
      Sentry.captureMessage(error.message, {
        level: 'error',
        fingerprint: createGraphQLErrorFingerprint(operation.operationName, error),
        contexts: {
          apolloGraphQLError: {
            error,
            message: error.message,
            extensions: error.extensions
          }
        }
      });
    });

    if (networkError) {
      Sentry.captureMessage(networkError.message, {
        level: 'error',
        contexts: {
          apolloNetworkError: {
            error: networkError,
            extensions: (networkError as any)?.extensions
          }
        }
      });
    }
  });
};
