import type { ApolloQueryResult, FetchPolicy } from '@apollo/client';
import { NetworkStatus } from '@apollo/client';
import type {
  ExtendedCoveragePersonObject,
  ExtendedGroupObject,
  ExtendedUserObject
} from '@app/common/types/users/types';
import User from '@app/common/types/users/user.class';
import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import type {
  AllGQLResponseErrors,
  AwaitGQLResultType,
  DataSourceCommon,
  GQLQueryResp,
  ICrudService
} from '@oms/frontend-foundation';
import { asDataSource, GQLResult, toGqlDatasource } from '@oms/frontend-foundation';
import type {
  ClearUserPreferencesMutation,
  ClearUserPreferencesMutationVariables,
  CreateUserMutation,
  CreateUserMutationVariables,
  CreateUserPreferencesMutation,
  CreateUserPreferencesMutationVariables,
  GetRoutableUsersForInvestorOrderQuery,
  GetUserPreferencesQuery,
  GetUserPreferencesQueryVariables,
  GetUserQuery,
  GetUserQueryVariables,
  GetUsersWithOptionsAndTeamsQuery,
  GetUserWithEntitlementsQuery,
  ListGroupsMembersQuery,
  MutationClearUserMontagePreferencesArgs,
  MutationClearUserPreferencesArgs,
  MutationCreateUserPreferencesArgs,
  MutationOverwriteUserPreferencesArgs,
  MutationUpdateUserPreferencesArgs,
  OverwriteUserPreferencesMutation,
  SimpleUsersQuery,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UpdateUserPreferencesMutation,
  UserGroupFragment,
  UserInput,
  UserPreferences
} from '@oms/generated/frontend';
import {
  ClearUserPreferencesDocument,
  CreateUserDocument,
  CreateUserPreferencesDocument,
  GetExecutionVenuesForUserDocument,
  GetRoutableUsersForInvestorOrderDocument,
  GetUserDocument,
  GetUserPreferencesDocument,
  GetUsersDocument,
  GetUsersWithOptionsAndTeamsDocument,
  GetUserWithEntitlementsDocument,
  ListGroupsMembersDocument,
  OverwriteUserPreferencesDocument,
  SimpleUsersDocument,
  UpdateUserDocument,
  UpdateUserPreferencesDocument
} from '@oms/generated/frontend';
import type { GQLErrorType } from '@oms/shared/oms-common';
import { GeneralError } from '@oms/shared/oms-common';
import { asArray, cleanMaybe, logger } from '@oms/ui-util';
import type { OneOrMore, Optional } from '@oms/ui-util';
import type { Observable } from 'rxjs';
import { catchError, firstValueFrom, map, of, startWith } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import { testScoped } from '@app/workspace.registry';

@testScoped
@singleton()
export class UsersService implements ICrudService<ExtendedUserObject> {
  protected _apolloClient: RxApolloClient;
  private _gqlResponse: GQLResponse;

  protected name: string = 'UsersService';
  protected logger: typeof logger.debug;

  protected fetchPolicy: FetchPolicy = 'cache-first';

  // 🏗️ Constructor ------------------------------------------------------- /

  public constructor(
    @inject(RxApolloClient) apolloClient: RxApolloClient,
    @inject(GQLResponse) gqlResponse: GQLResponse
  ) {
    this._apolloClient = apolloClient;
    this._gqlResponse = gqlResponse;
    this.logger = logger.as(this.name);
  }

  // 🙋 User queries --------------------------------------------------------- /

  public watchAll$ = (): Observable<DataSourceCommon<ExtendedUserObject>> =>
    this._watchQuery_GetSimpleUsers$().pipe(
      map((users) => users),
      startWith(asDataSource([], { isFetching: true })),
      catchError((_) => {
        return of(asDataSource([] as ExtendedUserObject[]));
      })
    );

  public watchForRoutableUsers$ = (
    investorOrderId: string
  ): Observable<DataSourceCommon<ExtendedUserObject>> =>
    this._watchQuery_GetRoutableUsers$(investorOrderId).pipe(
      map((users) => users),
      startWith(asDataSource([], { isFetching: true })),
      catchError((_) => {
        return of(asDataSource([] as ExtendedUserObject[]));
      })
    );

  public watchForPrimaryCoverage$ = (): Observable<DataSourceCommon<ExtendedCoveragePersonObject>> =>
    this._watchQuery_GetUsersForPrimaryCoverage$().pipe(
      map((users) => users),
      startWith(asDataSource([], { isFetching: true })),
      catchError((_) => {
        return of(asDataSource([] as ExtendedCoveragePersonObject[]));
      })
    );

  public watchForNonPrimaryCoverage$ = (): Observable<DataSourceCommon<ExtendedCoveragePersonObject>> =>
    this._watchQuery_GetUsersForNonPrimaryCoverage$().pipe(
      map((users) => users),
      startWith(asDataSource([], { isFetching: true })),
      catchError((_) => {
        return of(asDataSource([] as ExtendedCoveragePersonObject[]));
      })
    );

  public watchUsersFromTeams$ = (groupIds: string[]): Observable<DataSourceCommon<ExtendedUserObject>> =>
    this._watchQuery_GetUsersFromTeams$(groupIds).pipe(
      map((users) => users),
      startWith(asDataSource([], { isFetching: true })),
      catchError((_) => {
        return of(asDataSource([] as ExtendedUserObject[]));
      })
    );

  public getUser = async (id: string): Promise<Optional<ExtendedUserObject>> =>
    await firstValueFrom(this._watchQuery_GetUser$(id));

  public getUser$ = (id?: string): Observable<Optional<ExtendedUserObject>> => {
    if (!id) return of(undefined);
    return this._watchQuery_GetUser$(id);
  };

  public getUserPreferences = (userId: string): Promise<ApolloQueryResult<GetUserPreferencesQuery>> => {
    const result = this._apolloClient.query<GetUserPreferencesQuery, GetUserPreferencesQueryVariables>({
      query: GetUserPreferencesDocument,
      fetchPolicy: this.fetchPolicy,
      variables: {
        userId
      }
    });

    return result;
  };

  public getUserWithEntitlements = (): Promise<ApolloQueryResult<GetUserWithEntitlementsQuery>> => {
    return this._apolloClient.query<GetUserWithEntitlementsQuery>({
      query: GetUserWithEntitlementsDocument,
      fetchPolicy: 'network-only'
    });
  };

  // 🙋 User mutations --------------------------------------------------------- /

  public createUser = async (createUser: UserInput): AwaitGQLResultType<ExtendedUserObject> => {
    const result = await this._mutation_CreateUser(createUser);
    return result.mapTo(
      ({ data: createdUser }) => {
        if (!createdUser) {
          const message = 'User could not be created';
          this.logger.warn(`${message} with input:`, createUser);
          return GQLResult.failure([new GeneralError(message)]);
        }
        this.logger.debug('Create user completed successfully.', createdUser);
        this.notify({
          title: 'User Created',
          body: `New user${createdUser.name ? `, ${createdUser.name},` : ''} has been created.`,
          status: 'success'
        });
        return result;
      },
      (errors) => {
        errors.forEach(this.logger.error);
        this.logger.warn('Create user failed with input:', createUser);
        return this.wrapGQLFailure(errors);
      }
    );
  };

  public updateUser = async (
    userId: string,
    updateUserInput: UserInput
  ): AwaitGQLResultType<ExtendedUserObject> => {
    const result = await this._mutation_UpdateUser(userId, updateUserInput);
    return result.mapTo(
      ({ data: updatedUser }) => {
        if (!updatedUser) {
          const message = 'User could not be updated';
          this.logger.warn(`User ${userId} could not be updated with input:`, updateUserInput);
          return GQLResult.failure([new GeneralError(message)]);
        }
        this.logger.debug(`Update user ${userId} completed successfully.`, updatedUser);
        this.notify({
          title: 'User Updated',
          body: `User${updatedUser.name ? `, ${updatedUser.name},` : ''} has been updated.`,
          status: 'success'
        });
        return result;
      },
      (errors) => {
        errors.forEach(this.logger.error);
        this.logger.warn(`Update user ${userId} failed with input:`, updateUserInput);
        return this.wrapGQLFailure(errors);
      }
    );
  };

  public createUserPreferences = async (
    input: MutationCreateUserPreferencesArgs
  ): AwaitGQLResultType<UserPreferences> => {
    const result = await this._mutation_CreateUserPreferences(input);
    return result.mapTo(
      ({ data: createdUserPreferences }) => {
        if (!createdUserPreferences) {
          const message = 'User preferences could not be created';
          this.logger.warn(`${message} for user ${input.userId} with input:`, input.userPreferences);
          return GQLResult.failure([new GeneralError(message)]);
        }
        this.logger.debug('Create user preferences completed successfully.', createdUserPreferences);
        return result;
      },
      (errors) => {
        errors.forEach(this.logger.error);
        this.logger.warn(
          `Creating user preferences ${input.userId} failed with input:`,
          input.userPreferences
        );
        return this.wrapGQLFailure(errors);
      }
    );
  };

  public updateUserPreferences = async (
    input: MutationUpdateUserPreferencesArgs
  ): AwaitGQLResultType<UserPreferences> => {
    const result = await this._mutation_UpdateUserPreferences(input);
    return result.mapTo(
      ({ data: updatedUserPreferences }) => {
        if (!updatedUserPreferences) {
          const message = 'User preferences could not be updated';
          this.logger.warn(`${message} for user ${input.userId} with input:`, input.userPreferences);
          return GQLResult.failure([new GeneralError(message)]);
        }
        this.logger.debug('Update user preferences completed successfully.', updatedUserPreferences);
        return result;
      },
      (errors) => {
        errors.forEach(this.logger.error);
        this.logger.warn(
          `Updating user preferences ${input.userId} failed with input:`,
          input.userPreferences
        );
        return this.wrapGQLFailure(errors);
      }
    );
  };

  public overwriteUserPreferences = async (
    input: MutationOverwriteUserPreferencesArgs
  ): AwaitGQLResultType<UserPreferences> => {
    const result = await this._mutation_OverwriteUserPreferences(input);
    return result.mapTo(
      ({ data: overwrittenUserPreferences }) => {
        if (!overwrittenUserPreferences) {
          const message = 'User preferences could not be overwrttien';
          this.logger.warn(`${message} for user ${input.userId} with input:`, input.userPreferences);
          return GQLResult.failure([new GeneralError(message)]);
        }
        this.logger.debug('Overwrite user preferences completed successfully.', overwrittenUserPreferences);
        return result;
      },
      (errors) => {
        errors.forEach(this.logger.error);
        this.logger.warn(
          `Overwriting user preferences ${input.userId} failed with input:`,
          input.userPreferences
        );
        return this.wrapGQLFailure(errors);
      }
    );
  };

  public resetUserPreferences = async (
    input: MutationClearUserPreferencesArgs
  ): AwaitGQLResultType<UserPreferences> => {
    const result = await this._mutation_ClearUserPreferences(input);
    return result.mapTo(
      ({ data: clearedMontagePreferences }) => {
        if (!clearedMontagePreferences) {
          const message = 'Montage preferences could not be reset';
          this.logger.warn(`${message} for user ${input.userId} with input`);
          return GQLResult.failure([new GeneralError(message)]);
        }
        this.logger.debug('Reset montage preferences completed successfully.', clearedMontagePreferences);
        return result;
      },
      (errors) => {
        errors.forEach(this.logger.error);
        this.logger.warn(`Resetting montage preferences ${input.userId} failed`);
        return this.wrapGQLFailure(errors);
      }
    );
  };

  // 🔒 Protected --------------------------------------------------------- /

  protected notify = (_: any) => {
    throw new Error('not implemented!');
  };

  // 🔍 Queries ----------------- /

  protected _watchQuery_GetSimpleUsers$ = (): Observable<DataSourceCommon<ExtendedUserObject>> => {
    const result = this._apolloClient.rxWatchQuery<SimpleUsersQuery>({
      query: SimpleUsersDocument,
      fetchPolicy: this.fetchPolicy,
      pollInterval: 1000 * 60
    });
    return result.pipe(
      toGqlDatasource(({ simpleUsers }) =>
        cleanMaybe(simpleUsers, []).map((baseUser) => User.extend(baseUser))
      )
    );
  };

  protected _watchQuery_GetRoutableUsers$ = (
    investorOrderId: string
  ): Observable<DataSourceCommon<ExtendedUserObject>> => {
    const result = this._apolloClient.rxWatchQuery<GetRoutableUsersForInvestorOrderQuery>({
      query: GetRoutableUsersForInvestorOrderDocument,
      fetchPolicy: this.fetchPolicy,
      pollInterval: 1000 * 60,
      variables: {
        investorOrderId
      }
    });
    return result.pipe(
      toGqlDatasource(({ getRoutableUsersForInvestorOrder }) => {
        return cleanMaybe(getRoutableUsersForInvestorOrder, []).map((baseUser) => User.extend(baseUser));
      })
    );
  };

  protected _watchQuery_GetUsersForPrimaryCoverage$ = (): Observable<
    DataSourceCommon<ExtendedCoveragePersonObject>
  > => {
    const result = this._apolloClient.rxWatchQuery<GetUsersWithOptionsAndTeamsQuery>({
      query: GetUsersWithOptionsAndTeamsDocument,
      fetchPolicy: this.fetchPolicy,
      pollInterval: 1000 * 60,
      variables: {
        queryOptions: {
          includeFacets: ['PRIMARY_COVERAGE_DOMAIN']
        }
      }
    });
    return result.pipe(
      toGqlDatasource(({ simpleUsers, groups }) => {
        const gqlUsers: ExtendedUserObject[] = cleanMaybe(simpleUsers, []).map((baseUser) =>
          User.extend(baseUser)
        );
        const gqlGroups: ExtendedGroupObject[] = this.buildExtendGroupObjects(groups);
        return [...gqlGroups, ...gqlUsers];
      })
    );
  };

  protected _watchQuery_GetUsersForNonPrimaryCoverage$ = (): Observable<
    DataSourceCommon<ExtendedCoveragePersonObject>
  > => {
    const result = this._apolloClient.rxWatchQuery<GetUsersWithOptionsAndTeamsQuery>({
      query: GetUsersWithOptionsAndTeamsDocument,
      fetchPolicy: this.fetchPolicy,
      pollInterval: 1000 * 60,
      variables: {
        queryOptions: {
          includeFacets: ['PRIMARY_COVERAGE_DOMAIN']
        }
      }
    });
    return result.pipe(
      toGqlDatasource(({ simpleUsers, groups }) => {
        const gqlUsers: ExtendedUserObject[] = cleanMaybe(simpleUsers, []).map((simpleUser) =>
          User.extend(simpleUser)
        );
        const gqlGroups: ExtendedGroupObject[] = this.buildExtendGroupObjects(groups);
        return [...gqlGroups, ...gqlUsers];
      })
    );
  };

  protected _watchQuery_GetUsersFromTeams$ = (
    groupIds: string[]
  ): Observable<DataSourceCommon<ExtendedUserObject>> => {
    const result = this._apolloClient.rxWatchQuery<ListGroupsMembersQuery>({
      query: ListGroupsMembersDocument,
      fetchPolicy: this.fetchPolicy,
      pollInterval: 1000 * 60,
      variables: {
        groupIds
      }
    });
    return result.pipe(
      toGqlDatasource(({ listGroupsMembers }) => {
        return cleanMaybe(listGroupsMembers, []).map((baseUser) => User.extend(baseUser));
      })
    );
  };

  private buildExtendGroupObjects = (groups: UserGroupFragment[]): ExtendedGroupObject[] => {
    return cleanMaybe(groups, []).map((group) => {
      const groupAsUser: ExtendedGroupObject = {
        __typename: 'Group',
        id: group?.id ?? '',
        enabled: true,
        label: group?.name ?? '',
        name: group?.name ?? ''
      };
      return groupAsUser;
    });
  };

  protected _watchQuery_GetUser$ = (id: string): Observable<Optional<ExtendedUserObject>> => {
    const result = this._apolloClient.rxWatchQuery<GetUserQuery, GetUserQueryVariables>({
      query: GetUserDocument,
      fetchPolicy: this.fetchPolicy,
      variables: {
        id
      }
    });
    return result.pipe(
      map(({ data }) => data),
      map(({ user }) => {
        if (!user) return;
        return User.extend(user);
      })
    );
  };

  // 💱 Mutations ----------------- /

  // 🙋 User ------ /

  protected wrapGQLSuccessData = <T>(data: T): GQLResult<GQLQueryResp<T>, AllGQLResponseErrors> =>
    GQLResult.success({ data, loading: false, networkStatus: NetworkStatus.ready });

  protected wrapGQLFailure = <T, E extends GQLErrorType>(
    errors: OneOrMore<E>
  ): GQLResult<GQLQueryResp<T>, E[]> => GQLResult.failure(asArray(errors));

  protected _mutation_CreateUser = async (createUser: UserInput): AwaitGQLResultType<ExtendedUserObject> => {
    const mocked = this._getMock_CreateUser(createUser);
    if (typeof mocked !== 'undefined') return mocked;
    const result = await this._gqlResponse
      .wrapMutate<CreateUserMutation, CreateUserMutationVariables>({
        mutation: CreateUserDocument,
        variables: {
          createUser
        },
        refetchQueries: [GetUsersDocument],
        awaitRefetchQueries: true
      })
      .exec();
    return result.mapTo(
      ({ data }) => {
        const baseUser = cleanMaybe(data?.createUser);
        const createdUser = baseUser ? User.extend(baseUser) : undefined;
        return this.wrapGQLSuccessData(createdUser);
      },
      (errors) => {
        errors.forEach(this.logger.error);
        return this.wrapGQLFailure(errors);
      }
    );
  };

  protected _mutation_CreateUserPreferences = async (
    input: CreateUserPreferencesMutationVariables
  ): AwaitGQLResultType<UserPreferences> => {
    const mocked = this._getMock_CreateUserPreferences(input);
    if (typeof mocked !== 'undefined') return mocked;
    const result = await this._gqlResponse
      .wrapMutate<CreateUserPreferencesMutation, CreateUserPreferencesMutationVariables>({
        mutation: CreateUserPreferencesDocument,
        variables: { ...input },
        refetchQueries: [{ query: GetUserPreferencesDocument, variables: { userId: input.userId } }],
        awaitRefetchQueries: true
      })
      .exec();
    return result.mapTo(
      ({ data }) => {
        const montagePreferences = cleanMaybe(data?.createUserPreferences);
        return this.wrapGQLSuccessData(montagePreferences);
      },
      (errors) => {
        errors.forEach(this.logger.error);
        return this.wrapGQLFailure(errors);
      }
    );
  };

  protected _mutation_UpdateUserPreferences = async (
    input: MutationUpdateUserPreferencesArgs
  ): AwaitGQLResultType<UserPreferences> => {
    const mocked = this._getMock_UpdateUserPreferences(input);
    if (typeof mocked !== 'undefined') return mocked;
    const result = await this._gqlResponse
      .wrapMutate<UpdateUserPreferencesMutation, MutationUpdateUserPreferencesArgs>({
        mutation: UpdateUserPreferencesDocument,
        variables: { ...input },
        refetchQueries: [{ query: GetUserPreferencesDocument, variables: { userId: input.userId } }],
        awaitRefetchQueries: true
      })
      .exec();
    return result.mapTo(
      ({ data }) => {
        const userPreferences = cleanMaybe(data?.updateUserPreferences);
        return this.wrapGQLSuccessData(userPreferences);
      },
      (errors) => {
        errors.forEach(this.logger.error);
        return this.wrapGQLFailure(errors);
      }
    );
  };

  protected _mutation_OverwriteUserPreferences = async (
    input: MutationOverwriteUserPreferencesArgs
  ): AwaitGQLResultType<UserPreferences> => {
    const mocked = this._getMock_OverwriteUserPreferences(input);
    if (typeof mocked !== 'undefined') return mocked;
    const result = await this._gqlResponse
      .wrapMutate<OverwriteUserPreferencesMutation, MutationOverwriteUserPreferencesArgs>({
        mutation: OverwriteUserPreferencesDocument,
        variables: { ...input },
        refetchQueries: [{ query: GetUserPreferencesDocument, variables: { userId: input.userId } }],
        awaitRefetchQueries: true
      })
      .exec();
    return result.mapTo(
      ({ data }) => {
        const userPreferences = cleanMaybe(data?.overwriteUserPreferences);
        return this.wrapGQLSuccessData(userPreferences);
      },
      (errors) => {
        errors.forEach(this.logger.error);
        return this.wrapGQLFailure(errors);
      }
    );
  };

  protected _mutation_ClearUserPreferences = async (
    input: MutationClearUserMontagePreferencesArgs
  ): AwaitGQLResultType<UserPreferences> => {
    const mocked = this._getMock_ClearUserPreferences(input);
    if (typeof mocked !== 'undefined') return mocked;
    const result = await this._gqlResponse
      .wrapMutate<ClearUserPreferencesMutation, ClearUserPreferencesMutationVariables>({
        mutation: ClearUserPreferencesDocument,
        variables: { ...input },
        refetchQueries: [{ query: GetUserPreferencesDocument, variables: { userId: input.userId } }],
        awaitRefetchQueries: true
      })
      .exec();
    return result.mapTo(
      ({ data }) => {
        const montagePreferences = cleanMaybe(data?.clearUserPreferences);
        return this.wrapGQLSuccessData(montagePreferences);
      },
      (errors) => {
        errors.forEach(this.logger.error);
        return this.wrapGQLFailure(errors);
      }
    );
  };

  /** This is a hook for subclasses to provide a mock vs. making a real call. Return `undefined` for normal un-mocked behavior. */
  protected _getMock_CreateUser = (
    _createUser: UserInput
  ): Optional<AwaitGQLResultType<ExtendedUserObject>> => undefined;

  protected _mutation_UpdateUser = async (
    userId: string,
    updateUser: UserInput
  ): AwaitGQLResultType<ExtendedUserObject> => {
    const mocked = this._getMock_UpdateUser(userId, updateUser);
    if (typeof mocked !== 'undefined') return mocked;
    const result = await this._gqlResponse
      .wrapMutate<UpdateUserMutation, UpdateUserMutationVariables>({
        mutation: UpdateUserDocument,
        variables: {
          userId,
          updateUser,
          positionAccessInput: {
            userId,
            accountIds: updateUser.access?.positionAccessIds ?? []
          }
        },
        refetchQueries: [
          { query: GetUserDocument, variables: { id: userId } },
          { query: GetExecutionVenuesForUserDocument }
        ],
        awaitRefetchQueries: true
      })
      .exec();
    return result.mapTo(
      ({ data }) => {
        const baseUser = cleanMaybe(data?.updateUser);
        const updatedUser = baseUser ? User.extend(baseUser) : undefined;
        return this.wrapGQLSuccessData(updatedUser);
      },
      (errors) => {
        errors.forEach(this.logger.error);
        return this.wrapGQLFailure(errors);
      }
    );
  };

  /** This is a hook for subclasses to provide a mock vs. making a real call. Return `undefined` for normal un-mocked behavior. */
  protected _getMock_UpdateUser = (
    _userId: string,
    _updateUser: UserInput
  ): Optional<AwaitGQLResultType<ExtendedUserObject>> => undefined;

  protected _getMock_CreateUserPreferences = (
    _input: MutationCreateUserPreferencesArgs
  ): Optional<AwaitGQLResultType<UserPreferences>> => undefined;

  protected _getMock_UpdateUserPreferences = (
    _input: MutationUpdateUserPreferencesArgs
  ): Optional<AwaitGQLResultType<UserPreferences>> => undefined;

  protected _getMock_OverwriteUserPreferences = (
    _input: MutationOverwriteUserPreferencesArgs
  ): Optional<AwaitGQLResultType<UserPreferences>> => undefined;

  protected _getMock_ClearUserPreferences = (
    _input: MutationCreateUserPreferencesArgs
  ): Optional<AwaitGQLResultType<UserPreferences>> => undefined;
}

export default UsersService;
