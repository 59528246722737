import { useLayoutEffect, useMemo } from 'react';
import { map } from 'rxjs';
import type { AnyRecord } from '@oms/ui-util';
import { useGridSelection$ } from '@app/data-access/memory/grid.events';
import { useCurrentFlexLayoutSelectedTabIdEvent$ } from '@app/data-access/memory/flexlayout.events';
import { INITIAL_TAB } from './grid-tab.constants';
import TabSelectionManager from './tab-selection-manager.class';

export const useTabSelectionManager = <TData extends AnyRecord>(gridType: string) => {
  // Listen to grid selection
  const gridSelection$ = useGridSelection$<TData>(gridType, 'scoped');

  // Listen to current tab
  const currentTabId$ = useCurrentFlexLayoutSelectedTabIdEvent$().pipe(map((tabId) => tabId ?? INITIAL_TAB));

  const tabSelectionManager = useMemo(
    () => TabSelectionManager.fromSeparateTabAndGridSelectionEvents(gridSelection$, currentTabId$),
    [currentTabId$, gridSelection$]
  );

  // Cleanup manager subscriptions
  useLayoutEffect(() => {
    const deinit = tabSelectionManager.init();
    return () => {
      deinit();
    };
  }, []);

  return tabSelectionManager;
};

export default useTabSelectionManager;
