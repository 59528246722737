import get from 'lodash/get';
import merge from 'lodash/merge';
import { Field, FormOptions, UseFieldApiConfig } from '@data-driven-forms/react-form-renderer';
import { FactRule, FactOperator } from '@oms/generated/frontend';
import type { ComboBoxItem } from '@oms/ui-design-system';
import { ICommonField, ICommonSelectField } from '@oms/frontend-foundation';
import { ADVANCED_SELECT_QUERY_ENUM } from '@app/generated/common';

export type AdvancedSelectQueryMapperKeys = keyof typeof ADVANCED_SELECT_QUERY_ENUM;

export const RULE_CRITERIA_ACTION = 'ruleCriteriaAction';
export const RULE_OPERATOR_ACTION = 'ruleOperatorAction';

export type RuleCriteriaType =
  // Currently, focusing only on instrument-related rules.
  // TODO: TKT-13153 - Uncomment/Add the remaining rule criteria types once the FactRule types are implemented on the BE.
  | FactRule.InstrumentCountryOfIncorporation
  | FactRule.InstrumentExchange
  | FactRule.InstrumentId
  | FactRule.InstrumentFirmIsMarketMaker
  | FactRule.InstrumentExchangeCountry;
// | 'account'
// | 'defaultOrderTags'
// | 'handlingInstruction'
// | 'orderEntryType'
// | 'orderTags'
// | 'orderType'
// | 'quantity'
// | 'sendingDesk'
// | 'instrumentList'
// | 'createdAt'
// | 'orderValue'
// | 'transmitedTimestamp'
// | 'validatedTimestamp'
// | 'receivedTimestamp'
// | 'captureTimestamp'
// | 'exchangeOpen'
// | 'exchangeClose'
// | 'settlement'
// | 'quantityAsADV'
// | 'underlyingAccount'
// | 'discretionaryType';

export const RULE_VALUE_INPUT_LOOKUP = new Map<RuleCriteriaType | string, 'string' | 'number' | 'enum'>([
  [FactRule.InstrumentCountryOfIncorporation, 'enum'],
  [FactRule.InstrumentExchange, 'enum'],
  [FactRule.InstrumentExchangeCountry, 'enum'],
  [FactRule.InstrumentFirmIsMarketMaker, 'enum'],
  [FactRule.InstrumentId, 'enum']
]);

export type ICommonFieldComponentType<TValue, TValidate> = Omit<
  ICommonField<TValue, TValidate>,
  'component'
> & {
  componentType: ICommonField<TValue, TValidate>['component'];
};

export type RuleCriteriaQuery<
  TField extends Partial<ICommonFieldComponentType<any, any>> = Partial<ICommonFieldComponentType<any, any>>
> = AdvancedSelectQueryMapperKeys | ComboBoxItem<any, any>[] | TField;

export enum YesNo {
  Yes,
  No
}

const YES_NO_OPTS: ComboBoxItem<boolean, boolean>[] = [
  {
    type: 'item',
    label: 'Yes',
    value: true,
    id: true
  },
  {
    type: 'item',
    label: 'No',
    value: false,
    id: false
  }
];

// TODO: TKT-13153 - Uncomment once the FactRule types are implemented on the BE.
// function formatEnumLabel(str: string): string {
//   if (str.startsWith('TPlus_')) {
//     return str.replace('TPlus_', 'T+'); // TPlus_1 to T+1
//   }
//   return str.replace(/([a-z])([A-Z])/g, '$1 $2'); // camelCase to Camel Case
// }

// export function fromEnum<TEnum extends Record<string, any>>(e: TEnum): ComboBoxItem<TEnum>[] {
//   return Object.keys(e).map((k) => ({
//     type: 'item',
//     label: formatEnumLabel(k),
//     value: e[k],
//     id: e[k]
//   }));
// }

const queryTypeToQuery = new Map<RuleCriteriaType, RuleCriteriaQuery>([
  // Currently, focusing only on instrument-related rules.
  // TODO: TKT-13153 - Uncomment/Add the remaining types once the FactRule types are implemented on the BE.
  [FactRule.InstrumentCountryOfIncorporation, ADVANCED_SELECT_QUERY_ENUM.EXCHANGE_COUNTRY_WATCH_QUERY],
  [FactRule.InstrumentExchange, ADVANCED_SELECT_QUERY_ENUM.WATCH_ALL_EXCHANGES_QUERY],
  [FactRule.InstrumentId, ADVANCED_SELECT_QUERY_ENUM.LOOKUP_INSTRUMENTS_QUERY],
  [FactRule.InstrumentFirmIsMarketMaker, YES_NO_OPTS],
  [FactRule.InstrumentExchangeCountry, ADVANCED_SELECT_QUERY_ENUM.EXCHANGE_COUNTRY_WATCH_QUERY]
  // ['account', ADVANCED_SELECT_QUERY_ENUM.VISIBLE_ACCOUNTS_WATCH_QUERY],
  // ['defaultOrderTags', ADVANCED_SELECT_QUERY_ENUM.ORDER_TAGS_WATCH_QUERY],
  // ['handlingInstruction', fromEnum(HandlingInstruction)],
  // ['orderEntryType', fromEnum(OrderEntryType)],
  // ['orderTags', ADVANCED_SELECT_QUERY_ENUM.ORDER_TAGS_WATCH_QUERY],
  // ['orderType', fromEnum(OrderType)],
  // ['quantity', { componentType: 'number-format' }],
  // ['sendingDesk', { componentType: 'text-field' }],
  // ['instrumentList', ADVANCED_SELECT_QUERY_ENUM.LOOKUP_INSTRUMENTS_QUERY], // GetResourceList
  // ['createdAt', { componentType: 'time-picker'}],
  // ['orderValue', { componentType: 'number-format' }],
  // ['transmitedTimestamp', { componentType: 'time-picker' }],
  // ['validatedTimestamp', { componentType: 'time-picker' }],
  // ['receivedTimestamp', { componentType: 'time-picker' }],
  // ['captureTimestamp', { componentType: 'time-picker' }],
  // ['exchangeOpen', YES_NO_OPTS],
  // ['exchangeClose', YES_NO_OPTS],
  // ['settlement', fromEnum(OrderSettleType)],
  // ['quantityAsADV', { componentType: 'number-format' }],
  // ['underlyingAccount', { componentType: 'text-field' }],
  // ['discretionaryType', fromEnum(OrderDiscretionaryType)]
]);

export interface RuleCriteriaConfig extends Record<string, any> {
  query?: string;
  options?: any[];
}

export interface RuleCriteraQueryOptions {
  criteriaField: string;
  inArray: boolean;
  value: string;
  renderer?: string;
}

export interface RuleOperatorOptions {
  inArray?: boolean;
  criteriaField: string;
  value: string;
}

export const operatorLabelMap: Record<FactOperator, string> = {
  [FactOperator.BeginsWith]: 'Begins With',
  [FactOperator.Contains]: 'Contains',
  [FactOperator.DoesNotContain]: 'Does Not Contain',
  [FactOperator.DoesNotEqual]: 'Does Not Equal',
  [FactOperator.EndsWith]: 'Ends With',
  [FactOperator.Equal]: 'Equals',
  [FactOperator.GreaterThan]: '>',
  [FactOperator.GreaterThanOrEqualTo]: '>=',
  [FactOperator.LessThan]: '<',
  [FactOperator.LessThanOrEqualTo]: '<='
};

export const mapFactOperatorToComboBoxItem = (
  operator: FactOperator
): ComboBoxItem<FactOperator, FactOperator> => ({
  type: 'item',
  label: operatorLabelMap[operator],
  value: operator,
  id: operator
});

const getOperatorOptions = (fieldType: string): ComboBoxItem<FactOperator, FactOperator>[] => {
  switch (fieldType) {
    case 'string':
      return [
        FactOperator.Contains,
        FactOperator.BeginsWith,
        FactOperator.EndsWith,
        FactOperator.Equal,
        FactOperator.DoesNotContain
      ].map(mapFactOperatorToComboBoxItem);
    case 'number':
      return [
        FactOperator.GreaterThan,
        FactOperator.GreaterThanOrEqualTo,
        FactOperator.LessThan,
        FactOperator.LessThanOrEqualTo,
        FactOperator.Equal,
        FactOperator.DoesNotEqual
      ].map((operator) => ({
        ...mapFactOperatorToComboBoxItem(operator),
        label:
          operator === FactOperator.Equal
            ? '='
            : operator === FactOperator.DoesNotEqual
              ? '!='
              : operatorLabelMap[operator]
      }));
    case 'enum':
      return [FactOperator.Equal, FactOperator.DoesNotEqual].map(mapFactOperatorToComboBoxItem);
    default:
      return [];
  }
};

export const ruleOperatorAction: (
  opts: RuleOperatorOptions
) => (
  _props: Field,
  field: UseFieldApiConfig,
  formOptions: FormOptions
) => Omit<ICommonSelectField<any>, 'name' | 'component'> =
  ({ inArray = false, criteriaField, value = 'value' }) =>
  (_props: Field, field: UseFieldApiConfig, formOptions: FormOptions) => {
    let root = '';
    if (inArray) {
      const splits = field.input.name.split('.');
      root = `${splits.splice(0, splits.length - 1).join('.')}.`;
    }

    const { getFieldState } = formOptions;
    const fieldName = `${root}${criteriaField}`;
    const criteriaState = getFieldState(fieldName);

    const result = get(criteriaState, value);
    const fieldType = RULE_VALUE_INPUT_LOOKUP.get(result) || 'string';

    const options = getOperatorOptions(fieldType);

    return {
      options
    };
  };

export const ruleCriteriaAction: (
  opt: RuleCriteraQueryOptions
) => (_props: Field, field: UseFieldApiConfig, formOptions: FormOptions) => any =
  ({ criteriaField, inArray = false, value = 'value' }) =>
  (_props: Field, field: UseFieldApiConfig, formOptions: FormOptions) => {
    let root = '';
    if (inArray) {
      const splits = field.input.name.split('.');
      root = `${splits.splice(0, splits.length - 1).join('.')}.`;
    }

    const { getFieldState } = formOptions;
    const fieldName = `${root}${criteriaField}`;
    const criteriaState = getFieldState(fieldName);
    const queryType: RuleCriteriaType = get(criteriaState, value);
    const propsToChange: RuleCriteriaConfig = {};
    const queryOptions = queryTypeToQuery.get(queryType);
    const isQuery = typeof queryOptions === 'string';
    const isOptions = Array.isArray(queryOptions);
    const isDdfComponent = !isOptions && typeof queryOptions === 'object';

    if (queryType && isQuery) {
      propsToChange.query = queryOptions;
    } else if (isDdfComponent) {
      merge(propsToChange, getFieldState(field.input.name), queryOptions);
    } else if (isOptions) {
      propsToChange.options = queryOptions || [];
      propsToChange.query = undefined;
    }

    !queryOptions && formOptions.change(field.input.name, undefined);

    return propsToChange;
  };
