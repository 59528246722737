import { GraphQLError } from 'graphql';
import type { FieldValidationError, IFieldValidationError } from './field-validation-error';
import type { ValidationError } from './validation-error';
import type { GeneralError, IGeneralError } from './general-error';
import type { AsyncError, IAsyncError } from './async-error';
import type { AsyncTimeoutError, IAsyncTimeoutError } from './async-timeout-error';
import { UnauthorizedError } from './unauthorized-error';
import type { ApolloError, ServerParseError } from '@apollo/client';
import { ServerError } from './network-error';

/**
 * Error base / generics
 */
export enum ErrorTypeEnum {
  FIELD_VALIDATION_ERROR = 'FIELD_VALIDATION_ERROR',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  UNAUTHORIZED = 'UNAUTHORIZED',
  GENERAL_ERROR = 'GENERAL_ERROR',
  ASYNC_ERROR = 'ASYNC_ERROR',
  ASYNC_TIMEOUT_ERROR = 'ASYNC_TIMEOUT_ERROR',
  SERVER_ERROR = 'SERVER_ERROR',
  SERVER_PARSE_ERROR = 'SERVER_PARSE_ERROR',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  ERROR = 'ERROR'
}

export type ErrorType = `${ErrorTypeEnum}`;

export const customErrorsTypes: ErrorType[] = [
  ErrorTypeEnum.FIELD_VALIDATION_ERROR,
  ErrorTypeEnum.VALIDATION_ERROR,
  ErrorTypeEnum.GENERAL_ERROR,
  ErrorTypeEnum.UNAUTHORIZED,
  ErrorTypeEnum.ASYNC_ERROR,
  ErrorTypeEnum.ASYNC_TIMEOUT_ERROR
];

export interface ICustomGraphQLError<TErrorType extends string, TData = any, TException extends Error = Error>
  extends Omit<GraphQLError, 'extensions'> {
  extensions: {
    type: TErrorType;
    code: string;
    data: TData;
    exception?: TException;
  };
}

/**
 * Custom Errors discriminated union
 */
export type IAllCustomErrors =
  | IGeneralError
  | IFieldValidationError
  | GraphQLError
  | IAsyncError
  | IAsyncTimeoutError
  | UnauthorizedError;

/**
 * All GQL errors
 */
export type GQLErrorMap = {
  [ErrorTypeEnum.GENERAL_ERROR]: GeneralError;
  [ErrorTypeEnum.FIELD_VALIDATION_ERROR]: FieldValidationError;
  [ErrorTypeEnum.VALIDATION_ERROR]: ValidationError;
  [ErrorTypeEnum.ASYNC_ERROR]: AsyncError;
  [ErrorTypeEnum.ASYNC_TIMEOUT_ERROR]: AsyncTimeoutError;
  [ErrorTypeEnum.UNAUTHORIZED]: UnauthorizedError;
  [ErrorTypeEnum.SERVER_ERROR]: ServerError;
  [ErrorTypeEnum.INTERNAL_SERVER_ERROR]: ServerError;
  [ErrorTypeEnum.SERVER_PARSE_ERROR]: ServerParseError;
  [ErrorTypeEnum.UNKNOWN_ERROR]: ApolloError;
  [ErrorTypeEnum.ERROR]: Error;
};

type Constructable<T> = new (...args: any[]) => T;

export type GQLConstructableErrorMap = {
  [P in keyof GQLErrorMap]: Constructable<GQLErrorMap[P]>;
};

export type GQLErrorType = GQLErrorMap[keyof GQLErrorMap];
export type GQLErrorInstance = GQLConstructableErrorMap[keyof GQLConstructableErrorMap];
