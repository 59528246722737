import { useRef, useMemo, useCallback, type FocusEventHandler, useEffect } from 'react';
import { type Field, type FieldProps } from './types';
import { useFieldApi as useDDFFieldApi, type UseFieldApiProps } from '@data-driven-forms/react-form-renderer';
import { isObject } from 'lodash';
import type { Optional } from '@oms/ui-util';

export const useFieldApi = <TFieldProps = Field, TFieldValue = any>(
  props: FieldProps<TFieldProps, TFieldValue>
) => {
  const { fieldDef, ...ddfProps } = useDDFFieldApi<TFieldValue>(props) as UseFieldApiProps<
    TFieldValue,
    HTMLElement
  > &
    TFieldProps;

  return useMemo(() => {
    const ensureFieldDef = {
      fieldDef: fieldDef as Optional<TFieldProps>,
      ...(typeof fieldDef === 'object' && fieldDef !== null ? fieldDef : {}),
      ...ddfProps
    } as UseFieldApiProps<TFieldValue, HTMLElement> & TFieldProps;

    // Awful hack to convert bad error object (unknown issue inside DDF) to string
    if (ensureFieldDef.meta.error && isObject(ensureFieldDef.meta.error)) {
      return {
        ...ensureFieldDef,
        meta: { ...ensureFieldDef.meta, error: Object.values(ensureFieldDef.meta.error).join('') }
      } as UseFieldApiProps<TFieldValue, HTMLElement> & TFieldProps;
    }

    return ensureFieldDef;
  }, [fieldDef, ddfProps]);
};

/**
 * Hook to manage focus states:
 * - Select all on focus
 * - Auto focus on mount
 */
export const useFieldFocus = <T extends HTMLElement>(props: any) => {
  const ref = useRef<T>(null);

  const {
    selectAllOnFocus = false,
    autoFocus = false,
    input: { onFocus: _onFocus }
  } = useDDFFieldApi(props);

  // Handle auto focus
  useEffect(() => {
    if (autoFocus) {
      ref?.current?.focus();
    }
  }, [autoFocus]);

  // Handle select all on focus
  const onFocus: FocusEventHandler<T> = useCallback(
    (event) => {
      if (_onFocus) {
        _onFocus(event);
      }

      if (selectAllOnFocus && ref?.current instanceof HTMLInputElement) {
        ref?.current?.select();
      }
    },
    [selectAllOnFocus, _onFocus]
  );

  return [ref, onFocus] as const;
};
