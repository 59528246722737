import { useEffect, useState, createContext, ReactNode } from 'react';
import { darkThemeClassName, lightThemeClassName } from '../../theme/theme.css';
import { darkMode, lightMode } from '../../system/sprinkles.css';

export type ColorMode = 'dark' | 'light';
export const themeKey = 'valstro-theme-pref';

export interface ColorModeContextValues {
  colorMode: ColorMode | null;
  setColorMode: (colorMode: ColorMode) => void;
}

export const ColorModeContext = createContext<ColorModeContextValues>({
  colorMode: null,
  setColorMode: () => {}
});

export function ColorModeProvider({ children }: { children: ReactNode }) {
  const [colorMode, setColorMode] = useState<ColorMode | null>(null);

  useEffect(() => {
    setColorMode(document.documentElement.classList.contains(darkThemeClassName) ? 'dark' : 'light');
  }, []);

  const setter = (c: ColorMode) => {
    setColorMode(c);

    document.documentElement.classList.remove(lightThemeClassName, darkThemeClassName, darkMode, lightMode);
    document.documentElement.classList.add(c === 'dark' ? darkThemeClassName : lightThemeClassName);
    document.documentElement.classList.add(c === 'dark' ? darkMode : lightMode);

    document.body.classList.remove(lightThemeClassName, darkThemeClassName, darkMode, lightMode);
    document.body.classList.add(c === 'dark' ? darkThemeClassName : lightThemeClassName);
    document.body.classList.add(c === 'dark' ? darkMode : lightMode);

    const root = document.documentElement;
    root.style.setProperty('--valstro-color-mode', c);

    try {
      localStorage.setItem(themeKey, c);
    } catch (e) {
      console.error('Failed to save theme to local storage', e);
    }
  };

  return (
    <ColorModeContext.Provider
      value={{
        colorMode,
        setColorMode: setter
      }}
    >
      {children}
    </ColorModeContext.Provider>
  );
}
