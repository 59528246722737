import { useLeaderActor, useWorkspace } from '@valstro/workspace-react';
import { Box, TabContent, TabTrigger, Tabs, TabsList, Button } from '@oms/ui-design-system';
import { useService } from '@oms/frontend-foundation';
import { NotificationSettingsService } from '@app/data-access/services/user/notification-settings.service';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { openConfirmation } from '@app/generated/sdk';
import { NotificationsSettingsGrid } from './notification-settings.grid';
import { t } from '@oms/codegen/translations';

export const NotificationsSettingsWidget = () => {
  const leaderActor = useLeaderActor();
  const workspace = useWorkspace();
  const notificationSettingsService = useService(NotificationSettingsService);

  const onResetToDefaults = async () => {
    try {
      const [_, api] = await openConfirmation(workspace, leaderActor, {
        title: 'Reset settings',
        componentProps: {
          autoClose: true,
          message: `Are you sure you want to reset notification settings to their default values?`,
          confirmButtonText: t('app.common.yes'),
          confirmButtonProps: {
            variant: 'primary',
            palette: 'standard'
          },
          cancelButtonText: t('app.common.no')
        }
      });
      const event = await api.awaitFirstEvent;
      switch (event.type) {
        case DIALOG_EVENT_TYPE.OK: {
          const result = await notificationSettingsService.resetDefaultNotificationSettings();

          if (result.isFailure()) {
            const msgs = result?.errors.map((e) => e.message).join(', ');
            openMessageDialog(`Error: ${msgs}`, workspace).catch(console.error);
            throw new Error(msgs);
          } else {
            notificationSettingsService.triggerFetch();
          }
          break;
        }
      }
    } catch (e) {
      openMessageDialog(`Error: ${String(e)}`, workspace).catch(console.error);
      console.error(e);
    }
  };

  return (
    <Tabs
      defaultValue="tabDefault"
      sx={{
        position: 'absolute',
        inset: 0,
        display: 'flex',
        flexDirection: 'column',
        padding: 5,
        backgroundColor: 'layout.level2'
      }}
    >
      <TabsList variant="secondary" sx={{ display: 'flex', flexDirection: 'row' }}>
        <TabTrigger
          variant="secondary"
          value="tabDefault"
          style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}
        >
          Default
        </TabTrigger>
        <TabTrigger
          variant="secondary"
          value="tabCustom"
          style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}
        >
          Custom
        </TabTrigger>
      </TabsList>

      <TabContent
        value="tabDefault"
        sx={{ height: 'full', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
      >
        <Box sx={{ height: 'auto' }} style={{ minHeight: '380px' }}>
          <NotificationsSettingsGrid type={'default'} />
        </Box>
        <Box sx={{ mt: 'medium' }} style={{ textAlign: 'end' }}>
          <Button
            onClick={() => {
              onResetToDefaults().catch(console.error);
            }}
          >
            Reset to factory defaults
          </Button>
        </Box>
      </TabContent>
      <TabContent
        value="tabCustom"
        sx={{ height: 'full', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
      >
        <Box sx={{ height: 'auto' }} style={{ minHeight: '268px' }}>
          <NotificationsSettingsGrid type={'custom'} />
        </Box>
      </TabContent>
    </Tabs>
  );
};

export default NotificationsSettingsWidget;
