import { FetchResult } from '@apollo/client';
import {
  audit,
  BehaviorSubject,
  combineLatest,
  concatMap,
  filter,
  finalize,
  map,
  of,
  OperatorFunction,
  repeat,
  retry,
  share,
  startWith,
  switchMap,
  tap,
  throwError,
  timeout,
  TimeoutError,
  timer
} from 'rxjs';
import {
  TableServerQueryOptions,
  TableServerQueryResult,
  TableServerRow,
  TableServerSubscriptionShape
} from './table-server.datasource.contracts';
import { Logger } from '@oms/ui-util';
import { applyPatch } from 'fast-json-patch';
import * as Sentry from '@sentry/react';
import {
  createGraphQLErrorFingerprint,
  graphqlContextForSentry
} from '@app/data-access/api/apollo-client.sentry.context';
import { GraphQLError } from 'graphql';

/**
 * Empty result object for table server queries
 */
const EMPTY_RESULT: TableServerQueryResult<any> = {
  rows: [],
  errors: [],
  totalCount: 0,
  connectionState: 'initializing'
};

const logger = Logger.named('Table Server Operator');

const backoff = (count: number, max: number = 30_000) => Math.min(Math.pow(2, count - 1) * 1_000, max);

/**
 * An RxJS operator that encapsulates the logic of returning row updates & errors from a table server subscription.
 * @param opts Options for customizing the query.
 * @returns A table server response.
 */
export const tableServer = <
  TData extends TableServerRow,
  TSubscription extends TableServerSubscriptionShape<TData>
>(
  opts: TableServerQueryOptions<TData, TSubscription>
): OperatorFunction<FetchResult<TSubscription>, TableServerQueryResult<TData>> => {
  const { getData, reconnect, ...otherOpts } = opts;
  const { bufferTimeMs = 100, firstResponseTimeoutMs = 30_000 } = otherOpts;
  const errorState$ = new BehaviorSubject<void>(undefined);
  let hasInitialized = false;
  let lastKnownResponse: TableServerQueryResult<TData> = { ...EMPTY_RESULT };

  return (subscription$) => {
    return Sentry.withScope((scope) => {
      const { queryContext, queryName } = graphqlContextForSentry({
        document: otherOpts.query,
        scope,
        variables: otherOpts.variables,
        setTags: true
      });

      const span = Sentry.startInactiveSpan({
        name: 'table.server.response',
        op: 'graphql',
        scope,
        attributes: queryContext,
        parentSpan: null
      });

      const source$ = subscription$.pipe(
        timeout({ first: firstResponseTimeoutMs }),
        share({
          resetOnError: true,
          resetOnComplete: true,
          resetOnRefCountZero: true
        }),
        repeat({
          delay: (count) => {
            const backoffMs = backoff(count);
            logger.error(
              `❌ Tableserver connection completed unexpectedly for ${queryName}. Reconnecting in ${backoffMs}ms...`,
              {
                ...otherOpts,
                count
              }
            );
            lastKnownResponse.connectionState = 'unstable';
            errorState$.next();
            return timer(backoffMs);
          }
        }),
        concatMap((response) => {
          const hasErrors = response.errors && response.errors.length;
          const data = response.data ? getData(response.data) : undefined;
          const result: TableServerQueryResult<TData> = structuredClone({ ...lastKnownResponse, errors: [] });
          let hasRows = false;

          logger.trace(`Response from server for ${queryName}.`, { response, data, result, hasErrors });

          if (data) {
            const { queryInfo, rows = [], patch } = data;
            const { totalCount } = queryInfo || {
              totalCount: result.totalCount,
              queryCount: otherOpts.variables.limit
            };
            hasRows = rows && rows.length > 0;

            if (hasRows) {
              logger.debug(`Set Table Server rows for ${queryName}.`);
              result.rows = rows;
            }

            if (patch) {
              const parsedPatch = JSON.parse(patch);
              const { newDocument } = applyPatch(result.rows || [], parsedPatch);
              logger.debug(`Patching Table Server rows for ${queryName}.`, {
                parsedPatch
              });
              result.rows = newDocument;
            }

            result.totalCount = totalCount;
          }

          if (hasErrors) {
            result.errors = response.errors;
            logger.debug('Errors from table server response', { result });
          }

          result.connectionState = !hasErrors ? 'stable' : 'unstable';

          lastKnownResponse = result;
          logger.trace('setting last known response', lastKnownResponse);
          return hasErrors ? throwError(() => result.errors) : of(result);
        }),
        audit((response) => {
          const result = timer(hasInitialized ? bufferTimeMs : 0);

          hasInitialized &&
            logger.debug(`Auditing response by ${bufferTimeMs}ms`, {
              ...otherOpts,
              response: { ...response }
            });

          !hasInitialized &&
            logger.debug('Initializing Table Server... skipping audit.', {
              ...otherOpts,
              response: { ...response }
            });

          hasInitialized = true;

          return result;
        }),
        retry({
          delay: (errorOrErrors, errorCount) => {
            const delay = backoff(errorCount);
            lastKnownResponse.connectionState = 'unstable';

            const isAnError = errorOrErrors instanceof Error;
            const isAGraphqlError = errorOrErrors instanceof GraphQLError;
            const isATimeout = errorOrErrors instanceof TimeoutError;
            const isAnArray = Array.isArray(errorOrErrors);

            if (isATimeout) {
              logger.error(`Table Server timed out on first response. Retrying in ${delay}ms.`);
            } else {
              logger.error(`There was an error with Table Server. Retrying in ${delay}ms.`, {
                ...otherOpts,
                errorOrErrors,
                errorCount,
                connectiionState: lastKnownResponse.connectionState
              });
            }

            errorState$.next();

            if (span.isRecording()) {
              span.setStatus({
                code: 2,
                message: 'deadline_exceeded'
              });
              span.end();
            }

            const transactionName = isATimeout
              ? `Table Server timed out for ${queryName}`
              : `Table Server error for ${queryName}`;

            const createErrorScope = (errScope: Sentry.Scope, fingerprint: string[]) => {
              errScope.setFingerprint(fingerprint);
              errScope.setTransactionName(transactionName);

              graphqlContextForSentry({
                document: otherOpts.query,
                scope: errScope,
                variables: otherOpts.variables,
                setTags: true
              });

              return errScope;
            };

            const createGraphqlErrorScope = (
              errScope: Sentry.Scope,
              fingerprint: string[],
              err: GraphQLError
            ) => {
              errScope.setLevel('error');
              createErrorScope(errScope, fingerprint);

              errScope.setContext('apolloGraphQLError', {
                error: err,
                message: err.message,
                extensions: err.extensions
              });

              return errScope;
            };

            if (isAGraphqlError) {
              Sentry.captureMessage(errorOrErrors.message, (msgScope) =>
                createGraphqlErrorScope(
                  msgScope,
                  createGraphQLErrorFingerprint(queryName, errorOrErrors),
                  errorOrErrors
                )
              );
            } else if (isAnError) {
              Sentry.captureException(errorOrErrors, (errScope) =>
                createErrorScope(errScope, [queryName, errorOrErrors.message])
              );
            } else if (isAnArray) {
              errorOrErrors.forEach((err) => {
                const isGraphqlErr = err instanceof GraphQLError;
                const isErr = err instanceof Error;

                if (isGraphqlErr) {
                  Sentry.captureMessage(err.message, (msgScope) =>
                    createGraphqlErrorScope(msgScope, createGraphQLErrorFingerprint(queryName, err), err)
                  );
                } else if (isErr) {
                  Sentry.captureException(err, (errScope) =>
                    createErrorScope(errScope, [queryName, err.message])
                  );
                } else {
                  const parsedErr = typeof err === 'object' ? JSON.stringify(err) : err;
                  Sentry.captureMessage(parsedErr, (errScope) =>
                    createErrorScope(errScope, [queryName, parsedErr])
                  );
                }
              });
            }

            if (!isAnArray && !isAnError) {
              const type = typeof errorOrErrors;
              const parsedErr = type === 'object' ? JSON.stringify(errorOrErrors) : errorOrErrors;
              Sentry.captureMessage(parsedErr, (errScope) =>
                createErrorScope(errScope, [queryName, parsedErr])
              );
            }

            return timer(delay);
          },
          resetOnSuccess: true
        })
      );

      return reconnect.$.pipe(
        filter(() => {
          const isUnstable = lastKnownResponse.connectionState === 'unstable';

          logger.trace('reconnect$', lastKnownResponse.connectionState, hasInitialized);

          isUnstable && logger.info(`♻️ Retrying query ${queryName}.`);

          return isUnstable || !hasInitialized;
        }),
        switchMap(() =>
          combineLatest([source$.pipe(startWith(EMPTY_RESULT)), errorState$]).pipe(
            map(() => {
              const result = { ...lastKnownResponse };

              logger.info('Last known response', { lastKnownResponse });

              return result;
            })
          )
        ),
        filter((e) => e.connectionState !== 'initializing'),
        tap((response) => {
          logger.trace(`Operator response for ${queryName}.`, { response });
          if (span.isRecording() && lastKnownResponse.connectionState === 'stable') {
            span.setStatus({ code: 1, message: 'ok' });
            span.end();
          }
        }),
        finalize(() => {
          lastKnownResponse.connectionState !== 'initializing' &&
            logger.info(`⚠️ Closing connection to ${queryName}.`);
        })
      );
    });
  };
};
