import {
  useFieldApi,
  type UseFieldApiConfig,
  type UseFieldApiProps
} from '@data-driven-forms/react-form-renderer';
import { type ButtonProps, type RequiredFieldIndicatorStyle, ToggleGroupItem } from '@oms/ui-design-system';
import { type ValuedAndLabeled } from '@oms/ui-util';
import { type FC, useMemo } from 'react';

export type ProcessedFieldApiProps<T = any, Label = string> = {
  options: ValuedAndLabeled[];
  buttonProps: ButtonProps;
  meta?: UseFieldApiProps<T>['meta'];
  input?: UseFieldApiProps<T>['input'];
  autoFocus: boolean;
  isDisabled: boolean;
  hidden: boolean;
  label?: Label;
  ariaLabel?: string;
  isInvalid?: boolean;
  helperText?: string;
  isRequired?: boolean;
  isReadOnly?: boolean;
  hideFormControls?: boolean;
  isVisible?: boolean;
  requiredFieldIndicatorStyle?: RequiredFieldIndicatorStyle;
};

export function useToggleFieldApi<T = any, Label = string>(
  fieldApiConfig: UseFieldApiConfig
): ProcessedFieldApiProps<T, Label> {
  const useFieldApiProps = useFieldApi<T>(fieldApiConfig);
  const [buttonProps, rest] = useMemo(() => extractButtonProps(useFieldApiProps), [useFieldApiProps]);
  const {
    meta,
    input,
    isDisabled,
    hidden,
    label,
    ariaLabel,
    autoFocus,
    isInvalid,
    helperText,
    isRequired,
    isReadOnly,
    isVisible,
    requiredFieldIndicatorStyle,
    hideFormControls,
    options = []
  } = rest;
  return {
    options,
    buttonProps,
    meta,
    input,
    autoFocus,
    isDisabled,
    hidden,
    ariaLabel,
    label,
    isInvalid,
    helperText,
    isVisible,
    isRequired,
    isReadOnly,
    requiredFieldIndicatorStyle,
    hideFormControls
  };
}

type UseFieldApiPropsRemainder<T> = UseFieldApiProps<T> & {
  autoFocus: boolean;
  isDisabled: boolean;
  hidden: boolean;
};

function mergeProps(obj1: Record<string, unknown>, obj2: Record<string, unknown>) {
  const result = { ...obj1 };
  for (const prop in obj2) {
    if (obj2[prop] !== undefined) {
      result[prop] = obj2[prop];
    }
  }
  return result;
}

export function extractButtonProps<T = any>(
  useFieldApiProps: UseFieldApiProps<T>
): [ButtonProps, UseFieldApiPropsRemainder<T>] {
  const {
    isVisible,
    isDisabled: _isDisabled,
    forceIsDisabled,
    autoFocus = false,
    buttonContent,
    buttonGroupProps = {},
    buttonSize: size,
    buttonVariant: variant,
    buttonPalette: palette,
    buttonShape: shape,
    buttonElevation: elevation,
    sx,
    style,
    ...rest
  } = useFieldApiProps;
  // Sometimes validators override the disabled props. If forceIsDisabled is true, then we should always disable the field.
  const isDisabled = !!forceIsDisabled || !!_isDisabled;
  const hidden = typeof isVisible === 'boolean' && isVisible === false;
  const otherButtonProps = {
    buttonContent,
    size,
    variant,
    palette,
    shape,
    elevation,
    autoFocus,
    sx,
    style,
    disabled: isDisabled,
    hidden
  } as ButtonProps;
  const buttonProps = mergeProps(buttonGroupProps as ButtonProps, otherButtonProps);

  return [buttonProps, { ...rest, autoFocus, isDisabled, hidden, isVisible }];
}

type ToggleGroupItemFromOptionProps = ValuedAndLabeled & { buttonProps: ButtonProps; isDisabled?: boolean };

export const ToggleGroupItemFromOption: FC<ToggleGroupItemFromOptionProps> = ({
  value,
  label,
  isDisabled,
  buttonProps
}) => (
  <ToggleGroupItem key={value} value={value} isDisabled={isDisabled} buttonProps={buttonProps}>
    {label}
  </ToggleGroupItem>
);

export const createToggleGroupFromFields = (options: ValuedAndLabeled[], buttonProps: ButtonProps) =>
  options
    .map((option) => ({ ...option, buttonProps }))
    .map((props) => <ToggleGroupItemFromOption key={props.value} {...props} />);
