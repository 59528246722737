import React from 'react';
import { Heading as HeadingComponent } from '@oms/ui-design-system';
import type { FieldProps } from '../../types';
import { useFieldApi } from '../../helpers';
import { type IHeadingField } from './heading.type';

export const Heading: React.FC<FieldProps<IHeadingField>> = (props) => {
  const { label, as, headingType, sx = {}, style = {} } = useFieldApi<IHeadingField>(props);

  return (
    <HeadingComponent as={as} type={headingType} sx={sx} style={style}>
      {label}
    </HeadingComponent>
  );
};
