import { Box } from '../../system/components/box/box';
import type { BoxProps } from '../../system/components/box/box';
import { Sprinkles } from '../../system/sprinkles.css';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';

export type ContainerProps = {
  /**
   * Container max width
   */
  maxWidth?: Sprinkles['maxWidth'];
} & BoxProps;

export const Container = polymorphicComponent<'div', ContainerProps>((props, ref) => {
  const { as, children, maxWidth = 'md', sx = {}, ...rest } = props;

  return (
    <Box as={as} sx={{ maxWidth, marginX: 'auto', ...sx }} {...rest} ref={ref}>
      {children}
    </Box>
  );
});

if (__DEV__) {
  Container.displayName = 'Container';
}
