import { Flex, TabTrigger, TabContent, Tabs, TabsList, type TabsListProps } from '@oms/ui-design-system';
import { type StringsOf, type ValuedAndLabeled } from '@oms/ui-util';
import { type ReactNode, useMemo, useCallback } from 'react';
import type { AnyRecord } from '../../../common/type.helpers';
import {
  type SummaryGridField,
  type SummaryGridProps,
  type SummaryGridNumberFormatOptions
} from '../summary-grid/summary-grid';
import SummaryGridStack, { type SummaryGridStackProps } from '../summary-grid/summary-grid-stack';
import { type LabeledSummaryData } from '../summary-grid/summary-grid.types';

export const CLASS_NAMES = {
  tabs: 'form-summary-tabs',
  tabTrigger: 'form-summary-tab-trigger',
  tabContent: 'form-summary-tab-content'
};

export const getIdFor = (value: string) => ({
  tabTrigger: () => `${CLASS_NAMES.tabTrigger}-${value}`,
  tabContent: () => `${CLASS_NAMES.tabContent}-${value}`
});

export type SummaryTab = ValuedAndLabeled & { content?: () => ReactNode };

const mainSummaryTab: SummaryTab = { value: 'summary', label: 'Summary' };

export type FormSummaryProps<FieldValues extends AnyRecord = {}, SummaryHeadings extends string = string> = {
  labeledData: LabeledSummaryData<Partial<StringsOf<FieldValues>>>[];
  additionalTabs?: SummaryTab[];
  headings?: SummaryHeadings[];
  fields?: SummaryGridField<keyof FieldValues>[];
  sx?: SummaryGridProps['sx'];
  numberFormatOptions?: SummaryGridNumberFormatOptions;
  tabsVariant?: TabsListProps['variant'];
};

/**
 * Component making right rail summary tabs for advanced forms.
 */
export function FormSummary<TContract extends AnyRecord = {}, SummaryHeadings extends string = string>({
  labeledData,
  additionalTabs = [],
  headings,
  fields,
  sx,
  numberFormatOptions,
  tabsVariant
}: FormSummaryProps<TContract, SummaryHeadings>) {
  const summarySx: SummaryGridProps['sx'] = useMemo(
    () =>
      sx ?? [
        null,
        {
          heading: {
            color: 'text.primary'
          }
        }
      ],
    [sx]
  );
  const summaryHeadings = useMemo(() => {
    return headings ? headings.map((label) => ({ label })) : undefined;
  }, [headings]);

  const SummaryContent = useCallback(() => {
    return (
      <SummaryGridStack
        labeledData={labeledData}
        fields={fields as SummaryGridStackProps['fields']}
        headings={summaryHeadings}
        sx={summarySx}
        spacing={0}
        numberFormatOptions={numberFormatOptions}
      />
    );
  }, [fields, labeledData, numberFormatOptions, summaryHeadings, summarySx]);

  const tabs = useMemo(() => {
    if (!additionalTabs.length) return null;
    const tabs: SummaryTab[] = [mainSummaryTab, ...additionalTabs];
    const tabList = (
      <TabsList variant={tabsVariant} sx={{ marginBottom: 5 }}>
        {tabs.map(({ value, label }) => (
          <TabTrigger
            key={value}
            id={getIdFor(value).tabTrigger()}
            value={value}
            className={CLASS_NAMES.tabTrigger}
            variant={tabsVariant}
          >
            {label}
          </TabTrigger>
        ))}
      </TabsList>
    );
    const additionalTabContent = additionalTabs.map(({ value, content }) => {
      if (!content) return null;
      return (
        <TabContent
          key={value}
          id={getIdFor(value).tabContent()}
          value={value}
          className={CLASS_NAMES.tabContent}
        >
          {content()}
        </TabContent>
      );
    });
    return (
      <Tabs defaultValue={mainSummaryTab.value} orientation="horizontal" className={CLASS_NAMES.tabs}>
        <Flex justify="center">{tabList}</Flex>
        <TabContent
          key={mainSummaryTab.value}
          id={getIdFor(mainSummaryTab.value).tabContent()}
          value={mainSummaryTab.value}
          className={CLASS_NAMES.tabContent}
        >
          <SummaryContent />
        </TabContent>
        {additionalTabContent}
      </Tabs>
    );
  }, [SummaryContent, additionalTabs, tabsVariant]);

  return tabs ?? <SummaryContent />;
}

export default FormSummary;
