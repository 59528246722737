import i18next, { TOptions } from 'i18next';
import en_US_long from '../locales/en-US/long.json';
import en_US_short from '../locales/en-US/short.json';
import { Paths } from './util.types';

export const OMS_APP_TRANSLATION_DEFAULT = 'en-US';
export const OMS_APP_TRANSLATION_DEFAULT_NS = 'long';
export const OMS_APP_TRANSLATION_SHORT_NS = 'short';

export const omsAppTranslationResources = {
  'en-US': {
    long: en_US_long,
    short: en_US_short
  }
} as const;

export type SupportedLocales = keyof typeof omsAppTranslationResources;
export type SupportedNamespaces = keyof (typeof omsAppTranslationResources)[SupportedLocales];

type TranslationPaths = Paths<typeof en_US_long, 5>;

type OptionOverrides = {
  lng?: SupportedLocales;
  lngs?: readonly SupportedLocales[];
  ns?: SupportedNamespaces | readonly SupportedNamespaces[];
} & Record<string, any>;

export const t = (key: TranslationPaths, options?: TOptions<OptionOverrides>) => i18next.t(key, options);
