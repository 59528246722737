// ===============================================================================
// 📦 Imports 📦
// ===============================================================================
import { useMemo } from 'react';
import type { FC } from 'react';
import clsx from 'clsx';
import { isPromiseLike, UUID } from '@oms/ui-util';
import { Button } from '../../button/button';
import type { IContextMenu, SimpleContextMenuItem } from '../in-window.context-menu.model';
import type { ContextMenuService } from '../in-window.context-menu.service';
import { primaryButtonStyle } from './primary-button-row.css';

// ===============================================================================
// 🎯 Constants 🎯
// ===============================================================================
/**
 * CSS class for active primary buttons in the context menu
 */
export const PRIMARY_BUTTON_ACTIVE_CLASS = 'context-menu-primary-button';

/**
 * CSS class for filler primary buttons in the context menu
 */
export const PRIMARY_BUTTON_FILLER_CLASS = 'context-menu-primary-filler-button';

// ===============================================================================
// 📝 Interfaces 📝
// ===============================================================================
/**
 * Props for the PrimaryButton component
 */
interface PrimaryButtonProps {
  /** The context menu item to render as a button */
  item?: SimpleContextMenuItem;
  /** The context menu state or service */
  state: IContextMenu | ContextMenuService;
  /** Optional index for filler buttons */
  index?: number;
}

// ===============================================================================
// 🎨 Component 🎨
// ===============================================================================
/**
 * Renders a primary action button in the context menu.
 * Can be either an active button with an action or a filler button.
 */
export const PrimaryButton: FC<PrimaryButtonProps> = ({ item, state, index }: PrimaryButtonProps) => {
  const { id, label, onClick } = item || {};
  const buttonId = useMemo(() => id || `empty-${index ? index + 1 : UUID()}`, [id, index]);
  const className = useMemo(() => (item ? PRIMARY_BUTTON_ACTIVE_CLASS : PRIMARY_BUTTON_FILLER_CLASS), [item]);
  const ariaLabel = useMemo(() => (label ? `Button ${label}` : 'Empty'), [label]);
  return (
    <Button
      id={buttonId}
      className={clsx(className, primaryButtonStyle)}
      type="button"
      palette="standard"
      variant="secondary"
      size="sm"
      aria-label={ariaLabel}
      isDisabled={!(typeof onClick === 'function')}
      onClick={() => {
        if (onClick) {
          const result = onClick();
          if (isPromiseLike(result)) {
            result.catch(console.error);
          }
        }
        state.setOpen(false);
      }}
      sx={{ display: 'flex', flex: 1, flexGrow: 1 }}
    >
      {label || ''}
    </Button>
  );
};
