import { forwardRef } from 'react';
import { AgGridReact, AgGridReactProps } from '@ag-grid-community/react';
import { ContainerContext } from '../hooks/grid.container.hook';
import { DependencyContainer } from 'tsyringe';
import { VGridDisplay } from './vgrid-display.component';
import { GridBuilder } from '../builders/grid.builder';
import { ColumnApi, GridApi } from '@ag-grid-community/core';
import { AnyRecord, useService } from '@oms/frontend-foundation';
import { InWindowContextMenuProvider } from '@oms/ui-design-system';
import { FilterModel } from '../models/filter.model';
import { GridMenuOptions } from '../models/grid-menu-options.model';
import { OnToolbarHotkeyEvent } from '../models/toolbar.model';
import { CustomContextMenuService } from '../services/custom-context-menu.service';

/**
 * Common VGrid-specific event types
 */
type VGridEventBase<TData extends AnyRecord> = {
  gridApi: GridApi<TData>;
  columnApi: ColumnApi;
  container: DependencyContainer;
  gridId: string;
  widgetId: string;
  gridType: string;
  viewId: string;
};

/**
 * Grid ready (but not populated) event.
 * Alternative to ag-grid's GridReadyEvent
 */
export interface VGridReadyEvent<TData extends AnyRecord> extends VGridEventBase<TData> {}

/**
 * Grid is ready and first data was rendered.
 * Alternative to ag-grid's FirstDataRenderedEvent
 */
export interface VGridFirstDataRenderedEvent<TData extends AnyRecord> extends VGridEventBase<TData> {
  firstRow?: number;
  lastRow?: number;
}

export interface VGridProps<TData extends AnyRecord> extends Omit<AgGridReactProps<TData>, 'gridId'> {
  gridType: string;
  builderCb?: (builder: GridBuilder<TData>) => GridBuilder<TData>; // Used to be passed down to multi-grids
  title?: string;
  gridId?: string;
  gridName?: string;
  container?: DependencyContainer;
  viewId?: string;
  syncOfflineState?: boolean;
  filters?: FilterModel;
  serverSideFilters?: FilterModel;
  gridMenuOptions?: GridMenuOptions<TData>;
  hasCustomContextMenu?: boolean;
  onToolbarHotkey?: (e: OnToolbarHotkeyEvent<TData>) => void | Promise<void>;
  /**
   * Event handler when the grid is ready.
   * This fires before data is loaded into the grid.
   */
  onVGridReady?: (e: VGridReadyEvent<TData>) => void | Promise<void>;
  /**
   * Event handler when the grid is first populated.
   */
  onVGridFirstDataRendered?: (e: VGridFirstDataRenderedEvent<TData>) => void | Promise<void>;
  /**
   * Provide a custom event name to load data on.
   * This is useful for delaying data loading until a specific event is fired from the app level
   */
  loadDataOnEvent?: string;
}

export const defaultGridProps: VGridProps<AnyRecord> = {
  gridType: 'default'
};

const VGridWithRef = <TData extends AnyRecord>(
  props: VGridProps<TData>,
  ref: React.LegacyRef<AgGridReact<TData>>
) => {
  const contextMenu = useService(CustomContextMenuService, props.container);
  return (
    <ContainerContext.Provider value={props.container as DependencyContainer}>
      <InWindowContextMenuProvider contextMenu={contextMenu}>
        <VGridDisplay<TData> {...props} ref={ref} />
      </InWindowContextMenuProvider>
    </ContainerContext.Provider>
  );
};

// TODO: Breaks ESLint Server
export const VGrid = forwardRef(VGridWithRef) as <TData extends AnyRecord>(
  props: VGridProps<TData> & { ref?: React.LegacyRef<AgGridReact<TData>> }
) => React.ReactElement;
