import { useOpenCancelInvestorOrderDialog } from '@app/common/types/orders/investor-order/open.cancel.investor-order';
import { useOpenModifyInvestorOrderDiablog } from '@app/common/types/orders/investor-order/open.modify.investor-order';
import { useOpenViewTradingOrder } from '@app/common/types/orders/trading-order/open.view.trading-order';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { TradingOrdersService } from '@app/data-access/services/trading/trading-orders/trading-orders.service';
import { t } from '@oms/codegen/translations';
import { useWorkspaceContainer } from '@oms/frontend-foundation';
import { InvestorOrderStatus, ValstroEntitlements } from '@oms/generated/frontend';
import type { VisibleTradingOrderInfoFragment as TOFragment } from '@oms/generated/frontend';
import { Button, ButtonGroup } from '@oms/ui-design-system';
import { useEffect, useState } from 'react';
import type { FC } from 'react';

interface IInvestorOrderViewUtilityButtonGroupProps {
  orderId: string;
  orderStatus?: string;
  linkedTradingOrderId?: string | null;
}

export const InvestorOrderViewUtilityButtonGroup: FC<IInvestorOrderViewUtilityButtonGroupProps> = ({
  orderId,
  orderStatus,
  linkedTradingOrderId
}) => {
  const container = useWorkspaceContainer();
  const [linkedTradingOrderData, setLinkedTradingOrderData] = useState<TOFragment | undefined | null>(null);
  const tradingOrderService = container.resolve(TradingOrdersService);

  const onCancel = useOpenCancelInvestorOrderDialog(orderId);
  const onModify = useOpenModifyInvestorOrderDiablog('update', orderId);
  const onOpenLinkedTO = useOpenViewTradingOrder({
    id: linkedTradingOrderData?.id,
    side: linkedTradingOrderData?.sideType,
    orderType: linkedTradingOrderData?.orderType,
    price: linkedTradingOrderData?.limitPrice,
    quantity: linkedTradingOrderData?.quantity,
    symbol: linkedTradingOrderData?.instrument?.mappings.displayCode
  });

  useEffect(() => {
    if (linkedTradingOrderId) {
      tradingOrderService
        .getById(linkedTradingOrderId)
        .then((result) => {
          if (result.isSuccess()) {
            setLinkedTradingOrderData(result.value.data.visibleTradingOrder);
          }
          if (result.isFailure()) {
            console.error(result.errors);
          }
        })
        .catch((e) => console.error(e));
    }
  }, [linkedTradingOrderId]);

  const authService = container.resolve(AuthService);
  const hasEntitlement = authService.hasEntitlement([ValstroEntitlements.OrderManage]);

  const isEnabled = hasEntitlement && orderStatus === InvestorOrderStatus.Active;

  return (
    <ButtonGroup sx={{ display: 'block' }} spacing={0}>
      <Button isDisabled={!isEnabled} onClick={onModify}>
        {t('app.common.modify')}
      </Button>
      <Button isDisabled={!isEnabled} onClick={onCancel}>
        {t('app.common.cancel')}
      </Button>

      {linkedTradingOrderId && (
        <Button onClick={onOpenLinkedTO} isDisabled={!linkedTradingOrderData}>
          {t('app.orders.investorOrderView.viewLinkedTradingOrder')}
        </Button>
      )}
    </ButtonGroup>
  );
};
