import {
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE,
  ROW_SELECTION_COUNT_ACTION_TYPE,
  TOGGLE_FILTERS_AND_SIDEBAR_ACTION_TYPE,
  useVGrid,
  VGrid
} from '@oms/frontend-vgrid';
import type { GridBuilder } from '@oms/frontend-vgrid';
import { buildInvestorOrderViewTradingOrdersColumnDefs } from './trading-order-view.investor-orders.columns';
import { GridConfigEventHandler } from '@app/data-access/services/system/grids/grid-config.event-handler';
import { openIoEntryInvestorOrderAction } from '../investor-order-monitor/grid-actions/open-io-entry.investor-order.action';
import { IOHasExecutedQuantity, IONoExecutedQuantity, IOPending } from '../utils/row-state-rule-utils';
import { TsMappedInvestorOrdersWithFilterDocument } from '@oms/generated/frontend';
import type {
  MappedInvestorOrderRow,
  TsMappedInvestorOrdersWithFilterSubscription
} from '@oms/generated/frontend';
import { createViewInvestorOrderAction } from '../investor-order-view/commands/view-investor-order/view-investor-order.action';
import { createCancelInvestorOrderAction } from '../investor-order-monitor/commands/cancel-investor-order/cancel-investor-order.action';
import { withTableServer } from '@app/data-access/services/system/table-server/with.table-server.component';

type MappedInvestorOrdersSubscriptionKeys = 'mapped_investor_orders';

type MappedInvestorOrdersSubscriptionMap = {
  mapped_investor_orders: {
    datasource: MappedInvestorOrderRow;
    subscription: TsMappedInvestorOrdersWithFilterSubscription;
  };
};

type MappedInvestorOrderProps = {
  id: string;
};

export const TradingOrderInvestorOrdersGrid = withTableServer<
  MappedInvestorOrdersSubscriptionKeys,
  MappedInvestorOrdersSubscriptionMap,
  MappedInvestorOrderProps
>(
  ({ datasources, props: compProps }) => {
    const { id } = compProps;
    const props = useVGrid(
      'trading-order-investor-orders',
      (b: GridBuilder<MappedInvestorOrderRow>) =>
        b
          .tableServerColumnLibrary(buildInvestorOrderViewTradingOrdersColumnDefs())
          .datasource((d) =>
            d
              .source(
                datasources.mapped_investor_orders.asServerSideRowModel({
                  filter: {
                    tradingOrderId: {
                      filterType: 'text',
                      type: 'equals',
                      filter: id
                    }
                  }
                })
              )
              .rowId((r) => r.data.id)
              .cacheBlockSize(100)
          )
          .injectEvents([GridConfigEventHandler])
          .rowStateRules({
            pending: (params) => IOPending(params.data),
            noExecutedQuantity: (params) => IONoExecutedQuantity(params.data),
            hasExecutedQuantity: (params) => IOHasExecutedQuantity(params.data)
          })
          .sideBar()
          .rowSelection((c) => c.multiple())
          .actions((a) =>
            a.schema((s) =>
              s
                .action(TOGGLE_FILTERS_AND_SIDEBAR_ACTION_TYPE)
                .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(createViewInvestorOrderAction('context-menu'))
                .action(openIoEntryInvestorOrderAction('update'))
                .action(createCancelInvestorOrderAction('context-menu'))
                .action(ROW_SELECTION_COUNT_ACTION_TYPE)
            )
          )
          .reactiveCustomComponents(),
      [datasources, id]
    );
    return <VGrid {...props} />;
  },
  {
    mapped_investor_orders: {
      query: TsMappedInvestorOrdersWithFilterDocument,
      getData: (r) => r.tsMappedInvestorOrdersWithFilter
    }
  }
);

export default TradingOrderInvestorOrdersGrid;
