import { vars } from '@oms/ui-design-system-css';

export const FilterOffIcon = ({ width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      arial-label="Filters off"
    >
      <g id="filter_off">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 3C1 2.72386 1.22386 2.5 1.5 2.5L14.5 2.5C14.7761 2.5 15 2.72386 15 3C15 3.27614 14.7761 3.5 14.5 3.5L1.5 3.5C1.22386 3.5 1 3.27614 1 3ZM3 8C3 7.72386 3.22386 7.5 3.5 7.5H12.5C12.7761 7.5 13 7.72386 13 8C13 8.27614 12.7761 8.5 12.5 8.5H3.5C3.22386 8.5 3 8.27614 3 8ZM6.5 12.5C6.22386 12.5 6 12.7239 6 13C6 13.2761 6.22386 13.5 6.5 13.5H9.5C9.77614 13.5 10 13.2761 10 13C10 12.7239 9.77614 12.5 9.5 12.5H6.5Z"
          fill={vars.colors.base.BlueGrey[500]}
        />
      </g>
    </svg>
  );
};
