import { InfoCircledIcon } from '@radix-ui/react-icons';
import { Popover, PopoverContent, PopoverTrigger } from '@oms/ui-design-system';
import { useCurrentActor, useCurrentActorChildren } from '@valstro/workspace-react';

/**
 * Custom pop-in button for window actions
 */
export const ActorInfo: React.FC = () => {
  const currentActor = useCurrentActor();
  const children = useCurrentActorChildren({
    includeWindowRoots: true
  });

  return (
    <Popover>
      <PopoverTrigger asChild>
        <button aria-label="Actor Info" className="window__toolbar__action">
          <InfoCircledIcon />
        </button>
      </PopoverTrigger>
      <PopoverContent style={{ minWidth: '250px' }}>
        <table>
          <th>
            <tr>
              <th>Attr</th>
              <th>Value</th>
            </tr>
          </th>
          <tbody>
            <tr>
              <td className="font-medium">ID</td>
              <td>{currentActor.id}</td>
            </tr>
            <tr>
              <td className="font-medium">Type</td>
              <td>{currentActor.type}</td>
            </tr>
            <tr>
              <td className="font-medium">Name</td>
              <td>{currentActor.name}</td>
            </tr>
            <tr>
              <td className="font-medium">Children</td>
              <td className="text-xs">
                {children.map((c) => `${c.id}${c.type.includes('window') ? ` (rootable)` : ``}`).join(', ')}
              </td>
            </tr>
          </tbody>
        </table>
      </PopoverContent>
    </Popover>
  );
};
