import { useEffect, useState } from 'react';
import type { Optional } from '@oms/ui-util';
import type { MontageInstrumentFragment } from '@oms/generated/frontend';
import { useService } from '@oms/frontend-foundation';
import { MontageService } from '../montage.service';
import { useScopedActorId } from '@app/data-access/offline/hooks/use-scoped-actor-id.hook';

export const useMontageInstrumentDetails = (): Optional<MontageInstrumentFragment> => {
  const scopedActorId = useScopedActorId();
  const montageService = useService(MontageService);

  const [instrument, setInstrument] = useState<Optional<MontageInstrumentFragment>>(undefined);

  useEffect(() => {
    const subscription = montageService.watchInstrumentDetails$(scopedActorId).subscribe((ins) => {
      setInstrument(ins);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [scopedActorId, montageService]);

  return instrument;
};
