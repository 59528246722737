import type { FC } from 'react';
import { useMontageState } from '@app/data-access/services/trading/montage/hooks/use-montage-state.hook';
import { MatchedInvestorOrdersCheckboxGroup } from '@app/containers/matched-investor-orders/matched-investor-orders-checkbox-group';
import { OrderSide } from '@oms/generated/frontend';
import type { InvestorOrderRow } from '@oms/generated/frontend';
import { Flex } from '@oms/ui-design-system';
import { useMontageLayout } from './use-montage-layout.hooks';

const Divider = () => <Flex style={{ width: '3rem' }} />;

const MappableOrderCheckboxGroup = ({
  side,
  handleChange,
  instrumentId,
  maxOrdersToDisplay
}: {
  side: OrderSide;
  handleChange?: (orders: InvestorOrderRow[]) => void;
  instrumentId: string;
  maxOrdersToDisplay?: number;
}) => {
  return (
    <Flex sx={{ width: 'half' }}>
      <MatchedInvestorOrdersCheckboxGroup
        instrumentId={instrumentId}
        side={side}
        placement="montage"
        handleSelectOrders={handleChange}
        maxOrdersToDisplay={maxOrdersToDisplay}
      />
    </Flex>
  );
};

export const MappableOrders: FC = () => {
  const { instrumentId } = useMontageState();

  const { layout } = useMontageLayout();

  const isVisible = instrumentId && (layout === 'expanded' || layout === 'standard');

  if (!isVisible) {
    return null;
  }

  return (
    <Flex
      sx={{
        backgroundColor: 'layout.level2',
        borderRadius: 'md',
        p: 'small',
        justifyContent: 'space-between',
        gap: 'small',
        alignItems: 'center'
      }}
    >
      <MappableOrderCheckboxGroup side={OrderSide.Buy} instrumentId={instrumentId} maxOrdersToDisplay={3} />
      <Divider />
      <MappableOrderCheckboxGroup side={OrderSide.Sell} instrumentId={instrumentId} maxOrdersToDisplay={3} />
    </Flex>
  );
};
