import React, { Children, useState, useMemo, useCallback, forwardRef } from 'react';
import clsx from 'clsx';

import * as styles from './css/input.css';
import type { InputVariants } from './css/input.css';
import { inputOffsetOutline } from '../../system/styles.css';
import { Input, InputProps } from './input';
import { Box } from '../../system/components/box/box';
import { __DEV__ } from '../../system/utils/assertion';
import { dataAttr } from '../../system/utils/dom';
import { FormControlProps, useFormControl } from '../form-control/form-control';

type OmittedProps = 'isRequired';

type InternalInputGroupProps = {
  type?: string;
  'aria-label'?: string;
  'aria-describedby'?: string;
  customRenderer?: (props: InputGroupProps) => React.ReactNode;
} & React.InputHTMLAttributes<HTMLInputElement> &
  InputVariants &
  Omit<FormControlProps, OmittedProps>;

export type InputGroupProps = InternalInputGroupProps &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof InternalInputGroupProps> & {
    input?: (props: InputProps) => React.ReactElement;
    tagGroupStyle?: React.CSSProperties;
  };

export const InputGroup = forwardRef<HTMLInputElement, InputGroupProps>((props, ref) => {
  const [hasFocus, setHasFocus] = useState(false);
  const { isReadOnly, isDisabled, isInvalid, isRequired, ...formControl } = useFormControl(props);

  const {
    size = formControl.size || 'sm',
    variant = formControl.variant || 'default',
    'aria-label': ariaLabel,
    'aria-describedby': ariaDescribedby,
    className,
    type = 'text',
    id,
    onFocus,
    onBlur,
    children,
    input,
    style = {},
    sx = {},
    tagGroupStyle = {},
    customRenderer,
    ...rest
  } = props;

  const handleFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (onFocus) onFocus(e);
      setHasFocus(true);
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (onBlur) onBlur(e);

      setHasFocus(false);
    },
    [onBlur]
  );

  const items = Children.toArray(children) as React.ReactElement[];
  const shouldDisable = isDisabled || props.isDisabled;
  const inputProps: InputProps = useMemo(
    () => ({
      ref,
      readOnly: isReadOnly,
      'aria-readonly': dataAttr(isReadOnly),
      disabled: shouldDisable,
      'aria-disabled': dataAttr(shouldDisable),
      'aria-label': ariaLabel,
      'aria-invalid': dataAttr(isInvalid),
      required: isRequired,
      'aria-required': dataAttr(isRequired),
      'aria-describedby': ariaDescribedby,
      type,
      className: clsx(styles.innerInput, styles.inputGroupChild),
      id: id || formControl.id,
      onFocus: handleFocus,
      onBlur: handleBlur,
      style,
      ...rest
    }),
    [
      ref,
      isReadOnly,
      isInvalid,
      isRequired,
      ariaLabel,
      ariaDescribedby,
      type,
      id,
      formControl.id,
      handleFocus,
      handleBlur,
      style,
      shouldDisable,
      rest
    ]
  );

  return (
    <Box
      data-disabled={dataAttr(shouldDisable)}
      aria-invalid={dataAttr(isInvalid)}
      className={clsx(
        styles.inputRecipe({ size, variant }),
        className,
        inputOffsetOutline,
        hasFocus ? 'focus' : '',
        styles.inputGroup
      )}
      style={style}
      sx={sx}
    >
      {items.map((item, idx) => (
        <Box key={idx} className={styles.inputGroupChild} style={tagGroupStyle}>
          {item}
        </Box>
      ))}
      {input && input(inputProps)}
      {!input && <Input {...inputProps} />}
      {customRenderer && customRenderer(props)}
    </Box>
  );
});

if (__DEV__) {
  InputGroup.displayName = 'InputGroup';
}
