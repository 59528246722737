import { Plugin } from '@valstro/workspace';
import type { AppWorkspace } from '@app/app-config/workspace.config';
import { createLogger } from '@oms/ui-util';
import { ProcessState } from '@app/data-access/memory/process-id.subject';
import type { DependencyContainer } from 'tsyringe';

export const processIdPluginLogger = createLogger({ name: 'Process ID Plugin' });

export interface ProcessIdPluginOptions {
  container: DependencyContainer;
}

/**
 * Sets "window.processId" to the current process ID.
 * which allows us to access the process ID from anywhere in the app (without needing to hook into the workspace bootstrapping process).
 * This is particularly useful for signals.
 */
export const processIdPlugin = ({ container }: ProcessIdPluginOptions) =>
  Plugin.create<AppWorkspace>({
    name: 'valstro-process-id-plugin',
    pluginFn: ({ workspace }) => {
      const processService = container.resolve(ProcessState);
      workspace.addHook('leaderElection', ({ isLeader }) => {
        processIdPluginLogger.debug('Setting window.isLeaderProcess', { isLeader });
        if (isLeader) {
          processService.setLeader();
        }
      });

      workspace.addHook('windowReady', ({ processId, isLeader }) => {
        if (isLeader) {
          return; // Already set
        }

        processIdPluginLogger.debug('Setting window.processId', { processId });
        processService.setProcessId(processId);
      });

      return () => {
        processIdPluginLogger.debug('Cleaning up window.processId and window.isLeaderProcess');
        processService.setProcessId(undefined);
      };
    }
  });
