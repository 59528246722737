import type { Optional } from '@oms/ui-util';
import { isExtendedGroupObject, isExtendedUserObject } from '@app/common/types/users/types';
import { map } from 'rxjs';
import type { Observable } from 'rxjs';
import { compactMap, cleanMaybe } from '@oms/ui-util';
import { getAdvancedSelectQueryReturn } from '@oms/frontend-foundation';
import type { IAdvancedSelectField, DataSourceCommon, AdvancedSelectQueryFn } from '@oms/frontend-foundation';
import type { ComboBoxItem } from '@oms/ui-design-system';
import { isCoveragePerson } from '@app/common/types/users/types';
import type {
  ExtendedCoveragePersonObject,
  BaseUserObject,
  ExtendedUserObject
} from '@app/common/types/users/types';
import User from '@app/common/types/users/user.class';
import UsersService from '@app/data-access/services/reference-data/users/users.service';

export type AdvancedSelectUserFieldValue = ComboBoxItem<string>;
export type AdvancedSelectUserField = IAdvancedSelectField<string>;

export type UsersComboBoxData = BaseUserObject | ExtendedUserObject;
export type UsersComboBoxValue = string;

type UsersQueryType =
  | 'active'
  | 'all'
  | 'primary-coverage'
  | 'routable-users'
  | 'non-primary-coverage'
  | 'users-from-teams';

type QueryFunction = () => Observable<DataSourceCommon<UsersComboBoxData>>;

export const makeUsersResultMapper =
  (type: UsersQueryType) =>
  (user: UsersComboBoxData): Optional<ComboBoxItem<UsersComboBoxValue>> => {
    let extendedCoveragePerson: ExtendedCoveragePersonObject | undefined;
    let extendedUser: ExtendedUserObject | undefined;
    let avatar: string | undefined;
    let email: string | undefined;
    if (isCoveragePerson(user)) {
      if (user?.group?.id) {
        extendedCoveragePerson = isExtendedGroupObject(user.group)
          ? user.group
          : User.extendGroup(user.group);
      } else {
        const u = cleanMaybe(user?.user);
        extendedUser = isExtendedUserObject(u) ? u : User.extend(u);
      }
    } else {
      extendedUser = User.extend(user);
    }
    if (extendedUser) {
      avatar = extendedUser.avatar;
      email = extendedUser.email;
      extendedCoveragePerson = extendedUser;
    }
    const { id, enabled, label } = extendedCoveragePerson || {};
    const labelVal: string = 'label' in user && user.label ? user.label || '' : label || '';
    if (!enabled && type === 'active' && !user?.group?.id) {
      return;
    }
    return {
      type: 'item',
      id: id || '',
      showAvatar: true,
      avatarSrc: avatar,
      label: labelVal ?? User.buildNameFrom(user),
      sublabel: email,
      value: id || ''
    };
  };

export const allUsersResultMapper = makeUsersResultMapper('all');
export const activeUsersResultMapper = makeUsersResultMapper('active');
export const routableUsersResultMapper = makeUsersResultMapper('routable-users');

const getResultMapper = (type: UsersQueryType) => {
  switch (type) {
    case 'all':
      return allUsersResultMapper;
    case 'active':
    case 'non-primary-coverage':
    case 'primary-coverage':
    case 'users-from-teams':
      return activeUsersResultMapper;
    case 'routable-users':
      return routableUsersResultMapper;
  }
};

/**
 * Selects a query function use based on the specific type of users query
 * @param type query type
 * @param usersService access to the users service
 * @returns query function to use
 */
const selectQueryFn = (type: UsersQueryType, usersService: UsersService): QueryFunction => {
  switch (type) {
    case 'primary-coverage':
      return usersService.watchForPrimaryCoverage$.bind(usersService) as QueryFunction;
    case 'non-primary-coverage':
      return usersService.watchForNonPrimaryCoverage$.bind(usersService) as QueryFunction;
    case 'routable-users':
      return usersService.watchForRoutableUsers$.bind(usersService) as QueryFunction;
    case 'users-from-teams':
      return usersService.watchUsersFromTeams$.bind(usersService) as QueryFunction;
    default:
      return usersService.watchAll$.bind(usersService) as QueryFunction;
  }
};

const makeWatchAllUsersQuery =
  (type: UsersQueryType): AdvancedSelectQueryFn<UsersComboBoxValue> =>
  (container) => {
    const usersService = container.resolve(UsersService);

    return getAdvancedSelectQueryReturn<UsersComboBoxData, UsersComboBoxValue>({
      queryFn: selectQueryFn(type, usersService),
      resultMapper: getResultMapper(type)
    });
  };

export const watchAllPrimaryCoverageUsersFromTeamsQuery: AdvancedSelectQueryFn<UsersComboBoxValue> = (
  container
) => {
  const usersService = container.resolve(UsersService);

  return {
    type: 'watchAll',
    query: ({ form }) => {
      const primaryCoveragePersons = form.getState().values?.['primaryCoverageUsers'];
      const nonTeamUsers = primaryCoveragePersons.filter((pcp: any) => pcp?.value?.__typename !== 'Group');
      const groupIds: string[] =
        primaryCoveragePersons
          .filter((pcp: any) => pcp?.value?.__typename === 'Group')
          .map((pcp: any) => pcp.id) ?? [];

      return usersService.watchUsersFromTeams$(groupIds).pipe(
        map(({ results: _results, isFetching, error }) => {
          const results = _results
            ? compactMap(_results, (result) => {
                return getResultMapper('users-from-teams')(result);
              })
            : [];

          const filteredDuplicates = new Map<string, ComboBoxItem<UsersComboBoxValue>>();

          [...nonTeamUsers, ...results].forEach((r) => filteredDuplicates.set(r.id, r));

          return {
            isFetching,
            error,
            results: Array.from(filteredDuplicates.values())
          };
        })
      );
    }
  };
};

export const watchAllPrimaryCoverageUsersQuery = makeWatchAllUsersQuery('primary-coverage');
export const watchAllNonPrimaryCoverageUsersQuery = makeWatchAllUsersQuery('non-primary-coverage');

export const watchAllUsersQuery = makeWatchAllUsersQuery('all');
export const watchActiveUsersQuery = makeWatchAllUsersQuery('active');

//export const watchAllPrimaryCoverageUsersFromTeamsQuery: AdvancedSelectQueryFn<ExtendedUserObject> = makeWatchAllUsersQuery('users-from-teams');
export default watchActiveUsersQuery;
