import { type StackProps, VStack } from '@oms/ui-design-system';
import { useMemo } from 'react';
import { v4 as UUID } from 'uuid';
import { SummaryGrid, type SummaryGridProps } from './summary-grid';
import type { LabeledSummaryData } from './summary-grid.types';
import type { AnyKey } from '@oms/ui-util';

export type SummaryGridStackProps<
  FieldData extends Record<AnyKey, unknown> = Record<AnyKey, unknown>,
  FieldName extends AnyKey = string,
  HeadingLabel extends string = string
> = Omit<SummaryGridProps<FieldData, FieldName, HeadingLabel>, 'data' | 'title'> & {
  labeledData?: LabeledSummaryData<FieldData, FieldName>[];
  spacing?: StackProps['spacing'];
};

export function SummaryGridStack<
  FieldData extends Record<AnyKey, unknown> = Record<AnyKey, unknown>,
  FieldName extends AnyKey = string,
  HeadingLabel extends string = string
>({
  labeledData,
  spacing,
  fields: commonFields,
  ...rest
}: SummaryGridStackProps<FieldData, FieldName, HeadingLabel>) {
  const grids = useMemo(
    () =>
      labeledData?.map(({ value, label, fields }) => (
        <SummaryGrid
          key={label ?? UUID()}
          data={value}
          title={label}
          fields={fields || commonFields}
          {...rest}
        />
      )),
    [rest, labeledData, commonFields]
  );
  return <VStack spacing={typeof spacing !== 'undefined' ? spacing : 3}>{grids}</VStack>;
}

export default SummaryGridStack;
