import { withUpdatedTrade } from '@app/data-access/hoc/with-updated-trade';
import type { UpdatedTrade } from '@app/data-access/hoc/with-updated-trade';
import { Box } from '@oms/ui-design-system';
import { tabContentInner } from '@app/widgets/common/styles/trading.styles.css';
import { paneStyle as sx } from '@app/widgets/common/styles/trading.styles';
import { TradeDetailsSummary } from './trade-view.trade-details.widget';

export const TradeViewSidePanelWidget = ({ data: trade }: UpdatedTrade) => {
  return (
    <Box className={tabContentInner} sx={{ ...sx, padding: 5 }}>
      <TradeDetailsSummary trade={trade} />
    </Box>
  );
};

export default withUpdatedTrade(TradeViewSidePanelWidget, { sx });
