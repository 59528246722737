import { BehaviorSubject, Observable } from 'rxjs';
import {
  BroadcastBehaviorSubject,
  type BroadcastBehaviorSubjectOptions
} from '../subjects/broadcast-behavior-subject';

export type SignalSetter<T> = (value: T) => T;

export type SignalUnsubscribeFn = () => void;

export type CreateSignalOpts = BroadcastBehaviorSubjectOptions & { broadcast?: boolean };

export interface Signal<T> {
  get: () => T;
  set: (value: T | SignalSetter<T>) => void;
  subscribe: (next: (value: T) => void) => SignalUnsubscribeFn;
  $: Observable<T>;
  initialized$: Observable<boolean>;
}

function isSignalSetter<T>(value: T | SignalSetter<T>): value is SignalSetter<T> {
  return typeof value === 'function';
}

export function createSignal<T>(
  channelName: string,
  initialValue: T,
  _options?: CreateSignalOpts
): Signal<T> {
  const { broadcast = false, ...options } = _options || {};

  // If broadcast is false, we do NOT need to initialize the signal with remote state -> therefore, set the initialized$ to true (because it's initialized with the initial value the moment it's created)
  // If broadcast is true, then we want our subject to broadcast its state to other windows
  // If options.initialize$ is provided, then we need to wait for it to initialize remotely before we can initialize our signal
  // Therefore, if options.initialize$ is truthy, we set initialized$ to false first, until the remote subject emits a value
  // Otherwise, we set initialized$ to true immediately
  const initialized$ = new BehaviorSubject<boolean>(
    broadcast === false ? true : !!options.initialize$ ? false : true
  );

  const behaviorSubject$ = broadcast
    ? new BroadcastBehaviorSubject<T>(channelName, initialValue, {
        ...options,
        initialized$
      })
    : new BehaviorSubject<T>(initialValue);

  return {
    get: () => behaviorSubject$.getValue(),
    initialized$: initialized$.asObservable(),
    set: (value: T | SignalSetter<T>) => {
      if (isSignalSetter(value)) {
        const nextValue = value(behaviorSubject$.getValue());
        behaviorSubject$.next(nextValue);
      } else {
        behaviorSubject$.next(value);
      }
    },
    subscribe: (next: (value: T) => void) => {
      const sub = behaviorSubject$.subscribe(next);
      return () => {
        sub.unsubscribe();
      };
    },
    $: behaviorSubject$.asObservable()
  };
}

export function createBroadcastSignal<T>(
  channelName: string,
  initialValue: T,
  options?: BroadcastBehaviorSubjectOptions
): Signal<T> {
  return createSignal(channelName, initialValue, {
    ...options,
    broadcast: true
  });
}
