import type { AnyRecord } from '@oms/frontend-foundation';
import { ActionsService, GridIdService, ToolbarService } from '@oms/frontend-vgrid';
import type {
  EventHandler,
  EventSource,
  GridEvent,
  GridBuilder,
  ToolbarLocationKeys,
  ActionDef
} from '@oms/frontend-vgrid';
import { DependencyContainer, Lifecycle, inject, scoped } from 'tsyringe';
import { openActionButtonLayout } from '@app/generated/sdk';
import { combineLatest } from 'rxjs';
import type { Subscription } from 'rxjs';
import type { LayoutAction } from '../forms/types';
import type { ButtonProps } from '@oms/ui-design-system';
import { ActionConfigService } from '../services/action.config.service';
import { renderActions } from './render.actions';
import { GridConfigEventHandler } from '@app/data-access/services/system/grids/grid-config.event-handler';
import { getCompositeId } from '@app/data-access/offline/collections/grids.collection';
import type { ButtonConfigSubject } from '../events/button.config.subject';
import type { ActionButtonLayoutFormInput } from '../forms/action-button-layout/action-button-layout.contracts';
import type { GridReadyEvent, GridApi } from '@ag-grid-community/core';
import { AppWorkspace } from '@app/app-config/workspace.config';

type SetupConfigGridActionsMeta = Pick<LayoutAction, 'widgetTypeId'> & {
  props?: ButtonProps;
  actionContext$?: ButtonConfigSubject;
} & Pick<ActionButtonLayoutFormInput, 'allowedCommands'>;

export type SetupGridActions<TGrid extends AnyRecord> = {
  grid: GridBuilder<TGrid>;
  meta: SetupConfigGridActionsMeta;
};

@scoped(Lifecycle.ContainerScoped)
export class ConfigurableGridActionsService<TData extends AnyRecord> implements EventHandler {
  public name = 'config-grid-actions';

  private actionLayoutForm?: Awaited<ReturnType<typeof openActionButtonLayout>>;
  private actionLayoutFormSub?: Subscription;
  private actionSub?: Subscription;
  private api?: GridApi;

  constructor(
    @inject(ActionConfigService) private actionsConfigSvc: ActionConfigService,
    @inject(GridIdService) private gridIdService: GridIdService,
    @inject(ActionsService<TData>) private actionsService: ActionsService<TData>,
    @inject(ToolbarService) private toolbarService: ToolbarService
  ) {}

  addEvents(eventSource: EventSource<keyof GridEvent>): void {
    eventSource.add('onGridReady', (e) => {
      this.api = e.api;
      e.api.addEventListener('actionsReady', this.onActionsReady);
    });
  }

  removeEvents(): void {
    this.api?.removeEventListener('actionsReady', this.onActionsReady);
    this.actionLayoutForm?.dispose();
    this.actionLayoutForm = undefined;
    this.actionLayoutFormSub?.unsubscribe();
    this.actionLayoutFormSub = undefined;
    this.actionSub?.unsubscribe();
    this.actionSub = undefined;
  }

  private onActionsReady = (e: GridReadyEvent<AnyRecord, any>) => {
    if (e.context) {
      const meta: SetupConfigGridActionsMeta = e.context.meta as unknown as SetupConfigGridActionsMeta;
      const { widgetTypeId, actionContext$, allowedCommands = [] } = meta;
      const actionsService = this.actionsService;
      const gridIdService = this.gridIdService;
      const actionsConfigSvc = this.actionsConfigSvc;
      const objectId = gridIdService.gridType;
      const ignoredDefinitions: ActionDef<TData, AnyRecord>[] = actionsService.actions();
      const toolbarChanges$ = this.toolbarService.all$();
      const gridStateId = getCompositeId(gridIdService.gridId);

      this.actionSub = combineLatest([
        actionsConfigSvc.actionsBy$({
          objectId,
          widgetTypeId,
          gridStateId
        }),
        toolbarChanges$
      ]).subscribe(([actions, _toolbarState]) => {
        renderActions<TData>({
          actionsService,
          actions: actions.filter((a) => allowedCommands.includes(a.commandId) || !allowedCommands?.length),
          propOverrides: meta.props,
          actionContext$,
          ignoredDefinitions
        });
      });
    }
  };

  public async openForm(
    container: DependencyContainer,
    formWindowCfg: Parameters<typeof openActionButtonLayout>[1]
  ) {
    if (this.actionLayoutForm && this.actionLayoutFormSub) {
      return;
    }

    const workspace = container.resolve(AppWorkspace);
    const windowActor = workspace.getRootWindowActor();

    this.actionLayoutForm = await openActionButtonLayout(windowActor, formWindowCfg);
    this.actionLayoutFormSub = this.actionLayoutForm.events$.subscribe((e) => {
      if (!this.actionsService) return;

      switch (e.type) {
        case 'UNMOUNT':
        case 'SUBMIT_FINISHED_SUCCESS':
          this.actionLayoutForm?.dispose();
          this.actionLayoutForm = undefined;
          this.actionLayoutFormSub?.unsubscribe();
          this.actionLayoutFormSub = undefined;
          break;
      }
    });
  }
}

export const setupGridActions = <TGrid extends AnyRecord>(
  config: SetupGridActions<TGrid>
): GridBuilder<TGrid> => {
  const { grid, meta } = config;
  return grid
    .injectEvents([GridConfigEventHandler, ConfigurableGridActionsService<TGrid>])
    .context({ meta })
    .onToolbarHotkey(async ({ container, location }) => {
      const configurableActionSvc = container.resolve(ConfigurableGridActionsService);
      const gridIdService = container.resolve(GridIdService);
      const objectId = gridIdService.gridType;
      const widgetTypeId = meta.widgetTypeId;
      const gridStateId = getCompositeId(gridIdService.gridId);

      await configurableActionSvc.openForm(container, {
        form: {
          input: {
            locationId: location as ToolbarLocationKeys,
            objectId,
            widgetTypeId,
            gridStateId,
            allowedCommands: meta.allowedCommands
          }
        }
      });
    });
};
