import { createSignal, Signal } from '@oms/rx-broadcast';
import type { CreateSignalOpts } from '@oms/rx-broadcast';
import { UUID } from '@oms/ui-util';

export type ReconnectContext = {
  id?: string;
};

export abstract class ReconnectSignal {
  private signal$: Signal<ReconnectContext>;

  constructor(channelName: string, opts?: CreateSignalOpts) {
    this.signal$ = createSignal<ReconnectContext>(channelName, { id: undefined }, opts);
  }

  public reconnect(retryId?: string): void {
    this.signal$.set({ id: retryId || UUID() });
  }

  public get $() {
    return this.signal$.$;
  }
}
