import { Flex, Text, format } from '@oms/ui-design-system';
import type { IHeaderGroupParams } from '@ag-grid-community/core';
import { useMemo } from 'react';
import type { FC } from 'react';
import { useObservableState } from 'observable-hooks';
import { getCrossPrincipalFillOrders$ } from '@app/data-access/services/trading/cross-principal-fill/cross-principal-fill.state';
import { getOrderSideClassName, getPriceDisplayType } from '@oms/frontend-foundation';
import { translateEnum } from '@app/common/mappers/translate-enum';

type Context = {
  formId: string;
  side: string;
};

export const Header: FC<IHeaderGroupParams> = (props) => {
  const { formId = '', side = '' } = useMemo(() => {
    if (typeof props.context === 'object' && props.context !== null) {
      return props.context as Partial<Context>;
    } else {
      return {} as Partial<Context>;
    }
  }, [props.context]);

  const memoizedValue$ = useMemo(() => {
    return getCrossPrincipalFillOrders$(formId);
  }, [formId]);

  const value = useObservableState(memoizedValue$);

  const orderSideClassName = useMemo(() => getOrderSideClassName(side, { isInactive: false }), [side]);
  const sideText = useMemo(() => translateEnum('side', side.toUpperCase()), [side]);
  const rows = useMemo(
    () => (value ? (getPriceDisplayType(side) === 'positive' ? value.buyOrders : value.sellOrders) : []),
    [value, side]
  );
  const fillQuantity = useMemo(
    () =>
      rows.reduce(
        (acc, row) => acc + ((!row.state.isDisabled && row.state.isChecked && row.order.openQuantity) || 0),
        0
      ),
    [rows]
  );

  return (
    <Flex align="center" sx={{ bgColor: 'layout.level3', width: 'full', height: 'full' }}>
      <Text className={orderSideClassName} sx={{ marginLeft: 3 }}>
        {sideText}
      </Text>

      <Flex justify="flex-end" sx={{ marginRight: 1.5 }} style={{ flex: 1 }}>
        Total Open Qty:
      </Flex>
      <Flex
        justify="flex-end"
        style={{ flex: 0.15, marginRight: '102px' }}
        type="baseB"
        sx={{ color: 'white' }}
      >
        {format('quantity', fillQuantity)}
      </Flex>
    </Flex>
  );
};
