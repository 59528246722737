import { MEMORY_DB_COLLECTIONS_MAP, OFFLINE_DB_COLLECTIONS_MAP } from './collections';
import type {
  OfflineDatabaseCollectionType,
  OfflineDatabaseCollections,
  MemoryDatabaseCollections
} from './collections';
import type { MemoryDatabase } from './memory-database';
import type { OfflineDatabase } from './offline-database';
import { createLogger } from '@oms/ui-util';
import { testScoped } from '@app/workspace.registry';
import { singleton } from 'tsyringe';
import { BehaviorSubject, filter, firstValueFrom } from 'rxjs';
import type { Subscription } from 'rxjs';
import { createMemoryOnRemove$ } from './app-database.util';

const logger = createLogger({
  name: 'AppDatabase'
});
@testScoped
@singleton()
export class AppDatabase {
  private _memoryDb: MemoryDatabase | undefined;
  private _offlineDb: OfflineDatabase | undefined;
  private _offline: OfflineDatabaseCollections | undefined;
  private _memory: MemoryDatabaseCollections | undefined;
  private _memoryOnRemoveSubscription: Subscription | undefined;
  private _isInitialized = false;
  private _isResetting = new BehaviorSubject<boolean>(false);

  get isInitialized() {
    return this._isInitialized;
  }

  get memoryDb() {
    if (!this._memoryDb) {
      throw new Error('Memory database not initialized');
    }
    return this._memoryDb;
  }

  get offlineDb() {
    if (!this._offlineDb) {
      throw new Error('Offline database not initialized');
    }
    return this._offlineDb;
  }

  get offline() {
    if (!this._offline) {
      throw new Error('Offline database collections not initialized');
    }
    return this._offline;
  }

  get memory() {
    if (!this._memory) {
      throw new Error('Memory database collections not initialized');
    }
    return this._memory;
  }

  get isResetting() {
    return this._isResetting.value;
  }

  /**
   * Initializes the app database.
   * @param memoryDb - The memory database.
   * @param offlineDb - The offline database.
   */
  public init(memoryDb: MemoryDatabase, offlineDb: OfflineDatabase) {
    this._memoryDb = memoryDb;
    this._offlineDb = offlineDb;
    this._offline = offlineDb.collections;
    this._memory = memoryDb.collections;

    // Listen for a message to reset the memory
    this._memoryOnRemoveSubscription = createMemoryOnRemove$(this.memoryDb.collections).subscribe(() => {
      this.handleMemoryReset().catch((error) => {
        logger.error('Error resetting memory database', error);
      });
    });

    this._isInitialized = true;
  }

  /**
   * Resets the memory database
   */
  public async resetMemoryCollections() {
    await Promise.all(Object.values(this.memoryDb.collections).map((collection) => collection.remove()));

    logger.log('Memory database cleared');

    this._isResetting.next(true);
    await firstValueFrom(this._isResetting.asObservable().pipe(filter((isResetting) => !isResetting)));

    logger.log('Memory database reset');
  }

  /**
   * Clears the offline database.
   * @param options - The collections to clear. If not provided, all collections will be cleared.
   */
  public async clearOffline(options?: {
    [key in OfflineDatabaseCollectionType]?: boolean;
  }) {
    const collectionsToClear = Object.keys(OFFLINE_DB_COLLECTIONS_MAP)
      .filter((key) => {
        if (!options) return true; // If no options are provided, clear all collections
        if (!options[key as OfflineDatabaseCollectionType]) return false; // If the collection is not in the options OR is set to false, don't clear it
        return true; // Otherwise, clear the collection
      })
      .map((key) => {
        const collectionKey = key as OfflineDatabaseCollectionType;
        return {
          key: collectionKey,
          // Wrap the promise to find the docs first, then remove them per collection
          promise: new Promise<boolean>((resolve, reject) => {
            this.offline[collectionKey]
              .find()
              .exec()
              .then((docs) => {
                this.offline[collectionKey]
                  .bulkRemove(docs.map((doc) => doc.primary))
                  .then(() => resolve(true))
                  .catch(reject);
              })
              .catch(reject);
          })
        };
      });

    const collectionsStr = collectionsToClear.map(({ key }) => key).join(', ');
    logger.log('Clearing offline collections:', collectionsStr);
    await Promise.all(collectionsToClear.map(({ promise }) => promise));
    logger.log('Cleared offline collections:', collectionsStr);
  }

  /**
   * Destroys the memory and offline databases.
   * @returns A promise that resolves to the removed databases.
   */
  public async destroy() {
    this._isResetting.next(false);
    this._memoryOnRemoveSubscription?.unsubscribe();
    const offline = await this.offlineDb.remove();
    const memory = await this.memoryDb.remove();
    this._isInitialized = false;
    return { offline, memory };
  }

  /**
   * Handles re-creating the memory database collections after a reset.
   * This is done by listening for the `onRemove` event on each collection.
   * Notice, at the end of the function we create a new subscription with the same handler, because we use a take(1) observable.
   */
  private handleMemoryReset = async () => {
    await this.memoryDb.addCollections(MEMORY_DB_COLLECTIONS_MAP);
    this._memory = this.memoryDb.collections;
    this._isResetting.next(false);
    logger.info('Memory database collections re-created');
    this._memoryOnRemoveSubscription?.unsubscribe();
    this._memoryOnRemoveSubscription = createMemoryOnRemove$(this.memoryDb.collections).subscribe(() => {
      this.handleMemoryReset().catch((error) => {
        logger.error('Error resetting memory database', error);
      });
    });
  };
}
