import { Box } from '../../system/components/box/box';
import type { BoxProps } from '../../system/components/box/box';
import { TextTypographyStyleUnion } from '../../theme/foundations/typographyStyle';
import { typographyStyleVariants } from '../../theme/typography.css';
import clsx from 'clsx';
import * as styles from './text.css';
import { __DEV__ } from '../../system/utils/assertion';
import { polymorphicComponent } from '../../system/utils/polymorphic';

type OmittedProps = 'as';

export type TextProps = {
  type?: TextTypographyStyleUnion;
  isTruncated?: boolean;
  noOfLines?: number;
  as?:
    | 'div'
    | 'span'
    | 'p'
    | 'sup'
    | 'sub'
    | 'samp'
    | 's'
    | 'mark'
    | 'kbd'
    | 'em'
    | 'del'
    | 'cite'
    | 'abbr'
    | 'u'
    | 'i'
    | 'pre'
    | 'ins';
} & Omit<BoxProps, OmittedProps>;

export const Text = polymorphicComponent<'p', TextProps>((props, ref) => {
  const {
    as = 'span',
    type,
    children,
    className,
    noOfLines,
    style = {},
    isTruncated = false,
    ...rest
  } = props;

  return (
    <Box
      as={as}
      style={{
        ...style,
        ...(noOfLines ? { [`${styles.lineClampVarRaw}`]: noOfLines } : {})
      }}
      className={clsx(
        noOfLines && styles.noOfLines,
        type && typographyStyleVariants[type],
        isTruncated && styles.truncated,
        className
      )}
      {...rest}
      ref={ref}
    >
      {children}
    </Box>
  );
});

if (__DEV__) {
  Text.displayName = 'Text';
}
