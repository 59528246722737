import { ComponentType, DependencyList, useCallback, useEffect, useMemo, useState } from 'react';
import { useWidgetValue } from '@app/common/workspace/workspace.hooks';
import {
  TableServerFactory,
  TableServerOptions,
  TableServerQueries,
  TableServerQueryResult,
  TableServerSubscription
} from '../services/system/table-server/table-server.datasource.contracts';
import { useTableServer } from '../services/system/table-server/table-server.hook';
import { Box, Spinner } from '@oms/ui-design-system';
import { paneStyle } from '@app/widgets/common/styles/trading.styles';
import { Sprinkles } from '@oms/ui-design-system-css';
import { TableServerConnectionLoss } from '../services/system/table-server/table-server.connection.loss';

export type UpdatedData<T> = {
  data: T;
};

export type UseUpdatedDataResult<T> = Partial<UpdatedData<T>> & {
  unstableConnection: boolean;
  isLoading: boolean;
  reconnect: () => void;
  isReconnecting: boolean;
};

export type UpdatedDataProps = { idProp?: string; sx?: Sprinkles };
export type UpdatedDataOpts<
  TSubscriptionKey extends string,
  TSubscriptions extends Record<TSubscriptionKey, TableServerSubscription>,
  TResult = TableServerQueryResult<TSubscriptions[TSubscriptionKey]['datasource']>
> = {
  config: TableServerQueries<TSubscriptionKey, TSubscriptions>;
  result?: (response: TableServerQueryResult<TSubscriptions[TSubscriptionKey]['datasource']>) => TResult;
  queryOpts: Omit<
    TableServerOptions<
      TSubscriptions[TSubscriptionKey]['datasource'],
      TSubscriptions[TSubscriptionKey]['subscription']
    >,
    'query' | 'getData'
  >;
};

export type WithUpdatedDataOpts<
  TSubscriptionKey extends string,
  TSubscriptions extends Record<TSubscriptionKey, TableServerSubscription>,
  TResult = TableServerQueryResult<TSubscriptions[TSubscriptionKey]['datasource']>
> = Omit<UpdatedDataOpts<TSubscriptionKey, TSubscriptions, TResult>, 'queryOpts'> & {
  queryOpts: (
    widgetId: string
  ) => Omit<
    TableServerOptions<
      TSubscriptions[TSubscriptionKey]['datasource'],
      TSubscriptions[TSubscriptionKey]['subscription']
    >,
    'query' | 'getData'
  >;
  id: string;
  sx?: Sprinkles;
};

const useWidgetId = (propertyName: string) => {
  const id = useWidgetValue(propertyName);

  if (!id) {
    throw new Error('Can not determine id');
  }

  if (typeof id !== 'string') {
    throw new Error('id should be a string');
  }

  return id;
};

export const useUpdatedData = <
  TSubscriptionKey extends string,
  TSubscriptions extends Record<TSubscriptionKey, TableServerSubscription>,
  TResult = TableServerQueryResult<TSubscriptions[TSubscriptionKey]['datasource']>
>(
  {
    config,
    queryOpts,
    result = (r) => r as TResult
  }: UpdatedDataOpts<TSubscriptionKey, TSubscriptions, TResult>,
  dependencies?: DependencyList
): UseUpdatedDataResult<TResult> => {
  const { datasources, unstableConnection, connectionStates, reconnectService, isReconnecting } =
    useTableServer<TSubscriptionKey, TSubscriptions>({
      ...config
    });
  const [data, setData] = useState<UpdatedData<TResult>>();

  useEffect(() => {
    const queryKeys = Object.keys(config);
    const queryCount = queryKeys.length;

    if (queryCount > 1) {
      throw new Error('Query count should be 1.');
    }

    const factory = datasources[queryKeys[queryCount - 1] as TSubscriptionKey] as TableServerFactory<
      Omit<
        TableServerOptions<
          TSubscriptions[TSubscriptionKey]['datasource'],
          TSubscriptions[TSubscriptionKey]['subscription']
        >,
        'limit' | 'offset' | 'query' | 'getData'
      >,
      Omit<
        TableServerOptions<
          TSubscriptions[TSubscriptionKey]['datasource'],
          TSubscriptions[TSubscriptionKey]['subscription']
        >,
        'query' | 'getData'
      >,
      TSubscriptions[TSubscriptionKey]['datasource']
    >;

    // to prevent queries with no filters for new orders / pending modifications
    if (!queryOpts.filter) return;

    const sub = factory.asObservable(queryOpts).subscribe((e) => {
      setData({ data: result(e) });
    });

    return () => {
      sub?.unsubscribe();
    };
  }, [datasources, ...(dependencies || [])]);

  const connectionStateValues = Object.values(connectionStates);

  return {
    ...data,
    unstableConnection,
    isLoading: connectionStateValues.includes('initializing') || !connectionStateValues.length,
    reconnect() {
      reconnectService.reconnect();
    },
    isReconnecting
  };
};

export const withUpdatedData = <
  TSubscriptionKey extends string,
  TSubscriptions extends Record<TSubscriptionKey, TableServerSubscription>,
  TResult = TableServerQueryResult<TSubscriptions[TSubscriptionKey]['datasource']>,
  TProps = unknown
>(
  Component: ComponentType<{ props?: TProps } & UpdatedData<TResult>>,
  opts: WithUpdatedDataOpts<TSubscriptionKey, TSubscriptions, TResult>
) => {
  const WithUpdatedData: ComponentType<TProps> = (props: TProps) => {
    const widgetId = useWidgetId(opts.id);
    const queryOpts = useMemo(() => opts.queryOpts(widgetId), [widgetId]);
    const { data, isLoading, unstableConnection, isReconnecting, reconnect } =
      useUpdatedData<TSubscriptionKey, TSubscriptions, TResult>(
        {
          ...opts,
          queryOpts
        },
        [queryOpts]
      ) || {};

    const onTryAgain = useCallback(() => {
      reconnect?.();
    }, [reconnect]);

    return isLoading ? (
      <Spinner fillArea data-testid="with-updated-data-spinner" sx={opts.sx || paneStyle} />
    ) : (
      <TableServerConnectionLoss
        onTryAgain={onTryAgain}
        isReconnecting={isReconnecting}
        unstableConnection={unstableConnection}
      >
        {!data ? <Box sx={opts.sx || paneStyle}>Not found</Box> : <Component props={props} data={data} />}
      </TableServerConnectionLoss>
    );
  };

  return WithUpdatedData;
};
