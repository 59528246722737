import type {
  InvestorOrderRow,
  InvestorOrderStatus,
  ModificationValue,
  VisibleModificationInfoFragment
} from '@oms/generated/frontend';
import { ModificationType } from '@oms/generated/frontend';
import type { Sprinkles } from '@oms/ui-design-system';
import { Box, Spinner, VStack } from '@oms/ui-design-system';
import { PENDING_MODIFICATIONS } from './pending-modifications.grid.widget';
import { useGridSelectionEvent } from '@app/data-access/memory/grid.events';
import { useUpdatedInvestorOrder } from '@app/data-access/hoc/with-updated-investor-order';
import { InvestorOrderDetailsSummary } from '../investor-order-view/investor-order-view.order-details.widget';
import { InvestorOrderDetailsSummaryOfChanges } from './pending-modifications.summary-of-changes';
import { mapOrderTagsToString } from '@app/common/mappers/map-order-tags';
import { TableServerConnectionLoss } from '@app/data-access/services/system/table-server/table-server.connection.loss';

const ModificationToIOFieldMapping: Partial<Record<keyof ModificationValue, keyof InvestorOrderRow>> = {
  commType: 'commissionRateType',
  customerNotes: 'customerNotes',
  expiryDateTime: 'expiryDateTime',
  gtdTimestamp: 'gtdTimestamp',
  locate: 'locate',
  orderTags: 'orderTagIds',
  orderType: 'orderType',
  price: 'limitPrice',
  quantity: 'totalQuantity',
  receivedTimestamp: 'receivedTimestamp',
  representativeCode: 'representativeCode',
  settleDate: 'settleDate',
  settleType: 'settleType',
  timeInForce: 'timeInForce'
};

type ModificationValueMappers = {
  [K in keyof ModificationValue]?: (value: ModificationValue[K]) => string;
};

const ModificationToIOValueMapping: ModificationValueMappers = {
  orderTags: mapOrderTagsToString
};

const applyModification = <K extends keyof ModificationValue>(
  key: K,
  value: ModificationValue[K],
  modifiedOrder: InvestorOrderRow
) => {
  const IOKey = ModificationToIOFieldMapping[key];
  if (IOKey) {
    const valueMapper = ModificationToIOValueMapping[key];
    const val = (valueMapper ? valueMapper(value) : value) as InvestorOrderRow[typeof IOKey];
    modifiedOrder[IOKey] = val;
  }
};

const applyRequestedModificationsToInvestorOrder = (
  pendingModification: VisibleModificationInfoFragment,
  investorOrder: InvestorOrderRow
): InvestorOrderRow => {
  const modifiedInvestorOrder = structuredClone(investorOrder);

  Object.entries(pendingModification.requested ?? {}).forEach(([key, value]) => {
    applyModification(key as keyof ModificationValue, value, modifiedInvestorOrder);
  });

  // Although Cancel / Modify is not an order status, we want to show the status of the
  // modification instead of the investor order so we override the status for presentation
  // I would have used tsignore but linting complains :/ and FormSummary displays it correctly.
  modifiedInvestorOrder.status = ((pendingModification.requests ?? []).some(
    (request) => request?.type === ModificationType.Cancel
  )
    ? ModificationType.Cancel
    : ModificationType.Modify) as unknown as InvestorOrderStatus;

  return modifiedInvestorOrder;
};

const sx: Sprinkles = {
  paddingTop: 5,
  backgroundColor: 'layout.level2',
  height: 'full',
  overflowY: 'auto'
};

const textSx: Sprinkles = {
  ...sx,
  padding: 5,
  display: 'flex',
  flexDirection: 'column',
  placeContent: 'center',
  textAlign: 'center'
};

const wrapperSx: Sprinkles = {
  ...sx,
  paddingTop: 0,
  paddingLeft: 4,
  paddingRight: 4,
  paddingBottom: 4,
  position: 'relative'
};

const PendingModificationsSidePanelWidget = () => {
  const selectedRowsEvent = useGridSelectionEvent<
    VisibleModificationInfoFragment,
    { pendingModificationId: string | null }
  >(PENDING_MODIFICATIONS, 'scoped');
  const pendingModification = selectedRowsEvent?.payload.selectedRows[0];
  const investorOrderId = pendingModification?.investorOrderId;
  const {
    data: investorOrder,
    isLoading,
    isReconnecting,
    unstableConnection,
    reconnect
  } = useUpdatedInvestorOrder(investorOrderId);

  const ioWithModifications =
    pendingModification && investorOrder
      ? applyRequestedModificationsToInvestorOrder(pendingModification, investorOrder)
      : undefined;

  switch (true) {
    case !pendingModification:
      return <Box sx={textSx}>Please select a modification from pane on the left. </Box>;

    case isLoading || !ioWithModifications:
      return <Spinner fillArea bgColor={sx.backgroundColor} />;

    default:
      return (
        <TableServerConnectionLoss
          onTryAgain={reconnect}
          isReconnecting={isReconnecting}
          unstableConnection={unstableConnection}
        >
          <VStack sx={wrapperSx} spacing={0}>
            <InvestorOrderDetailsSummaryOfChanges
              currentIO={investorOrder ?? {}}
              newIO={ioWithModifications ?? {}}
            />
            <InvestorOrderDetailsSummary investorOrder={ioWithModifications} />
          </VStack>
        </TableServerConnectionLoss>
      );
  }
};

export default PendingModificationsSidePanelWidget;
