import clsx from 'clsx';
import { RowClassParams } from '@ag-grid-community/core';
import { ColorMode, useColorMode } from '@oms/ui-design-system';
import { useMemo } from 'react';
import { AgHeaderHeights, AgRowHeights, AgSizeValues } from '../models/grid.sizings';

export const calculateAgGridClassName = (
  displayOptions: { sizing?: keyof typeof AgSizeValues; hasRowBorders?: boolean; hasStripes?: boolean },
  colorMode: ColorMode
) => {
  const isDark = colorMode && colorMode === 'dark';

  if (!displayOptions) {
    return clsx('valstro-ag-grid-root', {
      'ag-theme-valstro-dark': isDark,
      'ag-theme-valstro': !isDark
    });
  }

  const { hasRowBorders, hasStripes, sizing } = displayOptions;

  return clsx('valstro-ag-grid-root', {
    'ag-theme-valstro-dark': isDark,
    'ag-theme-valstro': !isDark,
    'ag-theme-valstro--xs-font': sizing === AgSizeValues.XS,
    'ag-theme-valstro--sm-font': sizing === AgSizeValues.SM,
    'ag-theme-valstro--md-font': sizing === AgSizeValues.MD,
    'ag-theme-valstro--lg-font': sizing === AgSizeValues.LG,
    'ag-theme-valstro--no-row-borders': !hasRowBorders,
    'ag-theme-valstro--no-stripes': !hasStripes
  });
};

export const getAgGridRowSize = (displayOptions: { sizing?: keyof typeof AgSizeValues }) => {
  return displayOptions?.sizing ? AgRowHeights[displayOptions.sizing] : AgRowHeights['MD'];
};

export const getAgGridHeaderSize = (displayOptions: { sizing?: keyof typeof AgSizeValues }) => {
  return displayOptions?.sizing ? AgHeaderHeights[displayOptions.sizing] : AgHeaderHeights['MD'];
};

export const createAgGridRowSizeFunc =
  (displayOptions: { sizing?: keyof typeof AgSizeValues }) => (params: RowClassParams) => {
    const agGridRowSize = getAgGridRowSize(displayOptions);
    return {
      transform: `translateY(${params.rowIndex * agGridRowSize}px)`,
      height: `${agGridRowSize}px`
    };
  };

export const initialGridSettingsDisplayOptions = {
  hasRowBorders: true,
  hasStripes: true,
  sizing: AgSizeValues.MD,
  autoSizeColumns: false
};

export const useDisplayStateService = (title?: string, masterDetail?: boolean) => {
  const { colorMode } = useColorMode();

  const gridDisplayState = useMemo(() => {
    const agGridRowSize = getAgGridRowSize(initialGridSettingsDisplayOptions);
    const agGridHeaderSize = getAgGridHeaderSize(initialGridSettingsDisplayOptions);
    const agGridClassName = calculateAgGridClassName(initialGridSettingsDisplayOptions, colorMode || 'dark');
    const agGridGetRowStyle = createAgGridRowSizeFunc(initialGridSettingsDisplayOptions);

    return {
      agGridClassName: clsx(agGridClassName, {
        'has-ag-grid-title': !!title,
        'has-master-detail': !!masterDetail
      }),
      agGridGetRowStyle,
      agGridRowSize,
      agGridHeaderSize,
      agGridStyle: {
        height: '100%',
        '--ag-row-height': `${agGridRowSize - 2}px`,
        '--ag-header-height': `${agGridHeaderSize}px`
      } as React.CSSProperties,
      agGridContainerStyle: {
        position: 'relative',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        height: '100%',
        transition: 'height 100ms ease-in'
      } as React.CSSProperties
    };
  }, [colorMode, title, masterDetail]);

  return { ...gridDisplayState, colorMode };
};
