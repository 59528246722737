import { omit } from 'lodash';
import { GridApi } from '@ag-grid-community/core';
import { Logger } from '@oms/ui-util';
import type { AnyRecord } from '@oms/ui-util';
import type { GridFilterModel, GridFilterModelUpdateFn } from './grid-filter-model.types';

const logger = Logger.create({
  name: 'setGridFilterModel',
  level: 'debug'
});

/**
 * Retrieves the current filter model from the grid API in a type-safe manner
 * @param api - The AG Grid API instance
 * @returns The current filter model
 */
export function getGridFilterModel<TData extends AnyRecord, Filter = any>(
  api: GridApi
): GridFilterModel<TData, Filter> {
  return api.getFilterModel() as GridFilterModel<TData, Filter>;
}

/**
 * Updates the grid's filter model in a type-safe manner
 * @param api - The AG Grid API instance
 * @param filters - A new filter to merge into the existing model
 * @returns The updated filter model
 */
export function updateGridFilterModel<TData extends AnyRecord, Filter = any>(
  api: GridApi,
  filters: GridFilterModel<TData, Filter>
): GridFilterModel<TData, Filter>;

/**
 * Updates the grid's filter model in a type-safe manner
 * @param api - The AG Grid API instance
 * @param getFilters - A function to update the existing model
 * @returns The updated filter model
 */
export function updateGridFilterModel<TData extends AnyRecord, Filter = any>(
  api: GridApi,
  getFilters: GridFilterModelUpdateFn<TData, Filter>
): GridFilterModel<TData, Filter>;

// Implementation only ---------------------------------------------------- /
export function updateGridFilterModel<TData extends AnyRecord, Filter = any>(
  api: GridApi,
  filtersOrFiltersFn: GridFilterModel<TData, Filter> | GridFilterModelUpdateFn<TData, Filter>
): GridFilterModel<TData, Filter> {
  const current = getGridFilterModel<TData, Filter>(api);
  const filters = typeof filtersOrFiltersFn === 'function' ? filtersOrFiltersFn(current) : filtersOrFiltersFn;
  const updatedFilters = {
    ...omit(current, ...Object.keys(filters)),
    ...filters
  };
  logger.debug('Updating grid filter model', { filters, updatedFilters, current, api });
  api.setFilterModel(updatedFilters);
  api.onFilterChanged();
  return updatedFilters;
}
