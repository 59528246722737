import { Operation } from '@apollo/client';
import { getMainDefinition, getOperationName } from '@apollo/client/utilities';
import { Scope } from '@sentry/react';
import { GraphQLError, OperationDefinitionNode } from 'graphql';

const sentryGqlOpNameTag = 'graphql.operation.name';
const sentryGqlOpVariables = 'graphql.operation.variables';
const sentryGqlOpExtensions = 'graphql.operation.extensions';
const sentryGqlOpTypeTag = 'graphql.operation.type';
const sentryGraphqlContextName = 'Graphql Operation Info';

export type GraphQLContextForSentry = {
  document: Operation['query'];
  variables: Operation['variables'];
  extensions?: Operation['extensions'];
  scope: Scope;
  /* default: true */
  setTags?: boolean;
  context?: Record<string, any>;
};

export const graphqlContextForSentry = ({
  document,
  variables,
  extensions,
  scope,
  context,
  setTags = true
}: GraphQLContextForSentry) => {
  const definition = getMainDefinition(document);
  const queryOperationType = (definition as OperationDefinitionNode).operation;
  const operationName = getOperationName(document);

  const queryName = `graphql.${queryOperationType}.${operationName || ''}`;
  const queryContext = {
    [sentryGqlOpNameTag]: operationName || 'Unknown graphql operation name',
    [sentryGqlOpTypeTag]: queryOperationType
  };

  scope.setContext(sentryGraphqlContextName, {
    ...queryContext,
    [sentryGqlOpVariables]: variables,
    ...(extensions ? { [sentryGqlOpExtensions]: extensions } : {}),
    ...(context || {})
  });

  if (setTags) {
    scope.setTags(queryContext);
  }

  return {
    queryContext,
    queryName,
    queryOperationType
  };
};

export const createGraphQLErrorFingerprint = (queryName: string, err: GraphQLError) => [
  queryName,
  err.extensions?.code || 'unknown-error-code'
];
