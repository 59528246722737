import type { LoggerOptions } from './logger.types';
import { Logger } from './logger.class';

/**
 * Creates labeled debug logger that can be enabled or disabled.
 *
 * ```ts
 * const l = createLogger({ name: 'MyLogger' });
 * l('warn', 'Hello, World!'); // (WARN) [MyLogger] Hello, World!
 * l.info('Hello, World!'); // (INFO) [MyLogger] Hello, World!
 * ```
 *
 * @param name - Prepends all logs with this name
 * @returns A logger instance function to use for debugging
 */
export function createLogger(options?: LoggerOptions): Logger {
  return Logger.create(options);
}

/**
 * @deprecated Use `Logger` static constructors.
 *
 * A quick way to spin up a default
 */
export const logger = {
  debug: Logger.debug(),
  as: (name: string) => Logger.named(name)
};
