import { useCallback } from 'react';
import type { FC, MouseEventHandler } from 'react';
import { t } from '@oms/codegen/translations';
import type { AnyRecord, Prettify } from '@oms/ui-util';
import { Box, Button, Switch } from '@oms/ui-design-system';
import { Crosshair2Icon } from '@radix-ui/react-icons';
import type { ActionComponentProps } from '@oms/frontend-vgrid';
import { useActionProps } from '@oms/frontend-vgrid';
import { useGridTrackingState } from './use-grid-tracking.hook';

type GridTrackingToggleButtonProps = Prettify<
  {
    showSwitch?: boolean;
    turnOnTooltip?: string;
    turnOffTooltip?: string;
  } & ActionComponentProps<AnyRecord>
>;

/**
 * A component that displays a crosshair icon which indicates the follow target status.
 * The icon's color changes based on an active state managed through a subscription.
 * Includes an optional switch component to show the active state.
 *
 * @component
 * @example
 * ```tsx
 * <GridTrackingToggleButton
 *   showSwitch={true}
 *   turnOnTooltip={t('app.commands.follow.targetButton.investorOrder.turnOn')}
 *   turnOffTooltip={t('app.commands.follow.targetButton.investorOrder.turnOff')}
 * />
 * ```
 */
export const GridTrackingToggleButton: FC<GridTrackingToggleButtonProps> = ({
  showSwitch,
  turnOnTooltip = t('app.commands.follow.targetButton.default.turnOn'),
  turnOffTooltip = t('app.commands.follow.targetButton.default.turnOff'),
  ...props
}) => {
  const isTracking = useGridTrackingState();

  const actionProps = useActionProps<any, ActionComponentProps<AnyRecord>>(props);
  const { onChange, ...state } = actionProps;
  const { id: actionId, placeholder, variant = 'link' } = state;

  const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    onChange({
      state,
      actionId,
      lifecycle: 'change'
    });
  }, [actionId, onChange, state]);

  return (
    <Button
      className="follow-target"
      id={actionId}
      variant={variant}
      onClick={onClick}
      title={isTracking ? turnOffTooltip : turnOnTooltip}
      style={{ outline: 'none' }}
    >
      <Box className="follow-target-icon" sx={{ color: isTracking ? 'icons.active' : 'icons.semiMinor' }}>
        <Crosshair2Icon />
      </Box>
      {showSwitch && (
        <Switch
          sx={{ marginLeft: '4px' }}
          placeholder={placeholder}
          isDisabled={true}
          checked={isTracking}
          readOnly
        />
      )}
    </Button>
  );
};
