interface MultipleErrorsOptions {
  message?: string;
}

export class MultipleErrors<E extends Error> extends Error {
  public errors: E[];

  constructor(errors: E[], options?: MultipleErrorsOptions) {
    const { message: customMessage } = options ?? {};
    const message = customMessage ?? MultipleErrors.autoMessage(errors);
    super(message);
    this.errors = errors;
    Object.setPrototypeOf(this, MultipleErrors.prototype);
  }

  public get length(): number {
    return this.errors.length;
  }

  private static autoMessage<E extends Error>(errors: E[]) {
    return `${errors.length} error${errors.length === 1 ? '' : 's'}: ${errors
      .map(({ message }) => message)
      .join(', ')}`;
  }
}

