import type { ComponentType } from 'react';
import { withUpdatedData } from './with-updated-data';
import type { UpdatedData, UpdatedDataProps } from './with-updated-data';
import { TsTradingOrdersWithFilterDocument } from '@oms/generated/frontend';
import type { TradingOrderRow } from '@oms/generated/frontend';
import {
  TradingOrderMonitorSubscriptionKeys,
  TradingOrderMonitorSubscriptionMap
} from '@app/widgets/trading/trading-order-monitor/trading-order-monitor.widget';

export type UpdatedTradingOrder = UpdatedData<TradingOrderRow>;

export const withUpdatedTradingOrder = (
  Component: ComponentType<UpdatedTradingOrder>,
  props: UpdatedDataProps = {}
) => {
  return withUpdatedData<
    TradingOrderMonitorSubscriptionKeys,
    TradingOrderMonitorSubscriptionMap,
    TradingOrderRow
  >(Component, {
    id: props.idProp || 'id',
    sx: props.sx,
    config: {
      trading_orders: {
        query: TsTradingOrdersWithFilterDocument,
        getData: ({ tsTradingOrdersWithFilter }) => tsTradingOrdersWithFilter
      }
    },
    result(response) {
      return (response.rows || [])?.[0];
    },
    queryOpts(toId) {
      return {
        limit: 1,
        offset: 0,
        filter: {
          id: {
            filterType: 'text',
            type: 'contains',
            filter: toId
          }
        }
      };
    }
  });
};
