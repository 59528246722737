import {
  useVGrid,
  VGrid,
  ROW_SELECTION_COUNT_ACTION_TYPE,
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE
} from '@oms/frontend-vgrid';
import type { GridBuilder } from '@oms/frontend-vgrid';
import { ExecutionStatus } from '@oms/generated/frontend';
import type { ExecutionRow, TsExecutionsWithFilterSubscription } from '@oms/generated/frontend';
import { TsExecutionsWithFilterDocument } from '@oms/generated/frontend';
import type { TableServerMappedExecutions } from '@oms/generated/frontend';
import { GridConfigEventHandler } from '@app/data-access/services/system/grids/grid-config.event-handler';
import { buildExecutionsGridColumnDefs } from './trade-view.executions.columns';
import { cancelExecutionsAction } from './actions/cancel.executions.action';
import { applySettlementFXRateAction } from './actions/apply-settlement-fx-rate.action';
import { withTableServer } from '@app/data-access/services/system/table-server/with.table-server.component';

type TradeViewExecutionSubscriptionKeys = 'trade_view_executions';

type TradeViewExecutionSubscriptionMap = {
  trade_view_executions: {
    datasource: ExecutionRow;
    subscription: TsExecutionsWithFilterSubscription;
  };
};

type TradeViewExecutionProps = {
  id: ExecutionRow['id'];
};

export const TradeViewExecutionsGrid = withTableServer<
  TradeViewExecutionSubscriptionKeys,
  TradeViewExecutionSubscriptionMap,
  TradeViewExecutionProps
>(
  ({ datasources, props: compProps }) => {
    const { id } = compProps;

    const props = useVGrid<TableServerMappedExecutions>(
      'trade-view-executions',
      (b: GridBuilder<TableServerMappedExecutions>) =>
        b
          .tableServerColumnLibrary(buildExecutionsGridColumnDefs())
          .rowSelection((c) => c.multiple())
          .sideBar()
          .datasource((d) =>
            d
              .source(
                datasources.trade_view_executions.asServerSideRowModel({
                  filter: {
                    tradeId: {
                      filterType: 'text',
                      type: 'equals',
                      filter: id
                    }
                  }
                })
              )
              .rowId((r) => {
                return r.data?.tradeId;
              })
          )
          .injectEvents([GridConfigEventHandler])
          .rowStateRules({
            noExecutedQuantity: (params) => params.data?.status === ExecutionStatus.Inactive
          })
          .actions((a) =>
            a.schema((s) =>
              s
                .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(ROW_SELECTION_COUNT_ACTION_TYPE)
                .action(cancelExecutionsAction)
                .action(applySettlementFXRateAction)
            )
          ),
      [id, datasources]
    );

    return <VGrid {...props} />;
  },
  {
    trade_view_executions: {
      query: TsExecutionsWithFilterDocument,
      getData: (r) => r.tsExecutionsWithFilter
    }
  }
);

export default TradeViewExecutionsGrid;
