import { PERSISTED_AUTH_TOKEN_KEY } from './auth.contracts';
import { Maybe } from '@oms/ui-util';

export function persistAuthToken(token: string) {
  if (!localStorage) {
    throw new Error('Local storage is not available');
  }
  localStorage.setItem(PERSISTED_AUTH_TOKEN_KEY, token);
}

export function getPersistedAuthToken(): Maybe<string> {
  if (!localStorage) {
    throw new Error('Local storage is not available');
  }
  return localStorage.getItem(PERSISTED_AUTH_TOKEN_KEY);
}
