import type { ValidatorFunction } from '@data-driven-forms/react-form-renderer';
import { convert } from '@oms/ui-util';
import type { Optional, Maybe } from '@oms/ui-util';

type DurationInputFormatType = 'mm:ss';

const DURATION_PATTERN_MAPPER: Record<DurationInputFormatType, RegExp> = {
  'mm:ss': /^(\d+):([0-5][0-9])$/ // any number of digits before the colon, then exactly 2 digits after the colon (0-59)
} as const;

export interface ValidateDurationInputOptions {
  formatType?: DurationInputFormatType;
}

type ValidatorDurationFn = (options?: ValidateDurationInputOptions) => ValidatorFunction;

// Validator --------------------------------------------------------------- /

export const validateDurationInput: ValidatorDurationFn = (options) => (value) => {
  if (typeof value !== 'string' || !value.length) return null;
  const { formatType = 'mm:ss' } = options ?? {};
  switch (formatType) {
    case 'mm:ss': {
      const pattern = DURATION_PATTERN_MAPPER[formatType];
      if (pattern.test(value) === true) {
        return null;
      } else {
        return 'Format in mm:ss';
      }
    }
  }
};

/**
 * Calculates the duration in seconds based on the given value and options.
 *
 * @param value - The value representing the duration.
 * @param options - The options for validating the duration input.
 * @returns The duration in seconds, or undefined if the value is invalid or not provided.
 */
export const getDurationInSeconds = (
  value?: string,
  options?: ValidateDurationInputOptions
): Optional<number> => {
  const { formatType = 'mm:ss' } = options ?? {};
  switch (formatType) {
    case 'mm:ss': {
      if (!value) return undefined;
      const pattern = DURATION_PATTERN_MAPPER[formatType];
      const result = value.match(pattern);
      if (!result) return undefined;
      const [_, mm, ss] = result;
      const minutes = convert.string.to.numeric(mm) ?? 0;
      const seconds = convert.string.to.numeric(ss) ?? 0;
      return minutes * 60 + seconds;
    }
  }
};

/**
 * Converts a duration value in seconds to a string representation in the format "mm:ss".
 *
 * @param value - The duration value in seconds.
 * @returns The string representation of the duration in the format "mm:ss".
 */
export const getDurationInMMSS = (value: Maybe<number>): Optional<string> => {
  if (!value) return undefined;

  const minutes = Math.floor(value / 60);
  const remainingSeconds = value % 60;

  const mm = String(minutes).padStart(2, '0');
  const ss = String(remainingSeconds).padStart(2, '0');

  return `${mm}:${ss}`;
};
