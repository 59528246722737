import type { AnyRecord } from '@valstro/workspace';
import type { ComponentType } from 'react';
import { useWindowMeta } from '@app/widgets/common/hooks/use-window-meta';
import type { WindowMeta } from '@app/widgets/common/hooks/use-window-meta';

/**
 * HOC to conditionally render a component based on the window meta & pass the meta to the component
 *
 * @param Component - The component to wrap.
 * @returns The wrapped component.
 */
export function withWindowMetaShouldRender<TProps extends AnyRecord = AnyRecord>(
  Component: ComponentType<TProps & WindowMeta>,
  predicate: (meta: WindowMeta, props: TProps) => boolean
) {
  return (props: TProps) => {
    const meta = useWindowMeta();
    const shouldRender = predicate(meta, props);
    if (!shouldRender) {
      return null;
    }
    return <Component {...props} {...meta} />;
  };
}
