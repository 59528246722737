import type { InvestorOrderStatus } from '@oms/generated/frontend';
import type { Maybe } from '@oms/ui-util';
import { orderStatusMapping } from './order-enums';

export const mapOrderStatus = (
  value?: Maybe<InvestorOrderStatus> | string,
  defaultVal = value || ''
): string => {
  if (!value) return defaultVal;
  return orderStatusMapping[value as InvestorOrderStatus];
};
