import { DateFilter, FloatingDateFilter } from '../filters/date-filter';
import type { CustomFilterProps, CustomFloatingFilterProps } from '@ag-grid-community/react';
import type { Prettify } from '@oms/ui-util';
import type { AgGridStringFilter } from '../filters/filter-builders.relay';
import { AgArrayColumnFilter } from '../filters/ag-array-column-filter';
import { EnrichedColumnDef } from './table-server.types';

const CUSTOM_FILTER_COMPONENTS = ['dateFilter', 'agArrayColumnFilter'] as const;
const FLOATING_FILTER_COMPONENTS = ['dateFilter'] as const;

export type CustomFilterComponent = (typeof CUSTOM_FILTER_COMPONENTS)[number];
export type FloatingFilterComponent = (typeof FLOATING_FILTER_COMPONENTS)[number];

export type FilterComponent = Prettify<CustomFilterComponent | AgGridStringFilter>;

export const isCustomFilterComponent = (
  value: EnrichedColumnDef['filter']
): value is CustomFilterComponent => {
  return CUSTOM_FILTER_COMPONENTS.includes(value as CustomFilterComponent);
};

export const isFloatingFilterComponent = (
  value: EnrichedColumnDef['floatingFilterComponent']
): value is FloatingFilterComponent => {
  return FLOATING_FILTER_COMPONENTS.includes(value as FloatingFilterComponent);
};

export type CustomFilterFn<TData = any, TContext = any, TModel = any> = (
  params: CustomFilterProps<TData, TContext, TModel>
) => JSX.Element;
export type CustomFloatingFilterFn = (params: CustomFloatingFilterProps) => JSX.Element;

export const FLOATING_FILTER_COMPONENT_REGISTRY: Record<FloatingFilterComponent, CustomFloatingFilterFn> = {
  dateFilter: FloatingDateFilter
};

export const FILTER_COMPONENT_REGISTRY: Record<CustomFilterComponent, CustomFilterFn> = {
  agArrayColumnFilter: AgArrayColumnFilter,
  dateFilter: DateFilter
};
