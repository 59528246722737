import { inject, singleton } from 'tsyringe';
import { distinctUntilChanged, filter, map } from 'rxjs';
import type { Observable, OperatorFunction } from 'rxjs';
import type { AnyRecord, Prettify } from '@oms/ui-util';
import { compactMap, Result } from '@oms/ui-util';
import { ProcessState } from '@app/data-access/memory/process-id.subject';
import { AbstractSignal } from '@app/data-access/memory/signal';
import { testScoped } from '@app/workspace.registry';
import type {
  TrackedGridData,
  GridTrackingEvent,
  OrderType,
  InstrumentTrackingEvent,
  TrackedGridInstrumentData,
  OrderTrackingEvent,
  TrackingSourceType
} from './grid-tracking.types';
import { isSourceType } from './grid-tracking.util';
import { GridApi } from '@ag-grid-community/core';
import { GridIdService } from '@oms/frontend-vgrid';

export const GRID_TRACKING_CHANNEL_NAME = 'grid-tracking';

type ColIdOf<TData> = TData extends AnyRecord ? keyof TData : string;

type TrackedGridErrorDetails = Prettify<
  { message: string; sourceType: string } & Omit<GridTrackingEvent, 'sourceType'>
>;

interface WatchOptions<TData = unknown> {
  sourceTypes?: TrackingSourceType[];
  colIds?: ColIdOf<TData>[];
}

/**
 * Signal class for tracking grid state and selections across the application.
 * Provides mechanisms to:
 * - Publish grid selection changes
 * - Watch for changes in specific grid types
 * - Track instrument and order selections
 *
 * Uses process state to manage initialization and broadcasting of grid events.
 */
@testScoped
@singleton()
export class GridTrackingSignal extends AbstractSignal<Partial<GridTrackingEvent>> {
  public constructor(@inject(ProcessState) processState: ProcessState) {
    super(
      processState,
      GRID_TRACKING_CHANNEL_NAME,
      {
        initialize$: processState.isLeaderProcess$,
        initializeOnce: false,
        broadcast: true
      },
      {}
    );
  }

  // 📢 Public methods --------------------------------------------------------- /

  /**
   * Publishes a grid tracking event from the specified grid api and rows.
   *
   * @example
   * ```typescript
   * const api = gridOptions.api;
   * const gridIdService = container.resolve(GridIdService);
   * const rows = api.getSelectedRows().map(...);
   * gridTracking.publishFromGrid(api, gridIdService, rows);
   * ```
   *
   * @param api - The grid api instance
   * @param gridIdService - The grid id service instance
   * @param rows - Array of tracked items with their relevant properties
   * @returns A `Result` including the published event or error context if the source type is invalid
   */
  public publishFromGrid(
    api: GridApi,
    gridIdService: GridIdService,
    rows: Partial<TrackedGridData>[]
  ): Result<GridTrackingEvent, TrackedGridErrorDetails> {
    const { gridId: sourceId, gridType: sourceType } = gridIdService;
    const colId = api.getFocusedCell()?.column?.getColId();
    if (isSourceType(sourceType)) {
      const event: GridTrackingEvent = {
        sourceId,
        sourceType,
        rows,
        colId
      };
      this.publish(event);
      return Result.success(event);
    } else {
      return Result.failure({
        message: `Invalid source type: "${sourceType}"`,
        sourceId,
        sourceType,
        rows,
        colId
      });
    }
  }

  /**
   * Publishes a grid tracking event directly
   *
   * @example
   * ```typescript
   * gridTracking.publish({
   *   sourceId: 'grid1',
   *   sourceType: 'investor-order-monitor',
   *   rows: [{ orderId: '123', orderType: 'investor', instrumentId: '23233232', instrumentDisplayCode: 'AAPL.US' }],
   *   colId: 'instrumentDisplayCode'
   * });
   * ```
   *
   * @param event - The grid tracking event to publish
   * @param event.sourceId - The id of the source
   * @param event.sourceType - The tracked source type
   * @param event.rows - Array of tracked items with their relevant properties
   * @param event.colId - Optional identifier for the specific column being tracked
   */
  public publish(event: GridTrackingEvent) {
    this.signal.set(event);
  }

  /**
   * Returns an observable of the tracking events matching the specified options.
   *
   * @example
   * ```typescript
   * // Watch all changes in the instrument grid
   * gridTracking.watch$({
   *   sourceTypes: ['instrumentGrid']
   * }).subscribe(event => console.log('Grid updated:', event));
   *
   * // Watch specific columns across multiple grids
   * gridTracking.watch$<OrderGridData>({
   *   sourceTypes: ['orderGrid', 'tradeGrid'],
   *   colIds: ['status', 'quantity']
   * }).subscribe(event => console.log('Column updated:', event));
   *
   * // Watch all grid events
   * gridTracking.watch$().subscribe(event => console.log('Any grid updated:', event));
   * ```
   */
  public watch$<TData = unknown>(options?: WatchOptions<TData>): Observable<GridTrackingEvent> {
    const { sourceTypes = [], colIds } = options || {};
    return this.signal.$.pipe(
      filter((event) => {
        if (sourceTypes.length === 0) return true;
        return typeof event.sourceType === 'string' && (sourceTypes as string[]).includes(event.sourceType);
      }),
      map((event) => event as GridTrackingEvent),
      this.filterByColId(colIds)
    );
  }

  /**
   * Returns an observable of the last selected instrument.
   * @example
   * ```typescript
   * gridTracking.lastInstrument$({
   *   sourceTypes: ['instrumentGrid']
   * }).subscribe(instrument => console.log('Selected:', instrument));
   * ```
   */
  public instrument$<TData = unknown>(options?: WatchOptions<TData>): Observable<InstrumentTrackingEvent> {
    return this.watch$(options).pipe(
      filter(({ rows = [] }) => typeof rows[0]?.instrumentId === 'string'),
      map(({ sourceId, sourceType, colId, rows }) => {
        const { instrumentId, instrumentDisplayCode } = rows[0] as TrackedGridInstrumentData;
        return { sourceId, sourceType, colId, instrumentId, instrumentDisplayCode };
      }),
      distinctUntilChanged((previous, current) => previous.instrumentId === current.instrumentId)
    );
  }

  /**
   * Returns an observable of all selected order rows.
   * @example
   * ```typescript
   * gridTracking.allOrders$({
   *   sourceTypes: ['orderGrid', 'tradeGrid']
   * }).subscribe(orders => console.log('Selected:', orders));
   * ```
   */
  public allOrders$<TData = unknown>(options?: WatchOptions<TData>): Observable<OrderTrackingEvent> {
    return this.watch$(options).pipe(this.filterOrders('any'));
  }

  /**
   * Returns an observable of selected investor order rows.
   */
  public investorOrders$<TData = unknown>(options?: WatchOptions<TData>): Observable<OrderTrackingEvent> {
    return this.watch$(options).pipe(this.filterOrders('investor'));
  }

  /**
   * Returns an observable of selected trading order rows.
   */
  public tradingOrders$<TData = unknown>(options?: WatchOptions<TData>): Observable<OrderTrackingEvent> {
    return this.watch$(options).pipe(this.filterOrders('trading'));
  }

  // 👁️ Private methods --------------------------------------------------------- /

  private filterOrders(type: OrderType | 'any'): OperatorFunction<GridTrackingEvent, OrderTrackingEvent> {
    const matchesRequestedType = (requestedType: OrderType | 'any', type?: OrderType): type is OrderType =>
      typeof type === 'string' && (requestedType === 'any' || type === requestedType);
    return (source: Observable<GridTrackingEvent>) =>
      source.pipe(
        map(({ sourceId, sourceType, colId, rows = [] }) => ({
          sourceId,
          sourceType,
          colId,
          rows: compactMap(rows, ({ orderId, orderType }) =>
            typeof orderId === 'string' && matchesRequestedType(type, orderType)
              ? { orderId, orderType }
              : undefined
          )
        })),
        distinctUntilChanged(
          (previous, current) =>
            previous.rows[0]?.orderId === current.rows[0]?.orderId &&
            previous.rows.length === current.rows.length
        )
      );
  }

  private filterByColId<T extends GridTrackingEvent, TData = unknown>(
    colIds?: ColIdOf<TData>[]
  ): OperatorFunction<T, T> {
    return (source: Observable<T>) =>
      source.pipe(
        filter((event) => {
          if (!colIds || colIds.length === 0) {
            return true;
          }
          return typeof event.colId === 'string' && (colIds as string[]).includes(event.colId);
        })
      );
  }
}
