import { DestinationType, FactRule } from '@oms/generated/frontend';
import { FieldContract } from '@oms/frontend-foundation';
import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import type { RoutingRuleFieldContract } from './routing-rule.form-common';
import { ADVANCED_SELECT_QUERY_ENUM } from '@app/generated/common';
import { createVenueField } from '@app/forms/common/fields/venue-field/venue.field.common';
import { makeCommonRequiredProps } from '@app/forms/common/fields/shared-field-props';
import { FIXatdlStrategyValue } from '../../trading/route-order/fixatdl-strategy-field/fixatdl-strategy-field.contracts';
import { comboBoxItemsFrom } from '@oms/ui-design-system';
import { createActiveUserField } from '@app/forms/common/fields/active-user-field/active-user.field';

const fc = FieldContract.create<RoutingRuleFieldContract, AdditionalFormFieldUnion>();

const required = { type: 'required', message: 'This field is required' };

export const hiddenStrategyOptions = fc.field('hiddenStrategyOptions', 'hidden-field');

export const id = fc.field('id', 'hidden-field');

export const priority = fc.field('priority', 'hidden-field');

export const name = fc.field('name', 'text-field').options({
  label: 'Name',
  isRequired: true,
  validate: [required]
});

export const description = fc.field('description', 'text-field').options({
  label: 'Description',
  isRequired: true,
  validate: [required]
});

export const active = fc.field('active', 'switch').options({
  label: 'Active',
  initialValue: true,
  hideFormControls: true,
  type: 'checkbox',
  sx: {
    alignSelf: 'flex-end'
  }
});

export const venue = createVenueField(fc, 'venue', {
  ...makeCommonRequiredProps({
    indicatorStyle: 'none'
  }),
  query: ADVANCED_SELECT_QUERY_ENUM.WATCH_All_VENUES_FOR_USER_WITH_DESTINATION_TYPES_QUERY,
  label: 'Route To'
});

export const trader = createActiveUserField(fc, 'trader', {
  condition: {
    when: 'venue.value',
    is: DestinationType.Trader
  },
  label: 'Trader'
});

export const strategy = fc.field('strategy', 'fixatdl-strategy').advancedSelectOptions<FIXatdlStrategyValue>({
  validate: [{ type: 'validateRouteOrderStrategy' }]
});

const factRuleTypeMapping: { [key in FactRule]: string } = {
  [FactRule.InstrumentCountryOfIncorporation]: 'Country of Incorporation',
  [FactRule.InstrumentExchange]: 'Exchange',
  [FactRule.InstrumentExchangeCountry]: 'Exchange Country',
  [FactRule.InstrumentFirmIsMarketMaker]: 'Firm Market Maker',
  [FactRule.InstrumentId]: 'Instrument'
};

const getFactRuleTypeLabel = (fact: FactRule): string => factRuleTypeMapping[fact];

export const criteria = fc.field('criteria', 'rule-builder').options({
  name: 'criteria',
  addRuleText: 'Add criteria',
  addRuleGroupText: 'Add criteria group',
  initialValue: {
    combinator: 'and',
    rules: [{ field: '', operator: '', value: '' }]
  },
  field: {
    component: 'advanced-select',
    name: 'fact',
    label: 'Property',
    placeholder: 'Property',
    wrapperSx: {
      flexGrow: 1,
      marginRight: 4
    },
    options: comboBoxItemsFrom.enum(FactRule, {
      label: getFactRuleTypeLabel
    }),
    validate: [{ type: 'required', message: 'Choose a fact' }]
  },
  operator: {
    component: 'advanced-select',
    name: 'operator',
    label: 'Operator',
    wrapperSx: {
      flexGrow: 1,
      marginRight: 4
    },
    actions: {
      resolveProps: [
        'ruleOperatorAction',
        {
          inArray: true,
          criteriaField: 'fact',
          value: 'value.id'
        }
      ]
    },
    validate: [{ type: 'required', message: 'Choose an operator' }]
  },
  filterValue: {
    name: 'value',
    label: 'Value',
    component: 'component-mapper',
    componentType: 'advanced-select',
    placeholder: 'Enter a value',
    wrapperSx: {
      flexGrow: 1
    },
    actions: {
      resolveProps: [
        'ruleCriteriaAction',
        {
          criteriaField: 'fact',
          inArray: true,
          value: 'value.id'
        }
      ]
    },
    validate: [{ type: 'required', message: 'Choose a value' }]
  },
  validate: [{ type: 'required', message: 'Please select some criteria' }]
});
