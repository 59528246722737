import clsx from 'clsx';
import React, { useMemo } from 'react';
import { FormSpy, useFormApi } from '@data-driven-forms/react-form-renderer';
import { Button, Stack, Flex, Box } from '@oms/ui-design-system';
import type { FieldProps, ICommonField, Field } from '../../types';
import { useFieldApi } from '../../helpers';
import { type FORM_COMPONENT_TYPE } from '../../contracts';
import * as cssStyles from './runway.component.css';
export interface IRunwayField extends ICommonField<typeof FORM_COMPONENT_TYPE.RUNWAY> {
  fields: Field[][];
}

export const Runway: React.FC<FieldProps<IRunwayField>> = (props) => {
  const { renderForm } = useFormApi();
  const { fields = [], style = {}, sx = {} } = useFieldApi<IRunwayField>(props);
  const styles: React.CSSProperties = useMemo(
    () => ({ height: '70px', position: 'relative', zIndex: 1, ...style }),
    [style]
  );
  return (
    <Stack className="form-runway" spacing={0.5} style={styles} sx={sx}>
      {fields.map((fields: Field[], index: number) => (
        <Flex
          className={clsx('form-runway-field', cssStyles.field)}
          key={index}
          sx={{
            backgroundColor: 'layout.level3',
            ...(index === 0 && { borderTopLeftRadius: 'md', borderBottomLeftRadius: 'md' }),
            ...fields?.[0].wrapperSx
          }}
        >
          <Box sx={{ width: 'full', marginTop: 5 }}>{renderForm(fields)}</Box>
        </Flex>
      ))}
      <FormSpy>
        {({ submitting, validating, values: _values }) => (
          <Button
            type="submit"
            className={cssStyles.submit}
            style={{ height: '100%', width: '100px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            isLoading={submitting}
            isDisabled={
              submitting ||
              validating /* || getState().invalid - note: not using this as we decided Submit should always be clickable (with errors) */
            }
          >
            SUBMIT
          </Button>
        )}
      </FormSpy>
    </Stack>
  );
};
