import { whereNotUndefined } from '@oms/ui-util';
import type { Named } from '@oms/ui-util';
import { UUID, cleanMaybe } from '@oms/ui-util';
import type {
  PositionAccountRollUpFragment,
  PositionFragment,
  PositionGroupingAccountChildrenFragment,
  PositionGroupingAccountFragment,
  PositionGroupingAccountNestingFragment
} from '@oms/generated/frontend';
import type { PositionRow } from '@app/common/types/positions/positions.types';
import type { NestedTreeData } from '../common/tree-grid/types/tree-data.types';

const accountRollup = (accountRollup: Partial<PositionAccountRollUpFragment>) => ({
  toPartialPosition: (): Partial<PositionFragment> => {
    const { __typename, ...rest } = accountRollup;
    return { __typename: 'Position', ...rest };
  }
});

interface PositionFragmentToPositionRowOptions {
  name?: string;
  idOverride?: string;
  fallbackId?: (position: Partial<PositionFragment>) => string;
  fallbackQuantity?: (position: Partial<PositionFragment>) => number;
}

/**
 * @param partialPosition - Partial position fragment
 */
const partialPositionFragment = (partialPosition: Partial<PositionFragment>) => ({
  /**
   * Converts a partial position for grid row usage
   *
   * @param [options.name] - Provide a name for the row, as the fragment doesn't have on on it's own
   * @param [options.idOverride] - If you need to change the ID, this will override the one in the fragment
   * @param [options.fallbackId] - Provide a callback to get the fallback ID in case a partial fragment doesn't or there is no override. Default is to generate a new UUID if all else fails.
   * @param [options.fallbackQuantity] - Provide a callback to get the fallback quantity in case a partial fragment doesn't. Default is 0 if omitted.
   * @returns Object for position grid row use
   */
  toPositionRow: (options?: PositionFragmentToPositionRowOptions): PositionRow => {
    const { name, idOverride, fallbackId, fallbackQuantity } = options ?? {};
    const { id, quantity = fallbackQuantity?.(partialPosition) ?? 0, ...rest } = partialPosition;
    const input: PositionFragment & Partial<Named> = {
      id: typeof idOverride === 'string' ? idOverride : id || fallbackId?.(partialPosition) || UUID(),
      quantity,
      ...rest
    };
    if (typeof name === 'string') input.name = name;
    return input;
  }
});

/**
 * @param position - Position fragment
 */
const positionFragment = (position: PositionFragment) => ({
  /**
   * Converts a position for grid row usage
   *
   * @param [options.name] - Provide a name for the row, as the fragment doesn't have on on it's own
   * @param [options.idOverride] - If you need to change the ID, this will override the one in the fragment
   * @returns Object for position grid row use
   */
  toPositionRow: (
    options?: Omit<PositionFragmentToPositionRowOptions, 'fallbackId' | 'fallbackQuantity'>
  ): PositionRow => partialPositionFragment(position).toPositionRow(options)
});

const groupingAccountToPositionsRow = (
  groupingAccount: PositionGroupingAccountChildrenFragment | PositionGroupingAccountNestingFragment
): PositionRow => {
  const accountId = cleanMaybe(groupingAccount.id);
  const name = cleanMaybe(groupingAccount.name);
  const partialPosition = accountRollup(cleanMaybe(groupingAccount.position, {})).toPartialPosition();
  return partialPositionFragment(partialPosition).toPositionRow({
    idOverride: accountId,
    name
  });
};

export type AnyGroupingAccountFragment =
  | PositionGroupingAccountFragment
  | PositionGroupingAccountChildrenFragment
  | PositionGroupingAccountNestingFragment;

export const hasAccountType = (
  account: AnyGroupingAccountFragment
): account is PositionGroupingAccountNestingFragment | PositionGroupingAccountFragment => {
  return 'accountType' in account;
};

export const hasAccounts = (
  account: AnyGroupingAccountFragment
): account is PositionGroupingAccountChildrenFragment => {
  return 'accounts' in account;
};

export const hasChildren = (
  account: AnyGroupingAccountFragment
): account is PositionGroupingAccountFragment => {
  return 'children' in account;
};

const groupingAccountToPositionsRowForTreeData = (
  groupingAccount: AnyGroupingAccountFragment
): NestedTreeData<PositionRow> => {
  const positionRow = groupingAccountToPositionsRow(groupingAccount);
  const processGroupingAccounts = (
    groupingAccounts: (PositionGroupingAccountChildrenFragment | PositionGroupingAccountNestingFragment)[]
  ) => groupingAccounts.filter(whereNotUndefined).map(groupingAccountToPositionsRowForTreeData);
  const children = [
    ...(hasChildren(groupingAccount) ? processGroupingAccounts(groupingAccount.children) : []),
    ...(hasAccounts(groupingAccount) ? processGroupingAccounts(groupingAccount.accounts) : [])
  ];

  return children.length ? { ...positionRow, children } : positionRow;
};

export default {
  accountRollup,
  positionFragment,
  partialPositionFragment,
  groupingAccount: (groupingAccount: PositionGroupingAccountFragment) => ({
    toPositionRow: (): PositionRow => groupingAccountToPositionsRow(groupingAccount),
    toPositionRowForTreeData: (): NestedTreeData<PositionRow> =>
      groupingAccountToPositionsRowForTreeData(groupingAccount)
  })
};
