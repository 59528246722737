/**
 * 🎨 Internal components for the primary button row in context menus.
 * Provides wrapper and group components for organizing primary action buttons.
 */

// ===============================================================================
// 📦 Dependencies 📦
// ===============================================================================

// External dependencies
import { CSSProperties, useMemo } from 'react';
import type { FC, PropsWithChildren, ReactNode } from 'react';
import clsx from 'clsx';

// Internal dependencies
import { Box } from '../../../system/components/box/box';
import { ButtonGroup } from '../../button/button-group';
import {
  primaryButtonGroupFillerStyle,
  primaryButtonGroupStyle,
  primaryButtonRowWrapperStyle
} from './primary-button-row.css';

// ===============================================================================
// 🎯 Constants 🎯
// ===============================================================================

export const PRIMARY_BUTTON_ROW_WRAPPER_CLASS = 'context-menu-primary-button-row';
export const PRIMARY_BUTTON_GROUP_CLASS = 'context-menu-primary-button-group';
export const PRIMARY_BUTTON_FILLER_GROUP_CLASS = 'context-menu-filler-button-group';

// ===============================================================================
// 📝 Types 📝
// ===============================================================================

interface PrimaryButtonGroupProps {
  /** Array of button elements to render */
  children: ReactNode[];
  /** Determines the styling and behavior of the button group */
  type: 'active' | 'filler';
}

// ===============================================================================
// 🔧 Components 🔧
// ===============================================================================

/**
 * Wrapper component for the primary button row
 */
export const PrimaryButtonRowWrapper: FC<PropsWithChildren> = ({ children }) => (
  <Box className={clsx(PRIMARY_BUTTON_ROW_WRAPPER_CLASS, primaryButtonRowWrapperStyle)}>{children}</Box>
);

/**
 * Groups primary buttons with consistent styling and layout
 * Adjusts flex sizing based on number of children
 */
export const PrimaryButtonGroup: FC<PrimaryButtonGroupProps> = ({ children, type }) => {
  if (children.length === 0) {
    return null;
  }

  const className: string = useMemo(() => {
    const common = [PRIMARY_BUTTON_GROUP_CLASS, primaryButtonGroupStyle];
    switch (type) {
      case 'active':
        return clsx(...common);
      case 'filler':
        return clsx(...common, PRIMARY_BUTTON_FILLER_GROUP_CLASS, primaryButtonGroupFillerStyle);
    }
  }, [type]);

  const style: CSSProperties = useMemo(() => ({ flex: children.length }), [children.length]);

  return (
    <ButtonGroup
      className={className}
      style={style}
      direction="horizontal"
      justify="flex-end"
      align="flex-end"
    >
      {children}
    </ButtonGroup>
  );
};
