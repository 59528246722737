import { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import type { CSSProperties } from 'react';
import { internalWorkspace } from '@valstro/workspace';
import type { ClickEvent } from '@valstro/workspace';
import { useActorContext, useRootActor } from '@valstro/workspace-react';
import type { ReactActorComponentProps } from '@valstro/workspace-react';
import { useService } from '@oms/frontend-foundation';
import type { ComboBoxItem } from '@oms/ui-design-system';
import { ComboBox } from '@oms/ui-design-system';
import type {
  CommandPaletteContext,
  CommonCommandPaletteActorSchema
} from '@app/common/command-palette/command-palette.contracts';
import { useAppWorkspace } from '@app/common/workspace/workspace.hooks';
import { isMouseInsideBounds } from './command-palette.util';
import { commandPaletteActorLogger } from './command-palette.common.actor';
import { CommandPaletteService } from '@app/data-access/services/system/command-palette/command-palette.service';

// Note 36 is the height of tabs AND 43 is the height of the input
// In future, we should probably use a ref/js to get the height of the input
const TABS_HEIGHT = 24;
const COMMAND_PALETTE_META_HEIGHT = TABS_HEIGHT + 35;

export const CommonCommandPaletteComponent: React.FC<
  ReactActorComponentProps<CommonCommandPaletteActorSchema>
> = ({ actor }) => {
  const rootWindowActor = useRootActor();
  const cmdPaletteSvc = useService(CommandPaletteService);
  const workspace = useAppWorkspace();
  const [inputValue, setInputValue] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);
  const windowCtxRef = useRef<Partial<CommandPaletteContext>>({});
  const {
    items = [],
    selectedItems = [],
    multiSelect = false,
    height = 400,
    x,
    y,
    isVisible,
    width,
    placeholder,
    removeOnBackspace,
    hiddenSelectedItems,
    submitReady,
    strategy,
    isLoading,
    allowAnyValue,
    isInvalidated,
    errorMessage,
    scaleFactor
  } = useActorContext<CommonCommandPaletteActorSchema>(actor);

  // store the window context in a ref to be used by the useEffect below without deps (to avoid re-renders)
  windowCtxRef.current = { items, selectedItems, height, x, y, width };

  /**
   * When the window context changes, update the max list height for the command palette
   */
  const containerComputedStyles = useMemo(() => {
    return {
      height: 400 - 2 * scaleFactor // 2 for borders * by scaling
    } as CSSProperties;
  }, [scaleFactor]);

  const computedStyles = useMemo(() => {
    return {
      '--cmdk-list-max-height': `${height / scaleFactor - COMMAND_PALETTE_META_HEIGHT - 4}px` // 4 for borders
    } as CSSProperties;
  }, [height, scaleFactor]);

  /**
   * When the user selects an item, update the selected items
   */
  const setSelectedItems = useCallback(
    (items: ComboBoxItem[]) => {
      actor.operations
        .updatePalette({
          selectedItems: items
        })
        .then(() => {
          cmdPaletteSvc.cmdPaletteSelectedItemsChange$.next(items);
        })
        .catch(commandPaletteActorLogger.error);
    },
    [actor]
  );

  /**
   * When the user clicks away from the command palette, close it
   */
  useEffect(() => {
    const eventsTransport = internalWorkspace(workspace)._eventsTransport;

    const unlistenClickOutside = eventsTransport.listen('actor/custom-event', async (p) => {
      if (p.event.type !== 'click' || !containerRef.current) {
        return;
      }

      const { payload } = p.event as ClickEvent;

      if (payload.type === 'inside-dragstart') {
        await actor.operations.closePalette();
        return;
      }

      if (payload.type === 'inside') {
        const { x, y } = windowCtxRef.current;
        const { width, height } = containerRef.current.getBoundingClientRect();
        const { x: mouseX, y: mouseY } = payload;

        if (
          isMouseInsideBounds(
            { x, y, width: scaleFactor * width, height: scaleFactor * height },
            { x: mouseX, y: mouseY }
          ) === false
        ) {
          await actor.operations.closePalette();
        }
      }
    });

    return () => {
      unlistenClickOutside();
    };
  }, [workspace, actor, scaleFactor]);

  /**
   * When the user presses escape, close the command palette
   */
  const onInputEscape = useCallback(() => {
    actor.operations.closePalette().catch(commandPaletteActorLogger.error);
  }, [actor]);

  /**
   * When the user submits the command palette, close it
   */
  const onSubmit = useCallback(
    (items: ComboBoxItem[]) => {
      actor.operations.closePalette().catch(commandPaletteActorLogger.error);
      cmdPaletteSvc.cmdPaletteSubmit$.next({
        items,
        context: {
          windowId: rootWindowActor.id
        }
      });
    },
    [actor, rootWindowActor.id]
  );

  return (
    <div className="command-palette-container" ref={containerRef} style={containerComputedStyles}>
      <div cmdk-sm="" cmdk-frameless="" cmdk-wrapper="" style={computedStyles}>
        {isVisible && (
          <ComboBox
            items={items}
            inputValue={inputValue}
            setInputValue={setInputValue}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            removeOnBackspace={removeOnBackspace}
            hiddenInput={false}
            hiddenSelectedItems={hiddenSelectedItems}
            placeholder={placeholder}
            shouldAutoFilter={true}
            submitReady={submitReady !== undefined ? submitReady : multiSelect && selectedItems.length > 0}
            submitInstantly={multiSelect === false}
            onSubmit={onSubmit}
            strategy={strategy}
            isLoading={isLoading}
            allowAnyValue={allowAnyValue}
            isInvalidated={isInvalidated}
            errorMessage={errorMessage}
            onInputEscape={onInputEscape}
            tabThroughTabs={true}
            allSwitchable={true}
          />
        )}
      </div>
    </div>
  );
};
