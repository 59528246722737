import { Flex, Text } from '@oms/ui-design-system';
import { FormatLargeNumber } from '@oms/ui-util';
import { convert } from '@oms/ui-util';
import { type FC, useMemo } from 'react';
import { fieldLabel, fieldValue } from './summary-field.component.css';
import type { FieldProps, ICommonField } from '../../types';
import { useFieldApi } from '../../helpers';
import { type FORM_COMPONENT_TYPE } from '../../contracts';

export interface ISummaryField extends ICommonField<typeof FORM_COMPONENT_TYPE.SUMMARY_FIELD> {}

export const SummaryField: FC<FieldProps<ISummaryField>> = (props): JSX.Element => {
  const {
    label,
    input: { value }
  } = useFieldApi<ISummaryField>(props);

  const displayValue = useMemo(() => {
    switch (typeof value) {
      case 'undefined':
        return undefined;
      case 'object':
        return value === null ? undefined : value.toString();
      case 'string':
        return value;
      case 'number':
        return !Number.isNaN(value) ? FormatLargeNumber(value) : undefined;
      case 'boolean':
        return convert.boolean.to.string(value, { type: 'yes-no', capitalize: true });
      default:
        return value.toString();
    }
  }, [value]);

  return (
    <Flex justify="space-between">
      <Text className={fieldLabel}>{label}</Text>
      <Text className={fieldValue}>{displayValue}</Text>
    </Flex>
  );
};
