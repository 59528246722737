import React from 'react';
import { FormSpy } from '@data-driven-forms/react-form-renderer';
import { Button } from '@oms/ui-design-system';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import type { FieldProps, ICommonButtonField } from '../../types';
import { useFieldApi } from '../../helpers';
import { type FORM_COMPONENT_TYPE } from '../../contracts';

export interface ISubmitButtonField extends ICommonButtonField<typeof FORM_COMPONENT_TYPE.SUBMIT_BUTTON> {}

export const SubmitButton: React.FC<FieldProps<ISubmitButtonField>> = (props) => {
  const {
    style = {},
    isFeatureField = false,
    isPrimaryField = false,
    size
  } = useFieldApi<ISubmitButtonField>(props);
  const { submitDisableOnDirty = false } = props;

  const { buttonSize, buttonVariant, buttonPalette, buttonShape, buttonElevation } = props;

  return (
    <FieldWrapper isFeatureField={isFeatureField} isPrimaryField={isPrimaryField}>
      <FormSpy>
        {({ hasValidationErrors, dirty, submitFailed, submitting, pristine }) => (
          <Button
            type="submit"
            size={isFeatureField || isPrimaryField ? 'lg' : (buttonSize ?? size)}
            variant={buttonVariant}
            palette={buttonPalette}
            shape={buttonShape}
            elevation={buttonElevation}
            isLoading={submitting}
            isDisabled={
              submitDisableOnDirty &&
              (pristine || (hasValidationErrors && dirty) || (hasValidationErrors && submitFailed))
            }
            style={{ width: isFeatureField ? '100%' : 'fit-content', ...style }}
          >
            {props.buttonText ? props.buttonText : 'Submit'}
          </Button>
        )}
      </FormSpy>
    </FieldWrapper>
  );
};
