import React from 'react';
import { type IAdvancedSelectField } from './advanced-select.types';
import type { FieldProps } from '../../types';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import { useAdvancedSelectQuery } from './advanced-select-query.hook';
import { Autocomplete } from '../../../popovers/autocomplete/popover.autocomplete';
import { useFieldApi } from '../../helpers';
import { Flex } from '@oms/ui-design-system';

export const AdvancedSelectField: React.FC<
  React.PropsWithChildren<FieldProps<IAdvancedSelectField<any>>>
> = ({ children, ...props }) => {
  const field = useFieldApi<IAdvancedSelectField<any>>(props);
  const { fieldWrapperProps, autoCompleteProps } = useAdvancedSelectQuery(field);

  return (
    <FieldWrapper {...fieldWrapperProps} meta={field.meta}>
      <Flex align="center">
        {children}
        <Autocomplete
          {...autoCompleteProps}
          onChange={props.onChange || autoCompleteProps.onChange}
          value={props.value || autoCompleteProps.value}
        />
      </Flex>
    </FieldWrapper>
  );
};
