import { Valued } from '../types/objects/object-interface-types';

const stringToNumeric = (value: string | number): number | undefined => {
  if (!value) return undefined;
  value = typeof value === 'string' ? value : value.toString();
  const possible = Number.parseFloat(value.replace(/,/g, ''));
  return !Number.isNaN(possible) ? possible : undefined;
};

const stringToBoolean = (value?: string): boolean | undefined => {
  if (!value) return undefined;
  switch (value.toLowerCase()) {
    case 'true':
    case 'yes':
    case 't':
    case 'y':
    case '+':
    case '1':
    case 'on':
      return true;
    case 'false':
    case 'no':
    case 'f':
    case 'n':
    case '-':
    case '0':
    case 'off':
      return false;
    default:
      return undefined;
  }
};

const stringToEnum = (
  value: string,
  possibleValues: string[]
): (typeof possibleValues)[number] | undefined => {
  if (!value) return undefined;
  if (possibleValues.includes(value)) return value as (typeof possibleValues)[number];
  return undefined;
};

const stringToEnumFromValuedObject = (
  value: string,
  possibleValues: Valued<string>[]
): (typeof possibleValues)[number]['value'] | undefined => {
  return stringToEnum(
    value,
    possibleValues.map(({ value }) => value)
  );
};

const booleanToString = (
  value?: boolean | null,
  options?: {
    type?: 'default' | 'yes-no' | 'plus-minus' | 'on-off' | 'binary';
    short?: boolean;
    capitalize?: boolean;
    /** Requires that value be a boolean rather than evaluating as truthy/falsy (returns empty string for `undefined`) */
    strict?: boolean;
  }
): string => {
  const { type, short, capitalize, strict } = options ?? {};
  const base = (() => {
    if (strict && typeof value !== 'boolean') return '';
    if (value) {
      switch (type) {
        case 'yes-no':
          return short ? 'y' : 'yes';
        case 'plus-minus':
          return short ? '+' : 'plus';
        case 'on-off':
          return 'on';
        case 'binary':
          return short ? '1' : 'one';
        default:
          return short ? 't' : 'true';
      }
    } else {
      switch (type) {
        case 'yes-no':
          return short ? 'n' : 'no';
        case 'plus-minus':
          return short ? '-' : 'minus';
        case 'on-off':
          return 'off';
        case 'binary':
          return short ? '0' : 'zero';
        default:
          return short ? 'f' : 'false';
      }
    }
  })();
  const lowerCaseBase = base.toLowerCase();
  return capitalize
    ? lowerCaseBase.substring(0, 1).toUpperCase() + lowerCaseBase.substring(1)
    : lowerCaseBase;
};

export const convert = {
  string: {
    to: {
      alpha: (value: string): string => value,
      numeric: stringToNumeric,
      boolean: stringToBoolean,
      enum: stringToEnum,
      enumFromValuedObject: stringToEnumFromValuedObject
    }
  },
  boolean: {
    to: {
      string: booleanToString
    }
  }
};

