import { FieldContract } from '@oms/frontend-foundation';
import { orderSizeProfileContract } from './order-settings.profile.form-contract';
import type { OrderSettingsSchema } from '@app/data-access/offline/collections/user-preferences.collection';
import { getFieldsFromContract } from '../../user-preferences.util';

const fc = FieldContract.create<OrderSettingsSchema>();

export const profilesField = fc.field('profiles', 'field-array').options({
  style: {
    height: 'auto',
    maxHeight: '900px',
    flexGrow: 'initial'
  },
  sx: { overflow: 'visible' },
  container: {
    sx: { overflow: 'visible' }
  },
  form: {
    fields: getFieldsFromContract(orderSizeProfileContract)
  },
  button: {
    buttonText: 'Add order size profile',
    name: 'addOrderSizeProfile'
  }
});
